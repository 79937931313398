import {
  AdType,
  ErrorCategories,
  LoadingEvent,
  PlaybackEventTypes,
  StandardError,
  StoppedReasons,
  Stream,
  TrackingInfoEvent,
} from "@telia-company/tv.web-playback-sdk";
import {
  AdSession,
  createControls,
  getStartTime,
  isSafari,
  TUnfortunatelyAny,
  WebPlayerEventType,
} from "@telia-company/tv.web-player-shared";

import { getAdSession } from "../ad-session/ad-session";
import { attachMediaSession } from "../media-session/media-session";
import { getStreamingTicket } from "../streaming-ticket/streaming-ticket";
import { getTrackingInfoPayload } from "../tracking/tracking";
import { getEngineCallbacks } from "./engine-callbacks";
import { TSessionSetupOptions } from "./types";

const noValidStreamError = ({
  adSessionFailed,
}: {
  adSessionFailed: boolean;
}) =>
  new StandardError({
    category: ErrorCategories.STREAMING_GATEWAY,
    code: "NO_VALID_STREAM",
    details: {
      adSessionFailed,
      domain: "PlaybackSession",
      origin: "ServiceLayer",
    },
    fatal: true,
  });

export const sessionSetup = async (
  options: TSessionSetupOptions
): Promise<void> => {
  const {
    config: {
      application: { applicationVersion },
    },
    event,
    mutableSessionState,
    playback,
  } = options;

  if (options.features.timers) {
    // eslint-disable-next-line no-console
    console.timeEnd("incoming playback -> stream ticket request");
    // eslint-disable-next-line no-console
    console.time("getting stream url");
  }
  const ticketData = await getStreamingTicket(options);

  if (mutableSessionState.ended) return;

  let stream: Stream | undefined;

  if (options.features.disableAds || isSafari) {
    stream = ticketData?.streams?.find(
      (s: TUnfortunatelyAny) => s.adType === "NONE"
    );
  } else {
    stream = ticketData?.streams?.[0];
  }

  event.publish(
    new TrackingInfoEvent(
      getTrackingInfoPayload({
        stream,
        ticketData,
      })
    )
  );

  if (!stream) {
    throw noValidStreamError({ adSessionFailed: false });
  }

  // for testing and debug purposes in the reference app
  if (playback.overrideStreamUrl) {
    stream.url = playback.overrideStreamUrl;
  }

  const startTime = getStartTime(playback, stream);

  let adSession: AdSession | undefined;

  try {
    adSession = await getAdSession({
      ...options,
      startTime,
      stream,
    });
  } catch (_) {
    // AdSession failed, select ad free stream
    stream = ticketData?.streams?.find((s) => s.adType === AdType.NONE);
  }

  if (mutableSessionState.ended) return;

  // Check stream again, since we update it if ad session fails
  if (!stream) {
    throw noValidStreamError({ adSessionFailed: true });
  }

  if (adSession) {
    event.publish(
      new TrackingInfoEvent({
        advertisementBreaks: adSession.getAdBreaks(),
      })
    );
  } else {
    // Publish stream again, since we updated stream if ad session failed
    event.publish(
      new TrackingInfoEvent(
        getTrackingInfoPayload({
          stream,
          ticketData,
        })
      )
    );
  }

  event.publish({
    payload: {
      trickPlayRestrictions: ticketData.playbackRestrictions || {
        noFastForward: false,
        noPause: false,
        noRewind: false,
      },
    },
    type: WebPlayerEventType.PLAYBACK_RESTRICTIONS,
  });

  if (ticketData.timeLine) {
    event.publish({
      payload: {
        timeline: ticketData.timeLine,
      },
      type: WebPlayerEventType.TIMELINE_DATA,
    });
  }

  if (adSession) {
    event.publish({
      payload: adSession.getAdBreaks().map((b) => {
        if (!adSession) return 0;

        return (
          adSession.getPositionWithoutAds(b.position) /
          adSession.getDurationWithoutAds()
        );
      }),
      type: WebPlayerEventType.AD_MARKERS,
    });

    stream.url = adSession.getStreamUrl();
  }
  if (options.features.timers) {
    // eslint-disable-next-line no-console
    console.timeEnd("getting stream url");
  }

  const engineCallbacks = getEngineCallbacks({
    ...options,
    adSession,
  });

  const actualStartTime = adSession
    ? adSession.getPositionWithAds(startTime)
    : startTime;

  const engine = await options.engineLogic.createEngine({
    ...options,
    callbacks: engineCallbacks.callbacks,
    playback: {
      ...options.playback,
      // default autoplay to true
      autoplay:
        options.playback.autoplay === undefined
          ? true
          : options.playback.autoplay,
      startTimeInSeconds: actualStartTime,
    },
    selectedStream: stream,
  });

  // make engine available to endSession logic
  mutableSessionState.engine = engine;

  // update trackers now that we know what engine we use
  event.publish(
    new TrackingInfoEvent({
      player: {
        playerEngine: engine.playerId,
        playerName: "web-player",
        playerVersion: applicationVersion,
      },
    })
  );

  const controls = createControls({
    adSession,
    engine,
    restrictions: ticketData.playbackRestrictions || {},
  });

  event.publish({
    payload: controls,
    type: WebPlayerEventType.CONTROLS_AVAILABLE,
  });

  attachMediaSession({
    controls,
    endSession: () => {
      options.teardown(StoppedReasons.USER);
    },
    event,
  });

  options.event.publish(new LoadingEvent());

  if (options.features.timers) {
    const timerOff = () => {
      options.event.off(PlaybackEventTypes.PLAYING, timerOff);
      // eslint-disable-next-line no-console
      console.timeEnd("engine load -> content playing");
      // eslint-disable-next-line no-console
      console.timeEnd("incoming playback -> stream is playing");
    };
    options.event.on(PlaybackEventTypes.PLAYING, timerOff);
    // eslint-disable-next-line no-console
    console.time("engine load -> content playing");
  }
  engine.load();
};
