import styled from "@emotion/styled";
import { FC } from "react";

import { useIcon, useSmallLayout } from "./context-provider";
import { centeredFlexIcon } from "./styles";

const spinAnimation = `
  animation: spin 1.5s linear infinite, fadein .2s linear;
  transform-origin: center;
  
  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }
  
  @keyframes fadein {
    from {
      height: 0em;
      width: 0em;
      > svg { 
        fill-opacity: 0.2;
        stroke-opacity: 0.2;
      }
    }
    
    to {
      height: 4em;
      width: 4em;
      > svg { 
        fill-opacity: 0.7;
        stroke-opacity: 0.7;
      }
    }
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
`;

const SpinnerWrapper = styled.div<SpinnerProps>`
  width: ${({ smallLayout }) => (smallLayout ? "6em" : "4em")};
  height: ${({ smallLayout }) => (smallLayout ? "6em" : "4em")};

  pointer-events: none;

  > svg {
    pointer-events: none;
    fill-opacity: 0.7;
    stroke-opacity: 0.7;
  }

  ${spinAnimation}
  ${centeredFlexIcon}
`;

export type SpinnerProps = { smallLayout: boolean };

export const Spinner: FC = () => {
  const smallLayout = useSmallLayout();
  const { IconSpinner } = useIcon();

  return (
    <SpinnerContainer>
      <SpinnerWrapper smallLayout={smallLayout}>
        <IconSpinner />
      </SpinnerWrapper>
    </SpinnerContainer>
  );
};
