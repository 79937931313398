import {
  ID3Parser,
  ShakaEmsgFilter,
  StreamCueTypes,
} from "@telia-company/tv.web-playback-sdk";
import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";

import { TEngineMethodOptions } from "../../@types/types";

export const applyShakaEmsgFilter = (
  options: TEngineMethodOptions,
  nwEngine: TUnfortunatelyAny
): void => {
  nwEngine.registerResponseFilter(
    new ShakaEmsgFilter((emsg) => {
      let parsed = null;

      try {
        parsed = ID3Parser.Parse(
          emsg.messageData,
          emsg.startTime / emsg.timescale
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(
          "Engine::Event:EmsgCue: Failed to parse Id3 from Emsg cue",
          emsg,
          err
        );
      }

      options.callbacks.onStreamCue({
        parsed,
        raw: emsg.messageData,
        type: StreamCueTypes.ID3_IN_EMSG,
        ...emsg,
      });
    }, options.domElements.videoElement).responseFilter
  );
};
