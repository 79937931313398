import Constants from '../../core/nqs/Services';
import Core from '../../core/Core';
import AppAnalyticsRequest from '../AppAnalyticsRequest';
import { Method } from '../../common/Constants';
import CoreConstants from '../../core/utils/CoreConstants';
export default class AppAnalyticsRequestHandler {
    /**
     * Npaw Communication implements an abstraction layer over API requests.
     * Internally, Communication implements queues of {@link Request} objects.
     * This queue can be blocked using {@link Transform}
     *
     */
    constructor() {
        this.readyToSend = false;
        this.servicesToWait = [Constants.Service.SESSION_START];
        this.onWillSendAnalyticsRequestListeners = [];
        /**
         * Queue of {@link AppAnalyticsRequest}
         */
        this._requests = [];
        this._waitingForToken = [];
    }
    destroy() {
        this._requests = [];
        this._waitingForToken = [];
        this.readyToSend = false;
    }
    shouldSendStats() {
        return this.readyToSend;
    }
    /**
     * Enqueues the provided request
     *
     * @param { AppAnalyticsRequest } request Request to be enqueued
     */
    sendRequest(request) {
        if (Core.getInstance().getFastDataSessionToken() === '') {
            this._waitingForToken.push(request);
            return;
        }
        this._waitingForToken.forEach((request) => this.prepareParams(request));
        this._requests.push(...this._waitingForToken);
        this._waitingForToken = [];
        let params = request.getParams();
        let serviceName = request.getService();
        const listeners = this.onWillSendAnalyticsRequestListeners;
        for (const listener of listeners) {
            listener(serviceName, params);
        }
        this.prepareParams(request);
        this._requests.push(request);
        this._processRequests();
    }
    prepareParams(request) {
        const service = request.getService();
        request.setParam('sessionId', request.getParam('sessionId') || Core.getInstance().getFastDataSessionToken());
        if (service == Constants.Service.SESSION_START) {
            request.setParam('beatTime', request.getParam('beatTime') || Core.getInstance().getFastDataService().getBeatTime());
        }
    }
    /**
     * Build a generic request to the given host.
     *
     * @param {string} service A string with the service to be called. ie...
     * @param {Object} [params] Object of key:value params.
     * @param {Method} method Type of request (GET, POST, ...)
     * @param {any} body body of the request
     * @param {function} [onSuccessCallback] The defined onSuccess callback to the request
     * @param {function} [onFailCallback] The defined onFail callback to the request
     */
    buildRequest(service, params, method, body, onSuccessCallback, onFailCallback) {
        method = method ? method : Method.GET;
        const request = new AppAnalyticsRequest(service, params, body, onSuccessCallback, onFailCallback);
        request.setMethod(method);
        return request;
    }
    /**
     * Execute pending requests in the queue. Returns rejected ones to the queue.
     */
    _processRequests() {
        const workingQueue = this._requests;
        this._requests = [];
        const rejected = [];
        while (workingQueue.length) {
            const request = workingQueue.shift();
            // Apply logic of blocking requests before init or start or if we are in offline mode
            if (this.servicesToWait.indexOf(request.getService()) !== -1) {
                this.readyToSend = true;
            }
            if (this.readyToSend) {
                Core.getInstance().pushData(Core.getInstance().getOptions().offline ? CoreConstants.Consumer.BLACK_HOLE : CoreConstants.Consumer.NQS, request.getService(), request.getMethod(), request.getParams(), request.getOnSuccess(), request.getOnFail());
                while (rejected.length) {
                    const r = rejected.shift();
                    Core.getInstance().pushData(Core.getInstance().getOptions().offline ? CoreConstants.Consumer.BLACK_HOLE : CoreConstants.Consumer.NQS, r.getService(), r.getMethod(), r.getParams(), r.getOnSuccess(), r.getOnFail());
                }
            }
            else {
                rejected.push(request);
            }
        }
        this._requests.push(...rejected);
    }
}
