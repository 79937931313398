import {
  ErrorCategories,
  NetworkError,
  PlaybackSpec,
  StreamingGatewayError,
  SdkWatchMode,
  TimeUpdateEvent,
} from "../../base";
import { FetchRequestOptions, FetchRequestResponse } from "../../network";
import { BaseService } from "../BaseService";
import { StreamingTicketData } from "./Types";
import {
  DrmType,
  ServiceConfig,
  StreamingGatewayServiceConfiguration,
} from "../Types";
import { ServiceError } from "../errors/";
import { Logger as LoggerSingleton } from "../../base";
import { ServiceErrorCodes } from "../errors";
import Bowser from "bowser";
import { secondsToMs } from "../../utils";

const Logger = LoggerSingleton.createLoggerContext("StreamingGatewayService");

export type DeleteStreamingTicketArgs = {
  videoId: string;
  videoIdType: string;
  playbackSessionId: string;
  position?: number;
};

export type GetStreamingTicketArgs = {
  playbackSpec: PlaybackSpec;
  capabilities?: string[];
  drmType?: DrmType;
  playbackSessionId: string;
  packagings?: string[];
  regionalChannels?: Record<string, string>;
  preferredAudioLanguage?: string;
};

const parser = Bowser.getParser(navigator.userAgent);

export class StreamingGatewayService extends BaseService {
  protected configuration?: StreamingGatewayServiceConfiguration;
  private deleteStreamingTicketArgs?: DeleteStreamingTicketArgs | null;
  protected headers: any = {};
  private osVersion = parser.getOSVersion();
  private osName = parser.getOSName();

  constructor() {
    super("StreamingGatewayService");
  }

  public initialize(
    configuration: StreamingGatewayServiceConfiguration | ServiceConfig
  ) {
    this.configuration = configuration;
    this.headers = {
      "tv-client-boot-id": this.configuration.applicationSessionId,
      "X-Country": this.configuration.serviceCountry.toLowerCase(),
    };
  }

  private createRequestOptions(
    args: any,
    method: string = "POST",
    isAuthenticated: boolean = false,
    headers: any
  ): FetchRequestOptions {
    return {
      method,
      body: JSON.stringify({
        ...args,
      }),
      headers,
      useAuthentication: isAuthenticated,
    };
  }

  private getVideoId(
    playbackSpec: PlaybackSpec,
    regionalChannels?: Record<string, string>
  ): string {
    let videoId: string = playbackSpec.videoId;
    if (playbackSpec.videoIdType === "CHANNEL" && regionalChannels) {
      let regionalChannelContentId = this.getRegionalChannelIfAny(
        playbackSpec,
        regionalChannels
      );
      if (regionalChannelContentId) {
        videoId = regionalChannelContentId;
      }
    }

    return videoId;
  }

  private getRegionalChannelIfAny(
    playbackSpec: PlaybackSpec,
    regionalChannels: Record<string, string>
  ): string | undefined {
    return regionalChannels[playbackSpec.videoId];
  }

  /**
   * @throws {ServiceError} - MissingConfiguration
   */
  public async deleteStreamingTicket(
    args: DeleteStreamingTicketArgs,
    headers: any
  ): Promise<FetchRequestResponse | NetworkError> {
    if (!this.configuration) {
      throw new ServiceError({
        code: ServiceErrorCodes.MissingConfiguration,
        category: ErrorCategories.DEFAULT,
        fatal: true,
        details: {
          origin: this.name,
          domain: "deleteStreamingTicket",
          configuration: this.configuration,
        },
      });
    }

    let query = `sessionId=${args.playbackSessionId}&whiteLabelBrand=${this.configuration.serviceBrand}&country=${this.configuration.serviceCountry}`;
    if (args.position != null) {
      query += `&position=${args.position}`;
    }

    const url = `${this.configuration.streamingGatewayUrl}/streaminggateway/rest/secure/v2/streamingticket/${args.videoIdType}/${args.videoId}?${query}`;
    const isAuthenticated = await this.requestFactory.isAuthenticated();
    // When destroying the service, this.headers has been erased due to the above await, causing the delete request to have no headers.
    // Make sure to use the headers passed in to the deleteStreamingTicket function instead of this.headers here.
    const deletePromise = this.requestFactory.fetch(
      url,
      this.createRequestOptions({}, "DELETE", isAuthenticated, headers)
    );

    this.deleteStreamingTicketArgs = null;
    deletePromise.catch(() => {});

    return deletePromise;
  }

  /**
   * @throws {BadResponseError}
   * @throws {ServiceError} - ServiceError
   * @throws {NetworkError}
   */
  public async getStreamingTicket(
    args: GetStreamingTicketArgs
  ): Promise<StreamingTicketData> {
    if (!this.configuration) {
      throw new ServiceError({
        code: ServiceErrorCodes.MissingConfiguration,
        category: ErrorCategories.DEFAULT,
        fatal: true,
        details: {
          origin: this.name,
          domain: "getStreamingTicket",
          configuration: this.configuration,
        },
      });
    }

    if (this.deleteStreamingTicketArgs) {
      // Not awaiting this since delete of ticket is 'best effort'
      this.deleteStreamingTicket(this.deleteStreamingTicketArgs, this.headers);
    }

    const videoId: string = this.getVideoId(
      args.playbackSpec,
      args.regionalChannels
    );

    this.deleteStreamingTicketArgs = {
      videoId,
      videoIdType: args.playbackSpec.videoIdType,
      playbackSessionId: args.playbackSessionId,
    };

    const requestOptionsArgs = {
      sessionId: args.playbackSessionId,
      whiteLabelBrand: this.configuration.serviceBrand,
      watchMode: args.playbackSpec.watchMode,
      accessControl: args.playbackSpec.accessControl,
      device: {
        deviceId: this.configuration.deviceId,
        packagings: args.packagings || ["DASH_MP4_CTR"],
        drmType: args.drmType || DrmType.WIDEVINE,
        capabilities: args.capabilities || [],
        screen: {
          height: this.configuration.screen.screenHeight,
          width: this.configuration.screen.screenWidth,
        },
        tvClient: {
          name: this.configuration.clientName,
          version: this.configuration.application.applicationVersion,
          os: this.osName,
          osVersion: this.osVersion,
          vendor: this.configuration.deviceVendor,
          vendorModel: this.configuration.deviceModel,
        },
      },
      preferences: {
        audioLanguage: [],
        accessibility: [],
      },
    };

    if (args.preferredAudioLanguage) {
      requestOptionsArgs.preferences.audioLanguage.push(
        // @ts-ignore
        args.preferredAudioLanguage
      );
    }

    const query = `country=${this.configuration.serviceCountry}`;
    const isAuthenticated = await this.requestFactory.isAuthenticated();

    const mustBeSecure = args.playbackSpec.watchMode !== SdkWatchMode.TRAILER;

    const securePath = mustBeSecure || isAuthenticated ? "/secure" : "";
    ``;
    const url = `${this.configuration.streamingGatewayUrl}/streaminggateway/rest${securePath}/v2/streamingticket/${args.playbackSpec.videoIdType}/${videoId}?${query}`;
    const response = await this.requestFactory.fetch(
      url,
      this.createRequestOptions(
        requestOptionsArgs,
        "POST",
        isAuthenticated,
        this.headers
      )
    );

    if (response instanceof NetworkError) {
      if (response.responseBody && response.responseBody.errorCode) {
        throw new StreamingGatewayError({
          fatal: true,
          originalError: response,
          details: {
            origin: this.name,
            domain: "getStreamingTicket",
            configuration: this.configuration,
          },
        });
      }

      throw response;
    } else if (response instanceof FetchRequestResponse) {
      if (!(response.responseBody as StreamingTicketData)) {
        throw new ServiceError({
          code: ServiceErrorCodes.BadResponseError,
          category: ErrorCategories.STREAMING_GATEWAY,
          fatal: true,
          details: {
            response,
            origin: this.name,
            domain: "getStreamingTicket",
            configuration: this.configuration,
          },
        });
      }
      return response.responseBody;
    } else {
      throw new ServiceError({
        code: ServiceErrorCodes.InternalError,
        category: ErrorCategories.DEFAULT,
        fatal: true,
        details: {
          response,
          origin: this.name,
          domain: "getStreamingTicket",
          configuration: this.configuration,
        },
      });
    }
  }

  public timeUpdate(event: TimeUpdateEvent): void {
    if (this.deleteStreamingTicketArgs) {
      this.deleteStreamingTicketArgs.position = secondsToMs(
        event.payload.currentTime
      );
    }
  }

  async reset(): Promise<void> {
    if (this.deleteStreamingTicketArgs) {
      // Not awaiting this since delete of ticket is 'best effort'
      this.deleteStreamingTicket(this.deleteStreamingTicketArgs, this.headers);
    }

    this.configuration = undefined;
    this.headers = {};

    return Promise.resolve();
  }

  async destroy(): Promise<void> {
    this.reset();

    return Promise.resolve();
  }
}
