import * as React from "react";

function SvgSpinner(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 4C9.373 4 4 9.373 4 16s5.373 12 12 12c3.073 0 5.877-1.155 8-3.056"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgSpinner;
