import { TPlaybackConfig } from "@telia-company/tv.web-player-shared";
import { Dispatch, useEffect } from "react";

import { TGetRelatedContent } from "../components/overlays/list-overlay/types/types";
import { UiEventTypes } from "../constants";
import { noop } from "../utils";
import { TAction } from "./ui-state-reducer.hook";

export type TUseRelatedContentOptions = {
  dispatch: Dispatch<TAction>;
  getRelatedContent?: TGetRelatedContent;
  playback: TPlaybackConfig;
};

export const useRelatedContent = ({
  dispatch,
  getRelatedContent,
  playback,
}: TUseRelatedContentOptions) => {
  useEffect(() => {
    if (!playback) return noop;
    if (!getRelatedContent) return noop;

    let aborted = false;
    let interval: number | undefined;

    // use playback context to determine if we're showing live content, use related channels if we are.
    const shouldGetRelatedChannels =
      playback.playbackContext?.videoIdType === "CHANNEL" &&
      playback.playbackContext.watchMode === "LIVE";
    const channelPlaybackSpec = shouldGetRelatedChannels
      ? playback.playbackContext
      : undefined;

    getRelatedContent(playback.playbackSpec, channelPlaybackSpec)
      .then((r) => {
        if (aborted) return;

        dispatch({
          payload: r,
          type: UiEventTypes.RELATED_CONTENT_UPDATE,
        });

        if (r?.refreshInterval) {
          interval = window.setInterval(() => {
            if (!getRelatedContent) return;

            getRelatedContent(playback.playbackSpec, channelPlaybackSpec)
              .then((r2) => {
                if (aborted) return;

                dispatch({
                  payload: r2,
                  type: UiEventTypes.RELATED_CONTENT_UPDATE,
                });
              })
              // failure case should be rare and is non-fatal, but implementing some
              // sort of retry mechanism would be good
              .catch(() =>
                dispatch({
                  payload: null,
                  type: UiEventTypes.RELATED_CONTENT_UPDATE,
                })
              );
          }, r.refreshInterval);
        }
      })
      // failure case should be rare and is non-fatal, but implementing some
      // sort of retry mechanism would be good
      .catch(() =>
        dispatch({
          payload: null,
          type: UiEventTypes.RELATED_CONTENT_UPDATE,
        })
      );

    return () => {
      aborted = true;
      window.clearInterval(interval);
    };
  }, [dispatch, playback, getRelatedContent]);
};
