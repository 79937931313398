import styled from "@emotion/styled";
import React, { ReactNode, useEffect, useRef, useState } from "react";

const HoverScroll = styled.div<{
  animates: boolean;
  animationTime: number;
  clientWidth: number;
  overflowing: boolean;
  scrollWidth: number;
}>`
  width: 100%;
  left: 0%;
  white-space: nowrap;
  position: relative;
  ${({ animates, animationTime, clientWidth, overflowing, scrollWidth }) =>
    overflowing && animates
      ? `
    left: -${scrollWidth - clientWidth}px;
    width: ${scrollWidth}px;
    transition: ${animationTime}s linear all;
    text-overflow: unset;`
      : `
      text-overflow: ${overflowing ? "ellipsis" : "unset"};
      overflow: ${overflowing ? "hidden" : "unset"};
      width: 100%;
      left: 0%;
      transition: 0.3s ease-in all;
    `}
`;

export type TOverflowWithScroll = {
  children: ReactNode;
  runAnimation: boolean;
};

export const OverflowWithScroll = ({
  children,
  runAnimation,
}: TOverflowWithScroll): React.JSX.Element => {
  const hoverScrollRef = useRef<HTMLDivElement>(null);
  const [scrollWidth, setScrollWidth] = useState<number>(0);
  const [clientWidth, setClientWidth] = useState<number>(0);
  const [animationTime, setAnimationTime] = useState<number>(1);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(true);

  const timerRef = useRef<null | number>(null);

  // shorter overflow get a faster animation time, longer get a slower time
  const getAnimationTime = (_scrollWidth: number, _clientWidth: number) => {
    const fractionWidth = _scrollWidth / _clientWidth;

    return fractionWidth * 2 - 1;
  };

  useEffect(() => {
    if (runAnimation) {
      timerRef.current = window.setTimeout(() => {
        setShouldAnimate(true);
      }, 1100);
    } else if (typeof timerRef.current === "number") {
      setShouldAnimate(false);
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    return () => {
      if (typeof timerRef.current === "number") {
        clearTimeout(timerRef.current);
      }
    };
  }, [runAnimation]);

  useEffect(() => {
    const { current } = hoverScrollRef;
    if (!current) return;

    if (!scrollWidth) {
      setScrollWidth(current.scrollWidth);
      setClientWidth(current.clientWidth);
      setAnimationTime(
        getAnimationTime(current.scrollWidth, current.clientWidth)
      );
    }
    setIsOverflowing(current.scrollWidth > current.clientWidth);
  }, [hoverScrollRef, scrollWidth]);

  return (
    <HoverScroll
      animates={shouldAnimate}
      animationTime={animationTime}
      clientWidth={clientWidth}
      overflowing={isOverflowing}
      ref={hoverScrollRef}
      scrollWidth={scrollWidth}
    >
      {children}
    </HoverScroll>
  );
};
