import styled from "@emotion/styled";
import { ErrorMessage, Field, Form } from "formik";

import { BrandColor, BrandColorDark, TextColor } from "../misc/constants";

const fieldStyle = `
  border-radius: 0.5rem;
  background: ${BrandColorDark};
  border: 1px solid ${BrandColor};
  color: ${TextColor};
  padding: 0.5rem;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.3);
`;

export const StyledForm = styled(Form)`
  width: auto;
`;

export const StyledField = styled(Field)<{ margin: string }>`
  display: block;

  margin: ${({ margin }) => margin};

  ${fieldStyle}
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  width: auto;
  font-size: 0.8rem;
  margin: 0 0 1rem;
`;

export const StyledLoginBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledSelect = styled.select<{ margin: string }>`
  margin: ${({ margin }) => margin};
  cursor: pointer;

  ${fieldStyle}
`;
