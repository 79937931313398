const { default: Log, LogLevel } = require('../common/log');
const { AnalyticsTag } = require('../common/Constants');

/**
 * This static class provides utility methods.
 *
 * @class
 * @static
 * @memberof npaw
 */
var Util = {
  /**
   * Strip {protocol}:// and // from the begining of the string.
   *
   * @param {string} url
   * @returns {string} stripped url
   */
  stripProtocol: function (url) {
    var strippedUrl = url
    try {
      strippedUrl = url.replace(/^(.*?:\/\/|\/\/)/i, '')
    } catch (err) {
      Log.warn(AnalyticsTag, err)
    }
    return strippedUrl
  },

  /**
   * Parse Boolean (support strings)
   * @param booleanValue
   */
  parseBoolean: function (booleanValue) {
    if (typeof booleanValue === 'string' || booleanValue instanceof String) {
      return booleanValue.toLowerCase() === 'true'
    } else {
      return !!booleanValue
    }
  },

  /**
   * Return if element is in page
   *
   * @param {Object} node
   * @returns {boolean} returns true if element is in page
   */
  elementIsInPage: function (node) {
    try {
      return (node === document.body) ? false : document.body.contains(node)
    } catch (err) {
      Log.warn(AnalyticsTag, err)
    }
    return true
  },


  /**
   * Adds specific protocol. ie: [http://]nqs.nice264.com
   *
   * @param {string} url Domain of the service. Without protocol. ie: 'nqs.nice264.com'.
   * @param {boolean|null} [httpSecure]
   * If true will add https, if false http.
   * Otherwise will add //
   * @return Return the complete service URL.
   */
  addProtocol: function (url, httpSecure) {
    var serviceUrl = 'http://localhost/'
    try {
      serviceUrl = 'http://' + url
      if (httpSecure || (typeof window !== 'undefined' && window.location.protocol.indexOf('https') === 0)) {
        serviceUrl = 'https://' + url
      } else if (typeof window !== 'undefined' && window.location.protocol.indexOf('http') === 0) {
        serviceUrl = '//' + url
      }
    } catch (err) {
      Log.warn(AnalyticsTag, err)
    }
    return serviceUrl
  },

  /**
   * Return n if it isn't NaN, negative, Infinity, null or undefined.
   * In any other case, return def.
   *
   * @param {mixed} n Number to be parsed.
   * @param {number} def Number to return if n is not correct.
   */
  parseNumber: function (n, def) {
    return (!isNaN(n) &&
      n >= 0 &&
      n !== Infinity &&
      n !== -Infinity &&
      n !== null &&
      typeof n !== 'undefined')
      ? n
      : def
  },

  /**
   * This utility method will add most of the HTML5 common event listeners to the player sent.
   * This common events will be listened: 'canplay', 'buffering', 'waiting', 'ended', 'play',
   * 'playing', 'pause', 'resume', 'error', 'abort', 'seek', 'seeking', 'seeked', 'stalled',
   * 'dispose', 'loadeddata', 'loadstart'.
   *
   * Events will be reported as DEBUG level messages.
   *
   * @param {object|function} o Object to attach the events.
   * @param {array} [extraEvents]
   * An array of extra events to watch. ie:  ['timeupdate', 'progress'].
   * If the first item is null, no common events will be added.
   * @param {function} [report] Callback function called to report events.
   * Default calls Log.debug()
   */
  logAllEvents: function (o, extraEvents, report) {
    try {
      if (Log.logLevel <= LogLevel.DEBUG) {
        report = report || function (e) {
          Log.debug(AnalyticsTag, 'Event: ' + e.type)
        }

        var playerEvents = [
          'canplay', 'buffering', 'waiting', 'ended', 'play', 'playing',
          'pause', 'resume', 'error', 'abort', 'seek', 'seeking', 'seeked',
          'stalled', 'dispose', 'loadeddata', 'loadstart'
        ]
        if (extraEvents) {
          if (extraEvents[0] === null) {
            extraEvents.shift()
            playerEvents = extraEvents
          } else {
            playerEvents = playerEvents.concat(extraEvents)
          }
        }

        for (var i = 0; i < playerEvents.length; i++) {
          if (typeof o === 'function') {
            o.call(window, playerEvents[i], report)
          } else if (o.on) {
            o.on(playerEvents[i], report)
          } else if (o.addEventListener) {
            o.addEventListener(playerEvents[i], report)
          }
        }
      }
    } catch (err) {
      Log.error(AnalyticsTag, err)
    }
  },

  /**
   * Builds a string that represents the rendition.
   *
   * The returned string will have the following format: <width>x<height>@bitrate<suffix?>.
   * If either the width or height are < 1, only the bitrate will be returned.
   * If bitrate is < 1, only the dimensions will be returned.
   * If bitrate is < and there is no dimensions, a null will be returned.
   * The bitrate will also have one of the following suffixes dependin on its
   * magnitude: bps, Kbps, Mbps
   *
   * @param {any} width The width of the asset. If only 1 argument is sent, it will be treated
   * as bitrate.
   * @param {any} height The height of the asset.
   * @param {any} bitrate The indicated bitrate (in the manifest) of the asset.
   * @returns {string} A string with the following format: <width>x<height>@<bitrate><suffix>
   */
  buildRenditionString: function (width, height, bitrate) {
    if (arguments.length === 1) {
      bitrate = width
      width = null
      height = null
    }

    var ret = null
    if (width && height) {
      ret = width + 'x' + height
    }

    if (typeof bitrate === 'number' && !isNaN(bitrate) && bitrate >= 1) {
      if (ret) {
        ret += '@'
      } else {
        ret = ''
      }

      if (bitrate < 1e3) {
        ret += Math.round(bitrate) + 'bps'
      } else if (bitrate < 1e6) {
        bitrate = Math.round(bitrate / 1e3)
        ret += bitrate + 'Kbps'
      } else {
        bitrate = Math.round(bitrate / 1e4) / 1e2
        ret += bitrate + 'Mbps'
      }
    }
    return ret
  },

  /**
   * Returns a params dictionary with the error values.
   *
   * @param {String|Object} [code] Error Code, if an object is sent, it will be treated as params.
   * @param {String} [msg] Error Message
   * @param {Object} [metadata] Object defining error metadata
   * @returns {Object} Key:value params.
   */
  buildErrorParams: function (code, msg, metadata) {
    var params = {}
    if (typeof code === 'object' && code !== null) {
      params = code
    } else {
      params.errorCode = code || 'FAILURE'
      params.msg = msg || params.errorCode
      params.errorMetadata = metadata
    }
    return params
  },

  /**
   * Returns a boolean indicating if more than the desired % of pixels are on screen.
   *
   * @param {Object} [player] Player object
   * @param {Number} [screenPercent] Threshold % of pixels on screen to return true.
   * @returns {Boolean} Its on screen or not.
   */
  calculateAdViewability: function (player, screenPercent) {
    var isVisible = true
    if (typeof window !== 'undefined' && player && typeof player.getBoundingClientRect === 'function') {
      var box = player.getBoundingClientRect()
      // 100% outside the window cases
      if (box.top >= window.innerHeight || box.bottom <= 0 ||
        box.right <= 0 || box.left >= window.innerWidth) {
        isVisible = false
      } else {
        var pixels = Math.trunc(box.height * box.width)
        var x1 = box.top > 0 ? box.top : 0
        var x2 = box.bottom > window.innerHeight ? window.innerHeight : box.bottom
        var y1 = box.left > 0 ? box.left : 0
        var y2 = box.right > window.innerWidth ? window.innerWidth : box.right
        var inScreenPixels = (y2 - y1) * (x2 - x1)
        // more than screenPercent of pixels (by default 50%)
        isVisible = inScreenPixels * 100 > pixels * (screenPercent || 50)
      }
    }
    return isVisible
  },

  getMetricsFrom: function (op1, op2) {
    var metrics = op1 || op2
    for (var metric in metrics) {
      if (typeof metrics[metric] !== 'object' || !metrics[metric].value) {
        var temporal = {}
        // temporal.oper = 'SUM' //default one?
        temporal.value = metrics[metric]
        metrics[metric] = temporal
      }
    }
    return metrics
  },

  // The following methods replace core js functionallity to ensure compatibility in old versions.
  assign: require('./mixins/assign'),
  isArray: require('./mixins/isarray')
}

module.exports = Util
