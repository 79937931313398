import { TEXT_ELEMENT_ID, VIDEO_ELEMENT_ID } from "../shared-constants";
import { TDomElements } from "../shared-types";
import { getFromSessionStorage, SessionStorageKey } from "../storage";

const setupTextElement = (): HTMLDivElement => {
  const textElement = document.createElement("div");

  textElement.setAttribute("id", TEXT_ELEMENT_ID);

  return textElement;
};

const setupVideoElement = ({
  autoplay,
  v,
}: {
  autoplay: boolean | undefined;
  v?: HTMLVideoElement;
}): HTMLVideoElement => {
  const videoElement = v || document.createElement("video");

  videoElement.setAttribute("playsinline", "");

  if (autoplay || autoplay === undefined) {
    videoElement.setAttribute("autoplay", "");
  }

  videoElement.setAttribute("id", VIDEO_ELEMENT_ID);

  return videoElement;
};

export const setupDomElements = (options: {
  autoplay: boolean | undefined;
  v?: HTMLVideoElement;
}): TDomElements => {
  const videoElement = setupVideoElement(options);
  const textElement = setupTextElement();

  const previousVol = getFromSessionStorage(SessionStorageKey.VOLUME);
  const previousMute = getFromSessionStorage(SessionStorageKey.MUTED);

  if (typeof previousMute === "boolean") {
    videoElement.muted = previousMute;
  }

  if (typeof previousVol === "number") {
    videoElement.volume = previousVol;
  }

  return { textElement, videoElement };
};
