import { PlaybackRestrictions } from "@telia-company/tv.web-playback-sdk";

import { AdSession, MainPlayer, PlayerControls } from "../shared-types";
import { canSeek } from "./utils";

export type TCreateControlsOptions = {
  adSession?: AdSession;
  engine: MainPlayer;
  restrictions: PlaybackRestrictions;
};

export const createControls = ({
  adSession,
  engine,
  restrictions,
}: TCreateControlsOptions): PlayerControls => {
  if (adSession) {
    return adSession.createControls(engine, restrictions);
  }

  return {
    getDuration: engine.getDuration,
    getPosition: engine.getPosition,
    getSpeed: engine.getSpeed,
    getThumbnail: engine.getThumbnail,
    pause: () => {
      if (restrictions.noPause) return;

      engine.pause();
    },
    play: engine.play,
    seek: (destination) => {
      const currentPosition = engine.getPosition();

      if (typeof currentPosition !== "number") return;

      if (
        canSeek({
          currentPositionInSeconds: currentPosition,
          destination,
          restrictions,
        })
      ) {
        engine.seek(destination);
      }
    },
    setAudioTrack: engine.setAudioTrack,
    setSpeed: (speed) => {
      if (speed > 1 && restrictions.noFastForward) return;
      if (speed < 0 && restrictions.noRewind) return;
      // setting speed 0 is similar to pausing, prevent for noPause cases
      if (speed === 0 && restrictions.noPause) return;

      engine.setSpeed(speed);
    },
    setSubtitleTrack: engine.setSubtitleTrack,
    setSubtitleVisibility: engine.setSubtitleVisibility,
  };
};
