import { AdvertisementBreakTypes } from "../Constants";

export class AdvertisementInfo {
  readonly id: string;
  readonly name: string;
  readonly durationInSeconds: number;
  readonly breakType: AdvertisementBreakTypes;
  readonly campaignId?: string;
  readonly customId?: string;
  readonly goalId?: string;
  readonly sponsor: boolean;
  readonly positionInAdBreak?: number;
  readonly totalAdsInAdBreak?: number;

  constructor(args: AdvertisementInfo) {
    this.id = args.id;
    this.name = args.name;
    this.durationInSeconds = args.durationInSeconds;
    this.breakType = args.breakType;
    this.campaignId = args.campaignId;
    this.customId = args.customId;
    this.goalId = args.goalId;
    this.sponsor = args.sponsor;
    this.positionInAdBreak = args.positionInAdBreak;
    this.totalAdsInAdBreak = args.totalAdsInAdBreak;
  }
}
