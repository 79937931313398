var version = require('../version')
var Util = require('../util')
var RequestMethod = require('../constants/requestMethod')
const { default: Core } = require('../../core/Core')
const { default: CoreConstants } = require('../../core/utils/CoreConstants')

var PluginGettersMixin = {
  /**
   * Returns AccountCode
   *
   * @memberof npaw.Plugin.prototype
   */
  getAccountCode: function () {
    return Core.getInstance().getFastDataService().getAccountCode();
  },

  /**
   * Returns Username
   *
   * @memberof npaw.Plugin.prototype
   */
  getUsername: function (customOptions) {
    return this._getGenericOptionsValue('user.name', customOptions);
  },

  /**
   * Returns content's PluginVersion
   *
   * @memberof npaw.Plugin.prototype
   */
  getPluginVersion: function () {
    return Core.getPluginVersion();
  },

  /**
   * Returns requestNumber value, to prevent /data calls being cached
   *
   * @memberof npaw.Plugin.prototype
   */
  getRequestNumber: function () {
    return Math.random();
  },

  /** COMMONS GETTERS */

  getUserType: function (customOptions) {
    return this._getGenericOptionsValue('user.type', customOptions);
  },

  /**
   * Returns AnonymousUser
   *
   * @memberof npaw.Plugin.prototype
   */
  getAnonymousUser: function (customOptions) {
    return this._getGenericOptionsValue('user.anonymousId', customOptions);
  },

  /**
   * Get URL referer.
   *
   * @memberof npaw.Plugin.prototype
   */
  getReferer: function () {
    let ret = this.options.referer || '';
    if (!ret && typeof window !== 'undefined' && window.location) {
      ret = window.location.href;
    }
    return ret;
  },

  /**
   * Get URL referral.
   * Previous page url.
   * @memberof npaw.Plugin.prototype
   */
  getReferral: function () {
    let ret = this.options.referral || '';
    if (!ret && typeof document !== 'undefined') {
      ret = document.referrer;
    }
    return ret;
  },

  /**
   * Get Browser language
   *
   * @memberof npaw.Plugin.prototype
   */
  getLanguage: function () {
    let ret;
    if (typeof navigator !== 'undefined') {
      ret = navigator.language;
    }
    return ret;
  },

  /**
   * Get Screen resolution
   *
   * @memberof npaw.Plugin.prototype
   */
  getScreenResolution: function () {
    let ret;
    try {
      if (window && window.screen) {
        const devicePixelRatio = window.devicePixelRatio || 1;
        ret = (window.screen.width * devicePixelRatio) + 'x' + (window.screen.height * devicePixelRatio);
      }
    } catch (e) { }
    return ret;
  },

  /** Returns deviceInfo json
   *
   * @memberof npaw.Plugin.prototype
   */
  getDeviceInfo: function (customOptions) {
    const info = {};
    if (this.getDeviceCode(customOptions)) info.deviceCode = this.getDeviceCode(customOptions);
    if (this.getModel(customOptions)) info.model = this.getModel(customOptions);
    if (this.getBrand(customOptions)) info.brand = this.getBrand(customOptions);
    if (this.getDeviceType(customOptions)) info.deviceType = this.getDeviceType(customOptions);
    if (this.getDeviceName(customOptions)) info.deviceName = this.getDeviceName(customOptions);
    if (this.getOsName(customOptions)) info.osName = this.getOsName(customOptions);
    if (this.getOsVersion(customOptions)) info.osVersion = this.getOsVersion(customOptions);
    if (this.getBrowserName(customOptions)) info.browserName = this.getBrowserName(customOptions);
    if (this.getBrowserVersion(customOptions)) info.browserVersion = this.getBrowserVersion(customOptions);
    if (this.getBrowserType(customOptions)) info.browserType = this.getBrowserType(customOptions);
    if (this.getBrowserEngine(customOptions)) info.browserEngine = this.getBrowserEngine(customOptions);
    return info !== {} ? info : undefined;
  },

  /**
   * Returns DeviceCode
   *
   * @memberof npaw.Plugin.prototype
   */
  getDeviceCode: function (customOptions) {
    return this._getGenericOptionsValue('device.code', customOptions);
  },

  /**
   * Returns Device model
   *
   * @memberof npaw.Plugin.prototype
   */
  getModel: function (customOptions) {
    return this._getGenericOptionsValue('device.model', customOptions);
  },

  /**
   * Returns Device brand
   *
   * @memberof npaw.Plugin.prototype
   */
  getBrand: function (customOptions) {
    return this._getGenericOptionsValue('device.brand', customOptions);
  },

  /**
   * Returns Device type
   *
   * @memberof npaw.Plugin.prototype
   */
  getDeviceType: function (customOptions) {
    return this._getGenericOptionsValue('device.type', customOptions);
  },

  /**
   * Returns Device name
   *
   * @memberof npaw.Plugin.prototype
   */
  getDeviceName: function (customOptions) {
    return this._getGenericOptionsValue('device.name', customOptions);
  },

  /**
   * Returns Device OS name
   *
   * @memberof npaw.Plugin.prototype
   */
  getOsName: function (customOptions) {
    return this._getGenericOptionsValue('device.osName', customOptions);
  },

  /**
   * Returns Device OS Version
   *
   * @memberof npaw.Plugin.prototype
   */
  getOsVersion: function (customOptions) {
    let osVersion = this._getGenericOptionsValue('device.osVersion', customOptions);
    // If user didn't set device.osVersion and we are running on a Windows machine we check if the OS is Windows 11
    // If so, we return Windows 11 as the OS version so that it is correctly mapped on the backend
    if (typeof osVersion === 'undefined' && navigator && navigator.userAgentData && this.majorPlatformVersion) {
      if (navigator.userAgentData.platform === 'Windows' && this.majorPlatformVersion >= 13) {
        osVersion = '11';
      }
    }
    return osVersion;
  },

  /**
   * Returns Device browser name
   *
   * @memberof npaw.Plugin.prototype
   */
  getBrowserName: function (customOptions) {
    return this._getGenericOptionsValue('device.browserName', customOptions);
  },

  /**
   * Returns Device browser version
   *
   * @memberof npaw.Plugin.prototype
   */
  getBrowserVersion: function (customOptions) {
    return this._getGenericOptionsValue('device.browserVersion', customOptions);
  },

  /**
   * Returns Device browser type
   *
   * @memberof npaw.Plugin.prototype
   */
  getBrowserType: function (customOptions) {
    return this._getGenericOptionsValue('device.browserType', customOptions);
  },

  /**
   * Returns DeviceCode
   *
   * @memberof npaw.Plugin.prototype
   */
  getBrowserEngine: function (customOptions) {
    return this._getGenericOptionsValue('device.browserEngine', customOptions);
  },

  getIsBlocked: function (customOptions) {
    return this._getGenericOptionsValue('ad.blockerDetected', customOptions);
  },

  isMethodPostEnabled: function () {
    return this.options.method && this.options.method.toUpperCase() === RequestMethod.POST;
  },

  getDeviceUUID: function (customOptions) {
    let id;
    if (!customOptions['device.isAnonymous'] && customOptions['device.id']) {
      return customOptions['device.id'];
    }
    if (!this.options['device.isAnonymous']) {
      id = this.options['device.id'] || this.uuidGenerator.getKey();
    }
    return id;
  },

  /**
   * Returns content's PluginVersion
   *
   * @memberof npaw.Plugin.prototype
   */
  getLibVersion: function () {
    return Core.getPluginVersion();
  },

  getAppName: function (customOptions) {
    return this._getGenericOptionsValue('app.name', customOptions);
  },

  getAppReleaseVersion: function (customOptions) {
    return this._getGenericOptionsValue('app.releaseVersion', customOptions);
  },

  /**
   * Returns Ip
   *
   * @memberof npaw.Plugin.prototype
   */
  getIp: function (customOptions) {
    return this._getGenericOptionsValue('network.ip', customOptions);
  },

  /**
   * Returns Isp
   *
   * @memberof npaw.Plugin.prototype
   */
  getIsp: function (customOptions) {
    return this._getGenericOptionsValue('network.isp', customOptions);
  },

  /**
   * Returns ConnectionType
   *
   * @memberof npaw.Plugin.prototype
   */
  getConnectionType: function (customOptions) {
    return this._getGenericOptionsValue('network.connectionType', customOptions);
  },

  /** Returns EDID object
   *
   * @memberof npaw.Plugin.prototype
   */
  getEDID: function (customOptions) {
    const edid = this._getGenericOptionsValue('device.EDID', customOptions);
    return edid ? edid.toString() : undefined;
  },

  /**
   * Returns obfuscateIp option
   *
   * @memberof npaw.Plugin.prototype
   */
  getObfuscateIp: function (customOptions) {
    return this._getGenericOptionsValue('user.obfuscateIp', customOptions);
  },

  /**
   * Returns privacy protocol option
   *
   * @memberof npaw.Plugin.prototype
   */
  getPrivacyProtocol: function (customOptions) {
    let ret = this._getGenericOptionsValue('user.privacyProtocol', customOptions);
    if (typeof ret === 'string') {
      ret = ret.toLowerCase();
    }
    return ret === 'optin' || ret === 'optout' ? ret : undefined;
  },

  /**
   * Return custom dimensions
   *
   * @memberof npaw.Plugin.prototype
   */
  getCustomDimensions: function (customOptions) {
    const dim = this._getGenericOptionsValue('content.customDimensions', customOptions);
    return typeof dim === 'object' ? dim : undefined;
  },

  /**
   * Returns content's Extraparam1
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam1: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.1', customOptions);
  },

  /**
   * Returns content's Extraparam2
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam2: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.2', customOptions);
  },

  /**
   * Returns content's Extraparam3
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam3: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.3', customOptions);
  },

  /**
   * Returns content's Extraparam4
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam4: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.4', customOptions);
  },
  /**
   * Returns content's Extraparam5
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam5: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.5', customOptions);
  },

  /**
   * Returns content's Extraparam6
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam6: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.6', customOptions);
  },

  /**
   * Returns content's Extraparam7
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam7: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.7', customOptions);
  },

  /**
   * Returns content's Extraparam8
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam8: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.8', customOptions);
  },

  /**
   * Returns content's Extraparam9
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam9: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.9', customOptions);
  },

  /**
   * Returns content's Extraparam10
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam10: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.10', customOptions);
  },

  /**
   * Returns content's Extraparam11
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam11: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.11', customOptions);
  },

  /**
   * Returns content's Extraparam12
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam12: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.12', customOptions);
  },

  /**
   * Returns content's Extraparam13
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam13: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.13', customOptions);
  },

  /**
   * Returns content's Extraparam14
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam14: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.14', customOptions);
  },
  /**
   * Returns content's Extraparam15
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam15: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.15', customOptions);
  },

  /**
   * Returns content's Extraparam16
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam16: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.16', customOptions);
  },

  /**
   * Returns content's Extraparam17
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam17: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.17', customOptions);
  },

  /**
   * Returns content's Extraparam18
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam18: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.18', customOptions);
  },

  /**
   * Returns content's Extraparam19
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam19: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.19', customOptions);
  },

  /**
   * Returns content's Extraparam20
   *
   * @memberof npaw.Plugin.prototype
   */
  getExtraparam20: function (customOptions) {
    return this._getGenericOptionsValue('content.customDimension.20', customOptions);
  },

  /**
   * Returns parse Manifest Flag
   *
   * @memberof npaw.Plugin.prototype
   */
  isParseManifest: function (customOptions) {
    return this._getGenericOptionsValue('parse.manifest', customOptions);
  },

  /**
   * Returns parse CdnNode Flag
   *
   * @memberof npaw.Plugin.prototype
   */
  isParseCdnNode: function (customOptions) {
    return this._getGenericOptionsValue('parse.cdnNode', customOptions);
  },

  /**
   * Returns parse cdn switch Flag
   *
   * @memberof npaw.Plugin.prototype
   */
  isCdnSwitch: function (customOptions) {
    return this._getGenericOptionsValue('parse.cdnSwitchHeader', customOptions);
  },

  /**
   * Returns Cdn list
   *
   * @memberof npaw.Plugin.prototype
   */
  getParseCdnNodeList: function (customOptions) {
    return this._getGenericOptionsValue('parse.cdnNode.list', customOptions);
  },

  /**
   * Returns Cdn header name
   *
   * @memberof npaw.Plugin.prototype
   */
  getParseCdnNodeNameHeader: function (customOptions) {
    return this._getGenericOptionsValue('parse.cdnNameHeader', customOptions);
  },

  /**
   * Returns header node name
   *
   * @memberof npaw.Plugin.prototype
   */
  getParseNodeHeader: function (customOptions) {
    return this._getGenericOptionsValue('parse.cdnNodeHeader', customOptions);
  },

  /**
   * Return if is possible to overwrite adapters-json
   *
   * @returns {boolean}
   */
  canOverwriteAdapters: function (customOptions) {
    return Util.parseBoolean(this._getGenericOptionsValue('adapters.overwrite', customOptions));
  },

  /**
   * Get defined Adapter properties
   * @returns {object}
   */
  getAdaptersProperties: function (customOptions) {
    return this._getGenericOptionsValue('adapters.properties', customOptions);
  },

  /**
   * Returns the network connection type
   * This metric is only available for some mobile devices' browsers
   * Desktop browsers are not supported
   * @returns {string}
   */
  getNetworkConnectionType: function () {
    if (!this.options.enableExtraMetricCollection || !navigator || !navigator.connection) return;
    const exclude = ['wimax'];
    const value = navigator.connection.type;

    return exclude.includes(value) ? undefined : value;
  },

  /**
   * Returns the network cell type. 
   * @returns {string} A string that is either slow-2g, 2g, 3g, or 4g.
   */
  getNetworkCellType: function () {
    if (!this.options.enableExtraMetricCollection || !navigator || !navigator.connection) return;
    if (!this.getNetworkConnectionType() || this.getNetworkConnectionType() === 'wifi') return 'none';

    return navigator.connection.effectiveType;
  },

  /**
   * Network signal strength is not supported on web browsers
   * @returns 
   */
  getNetworkSignalStrength: function () {
    return undefined;
  },

  /**
   * Returns the CPU utilisation. Not supported for web browsers
   * @returns 
   */
  getCpuUsed: function () {
    return undefined;
  },

  /**
   * Returns the amount of the JS heap currently in use in Megabytes
   * @returns 
   */
  getMemoryUsed: function () {
    if (!this.options.enableExtraMetricCollection || !performance || !performance.memory) return;

    return performance.memory.usedJSHeapSize / 1024 / 1024;
  },

  /**
   * Returns the Cache storage utilisation. Not supported for web browsers
   * @returns 
   */
  getCacheStorageUsed: function () {
    if (!this.options.enableExtraMetricCollection) return;
    let size = 0;
    if (typeof localStorage !== 'undefined' && localStorage) {
      for (const _x in localStorage) {
        if (!localStorage.hasOwnProperty(_x)) {
          continue;
        }
        let _xLen = ((localStorage[_x].length + _x.length) * 2);
        size += _xLen;
      };
    }
    if (typeof sessionStorage !== 'undefined' && sessionStorage) {
      for (const _x in sessionStorage) {
        if (!sessionStorage.hasOwnProperty(_x)) {
          continue;
        }
        const _xLen = ((sessionStorage[_x].length + _x.length) * 2);
        size += _xLen;
      };
    }
    return size != 0 ? size : undefined;
  },

  /**
   * Returns the quantity of traffic transferred
   * @returns 
   */
  getTransferredTraffic: function () {
    if (!this.options.enableExtraMetricCollection) return;
    return Core.getInstance().getCommonVariable(CoreConstants.Products.BALANCER, CoreConstants.BalancerVariables.BALANCER_TRAFFIC);
  },

  /**
   * Returns the bandwidth of the traffic transferred
   * @returns 
   */
  getImprovedBandwidth: function () {
    if (!this.options.enableExtraMetricCollection) return;
    return Core.getInstance().getCommonVariable(CoreConstants.Products.BALANCER, CoreConstants.BalancerVariables.BALANCER_BITRATE);
  },

  /**
   * Check if AppAnalytics has auto begin before plugin creation
   * @returns {boolean}
   */
  isAppAnalyticsAutoBegin: function (customOptions) {
    return Util.parseBoolean(this._getGenericOptionsValue('check.appAnalytics.autoBegin', customOptions));
  },

  /**
   * Is AppAnalytics enabled
   * @returns {boolean}
   */
  isAppAnalyticsEnabled: function (customOptions) {
    return Util.parseBoolean(this._getGenericOptionsValue('check.appAnalytics.enabled', customOptions));
  },

  /**
   * Is VideoAnalytics enabled
   * @returns {boolean}
   */
  isVideoAnalyticsEnabled: function (customOptions) {
    return Util.parseBoolean(this._getGenericOptionsValue('check.videoAnalytics.enabled', customOptions));
  },

  /**
   * Is Configuration Refresh from LMA
   * @returns {boolean}
   */
  isRefreshLMAConfigurationEnabled: function (customOptions) {
    return Util.parseBoolean(this._getGenericOptionsValue('lma.refresh.configuration.enabled', customOptions));
  },

  /**
   * Is Plugin Logs enabled (to report QA Tools additional info)
   * @returns {boolean|*}
   */
  isPluginLogsEnabled: function (customOptions) {
    return Util.parseBoolean(this._getGenericOptionsValue('debug.pluginLogs.enabled', customOptions));
  },

  /**
   * Is Player Event Logs enabled (to report QA Tools additional info)
   * @returns {boolean|*}
   */
  isPlayerLogsEnabled: function (customOptions) {
    return Util.parseBoolean(this._getGenericOptionsValue('debug.playerLogs.enabled', customOptions));
  },

  /**
   * Time to refresh configuration object from LMA
   * @returns {boolean}
   */
  refreshLMAConfigurationSeconds: function (customOptions) {
    return this._getGenericOptionsValue('lma.refresh.configuration.seconds', customOptions);
  },

  /**
   * Return if playhead monitor is enabled
   * @returns {boolean}
   */
  getPlayheadMonitorEnabled: function (customOptions) {
    return this._getGenericOptionsValue('playhead.monitor', customOptions);
  },

  /**
   * Return if readyState monitor/check method are enabled
   * @returns {boolean}
   */
  getReadyStateMonitorEnabled: function (customOptions) {
    return this._getGenericOptionsValue('readyState.monitor', customOptions);
  },

  getPageName: function () {
    return this.options['content.title'];
  },

  /**
   * Method to get value from options (prioritizing customOptions if exists)
   * @param {string} key
   * @param {Object} customOptions
   */
  _getGenericOptionsValue: function (key, customOptions) {
    if (customOptions && customOptions[key]) {
      return customOptions[key];
    } else {
      return this.options[key];
    }
  }
};

module.exports = PluginGettersMixin;
