import {
  TPlaybackService,
  TPlaybackSpec,
  VideoIdType,
  WatchMode,
} from "@telia-company/tv.web-player-shared";
import { Dispatch, useEffect, useState } from "react";

import { UiEventTypes } from "../constants";
import { noop } from "../utils";
import { TAction } from "./ui-state-reducer.hook";

export type TUseMetadataOptions = {
  currentTime: null | number;
  dispatch: Dispatch<TAction>;
  liveSeekEnabled: boolean;
  playbackService: TPlaybackService;
  playbackSpec: TPlaybackSpec;
  seekRange?: {
    end: number;
    start: number;
  };
  shouldFetchMetadata: boolean;
};

const getAdjustedTimestamp = (
  liveSeekEnabled: boolean,
  currentTime: null | number,
  seekRange?: { end: number; start: number }
) => {
  if (!liveSeekEnabled || !currentTime || !seekRange) return Date.now();
  return Date.now() - (seekRange.end - currentTime) * 1000;
};

export const useMetadata = ({
  currentTime,
  dispatch,
  liveSeekEnabled,
  playbackService,
  playbackSpec,
  seekRange,
  shouldFetchMetadata,
}: TUseMetadataOptions): void => {
  const [calculateNewTimestamp, setCalculateNewTimestamp] = useState(false);
  const [adjustedTimestamp, setAdjustedTimestamp] = useState<null | number>(
    null
  );

  useEffect(() => {
    if (!calculateNewTimestamp) return;

    setCalculateNewTimestamp(false);

    setAdjustedTimestamp(
      getAdjustedTimestamp(liveSeekEnabled, currentTime, seekRange)
    );
  }, [calculateNewTimestamp, liveSeekEnabled, currentTime, seekRange]);

  useEffect(() => {
    if (!shouldFetchMetadata) return noop;

    let aborted = false;

    if (!adjustedTimestamp && playbackSpec.watchMode === WatchMode.LIVE) {
      setCalculateNewTimestamp(true);

      // First calculate a timestamp once before allowing the metadata
      // fetch to happen.
      return noop;
    }

    if (
      playbackSpec.videoIdType === VideoIdType.CHANNEL &&
      seekRange?.end === 0 &&
      liveSeekEnabled
    ) {
      // make sure we have a seekrange before fetching metadata if we have a seekable live stream
      return noop;
    }

    dispatch({
      payload: {},
      type: UiEventTypes.FETCHING_METADATA,
    });

    playbackService
      .getMetadata(playbackSpec, adjustedTimestamp)
      .then((metadata) => {
        if (aborted) return;

        dispatch({
          payload: {
            metadata,
          },
          type: UiEventTypes.METADATA,
        });

        setAdjustedTimestamp(null);
      }) // TODO retry logic here
      .catch(() => {
        dispatch({
          payload: {},
          type: UiEventTypes.FETCHING_METADATA_FAILED,
        });
      });

    return () => {
      dispatch({
        payload: {},
        type: UiEventTypes.FETCHING_METADATA_FAILED,
      });

      aborted = true;
    };
  }, [
    dispatch,
    playbackSpec,
    shouldFetchMetadata,
    playbackService,
    adjustedTimestamp,
    seekRange,
    liveSeekEnabled,
  ]);
};
