import Event from '../../analytics/constants/event';
import DiagnosticLogs, { LogColors } from '../utils/DiagnosticLogs';
export default class DiagnosticNQSEvent {
    constructor(eventType, params) {
        this.eventType = eventType;
        this.params = params;
        this.columns = ['Event', 'Params'];
    }
    generateRow() {
        return this.eventType.padEnd(15) + this.getParamsString().padEnd(15);
    }
    getParamsString() {
        if (!this.params)
            return DiagnosticLogs.colorizeText('Data not defined', LogColors.YELLOW);
        const paramStringContent = [];
        switch (this.eventType) {
            case Event.INIT:
            case Event.START:
                // Video Analytics metrics
                if (this.params.player)
                    paramStringContent.push(`Player: ${this.params.player}`);
                if (this.params.pluginVersion)
                    paramStringContent.push(`Plugin Version: ${this.params.pluginVersion}`);
                if (this.params.playerStartupTime)
                    paramStringContent.push(`Player Startup Time: ${this.params.playerStartupTime} ms`);
                // Ads metrics
                if (this.params.playhead)
                    paramStringContent.push(`Playhead: ${this.params.playhead}`);
                if (this.params.position)
                    paramStringContent.push(`Position: ${this.params.position}`);
                if (this.params.breakNumber)
                    paramStringContent.push(`Break Number: ${this.params.breakNumber}`);
                if (this.params.adAdapterVersion)
                    paramStringContent.push(`Ad Adapter Version: ${this.params.adAdapterVersion}`);
                if (this.params.adDuration)
                    paramStringContent.push(`Ad Duration: ${this.params.adDuration}`);
                break;
            case Event.JOIN:
                // Video Analytics metrics
                if (this.params.rendition)
                    paramStringContent.push(`Rendition: ${this.params.rendition}`);
                if (this.params.pingTime)
                    paramStringContent.push(`Ping Time: ${this.params.pingTime} s`);
                // Ads metrics
                if (this.params.playhead)
                    paramStringContent.push(`Playhead: ${this.params.playhead}`);
                if (this.params.position)
                    paramStringContent.push(`Position: ${this.params.position}`);
                if (this.params.breakNumber)
                    paramStringContent.push(`Break Number: ${this.params.breakNumber}`);
                if (this.params.adJoinDuration)
                    paramStringContent.push(`Ad Join Duration: ${this.params.adJoinDuration}`);
                if (this.params.adPlayhead)
                    paramStringContent.push(`Ad Playhead: ${this.params.adPlayhead}`);
                break;
            case Event.JOIN_TIME:
                if (this.params.joinDuration)
                    paramStringContent.push(`Join Time: ${this.params.joinDuration} ms`);
                if (this.params.bitrate)
                    paramStringContent.push(`Bitrate: ${(this.params.bitrate / 10e5).toFixed(2)} MB/s`);
                break;
            case Event.PAUSE:
                if (this.params.playhead)
                    paramStringContent.push(`Playhead: ${this.params.playhead}`);
                break;
            case Event.RESUME:
                if (this.params.playhead)
                    paramStringContent.push(`Playhead: ${this.params.playhead}`);
                if (this.params.pauseDuration)
                    paramStringContent.push(`Pause Duration: ${this.params.pauseDuration} ms`);
                break;
            case Event.SEEK_BEGIN:
                break;
            case Event.SEEK_END:
                if (this.params.seekDuration)
                    paramStringContent.push(`Seek Duration: ${this.params.seekDuration} ms`);
                break;
            case Event.BUFFER_BEGIN:
                break;
            case Event.BUFFER_END:
                if (this.params.bufferDuration)
                    paramStringContent.push(`Buffer Duration: ${this.params.bufferDuration} ms`);
                break;
            case Event.ERROR:
                break;
            case Event.STOP:
                // Video Analytics metrics
                if (this.params.playhead)
                    paramStringContent.push(`Playhead: ${this.params.playhead}`);
                if (this.params.bitrate && this.params.bitrate !== -1) {
                    paramStringContent.push(`Bitrate: ${(this.params.bitrate / 10e5).toFixed(2)} MB/s`);
                }
                else if (this.params.bitrate) {
                    paramStringContent.push(`Bitrate: ${this.params.bitrate}`);
                }
                // Ads metrics
                if (this.params.position)
                    paramStringContent.push(`Position: ${this.params.position}`);
                if (this.params.breakNumber)
                    paramStringContent.push(`Break Number: ${this.params.breakNumber}`);
                if (this.params.adPlayhead)
                    paramStringContent.push(`Ad Playhead: ${this.params.adPlayhead}`);
                if (this.params.adJoinDuration)
                    paramStringContent.push(`Ad Join Duration: ${this.params.adJoinDuration}`);
                if (this.params.adViewedDuration)
                    paramStringContent.push(`Ad Viewed Duration: ${this.params.adViewedDuration}`);
                if (this.params.adTotalDuration)
                    paramStringContent.push(`Ad Total Duration: ${this.params.adTotalDuration}`);
                if (this.params.adBitrate)
                    paramStringContent.push(`Ad Bitrate: ${this.params.adBitrate}`);
                break;
            case Event.VIDEO_EVENT:
                break;
            case Event.QUARTILE:
                if (this.params.position)
                    paramStringContent.push(`Position: ${this.params.position}`);
                if (this.params.breakNumber)
                    paramStringContent.push(`Break Number: ${this.params.breakNumber}`);
                if (this.params.quartile)
                    paramStringContent.push(`Quartile: ${this.params.quartile}`);
                if (this.params.adViewedDuration)
                    paramStringContent.push(`Ad Viewed Duration: ${this.params.adViewedDuration}`);
                break;
            case Event.PODSTART:
                if (this.params.position)
                    paramStringContent.push(`Position: ${this.params.position}`);
                if (this.params.breakNumber)
                    paramStringContent.push(`Break Number: ${this.params.breakNumber}`);
                if (this.params.adInsertionType)
                    paramStringContent.push(`Ad Insertion Type: ${this.params.adInsertionType}`);
                break;
            case Event.PODSTOP:
                if (this.params.position)
                    paramStringContent.push(`Position: ${this.params.position}`);
                if (this.params.breakNumber)
                    paramStringContent.push(`Break Number: ${this.params.breakNumber}`);
                break;
            case Event.CLICK:
                break;
            case Event.MANIFEST:
                break;
            default:
                break;
        }
        return paramStringContent.length === 0
            ? DiagnosticLogs.colorizeText('Data not defined', LogColors.YELLOW)
            : paramStringContent.join(' | ');
    }
}
