/**
 * List of will-send events.
 *
 * @memberof npaw.Plugin
 * @enum
 * @event
 */
var WillSendLog = {
  WILL_SEND_LOG_PLUGIN_INIT: 'will-send-log-plugin-init',
  WILL_SEND_LOG_PLUGIN_SET_OPTIONS: 'will-send-log-plugin-set-options',
  WILL_SEND_LOG_PLUGIN_RECEIVE_DATA: 'will-send-log-plugin-receive-data',

  WILL_SEND_LOG_INFINITY_BEGIN: 'will-send-log-infinity-begin',
  WILL_SEND_LOG_INFINITY_START: 'will-send-log-infinity-start',
  WILL_SEND_LOG_INFINITY_EVENT: 'will-send-log-infinity-event',
  WILL_SEND_LOG_INFINITY_NAV: 'will-send-log-infinity-nav',
  WILL_SEND_LOG_INFINITY_NEW_SESSION: 'will-send-log-infinity-new-session',
}
module.exports = WillSendLog
