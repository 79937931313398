import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import { PlaybackEventTypes } from "../Constants";
import { TLanguageCode } from "./Types";

export type TAudioTrackChangedEventPayload = {
  language: keyof TLanguageCode;
  type: TAudioTrackType;
};

export type TAudioTrackType = "main" | "commentary" | "alternative";

export class AudioTrackChangedEvent extends PlaybackEventBase<
  PlaybackEventTypes.AUDIO_TRACK_CHANGED,
  TAudioTrackChangedEventPayload
> {
  constructor(payload: TAudioTrackChangedEventPayload) {
    super(PlaybackEventTypes.AUDIO_TRACK_CHANGED, payload);
  }
}
