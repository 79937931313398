import { ChannelsMetadata, Icons, Program } from "../Types";
import { ChannelIconMapper, ChannelProgramMapper } from "./CommonMappers";

import IChannels = GraphTypes.IChannels;

export const ChannelsMetadataMapper = (response: {
  data: {
    channels: IChannels;
  };
}): Array<ChannelsMetadata> => {
  if (!(response && response.data && response.data.channels)) {
    throw new Error(
      "MetadataService::ChannelMetadataMapper: Missing data or channel!"
    );
  }

  const channelItems = response.data.channels.channelItems;

  const channelsMetadata: Array<ChannelsMetadata> = [];

  channelItems.map((channel) => {
    const icons: Icons = ChannelIconMapper(channel);
    const programs: Array<Program> = ChannelProgramMapper(channel);
    const mapped: ChannelsMetadata = {
      id: channel.id,
      name: channel.name,
      playback: channel.playback,
      icons,
      programs,
    };
    channelsMetadata.push(mapped);
  });

  return channelsMetadata;
};
