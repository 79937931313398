import {
  FetchRequestOptions,
  FetchRequestResponse,
} from "./FetchRequestFactory";
import { NetworkError } from "../../base";
export class FetchRequestBase {
  public lastResponse: FetchRequestResponse | undefined;
  public manuallyAborted: boolean = false;
  public options: FetchRequestOptions;
  public request: RequestInfo;

  constructor(request: RequestInfo, options: FetchRequestOptions) {
    this.options = options;
    this.request = request;
  }

  public abort() {
    this.manuallyAborted = true;
  }

  async send(requestBody?: any): Promise<FetchRequestResponse | NetworkError> {
    return new FetchRequestResponse("", {});
  }
}
