import { TSeconds, TTimeline } from "@telia-company/tv.web-player-shared";
import { useEffect, useState } from "react";

import { currentTimeWithinTimelineBlock } from "../utils";

export type TUseActionButtons = {
  currentTime: null | number;
  timeline: TTimeline;
};

export type TTimelineActionButton = {
  end: TSeconds;
  start: TSeconds;
  type: string;
};

export const useActionButtons = ({
  currentTime,
  timeline,
}: TUseActionButtons) => {
  const [actionButton, setActionButton] =
    useState<null | TTimelineActionButton>(null);
  useEffect(() => {
    if (typeof currentTime !== "number") return;
    const activeTimelineActions: TTimelineActionButton[] = [];
    if (
      timeline?.openingCredits &&
      currentTimeWithinTimelineBlock(timeline.openingCredits, currentTime)
    ) {
      activeTimelineActions.push(timeline.openingCredits);
    }
    if (
      timeline?.recap &&
      currentTimeWithinTimelineBlock(timeline.recap, currentTime)
    ) {
      activeTimelineActions.push(timeline.recap);
    }
    if (activeTimelineActions.length > 0) {
      if (activeTimelineActions.length > 1) {
        activeTimelineActions.sort((a, b) => b.start - a.start);
      }
      setActionButton(activeTimelineActions[0]);
    } else {
      setActionButton(null);
    }
  }, [currentTime, timeline]);

  return {
    actionButton,
  };
};
