import { guid } from "../../../../../utils";

export interface AdvertisementAuxiliaryData {}

export type TAdvertisementArgs<
  TAdvertisementAuxiliaryData extends AdvertisementAuxiliaryData
> = {
  id?: string;
  name?: string;
  auxiliaryData?: TAdvertisementAuxiliaryData;
  clickThroughUrl?: string;
  correlationId: string;
  durationInSeconds: number;
  indexInAdvertisementBreak?: number;
};

export class Advertisement<
  TAdvertisementAuxiliaryData extends AdvertisementAuxiliaryData
> {
  public readonly type = "Advertisement";

  public id: string;
  public name: string;
  public auxiliaryData: TAdvertisementAuxiliaryData;
  public clickThroughUrl?: string;
  public correlationId: string;
  public durationInSeconds: number;
  public indexInAdvertisementBreak?: number;

  constructor(advertisement?: TAdvertisementArgs<TAdvertisementAuxiliaryData>) {
    this.id = advertisement?.id ?? guid();
    this.name = advertisement?.name ?? "Unknown";
    this.auxiliaryData =
      advertisement?.auxiliaryData ?? ({} as TAdvertisementAuxiliaryData);
    this.clickThroughUrl = advertisement?.clickThroughUrl;
    this.correlationId = advertisement?.correlationId ?? "Unknown";
    this.durationInSeconds = advertisement?.durationInSeconds ?? 0;
    this.indexInAdvertisementBreak = advertisement?.indexInAdvertisementBreak;
  }
}
