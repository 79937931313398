import { TCallback, TVideoEventHandlerOptions } from "../shared-types";

export const detectGapStall: TCallback = ({
  callbacks,
  videoElement,
}: TVideoEventHandlerOptions) => {
  /**
   * Detection for video stalls triggered by very tiny gaps coinciding with
   * HLS discontinuities.
   *
   * The engine (e.g. Shaka) would normally jump gaps, but ignores tiny
   * ones (~30-50ms) and leaves it up to the decoder to handle them. In some
   * cases, this can cause pipeline stalls in the decoder.
   *
   * The effect without the recovery is a stalled stream with infinite
   * spinner.
   */

  // Check that we have a gap (two time ranges)
  if (videoElement.buffered.length > 1) {
    // Check that the gap isn't created by a seek, the gap should be upcoming
    if (videoElement.currentTime > videoElement.buffered.start(1)) return;

    const diff = videoElement.buffered.start(1) - videoElement.buffered.end(0);

    // Check if the diff is large enough to be handled by the player engine automatically
    if (diff > 0.1) return;

    // Jump the size of the gap + 10ms
    videoElement.currentTime += diff + 0.01;

    callbacks.onCustomTrackingEvent({
      name: "PIPELINE_STALL_GAP_RECOVERY",
    });
  }
};
