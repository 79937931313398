export class BaseComponent {
  readonly name: string;
  protected configuration?: any = {};

  constructor(componentName: string) {
    this.name = componentName;
  }

  public initialize(configuration?: any) {
    throw new Error(
      `Error class:BaseComponent[initialize] : configuration is a required method!`
    );
  }

  /**
   * throwError will overridden when the component is registered with a Manager
   * @param {Error} error
   */
  public throwError(error: Error) {
    throw error;
  }

  public async reset(): Promise<void> {
    throw new Error(
      `Error class:BaseComponent[reset] : reset is a required method!`
    );
  }

  public async destroy(): Promise<void> {
    throw new Error(
      `Error class:BaseComponent[destroy] : destroy is a required`
    );
  }
}
