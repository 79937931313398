var YBRequest = require('../../../request')
var Parser = require('./parser')
var TransportFormat = require('../../../../constants/transportFormat')
const { AnalyticsTag } = require('../../../../../common/Constants');
const { default: Log } = require('../../../../../common/log');

var HlsParser = Parser.extend(
  /** @lends npaw.HlsParser.prototype */
  {
    /**
     * Starts the HLS parsing from the given resource. The first (outside) call should set the
     * parentResource to null.
     *
     * @param {string} resource Either the resource url or the manifest body.
     * @param {string} parentResource Parent resource in case relative paths are sent.
     */
    parse: function (resource, lastManifest, parentResource) {
      parentResource = parentResource || ''
      var matches = null
      try {
        matches = /((\S*?)(\.m3u8|\.m3u|\.ts|\.m4s|\.mp4|\.cmfv|\.m4a)((\?|;)\S*|\n|\r|$))/i.exec(resource.replaceAll(',URI=', '\n').replaceAll('"', '').replaceAll(',', '\n'))
      } catch (err) {
        Log.warn(AnalyticsTag, 'Parse HLS Regex failed', err)
        this.done()
      }

      if (matches !== null) { // get first line ending in .m3u8, .m3u, .mp4, .cmfv or .ts
        var res = matches[1].trim()
        var prevParent = ''
        var index = parentResource.lastIndexOf('/')
        if (res.indexOf('http') !== 0 && index !== -1) {
          // If does not start with http, add parentResource relative route.
          if (res[0] === '/' && res[1] !== '/') {
            // if its a relative route not using the same path, but the same domain
            var ind = parentResource.indexOf('/') + 1
            ind += parentResource.substr(ind, parentResource.length).indexOf('/') + 1 // Second '/'
            ind += parentResource.substr(ind, parentResource.length).indexOf('/') // Third '/'
            res = parentResource.slice(0, ind) + res
          } else {
            // if its sharing the (omitted) path
            res = parentResource.slice(0, index) + '/' + res
          }
          prevParent = parentResource.slice(0, index) + '/'
        }

        if (matches[3] === '.m3u8' || matches[3] === '.m3u') { // It is m3u8 or m3u...
          if (!lastManifest) {
            var request = new YBRequest(res, null, null, { cache: true, requestHeaders: this._headers })

            request.on(YBRequest.Event.SUCCESS, function (resp) {
              this._parseWithManifest(resp, prevParent, matches)
            }.bind(this))

            request.on(YBRequest.Event.ERROR, function (resp) {
              this.done()
            }.bind(this))

            request.send()
          } else {
            this._parseWithManifest(lastManifest, prevParent, matches)
          }
        } else { // It is mp4 or ts...
          switch (matches[3]) {
            case '.mp4':
            case '.m4s':
            case '.m4a':
              this._transportFormat = TransportFormat.MP4
              break
            case '.ts':
              this._transportFormat = TransportFormat.MPEG2
              break
            case '.cmfv':
              this._transportFormat = TransportFormat.CMF
          }
          this._realResource = res
          this.done()
        }
      } else {
        this.done()
      }
    },

    _parseWithManifest: function (resp, prevParent, matches) {
      this._lastManifest = resp
      this.parse(resp.getResponseText(), null, prevParent + matches[2])
    },

    shouldExecute: function (lastManifest) {
      var ret = true
      if (lastManifest) {
        var response = lastManifest.getResponseText()
        ret = response.indexOf('#EXTM3U') !== -1
      }
      return ret
    }
  }
)

module.exports = HlsParser
