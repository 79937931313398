import { useEffect, useState } from "react";

import { TPlaybackType } from "../../@types/types";
import { TSeekRange } from "../../hooks/ui-state-reducer.hook";
import { shouldConvertCurrentTime } from "../../utils";

export type TUseProgressOptions = {
  currentTime: null | number;
  endTime: number | undefined;
  liveSeekEnabled: boolean;
  playbackType: null | TPlaybackType;
  seekRange?: TSeekRange;
  startTime: number | undefined;
  vodDuration: null | number;
};

export const useProgress = ({
  currentTime,
  endTime,
  liveSeekEnabled,
  playbackType,
  seekRange,
  startTime,
  vodDuration,
}: TUseProgressOptions): {
  progressPercent: null | number;
  seekWindowPercent: null | number;
} => {
  const [progressPercent, setProgressPercent] = useState<null | number>(null);
  const [seekWindowPercent, setSeekWindowPercent] = useState<null | number>(
    null
  );

  useEffect(() => {
    if (!currentTime) return;

    // Vod/Catchup Case
    if (playbackType === "vod" && vodDuration) {
      setSeekWindowPercent(1);
      setProgressPercent(currentTime / vodDuration);

      return;
    }

    // not used right now since we want startover-like behaviour for channels with dvr windows
    // Channel with Seek Case
    /* if (liveSeekEnabled && playbackType === "linear-channel" && seekRange) {
      const clampedCurrentTime =
        currentTime > seekRange.start ? currentTime : seekRange.start;
      setSeekWindowPercent(1);

      const dvrWindowDuration = seekRange.end - seekRange.start;
      const positionInDvrWindow = clampedCurrentTime - seekRange.start;

      setProgressPercent(positionInDvrWindow / dvrWindowDuration);

      return;
    } */

    // Channel without Seek Case
    if (
      !liveSeekEnabled &&
      playbackType === "linear-channel" &&
      startTime &&
      endTime
    ) {
      const now = Date.now();

      const elapsedMs = now - startTime;
      const lengthMs = endTime - startTime;

      const progress = Math.min(1, elapsedMs / lengthMs);

      setProgressPercent(progress);

      return;
    }

    // Startover and Live Event Case
    if (
      (liveSeekEnabled ||
        playbackType === "live-event" ||
        playbackType === "startover") &&
      seekRange
    ) {
      const { end, start } = seekRange;
      const endTimeS = typeof endTime === "number" ? endTime / 1000 : null;
      const startTimeS =
        typeof startTime === "number" ? startTime / 1000 : null;

      const seekRangeDuration = end - start;

      // const availabilityStartTime = playheadTime - currentTime;
      // const epochStartTime = (availabilityStartTime + start) * 1000;
      // const epochEndTime = (availabilityStartTime + end) * 1000;
      //
      // const progress = ((playheadTime * 1000) - epochStartTime) / (epochEndTime - epochStartTime);

      /**
       * Hur det funkar nu:
       *
       * range:    17:59 ---------------------------- 19:07
       * seekable: 17:59   ----------------------     19:07
       * visual:   18:00   -------------o--------     19:00
       *
       * Förslag #2
       *
       * range:    17:59 ---------------------------- 19:07
       * seekable: 18:00   ----------------------     19:00
       * visual:   18:00   -------------o--------     19:00
       *
       * #2 skulle innebära att "Dot" är låst på 0 progress och 60 progress i början och i slutet av startover när videon spelar utanför det visuella fönstret. Man kan fortfarande titta på hela buffern om man inte seekar vid start, och om man låter den ticka på när den slagit i högersidan av den visuella progress-baren
       * när man väl seekat sig in i det visuella fönstret kan man aldrig seeka sig tillbaks till början av seekableRange (17:59), för vi cappar den till epgStart (18:00)
       *
       * Förslag #3, basera allt på seekable range:
       *
       * range:    17:59 ---------------------------- 19:07
       * seekable: 17:59 ---------------------------- 19:07
       * visual:   17:59 ---------------o------------ 19:07
       *
       * Då får "endTime" vara EPG end time tills ast+seekableRange.end blir en större siffra. Det kommer nog dock leda till att Dot hamnar snett när man seekar inom seekable, då den blir fel relativt epg endTime
       *
       */

      // Check if we get epoch current time or 0 based current time,
      const actualCurrentTime = shouldConvertCurrentTime(currentTime)
        ? currentTime - start
        : currentTime;

      let elapsed;
      // if liveSeekEnabled we need to subtract part of seekRange that is outside of current programme
      if (liveSeekEnabled) {
        elapsed = Math.min(
          Math.max(
            0,
            (actualCurrentTime - seekRange.start) / seekRangeDuration
          ),
          1
        );
      } else {
        elapsed = Math.min(
          Math.max(0, actualCurrentTime / seekRangeDuration),
          1
        );
      }

      const seekWindowRelativeSize =
        endTimeS && startTimeS
          ? seekRangeDuration / (endTimeS - startTimeS)
          : 1;

      const epgWindowRelativeSize =
        endTimeS && startTimeS
          ? (Date.now() / 1000 - startTimeS) / (endTimeS - startTimeS)
          : 1;

      const liveWindowIsPastEnd = seekWindowRelativeSize > 1;

      if (playbackType === "startover" || liveSeekEnabled) {
        setSeekWindowPercent(liveWindowIsPastEnd ? 1 : seekWindowRelativeSize);
      }

      if (playbackType === "startover" || liveSeekEnabled) {
        // When seek is enabled, use the elapsed time relative to seek range
        setProgressPercent(elapsed);
      } else {
        // When seek is disabled, calculate progress relative to epg start time
        setProgressPercent(Math.min(1, Math.max(0, epgWindowRelativeSize)));
      }
    }
  }, [
    startTime,
    endTime,
    currentTime,
    playbackType,
    seekRange,
    liveSeekEnabled,
    vodDuration,
  ]);

  return {
    progressPercent,
    seekWindowPercent,
  };
};
