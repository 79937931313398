import styled from "@emotion/styled";
import React, {
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { noop } from "../../../../utils";
import { usePlayerState } from "../../../context-provider";
import { OverlayInnerWrapper, OverlayWrapper } from "../../elements";
import { TRelatedContentImplementation } from "../types/types";
import { getEpisodesForCurrentSeason, listPadding } from "../util";
import { Panel, TPanelProps } from "./panel";
import {
  newPanelDataAction,
  nextPageAction,
  PanelStateContext,
  PanelStateDispatchContext,
  previousPageAction,
  renderTickAction,
  updateIndexState,
  usePanelDispatch,
  usePanelState,
  usePanelStateReducer,
} from "./state.reducer";

const FlexContainer = styled.div`
  pointer-events: all;
  display: grid;
  align-items: flex-end;
  flex-direction: column;
`;

export type TPanelOverlayHeader<
  THeaderProps extends React.JSX.IntrinsicAttributes,
> = {
  Component: FC<THeaderProps>;
  props: THeaderProps;
};

export type TPanelOverlayProps<P extends React.JSX.IntrinsicAttributes> = {
  header: null | TPanelOverlayHeader<P>;
  panel: Omit<TPanelProps, "nextPageFn" | "pageData" | "prevPageFn">;
  refreshInterval: number;
  relatedContent: TRelatedContentImplementation;
};

export type TPanelWithHeaderProps<P extends React.JSX.IntrinsicAttributes> =
  Omit<TPanelOverlayProps<P>, "getRelatedContent">;

const PanelWithHeader = <P extends React.JSX.IntrinsicAttributes>({
  header,
  panel,
  refreshInterval,
  relatedContent,
}: TPanelWithHeaderProps<P>): null | ReactElement => {
  const {
    dataFetchOffset,
    indexOfCurrentItem,
    isLeftArrowVisible,
    isRightArrowVisible,
    pageSize,
    panelItems,
    refreshListData,
    totalItemsInList,
  } = usePanelState();
  const { playback } = usePlayerState();
  const dispatchPanelUpdate = usePanelDispatch();
  const [windowStartAt] = useState(Date.now());

  const nowPlayingIndex = useRef(relatedContent.indexOfCurrentItem);
  const nowPlayingSeason = useRef(panel.selectedSeason);
  const [currentIndex, setCurrentIndex] = useState(
    relatedContent.indexOfCurrentItem
  );

  // when we switch season, also reset the current index for the new season
  // keep index of currently playing item when we switch back to the season
  // of the currently playing episode
  useEffect(() => {
    if (nowPlayingSeason.current !== panel.selectedSeason) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(nowPlayingIndex.current);
    }
  }, [panel.selectedSeason]);
  // Updates at player start and restart,
  // or season change for series
  useEffect(() => {
    if (
      relatedContent.type === "series" &&
      typeof panel.selectedSeason === "number"
    ) {
      dispatchPanelUpdate(
        updateIndexState({
          indexOfCurrentItem: currentIndex,
          pageSize: panel.pageSize,
          totalItemsInList: getEpisodesForCurrentSeason({
            relatedContent,
            season: panel.selectedSeason,
          }),
        })
      );
    } else {
      dispatchPanelUpdate(
        updateIndexState({
          indexOfCurrentItem: relatedContent.indexOfCurrentItem,
          pageSize: panel.pageSize,
          totalItemsInList: relatedContent.totalItems,
        })
      );
    }
  }, [
    relatedContent,
    dispatchPanelUpdate,
    panel.pageSize,
    panel.selectedSeason,
    currentIndex,
  ]);

  // Update page
  useEffect(() => {
    if (typeof pageSize !== "number" || typeof dataFetchOffset !== "number")
      return noop;
    if (!refreshListData) return noop;

    let aborted = false;

    if (relatedContent.type === "series") {
      if (typeof panel.selectedSeason !== "number") return noop;

      relatedContent
        .getPanelData({
          limit: pageSize + listPadding,
          offset: dataFetchOffset,
          season: panel.selectedSeason,
          startEpisodeId:
            panel.selectedSeason === nowPlayingSeason.current
              ? playback?.playbackSpec.videoId
              : undefined,
        })
        .then((d) => {
          if (aborted) return;

          dispatchPanelUpdate(newPanelDataAction(d));
        });
    } else if (relatedContent.type === "live-event") {
      relatedContent
        .getPanelData({
          limit: pageSize + listPadding,
          offset: dataFetchOffset,
          windowStartAt,
        })
        .then((d) => {
          if (aborted) return;

          if (d.totalItems !== totalItemsInList) {
            // list changed, indicating indices are no longer valid
            dispatchPanelUpdate(
              updateIndexState({
                indexOfCurrentItem: relatedContent.indexOfCurrentItem,
                pageSize,
                totalItemsInList: d.totalItems,
              })
            );
          } else {
            dispatchPanelUpdate(newPanelDataAction(d));
          }
        });
    } else {
      relatedContent
        .getPanelData({
          limit: pageSize + listPadding,
          offset: dataFetchOffset,
        })
        .then((d) => {
          if (aborted) return;

          dispatchPanelUpdate(newPanelDataAction(d));
        });
    }

    return () => {
      aborted = true;
    };
  }, [
    totalItemsInList,
    indexOfCurrentItem,
    relatedContent,
    pageSize,
    dataFetchOffset,
    dispatchPanelUpdate,
    refreshListData,
    windowStartAt,
    panel.selectedSeason,
    playback?.playbackSpec.videoId,
  ]);

  // Refresh interval, triggers re-render for progress bars and
  // a check for fetching new content for some types
  useEffect(() => {
    if (!refreshInterval) return noop;
    // 1 item means there is only a filler item, the list has no real content yet
    if (panelItems.items.length < 2) return noop;

    const interval = window.setInterval(() => {
      dispatchPanelUpdate(renderTickAction(Date.now()));
    }, refreshInterval);

    return () => clearInterval(interval);
  }, [panelItems, refreshInterval, dispatchPanelUpdate]);

  const nextPageFn = useMemo(
    () => () => dispatchPanelUpdate(nextPageAction()),
    [dispatchPanelUpdate]
  );

  const prevPageFn = useMemo(
    () => () => dispatchPanelUpdate(previousPageAction()),
    [dispatchPanelUpdate]
  );

  return (
    <OverlayWrapper>
      <OverlayInnerWrapper padded={false}>
        <FlexContainer
          onDoubleClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onMouseLeave={(e) => e.currentTarget.blur()}
        >
          {header && <header.Component {...header.props} />}

          <Panel
            nextPageFn={isRightArrowVisible ? nextPageFn : undefined}
            prevPageFn={isLeftArrowVisible ? prevPageFn : undefined}
            {...panel}
          />
        </FlexContainer>
      </OverlayInnerWrapper>
    </OverlayWrapper>
  );
};

export const PanelOverlay = <P extends React.JSX.IntrinsicAttributes>({
  header,
  panel,
  refreshInterval,
  relatedContent,
}: TPanelOverlayProps<P>) => {
  const [state, dispatchPanelUpdate] = usePanelStateReducer();

  return (
    <PanelStateContext.Provider value={state}>
      <PanelStateDispatchContext.Provider value={dispatchPanelUpdate}>
        <PanelWithHeader
          header={header}
          panel={panel}
          refreshInterval={refreshInterval}
          relatedContent={relatedContent}
        />
      </PanelStateDispatchContext.Provider>
    </PanelStateContext.Provider>
  );
};
