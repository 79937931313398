var AdapterConstants = require('../constants/adapter')
var Chrono = require('../chrono')

var AdapterAdsMixin = {
  /** Override to return current ad position */
  getPosition: function () {
    return null
  },

  /** Override to return the given ad structure (list with number of pre, mid, and post breaks) */
  getGivenBreaks: function () {
    return null
  },

  /** Override to return the ad structure requested (list with number of pre, mid, and post breaks) */
  getExpectedBreaks: function () {
    return null
  },

  /** Override to return the structure of ads requested */
  getExpectedPattern: function () {
    return null
  },

  /** Override to return a list of playheads of ad breaks begin time */
  getBreaksTime: function () {
    return null
  },

  /** Override to return the number of ads given for the break */
  getGivenAds: function () {
    return null
  },

  /** Override to return the number of ads requested for the break */
  getExpectedAds: function () {
    return null
  },

  /** Override to return if the ad is being shown in the screen or not
   * The standard definition is: more than 50% of the pixels of the ad are on the screen
   */
  getIsVisible: function () {
    return true
  },

  /** Override to return a boolean showing if the audio is enabled when the ad begins */
  getAudioEnabled: function () {
    return null
  },

  /** Override to return if the ad is skippable */
  getIsSkippable: function () {
    return null
  },

  /** Override to return a boolean showing if the player is in fullscreen mode when the ad begins */
  getIsFullscreen: function () {
    return null
  },

  /** Override to return the ad campaign */
  getCampaign: function () {
    return null
  },

  /** Override to return the ad creative id */
  getCreativeId: function () {
    return null
  },

  /** Override to return the ad provider name */
  getProvider: function () {
    return null
  },

  /** Override to return the ad insertion type
   * Use constants:
   * npaw.Constants.AdInsertionType.ClientSide
   * npaw.Constants.AdInsertionType.ServerSide
  */
  getAdInsertionType: function () {
    return null
  },

  /**
 * Emits related event and set flags if current status is valid.
 * ie: won't sent start if isStarted is already true.
 *
 * @param {Object} [params] Object of key:value params to add to the request.
 */
  fireClick: function (params) {
    if (typeof params === 'string') {
      params = { url: params }
    }
    this.emit(AdapterConstants.Event.CLICK, { params: params })
  },

  /**
   * Emits related event and set flags if current status is valid.
   * ie: won't sent start if isStarted is already true.
   *
   * @param {Object} [params] Object of key:value params to add to the request.
   */
  fireQuartile: function (quartile) {
    if (this.flags.isStarted && typeof quartile === 'number' &&
      quartile > this.flags.lastQuartileSent && quartile < 4) {
      this.flags.lastQuartileSent++
      this.emit(AdapterConstants.Event.QUARTILE, { params: { quartile: quartile } })
    }
  },

  /**
  * Starts a new chrono for viewedMax and restarts viewedAcum
  */
  startChronoView: function () {
    if (this.getIsVisible() && this.plugin.backgroundDetector && !this.plugin.backgroundDetector.isInBackground) {
      var max = this.chronos.viewedMax
      if (max.length === 0 || max[max.length - 1].stopTime !== 0) {
        max.push(new Chrono())
        max[max.length - 1].start()
      }
    }
  },

  /**
  * Stops a new chrono for viewedMax and restarts viewedAcum
  */
  stopChronoView: function () {
    var max = this.chronos.viewedMax
    if (max[0] && max.length > 0 && max[max.length - 1].stopTime === 0) {
      max[max.length - 1].stop()
    }
  },

  /**
  * @param {Object} [params] Object of key:value params to add to the request.
  */
  fireManifest: function (params, message) {
    var paramsObject = { params: params }
    if (typeof params === 'string') {
      paramsObject = { params: { errorType: params, errorMessage: message } }
    }
    this.emit(AdapterConstants.Event.MANIFEST, paramsObject)
  },

  /*
  * Emits related event and set flags if current status is valid.
  * ie: won't sent start if isStarted is already true.
  *
  * @param {Object} [params] Object of key:value params to add to the request.
  */
  fireSkip: function (params) {
    if (params === undefined) {
      params = {}
    }
    params.skipped = true
    this.fireStop(params)
  },

  /**
  * Emits related event and set flags if current status is valid.
  * ie: won't sent start if isStarted is already true.
  *
  * @param {Object} [params] Object of key:value params to add to the request.
  */
  fireBreakStart: function (params) {
    this.fireAdBreakStart(params)
  },

  /**
  * Emits related event and set flags if current status is valid.
  * ie: won't sent start if isStarted is already true.
  *
  * @param {Object} [params] Object of key:value params to add to the request.
  */
  fireBreakStop: function (params) {
    this.fireAdBreakStop(params)
  },

  /**
  * Emits related event and set flags if current status is valid.
  * ie: won't sent start if isStarted is already true.
  *
  * @param {Object} [params] Object of key:value params to add to the request.
  */
  fireAdBreakStart: function (params) {
    this.emit(AdapterConstants.Event.PODSTART, { params: params })
  },

  /**
  * Emits related event and set flags if current status is valid.
  * ie: won't sent start if isStarted is already true.
  *
  * @param {Object} [params] Object of key:value params to add to the request.
  */
  fireAdBreakStop: function (params) {
    this.emit(AdapterConstants.Event.PODSTOP, { params: params })
  }
}

module.exports = AdapterAdsMixin
