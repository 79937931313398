/* eslint-disable perfectionist/sort-jsx-props */

import { Track } from "@useless-media-public/useless-sender-web";
import React from "react";

import { SvgLanguageSettings } from "~/components/Icons";
import { Popup } from "~/components/Popup";
import { useChromecastContext } from "~/providers";

import { UselessTrackList } from "./TrackList";

export type UselessLanguagePickerProps = {
  activeAudioTrack?: Track;
  activeTextTrack?: Track;
  audioTracks?: Track[];
  className?: string;
  disabled?: boolean;
  onChangeAudioTrack: (track: Track) => void;
  onChangeTextTrack: (track: Track) => void;
  textTracks?: Track[];
};

export const UselessLanguagePicker: React.FC<UselessLanguagePickerProps> = ({
  activeAudioTrack,
  activeTextTrack,
  audioTracks,
  className,
  disabled,
  onChangeAudioTrack,
  onChangeTextTrack,
  textTracks,
}) => {
  const { translations } = useChromecastContext();

  return (
    <Popup className={className} disabled={disabled} Icon={SvgLanguageSettings}>
      {Boolean(textTracks?.length) && (
        <UselessTrackList
          activeTrack={activeTextTrack}
          onClickTrack={onChangeTextTrack}
          title={translations?.subtitleTracks || "Subtitles"}
          tracks={textTracks}
          accessibilityTranslation={translations?.hardOfHearingSubtitles}
        />
      )}
      {Boolean(audioTracks?.length) && (
        <UselessTrackList
          activeTrack={activeAudioTrack}
          onClickTrack={onChangeAudioTrack}
          title={translations?.audioTracks || "Audio"}
          tracks={audioTracks}
          accessibilityTranslation={translations?.descriptiveAudio}
        />
      )}
    </Popup>
  );
};
