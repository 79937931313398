import { useEffect, useState } from "react";

import { TPlaybackType } from "../../@types/types";
import { TSeekRange } from "../../hooks/ui-state-reducer.hook";
import {
  getHHMMFromEpoch,
  getZeroBasedTimeString,
  shouldConvertCurrentTime,
} from "../../utils";

export type TUseTimestampsOptions = {
  currentTime: null | number;
  endTime: number | undefined;
  liveSeekEnabled: boolean;
  playbackType: null | TPlaybackType;
  seekRange?: TSeekRange;
  startTime: number | undefined;
  vodDuration: null | number;
};

export const useTimestamps = ({
  currentTime,
  endTime,
  liveSeekEnabled,
  playbackType,
  seekRange,
  startTime,
  vodDuration,
}: TUseTimestampsOptions): {
  leftTimer: null | string;
  rightTimer: null | string;
} => {
  const [leftTimer, setLeftTimer] = useState<null | string>(null);
  const [rightTimer, setRightTimer] = useState<null | string>(null);

  useEffect(() => {
    // Live with Seek Case
    if (playbackType === "linear-channel" && liveSeekEnabled) {
      if (!seekRange || !endTime || !startTime) return;

      setLeftTimer(getHHMMFromEpoch(startTime));
      setRightTimer(getHHMMFromEpoch(endTime));

      return;
    }

    // Live without Seek Case
    if (playbackType === "linear-channel" && !liveSeekEnabled) {
      if (!(startTime && endTime)) return;

      setLeftTimer(getHHMMFromEpoch(startTime));
      setRightTimer(getHHMMFromEpoch(endTime));

      return;
    }

    // Startover Case
    if (playbackType === "startover") {
      if (!(seekRange && currentTime)) return;

      // Check if we have epoch timestamp or zero based (telia/cmore differences)
      // if currentTime is larger than some arbitrary value, assume we have to recalculate it
      if (shouldConvertCurrentTime(currentTime)) {
        setLeftTimer(getZeroBasedTimeString(currentTime - seekRange.start));
      } else {
        setLeftTimer(getZeroBasedTimeString(currentTime));
      }
      setRightTimer(null);

      return;
    }
    // Live Event Case
    if (playbackType === "live-event") {
      if (!(seekRange && currentTime)) return;

      // Check if we have epoch timestamp or zero based (telia/cmore differences)
      // if currentTime is larger than some arbitrary value, assume we have to recalculate it
      if (shouldConvertCurrentTime(currentTime)) {
        if (typeof startTime !== "number") return;

        setLeftTimer(getZeroBasedTimeString(currentTime - startTime / 1000));
      } else {
        setLeftTimer(getZeroBasedTimeString(currentTime));
      }
      setRightTimer(null);

      return;
    }

    // VOD Case
    if (playbackType === "vod") {
      if (!(vodDuration && currentTime)) return;

      setLeftTimer(getZeroBasedTimeString(currentTime));
      setRightTimer(getZeroBasedTimeString(-(vodDuration - currentTime)));
    }
  }, [
    startTime,
    endTime,
    playbackType,
    vodDuration,
    currentTime,
    liveSeekEnabled,
    seekRange,
  ]);

  return {
    leftTimer,
    rightTimer,
  };
};
