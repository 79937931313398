import {
  AdvertisementTimeUpdateEvent,
  AudioTrackChangedEvent,
  BitrateChangedEvent,
  BufferedEvent,
  BufferingEvent,
  CdnChangedEvent,
  CustomTrackingEvent,
  DroppedFramesEvent,
  LoadedEvent,
  ManifestTypeChangedEvent,
  PausedEvent,
  PlaybackErrorEvent,
  PlayEvent,
  PlayingEvent,
  SeekedEvent,
  SeekingEvent,
  StreamCueEvent,
  TextTrackChangedEvent,
  TextTrackVisibilityEvent,
  TimeUpdateEvent,
  VolumeChangedEvent,
} from "@telia-company/tv.web-playback-sdk";
import {
  AdSession,
  TEngineCallbacks,
  TEventMethods,
  WebPlayerEventType,
} from "@telia-company/tv.web-player-shared";

export const getEngineCallbacks = ({
  adSession,
  event: { publish },
}: {
  adSession: AdSession | undefined;
  event: TEventMethods;
}): {
  callbacks: TEngineCallbacks;
} => {
  let isLive = false;

  const onTimeUpdate = (d: TimeUpdateEvent["payload"]) => {
    if (adSession && adSession.isPlayingAd()) {
      publish(
        new AdvertisementTimeUpdateEvent({
          advertisementCurrentTime: adSession.getCurrentAdvertisementTime(),
          advertisementDuration: adSession.getCurrentAdvertisementDuration(),
          currentTime: adSession.getPositionWithoutAds(d.currentTime),
        })
      );
    } else {
      publish(
        new TimeUpdateEvent({
          currentTime: adSession
            ? adSession.getPositionWithoutAds(d.currentTime)
            : d.currentTime,
          duration: adSession ? adSession.getDurationWithoutAds() : d.duration,
        })
      );
    }

    if (adSession) {
      adSession.timeUpdate(d.currentTime);
    }
  };

  /* vvv REMOVE BLOCK IF RELYING ON MEDIA EVENT FILTER FOR BUFFERS */

  //  Since Buffer detection experimentally moved from media-event-filter
  //  to shaka we lose some control over event sequencing. According to
  //  our own sequence spec, a buffering is not allowed to happen
  //  during seeking, and a seek should cancel an ongoing buffer.

  // Prevent buffers during seeks
  let buffering = false;
  let seeking = false;
  /* ^^^ REMOVE BLOCK IF RELYING ON MEDIA EVENT FILTER FOR BUFFERS */

  return {
    callbacks: {
      onAudioTrackChanged: (payload) =>
        publish(new AudioTrackChangedEvent(payload)),
      onAutoPlayBlocked: (payload) =>
        publish({
          payload,
          type: WebPlayerEventType.AUTOPLAY_BLOCKED,
        }),
      onBitrateChanged: (payload) => publish(new BitrateChangedEvent(payload)),
      onBuffered: () => {
        if (seeking) return;

        buffering = false;
        publish(new BufferedEvent());
      },
      onBuffering: () => {
        if (seeking) return;

        buffering = true;
        publish(new BufferingEvent());
      },
      onCdnChange: (payload) => publish(new CdnChangedEvent(payload)),
      onCustomTrackingEvent: (payload) =>
        publish(new CustomTrackingEvent(payload)),
      onDroppedFrames: (payload) => publish(new DroppedFramesEvent(payload)),
      onEnded: (payload) => {
        if (payload.currentTime < payload.duration) {
          // eslint-disable-next-line no-console
          console.warn(
            "onEnded callback received unexpected data: currentTime was less than duration when video reached EOS."
          );
        }

        // Force through a timeupdate before ended,
        // to make sure ad trackers hit the last second
        // of duration
        onTimeUpdate(payload);

        publish({
          payload,
          type: WebPlayerEventType.END_OF_STREAM,
        });
      },
      onError: (payload) => publish(new PlaybackErrorEvent(payload)),
      onInitialActiveTracks: (payload) =>
        publish({
          payload,
          type: WebPlayerEventType.INITIAL_ACTIVE_TRACKS,
        }),
      onIsLive: (payload) => {
        isLive = payload.isLive;

        publish({
          payload,
          type: WebPlayerEventType.IS_LIVE,
        });
      },
      onLanguagesUpdated: (payload) =>
        publish({
          payload,
          type: WebPlayerEventType.LANGUAGES,
        }),
      onLoaded: () => publish(new LoadedEvent()),
      onManifestTypeChanged: () => publish(new ManifestTypeChangedEvent()),
      onPaused: () => publish(new PausedEvent()),
      onPlay: () => publish(new PlayEvent()),
      onPlaying: () => publish(new PlayingEvent()),
      onSeeked: () => {
        seeking = false;

        publish(new SeekedEvent());
      },
      onSeeking: () => {
        if (buffering) {
          publish(new BufferedEvent());
          buffering = false;
        }

        seeking = true;

        publish(new SeekingEvent());
      },
      onSeekRangeUpdate: (e) => {
        publish({
          payload: {
            seekRange: {
              end:
                adSession && !isLive
                  ? adSession.getDurationWithoutAds()
                  : e.seekRange.end,
              start: e.seekRange.start,
            },
            stats: e.stats,
          },
          type: WebPlayerEventType.SEEK_RANGE_UPDATE,
        });
      },
      onSpeedChange: (payload) =>
        publish({
          payload,
          type: WebPlayerEventType.SPEED_CHANGE,
        }),
      onStreamCue: (payload) => publish(new StreamCueEvent(payload)),
      onTextTrackChanged: (payload) =>
        publish(new TextTrackChangedEvent(payload)),
      onTextTrackVisibility: (payload) =>
        publish(new TextTrackVisibilityEvent(payload)),
      onTimeUpdate,
      onVideoElementReady: (payload) =>
        publish({
          payload,
          type: WebPlayerEventType.VIDEO_ELEMENT_READY,
        }),
      onVolumeChanged: (payload) => publish(new VolumeChangedEvent(payload)),
    },
  };
};
