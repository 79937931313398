import { ErrorCategories } from "@telia-company/tv.web-playback-sdk";
import shaka from "shaka-player";

export const convertShakaCategory = (
  key: number | undefined
): ErrorCategories => {
  switch (key) {
    case shaka.util.Error.Category.ADS:
      return ErrorCategories.ADS;
    case shaka.util.Error.Category.CAST:
    case shaka.util.Error.Category.MANIFEST:
    case shaka.util.Error.Category.MEDIA:
    case shaka.util.Error.Category.PLAYER:
    case shaka.util.Error.Category.STORAGE:
    case shaka.util.Error.Category.STREAMING:
    case shaka.util.Error.Category.TEXT:
      return ErrorCategories.MEDIA;
    case shaka.util.Error.Category.DRM:
      return ErrorCategories.DRM;
    case shaka.util.Error.Category.NETWORK:
      return ErrorCategories.NETWORK;
    default:
      return ErrorCategories.DEFAULT;
  }
};
