import styled from "@emotion/styled";
import { TCallback } from "@telia-company/tv.web-player-shared";
import { FC } from "react";

import { TPlaybackType } from "../../@types/types";
import { Bar } from "../elements";
import { LiveMarker } from "./live-marker";

const ProgressTrackWrapper = styled(Bar)`
  background: rgba(255, 255, 255, 0.25);

  width: 100%;
  align-self: center;
  position: relative;
`;

export const Progress = styled(Bar)<{ progressBarColor: string }>`
  background: ${({ progressBarColor }) => progressBarColor};
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
`;

const ProgressShadow = styled(Progress)<{ progressBarColorDark: string }>`
  background: ${({ progressBarColorDark }) => progressBarColorDark};
`;

const OverlayProgress = styled(Progress)`
  background: rgba(255, 255, 255, 0.42);
`;

export type TProgressTrackProps = {
  atLiveEdge: boolean;
  canJumpToLive: boolean;
  isNpvr: boolean;
  isOngoingStartoverOrLiveEvent: boolean | undefined;
  liveSeekEnabled: boolean;
  mouseProgressPosition: number;
  onJumpToLive: TCallback;
  playbackType: null | TPlaybackType;
  progressBarColor: string;
  progressBarColorDark: string;
  progressPosition: number;
  seekWindowPercent: null | number;
  showScrubbingInfo: () => void;
};

export const ProgressTrack: FC<TProgressTrackProps> = ({
  atLiveEdge,
  canJumpToLive,
  isNpvr,
  isOngoingStartoverOrLiveEvent,
  liveSeekEnabled,
  mouseProgressPosition,
  onJumpToLive,
  playbackType,
  progressBarColor,
  progressBarColorDark,
  progressPosition,
  seekWindowPercent,
  showScrubbingInfo,
}) => (
  <ProgressTrackWrapper horizontal>
    {(playbackType === "startover" ||
      playbackType === "live-event" ||
      liveSeekEnabled) &&
      !!seekWindowPercent && (
        <OverlayProgress
          horizontal
          progressBarColor={progressBarColor}
          style={{ width: `${seekWindowPercent * 100}%` }}
        />
      )}

    {!!mouseProgressPosition && (
      <OverlayProgress
        horizontal
        progressBarColor={progressBarColor}
        style={{ width: `${mouseProgressPosition * 100}%` }}
      />
    )}

    {(playbackType === "startover" ||
      playbackType === "live-event" ||
      liveSeekEnabled) &&
      !!(seekWindowPercent || progressPosition) &&
      !!(
        canJumpToLive ||
        ((liveSeekEnabled || isOngoingStartoverOrLiveEvent) && !isNpvr)
      ) && (
        <LiveMarker
          atLiveEdge={atLiveEdge}
          canJumpToLive={canJumpToLive}
          onJumpToLive={onJumpToLive}
          seekWindowPercent={seekWindowPercent || progressPosition / 100}
        />
      )}

    <ProgressShadow
      horizontal
      progressBarColor={progressBarColor}
      progressBarColorDark={progressBarColorDark}
      style={{
        width: `${progressPosition}%`,
      }}
    />

    <Progress
      horizontal
      onClick={showScrubbingInfo}
      progressBarColor={progressBarColor}
      style={{
        width: `${
          mouseProgressPosition &&
          mouseProgressPosition * 100 < progressPosition
            ? mouseProgressPosition * 100
            : progressPosition
        }%`,
      }}
    />
  </ProgressTrackWrapper>
);
