import {
  CustomTrackingEvent,
  FetchRequestFactory,
  MetadataService,
  stillWatchingTimerUtility,
  StoppedReasons,
  StreamingGatewayService,
  TrackingManager,
} from "@telia-company/tv.web-playback-sdk";
import {
  EngineLogic,
  getEventModule,
  PlaybackSession,
  TContentMetadata,
  TInitializePlaybackOptions,
  TInitializeServicesOptions,
  TPlaybackService,
  TServiceLayerConfig,
  VideoIdType,
  WebPlayerEventType,
} from "@telia-company/tv.web-player-shared";

import { TServices } from "./@types/types";
import { getChannelMetadata } from "./metadata/channel";
import { getMediaMetadata } from "./metadata/media";
import { getUnhandledMetadata } from "./metadata/unhandled-metadata";
import {
  getPlaybackSessionHandler,
  PlaybackSessionHandler,
} from "./playback-session/playback-session";

export type TCreatePlaybackServiceOptions = {
  client: string;
  engineLogic: EngineLogic;
};

// a playbackService instance can handle several consecutive playback sessions
// a separate playbackService instance is required for multiple simultaneous playback sessions
export const createPlaybackService = ({
  client,
  engineLogic,
}: TCreatePlaybackServiceOptions): TPlaybackService => {
  const requestFactory = new FetchRequestFactory({
    headers: {
      client,
    },
    useProfileHeader: true,
  });
  const event = getEventModule();

  const services: TServices = {
    metadataService: new MetadataService(),
    sgwService: new StreamingGatewayService(),
    trackingManager: new TrackingManager(),
  };

  let playbackSessionHandler: null | PlaybackSessionHandler = null;
  let config: null | TServiceLayerConfig = null;
  let playerVersion = "";
  let destroyed = false;
  let pendingChannelMetadataPromise: null | Promise<TContentMetadata> = null;

  const stillWatchingTimer = stillWatchingTimerUtility({
    closeStreamCb: () => {
      playbackSessionHandler?.teardown(StoppedReasons.INACTIVITY);
    },
    idleWarningCb: () => {
      // set state variable to show hint message for live
      // do nothing for vod, since we will check if we've been idle
      // when we start playback of the next episode
      event.publish({
        payload: {
          visible: true,
        },
        type: WebPlayerEventType.USER_INACTIVITY_WARNING,
      });
    },
    resetWarningCb: () => {
      event.publish({
        payload: {
          visible: false,
        },
        type: WebPlayerEventType.USER_INACTIVITY_WARNING,
      });
    },
  });

  const initializeServices = ({
    conf,
    version,
  }: TInitializeServicesOptions) => {
    if (destroyed)
      throw new Error(
        "[playbackService] Instance is destroyed. This is an implementation error."
      );
    if (config)
      throw new Error(
        "[playbackService] Services already initialized. This is an implementation error."
      );

    playerVersion = version;
    config = conf;
    requestFactory.setOptions({ authenticationComponent: conf.auth });

    if (conf.fetchOverride) {
      requestFactory.setFetchMethod(conf.fetchOverride);
    }

    Object.values(services).forEach((s) => s.initialize(conf));

    services.metadataService.attachRequestFactory(requestFactory);
    services.sgwService.attachRequestFactory(requestFactory);
  };

  const initializePlayback = ({
    disableSessionTimeout,
    features,
    /* TODO reason, (PNC, RELATED_CONTENT_CHOICE...) - make reason part of playback object  */
    playback,
    restrictions,
    user,
  }: TInitializePlaybackOptions): PlaybackSession => {
    if (destroyed)
      throw new Error(
        "[playbackService] Instance is destroyed. This is an implementation error."
      );
    if (!config)
      throw new Error(
        "[playbackService] deviceId missing, call initializeServices first. This is an implementation error."
      );

    // Abort previous playback session
    playbackSessionHandler?.teardown(StoppedReasons.INTERRUPTED);

    // Detect when user has been idle, and ask if they're still watching
    // If not, close the stream
    // Must be started before getPlaybackSessionHandler is called,
    // otherwise it's possible for the stream start to error before
    // the stillWatchingTimer is started, meaning it may be "stopped" before
    // start triggers.
    if (user.stillWatching) {
      stillWatchingTimer.start({
        config: user.stillWatching,
        playbackSpec: playback.playbackSpec,
      });
    }

    // Create a new session
    playbackSessionHandler = getPlaybackSessionHandler({
      config,
      disableSessionTimeout: !!disableSessionTimeout,
      engineLogic,
      event,
      features,
      playback,
      playerVersion,
      requestFactory,
      restrictions,
      services,
      user,
    });

    const { onConcurrentStreams } = playbackSessionHandler;

    return {
      onConcurrentStreams,
    };
  };

  const destroy = async () => {
    if (destroyed) return;

    destroyed = true;

    // Abort ongoing session
    playbackSessionHandler?.teardown(StoppedReasons.USER);

    stillWatchingTimer.stop();

    // Clear all shared event channel handlers
    event.clear();

    // Destroy service instances
    await Promise.all(Object.values(services).map((s) => s.destroy()));
  };

  const { all, off, on } = event;

  return {
    // called when all playback is finished (e.g. when player ui component is unmounted)
    destroy,
    // the shared event channel
    event: {
      all,
      off,
      on,
      publishCustomTrackingEvent: (name) =>
        event.publish(
          new CustomTrackingEvent({
            name,
          })
        ),
    },
    // to be deprecated, by making metadata an api the client needs to fulfill
    getMetadata: async (playbackSpec, timestamp) => {
      switch (playbackSpec.videoIdType) {
        case VideoIdType.CHANNEL:
          if (pendingChannelMetadataPromise)
            return pendingChannelMetadataPromise;
          pendingChannelMetadataPromise = getChannelMetadata({
            metadataService: services.metadataService,
            playbackSpec,
            timeRange: {
              end: Date.now() + 8 * 3600 * 1000, // fetch -4 to +8 hours, for dvr window scrubbing
              start: Date.now() - 4 * 3600 * 1000,
            },
            timestamp,
          }).finally(() => {
            pendingChannelMetadataPromise = null;
          });
          return pendingChannelMetadataPromise;
        case VideoIdType.MEDIA:
          return getMediaMetadata({
            metadataService: services.metadataService,
            playbackSpec,
          });
        default:
          break;
      }

      return getUnhandledMetadata();
    },
    // called every time you want to play something
    initializePlayback,
    // called once per "playbackService" instance
    initializeServices,
  };
};
