import { TContentMetadata } from "@telia-company/tv.web-player-shared";
import { useEffect } from "react";

export type TUseMediaSessionMetadataOptions = {
  metadata: TContentMetadata;
};

const supportsMediaSession = "mediaSession" in navigator;

export const useMediaSessionMetadata = ({
  metadata,
}: TUseMediaSessionMetadataOptions) => {
  useEffect(() => {
    if (!metadata) return;
    if (!supportsMediaSession) return;

    const artwork = metadata.poster || metadata.logo;

    navigator.mediaSession.metadata = new MediaMetadata({
      // TODO resize image to reasonable size and square
      artwork: artwork ? [{ src: artwork }] : undefined,
      title: metadata.title,
    });
  }, [metadata]);
};
