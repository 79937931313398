import styled from "@emotion/styled";
import { CoreTrackingEvent } from "@telia-company/tv.web-playback-sdk";
import { FC, useEffect } from "react";

import { useActionButtons } from "./action-button-hook";
import { ButtonReset } from "./buttons/base";
import {
  useClickHandler,
  useCustomStyles,
  usePlayerState,
  usePublishCustomTrackingEvent,
  useSmallLayout,
  useTranslations,
} from "./context-provider";
import { AbsolutePositionFullSizeDiv } from "./elements";

const TimelineWrapper = styled(AbsolutePositionFullSizeDiv)<{
  smallLayout: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(9, 1fr);
  grid-gap: 0.5%;
  padding: ${({ smallLayout }) =>
    smallLayout ? "3% 2% 1% 2%" : "3% 3.5% 1% 3.5%;"};
  pointer-events: none;
`;

const getCustomEvent = (timelineType: string): CoreTrackingEvent | null => {
  switch (timelineType) {
    case "OPENING_CREDITS":
      return CoreTrackingEvent.PLAYER_SKIP_INTRO;
    case "RECAP":
      return CoreTrackingEvent.PLAYER_SKIP_RECAP;
    default:
      return null;
  }
};

const getCustomImpressionEvent = (
  timelineType: string
): CoreTrackingEvent | null => {
  switch (timelineType) {
    case "OPENING_CREDITS":
      return CoreTrackingEvent.PLAYER_SKIP_INTRO_IMPRESSION;
    case "RECAP":
      return CoreTrackingEvent.PLAYER_SKIP_RECAP_IMPRESSION;
    default:
      return null;
  }
};

const TimelineActionButtonWrapper = styled(ButtonReset)<{
  buttonBorderRadius: string;
  fadeIn: boolean;
  fadeOut: boolean;
  uiVisible: boolean;
}>`
  grid-row: 7;
  grid-column: 1 / -1;
  justify-self: end;
  overflow: hidden;
  padding-right: 1em;
  padding-left: 1em;
  text-align: center;
  background: ${({ uiVisible }) =>
    uiVisible ? `rgba(255, 255, 255, 0.15)` : `rgba(0, 0, 0, 0.25)`};
  transition: background-color 0.3s linear;
  border-radius: ${({ buttonBorderRadius }) => buttonBorderRadius};
  white-space: pre;
  cursor: pointer;
  pointer-events: all;
  :hover {
    background: rgba(255, 255, 255, 0.3);
  }
  @keyframes fadeIn {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 100%;
    }
    to {
      opacity: 0%;
    }
  }
  ${({ fadeIn }) => (fadeIn ? `animation: 0.3s fadeIn ease-in forwards;` : "")}
  ${({ fadeOut }) =>
    fadeOut ? `animation: 0.3s fadeOut ease-in forwards;` : ""}
`;

export const TimelineActionButton: FC = () => {
  const { currentTime, timeline, uiVisible } = usePlayerState();
  const smallLayout = useSmallLayout();
  const { skipOpeningCredits, skipRecap } = useTranslations();
  const { buttonBorderRadius } = useCustomStyles();
  const { onSeek } = useClickHandler();
  const publish = usePublishCustomTrackingEvent();

  const buttonHintSeconds = 5;

  let showButton = false;
  let buttonText = "";

  const { actionButton } = useActionButtons({ currentTime, timeline });

  useEffect(() => {
    if (!actionButton) return;
    const impression = getCustomImpressionEvent(actionButton.type);
    if (!impression) return;

    publish(impression);
  }, [actionButton, publish]);

  if (typeof currentTime !== "number") return null;

  // closing credits handled in next-content.hook
  /* if (timeline?.closingCredits && currentTimeWithinTimelineBlock(timeline.closingCredits, currentTime)) {
    actionButtons.push(timeline.closingCredits);
  } */

  if (!actionButton) return null;

  const fadeIn = currentTime < actionButton.start + 0.5;
  const fadeOut = currentTime > actionButton.end - 0.5;
  const buttonHintVisible =
    currentTime > actionButton.start &&
    currentTime < actionButton.start + buttonHintSeconds;
  const seekTarget = actionButton.end;

  switch (actionButton.type) {
    case "OPENING_CREDITS":
      buttonText = skipOpeningCredits;
      break;
    case "RECAP":
      buttonText = skipRecap;
      break;
    default:
      buttonText = "";
      break;
  }

  const customEvent = getCustomEvent(actionButton.type);

  showButton = !!(
    actionButton &&
    buttonText &&
    (buttonHintVisible || uiVisible)
  );

  if (!showButton) return null;

  return (
    <TimelineWrapper smallLayout={smallLayout}>
      <TimelineActionButtonWrapper
        buttonBorderRadius={buttonBorderRadius}
        fadeIn={fadeIn}
        fadeOut={fadeOut}
        onClick={() => {
          if (customEvent) {
            publish(customEvent);
          }

          onSeek(seekTarget);
        }}
        uiVisible={uiVisible}
      >
        {buttonText}
      </TimelineActionButtonWrapper>
    </TimelineWrapper>
  );
};
