import styled from "@emotion/styled";
import {
  PlayerControls,
  TEXT_ELEMENT_ID,
  TPlayerFeatures,
  TTranslations,
  VIDEO_ELEMENT_ID,
} from "@telia-company/tv.web-player-shared";
import { Dispatch, FC, useRef } from "react";

import {
  TDesign,
  TImageProxyImpl,
  TMetadataImplementations,
  TUiCallbacks,
} from "../@types/types";
import { TAction, TState } from "../hooks/ui-state-reducer.hook";
import { ContextProviders, TClickHandlers } from "./context-provider";
import {
  AbsolutePositionFullSizeDiv,
  SixteenByNineContainer,
} from "./elements";
import { StatsOverlay } from "./stats-overlay";
import { absolutePositionFullSizeBlock } from "./styles";
import { Ui } from "./ui";

const SubtitleContainer = styled(AbsolutePositionFullSizeDiv)`
  pointer-events: none;

  #${TEXT_ELEMENT_ID} {
    ${absolutePositionFullSizeBlock}
  }
`;

const VideoContainer = styled(AbsolutePositionFullSizeDiv)`
  #${VIDEO_ELEMENT_ID} {
    ${absolutePositionFullSizeBlock}
    ::-webkit-media-text-track-display {
      width: auto !important;
      left: 50% !important;
      white-space: pre;
      transform: translateX(-50%);
    }
  }
`;

export type TVideoWrapperProps = {
  callbacks: TUiCallbacks;
  contextValues: {
    clickHandlers: TClickHandlers;
    controls: PlayerControls | undefined;
    design: TDesign;
    dispatch: Dispatch<TAction>;
    features: TPlayerFeatures;
    imageProxyImpl: TImageProxyImpl;
    metadataImpl: TMetadataImplementations;
    publishCustomTrackingEvent: (name: string) => void;
    state: TState;
    translations: TTranslations;
  };
  textWrapperCallbackRef: (node: HTMLDivElement | null) => void;
  videoWrapperCallbackRef: (node: HTMLDivElement | null) => void;
};

const FullscreenElement = styled.div<{
  fullscreen: boolean;
  uiVisible: boolean;
}>`
  ${({ fullscreen }) =>
    fullscreen
      ? `
  display: flex;
  align-items: center;
  justify-content: center;
  `
      : ""};

  cursor: ${({ uiVisible }) => (uiVisible ? "default" : "none")};

  flex: 1 1 0;
`;

export const VideoWrapper: FC<TVideoWrapperProps> = ({
  callbacks,
  contextValues: {
    clickHandlers,
    controls,
    design,
    dispatch,
    features,
    imageProxyImpl,
    metadataImpl,
    publishCustomTrackingEvent,
    state,
    translations,
  },
  textWrapperCallbackRef,
  videoWrapperCallbackRef,
}) => {
  const fullscreenElementRef = useRef<HTMLDivElement>(null);
  const { fullscreen, uiVisible } = state;

  /**
   * DO NOT CONDITIONALLY RENDER ANYTHING ABOVE THE <Ui /> LEVEL
   *
   * The Ui is what renders the <Spinner /> and back button. Conditionally
   * rendering above Ui would mean that the back button and spinner are
   * hidden, preventing closing the player and hiding that loading is
   * ongoing.
   */

  return (
    <FullscreenElement
      fullscreen={fullscreen}
      ref={fullscreenElementRef}
      uiVisible={uiVisible}
    >
      <SixteenByNineContainer>
        <ContextProviders
          callbacks={callbacks}
          clickHandlers={clickHandlers}
          controls={controls}
          design={design}
          dispatch={dispatch}
          features={features}
          fullscreenElementRef={fullscreenElementRef}
          imageProxyImpl={imageProxyImpl}
          metadataImpl={metadataImpl}
          publishCustomTrackingEvent={publishCustomTrackingEvent}
          state={state}
          translations={translations}
        >
          {/* videoElement is appended here */}
          <VideoContainer ref={videoWrapperCallbackRef} />
          {/* textElement is appended here */}
          <SubtitleContainer ref={textWrapperCallbackRef} />

          <Ui />

          <StatsOverlay />
        </ContextProviders>
      </SixteenByNineContainer>
    </FullscreenElement>
  );
};
