import { StandardError, StandardErrorArgs } from "./StandardError";
import { ErrorCategories } from "../Constants";
import { NetworkError } from "./NetworkError";
import { getCodeFromNetworkError } from "./utils";
import { MessagingErrorMap } from "./constants";

export type TMessagingErrorArgs = Omit<
  StandardErrorArgs,
  "category" | "code"
> & {
  originalError: NetworkError;
};

export class MessagingError extends StandardError {
  constructor(args: TMessagingErrorArgs) {
    super({
      ...args,
      category: ErrorCategories.TV_CLIENT_GATEWAY,
      code: getCodeFromNetworkError({
        error: args.originalError,
        errorMap: MessagingErrorMap,
      }),
    });
  }
}
