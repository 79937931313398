import { FC } from "react";

import { usePlayerState } from "./context-provider";
import { MetadataContainer } from "./metadata-container";
import { PlayerControlsContainer } from "./player-controls-container";
import { ProgressBarContainer } from "./progress-bar/progress-bar";

export type UiProps = {
  liveSeekEnabled: boolean;
  showNextEpisodeOverlay: boolean;
  showRelatedContentOverlay: boolean;
  skip: (direction: number) => void;
};

export const UiGridDependentComponents: FC<UiProps> = ({
  liveSeekEnabled,
  showNextEpisodeOverlay,
  showRelatedContentOverlay,
  skip,
}) => {
  const {
    metadata: { channelPlaybackSpec, endTime, linearPlaybackSpec },
    playbackType,
  } = usePlayerState();
  // check for when to hide the go-to-live button, together with canJumpToLive
  const isOngoingStartoverOrLiveEvent = !!(
    (playbackType === "startover" || playbackType === "live-event") &&
    endTime &&
    endTime > Date.now() + 5000
  );

  /*
   * Can only jump to live when we're playing a startover that has a channelPlaybackspec,
   * or when we're playing a startover that has not yet ended.
   * 2023-05-11 or when we have channel_dvr capabilities
   */
  const canJumpToLive =
    !!(playbackType === "startover" && channelPlaybackSpec) ||
    !!(
      playbackType === "startover" &&
      linearPlaybackSpec &&
      endTime &&
      endTime > Date.now() + 5000
    ) ||
    liveSeekEnabled;
  return (
    <>
      <MetadataContainer />
      {!showNextEpisodeOverlay && !showRelatedContentOverlay && (
        <ProgressBarContainer
          canJumpToLive={canJumpToLive}
          isOngoingStartoverOrLiveEvent={isOngoingStartoverOrLiveEvent}
        />
      )}
      {!showNextEpisodeOverlay && !showRelatedContentOverlay && (
        <PlayerControlsContainer
          canJumpToLive={canJumpToLive}
          isOngoingStartoverOrLiveEvent={isOngoingStartoverOrLiveEvent}
          skip={skip}
        />
      )}

      {/* <RelatedContent /> */}
    </>
  );
};
