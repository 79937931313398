/**
 * List of events that can be triggered by the adapter
 */
var Event = {
  INIT: 'init',
  START: 'start',
  JOIN: 'join',
  JOIN_TIME: 'joinTime',
  DATA_OBJECT: 'data',
  PAUSE: 'pause',
  RESUME: 'resume',
  SEEK_BEGIN: 'seek-begin',
  SEEK_END: 'seek-end',
  BUFFER_BEGIN: 'buffer-begin',
  BUFFER_END: 'buffer-end',
  ERROR: 'error',
  STOP: 'stop',
  CLICK: 'click',
  MANIFEST: 'manifest',
  PODSTART: 'break-start',
  PODSTOP: 'break-stop',
  QUARTILE: 'quartile',
  VIDEO_EVENT: 'video-event'
};
module.exports = Event;
