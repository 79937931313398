import mitt from "mitt";

import { TEventMethods, WebPlayerEventRecord } from "../shared-types";

export const getEventModule = (): TEventMethods => {
  const { all, emit, off, on } = mitt<WebPlayerEventRecord>();

  return {
    // Map of all listeners
    all,
    // clear all event listeners
    clear: () => all.clear(),
    // stop listening to an event
    off,
    // listen to an event
    on,
    // publish an event to listeners
    publish: (event) => emit(event.type, event),
  };
};
