var _a;
class Constants {
}
_a = Constants;
Constants.VideoService = {
    INIT: '/init',
    START: '/start',
    JOIN: '/joinTime',
    PAUSE: '/pause',
    RESUME: '/resume',
    SEEK: '/seek',
    BUFFER: '/bufferUnderrun',
    ERROR: '/error',
    STOP: '/stop',
    PING: '/ping',
    DATA_OBJECT: '/data'
};
Constants.Service = Object.assign(Object.assign({ DATA: '/data', ADAPTERS: '/adapters', CONFIGURATION: '/configuration', 
    // Plugin Logs
    APP_ANALYTICS_PLUGIN_LOGS: '/infinity/session/pluginLogs', VIDEO_PLUGIN_LOGS: '/infinity/video/pluginLogs' }, _a.VideoService), { CDN: '/cdn', VIDEO_EVENT: '/infinity/video/event', 
    // Ads
    AD_INIT: '/adInit', AD_START: '/adStart', AD_JOIN: '/adJoin', AD_PAUSE: '/adPause', AD_RESUME: '/adResume', AD_BUFFER: '/adBufferUnderrun', AD_STOP: '/adStop', AD_CLICK: '/adClick', AD_ERROR: '/adError', AD_MANIFEST: '/adManifest', AD_POD_START: '/adBreakStart', AD_POD_STOP: '/adBreakStop', AD_QUARTILE: '/adQuartile', 
    // AppAnalytics
    EVENT: '/infinity/session/event', SESSION_START: '/infinity/session/start', SESSION_STOP: '/infinity/session/stop', NAV: '/infinity/session/nav', BEAT: '/infinity/session/beat', 
    // Offline
    OFFLINE_EVENTS: '/offlineEvents' });
export default Constants;
