import { StandardErrorArgs, StandardError } from "../../base";

export enum ServiceErrorCodes {
  MissingConfiguration = "MISSING_CONFIGURATION",
  MissingAuthentication = "MISSING_AUTHENTICATION",

  BadResponseError = "BAD_RESPONSE_ERROR",
  MissingCredentials = "MISSING_CREDENTIALS",
  MappingError = "MappingError",
  UnknownError = "UNKNOWN_ERROR",
  InternalError = "INTERNAL_ERROR",
}

export type TServiceErrorArgs = StandardErrorArgs & {};

export class ServiceError extends StandardError {
  constructor(args: TServiceErrorArgs) {
    super(args);
  }
}
