import styled from "@emotion/styled";
import { FC } from "react";

import { HotkeyTooltips } from "../hooks/global-hotkeys.hook";
import { useIcon, useSmallLayout } from "./context-provider";
import { GridIcon } from "./elements";

const BackButtonIcon = styled(GridIcon)<TBackButtonIconProps>`
  grid-column: ${({ smallLayout }) => (smallLayout ? "1 / 3" : "1")};
  grid-row: ${({ smallLayout }) => (smallLayout ? "1 / 3" : "1")};
`;

export type TBackButtonIconProps = {
  smallLayout: boolean;
};

export type TBackButtonProps = {
  callback: () => void;
  tooltip?: string;
};

export const BackButton: FC<TBackButtonProps> = ({ callback, tooltip }) => {
  const smallLayout = useSmallLayout();
  const { IconNavigateLeft } = useIcon();

  return (
    <BackButtonIcon
      aria-label={`${tooltip} (${HotkeyTooltips.CLOSE.toUpperCase()})`}
      data-microtip-position="bottom"
      data-tooltip
      onClick={callback}
      smallLayout={smallLayout}
    >
      <IconNavigateLeft />
    </BackButtonIcon>
  );
};
