import { TContentItemMetadata, TSeriesRelatedContentImpl } from "./types/types";

export const listPadding = 2;

export const clampToFullPageRenderingOffset = ({
  pageSize,
  renderingOffset,
  totalChannelsInEngagement,
}: {
  pageSize: number;
  renderingOffset: number;
  totalChannelsInEngagement: number;
}) => {
  const isSinglePage = totalChannelsInEngagement <= pageSize;

  // Always show full page if all channels fit in a single page
  if (isSinglePage) return 0;

  // Always show a full page
  if (renderingOffset + pageSize > totalChannelsInEngagement) {
    return totalChannelsInEngagement - pageSize;
  }

  // Prevent navigation to a negative index
  return Math.max(renderingOffset, 0);
};

export const getEpisodesForCurrentSeason = ({
  relatedContent,
  season,
}: {
  relatedContent: TSeriesRelatedContentImpl;
  season: number;
}) => {
  const { seasons } = relatedContent;
  const wantedSeason = seasons.find((s) => s.season === season);
  // something went wrong, mismatch in data, shouldn't happen.
  if (!wantedSeason) return 0;
  // the number of episodes in the wanted season
  return wantedSeason.episodes;
};

export const isPlayable = (
  i: TContentItemMetadata,
  currentlyPlayingVideoId?: string
) => {
  if (i.type === "filler") return false;

  if (!i.playback) return false;

  if (i.playback.playbackSpec.videoId === currentlyPlayingVideoId) return false;

  if (i.type === "sport") {
    const now = Date.now();
    return i.endTime > now && i.startTime < now;
  }

  if (i.type === "npvr") {
    const now = Date.now();
    // Prevent rec & watch interaction X seconds before end time
    // as a safety buffer, since attempting to record after end
    // time will result in an error.
    return i.currentProgram.endTime > now + 5000;
  }

  return true;
};
