enum OperatingSystem {
  WINDOWS = "windows",
  MAC_OS = "macos",
  IOS = "iOS",
  ANDROID = "android",
  LINUX = "linux",
  CHROME_OS = "chromeos",
  CHROMECAST = "chromecast",
  BLACKBERRY = "blackberry",
  WINDOWS_PHONE = "windowsphone",
  UNKNOWN = "",
}

export const getPlatform = (): string => {
  if (window.navigator && window.navigator.platform) {
    return window.navigator.platform.toLowerCase();
  }
  return "";
};

export const getUserAgent = (): string => {
  if (window.navigator && window.navigator.userAgent) {
    return window.navigator.userAgent.toLowerCase();
  }
  return "";
};

export const getOS = (userAgent: string, platform: string): string => {
  const userAgentLowerCase = userAgent.toLowerCase();

  if (/windows nt/.test(userAgentLowerCase)) {
    return OperatingSystem.WINDOWS;
  }

  if (
    platform.toLowerCase().indexOf("mac") >= 0 ||
    /macintosh/.test(userAgentLowerCase)
  ) {
    return OperatingSystem.MAC_OS;
  }

  if (/iphone|ipod|ipad/.test(userAgentLowerCase)) {
    return OperatingSystem.IOS;
  }

  if (/android/.test(userAgentLowerCase) && !/crkey/.test(userAgentLowerCase)) {
    return OperatingSystem.ANDROID;
  }

  if (/crkey/.test(userAgentLowerCase)) {
    return OperatingSystem.CHROMECAST;
  }

  if (
    (platform.toLowerCase().indexOf("linux") >= 0 ||
      /linux/.test(userAgentLowerCase)) &&
    !/crkey/.test(userAgentLowerCase)
  ) {
    return OperatingSystem.LINUX;
  }

  if (/cros/.test(userAgentLowerCase)) {
    return OperatingSystem.CHROME_OS;
  }

  if (/blackberry/.test(userAgentLowerCase)) {
    return OperatingSystem.BLACKBERRY;
  }

  if (/windows phone|iemobile|wpdesktop/.test(userAgentLowerCase)) {
    return OperatingSystem.WINDOWS_PHONE;
  }

  return OperatingSystem.UNKNOWN;
};

export const getOSName = (): string => getOS(getUserAgent(), getPlatform());

export const isAndroidBrowser = (): boolean =>
  getOSName() === OperatingSystem.ANDROID;
export const isChromecast = (): boolean =>
  getOSName() === OperatingSystem.CHROMECAST;
export const isIOsBrowser = (): boolean => getOSName() === OperatingSystem.IOS;
