import { AdvertisementEndedEvent } from "./AdvertisementEndedEvent";
import { AdvertisementStartedEvent } from "./AdvertisementStartedEvent";
import { AdvertisementBreakEndedEvent } from "./AdvertisementBreakEndedEvent";
import { AdvertisementBreakStartedEvent } from "./AdvertisementBreakStartedEvent";
import { AdvertisementTimeUpdateEvent } from "./AdvertisementTimeUpdateEvent";
import { AudioTrackChangedEvent } from "./AudioTrackChangedEvent";
import { BitrateChangedEvent } from "./BitrateChangedEvent";
import { BufferedEvent } from "./BufferedEvent";
import { BufferingEvent } from "./BufferingEvent";
import { CdnChangedEvent } from "./CdnChangedEvent";
import { CustomTrackingEvent } from "./CustomTrackingEvent";
import { DroppedFramesEvent } from "./DroppedFramesEvent";
import { LoadedEvent } from "./LoadedEvent";
import { LoadingEvent } from "./LoadingEvent";
import { ManifestTypeChangedEvent } from "./ManifestTypeChangedEvent";
import { PausedEvent } from "./PausedEvent";
import { PlayEvent } from "./PlayEvent";
import { PlaybackErrorEvent } from "./PlaybackErrorEvent";
import { PlayingEvent } from "./PlayingEvent";
import { SeekedEvent } from "./SeekedEvent";
import { SeekingEvent } from "./SeekingEvent";
import { StartingEvent } from "./StartingEvent";
import { StoppedEvent } from "./StoppedEvent";
import { StreamCueEvent } from "./StreamCueEvent";
import { StreamInfoEvent } from "./StreamInfoEvent";
import { TextTrackChangedEvent } from "./TextTrackChangedEvent";
import { TextTrackVisibilityEvent } from "./TextTrackVisibilityEvent";
import { TimeUpdateEvent } from "./TimeUpdateEvent";
import { TrackingInfoEvent } from "./TrackingInfoEvent";
import { VolumeChangedEvent } from "./VolumeChangedEvent";
import { PlaybackEventTypes } from "../Constants";

export type EventRecord = {
  [PlaybackEventTypes.ADVERTISEMENT_ENDED]: AdvertisementEndedEvent;
  [PlaybackEventTypes.ADVERTISEMENT_STARTED]: AdvertisementStartedEvent;
  [PlaybackEventTypes.ADVERTISEMENT_BREAK_ENDED]: AdvertisementBreakEndedEvent;
  [PlaybackEventTypes.ADVERTISEMENT_BREAK_STARTED]: AdvertisementBreakStartedEvent;
  [PlaybackEventTypes.ADVERTISEMENT_TIME_UPDATE]: AdvertisementTimeUpdateEvent;
  [PlaybackEventTypes.AUDIO_TRACK_CHANGED]: AudioTrackChangedEvent;
  [PlaybackEventTypes.BITRATE_CHANGED]: BitrateChangedEvent;
  [PlaybackEventTypes.BUFFERED]: BufferedEvent;
  [PlaybackEventTypes.BUFFERING]: BufferingEvent;
  [PlaybackEventTypes.CDN_CHANGED]: CdnChangedEvent;
  [PlaybackEventTypes.CUSTOM_EVENT]: CustomTrackingEvent;
  [PlaybackEventTypes.DROPPED_FRAMES]: DroppedFramesEvent;
  [PlaybackEventTypes.LOADED]: LoadedEvent;
  [PlaybackEventTypes.LOADING]: LoadingEvent;
  [PlaybackEventTypes.MANIFEST_TYPE_CHANGED]: ManifestTypeChangedEvent;
  [PlaybackEventTypes.PAUSED]: PausedEvent;
  [PlaybackEventTypes.PLAY]: PlayEvent;
  [PlaybackEventTypes.ERROR]: PlaybackErrorEvent;
  [PlaybackEventTypes.PLAYING]: PlayingEvent;
  [PlaybackEventTypes.SEEKED]: SeekedEvent;
  [PlaybackEventTypes.SEEKING]: SeekingEvent;
  [PlaybackEventTypes.STARTING]: StartingEvent;
  [PlaybackEventTypes.STOPPED]: StoppedEvent;
  [PlaybackEventTypes.STREAM_CUE]: StreamCueEvent;
  [PlaybackEventTypes.STREAM_INFO]: StreamInfoEvent;
  [PlaybackEventTypes.TEXT_TRACK_CHANGED]: TextTrackChangedEvent;
  [PlaybackEventTypes.TEXT_TRACK_VISIBILITY_CHANGED]: TextTrackVisibilityEvent;
  [PlaybackEventTypes.TIME_UPDATE]: TimeUpdateEvent;
  [PlaybackEventTypes.VOLUME_CHANGED]: VolumeChangedEvent;
  [PlaybackEventTypes.TRACKING_INFO]: TrackingInfoEvent;
};

export type ExcludeMethods<T> = {
  [K in keyof T as T[K] extends Function ? never : K]: T[K];
};

export type TLanguageCode = {
  ar: string;
  bg: string;
  cs: string;
  da: string;
  de: string;
  el: string;
  en: string;
  es: string;
  et: string;
  fa: string;
  fi: string;
  fr: string;
  he: string;
  hi: string;
  hr: string;
  hu: string;
  is: string;
  it: string;
  ja: string;
  lt: string;
  lv: string;
  mk: string;
  nl: string;
  no: string;
  nb: string;
  pl: string;
  pt: string;
  qc: string;
  qd: string;
  ro: string;
  ru: string;
  sk: string;
  sl: string;
  sq: string;
  sr: string;
  sv: string;
  tr: string;
  uk: string;
  zh: string;
  zu: string;
  qad: string;
};

export type TEmptyPayload = Record<string, never>;

export type TPlaybackEvent =
  | AdvertisementEndedEvent
  | AdvertisementStartedEvent
  | AdvertisementBreakEndedEvent
  | AdvertisementBreakStartedEvent
  | AdvertisementTimeUpdateEvent
  | AudioTrackChangedEvent
  | BitrateChangedEvent
  | BufferedEvent
  | BufferingEvent
  | CdnChangedEvent
  | CustomTrackingEvent
  | DroppedFramesEvent
  | LoadedEvent
  | LoadingEvent
  | ManifestTypeChangedEvent
  | PausedEvent
  | PlayEvent
  | PlaybackErrorEvent
  | PlayingEvent
  | SeekedEvent
  | SeekingEvent
  | StartingEvent
  | StoppedEvent
  | StreamCueEvent
  | StreamInfoEvent
  | TextTrackChangedEvent
  | TextTrackVisibilityEvent
  | TimeUpdateEvent
  | VolumeChangedEvent
  | TrackingInfoEvent;
