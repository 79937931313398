import { WebPlayerEventType } from "@telia-company/tv.web-player-shared";

export const getResponseFilters = () => {
  let firstManifestType = null;
  let wasDynamic = false;
  let hasChanged = false;
  let publishFn = () => {};

  // Fix for when startovers change from dynamic to static manifest after recording is completed
  const manifestFilter = (type, response) => {
    // type: 0 === shaka.net.NetworkingEngine.html.RequestType.Manifest
    if (type !== 0) return;

    // if the first manifest is static we're playing a vod,
    // no need to do any more manifest filtering
    if (firstManifestType === "static") {
      return;
    }

    const textDecoder = new TextDecoder();

    try {
      const manifestStr = textDecoder.decode(response.data);
      const parser = new DOMParser();
      const manifestDoc = parser.parseFromString(
        manifestStr,
        "application/xml"
      );

      const manifestType =
        manifestDoc.getElementsByTagName("MPD")[0].attributes.type.value;

      if (!firstManifestType) {
        firstManifestType = manifestType;
      }

      if (wasDynamic && !hasChanged && manifestType === "static") {
        // manifest has switched from dynamic -> static
        publishFn({
          payload: {},
          type: WebPlayerEventType.MANIFEST_TYPE_CHANGED,
        });
        hasChanged = true;
      }

      if (manifestType === "dynamic") {
        wasDynamic = true;
      }
    } catch (_) {
      // Ignore errors and return unmodified manifest if modifications fail
    }
  };

  const responseFilters = {
    manifestFilter,
    setManifestChangedHandler: (publish) => {
      publishFn = publish;
    },
  };
  return responseFilters;
};
