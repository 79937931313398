const { default: Log } = require('../../common/log')
var Adapter = require('adapter')
var NpawVideo = require('../video/video')

const { default: DiagnosticTool } = require('../../diagnostic/DiagnosticTool')
const { AnalyticsTag } = require('../../common/Constants');

// This file is designed to add extra functionalities to Plugin class

/** @lends npaw.Plugin.prototype */
var PluginAdapterMixin = {
  /**
   *
   * @param {object|string} player
   * @param {string|object} adapterCode
   * @param {object} options
   * @param {object} videoKey
   */
  registerAdapter: function (player, adapterCode, options, videoKey) {
    if (!adapterCode) {
      Log.error(AnalyticsTag, 'Register Adapter needs an adapter to initialize');
      return;
    }

    options = options || {}

    videoKey = videoKey || 'default';
    if (!this.videos.existsVideo(videoKey)) {
      // First, create new empty video object
      var videoObj = new NpawVideo(videoKey, this, this.options, null, null);
      this.getNextViewIndex(videoObj);
      options = options || {};
      videoObj.setVideoOptions(options);
      this.videos.setVideo(videoObj);
      // Then, create adapter, and set adapter
      var adapter = new Adapter(videoKey, videoObj, this, this.adapterTemplates, player, adapterCode);
      this.setAdapter(adapter, videoKey);
    } else {
      options = options || {}
      this.videos.getVideo(videoKey).mergeOptions(options)
      this.setAdapter(new Adapter(videoKey, this.videos.getVideo(videoKey), this, this.adapterTemplates, player, adapterCode), videoKey)
    }
    DiagnosticTool.getInstance().registeredPlayer(true);
    let resource = options['content.resource'];
    if (!resource && this.videos.existsAdapter(videoKey)) {
      resource = this.videos.getAdapter(videoKey).getURLToParse();
      if (!resource) {
        resource = this.videos.getAdapter(videoKey).getResource();
      }
    }
    this.resourceTransform.init(resource);
  },

  /**
   * Sets an adapter for video content.
   *
   * @param {Adapter} adapter
   * @param {string} videoKey
   *
   * @memberof npaw.Plugin.prototype
   */
  setAdapter: function (adapter, videoKey) {
    videoKey = videoKey || 'default'
    if (this.browserLoadTimes) this.browserLoadTimes.setPlayerSetupTime()
    if (adapter.isSetAdapter()) {
      Log.warn(AnalyticsTag, 'Adapters can only be added to a single plugin')
    } else {
      // Pass old adapter state to new adapter
      var oldAdapter = this.getAdapter(videoKey)
      if (oldAdapter) {
        adapter.flags = oldAdapter.flags
        adapter.chronos = oldAdapter.chronos
        adapter.fireEventsStruct = oldAdapter.fireEventsStruct
      }

      this.removeAdapter(videoKey)
      this.videos.setAdapter(adapter, videoKey)
    }
  },

  /**
   * Returns current adapter or null.
   *
   * @param {string} videoKey
   * @returns {Adapter}
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdapter: function (videoKey) {
    videoKey = videoKey || 'default'
    return this.videos.getAdapter(videoKey)
  },

  /**
   * Removes the current adapter. Fires stop if needed. Calls adapter.dispose().
   *
   * @param videoKey
   * @memberof npaw.Plugin.prototype
   * */
  removeAdapter: function (videoKey) {
    videoKey = videoKey || 'default';
    if (this.videos.existsVideo(videoKey)) {
      this.videos.removeAdapter(this.contentAdapterListeners, videoKey);
    }
  },

  getNextViewIndex: function (videoObj) {
    videoObj.nextView();
    // Verify if the video code is already in use
    let existsCode = true;
    while (existsCode) {
      let found = false;
      for (const key of this.videos.getVideoKeys().filter((key) => key !== videoObj.getVideoKey())) {
        if (videoObj.getViewCode() === this.videos.getVideo(key).getViewCode()) {
          found = true;
          break;
        }
      }
      if (found) videoObj.nextView();
      existsCode = found;
    }
  }
};

module.exports = PluginAdapterMixin;
