import { PlayerControls } from "@telia-company/tv.web-player-shared";
import { useEffect } from "react";

import { EngineState } from "../constants";

export type TUseAutoplayOptions = {
  autoplay: boolean;
  controls: PlayerControls | undefined;
  engineState: EngineState;
};

export const useAutoplay = ({
  autoplay,
  controls,
  engineState,
}: TUseAutoplayOptions): void => {
  useEffect(() => {
    if (engineState !== EngineState.READY) return;

    if (autoplay && controls) controls.play();
  }, [autoplay, engineState, controls]);
};
