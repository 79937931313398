import { AdvertisementBreakTypes } from "../Constants";
import { AdvertisementInfo } from "./AdvertisementInfo";

const END_MARGIN = 0.5;

export class AdvertisementBreakInfo {
  readonly id: string;
  readonly name: string;
  readonly active: boolean;
  readonly breakType: AdvertisementBreakTypes;
  readonly durationInSeconds: number;
  readonly advertisements: Array<AdvertisementInfo>;
  readonly embedded: boolean;
  readonly position: number;
  readonly watched: boolean;

  constructor(
    args: Omit<
      AdvertisementBreakInfo,
      | "active"
      | "watched"
      | "getEndPosition"
      | "isPositionInAdvertisementBreak"
      | "isPositionPastAdvertisementBreak"
    > & {
      active?: boolean;
      watched?: boolean;
    }
  ) {
    this.id = args.id;
    this.name = args.name;
    this.active = args.active ?? false;
    this.breakType = args.breakType;
    this.durationInSeconds = args.durationInSeconds;
    this.advertisements = args.advertisements;
    this.embedded = args.embedded;
    this.position = args.position;
    this.watched = args.watched ?? false;
  }

  public getEndPosition(): number {
    return this.position + this.durationInSeconds + END_MARGIN;
  }

  public isPositionInAdvertisementBreak(position: number): boolean {
    return position >= this.position && position < this.getEndPosition();
  }

  public isPositionPastAdvertisementBreak(position: number): boolean {
    return position >= this.getEndPosition();
  }
}
