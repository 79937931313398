import styled from "@emotion/styled";
import { TCallback } from "@telia-company/tv.web-player-shared";
import { FC, ReactNode } from "react";

import { useCustomStyles } from "../context-provider";

export const ButtonReset = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
`;

const padding = "0.8em 1.2em";
const textColor = "#ffffff";
const buttonBackground = "rgba(255,255,255, 0.15)";
const buttonHoverBackground = "rgba(255,255,255, 0.3)";

const StyledButton = styled(ButtonReset)<{
  background?: string;
  buttonBorderRadius: string;
}>`
  cursor: pointer;
  font-size: 0.8em;
  padding: ${padding};
  color: ${textColor};

  ${({ buttonBorderRadius }) => `
    border-radius: ${buttonBorderRadius};
  `}

  background: ${({ background }) => background || `${buttonBackground}`};

  &:hover {
    ${({ background }) =>
      background
        ? `filter: brightness(115%);`
        : `background: ${buttonHoverBackground};`}
  }
`;

export const BaseButton: FC<{
  background?: string;
  children: ReactNode;
  onClick: TCallback;
}> = ({ background, children, onClick }) => {
  const { buttonBorderRadius } = useCustomStyles();

  return (
    <StyledButton
      background={background}
      buttonBorderRadius={buttonBorderRadius}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};
