import {
  ErrorCategories,
  PlaybackEventTypes,
  StandardError,
} from "@telia-company/tv.web-playback-sdk";
import { getSessionTimeoutFilter } from "@telia-company/tv.web-player-shaka-util";
import {
  isArray,
  TUnfortunatelyAny,
} from "@telia-company/tv.web-player-shared";
import shaka from "shaka-player";

import { TEngineMethodOptions } from "../../@types/types";
import { getManifestStallFilter } from "../../network-filters/manifest-stall-filter";
import { applyShakaEmsgFilter } from "./shaka-emsg-response-filter";

const registerManifestStallFilter = (
  options: TEngineMethodOptions,
  noDrm: boolean
) => {
  const listener = () => {
    const nwEngine = options.player.getNetworkingEngine();
    const manifestStallFilter = getManifestStallFilter(options);

    nwEngine.registerResponseFilter(manifestStallFilter);

    options.event.on(PlaybackEventTypes.LOADED, () =>
      nwEngine.unregisterResponseFilter(manifestStallFilter)
    );
  };

  if (noDrm) {
    listener();
  } else {
    options.player.addEventListener("drmsessionupdate", listener);

    options.event.on(PlaybackEventTypes.LOADED, () =>
      options.player.removeEventListener("drmsessionupdate", listener)
    );
  }
};

const vodManifestNotFound = (options: TEngineMethodOptions, isVod: boolean) => {
  const nwEngine = options.player.getNetworkingEngine();
  const { callbacks } = options;
  const vodManifestNotFoundHandler = (error: TUnfortunatelyAny) => {
    const {
      error: { code, data },
    } = error;

    if (code === 1001) {
      if (
        isVod &&
        isArray(data) &&
        data[1] === 404 &&
        data[4] === shaka.net.NetworkingEngine.RequestType.MANIFEST
      ) {
        // NOTE: will shut down the stream immediately
        callbacks.onError({
          error: new StandardError({
            category: ErrorCategories.NETWORK,
            code: "MANIFEST_NOT_FOUND",
            fatal: true,
            message: "Could not find manifest (404)",
            originalError: new shaka.util.Error(2, 1, "MANIFEST_NOT_FOUND", {
              reason: "Could not find manifest (404)",
            }),
          }),
        });
      } else if (
        isArray(data) &&
        data[1] === 410 &&
        data[4] === shaka.net.NetworkingEngine.RequestType.MANIFEST
      ) {
        // NOTE: will shut down the stream immediately
        callbacks.onError({
          error: new StandardError({
            category: ErrorCategories.NETWORK,
            code: "MANIFEST_NOT_FOUND",
            fatal: true,
            message: "Could not find manifest (410)",
            originalError: new shaka.util.Error(2, 1, "MANIFEST_NOT_FOUND", {
              reason: "Could not find manifest (410)",
            }),
          }),
        });
      }
    }
  };

  nwEngine.addEventListener("retry", vodManifestNotFoundHandler);
  options.event.on(PlaybackEventTypes.LOADED, () => {
    nwEngine.removeEventListener("retry", vodManifestNotFoundHandler);
  });
};

export type TRequestFilterOptions = {
  isDash: boolean;
  isHls: boolean;
  isVod: boolean;
  noDrm: boolean;
  options: TEngineMethodOptions;
};

export const setupRequestResponseFilter = ({
  isDash,
  isVod,
  noDrm,
  options,
}: TRequestFilterOptions): void => {
  const nwEngine = options.player.getNetworkingEngine();

  nwEngine.registerRequestFilter(getSessionTimeoutFilter());

  if (isDash) {
    registerManifestStallFilter(options, noDrm);
  }

  // To read id3 tags for ad tracking
  applyShakaEmsgFilter(options, nwEngine);

  vodManifestNotFound(options, isVod);
};
