import IEpisode = GraphTypes.IEpisode;
import { Icons, NpvrInfo, Program, ProgramMedia, TimeRange } from "../Types";
import { PlaybackSpec } from "../../../base";
import Media = GraphTypes.Media;
import ISeries = GraphTypes.ISeries;
import IChannel = GraphTypes.IChannel;
import IProgram = GraphTypes.IProgram;

export const ChannelProgramMapper = (
  channel: IChannel,
  timeRange?: TimeRange
): Array<Program> => {
  let programs: Array<Program> = [];
  if (channel.programs && channel.programs.programItems) {
    for (let i in channel.programs.programItems) {
      if (!channel.programs.programItems.hasOwnProperty(i)) continue;

      const programItem: IProgram = channel.programs.programItems[i];

      const programMedia: ProgramMedia = {
        id: programItem.media.id,
        title: programItem.media.title,
        description: programItem.media.description ?? "",
        images: {},
      };

      if (IsEpisode(programItem.media)) {
        if (programItem.media.series && programItem.media.series.title) {
          programMedia.seriesTitle = programItem.media.series.title;
        }

        if (programItem.media.seasonNumber) {
          programMedia.season = programItem.media.seasonNumber.number;
        }

        if (programItem.media.episodeNumber) {
          programMedia.episode = programItem.media.episodeNumber.number;
        }
      }
      const graphImages: any = programItem.media.images;
      for (let key in graphImages) {
        if (!graphImages.hasOwnProperty(key) || !graphImages[key]) continue;

        if (graphImages[key].source) {
          //@ts-ignore
          programMedia.images[key] = decodeURIComponent(
            graphImages[key].source
          );
        } else if (graphImages[key].sourceNonEncoded) {
          //@ts-ignore
          programMedia.images[key] = graphImages[key].sourceNonEncoded;
        }
      }

      //trimming programItems...
      if (timeRange && programItem.startTime.timestamp > timeRange.end)
        continue;

      programs.push({
        id: programItem.id,
        startTime: programItem.startTime.timestamp,
        endTime: programItem.endTime.timestamp,
        live: programItem.live,
        rerun: programItem.rerun,
        startoverPlaybackSpec: programItem.media?.playback?.play?.linear?.item
          ?.startover?.playbackSpec as PlaybackSpec,
        media: programMedia,
        npvrInfo: getNpvrInfo(programItem.media),
      });
    }
  }
  return programs;
};

export const ChannelIconMapper = (channel: IChannel): Icons => {
  return {
    light:
      channel.icons2.compact.light.sourceNonEncoded ??
      (channel.icons2.compact.light.source
        ? decodeURIComponent(channel.icons2.large.light.source)
        : undefined),
    dark:
      channel.icons2.compact.dark.sourceNonEncoded ??
      (channel.icons2.compact.dark.source
        ? decodeURIComponent(channel.icons2.large.dark.source)
        : undefined),
  };
};

export const getNpvrInfo = (media: Media): NpvrInfo => {
  const series: ISeries | undefined = IsEpisode(media)
    ? media.series
    : undefined;
  const now = Date.now();
  const isRecorded = !!media.playback?.play?.npvr?.[0].item?.playbackSpec;
  const hasRecordablePlaybackSpec =
    media.playback?.buy?.npvr?.[0].item?.playbackSpec &&
    media.playback.buy?.npvr?.[0].recordableBefore?.timestamp > now;
  const recordableVideoId =
    media.playback?.buy?.npvr?.[0].item?.playbackSpec.videoId;
  const canBeRecorded =
    hasRecordablePlaybackSpec && typeof recordableVideoId === "string";
  const seriesRecording =
    !!media.playback?.play?.npvr?.[0].npvrInfo.series?.active ??
    series?.userData?.npvrInfo?.active ??
    false;
  const isSeriesRecordable = !!series?.isRecordable;
  const recordableBefore = canBeRecorded
    ? media.playback?.buy?.npvr?.[0].recordableBefore?.timestamp
    : null;

  let recordablePlaybackSpec = ((): PlaybackSpec | undefined => {
    let s = media.playback?.play?.npvr?.[0]?.item?.playbackSpec;
    if (s && isRecorded) {
      return {
        ...s,
      };
    }
  })();

  return {
    media: {
      seriesRecording,
      isSeriesRecordable,
      isRecordable: !!canBeRecorded,
      recordableBefore,
      metadata: {
        id: media.id,
        seriesId: series?.id,
        title: media.title,
      },
      recordedPlaybackSpec: recordablePlaybackSpec,
    },
  };
};

export const IsEpisode = (value: any): value is IEpisode =>
  value.__typename === "Episode";
