import { Dispatch, useEffect, useState } from "react";

import { TPlaybackType } from "../@types/types";
import { UiEventTypes } from "../constants";
import { TAction } from "./ui-state-reducer.hook";

export type TUseDetectProgramChangeOptions = {
  currentTime: null | number;
  dispatch: Dispatch<TAction>;
  endTime?: number;
  liveSeekEnabled: boolean;
  playbackType: null | TPlaybackType;
  seekRange?: {
    end: number;
    start: number;
  };
  startTime?: number;
};

export const useDetectProgramChange = ({
  currentTime,
  dispatch,
  endTime,
  liveSeekEnabled,
  playbackType,
  seekRange,
  startTime,
}: TUseDetectProgramChangeOptions): void => {
  const [isOutsideDvrWindow, setIsOutsideDvrWindow] = useState(false);
  const [isOutsideCurrentProgram, setIsOutsideCurrentProgram] = useState(false);

  // Check if currentTime exited seekRange
  useEffect(() => {
    if (playbackType !== "linear-channel") return;

    if (typeof currentTime !== "number") return;

    if (seekRange && currentTime < seekRange.start) {
      // we're paused, and have fallen out of the dvr window
      setIsOutsideDvrWindow(true);
    } else {
      setIsOutsideDvrWindow(false);
    }
  }, [seekRange, currentTime, playbackType]);

  // Check if currentTime exited program boundaries
  useEffect(() => {
    if (isOutsideDvrWindow) return;

    if (playbackType !== "linear-channel") return;

    if (!endTime || !currentTime) return;

    const timestamp = Date.now();
    const padding = 10; // padding to avoid metadata fetch loop when playing close to seekRange.end

    // some janky handling of programme changes within the DVR window
    // it is based on currentTime & seekRange instead of Date.now() & startTime/endTime
    if (liveSeekEnabled && seekRange && startTime) {
      if (currentTime > seekRange.end + padding) {
        setIsOutsideCurrentProgram(true);
      } else if (currentTime < seekRange.start) {
        setIsOutsideCurrentProgram(true);
      } else {
        setIsOutsideCurrentProgram(false);
      }
    }
    if (!liveSeekEnabled) {
      if (timestamp > endTime) {
        setIsOutsideCurrentProgram(true);
      } else if (startTime && timestamp < startTime) {
        setIsOutsideCurrentProgram(true);
      } else {
        setIsOutsideCurrentProgram(false);
      }
    }
  }, [
    isOutsideDvrWindow,
    currentTime,
    liveSeekEnabled,
    seekRange,
    startTime,
    endTime,
    playbackType,
  ]);

  useEffect(() => {
    if (playbackType !== "linear-channel") return;

    if (isOutsideCurrentProgram) {
      dispatch({
        payload: {},
        type: UiEventTypes.SHOULD_FETCH_METADATA,
      });
    }
  }, [playbackType, dispatch, isOutsideCurrentProgram]);
};
