import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import { PlaybackEventTypes } from "../Constants";

export type TTextTrackVisibilityEventPayload = {
  visible: boolean;
};

export class TextTrackVisibilityEvent extends PlaybackEventBase<
  PlaybackEventTypes.TEXT_TRACK_VISIBILITY_CHANGED,
  TTextTrackVisibilityEventPayload
> {
  constructor(payload: TTextTrackVisibilityEventPayload) {
    super(PlaybackEventTypes.TEXT_TRACK_VISIBILITY_CHANGED, payload);
  }
}
