import { useEffect, useState } from "react";

import { TPlaybackType } from "../@types/types";

export type TUseInactivityModal = {
  playbackType: null | TPlaybackType;
  showInactivityWarning: boolean;
};

export const useVodInactivityModal = ({
  playbackType,
  showInactivityWarning,
}: TUseInactivityModal) => {
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  useEffect(() => {
    if (showInactivityWarning && playbackType === "vod") {
      setShowInactivityModal(true);
    }
  }, [showInactivityWarning, playbackType, setShowInactivityModal]);

  const dismissInactivityModal = () => {
    setShowInactivityModal(false);
  };

  return { dismissInactivityModal, showInactivityModal };
};
