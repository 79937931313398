/* global atv */
var Transform = require('./transform')
var HlsParser = require('./resourceparsers/formatparsers/hlsparser')
var CdnParser = require('./resourceparsers/cdnparser')
var CdnSwitch = require('./resourceparsers/cdnSwitch')
var DashParser = require('./resourceparsers/formatparsers/dashparser')
var Parser = require('./resourceparsers/formatparsers/parser')
var LocationheaderParser = require('./resourceparsers/formatparsers/locationheaderparser')
var Constants = require('../../constants')
const { AnalyticsTag } = require('../../../common/Constants');
const { default: Log } = require('../../../common/log');

var ResourceTransform = Transform.extend(
  /** @lends npaw.ResourceTransform.prototype */
  {
    /**
     * This class parses resource to fetch HLS transportstreams and CDN-related info.
     *
     * @constructs ResourceTransform
     * @extends npaw.Transform
     * @memberof npaw
     *
     * @param {Plugin} plugin Instance of {@link Plugin}
     */
    constructor: function (plugin) {
      ResourceTransform.__super__.constructor.apply(this, arguments);

      this.plugin = plugin;
      this._realResource = undefined;
      this._transportFormat = undefined;
      this._initResource = undefined;
      this._cdnName = undefined;
      this._cdnNodeHost = undefined;
      this._cdnNodeTypeString = undefined;
      this._cdnNodeType = undefined;
      this._responses = {};
      this._isBusy = false;

      this.transformName = 'Resource';
    },

    /**
     * Get the resource. If the transform is done, the real (parsed) resource will be returned
     * Otherwise the initial one is returned.
     *
     * @return {string} The initial or parsed resource
     */
    getResource: function () {
      return this._realResource;
    },

    _getInitialCdnResource: function () {
      return this._realResource || this._initResource;
    },

    /**
     * Get the transport format. If the transform detected the chunk format it will be returned, if not, null.
     *
     * @return {string} The streaming protocol
     */
    getTransportFormat: function () {
      return this._transportFormat;
    },

    /**
     * Get the parsed CDN name.
     *
     * @return {string} The CDN name or null if unknown
     */
    getCdnName: function () {
      return this._cdnName;
    },

    /**
     * Get the parsed CDN node.
     *
     * @return {string} The CDN node or null if unknown
     */
    getNodeHost: function () {
      return this._cdnNodeHost;
    },

    /**
     * Get the parsed CDN type string, as returned in the cdn header response.
     *
     * @return {string} The CDN type string
     */
    getNodeTypeString: function () {
      return this._cdnNodeTypeString;
    },

    /**
     * Get the parsed CDN type, parsed from the type string.
     *
     * @return {string} The CDN type
     */
    getNodeType: function () {
      return this._cdnNodeType;
    },

    /**
     * Start the execution. Can be called more than once. If already running, it will be ignored,
     * if ended it will restart.
     * @param {string} resource the original resource
     */
    init: function (resource) {
      if (!resource) {
        this.done();
        return;
      }
      if (!this._isBusy) {
        this._isBusy = true;
        this._initResource = resource;
        this._parseManifestEnabled = this.plugin.isParseManifest();
        this._cdnEnabled = this.plugin.isParseCdnNode();
        this._cdnList = this.plugin.getParseCdnNodeList().slice(); // clone
        CdnParser.setBalancerHeaderName(this.plugin.getParseCdnNodeNameHeader(), this.plugin.getParseNodeHeader());

        this._setTimeout();
        if (this._parseManifestEnabled) {
          if (!this._isFinalUrl(this._initResource)) {
            this.parseManifest(); // Unknown lastManifest or lastSrc, call without arguments
          } else {
            this._realResource = this._initResource;
            this.done();
          }
        } else {
          this._parseCDN();
        }
      }
    },

    _isFinalUrl: function (url) {
      const segmentExtensions = ['.ts', '.mp4', '.m4s', '.cmfv'];
      url = url || '';
      for (const i in segmentExtensions) {
        const ext = segmentExtensions[i];
        if (url.lastIndexOf(ext) === url.length - ext.length) {
          return true;
        }
      }
      return false;
    },

    // done: function () {
    //  Transform.prototype.done.apply(this, arguments) // super
    // },

    _setTimeout: function () {
      // Abort operation after 3s
      const timeoutComplete = () => {
        if (this._isBusy) {
          this.done();
          Log.warn(AnalyticsTag, 'ResourceTransform has exceded the maximum execution time (3s) and will be aborted.')
        }
      };
      try {
        this._parseTimeout = setTimeout(timeoutComplete, 3000);
      } catch (err) {
        if (typeof atv !== 'undefined') {
          this._parseTimeout = atv.setTimeout(timeoutComplete, 3000);
        } else {
          Log.error(AnalyticsTag, err);
        }
      }
    },

    parseManifest: function (lastManifest, lastSrc) {
      const headers = this.plugin.options['parse.manifest.auth'];
      const parserArray = [new LocationheaderParser(headers), new DashParser(headers), new HlsParser(headers)];
      this._parseManifest(parserArray, lastManifest, lastSrc || this._initResource);
    },

    _parseManifest: function (parserArray, lastManifest, lastSrc, format) {
      if (parserArray.length > 0) {
        const parser = parserArray[0];
        if (parser.shouldExecute(lastManifest)) {
          parser.on(Parser.Event.DONE, () => {
            this._parseManifest(parserArray.slice(1, parserArray.length), parser.getLastManifest(), parser.getResource(), parser.getTransportFormat() || format)
          });
          parser.parse(lastSrc, lastManifest);
        } else {
          this._parseManifest(parserArray.slice(1, parserArray.length), lastManifest, lastSrc, format);
        }
      } else {
        this._transportFormat = format;
        this._realResource = lastSrc;
        this._parseCDN();
      }
    },

    _parseCDN: function () {
      if (this.plugin.isCdnSwitch()) {
        this.switchDetector = new CdnSwitch(this.plugin);
        this.switchDetector.on(CdnSwitch.Events.DONE, (resp) => {
          this._cdnName = resp.data;
          this.done();
        });

        this.switchDetector.on(CdnSwitch.Events.ERROR, () => {
          this.done();
        });
        this.switchDetector.init();
      } else if (this._cdnEnabled && this._cdnList.length > 0) {
        const cdn = this._cdnList.shift();

        const cdnParser = CdnParser.create(cdn);
        if (cdnParser) {
          cdnParser.on(CdnParser.Event.DONE, () => {
            this._responses = cdnParser.getResponses();
            this._cdnName = cdnParser.getParsedCdnName();
            this._cdnNodeHost = cdnParser.getParsedNodeHost();
            this._cdnNodeTypeString = cdnParser.getParsedNodeTypeString();
            this._cdnNodeType = cdnParser.getParsedNodeType();
            if (this.getNodeHost()) {
              this.done();
            } else {
              this._parseCDN();
            }
          });
          // Run parse
          cdnParser.parse(this._getInitialCdnResource(), this._responses);
        } else {
          this._parseCDN();
        }
      } else {
        this.done();
      }
    },

    /**
     * Replaces the resource and/or Cdn info for the /start service.
     *
     * @param {YBRequest} request YBRequest to transform.
     */
    parse: function (request) {
      if (request.service === Constants.Service.START) {
        const lastSent = this.plugin.requestBuilder.lastSent;
        lastSent.parsedResource = request.params.parsedResource = this.getResource() || request.params.parsedResource;
        lastSent.transportFormat = request.params.transportFormat = this.getTransportFormat() || request.params.transportFormat;
        if (this._cdnEnabled) {
          lastSent.cdn = request.params.cdn = request.params.cdn || this.getCdnName();
          lastSent.nodeHost = request.params.nodeHost = this.getNodeHost() || request.params.nodeHost;
          lastSent.nodeType = request.params.nodeType = this.getNodeType() || request.params.nodeType;
          lastSent.nodeTypeString = request.params.nodeTypeString = this.getNodeTypeString() || request.params.nodeTypeString;
        }
      }
    }
  }
);

module.exports = ResourceTransform;
