import styled from "@emotion/styled";
import { FC } from "react";

import { Panel } from "../hooks/continue-watching.hook";
import { getPreparedVideoElement } from "../misc/util";
import { playbackAction, useRefAppDispatch } from "../reducer/ref.reducer";

export type Props = {
  items: Panel;
  title: string;
};

const FlexRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.5rem;
  margin: 0 0 0.5rem;
`;

const PanelItem = styled.div`
  background: rgba(255, 255, 255, 0.2);
  padding: 0.5rem;
  display: grid;
  align-items: center;
  cursor: pointer;

  :hover {
    background: rgba(255, 255, 255, 0.4);
  }
`;

export const PanelTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0 0.5rem;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.2);
`;

export const Row: FC<Props> = ({ items, title }) => {
  const dispatch = useRefAppDispatch();

  if (!items.length) return null;

  return (
    <div>
      <PanelTitle>{title}</PanelTitle>
      <FlexRow>
        {items.map((i) => (
          <PanelItem
            key={i.playbackSpec.videoId}
            onClick={() =>
              dispatch(
                playbackAction({
                  overrideVideoElement: getPreparedVideoElement(),
                  playbackSpec: i.playbackSpec,
                })
              )
            }
          >
            <img alt={i.title} src={i.img} />
          </PanelItem>
        ))}
      </FlexRow>
    </div>
  );
};
