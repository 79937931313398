var NpawObject = require('../object')
var Timer = require('../timer')
var Chrono = require('../chrono')

var PlayheadMonitor = NpawObject.extend(
  /** @lends npaw.PlayheadMonitor.prototype */
  {
    /**
     * This class periodically checks the player's playhead in order to infer buffer and/or seek
     * events.
     *
     * Instances of this class are bounded to an {@link Adapter} and fires its buffer and seek
     * start and end methods.
     *
     * In order to use this feature, {@link Adapter#monitorPlayhead} should be used.
     *
     * @constructs PlayheadMonitor
     * @extends npaw.NpawObject
     * @memberof npaw
     *
     * @param {Adapter} adapter Adapter to monitor. Must have getPlayhead defined.
     * @param {PlayheadMonitor.Type} [type=NONE]
     * Which metric to monitor seek and/or buffer.
     * Use bitwise operators to join both values (Type.BUFFER | Type.SEEK)
     * @param {int} [interval=800] How many ms will wait between progress. -1 to disable.
     */
    constructor: function (adapter, type, interval) {
      this._adapter = adapter
      this._seekEnabled = type & PlayheadMonitor.Type.SEEK
      this._bufferEnabled = type & PlayheadMonitor.Type.BUFFER
      interval = interval || 800

      this._chrono = new Chrono()
      this._lastPlayhead = 0

      if (interval > 0) {
        this._timer = new Timer(this.progress.bind(this), interval)
      }
    },

    /**
     * Start interval checks.
     */
    start: function () {
      this.stop()
      if (this.canBeUsed()) this._timer.start()
    },

    /**
     * Stop interval checks.
     */
    stop: function () {
      this._lastPlayhead = 0
      if (this._timer) this._timer.stop()
    },

    skipNextTick: function () {
      this._lastPlayhead = 0
    },

    /**
     * Call this method at every tick of timeupdate/progress.
     * If you defined an interval, do not fire this method manually.
     */
    progress: function () {
      // Reset timer
      var deltaTime = this._chrono.stop()
      this._chrono.start()

      // Define thresholds
      var bufferThreshold = deltaTime * PlayheadMonitor.kBUFFER_THRESHOLD_RATIO
      var seekThreshold = deltaTime * PlayheadMonitor.kSEEK_THRESHOLD_RATIO

      if (this._adapter.getPlayrate && this._adapter.getPlayrate() && this._adapter.getPlayrate() !== 1) {
        bufferThreshold *= this._adapter.getPlayrate()
        seekThreshold *= this._adapter.getPlayrate()
      }

      // Calculate diff playhead
      var currentPlayhead = this._getPlayhead()
      var diffPlayhead = Math.abs(this._lastPlayhead - currentPlayhead) * 1000

      // Check if adapter has getVideoBufferLength method and get buffer length
      var bufferLength =
        typeof this._adapter.getVideoBufferLength === 'function' ? this._adapter.getVideoBufferLength() : null;

      if (diffPlayhead < bufferThreshold) {
        // Playhead is stalling > potential buffer
        if (
          this._bufferEnabled &&
          this._lastPlayhead > 0 &&
          !this._adapter.flags.isPaused &&
          !this._adapter.flags.isSeeking
        ) {
          const plugin = this._adapter.getPlugin();
          if (plugin && plugin.options && plugin.options.checkVideoBufferInPlayheadMonitor) {
            // Only fire buffer begin if we don't have a valid buffer length
            // This allows to distinguish potential buffers that were triggered when in PiP mode and screen is turned off
            if (bufferLength === null || bufferLength <= 0) {
              this._adapter.fireBufferBegin(null, false, 'playheadMonitor');
            }
          } else {
            this._adapter.fireBufferBegin(null, false, 'playheadMonitor');
          }
        }
      } else if (diffPlayhead > seekThreshold) {
        // Playhead has jumped > seek
        if (this._seekEnabled && this._lastPlayhead > 0) {
          this._adapter.fireSeekBegin(null, false, 'playheadMonitor')
        }
      } else {
        // Healthy
        if (this._seekEnabled) {
          this._adapter.fireSeekEnd(null, 'playheadMonitor')
        }
        if (this._bufferEnabled) {
          this._adapter.fireBufferEnd(null, 'playheadMonitor')
        }
      }

      // Update Playhead
      this._lastPlayhead = currentPlayhead
    },

    /**
     * Returns if the monitor can be used or not.
     * Enabled by default, except the case where the content is live and the option 'content.isLive.noMonitor' is true.
     * @public
     * @returns {boolean} If the monitor can be used or not.
     */
    canBeUsed: function () {
      const videoObject = this._adapter.getVideo();
      const plugin = this._adapter.getPlugin();
      if (plugin && plugin.getPlayheadMonitorEnabled() && (this._seekEnabled || this._bufferEnabled)) {
        return videoObject && videoObject.getIsLive() ? !plugin.options['content.isLive.noMonitor'] : true;
      } else {
        return false;
      }
    },

    /**
     * Returns adapter's playhead. Override to add a custom playhead getter.
     * @private
     * @returns {double} Playhead in seconds
     */
    _getPlayhead: function () {
      return this._adapter.getPlayhead()
    }
  },
  /** @lends npaw.PlayheadMonitor */
  {
    // Static methods

    /**
     * Enum for monitoring type
     * @enum
     */
    Type: {
      /** Would not monitor */
      NONE: 0,
      /** Sends buffer-begin/end */
      BUFFER: 1,
      /** Sends seek-begin/end */
      SEEK: 2
    },

    /** Buffer threshold */
    kBUFFER_THRESHOLD_RATIO: 0.5,

    /** Seek threshold */
    kSEEK_THRESHOLD_RATIO: 2
  })

module.exports = PlayheadMonitor
