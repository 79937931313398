var Util = require('../util');
const { default: Core } = require('../../core/Core');

/** @lends npaw.Plugin.prototype */
var PluginStorageMixin = {
  /**
   * Get fastdata response to set it in another plugin instance
   */
  getFastDataConfig: function () {
    return this.fastDataTransform.response.msg;
  },

  /**
   * Returns service host
   *
   * @memberof npaw.Plugin.prototype
   */
  getHost: function () {
    let host = Core.getInstance().getOptions().getFastDataHost();
    if (this.fastDataTransform && this.fastDataTransform.response && this.fastDataTransform.response.host) {
      host = this.fastDataTransform.response.host;
    }
    return Util.addProtocol(Util.stripProtocol(host), this.options['app.https']);
  },

  /**
   *
   * @returns {string}
   */
  getFastDataHost: function () {
    return Util.addProtocol(
      Util.stripProtocol(Core.getInstance().getOptions().getFastDataHost()),
      this.options['app.https']
    );
  },

  /**
   *
   * @returns {*}
   */
  getSession: function () {
    var ret = Core.getInstance().getCoreStorage().getStorages('session');
    // Some tvs set the value as 'undefined' as string when deleting the value
    if (ret === 'undefined') {
      ret = undefined;
    }
    if (!ret) {
      var data = this.getStoredData();
      if (data) {
        try {
          ret = JSON.parse(data).q.c;
        } catch (err) {
          // nothing
        }
      }
    }
    return ret;
  },

  getStorageHost: function () {
    let ret = null;
    const data = this.getStoredData();
    if (data) {
      try {
        ret = JSON.parse(data).q.h;
      } catch (err) {
        // nothing
      }
    }
    return ret ? Util.addProtocol(ret, this.options['app.https']) : ret;
  },

  getStoredData: function () {
    return Core.getInstance().getCoreStorage().getStorages('data');
  },

  getDataTime: function () {
    return Core.getInstance().getCoreStorage().getStorages('dataTime');
  },

  setStoredData: function (data) {
    this.storage.setStorages('data', data);
  },

  setSession: function (session) {
    this.storage.setStorages('session', session);
  },

  setDataTime: function (time) {
    this.storage.setStorages('dataTime', time);
  }
};

module.exports = PluginStorageMixin;
