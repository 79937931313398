import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";
import shaka from "shaka-player";

// import { ShakaAbrManager } from "../overrides/abr-manager";
import { TGetShakaInstanceOptions } from "../@types/types";
import { setUpCdnDetection } from "./load/cdn-detection";

const retryParameters = {
  backoffFactor: 2.2,
  baseDelay: 1500,
  connectionTimeout: 12000,
  fuzzFactor: 0.4,
  maxAttempts: 5,
  stallTimeout: 18000,
  timeout: 40000,
};

const shakaConfig = {
  abr: {
    bandwidthDowngradeTarget: 0.9,
    bandwidthUpgradeTarget: 0.75,
    // Used before a reliable estimate is available
    defaultBandwidthEstimate: 5000000, // 625 Kilobyte
    enabled: true,
    switchInterval: 4,
  },
  drm: {
    retryParameters: {
      ...retryParameters,
      backoffFactor: 2,
      baseDelay: 1200,
      connectionTimeout: 6000,
    },
  },
  manifest: {
    dash: {
      clockSyncUri: "https://time.akamai.com/?iso",
      ignoreMinBufferTime: true, // Enables startover to start from rebufferingGoal instead of manifest minBufferTime (30)
    },
    defaultPresentationDelay: 20,
    retryParameters: {
      ...retryParameters,
      // Do not modify max attempts without also modifying manifest fetch loop detection
      maxAttempts: 5,
    },
  },
  mediaSource: {
    forceTransmux: true,
  },
  streaming: {
    alwaysStreamText: false,
    // prevent MEDIA_SOURCE_OPERATION_THREW for stitched streams in Edge
    autoLowLatencyMode: true,
    bufferingGoal: 60,
    ignoreTextStreamFailures: true,
    rebufferingGoal: 6,
    retryParameters: {
      ...retryParameters,
      backoffFactor: 2.4,
      maxAttempts: 6,
    },
    useNativeHlsForFairPlay: true,
  },
  // abrFactory: ShakaAbrManager,
};

export const getShakaInstance = async (
  options: TGetShakaInstanceOptions
): Promise<TUnfortunatelyAny> => {
  const player = new shaka.Player();
  await player.attach(options.videoElement);
  // to be able to show version in the stats display
  player.version = shaka.Player.version;

  player.configure(shakaConfig);

  setUpCdnDetection(player, options);

  return player;
};
