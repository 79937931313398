import {
  TAudioTrack,
  TRelatedContentType,
  TTextTrack,
  TUnfortunatelyAny,
} from "@telia-company/tv.web-player-shared";
import { FC, useMemo } from "react";

import {
  TAudioListItems,
  TCastButtonCallback,
  TLanguageButtonProps,
  TPlaybackButtonProps,
  TPlaybackType,
  TRecordButtonProps,
  TRelatedContentButtonProps,
  TSkipBackButtonProps,
  TSkipForwardButtonProps,
  TStartoverButtonProps,
  TTextListItems,
} from "../@types/types";
import { JumpToLiveButton } from "./jump-to-live-button";
import { LanguageButton } from "./language-button";
import { TRelatedContentImplementation } from "./overlays/list-overlay/types/types";
import { PlaybackButton } from "./playback-button";
import { RecordButton } from "./record-button";
import { RelatedContentButton } from "./related-content-button";
import { SkipBackButton } from "./skip-backward-button";
import { SkipForwardButton } from "./skip-forward-button";
import { StartoverButton } from "./startover-button";

export type TRelatedContentLegacy =
  | {
      items: TUnfortunatelyAny;
      type: TRelatedContentType;
    }
  | undefined;

export type TUsePlaybackButton = {
  atLiveEdge: boolean;
  audioTracks: TAudioListItems;
  canJumpToLive: boolean;
  canRecordAndWatch: boolean;
  canStartOver: boolean | undefined;
  ccButtonCallback?: TCastButtonCallback;
  hasRecordingCbs: boolean;
  hideRelatedContent: boolean | undefined;
  isLive: boolean | null;
  isMediaRecordable: boolean;
  isOngoingStartoverOrLiveEvent: boolean;
  isRecording: boolean;
  isRecordingSeries: boolean;
  isSeriesRecordable: boolean;
  liveSeekEnabled: boolean;
  noFastForward: boolean | undefined;
  noPause: boolean | undefined;
  noRewind: boolean | undefined;
  playbackType: null | TPlaybackType;
  relatedContent: null | TRelatedContentImplementation;
  relatedContentLegacy: TRelatedContentLegacy;
  seekEnabled: boolean;
  seekIndicatorVisible: boolean;
  selectedAudioTrack: null | TAudioTrack;
  selectedTextTrack: null | TTextTrack;
  smallLayout: boolean;
  textTracks: TTextListItems;
};

export type TPlayButtonConfig = {
  Component: FC<TPlaybackButtonProps>;
  name: string;
  position: string;
};

export type TStartoverButtonConfig = {
  Component: FC<TStartoverButtonProps>;
  name: string;
  position: number;
};

export type TSkipBackButtonConfig = {
  Component: FC<TSkipBackButtonProps>;
  name: string;
  position: string;
};

export type TSkipForwardButtonConfig = {
  Component: FC<TSkipForwardButtonProps>;
  name: string;
  position: string;
};

export type TRelatedContentButtonConfig = {
  Component: FC<TRelatedContentButtonProps>;
  name: string;
  position: string;
};

export type TRecordButtonConfig = {
  Component: FC<TRecordButtonProps>;
  name: string;
  position: string;
};

export type TLanguageButtonConfig = {
  Component: FC<TLanguageButtonProps>;
  name: string;
  position: string;
};
export type TPlaybackButtons = {
  jumpToLiveButtonConfig?: TStartoverButtonConfig;
  languageButtonConfig?: TLanguageButtonConfig;
  playButtonConfig?: TPlayButtonConfig;
  recordAndWatchButtonConfig?: TStartoverButtonConfig;
  recordButtonConfig?: TRecordButtonConfig;
  relatedContentButtonConfig?: TRelatedContentButtonConfig;
  skipBackButtonConfig?: TSkipBackButtonConfig;
  skipForwardButtonConfig?: TSkipForwardButtonConfig;
  startoverButtonConfig?: TStartoverButtonConfig;
};

const getRightmostButtonPosition = (buttons: TPlaybackButtons) => {
  const numberOfButtons = Object.keys(buttons).length;
  return numberOfButtons + 1;
};

const getRelatedContentButtonPosition = (
  buttons: TPlaybackButtons,
  showRecordButton: boolean
) => {
  // check if we show recording button
  const numberOfButtons = Object.keys(buttons).length;
  const relatedContentPos = numberOfButtons > 3 ? "10 / 13" : "9 / 12";

  return showRecordButton ? relatedContentPos : "7 / 10";
};

const getRecordButtonPosition = (
  buttons: TPlaybackButtons,
  showRelatedContent: boolean
) => {
  const numberOfButtons = Object.keys(buttons).length;
  const recordButtonPos = numberOfButtons > 3 ? "7 / 10" : "6 / 9";

  return showRelatedContent ? recordButtonPos : "8 / 11";
};

const getLanguageButtonPosition = (
  ccButtonCallback: TCastButtonCallback | undefined
) => (typeof ccButtonCallback !== "undefined" ? "13" : "14");

const shouldShowLanguageMenu = (
  audioTracks: TAudioListItems,
  textTracks: TTextListItems,
  selectedAudioTrack: null | TAudioTrack,
  selectedTextTrack: null | TTextTrack
) => {
  if (!textTracks && !audioTracks) return false;
  if (!textTracks?.length && !(audioTracks?.length > 1)) return false;
  return !(!selectedAudioTrack && !(selectedTextTrack || textTracks.length));
};

export const usePlaybackButtons = ({
  atLiveEdge,
  audioTracks,
  canJumpToLive,
  canRecordAndWatch,
  canStartOver,
  ccButtonCallback,
  hasRecordingCbs,
  hideRelatedContent,
  isLive,
  isMediaRecordable,
  isOngoingStartoverOrLiveEvent,
  isRecording,
  isRecordingSeries,
  isSeriesRecordable,
  liveSeekEnabled,
  noFastForward,
  noPause,
  noRewind,
  playbackType,
  relatedContent,
  relatedContentLegacy,
  seekEnabled,
  seekIndicatorVisible,
  selectedAudioTrack,
  selectedTextTrack,
  smallLayout,
  textTracks,
}: TUsePlaybackButton): TPlaybackButtons => {
  const showLanguageMenu = useMemo(
    () =>
      shouldShowLanguageMenu(
        audioTracks,
        textTracks,
        selectedAudioTrack,
        selectedTextTrack
      ),
    [audioTracks, textTracks, selectedAudioTrack, selectedTextTrack]
  );

  const shouldShowStartoverButton =
    (seekEnabled && liveSeekEnabled && atLiveEdge) ||
    (!liveSeekEnabled &&
      !smallLayout &&
      canStartOver &&
      !canRecordAndWatch); /* && atLiveEdge */
  const shouldShowJumpToLiveButton =
    (seekEnabled && liveSeekEnabled && !atLiveEdge) ||
    (isOngoingStartoverOrLiveEvent &&
      canJumpToLive &&
      !shouldShowStartoverButton &&
      !canRecordAndWatch &&
      !smallLayout); /* && !atLiveEdge */

  const showRelatedContent = !!(relatedContent || relatedContentLegacy);

  // show button when we have recording callbacks, and we're either recording
  // or have the possibility to record
  const showRecordButton =
    playbackType !== "vod" &&
    hasRecordingCbs &&
    (isMediaRecordable ||
      isSeriesRecordable ||
      isRecording ||
      isRecordingSeries);

  const buttons: TPlaybackButtons = useMemo(() => {
    const btns: TPlaybackButtons = {};

    // if we have a pause button
    if (
      (seekEnabled && !noPause) ||
      (!isLive && !(smallLayout && seekIndicatorVisible))
    )
      btns.playButtonConfig = {
        Component: PlaybackButton,
        name: "PlaybackButton",
        position: smallLayout ? "7 / 11" : "1",
      };

    // skip backward
    if (seekEnabled && !noRewind)
      btns.skipBackButtonConfig = {
        Component: SkipBackButton,
        name: "SkipBackButton",
        position: smallLayout ? "3 / 6" : "2",
      };

    // skip forward
    if (seekEnabled && !noFastForward)
      btns.skipForwardButtonConfig = {
        Component: SkipForwardButton,
        name: "SkipForwardButton",
        position: smallLayout ? "12 / 15" : "3",
      };

    // if we have a record & watch-button
    if (canRecordAndWatch)
      btns.recordAndWatchButtonConfig = {
        Component: StartoverButton,
        name: "RecordAndWatchButton",
        position: getRightmostButtonPosition(btns),
      };

    // if we have a startover button
    if (shouldShowStartoverButton)
      btns.startoverButtonConfig = {
        Component: StartoverButton,
        name: "StartoverButton",
        position: getRightmostButtonPosition(btns),
      };

    // if we have a jump to live button
    if (shouldShowJumpToLiveButton) {
      btns.jumpToLiveButtonConfig = {
        Component: JumpToLiveButton,
        name: "JumpToLiveButton",
        position: getRightmostButtonPosition(btns),
      };
    }

    // related content
    if (!hideRelatedContent && showRelatedContent && !smallLayout) {
      btns.relatedContentButtonConfig = {
        Component: RelatedContentButton,
        name: "RelatedContentButton",
        position: getRelatedContentButtonPosition(btns, showRecordButton),
      };
    }

    // placeholder record button
    if (showRecordButton && !smallLayout) {
      btns.recordButtonConfig = {
        Component: RecordButton,
        name: "RecordButton",
        position: getRecordButtonPosition(btns, showRelatedContent),
      };
    }

    // language button
    if (showLanguageMenu && !smallLayout) {
      btns.languageButtonConfig = {
        Component: LanguageButton,
        name: "LanguageButton",
        position: getLanguageButtonPosition(ccButtonCallback),
      };
    }

    return btns;
  }, [
    hideRelatedContent,
    shouldShowJumpToLiveButton,
    showLanguageMenu,
    shouldShowStartoverButton,
    showRelatedContent,
    smallLayout,
    noPause,
    isLive,
    seekIndicatorVisible,
    seekEnabled,
    noFastForward,
    noRewind,
    canRecordAndWatch,
    ccButtonCallback,
    showRecordButton,
  ]);

  return buttons;
};
