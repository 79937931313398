import * as React from "react";

function SvgPause(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M26.214 3.929V28.07a.929.929 0 01-.928.929h-4.643a.929.929 0 01-.929-.929V3.93a.93.93 0 01.929-.929h4.643c.512 0 .928.416.928.929zM11.357 3H6.714a.929.929 0 00-.928.929V28.07c0 .513.415.929.928.929h4.643a.929.929 0 00.929-.929V3.93a.929.929 0 00-.929-.93z"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgPause;
