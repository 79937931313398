import { FC, useCallback, useMemo, useRef } from "react";

import { UiEventTypes } from "../constants";
import { dotAnimation } from "../utils";
import { CastButton } from "./cast-button";
// import { PipButton } from "./pip-button";
import {
  useClickHandler,
  useDispatch,
  useFeatureToggle,
  useIcon,
  useMetadataImpl,
  usePlayerState,
  useSmallLayout,
  useTranslations,
  useUiCallbacks,
} from "./context-provider";
import { FullscreenButton } from "./fullscreen-button";
import { useFullscreen } from "./fullscreen.hook";
import { usePlaybackButtons } from "./playback-buttons.hook";
import { useRecordAndWatchClickHandler } from "./record-and-watch-click-handler.hook";
import { useRecordings } from "./recordings.hook";
import { VolumeButton } from "./volume-button";
import { useVolume } from "./volume.hook";

export type TPlayerControlsProps = {
  canJumpToLive: boolean;
  isOngoingStartoverOrLiveEvent: boolean;
  skip: (direction: number) => void;
};

export const PlayerControlsContainer: FC<TPlayerControlsProps> = ({
  canJumpToLive,
  isOngoingStartoverOrLiveEvent,
  skip,
}) => {
  const smallLayout = useSmallLayout();
  const { onJumpToLive, onStartover, onTogglePlay } = useClickHandler();
  const { getRecordAndWatch, getRecordingCbs } = useMetadataImpl();
  const {
    atLiveEdge,
    audioTracks,
    fullscreen,
    isInAdBreak,
    isLive,
    liveSeekEnabled,
    metadata: {
      canStartOver,
      npvrInfo,
      relatedContent: relatedContentLegacy,
      startTime,
    },
    muted,
    paused,
    playbackType,
    recordingSpinner,
    relatedContent,
    seekIndicatorVisible,
    selectedAudioTrack,
    selectedTextTrack,
    subtitlesOff,
    textTracks,
    textTrackVisible,
    trickPlayRestrictions: { noFastForward, noPause, noRewind },
    volume,
  } = usePlayerState();
  const {
    audioTracks: audioTracksText,
    descriptiveAudio,
    hardOfHearingSubtitles,
    language,
    live,
    off,
    relatedContentSeries,
    startOver,
    subtitleTracks: subtitleTracksText,
    tooltips,
  } = useTranslations();
  const {
    IconChromecast,
    IconEnterFullscreen,
    IconExitFullscreen,
    IconJumpToLive,
    IconLanguageSettings,
    IconPause,
    IconPlay,
    IconRecording,
    IconRecordingActive,
    IconRecordingSeries,
    IconRelatedContent,
    IconSkipBack,
    IconSkipForward,
    IconStartOver,
    IconVolumeLow,
    IconVolumeMedium,
    IconVolumeOff,
    // IconPictureInPicture,
  } = useIcon();
  const { ccButtonCallback /* pipButtonCallback */ } = useUiCallbacks();
  const { fullscreenEnabled } = document;
  const dispatch = useDispatch();
  const { changeVolume, toggleMute } = useVolume();
  const { toggleFullscreen } = useFullscreen();
  const volumeDotRef = useRef<HTMLDivElement>(null);
  const { hideRelatedContent } = useFeatureToggle();
  const { recordAndWatchClickHandler } = useRecordAndWatchClickHandler();
  const { canRecordMedia, canRecordSeries } = useRecordings();
  const seekEnabled =
    playbackType === "vod" ||
    playbackType === "startover" ||
    (liveSeekEnabled && !!startTime);
  const hasRecordingCbs = !!getRecordingCbs;
  const canRecordAndWatch = !!getRecordAndWatch;
  const isMediaRecordable = canRecordMedia() && hasRecordingCbs;
  const isSeriesRecordable = canRecordSeries() && hasRecordingCbs;

  // should be updated when program or recording status changes
  const isRecording = !!npvrInfo?.media.recordedPlaybackSpec;
  const isRecordingSeries = !!npvrInfo?.media.seriesRecording;

  const hideProgressBar = useCallback(() => {
    dispatch({
      payload: {},
      type: UiEventTypes.HIDE_PROGRESS_BAR,
    });
  }, [dispatch]);

  const showProgressBar = useCallback(() => {
    dispatch({
      payload: {},
      type: UiEventTypes.SHOW_PROGRESS_BAR,
    });
  }, [dispatch]);

  const buttonOptions = useMemo(
    () => ({
      atLiveEdge,
      audioTracks,
      canJumpToLive,
      canRecordAndWatch,
      canStartOver,
      ccButtonCallback,
      hasRecordingCbs,
      hideRelatedContent,
      isLive,
      isMediaRecordable,
      isOngoingStartoverOrLiveEvent,
      isRecording,
      isRecordingSeries,
      isSeriesRecordable,
      liveSeekEnabled,
      noFastForward,
      noPause,
      noRewind,
      playbackType,
      relatedContent,
      relatedContentLegacy,
      seekEnabled,
      seekIndicatorVisible,
      selectedAudioTrack,
      selectedTextTrack,
      smallLayout,
      textTracks,
    }),
    [
      isLive,
      atLiveEdge,
      canStartOver,
      liveSeekEnabled,
      smallLayout,
      seekIndicatorVisible,
      seekEnabled,
      noFastForward,
      noRewind,
      hideRelatedContent,
      audioTracks,
      textTracks,
      selectedAudioTrack,
      selectedTextTrack,
      relatedContent,
      relatedContentLegacy,
      isOngoingStartoverOrLiveEvent,
      canJumpToLive,
      noPause,
      playbackType,
      canRecordAndWatch,
      isMediaRecordable,
      isSeriesRecordable,
      hasRecordingCbs,
      isRecording,
      isRecordingSeries,
      ccButtonCallback,
    ]
  );

  // calculate what buttons should be shown, and their positions
  // used for play/pause, skip buttons, startover/jump to live
  const buttons = usePlaybackButtons(buttonOptions);
  const languageButtonTextTranslations = useMemo(
    () => ({
      audioTracksText,
      descriptiveAudio,
      hardOfHearingSubtitles,
      language,
      off,
      subtitleTracksText,
    }),
    [
      off,
      language,
      subtitleTracksText,
      audioTracksText,
      descriptiveAudio,
      hardOfHearingSubtitles,
    ]
  );

  return (
    <>
      {buttons.playButtonConfig && (
        <buttons.playButtonConfig.Component
          clickHandler={onTogglePlay}
          paused={paused}
          PauseIcon={IconPause}
          PlayIcon={IconPlay}
          position={buttons.playButtonConfig.position}
          smallLayout={smallLayout}
          tooltips={tooltips}
        />
      )}
      {buttons.recordAndWatchButtonConfig && (
        <buttons.recordAndWatchButtonConfig.Component
          clickHandler={recordAndWatchClickHandler}
          Icon={IconStartOver}
          position={buttons.recordAndWatchButtonConfig.position}
          text={startOver}
          tooltip={tooltips?.START_OVER}
        />
      )}
      {buttons.startoverButtonConfig && (
        <buttons.startoverButtonConfig.Component
          clickHandler={onStartover}
          Icon={IconStartOver}
          position={buttons.startoverButtonConfig.position}
          text={startOver}
          tooltip={tooltips?.START_OVER}
        />
      )}
      {buttons.jumpToLiveButtonConfig && (
        <buttons.jumpToLiveButtonConfig.Component
          clickHandler={onJumpToLive}
          Icon={IconJumpToLive}
          position={buttons.jumpToLiveButtonConfig.position}
          text={live}
          tooltip={/* tooltips?.JUMP_TO_LIVE */ live}
        />
      )}

      {buttons.relatedContentButtonConfig && relatedContent && (
        <buttons.relatedContentButtonConfig.Component
          clickHandler={() =>
            dispatch({
              payload: {},
              type: UiEventTypes.SHOW_RELATED_CONTENT,
            })
          }
          Icon={relatedContent.openButtonIcon}
          isAlone={!textTracks?.length && !(audioTracks?.length > 1)}
          position={buttons.relatedContentButtonConfig.position}
          text={relatedContent.openButtonText}
          tooltips={tooltips}
        />
      )}

      {/* placeholder for recordings */}
      {buttons.recordButtonConfig && (
        <buttons.recordButtonConfig.Component
          AlternateIcon={IconRecordingActive}
          Icon={IconRecording}
          IconRecordingSeries={IconRecordingSeries}
          isAlone={false}
          isLoading={recordingSpinner}
          isRecording={isRecording}
          isRecordingSeries={isRecordingSeries}
          onMouseEnter={hideProgressBar}
          onMouseLeave={showProgressBar}
          position={buttons.recordButtonConfig.position}
        />
      )}

      {
        // legacy related content, to be removed when the new impl solves every case
        buttons.relatedContentButtonConfig &&
          relatedContentLegacy &&
          !relatedContent && (
            <buttons.relatedContentButtonConfig.Component
              clickHandler={() =>
                dispatch({
                  payload: {},
                  type: UiEventTypes.SHOW_RELATED_CONTENT,
                })
              }
              Icon={IconRelatedContent}
              isAlone={!textTracks?.length && !(audioTracks?.length > 1)}
              position={buttons.relatedContentButtonConfig.position}
              text={relatedContentSeries}
              tooltips={tooltips}
            />
          )
      }

      {buttons.languageButtonConfig && (
        <buttons.languageButtonConfig.Component
          audioTracks={audioTracks}
          LanguageIcon={IconLanguageSettings}
          onMouseEnter={hideProgressBar}
          onMouseLeave={showProgressBar}
          position={buttons.languageButtonConfig.position}
          selectedAudioTrack={selectedAudioTrack}
          selectedTextTrack={selectedTextTrack}
          subtitlesOff={subtitlesOff}
          text={languageButtonTextTranslations}
          textTracks={textTracks}
          textTrackVisible={textTrackVisible}
        />
      )}

      <VolumeButton
        changeVolume={changeVolume}
        muted={muted}
        MutedIcon={IconVolumeOff}
        onMouseEnter={hideProgressBar}
        onMouseLeave={() => {
          dotAnimation({ ref: volumeDotRef });
          showProgressBar();
        }}
        onToggleMute={toggleMute}
        smallLayout={smallLayout}
        volume={volume}
        volumeDotRef={volumeDotRef}
        VolumeIcon={IconVolumeMedium}
        VolumeLowIcon={IconVolumeLow}
      />

      {fullscreenEnabled && (
        <FullscreenButton
          EnterFullscreenIcon={IconEnterFullscreen}
          ExitFullscreenIcon={IconExitFullscreen}
          fullscreen={fullscreen}
          smallLayout={smallLayout}
          toggleFullscreen={toggleFullscreen}
          tooltips={tooltips}
        />
      )}

      {!isInAdBreak && (
        <>
          {buttons.skipBackButtonConfig && (
            <buttons.skipBackButtonConfig.Component
              Icon={IconSkipBack}
              onClick={skip}
              position={buttons.skipBackButtonConfig.position}
              smallLayout={smallLayout}
              tooltip={tooltips?.SKIP_BACKWARD}
            />
          )}
          {buttons.skipForwardButtonConfig && (
            <buttons.skipForwardButtonConfig.Component
              Icon={IconSkipForward}
              onClick={skip}
              position={buttons.skipForwardButtonConfig.position}
              smallLayout={smallLayout}
              tooltip={tooltips?.SKIP_FORWARD}
            />
          )}
        </>
      )}

      {ccButtonCallback && (
        <CastButton
          callback={ccButtonCallback}
          Icon={IconChromecast}
          smallLayout={smallLayout}
          tooltips={tooltips}
        />
      )}

      {/* not currently used, placeholder for cc button when using ref app */}
      {/* {pipButtonCallback && !smallLayout && (
        <PipButton
          Icon={IconPictureInPicture}
          tooltip={tooltips?.ENTER_PIP}
          callback={pipButtonCallback}
        />
      )} */}
    </>
  );
};
