import * as React from "react";

function SvgLanguageSettings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26 7H6a3 3 0 00-3 3v10a3 3 0 003 3h3v3.793a.5.5 0 00.854.354L14 23h12a3 3 0 003-3V10a3 3 0 00-3-3zM7 15h18m-5 4H7"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgLanguageSettings;
