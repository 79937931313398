import { PlaybackSpec } from "../../base";

export interface IQuery {
  query?: string;
  generateBody(queryVariables: any): string;
}

export type Image = {
  source?: string;
  sourceNonEncoded?: string;
};

// export type ChannelsMetadata = Array<ChannelMetadata>;

export type ChannelsMetadata = {
  id: string;
  name: string;
  icons: Icons;
  playback: any;
  programs: Array<Program>;
};

export type ChannelMetadata = {
  id: string;
  name: string;
  icons: Icons;
  playbackSpec: PlaybackSpec;
  startoverPlaybackSpec?: PlaybackSpec;
  programs: Array<Program>;
};

export type NpvrInfo = {
  media: {
    seriesRecording: boolean;
    isRecordable: boolean;
    isSeriesRecordable: boolean;
    recordableBefore: (number | undefined) | null;
    metadata: {
      id: string;
      seriesId?: string;
      title: string;
    };
    recordedPlaybackSpec?: PlaybackSpec;
  };
};
export type MediaMetadata = {
  type: MediaTypes;
  id: string;
  description: string;
  images: Images;
  title: string;
  series?: Series;
  nextContent?: NextContent;
  startoverPlaybackSpec?: PlaybackSpec;
  channelPlaybackSpec?: PlaybackSpec;
  linearPlaybackSpec?: PlaybackSpec;
  startTime?: number;
  endTime?: number;
  npvrInfo?: NpvrInfo;
};

export type Images = {
  showcard2x3?: string;
  showcard16x9?: string;
  backdrop16x9?: string;
  backdrop4x3?: string;
  screenshot16x9?: string;
};

export type NextContent = {
  validFrom: number;
  validTo: number;
  playbackSpec: PlaybackSpec;
  metadata?: MediaMetadata;
  nextContent?: PlaybackSpec;
  seriesTitle?: string;
  episode: number;
  season: number;
};

export type EpisodeDetails = {
  episode: number;
  season: number;
};

export enum MediaTypes {
  Episode = "Episode",
  Movie = "Movie",
  Channel = "Channel",
  SportEvent = "SportEvent",
}

export type Seasons = {};

export type Series = {
  id: string;
  title: string;
  episode: number;
  season: number;
  seasons?: any;
};

export type TimeRange = {
  start: number | Date;
  end: number | Date;
};

export type Icons = {
  light?: string;
  dark?: string;
};

export type Program = {
  id: string;
  startTime: number;
  endTime: number;
  live: boolean;
  rerun: boolean;
  startoverPlaybackSpec?: PlaybackSpec;
  media: ProgramMedia;
  npvrInfo?: NpvrInfo;
};

export type ProgramMedia = {
  id: string;
  title: string;
  description: string;
  seriesTitle?: string;
  season?: number;
  episode?: number;
  images: Images;
};
