var NpawObject = require('../object')
const { default: Log } = require('../../common/log')
const { AnalyticsTag } = require('../../common/Constants');

var startParams = [
  'accountCode',
  'username',
  'anonymousUser',
  'rendition',
  'deviceInfo',
  'player',
  'title',
  'title2',
  'live',
  'segmentDuration',
  'mediaDuration',
  'mediaResource',
  'parsedResource',
  'transactionCode',
  'properties',
  'cdn',
  'profile',
  'bucket',
  'playerVersion',
  'param1',
  'param2',
  'param3',
  'param4',
  'param5',
  'param6',
  'param7',
  'param8',
  'param9',
  'param10',
  'param11',
  'param12',
  'param13',
  'param14',
  'param15',
  'param16',
  'param17',
  'param18',
  'param19',
  'param20',
  'dimensions',
  'playerStartupTime',
  'obfuscateIp',
  'privacyProtocol',
  'p2pEnabled',
  'pluginVersion',
  'pluginInfo',
  'isp',
  'connectionType',
  'ip',
  'referer',
  'userType',
  'streamingProtocol',
  'transportFormat',
  'householdId',
  'adsBlocked',
  'adsExpected',
  'deviceUUID',
  'smartswitchConfigCode',
  'smartswitchGroupCode',
  'smartswitchContractCode',
  'libVersion',
  'nodeHost',
  'nodeType',
  'appName',
  'appReleaseVersion',
  'package',
  'saga',
  'tvshow',
  'season',
  'titleEpisode',
  'channel',
  'imdbID',
  'gracenoteID',
  'contentType',
  'genre',
  'contentLanguage',
  'subtitles',
  'cost',
  'price',
  'playbackType',
  'email',
  'drm',
  'videoCodec',
  'audioCodec',
  'codecSettings',
  'codecProfile',
  'containerFormat',
  'contentId',
  'contractedResolution',
  'linkedViewId',
  'edid',
  'cdnBalancerResponseUUID',
  'accur_geo_location',
  'network_type',
  'network_cell_type',
  'network_signal_strengh',
  'cpu_used',
  'mem_used',
  'cache_used'
]

var adStartParams = [
  'player',
  'playhead',
  'adTitle',
  'position',
  'adDuration',
  'adCampaign',
  'adCreativeId',
  'adProvider',
  'adResource',
  'adPlayerVersion',
  'adProperties',
  'adAdapterVersion',
  'adInsertionType',
  'extraparam1',
  'extraparam2',
  'extraparam3',
  'extraparam4',
  'extraparam5',
  'extraparam6',
  'extraparam7',
  'extraparam8',
  'extraparam9',
  'extraparam10',
  'fullscreen',
  'audio',
  'skippable',
  'adNumber',
  'adNumberInBreak',
  'breakNumber'
]

var RequestBuilder = NpawObject.extend(
  /** @lends npaw.RequestBuilder.prototype */
  {
    /**
     * This class helps building params associated with each event: /start, /joinTime...
     *
     * @constructs RequestBuilder
     * @extends npaw.NpawObject
     * @memberof npaw
     *
     * @param {Plugin} plugin A Plugin instance
     */
    constructor: function () {
      /** Stores a list of the last params fetched */
      this.lastSent = {}
    },

    /**
     * Adds to params all the entities specified in paramList, unless they are already set.
     *
     * @param {Object} params Object of params key:value.
     * @param {Array.string} paramList A list of params to fetch.
     * @param {boolean} onlyDifferent If true, only fetches params that have changed
     * @param {Object} contextObjects
     * @param {Object} contextOptions
     */
    fetchParams: function (params, paramList, onlyDifferent, contextObjects, contextOptions) {
      params = params || {};
      paramList = paramList || [];
      for (let i = 0; i < paramList.length; i++) {
        const param = paramList[i];

        if (params[param]) {
          continue;
        }
        const getterName = RequestBuilder.getters[param];
        let foundMethod = false;

        for (const contextKey in contextObjects) {
          if (contextObjects[contextKey] && contextObjects[contextKey][getterName]) {
            const value = contextObjects[contextKey][getterName](contextOptions);
            if (value !== undefined && (!onlyDifferent || this.lastSent[param] !== value)) {
              params[param] = value;
              this.lastSent[param] = value;
            }
            foundMethod = true;
            break;
          }
        }

        if (!foundMethod) {
          Log.warn(AnalyticsTag, 'Trying to call undefined getter ' + param + ':' + getterName);
        }
      }
      return params;
    },

    getGetters: function () {
      return RequestBuilder.getters;
    },

    getRequiredMetadataParams: function () {
      return ['live', 'rendition'];
    },

    buildBody: function (service, contextObjects) {
      return this.fetchParams(undefined, RequestBuilder.bodyParams[service], false, contextObjects);
    },

    /**
     * Adds to params object all the entities specified in paramList, unless they are already set.
     *
     * @param {Object} params Object of params key:value.
     * @param {string} service The name of the service. Use {@link Plugin.Service} enum.
     * @param {Object} contextObjects
     * @param {Object} contextOptions
     */
    buildParams: function (params, service, contextObjects, contextOptions) {
      params = params || {};
      this.fetchParams(params, RequestBuilder.params[service], false, contextObjects, contextOptions);
      this.fetchParams(params, RequestBuilder.differentParams[service], true, contextObjects, contextOptions);
      return params;
    },

    /**
     * Return changed entities since last check
     *
     * @returns {Object} params
     */
    getChangedEntities: function (contextObjects) {
      return this.fetchParams({}, RequestBuilder.differentParams.entities, true, contextObjects);
    }
  },
  /** @lends npaw.RequestBuilder */
  {
    // Static Members

    /** List of params used by each service */
    params: {
      '/data': ['system', 'pluginVersion', 'requestNumber', 'username'],
      '/adapters': ['system', 'pluginVersion', 'requestNumber'],
      '/configuration': ['system', 'pluginVersion', 'requestNumber', 'appName', 'appReleaseVersion'],

      '/infinity/session/pluginLogs': ['logAction', 'logType'],
      '/infinity/video/pluginLogs': ['logAction', 'logType'],

      '/init': startParams,
      '/start': startParams,
      '/joinTime': ['joinDuration', 'playhead', 'bitrate'],
      '/pause': ['playhead', 'accountCode', 'pluginVersion'],
      '/resume': ['pauseDuration', 'playhead', 'accountCode', 'pluginVersion'],
      '/seek': ['seekDuration', 'playhead', 'accountCode', 'pluginVersion'],
      '/bufferUnderrun': ['bufferDuration', 'playhead', 'accountCode', 'pluginVersion'],
      '/error': ['player', 'playhead'].concat(startParams),
      '/stop': ['accur_geo_location', 'bitrate', 'totalBytes', 'playhead', 'pauseDuration', 'metrics', 'cdnDownloadedTraffic', 'multiCdnInfo', 'p2pDownloadedTraffic', 'uploadTraffic', 'profile', 'bucket', 'accountCode', 'pluginVersion'],

      '/infinity/video/event': [],

      '/adInit': adStartParams,
      '/adStart': adStartParams,
      '/adJoin': ['playhead', 'position', 'adJoinDuration', 'adPlayhead', 'adNumber', 'adNumberInBreak', 'breakNumber'],
      '/adPause': ['playhead', 'position', 'adPlayhead', 'adNumber', 'adNumberInBreak', 'breakNumber'],
      '/adResume': ['playhead', 'position', 'adPlayhead', 'adPauseDuration', 'adNumber', 'adNumberInBreak', 'breakNumber'],
      '/adBufferUnderrun': ['playhead', 'position', 'adPlayhead', 'adBufferDuration', 'adNumber', 'adNumberInBreak', 'breakNumber'],
      '/adStop': ['playhead', 'position', 'adPlayhead', 'adTotalDuration', 'adViewedDuration', 'adViewability', 'adNumber', 'adNumberInBreak', 'breakNumber'],
      '/adClick': ['playhead', 'position', 'adPlayhead', 'adNumber', 'adNumberInBreak', 'breakNumber'],
      '/adError': ['adPlayhead'].concat(adStartParams),
      '/adManifest': ['givenBreaks', 'expectedBreaks', 'expectedPattern', 'breaksTime'],
      '/adBreakStart': ['position', 'givenAds', 'expectedAds', 'breakNumber', 'adInsertionType'],
      '/adBreakStop': ['position', 'breakNumber'],
      '/adQuartile': ['position', 'adViewedDuration', 'adViewability', 'adNumber', 'adNumberInBreak', 'breakNumber'],

      '/ping': ['real_traffic', 'improved_bandwidth_estimation', 'network_type', 'network_cell_type', 'network_signal_strengh', 'cpu_used', 'mem_used', 'cache_used', 'accountCode', 'pluginVersion', 'droppedFrames', 'playrate', 'cdnDownloadedTraffic', 'multiCdnInfo', 'p2pDownloadedTraffic', 'uploadTraffic', 'latency', 'packetLoss', 'packetSent', 'metrics', 'totalBytes', 'segmentDuration'],

      '/infinity/session/start': [
        'accountCode',
        'username',
        'userType',
        'anonymousUser',
        'route',
        'page',
        'referer',
        'referral',
        'screenResolution',
        'language',
        'deviceInfo',
        'adsBlocked',
        'deviceUUID',
        'libVersion',
        'appName',
        'appReleaseVersion',
        'isp',
        'connectionType',
        'ip',
        'obfuscateIp',
        'privacyProtocol',
        'dimensions',
        'param1',
        'param2',
        'param3',
        'param4',
        'param5',
        'param6',
        'param7',
        'param8',
        'param9',
        'param10',
        'param11',
        'param12',
        'param13',
        'param14',
        'param15',
        'param16',
        'param17',
        'param18',
        'param19',
        'param20',
        'edid'
      ],
      '/infinity/session/stop': ['sessionMetrics'],
      '/infinity/session/nav': ['route', 'page', 'scrollDepth'],
      '/infinity/session/beat': ['sessionMetrics'],
      '/infinity/session/event': ['accountCode'],

      '/offlineEvents': {}
    },

    /** Values for request body */
    bodyParams: {
      '/offlineEvents': ['viewJson']
    },

    /** List of params used by each service (only if they are different) */
    differentParams: {
      entities: [
        'pluginInfo',
        'rendition',
        'title',
        'title2',
        'param1',
        'param2',
        'param3',
        'param4',
        'param5',
        'param6',
        'param7',
        'param8',
        'param9',
        'param10',
        'param11',
        'param12',
        'param13',
        'param14',
        'param15',
        'param16',
        'param17',
        'param18',
        'param19',
        'param20',
        'cdn',
        'nodeHost',
        'nodeType',
        'nodeTypeString',
        'subtitles',
        'contentLanguage',
        'contentId',
        'accur_geo_location'
      ]
    },

    /** List of params and its related getter */
    getters: {
      requestNumber: 'getRequestNumber',
      playhead: 'getPlayhead',
      playrate: 'getPlayrate',
      fps: 'getFramesPerSecond',
      segmentDuration: 'getSegmentDuration',
      droppedFrames: 'getDroppedFrames',
      mediaDuration: 'getDuration',
      bitrate: 'getBitrate',
      totalBytes: 'getTotalBytes',
      throughput: 'getThroughput',
      rendition: 'getRendition',
      title: 'getTitle',
      title2: 'getTitle2',
      live: 'getIsLive',
      mediaResource: 'getResource',
      parsedResource: 'getParsedResource',
      transactionCode: 'getTransactionCode',
      properties: 'getMetadata',
      playerVersion: 'getPlayerVersion',
      player: 'getPlayerName',
      cdn: 'getCdn',
      profile: 'getProfileName',
      bucket: 'getBucketName',
      pluginVersion: 'getPluginVersion',
      libVersion: 'getLibVersion',
      userType: 'getUserType',
      streamingProtocol: 'getStreamingProtocol',
      transportFormat: 'getTransportFormat',
      obfuscateIp: 'getObfuscateIp',
      privacyProtocol: 'getPrivacyProtocol',
      householdId: 'getHouseholdId',
      latency: 'getLatency',
      packetLoss: 'getPacketLoss',
      packetSent: 'getPacketSent',
      metrics: 'getVideoMetrics',
      dimensions: 'getCustomDimensions',
      accur_geo_location: 'getAccurateGeoLocation',
      network_type: 'getNetworkConnectionType',
      network_cell_type: 'getNetworkCellType',
      network_signal_strengh: 'getNetworkSignalStrength',
      cpu_used: 'getCpuUsed',
      mem_used: 'getMemoryUsed',
      cache_used: 'getCacheStorageUsed',
      real_traffic: 'getTransferredTraffic',
      improved_bandwidth_estimation: 'getImprovedBandwidth',

      param1: 'getExtraparam1',
      param2: 'getExtraparam2',
      param3: 'getExtraparam3',
      param4: 'getExtraparam4',
      param5: 'getExtraparam5',
      param6: 'getExtraparam6',
      param7: 'getExtraparam7',
      param8: 'getExtraparam8',
      param9: 'getExtraparam9',
      param10: 'getExtraparam10',
      param11: 'getExtraparam11',
      param12: 'getExtraparam12',
      param13: 'getExtraparam13',
      param14: 'getExtraparam14',
      param15: 'getExtraparam15',
      param16: 'getExtraparam16',
      param17: 'getExtraparam17',
      param18: 'getExtraparam18',
      param19: 'getExtraparam19',
      param20: 'getExtraparam20',

      extraparam1: 'getAdExtraparam1',
      extraparam2: 'getAdExtraparam2',
      extraparam3: 'getAdExtraparam3',
      extraparam4: 'getAdExtraparam4',
      extraparam5: 'getAdExtraparam5',
      extraparam6: 'getAdExtraparam6',
      extraparam7: 'getAdExtraparam7',
      extraparam8: 'getAdExtraparam8',
      extraparam9: 'getAdExtraparam9',
      extraparam10: 'getAdExtraparam10',

      position: 'getAdPosition',
      adNumber: 'getAdNumber',
      adNumberInBreak: 'getAdNumberInBreak',
      breakNumber: 'getBreakNumber',
      adPlayhead: 'getAdPlayhead',
      adDuration: 'getAdDuration',
      adCampaign: 'getAdCampaign',
      adCreativeId: 'getAdCreativeId',
      adBitrate: 'getAdBitrate',
      adTitle: 'getAdTitle',
      adResource: 'getAdResource',
      adPlayerVersion: 'getAdPlayerVersion',
      adProperties: 'getAdMetadata',
      adAdapterVersion: 'getAdAdapterVersion',
      givenBreaks: 'getGivenBreaks',
      expectedBreaks: 'getExpectedBreaks',
      expectedPattern: 'getExpectedPattern',
      breaksTime: 'getBreaksTime',
      givenAds: 'getGivenAds',
      expectedAds: 'getExpectedAds',
      adsExpected: 'getAdsExpected',
      adViewedDuration: 'getAdViewedDuration',
      adViewability: 'getAdViewability',
      fullscreen: 'getIsFullscreen',
      audio: 'getAudioEnabled',
      skippable: 'getIsSkippable',
      adProvider: 'getAdProvider',
      adInsertionType: 'getAdInsertionType',

      pluginInfo: 'getPluginInfo',

      isp: 'getIsp',
      connectionType: 'getConnectionType',
      ip: 'getIp',

      deviceInfo: 'getDeviceInfo',
      edid: 'getEDID',

      system: 'getAccountCode',
      accountCode: 'getAccountCode',
      username: 'getUsername',
      anonymousUser: 'getAnonymousUser',

      joinDuration: 'getJoinDuration',
      bufferDuration: 'getBufferDuration',
      seekDuration: 'getSeekDuration',
      pauseDuration: 'getPauseDuration',

      adJoinDuration: 'getAdJoinDuration',
      adBufferDuration: 'getAdBufferDuration',
      adPauseDuration: 'getAdPauseDuration',
      adTotalDuration: 'getAdTotalDuration',

      referer: 'getReferer',
      referral: 'getReferral',
      language: 'getLanguage',
      screenResolution: 'getScreenResolution',

      nodeHost: 'getNodeHost',
      nodeType: 'getNodeType',
      nodeTypeString: 'getNodeTypeString',

      route: 'getReferer',
      page: 'getPageName',

      playerStartupTime: 'getPlayerStartupTime',
      pageLoadTime: 'getPageLoadTime',

      cdnDownloadedTraffic: 'getCdnTraffic',
      multiCdnInfo: 'getMultiCdnInfo',
      p2pDownloadedTraffic: 'getP2PTraffic',
      p2pEnabled: 'getIsP2PEnabled',
      uploadTraffic: 'getUploadTraffic',
      cdnBalancerResponseUUID: 'getBalancerResponseId',

      viewJson: 'getOfflineView',
      deviceUUID: 'getDeviceUUID',
      sessionMetrics: 'getSessionMetrics',
      scrollDepth: 'getScrollDepth',

      adsBlocked: 'getIsBlocked',
      linkedViewId: 'getLinkedViewId',

      smartswitchConfigCode: 'getSmartswitchConfigCode',
      smartswitchGroupCode: 'getSmartswitchGroupCode',
      smartswitchContractCode: 'getSmartswitchContractCode',

      appName: 'getAppName',
      appReleaseVersion: 'getAppReleaseVersion',
      package: 'getPackage',
      saga: 'getSaga',
      tvshow: 'getTvShow',
      season: 'getSeason',
      titleEpisode: 'getEpisodeTitle',
      channel: 'getChannel',
      drm: 'getDRM',
      videoCodec: 'getVideoCodec',
      audioCodec: 'getAudioCodec',
      codecSettings: 'getCodecSettings',
      codecProfile: 'getCodecProfile',
      containerFormat: 'getContainerFormat',
      contentId: 'getID',
      imdbID: 'getImdbId',
      gracenoteID: 'getGracenoteID',
      contentType: 'getType',
      genre: 'getGenre',
      contentLanguage: 'getVideoLanguage',
      subtitles: 'getSubtitles',
      contractedResolution: 'getContractedResolution',
      cost: 'getCost',
      price: 'getPrice',
      playbackType: 'getPlaybackType',
      email: 'getEmail'
    }

  }
)

module.exports = RequestBuilder
