import styled from "@emotion/styled";

import { AbsolutePositionFullSizeDiv } from "../elements";

export const OverlayWrapper = styled(AbsolutePositionFullSizeDiv)`
  pointer-events: none;
  display: flex;
  align-items: flex-end;
  animation: opacity 0.3s ease-in;

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const OverlayInnerWrapper = styled.div<{ padded?: boolean }>`
  ${({ padded }) => (padded ? "padding: 2em;" : "padding: 2em 0;")}
  width: 100%;

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;

export const NonBreaking = styled.span`
  white-space: nowrap;
`;

export const TextOverflowEllipsis = styled.span`
  white-space: nowrap;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
`;

export type TBrowsableListOptions = { pageSize: number };

export const BrowsableList = styled.div<TBrowsableListOptions>`
  display: grid;
  grid-template-columns: repeat(${({ pageSize }) => pageSize}, minmax(0, 1fr));
  grid-auto-flow: column;
  grid-gap: 0.5em;
  width: 100%;
`;
