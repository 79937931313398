//
//  RegisterService.ts
//  Created by Lars Rothaus on 23/08/2022.
//

import { BaseService } from "../BaseService";
import { ServiceConfig } from "../Types";
import { ServiceError, ServiceErrorCodes } from "../errors";
import {
  ErrorCategories,
  NetworkError,
  StandardErrorArgs,
  TClientName,
} from "../../base";
import { FetchRequestResponse } from "../../network";

export class RegistrationService extends BaseService {
  protected configuration?: ServiceConfig;

  constructor() {
    super("RegistrationService");
  }

  public initialize(configuration: ServiceConfig) {
    this.configuration = configuration;

    this.headers = {
      "client-name": this.configuration.clientName,
      "tv-client-boot-id": this.configuration.applicationSessionId,
    };
  }

  /**
   * @throws {RegistrationServiceError | ServiceError}
   */
  public async registerDevice(deviceId?: string): Promise<boolean> {
    if (!this.configuration) {
      throw new ServiceError({
        code: ServiceErrorCodes.MissingConfiguration,
        category: ErrorCategories.DEFAULT,
        fatal: true,
        details: {
          origin: this.name,
          domain: "registration",
        },
      });
    }
    deviceId = deviceId ?? this.configuration.deviceId;

    let body: RegisterDeviceRequestProperties = {
      deviceId: deviceId,
      tvClient: {
        bootId: this.configuration.applicationSessionId,
        name: this.configuration.clientName,
        vendor: this.configuration.deviceVendor,
        version: this.configuration.application.applicationVersion,
      },
    };

    const response = await this.requestFactory.fetch(
      `${this.configuration.tvClientGatewayUrl}/tvclientgateway/rest/secure/v1/register`,
      {
        method: "POST",
        headers: this.headers,
        useAuthentication: true,
        body: JSON.stringify(body),
      }
    );

    if (response instanceof NetworkError) {
      let errorMessage: string | undefined;
      let code: RegistrationServiceErrorCodes =
        RegistrationServiceErrorCodes.Unknown;
      let fatal = false;
      switch (response.responseBody?.errorCode) {
        case 61002: {
          code = RegistrationServiceErrorCodes.RegistrationFailure;
          errorMessage =
            "[61002] Tv client registration failure (incorrect parameters in register request)";
          fatal = false;
          break;
        }
        case 61004: {
          code = RegistrationServiceErrorCodes.TooManyDevices;
          errorMessage =
            "[61004] Too many devices has been registered for the user";
          fatal = true;
          break;
        }
        case 61001: {
          code = RegistrationServiceErrorCodes.RegistrationFailure;
          errorMessage = "[61001] Drm registration failure";
          fatal = false;
          break;
        }
      }

      throw new RegistrationServiceError({
        code,
        details: {
          detailedErrorName: code,
          errorMessage,
        },
        fatal,
        category: ErrorCategories.TV_CLIENT_GATEWAY,
        originalError: response,
        message: errorMessage,
      });
    } else if (response instanceof FetchRequestResponse && response.ok) {
      return true;
    } else {
      throw new ServiceError({
        code: ServiceErrorCodes.BadResponseError,
        category: ErrorCategories.TV_CLIENT_GATEWAY,
        fatal: false,
        details: {
          response,
          origin: this.name,
          domain: "register",
        },
      });
    }
  }

  async reset(): Promise<void> {
    return Promise.resolve();
  }

  async destroy(): Promise<void> {
    return Promise.resolve();
  }
}

// |--------------------------|
// | Internal interfaces
// |--------------------------|

interface TvClient {
  bootId?: string;
  name?: TClientName;
  version?: string;
  os?: string;
  osVersion?: string;
  browser?: string;
  browserVersion?: string;
  vendor?: string;
  vendorModel?: string;
  networkType?: string;
  networkProvider?: string;
}
interface RegisterDeviceRequestProperties {
  tvClient: TvClient;
  deviceId: string;
}
// |--------------------------|
// | External Error stuff
// |--------------------------|
export enum RegistrationServiceErrorCodes {
  RegistrationFailure = "RegistrationServiceError.RegistrationFailure",
  TooManyDevices = "RegistrationServiceError.TooManyDevices",
  Unknown = "RegistrationServiceError.Unknown",
}

// |--------------------------|
// | RegistrationServiceError
// |--------------------------|
export class RegistrationServiceError extends ServiceError {
  public code: RegistrationServiceErrorCodes;
  constructor(
    args: StandardErrorArgs & { code: RegistrationServiceErrorCodes }
  ) {
    super(args);
    this.code = args.code;
  }
}
