import { useCallback, useEffect, useState } from "react";

import { UiEventTypes } from "../constants";
import { noop } from "../utils";
import {
  useClickHandler,
  useDispatch,
  useMetadataImpl,
} from "./context-provider";

export const useRecordAndWatchClickHandler = () => {
  const [recAndWatchClicked, setRecAndWatchClicked] = useState<boolean>(false);
  const { getRecordAndWatch } = useMetadataImpl();
  const dispatch = useDispatch();

  const { onPlayContent, onRecordAndWatch } = useClickHandler();
  useEffect(() => {
    let aborted = false;
    if (!getRecordAndWatch) return noop;
    if (!recAndWatchClicked) return noop;

    dispatch({
      payload: {},
      type: UiEventTypes.SHOW_UI_SPINNER,
    });

    getRecordAndWatch()
      .then((res) => {
        if (aborted) return;

        setRecAndWatchClicked(false);

        dispatch({
          payload: {},
          type: UiEventTypes.HIDE_UI_SPINNER,
        });

        if ("recordButton" in res) {
          const modalButton = {
            ...res.recordButton,
            onClick: () => onRecordAndWatch(res.recordButton.onClick),
          };
          dispatch({
            payload: {
              modalOptions: {
                modalButtons: [modalButton],
                modalMetadata: res.modalMetadata,
              },
            },
            type: UiEventTypes.SHOW_MODAL,
          });
        } else {
          onPlayContent({ playbackSpec: res });
        }
      })
      .catch(() => {
        // ignore errors if the hook is no longer rendered
        if (aborted) return;

        setRecAndWatchClicked(false);

        dispatch({
          payload: {},
          type: UiEventTypes.HIDE_UI_SPINNER,
        });
      });

    return () => {
      aborted = true;
      dispatch({
        payload: {},
        type: UiEventTypes.HIDE_UI_SPINNER,
      });
    };
  }, [
    recAndWatchClicked,
    getRecordAndWatch,
    dispatch,
    onRecordAndWatch,
    onPlayContent,
  ]);

  const recordAndWatchClickHandler = useCallback(() => {
    setRecAndWatchClicked(true);
  }, [setRecAndWatchClicked]);

  return {
    recordAndWatchClickHandler,
  };
};
