import {
  Drm,
  DrmType,
  FairplayDrm,
  PlayreadyDrm,
  WidevineDrm,
} from "@telia-company/tv.web-playback-sdk";
import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";
import shaka from "shaka-player";

const setWidevineRequestFilter = (
  drm: WidevineDrm,
  player: TUnfortunatelyAny
) =>
  player
    .getNetworkingEngine()
    .registerRequestFilter((type: number, request: TUnfortunatelyAny) => {
      if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
        request.headers = drm.headers;
      }
    });

const setPlayreadyRequestFilter = (
  drm: PlayreadyDrm,
  player: TUnfortunatelyAny
) =>
  player
    .getNetworkingEngine()
    .registerRequestFilter((type: number, request: TUnfortunatelyAny) => {
      if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
        request.headers = drm.headers;
      }
    });

const setFairplayRequestFilter = (
  drm: FairplayDrm,
  player: TUnfortunatelyAny
) =>
  player
    .getNetworkingEngine()
    .registerRequestFilter((type: number, request: TUnfortunatelyAny) => {
      if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
        // Types generated from SGW do not include any properties
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        request.headers = drm.headers;

        const originalPayload = new Uint8Array(request.body);
        const base64Payload =
          shaka.util.Uint8ArrayUtils.toStandardBase64(originalPayload);
        const params = `spc=${encodeURIComponent(base64Payload)}`;
        request.headers["Content-Type"] = "application/x-www-form-urlencoded";
        request.body = shaka.util.StringUtils.toUTF8(params);
      }
    });

const setFairplayResponseFilter = (player: TUnfortunatelyAny) =>
  player
    .getNetworkingEngine()
    .registerResponseFilter((type: number, response: TUnfortunatelyAny) => {
      if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
        let responseText = shaka.util.StringUtils.fromUTF8(response.data);
        // Trim whitespace.
        responseText = responseText.trim();

        // Look for <ckc> wrapper and remove it.
        if (
          responseText.substr(0, 5) === "<ckc>" &&
          responseText.substr(-6) === "</ckc>"
        ) {
          responseText = responseText.slice(5, -6);
        }

        // Decode the base64-encoded data into the format the browser expects.
        response.data =
          shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
      }
    });

export type TSetUpDrmOptions = {
  drm?: Drm;
  player: TUnfortunatelyAny;
};

export const setUpDRM = ({ drm, player }: TSetUpDrmOptions): void => {
  // SGW types indicate that drm can be undefined
  if (!drm) return;

  switch (drm.type) {
    case DrmType.WIDEVINE:
      setWidevineRequestFilter(drm, player);

      player.configure({
        drm: {
          servers: {
            // Types generated from SGW do not include any properties
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            "com.widevine.alpha": drm.licenseUrl,
          },
        },
      });
      break;
    case DrmType.PLAYREADY:
      setPlayreadyRequestFilter(drm, player);

      player.configure({
        drm: {
          servers: {
            // Types generated from SGW do not include any properties
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            "com.microsoft.playready": drm.licenseUrl,
          },
          // advanced: {
          //   "com.microsoft.playready.recommendation": {
          //     videoRobustness: "3000"
          //   }
          // }
        },
      });
      break;
    case DrmType.FAIRPLAY:
      setFairplayRequestFilter(drm, player);
      setFairplayResponseFilter(player);

      player.configure({
        drm: {
          advanced: {
            "com.apple.fps.1_0": {
              // Types generated from SGW don't include certificateUrl
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              serverCertificateUri: drm.certificateUrl,
            },
          },
          initDataTransform: (
            initData: TUnfortunatelyAny,
            initDataType: string,
            drmData: TUnfortunatelyAny
          ) => {
            if (initDataType !== "skd") return initData;
            // Types generated from SGW do not include any properties
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { assetId } = drm;

            let contentId = assetId;

            // castlabs recommends using skd://drmtoday?keyId=<keyId> for fairplay
            // license requests, so we send the full skd uri as contentId
            const skdUri = shaka.util.StringUtils.fromBytesAutoDetect(initData);
            const startIndex = skdUri.indexOf("skd://drmtoday");
            if (startIndex > -1) {
              contentId = skdUri.substring(startIndex);
            }

            return shaka.util.FairPlayUtils.initDataTransform(
              initData,
              contentId,
              drmData.serverCertificate
            );
          },
          servers: {
            // Types generated from SGW do not include any properties
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            "com.apple.fps.1_0": drm.licenseUrl,
          },
        },
      });
      break;
    default:
      break;
  }
};
