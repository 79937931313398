import { FetchRequestBase } from "./FetchRequestBase";
import {
  FetchRequestOptions,
  FetchRequestResponse,
} from "./FetchRequestFactory";
import {
  getNetworkErrorCode,
  Logger as LoggerSingleton,
  NetworkError,
  NetworkErrorCodes,
  UnknownError,
} from "../../base";

const Logger = LoggerSingleton.createLoggerContext("FetchRequest");

const getErrorCode = (error: any) => {
  if (error instanceof Error) {
    if (error.message.toLowerCase().indexOf("failed to fetch") > -1)
      return "FAILED_TO_FETCH";

    return error.message
      .replace("TypeError: ", "")
      .replace("NetworkError: ", "")
      .toUpperCase()
      .replace(" ", "_");
  }

  return UnknownError;
};

const fromEntries = (entries: Iterable<Record<string, any>>) =>
  [...entries].reduce((acc, entry) => {
    acc[entry[0]] = entry[1];
    return acc;
  }, {});

export type TFetchMethod = (
  input: RequestInfo,
  init?: RequestInit
) => Promise<Response>;

export class FetchRequest extends FetchRequestBase {
  private readonly abortSignal: AbortSignal;
  private readonly fetchMethod: TFetchMethod | undefined = undefined;
  private readonly abortController: AbortController = new AbortController();
  private abortTimer?: any;

  constructor(
    request: RequestInfo,
    options: FetchRequestOptions,
    fetchMethod?: TFetchMethod
  ) {
    super(request, options);
    this.abortSignal = this.abortController.signal;
    if (!options.signal) {
      options.signal = this.abortSignal;
    }

    if (fetchMethod) {
      this.fetchMethod = fetchMethod;
    }
  }

  private abortTimeoutTimer() {
    // if we have an abortTimer clear it..
    if (this.abortTimer) {
      clearTimeout(this.abortTimer);
    }
  }

  public abort() {
    this.abortTimeoutTimer();
    this.abortController.abort();
    super.abort();
  }

  async send(requestBody?: any): Promise<FetchRequestResponse | NetworkError> {
    let fetchResponse = new FetchRequestResponse(this.request, this.options);
    if (requestBody) {
      this.options.body =
        typeof requestBody === "object"
          ? JSON.stringify(requestBody)
          : requestBody;
    }
    let response;
    try {
      if (this.options.timeoutDelayInSec) {
        this.abortTimer = setTimeout(() => {
          this.abort();
        }, this.options.timeoutDelayInSec * 1000);
      }
      response = await (this.fetchMethod
        ? this.fetchMethod(this.request, this.options)
        : fetch(this.request, this.options));
    } catch (error) {
      // @ts-ignore
      if (error.name === "AbortError") {
        this.abortTimeoutTimer();

        return new NetworkError({
          code: NetworkErrorCodes.Aborted,
          fatal: true,
          originalError: error,
          message: "The request was aborted.",
          manuallyAborted: this.manuallyAborted,
          details: {
            origin: "FetchRequest",
            domain: "send",
            request: this.request,
            options: this.options,
          },
        });
      }

      return new NetworkError({
        code: getErrorCode(error),
        fatal: true,
        originalError: error,
        details: {
          origin: "FetchRequest",
          domain: "send",
          request: this.request,
          options: this.options,
        },
      });
    }

    this.abortTimeoutTimer();

    if (response) {
      let responseBody: any = {};
      let responseHeaders: any = {};

      if (response.headers) {
        // ToDo: Add request option to skip reading response body (default: false)
        responseHeaders = fromEntries(response.headers);

        const hasContentLength = response.headers.has("content-length");
        const contentLength = parseInt(
          response.headers.get("content-length") || "0",
          10
        );

        // Not parsing empty responses
        if (!hasContentLength || contentLength > 0) {
          //todo fix content-type look up..
          if (response.headers.has("content-type")) {
            let contentType = (
              response.headers?.get("content-type") || ""
            ).toLocaleLowerCase();
            try {
              if (contentType && contentType.includes("text")) {
                responseBody = await response.text();
              } else {
                responseBody = await response.json();
              }
            } catch (error) {
              return new NetworkError({
                code: NetworkErrorCodes.ResponseParsing,
                fatal: true,
                message: "Unable to parse response body.",
                statusText: response.statusText,
                statusCode: response.status,
                responseHeaders,
                details: {
                  origin: "FetchRequest",
                  domain: "send",
                  request: this.request,
                  options: this.options,
                },
                originalError: error,
              });
            }
          } else {
            try {
              responseBody = await response.json();
            } catch (e) {
              return new NetworkError({
                code: NetworkErrorCodes.ResponseParsing,
                fatal: true,
                message: "Unable to parse response body.",
                statusText: response.statusText,
                statusCode: response.status,
                responseHeaders,
                details: {
                  origin: "FetchRequest",
                  domain: "send",
                  request: this.request,
                  options: this.options,
                },
                originalError: e,
              });
            }
          }
        }
      }

      if (response.ok === false) {
        return new NetworkError({
          code: getNetworkErrorCode(response.status),
          fatal: true,
          statusText: response.statusText,
          statusCode: response.status,
          responseBody,
          responseHeaders,
          details: {
            origin: "FetchRequest",
            domain: "send",
            request: this.request,
            options: this.options,
          },
        });
      }

      fetchResponse.responseBody = responseBody;

      fetchResponse.populate(response);

      return fetchResponse;
    } else {
      // this should never be able to happen!
      return new NetworkError({
        code: NetworkErrorCodes.InternalError,
        fatal: true,
        message: "Unknown fetch error.",
        details: {
          origin: "FetchRequest",
          domain: "send",
          request: this.request,
          options: this.options,
        },
      });
    }
  }
}
