import { Dispatch, RefObject, SetStateAction } from "react";
import useResizeObserver from "use-resize-observer";

export type TUseResizeDetectionOptions = {
  setMinimized: Dispatch<SetStateAction<boolean | null>>;
  setScaleFactor: Dispatch<SetStateAction<number>>;
  setSmallLayout: Dispatch<SetStateAction<boolean | null>>;
  sizeRef: RefObject<HTMLDivElement>;
};

export const useResizeDetection = ({
  setMinimized,
  setScaleFactor,
  setSmallLayout,
  sizeRef,
}: TUseResizeDetectionOptions): void => {
  useResizeObserver({
    onResize: ({ width }) => {
      // inspired by https://www.madebymike.com.au/writing/precise-control-responsive-typography/
      // will scale font size for container element width between 335 and 1440 pixels

      let minSize = 0.85;
      let useSmallLayout = false;
      let minimized = false;
      const maxSize = 1.45;
      const minWidth = 635;
      const maxWidth = 1440;

      if (!width) return;
      if (width && width <= 635) {
        useSmallLayout = true;
        minSize = 1;
      }
      if (width && width <= 300) {
        // TODO: see if we need a better way to know when we have been minimized
        minimized = true;
      }

      setSmallLayout(useSmallLayout);
      setMinimized(minimized);
      setScaleFactor(
        minSize +
          (maxSize - minSize) * ((width - minWidth) / (maxWidth - minWidth))
      );
    },
    ref: sizeRef,
  });
};
