import styled from "@emotion/styled";
import { TTooltipTranslations } from "@telia-company/tv.web-player-shared";
import { FC } from "react";

import { TPlayerIcon } from "../@types/icon-type";
import { HotkeyTooltips } from "../hooks/global-hotkeys.hook";
import { GridIcon } from "./elements";

export type TFullScreenIconProps = {
  smallLayout: boolean;
};

export type TFullScreenButtonProps = {
  EnterFullscreenIcon: TPlayerIcon;
  ExitFullscreenIcon: TPlayerIcon;
  fullscreen: boolean;
  toggleFullscreen: () => void;
  tooltips?: TTooltipTranslations;
} & TFullScreenIconProps;

const FullscreenIcon = styled(GridIcon)<TFullScreenIconProps>`
  grid-column: ${({ smallLayout }) => (smallLayout ? "15 / -1" : "16")};
  grid-row: ${({ smallLayout }) => (smallLayout ? "1 / 3" : "9")};
`;

export const FullscreenButton: FC<TFullScreenButtonProps> = ({
  EnterFullscreenIcon,
  ExitFullscreenIcon,
  fullscreen,
  smallLayout,
  toggleFullscreen,
  tooltips,
}) => (
  <FullscreenIcon
    aria-label={
      fullscreen
        ? `${
            tooltips?.EXIT_FULLSCREEN
          } (${HotkeyTooltips.FULLSCREEN.toUpperCase()})`
        : `${
            tooltips?.ENTER_FULLSCREEN
          } (${HotkeyTooltips.FULLSCREEN.toUpperCase()})`
    }
    data-microtip-position={smallLayout ? "bottom-left" : "top"}
    data-tooltip
    onClick={toggleFullscreen}
    onKeyDown={(e) => {
      if (e.key === " ") {
        e.preventDefault();
      }
    }}
    onMouseLeave={(e) => e.currentTarget.blur()}
    smallLayout={smallLayout}
  >
    {fullscreen ? <ExitFullscreenIcon /> : <EnterFullscreenIcon />}
  </FullscreenIcon>
);
