import { ErrorCategories, ErrorSeverities } from "../Constants";
import { INormalizedError, TErrorCode, TErrorDetails } from "./Types";
import { mapToEnumerableError } from "./utils";

export type ErrorDebugInfo = Record<string, any> & {
  // Legacy DEPRECATED
  severity?: ErrorSeverities;
  origin?: string;
  domain?: string;
  errorName?: string;
  detailedErrorName?: string;
};

export type StandardErrorArgs = {
  code: TErrorCode;
  category: ErrorCategories;
  details?: ErrorDebugInfo;
  fatal: boolean;
  message?: string;
  originalError?: Error | any;
};

const buildErrorDetails = (args: StandardErrorArgs): TErrorDetails => ({
  descriptiveCode:
    args.details?.origin && args.details?.domain
      ? `${args.details?.origin}:${args.details?.domain}:${
          args.details?.errorName || args.code
        }${
          args.details?.detailedErrorName
            ? `:${args.details?.detailedErrorName}`
            : ""
        }`
      : "",
  severity: ErrorSeverities.CRITICAL,
  originalError: mapToEnumerableError(args.originalError),
  message: args.message,
  ...args.details,
});

export class StandardError implements INormalizedError {
  // original error code OR a constructed error code from whatever source (SGW, HLSJS...)
  // this is used for tracking, and exposed to the client so that they can react to it
  readonly code: TErrorCode;
  // Youbora specific category
  readonly category: ErrorCategories = ErrorCategories.DEFAULT;
  // If the error prevents playback
  readonly fatal: boolean = true;
  // A human friendly message/description of the error
  readonly message: string;
  // Debug information and implementation specific data
  readonly details: TErrorDetails;

  constructor(args: StandardErrorArgs) {
    this.code = args.code;
    this.category = args.category;
    this.fatal = args.fatal;
    this.message =
      args.message ||
      args.originalError?.message ||
      args.originalError?.errorMessage ||
      "Descriptive message missing.";
    this.details = buildErrorDetails(args);
  }
}
