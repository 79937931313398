import styled from "@emotion/styled";
import { FC, useEffect } from "react";

import { UiEventTypes } from "../../constants";
import { noop } from "../../utils";
import { useDispatch, usePlayerState } from "../context-provider";
import { OverlayWrapper } from "./elements";

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: none;
  cursor: default;
  background: rgba(0, 0, 0, 0);
`;

const InnerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 4fr 3fr 2fr;
  grid-template-areas:
    ". . ."
    ". message ."
    ". . .";
  width: 100%;
  height: 100%;
  align-items: end;
  justify-items: center;
`;

const ToastMessage = styled.div<{
  bgColor: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  grid-area: message;
  display: flex;
  width: fit-content;
  padding: 0.5em;
  text-align: center;
`;

export const ToastOverlay: FC = () => {
  const dispatch = useDispatch();
  const { toastMessage } = usePlayerState();

  useEffect(() => {
    if (!toastMessage) return noop;

    const hideToastOverlay = setTimeout(() => {
      dispatch({
        payload: {},
        type: UiEventTypes.HIDE_TOAST,
      });
    }, 2000);

    return () => {
      clearTimeout(hideToastOverlay);
      dispatch({
        payload: {},
        type: UiEventTypes.HIDE_TOAST,
      });
    };
  }, [dispatch, toastMessage]);

  if (!toastMessage) return null;

  return (
    <OverlayWrapper>
      <FlexContainer>
        <InnerGrid>
          <ToastMessage bgColor="rgba(0,0,0,0.8)">{toastMessage}</ToastMessage>
        </InnerGrid>
      </FlexContainer>
    </OverlayWrapper>
  );
};
