import styled from "@emotion/styled";
import { IAuthenticationComponent } from "@telia-company/tv.web-playback-sdk";
import React, { FC } from "react";

import { EnvironmentConfig } from "../config/ref-app-config";
import { BrandColorDark } from "../misc/constants";
import {
  changeEnvAction,
  logoutAction,
  useRefAppDispatch,
  useRefAppState,
} from "../reducer/ref.reducer";
import { Button } from "./Button";

const EnvironmentBarWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  background: ${BrandColorDark};
  display: flex;
  flex-wrap: wrap;
`;

const EnvItem = styled.li<{ right?: boolean }>`
  padding: 0.5rem;
  display: flex;
  align-self: end;
  margin: 0;
  margin-left: ${({ right }) => (right ? "auto" : "0")};
`;

// const LogoContainer = styled.div`
//   max-width: 1em;
//
//   > svg {
//     display: block;
//     width: 100%;
//     height: auto;
//   }
// `;

export type Props = {
  auth: IAuthenticationComponent;
  deviceId: string;
  sessionId: string;
};

export const EnvironmentBar: FC<Props> = () => {
  const { env, isLoggedIn } = useRefAppState();
  const dispatch = useRefAppDispatch();

  return (
    <EnvironmentBarWrapper>
      {EnvironmentConfig.map((envItem) => (
        <EnvItem key={envItem.id}>
          <Button
            disabled={envItem.name === env.name}
            onClick={() => {
              if (envItem.id !== env.id) {
                dispatch(changeEnvAction(envItem));
              }
            }}
            text={envItem.name}
          />
        </EnvItem>
      ))}
      {isLoggedIn && (
        <EnvItem right>
          <Button onClick={() => dispatch(logoutAction())} text="Logout" />
        </EnvItem>
      )}
    </EnvironmentBarWrapper>
  );
};
