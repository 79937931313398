import {
  ErrorCategories,
  PlaybackErrorEvent,
  PlaybackEventTypes,
  StandardError,
  StartingEvent,
  StoppedEvent,
  StoppedReasons,
  TrackingInfoEvent,
  UnknownError,
} from "@telia-company/tv.web-playback-sdk";
import {
  createPauseTimerUtility,
  getUuid,
  TTimerUtility,
  TUnfortunatelyAny,
  WebPlayerEventType,
} from "@telia-company/tv.web-player-shared";

import { TMutableSessionState } from "../@types/types";
import { getTrackingInfoPayload, registerTrackers } from "../tracking/tracking";
import { createDynamicPlaybackRateUtility } from "../util/dynamic-playback-rate-utility";
import { sessionSetup } from "./session-setup";
import { TGetPlaybackHandlerOptions, TTeardownFn } from "./types";

export interface PlaybackSessionHandler {
  onConcurrentStreams: (id: string) => void;
  teardown: TTeardownFn;
}

const playbackError = (error: TUnfortunatelyAny): PlaybackErrorEvent =>
  new PlaybackErrorEvent({
    error:
      error instanceof StandardError
        ? error
        : new StandardError({
            category: ErrorCategories.DEFAULT,
            code: error.code || UnknownError,
            fatal: true,
            originalError: error,
          }),
  });

export const getPlaybackSessionHandler = (
  options: TGetPlaybackHandlerOptions
): PlaybackSessionHandler => {
  const { event } = options;
  // Register trackers before emitting any events
  const unregisterTrackers = registerTrackers(options);

  const playbackSessionId = getUuid();

  // Immediately publish required tracker information
  event.publish(new StartingEvent({ playbackSessionId }));
  event.publish(new TrackingInfoEvent(getTrackingInfoPayload(options)));

  // This state is passed around inside of the session to enable checking
  // if it has ended after an async action has been taken
  const mutableSessionState: TMutableSessionState = {
    ended: false,
    engine: null,
  };

  let pauseTimerUtility: TTimerUtility | undefined;

  if (!options.disableSessionTimeout) {
    pauseTimerUtility = createPauseTimerUtility({
      event,
    });
  }

  const dynamicPlaybackRateHandler = createDynamicPlaybackRateUtility({
    event,
  });

  // Start listening for pauses
  pauseTimerUtility?.start();
  // Enable dynamic playback rate for catching up to live point
  dynamicPlaybackRateHandler.start();

  // The only method for ending a session
  const endSession = (reason: StoppedReasons) => {
    if (mutableSessionState.ended) return;

    mutableSessionState.ended = true;

    pauseTimerUtility?.stop();
    dynamicPlaybackRateHandler.stop();

    event.publish(
      new StoppedEvent({
        currentTime: mutableSessionState.engine?.getPosition(),
        duration: mutableSessionState.engine?.getDuration(),
        reason,
      })
    );

    unregisterTrackers();

    mutableSessionState.engine?.teardown();
  };

  // Event listener that interprets how a session was ended
  const stopSessionHandler = (
    type: PlaybackEventTypes | WebPlayerEventType /* payload */
  ) => {
    const unsubscribe = () => {
      event.off("*", stopSessionHandler);
    };

    switch (type) {
      case PlaybackEventTypes.ERROR:
        endSession(StoppedReasons.ERROR);
        unsubscribe();
        break;
      case WebPlayerEventType.CONCURRENT_STREAMS:
        endSession(StoppedReasons.CONCURRENT_STREAMS);
        unsubscribe();
        break;
      case WebPlayerEventType.END_OF_STREAM:
        endSession(StoppedReasons.END_OF_STREAM);
        unsubscribe();
        break;
      case WebPlayerEventType.USER_INACTIVITY:
        endSession(StoppedReasons.INACTIVITY);
        unsubscribe();
        break;
      default:
        break;
    }
  };

  event.on("*", stopSessionHandler);

  // The method exposed publicly to enable consumers of
  // playback-session to end the session with a reason.
  const teardown = (reason: StoppedReasons) => {
    endSession(reason);

    event.off("*", stopSessionHandler);
  };

  // Start loading everything required to start playback, in a non-blocking asynchronous way
  options.engineLogic
    .selectDrm()
    .then((drmType) => {
      if (mutableSessionState.ended) return undefined;

      return sessionSetup({
        ...options,
        drmType,
        mutableSessionState,
        playbackSessionId,
        teardown,
      });
    })
    .catch((error) => {
      if (mutableSessionState.ended) return;

      event.publish(playbackError(error));
    });

  const onConcurrentStreams = (id: string) => {
    if (id === playbackSessionId) {
      endSession(StoppedReasons.CONCURRENT_STREAMS);
    }
  };

  // Synchronously return the session API, enabling
  // synchronous interaction with teardown
  return {
    onConcurrentStreams,
    teardown,
  };
};
