import styled from "@emotion/styled";
import { CoreTrackingEvent } from "@telia-company/tv.web-playback-sdk";
import {
  TCallback,
  TPlaybackConfig,
} from "@telia-company/tv.web-player-shared";
import React, { FC, useCallback, useState } from "react";

import { TPlayerIcon } from "../../../../@types/icon-type";
import {
  TModalButton,
  TModalButtonPrimary,
  TModalOptions,
} from "../../../../@types/modal.types";
import { UiEventTypes } from "../../../../constants";
import { noop } from "../../../../utils";
import { ButtonWithIcon } from "../../../buttons/button-with-icon";
import {
  TOnRecordAndWatch,
  useCustomStyles,
  useDispatch,
  useIcon,
  usePlayerState,
  usePublishCustomTrackingEvent,
  useTranslations,
} from "../../../context-provider";
import { Progress } from "../../../progress-bar/progress-track";
import { OverflowWithScroll } from "../../../scrolling-text";
import { BrowsableList, TextOverflowEllipsis } from "../../elements";
import { TContentItemMetadata, TRelatedContentTypes } from "../types/types";
import { isPlayable, listPadding } from "../util";
import { usePanelState } from "./state.reducer";

const SimpleRelatedContentHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
  padding: 0 6%;
`;

const TitleText = styled.div`
  justify-self: center;
  font-weight: bold;
  font-size: 1em;
`;

export type TMiniEpgHeaderProps = {
  buttonIcon: TPlayerIcon;
  buttonText: string;
  headerText: string;
  onClose: TCallback;
} & React.JSX.IntrinsicAttributes;

export const SimpleRelatedContentHeader: FC<TMiniEpgHeaderProps> = ({
  buttonIcon,
  buttonText,
  headerText,
  onClose,
}) => (
  <SimpleRelatedContentHeaderWrapper>
    <TitleText>{headerText}</TitleText>
    {/* TODO hotkey after rebase on master */}
    <ButtonWithIcon Icon={buttonIcon} onClick={onClose} text={buttonText} />
  </SimpleRelatedContentHeaderWrapper>
);

const zoomPadding = "0.5em";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: ${zoomPadding} 0;
  margin: -${zoomPadding} 0;
`;

const PanelArrow = styled.div<{ rightAligned?: boolean }>`
  position: absolute;
  top: ${zoomPadding};
  bottom: ${zoomPadding};
  width: 2.5em;
  background: linear-gradient(
    ${({ rightAligned }) => (rightAligned ? "90deg" : "270deg")},
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75em;
  padding-bottom: 3.5em;

  cursor: pointer;
  & svg {
    width: 100%;
  }
  ${({ rightAligned }) => (rightAligned ? "right: 0" : "left: 0")};
`;

const PanelList = styled(BrowsableList)<{ contentType: TRelatedContentTypes }>`
  width: ${({ contentType }) => (contentType === "epg" ? "124%" : "134%")};
  margin-left: ${({ contentType }) =>
    contentType === "epg" ? "-12%" : "-17%"};
`;

const flashKeyframe = `
@keyframes flash {
  0% {
    opacity:1;
  }
  50% {
    opacity:0.7;
  }
  100% {
    opacity:1;
  }
}

animation: flash normal 1s infinite ease-in-out;
`;

const PanelItem = styled.div<{
  currentlyPlaying: boolean;
  isFillerItem: boolean;
  loadingNewPage: boolean;
  playable: boolean;
}>`
  cursor: ${({ currentlyPlaying, loadingNewPage, playable }) =>
    !playable || loadingNewPage || currentlyPlaying ? "default" : "pointer"};

  ${({ isFillerItem }) => (isFillerItem ? "visibility: hidden;" : "")}

  ${({ loadingNewPage, playable }) => `
      ${loadingNewPage ? flashKeyframe : ""}
      
      ${
        !playable || loadingNewPage
          ? ""
          : `
        &:hover {
          transform: scale(1.06);
        }
      `
      }
    `}

  transition: transform 0.1s ease-in-out;
`;

const PanelItemImage = styled.div<{
  backgroundColor: string;
  borderRadius: string;
  contentType: TRelatedContentTypes;
  currentlyPlaying: boolean;
}>`
  border-radius: ${({ borderRadius }) => borderRadius};
  margin-bottom: 0.75em;
  // hide pixel scaling alignment issues
  overflow: hidden;

  position: relative;
  outline: none;

  &:before {
    background-color: ${({ backgroundColor, currentlyPlaying }) =>
      currentlyPlaying ? "#ffffff" : backgroundColor};
    display: block;
    content: "";
    width: 100%;
    padding-top: ${({ contentType }) =>
      contentType === "epg" ? "100%" : "56.25%"};
  }
`;

const FavoriteChannel = styled.div<{ fillColor: string }>`
  position: absolute;
  bottom: 0.5em;
  left: 0.2em;
  width: 1em;
  height: 1em;
  *[fill] {
    fill: ${({ fillColor }) => fillColor};
  }
}
`;

const ImageWrapper = styled.div<{ contentType: TRelatedContentTypes }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ contentType }) => (contentType === "epg" ? "25%" : "0")};
`;

const PanelItemTitle = styled.div`
  margin: 0;
  line-height: 1.05;
  font-size: 0.8em;
  font-weight: bold;
  padding-bottom: 0.25em;
  overflow: hidden;
`;

const PanelItemSubtitle = styled.p`
  margin: 0;
  line-height: 1.05;
  font-size: 0.7em;
  color: rgba(255, 255, 255, 0.66);
`;

export type TContentItem = {
  backgroundColor: string;
  borderRadius: string;
  contentType: TRelatedContentTypes;
  currentlyPlaying: boolean;
  eventEnded: string;
  eventNotStarted: string;
  i: TContentItemMetadata;
  isFavorite?: boolean;
  loadingNewPage: boolean;
  notPlayable: string;
  onPlayContent: (p: TPlaybackConfig) => void;
  playable: boolean;
  playingNow: string;
  progress: number;
  progressBarColor: string;
};

const ImgWithFadeIn = styled.img<{ loaded: boolean }>`
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  transition: opacity 0.5s ease-in;
`;

const getImage = (
  item: TContentItemMetadata,
  currentlyPlaying: boolean
): string => {
  if (item.type === "npvr" || item.type === "channel") {
    return currentlyPlaying ? item.iconOnLightBg : item.iconOnDarkBg;
  }

  return item.image;
};

const getProgress = (item: TContentItemMetadata): number => {
  if (item.type === "filler") return 0;

  if (item.type === "series") return item.progress;

  if (item.type === "channel" || item.type === "npvr") {
    const startTime = item.currentProgram.startTime || 0;
    const endTime = item.currentProgram.endTime || 0;

    const elapsedMs = Date.now() - startTime;
    const lengthMs = endTime - startTime;
    return Math.min(100, (elapsedMs / lengthMs) * 100);
  }

  if (item.type === "sport") {
    const startTime = item.startTime || 0;
    const endTime = item.endTime || 0;

    const elapsedMs = Date.now() - startTime;
    const lengthMs = endTime - startTime;
    return Math.min(100, (elapsedMs / lengthMs) * 100);
  }

  return 0;
};

const invisibleWhiteSpace = String.fromCharCode(160);

const UnplayableOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  text-align: center;
`;

export type TGetUnplayableTextOptions = {
  eventEnded: string;
  eventNotStarted: string;
  i: TContentItemMetadata;
  notPlayable: string;
};

const getUnplayableText = ({
  eventEnded,
  eventNotStarted,
  i,
  notPlayable,
}: TGetUnplayableTextOptions): string => {
  if (i.type === "sport") {
    const now = Date.now();

    if (i.endTime < now) {
      return eventEnded;
    }

    if (i.startTime > now) {
      return eventNotStarted;
    }
  }

  return notPlayable;
};

const Label = styled.div<{
  bgColor: string;
  borderRadius: string;
  textColor: string;
}>`
  color: ${({ textColor }) => textColor};
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  background: ${({ bgColor }) => bgColor};
  font-size: 0.6em;
  padding: 0.5em;
  border-bottom-right-radius: ${({ borderRadius }) => borderRadius};
`;

export const ContentItem: FC<
  {
    onRecordAndWatch: TOnRecordAndWatch;
    showModal: (options: TModalOptions) => void;
  } & TContentItem
> = ({
  backgroundColor,
  borderRadius,
  contentType,
  currentlyPlaying,
  eventEnded,
  eventNotStarted,
  i,
  isFavorite,
  loadingNewPage,
  notPlayable,
  onPlayContent,
  onRecordAndWatch,
  playable,
  playingNow,
  progress,
  progressBarColor,
  showModal,
}) => {
  const publish = usePublishCustomTrackingEvent();
  const dispatch = useDispatch();
  const {
    liveSeekEnabled,
    metadata: { endTime, startTime },
  } = usePlayerState();
  const {
    zapModalBody,
    zapModalCancel,
    zapModalConfirm,
    zapModalTitle,
    // zapModalNpvrBody, // TODO: pending decision on how to present channel dvr stuff in npvr popup
  } = useTranslations();

  const { accentColor } = useCustomStyles();
  const { IconHeartFill: FavoriteIcon } = useIcon();
  const [loaded, setLoaded] = useState(false);
  const [focused, setFocused] = useState(false);

  const channelRelated = i.type === "channel" || i.type === "npvr";

  const image = getImage(i, currentlyPlaying);
  const subtitle = channelRelated ? i.currentProgram.subtitle : i.subtitle;
  const title = channelRelated ? i.currentProgram.title : i.title;

  const mouseEnter = useCallback(() => {
    setFocused(true);
  }, []);
  const mouseLeave = useCallback(() => {
    setFocused(false);
  }, []);

  const onPressed = () => {
    if (currentlyPlaying || loadingNewPage) return;

    // re-check playability since time has passed
    if (!isPlayable(i)) return;

    if (i.type === "filler") return;

    if (!i.playback) return;

    publish(CoreTrackingEvent.PLAYER_MORE_CONTENT_ASSET_CLICK);

    if (liveSeekEnabled) {
      const ongoing =
        startTime && endTime && Date.now() > startTime && Date.now() < endTime;

      const playContent = () => {
        if (i.playback) {
          onPlayContent(i.playback);
        }
      };
      const dismissModal = () => {
        dispatch({
          payload: {},
          type: UiEventTypes.HIDE_MODAL,
        });
        return Promise.resolve();
      };

      if (!ongoing && i.type !== "npvr") {
        // create data for modal that is shown when changing channels would cause the
        // current dvr buffer to be lost
        const confirmButton: TModalButtonPrimary = {
          buttonText: zapModalConfirm,
          onClick: () =>
            new Promise<void>(() => {
              playContent();
            }),
          type: "primary",
        };
        const cancelButton: TModalButton = {
          buttonText: zapModalCancel,
          onClick: () => dismissModal(),
          type: "secondary",
        };

        const zapConfirmationMetadata = {
          body: zapModalBody,
          title: zapModalTitle,
        };

        const modalOptions: TModalOptions = {
          modalButtons: [confirmButton, cancelButton],
          modalMetadata: zapConfirmationMetadata,
        };

        showModal(modalOptions);
        return;
      }
      if (!ongoing && i.type === "npvr") {
        // update copy in npvr recording modal
        // i.modalMetadata.body += `something something...`;
      }
    }

    if (i.type === "npvr") {
      const modalButton = {
        ...i.recordButton,
        onClick: () => onRecordAndWatch(i.recordButton.onClick),
      };

      const modalOptions: TModalOptions = {
        modalButtons: [modalButton],
        modalMetadata: i.modalMetadata,
      };

      showModal(modalOptions);
    } else {
      onPlayContent(i.playback);
    }
  };

  return (
    <PanelItem
      currentlyPlaying={currentlyPlaying}
      isFillerItem={i.type === "filler"}
      loadingNewPage={loadingNewPage}
      onClick={playable ? onPressed : noop}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      playable={playable}
    >
      <PanelItemImage
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        contentType={contentType}
        currentlyPlaying={!loadingNewPage && currentlyPlaying}
      >
        <ImageWrapper contentType={contentType}>
          {!loadingNewPage && (
            <>
              <ImgWithFadeIn
                alt={i.title}
                loaded={loaded}
                onLoad={() => setLoaded(true)}
                src={image}
              />

              {!playable && i.type !== "channel" && (
                <UnplayableOverlay>
                  {currentlyPlaying
                    ? playingNow
                    : getUnplayableText({
                        eventEnded,
                        eventNotStarted,
                        i,
                        notPlayable,
                      })}
                </UnplayableOverlay>
              )}

              {i.type !== "filler" && i.label && (
                <Label
                  bgColor={i.label.backgroundColor}
                  borderRadius={borderRadius}
                  textColor={i.label.textColor}
                >
                  {i.label.text}
                </Label>
              )}

              {!loadingNewPage && !!progress && (
                <Progress
                  horizontal
                  progressBarColor={progressBarColor}
                  style={{
                    bottom: 0,
                    top: "auto",
                    width: `${progress}%`,
                  }}
                />
              )}
            </>
          )}
        </ImageWrapper>
        {isFavorite ? (
          <FavoriteChannel fillColor={accentColor}>
            <FavoriteIcon />
          </FavoriteChannel>
        ) : null}
      </PanelItemImage>
      <PanelItemTitle>
        <OverflowWithScroll runAnimation={focused}>
          {loadingNewPage || !title ? invisibleWhiteSpace : title}
        </OverflowWithScroll>
      </PanelItemTitle>
      <PanelItemSubtitle>
        <TextOverflowEllipsis>
          {loadingNewPage || !subtitle ? invisibleWhiteSpace : subtitle}
        </TextOverflowEllipsis>
      </PanelItemSubtitle>
    </PanelItem>
  );
};

export type TPanelProps = {
  borderRadius: string;
  contentType: TRelatedContentTypes;
  currentlyPlayingVideoId: string;
  eventEnded: string;
  eventNotStarted: string;
  itemBackground: string;
  LeftIcon: TPlayerIcon;
  nextPageFn?: TCallback;
  notPlayable: string;
  onItemPressed: (p: TPlaybackConfig) => void;
  onRecordAndWatch: TOnRecordAndWatch;
  pageSize: number;
  playingNow: string;
  prevPageFn?: TCallback;
  progressBarColor: string;
  RightIcon: TPlayerIcon;
  selectedSeason: null | number;
  showModal: (options: TModalOptions) => void;
};

export const Panel: FC<TPanelProps> = ({
  borderRadius,
  contentType,
  currentlyPlayingVideoId,
  eventEnded,
  eventNotStarted,
  itemBackground,
  LeftIcon,
  nextPageFn,
  notPlayable,
  onItemPressed,
  onRecordAndWatch,
  pageSize,
  playingNow,
  prevPageFn,
  progressBarColor,
  RightIcon,
  showModal,
}) => {
  const { loadingNewPage, panelItems } = usePanelState();

  return (
    <Wrapper>
      <PanelList contentType={contentType} pageSize={pageSize + listPadding}>
        {panelItems.items.map((i) => {
          const progress = getProgress(i);
          const playable = isPlayable(i, currentlyPlayingVideoId);

          return (
            <ContentItem
              backgroundColor={itemBackground}
              borderRadius={borderRadius}
              contentType={contentType}
              currentlyPlaying={
                i.type !== "filler" &&
                currentlyPlayingVideoId === i.playback?.playbackSpec.videoId
              }
              eventEnded={eventEnded}
              eventNotStarted={eventNotStarted}
              i={i}
              isFavorite={
                i.type === "channel" || i.type === "npvr"
                  ? i.isFavorite
                  : undefined
              }
              key={i.id}
              loadingNewPage={loadingNewPage}
              notPlayable={notPlayable}
              onPlayContent={onItemPressed}
              onRecordAndWatch={onRecordAndWatch}
              playable={playable}
              playingNow={playingNow}
              progress={progress || 0}
              progressBarColor={progressBarColor}
              showModal={showModal}
            />
          );
        })}
      </PanelList>

      {prevPageFn && (
        <PanelArrow
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            prevPageFn();
          }}
        >
          <LeftIcon />
        </PanelArrow>
      )}

      {nextPageFn && (
        <PanelArrow
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            nextPageFn();
          }}
          rightAligned
        >
          <RightIcon />
        </PanelArrow>
      )}
    </Wrapper>
  );
};
