import { TUnfortunatelyAny } from "../shared-types";
import { canJSONStringify, PREFIX } from "./util";

const localStorageSupported =
  typeof window.localStorage !== "undefined" && window.localStorage !== null;

export enum LocalStorageKey {
  MUTED = "MUTED",
  REF_APP_DEVICE_ID = "REF_APP_DEVICE_ID",
  REF_APP_ENV = "REF_APP_ENV",
  REF_APP_SERVICECOUNTRY = "REF_APP_SERVICECOUNTRY",
  VOLUME = "VOLUME",
}

export const getFromLocalStorage = (
  key: LocalStorageKey
): TUnfortunatelyAny | undefined => {
  if (!localStorageSupported) return undefined;

  const value = localStorage.getItem(PREFIX + key);

  if (!value) return undefined;

  return JSON.parse(value);
};

export const setInLocalStorage = (
  key: LocalStorageKey,
  value: TUnfortunatelyAny | undefined
): void => {
  if (!localStorageSupported) return;

  if (!canJSONStringify(value)) return;

  localStorage.setItem(PREFIX + key, JSON.stringify(value));
};
