import {
  BaseManager,
  Logger as LoggerSingleton,
  TPlaybackEvent,
  PlaybackEventTypes,
  TrackingInfoEvent,
} from "../base";

import { BaseTracker } from "./trackers/BaseTracker";
import deepmerge from "deepmerge";

const Logger = LoggerSingleton.createLoggerContext("TrackingManager");

export type TUnregisterAllTrackersOptions = {
  destroy?: boolean;
};

export class TrackingManager extends BaseManager {
  private mergedTrackingInfoData: TrackingInfoEvent | undefined;

  constructor() {
    super("TrackingManager");
  }

  public track(event: TPlaybackEvent) {
    switch (event.type) {
      case PlaybackEventTypes.ADVERTISEMENT_ENDED:
        this.executeOnComponents("advertisementEnded", event);
        break;
      case PlaybackEventTypes.ADVERTISEMENT_STARTED:
        this.executeOnComponents("advertisementStarted", event);
        break;
      case PlaybackEventTypes.ADVERTISEMENT_BREAK_ENDED:
        this.executeOnComponents("advertisementBreakEnded", event);
        break;
      case PlaybackEventTypes.ADVERTISEMENT_BREAK_STARTED:
        this.executeOnComponents("advertisementBreakStarted", event);
        break;
      case PlaybackEventTypes.ADVERTISEMENT_TIME_UPDATE:
        this.executeOnComponents("advertisementTimeUpdate", event);
        break;
      case PlaybackEventTypes.AUDIO_TRACK_CHANGED:
        this.executeOnComponents("audioTrackChanged", event);
        Logger.debug("Track : audioTrackChanged -> ", event);
        break;
      case PlaybackEventTypes.BITRATE_CHANGED:
        Logger.debug("Track : bitrateChanged -> ", event);
        this.executeOnComponents("bitrateChanged", event);
        break;
      case PlaybackEventTypes.BUFFERED:
        Logger.debug(`Track : buffered -> ${event}`);
        this.executeOnComponents("buffered", event);
        break;
      case PlaybackEventTypes.BUFFERING:
        Logger.debug(`Track : buffering -> ${event}`);
        this.executeOnComponents("buffering", event);
        break;
      case PlaybackEventTypes.CDN_CHANGED:
        Logger.debug("Track : cdnChanged -> ", event);
        this.executeOnComponents("cdnChanged", event);
        break;
      case PlaybackEventTypes.CUSTOM_EVENT:
        Logger.debug("Track : customEvent -> ", event);
        this.executeOnComponents("custom", event);
        break;
      case PlaybackEventTypes.DROPPED_FRAMES:
        Logger.debug("Track : droppedFrames -> ", event);
        this.executeOnComponents("droppedFrames", event);
        break;
      case PlaybackEventTypes.ERROR:
        Logger.debug("Track : error -> ", event);
        this.executeOnComponents("error", event);
        break;
      case PlaybackEventTypes.LOADED:
        Logger.debug(`Track : loaded -> ${event}`);
        this.executeOnComponents("loaded", event);
        break;
      case PlaybackEventTypes.LOADING:
        Logger.debug(`Track : loading -> ${event}`);
        this.executeOnComponents("loading", event);
        break;
      case PlaybackEventTypes.MANIFEST_TYPE_CHANGED:
        break;
      case PlaybackEventTypes.PAUSED:
        Logger.debug(`Track : paused -> ${event}`);
        this.executeOnComponents("paused", event);
        break;
      case PlaybackEventTypes.PLAY:
        break;
      case PlaybackEventTypes.PLAYING:
        Logger.debug(`Track : playing -> ${event}`);
        this.executeOnComponents("playing", event);
        break;
      case PlaybackEventTypes.SEEKED:
        Logger.debug(`Track : seeked -> ${event}`);
        this.executeOnComponents("seeked", event);
        break;
      case PlaybackEventTypes.SEEKING:
        Logger.debug(`Track : seeking -> ${event}`);
        this.executeOnComponents("seeking", event);
        break;
      case PlaybackEventTypes.STARTING:
        Logger.debug(`Track : starting -> ${event}`);
        this.executeOnComponents("starting", event);
        break;
      case PlaybackEventTypes.STOPPED:
        Logger.debug("Track : stopped -> ", event);
        this.executeOnComponents("stopped", event);
        break;
      case PlaybackEventTypes.STREAM_CUE:
        Logger.debug("Track : streamCue -> ", event);
        this.executeOnComponents("streamCue", event);
        break;
      case PlaybackEventTypes.STREAM_INFO:
        Logger.debug(`Track : streamInfo`, event);
        this.executeOnComponents("streamInfo", event);
        break;
      case PlaybackEventTypes.TEXT_TRACK_CHANGED:
        Logger.debug("Track : textTrackChanged -> ", event);
        this.executeOnComponents("textTrackChanged", event);
        break;
      case PlaybackEventTypes.TIME_UPDATE:
        this.executeOnComponents("timeUpdate", event);
        break;
      case PlaybackEventTypes.TRACKING_INFO:
        Logger.debug("Track : trackingInfo -> ", event);

        this.mergedTrackingInfoData = deepmerge<TrackingInfoEvent>(
          this.mergedTrackingInfoData || {},
          event
        );

        this.executeOnComponents("trackingInfo", this.mergedTrackingInfoData);
        break;
      case PlaybackEventTypes.VOLUME_CHANGED:
        Logger.debug("Track : volumeChanged -> ", event);
        this.executeOnComponents("volumeChanged", event);
        break;
      default:
        break;
    }
  }

  public registerTracker(tracker: BaseTracker) {
    if (this.getComponentWithName(tracker.name)) {
      return Logger.error(
        `TrackingManager::registerTracker: Tracker with name: ${tracker.name} is already registered!`
      );
    }

    this.registerComponent(tracker);

    return this.getComponentWithName(tracker.name);
  }

  public unregisterTracker(tracker: BaseTracker) {
    if (!this.getComponentWithName(tracker.name)) {
      Logger.warn(
        `TrackingManager::unregisterTracker: You are trying to remove a unregistered tracker named:${tracker.name}`
      );
    }

    this.unregisterComponent(tracker.name, false);
  }

  public unregisterAllTrackers({
    destroy = false,
  }: TUnregisterAllTrackersOptions = {}) {
    this.unregisterAllComponents(destroy);
  }

  public async reset(): Promise<void> {
    this.mergedTrackingInfoData = undefined;

    return super.reset();
  }

  public async destroy(): Promise<void> {
    return super.destroy();
  }
}
