import { CoreTrackingEvent } from "@telia-company/tv.web-playback-sdk";
import { useEffect } from "react";

export type TUseRelatedContentImpression = {
  publish: (name: string) => void;
  showRelatedContentOverlay: boolean;
};

export const useRelatedContentImpression = ({
  publish,
  showRelatedContentOverlay,
}: TUseRelatedContentImpression) => {
  useEffect(() => {
    if (showRelatedContentOverlay) {
      publish(CoreTrackingEvent.PLAYER_MORE_CONTENT);
    }
  }, [showRelatedContentOverlay, publish]);
};
