const Emitter = require('../../../../emitter');

const Parser = Emitter.extend(
  /** @lends npaw.Parser.prototype */
  {
    /**
     * Class that asynchronously parses a resource in order to get to the URL.
     *
     * Since the CDN detection is performed with the resource url, it is essential that this
     * resource url is pointing to the CDN that is actually hosting the manifest.
     *
     * @constructs Parser
     * @extends npaw.Emitter
     * @memberof npaw
     */
    constructor: function (headers) {
      this._realResource = undefined;
      this._lastManifest = undefined;
      this._transportFormat = undefined;
      this.iterations = 3;
      this._headers = headers;
    },

    /**
     * Emits DONE event
     */
    done: function () {
      this.iterations = 3;
      this.emit(Parser.Event.DONE);
    },

    /**
     * Starts the parsing from the given resource.
     *
     * @param {string} resource The resource url.
     * @param {object} lastManifest The manifest request response if parsed previously.
     */
    parse: function (resource, lastManifest) { },

    /**
     * Get the parsed resource. Will be null/undefined if parsing is not yet started and can be a partial
     * (an intermediate manifest) result if the parser is still running.
     *
     * @return {string} The parsed resource.
     */
    getResource: function () {
      return this._realResource;
    },

    /**
     * Get the transport format. Will be null/undefined if parsing is not yet started or is still running.
     *
     * @return {string} The parsed resource.
     */
    getTransportFormat: function () {
      return this._transportFormat;
    },

    /**
     * Get the last request manifest response object used to parse the resource.
     * Will be null/undefined if parsing is not yet started and can be a partial
     * (an intermediate manifest) result if the parser is still running.
     *
     * @return {object} The last manifest request response used to parse the resource.
     */
    getLastManifest: function () {
      return this._lastManifest;
    },

    /**
     * Get the result of checking a previous manifest to detect if the parser should be used or not.
     *
     * @param {string} resource The resource url.
     * @return {object} The last manifest request response used to parse the resource.
     */
    shouldExecute: function (lastManifest) {
      return true;
    }
  },

  /** @lends npaw.Parser */
  {
    // Static members

    /**
     * List of events that could be fired from this class.
     * @enum
     */
    Event: {
      /** Notifies that this DashParser is done processing. */
      DONE: 'done'
    }
  }
);

module.exports = Parser;
