import styled from "@emotion/styled";
import { TThumbnail } from "@telia-company/tv.web-player-shared";

import { Dot } from "../elements";
import { fadeAnimation } from "../styles";

export type TProgressBarProps = {
  smallLayout: boolean;
  visible: boolean;
};

export type TProgressContainerProps = {
  noTimerRight: boolean;
  shortTimerLeft: boolean;
  shortTimerRight: boolean;
  smallLayout: boolean;
};

export type TSeekBubbleProps = {
  withThumbnail?: boolean;
};

export const ProgressTrackContainer = styled.div<TProgressContainerProps>`
  height: 100%;

  grid-column: ${({ noTimerRight, smallLayout }) => {
    if (smallLayout) return "1 / 13;";
    return noTimerRight ? "span 15;" : "span 14;";
  }}
  display: flex;
  align-items: center;
  position: relative;

  ${({ shortTimerLeft, shortTimerRight, smallLayout }) => {
    if (smallLayout) return "margin: 0 1%;";

    return `margin: 0 ${shortTimerRight ? "1%" : "2.5%"} 0 ${
      shortTimerLeft ? "1%" : "2.5%"
    };`;
  }}
`;

export const ProgressDot = styled(Dot)`
  position: absolute;
  pointer-events: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

export const ProgressBar = styled.div<TProgressBarProps>`
  display: grid;
  grid-template-columns: repeat(
    ${({ smallLayout }) => (smallLayout ? "13, auto" : "16, 1fr")}
  );
  grid-column: ${({ smallLayout }) => (smallLayout ? "3" : "1")} / -1;
  grid-row: ${({ smallLayout }) => (smallLayout ? "8 / -1" : "8")};
  grid-gap: 0;
  align-self: ${({ smallLayout }) => (smallLayout ? "center" : "end")};
  ${({ visible }) => fadeAnimation(visible)}
`;

/* export const FloatingBubble = styled.div`
  flex-shrink: 0;
  margin-top: -1.6em;
`; */

export const SeekBubble = styled.div<TSeekBubbleProps>`
  position: ${({ withThumbnail }) => (withThumbnail ? "relative" : "absolute")};
  font-size: 0.9em;
  line-height: 0.9;
  height: 0.9em;

  transform: translateX(-50%);

  ${({ withThumbnail }) => (withThumbnail ? "bottom: 2.2em" : "top: -3em")};
  width: ${({ withThumbnail }) => (withThumbnail ? "100%" : "auto")};
  text-align: center;
  border-radius: 5px;
`;

export const ThumbnailWrapper = styled.div<{ scaleFactor: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 4.1em;
  transform-origin: bottom left;
  ${({ scaleFactor }) => `transform: scale(${scaleFactor});`}
`;

export const Thumbnail = styled.div<{
  img: TThumbnail;
  offset: { left: null | number; right: null | number } | null;
}>`
  position: relative;
  background-image: url("${({ img }) => img.uris[0]}");
  background-position: ${({ img }) => img.positionX}px
    ${({ img }) => img.positionY}px;
  background-repeat: no-repeat;
  width: ${({ img }) => img.width}px;
  height: ${({ img }) => img.height}px;
  transform: translateX(-50%);
  ${({ offset }) => (offset?.left ? `left: ${Math.round(offset.left)}px` : ``)}
  ${({ offset }) =>
    offset?.right ? `right: ${Math.round(offset.right)}px` : ``}
`;
