import { TUnfortunatelyAny } from "../shared-types";
import { canJSONStringify, PREFIX } from "./util";

const sessionStorageSupported =
  typeof window.sessionStorage !== "undefined" &&
  window.sessionStorage !== null;

export enum SessionStorageKey {
  AUDIO_LANG = "AUDIO_LANG",
  MUTED = "MUTED",
  SUBTITLE_LANG = "SUBTITLE_LANG",
  SUBTITLE_VISIBLE = "SUBTITLE_VISIBLE",
  VOLUME = "VOLUME",
}

export const getFromSessionStorage = (
  key: SessionStorageKey
): TUnfortunatelyAny => {
  if (!sessionStorageSupported) return undefined;

  const value = sessionStorage.getItem(PREFIX + key);

  if (!value) return undefined;

  return JSON.parse(value);
};

export const setInSessionStorage = (
  key: SessionStorageKey,
  value: TUnfortunatelyAny
): void => {
  if (!sessionStorageSupported) return;

  if (!canJSONStringify(value)) return;

  sessionStorage.setItem(PREFIX + key, JSON.stringify(value));
};
