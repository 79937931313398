import { TPlayerErrorId } from "./error/error-ids";

export * from "@telia-company/tv.web-playback-sdk";

export enum WebPlayerEventType {
  AD_MARKERS = "AD_MARKERS",
  AUTOPLAY_BLOCKED = "AUTOPLAY_BLOCKED",
  BUFFER_FULLNESS = "BUFFER_FULLNESS",
  CONCURRENT_STREAMS = "CONCURRENT_STREAMS",
  CONTROLS_AVAILABLE = "CONTROLS_AVAILABLE",
  END_OF_STREAM = "END_OF_STREAM",
  INITIAL_ACTIVE_TRACKS = "INITIAL_ACTIVE_TRACKS",
  IS_LIVE = "IS_LIVE",
  LANGUAGES = "LANGUAGES",
  MANIFEST_TYPE_CHANGED = "MANIFEST_TYPE_CHANGED",
  PLAYBACK_RESTRICTIONS = "PLAYBACK_RESTRICTIONS",
  SEEK_RANGE_UPDATE = "SEEK_RANGE_UPDATE",
  SPEED_CHANGE = "SPEED_CHANGE",
  TIMELINE_DATA = "TIMELINE_DATA",
  USER_INACTIVITY = "USER_INACTIVITY",
  USER_INACTIVITY_WARNING = "USER_INACTIVITY_WARNING",
  VIDEO_ELEMENT_READY = "VIDEO_ELEMENT_READY",
}

export enum WatchMode {
  LIVE = "LIVE",
  ONDEMAND = "ONDEMAND",
  STARTOVER = "STARTOVER",
  TRAILER = "TRAILER",
}

export enum VideoIdType {
  CHANNEL = "CHANNEL",
  MEDIA = "MEDIA",
}

export enum AccessControl {
  FREE = "FREE",
  NPVR = "NPVR",
  SUBSCRIPTION = "SUBSCRIPTION",
  TRANSACTION = "TRANSACTION",
}

// TODO need to be randomly generated to support multiple video elements. Use playbackSessionId.
export const VIDEO_ELEMENT_ID = "VIDEO_ELEMENT_ASDFGHJKL";
export const TEXT_ELEMENT_ID = "TEXT_ELEMENT_ASDFGHJKL";

export const MsEdgeErrorMap: Record<string, null | TPlayerErrorId> = {
  "800401fb": "UNSUPPORTED_DEVICE", // Windows needs to be updated
  "800401fd": null,
  "8004110e": "DRM_INVALID", // DRM_E_CH_BAD_KEY
  "8007000e": null,
  "88890001": "UNSUPPORTED_DEVICE", // Windows needs to be updated
  "8889000f": "UNSUPPORTED_DEVICE", // Windows needs to be updated
  c00d36b2: "UNSUPPORTED_SCREEN",
  c00d36b4: "UNSUPPORTED_SCREEN",
  c00d36b6: null,
  c00d3e8c: null,
  c00d4e85: "UNSUPPORTED_SCREEN",
  c00d4e86: "UNSUPPORTED_SCREEN",
  c00d6d6f: null,
  c00d7159: "UNSUPPORTED_SCREEN",
};

export const NativeErrorMap: Record<number, string> = {
  1: "MEDIA_ERR_ABORTED",
  2: "MEDIA_ERR_NETWORK",
  3: "MEDIA_ERR_DECODE",
  4: "MEDIA_ERR_SRC_NOT_SUPPORTED",
};
