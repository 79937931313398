import styled from "@emotion/styled";
import { TCallback } from "@telia-company/tv.web-player-shared";
import { lighten } from "polished";
import { FC } from "react";

import { BrandColor, BrandColorDark } from "../misc/constants";

export type Props = {
  disabled?: boolean;
  onClick: TCallback;
  text: string;
};

export const StyledButton = styled.button`
  padding: 0.5rem 1rem;
  margin: 0;
  background: ${BrandColor};
  border: 0;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #fff;
  text-shadow: 1px 1px ${BrandColorDark};
  display: block;
  font-weight: bold;

  :hover:not([disabled]) {
    background: ${lighten(0.1, BrandColor)};
  }

  &[disabled],
  &:disabled {
    cursor: default;
    background: ${BrandColorDark};
  }
`;

export const Button: FC<Props> = ({ disabled = false, onClick, text }) => (
  <StyledButton disabled={disabled} onClick={onClick} type="submit">
    {text}
  </StyledButton>
);
