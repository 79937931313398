import { StandardError, StandardErrorArgs } from "./StandardError";
import { ErrorCategories } from "../Constants";
import { getCodeFromNetworkError } from "./utils";
import { NetworkError } from "./NetworkError";
import { GraphErrorMap } from "./constants";

export type TGraphErrorArgs = Omit<StandardErrorArgs, "code" | "category"> & {
  originalError: NetworkError;
};

export class GraphError extends StandardError {
  constructor(args: TGraphErrorArgs) {
    super({
      ...args,
      category: ErrorCategories.GRAPH,
      code: getCodeFromNetworkError({
        error: args.originalError,
        errorMap: GraphErrorMap,
      }),
    });
  }
}
