export default class NpawPluginOptions {
    constructor() {
        /**
         * Fast Data server host
         * Default: 'lma.npaw.com'
         */
        this.host = 'lma.npaw.com';
        this.components = {};
        this.host = 'lma.npaw.com';
        this.components = {};
    }
}
