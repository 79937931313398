import { ChannelMetadata, Icons, TimeRange } from "../Types";
import IChannel = GraphTypes.IChannel;
import { ChannelIconMapper, ChannelProgramMapper } from "./CommonMappers";

export const ChannelMetadataMapper = (
  response: { data: { channel: IChannel } },
  timeRange?: TimeRange
): ChannelMetadata => {
  if (!(response && response.data && response.data.channel)) {
    throw new Error(
      "MetadataService::ChannelMetadataMapper: Missing data or channel!"
    );
  }

  const graphChannel = response.data.channel;
  const icons: Icons = ChannelIconMapper(graphChannel);

  if (!graphChannel.playback.play?.playbackSpec) {
    throw new Error(
      `Error class:ChannelMetadataMapper[ChannelMetadataMapper] : No playbackSpec in graphChannel.playback.play!`
    );
  }

  const channelMetadata: ChannelMetadata = {
    icons,
    id: graphChannel.id,
    name: graphChannel.name,
    playbackSpec: graphChannel.playback.play!.playbackSpec,
    programs: [],
  };

  channelMetadata.programs = ChannelProgramMapper(graphChannel);
  return channelMetadata;
};
