import {
  getFromSessionStorage,
  SessionStorageKey,
  setInSessionStorage,
  TUnfortunatelyAny,
} from "@telia-company/tv.web-player-shared";
import { PointerEvent, useCallback } from "react";

import { TChangeVolumeArgs } from "../@types/types";
import { usePlayerState } from "./context-provider";

export type TUseVolume = {
  changeVolume: (args: TChangeVolumeArgs) => void;
  decreaseVolume: () => void;
  increaseVolume: () => void;
  toggleMute: (e?: PointerEvent<HTMLDivElement>) => void;
};

export const useVolume = (): TUseVolume => {
  const { videoElement } = usePlayerState();
  const toggleMute = useCallback(
    (e: TUnfortunatelyAny) => {
      if (e) {
        e.preventDefault();
      }
      if (videoElement) {
        const isMuted = videoElement.muted;
        const toggleMuteVolume = videoElement.volume;

        if (isMuted) {
          const sessionStorageVolume = getFromSessionStorage(
            SessionStorageKey.VOLUME
          );

          if (typeof sessionStorageVolume === "number") {
            videoElement.volume = sessionStorageVolume;
          }
        } else if (!isMuted && toggleMuteVolume > 0) {
          setInSessionStorage(SessionStorageKey.VOLUME, toggleMuteVolume);
        }

        videoElement.muted = !isMuted;
        setInSessionStorage(SessionStorageKey.MUTED, !isMuted);
      }
    },
    [videoElement]
  );

  const changeVolume = useCallback(
    ({ saveVolume = false, vol }: TChangeVolumeArgs) => {
      if (videoElement && typeof vol === "number") {
        if (!videoElement.muted && vol === 0) {
          videoElement.muted = true;
          setInSessionStorage(SessionStorageKey.MUTED, true);
        } else if (videoElement.muted && vol > 0) {
          videoElement.volume = vol;
          videoElement.muted = false;

          if (saveVolume) {
            setInSessionStorage(SessionStorageKey.VOLUME, vol);
            setInSessionStorage(SessionStorageKey.MUTED, false);
          }
        } else {
          videoElement.volume = vol;

          if (saveVolume) {
            setInSessionStorage(SessionStorageKey.VOLUME, vol);
            setInSessionStorage(SessionStorageKey.MUTED, false);
          }
        }
      }
    },
    [videoElement]
  );

  const decreaseVolume = useCallback(() => {
    if (!videoElement) return;
    const vol = Math.max(Math.min(videoElement.volume - 0.05, 1), 0);
    changeVolume({
      saveVolume: vol !== 0,
      vol,
    });
  }, [changeVolume, videoElement]);

  const increaseVolume = useCallback(() => {
    if (!videoElement) return;
    changeVolume({
      saveVolume: true,
      vol: Math.min(Math.max(videoElement.volume + 0.05, 0.05), 1),
    });
  }, [changeVolume, videoElement]);

  return {
    changeVolume,
    decreaseVolume,
    increaseVolume,
    toggleMute,
  };
};
