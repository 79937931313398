import { AdvertisementBreakTypes } from "../../../../../base";
import { guid } from "../../../../../utils";
import { Advertisement, AdvertisementAuxiliaryData } from "./Advertisement";

/*
  Sometimes the Yospace SDK position values and video position values differ by
  a few ms, eg. video 28.485 vs SDK 28.48500003, this can cause
  invalid behavior in `isPositionInAdvertisementBreak()` and `isPositionPastAdvertisementBreak()`
  To avoid this we add a margin to the position when comparing position to AdvertisementBreak end.

  We also add the margin to `getEndPosition()` as the Yospace SDK values can be inaccurate causing the end position
  to be a bit smaller than the actual end position which can cause problems when converting between timestamps
  with and without ads.
*/
const END_MARGIN = 0.5;

export type TAdvertisementBreakArgs<
  TAdvertisementAuxiliaryData extends AdvertisementAuxiliaryData
> = {
  id?: string;
  name?: string;
  active: boolean;
  advertisements: Array<Advertisement<TAdvertisementAuxiliaryData>>;
  auxiliaryData?: Record<string, any>;
  breakType: AdvertisementBreakTypes;
  correlationId: string;
  durationInSeconds: number;
  embedded: boolean;
  position: number;
  watched: boolean;
};

export class AdvertisementBreak<
  TAdvertisementAuxiliaryData extends AdvertisementAuxiliaryData
> {
  public readonly type = "AdvertisementBreak";

  public id: string;
  public name: string;
  public active: boolean;
  public advertisements: Array<Advertisement<TAdvertisementAuxiliaryData>>;
  public auxiliaryData: Record<string, any>;
  public breakType: AdvertisementBreakTypes;
  public correlationId: string;
  public durationInSeconds: number;
  public embedded: boolean;
  public position: number;
  public watched: boolean;

  constructor(
    advertisementBreak?: TAdvertisementBreakArgs<TAdvertisementAuxiliaryData>
  ) {
    this.id = advertisementBreak?.id ?? guid();
    this.name = advertisementBreak?.name ?? "Unknown";
    this.active = advertisementBreak?.active ?? false;
    this.advertisements = advertisementBreak?.advertisements ?? [];
    this.auxiliaryData = advertisementBreak?.auxiliaryData ?? {};
    this.breakType =
      advertisementBreak?.breakType ?? AdvertisementBreakTypes.MIDROLL; // Defaults to MidRoll as that is the most common type of break
    this.correlationId = advertisementBreak?.correlationId ?? "Unknown";
    this.durationInSeconds = advertisementBreak?.durationInSeconds ?? 0;
    this.embedded = advertisementBreak?.embedded ?? false;
    this.position = advertisementBreak?.position ?? 0;
    this.watched = advertisementBreak?.watched ?? false;
  }

  public getEndPosition(): number {
    return this.position + this.durationInSeconds + END_MARGIN;
  }

  public isPositionInAdvertisementBreak(position: number): boolean {
    return (
      position >= this.position &&
      position + END_MARGIN < this.position + this.durationInSeconds
    );
  }

  public isPositionPastAdvertisementBreak(position: number): boolean {
    return position + END_MARGIN >= this.position + this.durationInSeconds;
  }
}
