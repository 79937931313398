import { TPlaybackConfig } from "@telia-company/tv.web-player-shared";
import { Dispatch, useEffect } from "react";

import { UiEventTypes } from "../constants";
import { TAction } from "./ui-state-reducer.hook";

export type TUseWatchModeOptions = {
  dispatch: Dispatch<TAction>;
  playback: TPlaybackConfig;
};

export const useNewPlaybackConfig = ({
  dispatch,
  playback,
}: TUseWatchModeOptions): void => {
  useEffect(() => {
    dispatch({
      payload: {
        playback,
      },
      type: UiEventTypes.CONTENT_SPEC_UPDATED,
    });
  }, [playback, dispatch]);
};
