import { CdnChangedEvent } from "@telia-company/tv.web-playback-sdk";

export const cdnDetection = (
  onCdnChange: (d: CdnChangedEvent["payload"]) => void
) => {
  let cdn: string | undefined;

  return (cdnName: string) => {
    if (cdnName !== cdn) {
      cdn = cdnName;
      onCdnChange({
        cdn,
      });
    }
  };
};
