import { MediaMetadata, MediaTypes, NextContent } from "../Types";
import { getNpvrInfo, IsEpisode } from "./CommonMappers";

import Media = GraphTypes.Media;
import IPlaybackSpec = GraphTypes.IPlaybackSpec;

export const MediaMetadataMapper = function (response: {
  data: { media: Media };
}): MediaMetadata | null {
  if (!(response && response.data && response.data.media)) {
    throw new Error(
      "MetadataService::ChannelMetadataMapper: Missing data or media!"
    );
  }
  const graphMedia = response.data.media;

  const getNpvrStartTime = (graphMedia: Media) => {
    return graphMedia.playback?.play?.npvr?.[0].npvrInfo.originalAirDate
      .startDate.timestamp;
  };

  const getNpvrEndTime = (graphMedia: Media) => {
    return graphMedia.playback?.play?.npvr?.[0]?.npvrInfo.originalAirDate
      .endDate.timestamp;
  };
  const flattenImages = (images: any) => {
    let flatImages: any = {};
    Object.keys(images)
      .filter((image) => !!images[image])
      .forEach(
        (image) =>
          (flatImages[image] =
            images[image].sourceNonEncoded ||
            decodeURIComponent(images[image].source))
      );
    return flatImages;
  };

  const mediaMetadata: MediaMetadata = {
    type: graphMedia.__typename as string as MediaTypes,
    id: graphMedia.id,
    description: graphMedia.description || "",
    title: graphMedia.title || "unknown",

    startTime:
      graphMedia.playback?.play?.linear?.item?.startTime?.timestamp ||
      getNpvrStartTime(graphMedia),

    endTime:
      graphMedia.playback?.play?.linear?.item?.endTime?.timestamp ||
      getNpvrEndTime(graphMedia),

    startoverPlaybackSpec: graphMedia.playback?.play?.linear?.item?.startover
      ?.playbackSpec
      ? {
          ...graphMedia.playback?.play?.linear?.item?.startover?.playbackSpec,
        }
      : undefined,
    linearPlaybackSpec: graphMedia.playback?.play?.linear?.item?.playbackSpec,
    channelPlaybackSpec:
      graphMedia.playback?.play?.linear?.item?.channel?.playbackSpec,
    images: graphMedia.images ? flattenImages(graphMedia.images) : undefined,
    npvrInfo: getNpvrInfo(graphMedia),
  };

  let nextContentPlaybackSpec: IPlaybackSpec | undefined = undefined;

  if (IsEpisode(graphMedia)) {
    if (graphMedia.series) {
      mediaMetadata.series = {
        ...graphMedia.series,
        episode: graphMedia.episodeNumber.number,
        season: graphMedia.seasonNumber.number,
      };
    }

    nextContentPlaybackSpec =
      graphMedia.nextEpisode?.playback?.play?.subscription?.[0]?.item
        ?.playbackSpec ||
      graphMedia.nextEpisode?.playback?.play?.rental?.[0]?.item?.playbackSpec;
    if (nextContentPlaybackSpec && graphMedia.nextEpisode) {
      mediaMetadata.nextContent = {
        validFrom:
          graphMedia.nextEpisode?.playback?.play?.subscription?.[0]?.item
            ?.validFrom?.timestamp ||
          graphMedia.nextEpisode?.playback?.play?.rental?.[0]?.item?.validFrom
            ?.timestamp,
        validTo:
          graphMedia.nextEpisode?.playback?.play?.subscription?.[0]?.item
            ?.validTo?.timestamp ||
          graphMedia.nextEpisode?.playback?.play?.rental?.[0]?.item?.validTo
            ?.timestamp,
        playbackSpec: nextContentPlaybackSpec,
        metadata: {
          type: graphMedia.nextEpisode.__typename as string as MediaTypes,
          id: graphMedia.nextEpisode.id,
          description: graphMedia.nextEpisode.description || "",
          title: graphMedia.nextEpisode.title || "unknown",
          images: graphMedia.nextEpisode.images
            ? flattenImages(graphMedia.nextEpisode.images)
            : undefined,
        },
        seriesTitle: graphMedia.nextEpisode.series.title,
        episode: graphMedia.nextEpisode.episodeNumber.number,
        season: graphMedia.nextEpisode.seasonNumber.number,
      };
    }
  }

  return mediaMetadata;
};
