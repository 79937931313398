import {
  TContentMetadata,
  TTimeline,
} from "@telia-company/tv.web-player-shared";
import { Dispatch, useEffect } from "react";

import { UiEventTypes } from "../constants";
import { currentTimeWithinTimelineBlock, justBeforeEOS } from "../utils";
import { TAction } from "./ui-state-reducer.hook";

type TUseNoDeadEnds = {
  currentTime: null | number;
  dispatch: Dispatch<TAction>;
  duration: null | number;
  metadata: TContentMetadata;
  minimizeRequested: boolean;
  timeline: TTimeline;
};

let wasOutsideClosingCredits = false;
let didEOStrigger = false;

/*
 * Will make the player request to be minimized when credits roll
 * and there are no subsequent episodes to play.
 * The client can then minimize the player and show end screen recommendations
 */

export const useNoDeadEnds = ({
  currentTime,
  dispatch,
  duration,
  metadata,
  minimizeRequested,
  timeline,
}: TUseNoDeadEnds) => {
  useEffect(() => {
    if (metadata.nextEpisode) return;

    // when reaching EOS, minimize the player and show end screen recommendations
    if (duration && currentTime && timeline?.closingCredits) {
      const isJustBeforeEOS = justBeforeEOS({ currentTime, duration });
      const inClosingCredits = currentTimeWithinTimelineBlock(
        timeline.closingCredits,
        currentTime
      );
      if (isJustBeforeEOS && !minimizeRequested && !didEOStrigger) {
        dispatch({
          payload: {
            minimizeRequested: true,
          },
          type: UiEventTypes.MINIMIZE_PLAYER_REQUESTED,
        });
        didEOStrigger = true;
      } else if (
        inClosingCredits &&
        wasOutsideClosingCredits &&
        !minimizeRequested
      ) {
        dispatch({
          payload: {
            minimizeRequested: true,
          },
          type: UiEventTypes.MINIMIZE_PLAYER_REQUESTED,
        });
        wasOutsideClosingCredits = false;
      } else if (!inClosingCredits) {
        wasOutsideClosingCredits = true;
        if (minimizeRequested) {
          dispatch({
            payload: {
              minimizeRequested: false,
            },
            type: UiEventTypes.MINIMIZE_PLAYER_REQUESTED,
          });
        }
      }
    }
  }, [
    currentTime,
    dispatch,
    duration,
    metadata.nextEpisode,
    minimizeRequested,
    timeline,
  ]);
};
