import Log, { LogLevel } from '../../common/log';
export var LogColors;
(function (LogColors) {
    LogColors["BLACK"] = "\u001B[30m";
    LogColors["RED"] = "\u001B[31m";
    LogColors["GREEN"] = "\u001B[32m";
    LogColors["YELLOW"] = "\u001B[33m";
    LogColors["BLUE"] = "\u001B[34m";
    LogColors["MAGENTA"] = "\u001B[35m";
    LogColors["CYAN"] = "\u001B[36m";
    LogColors["WHITE"] = "\u001B[37m";
})(LogColors || (LogColors = {}));
;
export default class DiagnosticLogs {
    static colorizeText(msg, color) {
        return `${color}${msg}${this.RESET}`;
    }
    static header(msg) {
        if (Log.getLogLevel() < LogLevel.SILENT) {
            console.log(this.BOLD + msg + this.RESET);
        }
    }
    static log(msg, options) {
        if (Log.getLogLevel() < LogLevel.SILENT) {
            console.log(msg, options || '');
        }
    }
    static logWithPrefix(msg, prefix, numberOfRepeats = 1) {
        if (Log.getLogLevel() < LogLevel.SILENT) {
            const composedMessage = prefix.repeat(numberOfRepeats) + msg;
            console.log(composedMessage);
        }
    }
}
DiagnosticLogs.logIdent = '  ';
DiagnosticLogs.BOLD = `\x1b[1m`;
DiagnosticLogs.RESET = `\x1b[0m`;
