var Emitter = require('../../../emitter')
var YBRequest = require('../../request')
const { AnalyticsTag } = require('../../../../common/Constants');
const { default: Log } = require('../../../../common/log');

var CdnSwitch = Emitter.extend({
  constructor: function (plugin) {
    this.plugin = plugin
    this.headerName = 'x-cdn'
  },

  init: function () {
    var url = null
    if (this.plugin) {
      if (this.plugin.getAdapter() && this.plugin.getAdapter().getURLToParse()) {
        url = this.plugin.getAdapter().getURLToParse()
      } else {
        url = this.plugin.getResource()
      }
    }
    if (this.plugin && this.plugin.getAdapter() && this.plugin.getAdapter().flags.isStarted) {
      this._request(url)
    }
  },

  done: function (data) {
    this.emit(CdnSwitch.Events.DONE, data)
  },

  error: function () {
    this.emit(CdnSwitch.Events.ERROR)
  },

  _successfulRequest: function (resp) {
    setTimeout(this.init.bind(this), parseInt(this.plugin.options['parse.cdnTTL']) * 1000)
    var headers = resp.getResponseHeaders().split('\n')
    var cdn = null
    for (var i in headers) {
      var line = headers[i]
      var index = line.indexOf(this.headerName)
      if (index > -1) {
        cdn = line.substring(index + this.headerName.length + 1, line.length - 1).replace(' ', '')
      }
    }
    this.done(cdn)
  },

  _failedRequest: function () {
    setTimeout(this.init.bind(this), parseInt(this.plugin.options['parse.cdnTTL']) * 1000)
    Log.warn(AnalyticsTag, 'CDN switch detection request failed')
    this.error()
  },

  _request: function (url) {
    var request = new YBRequest(url, null, null, { method: 'HEAD', cache: true })
    request.on(YBRequest.Event.SUCCESS, this._successfulRequest.bind(this))
    request.on(YBRequest.Event.ERROR, this._failedRequest.bind(this))
    request.send()
  }

}, {
  Events: {
    DONE: 'done',
    ERROR: 'error'
  }
})

module.exports = CdnSwitch
