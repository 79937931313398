/* global localStorage, sessionStorage, location */
var NpawObject = require('../object')
const { default: Log } = require('../../common/log')
const { AnalyticsTag } = require('../../common/Constants');

/**
 * This class manages data sotrage in the browser memory.
 *
 * @extends npaw.Emitter
 * @memberof npaw
 */
var NpawStorage = NpawObject.extend(
  /** @lends npaw.NpawStorage.prototype */
  {
    /**
     *
     * @constructs NpawStorage
     * @extends NpawObject
     * @memberof npaw
     *
     * @param {String} [prefix] Optional. Sets the prefix for saved objects in storages or cookies. 'npaw' by default.
     * @param {Boolean} [disableCookies] Optional. Set to true to disable cookies fallback. True by default.
     */
    constructor: function (prefix, disableCookies, forceCookies, disable) {
      this.prefix = prefix || 'npaw'
      this.disableCookies = disableCookies
      this.forceCookies = forceCookies
      this.disabled = disable
    },

    /**
     *
     * @param disableCookies
     * @param forceCookies
     * @param disable
     */
    updateStorageOptions: function (disableCookies, forceCookies, disable) {
      this.disableCookies = disableCookies
      this.forceCookies = forceCookies
      this.disabled = disable
    },

    /**
     * Returns if storages are available or not
     *
     */
    isEnabled: function () {
      if (this.disabled) return false
      var ret = true
      if (!this.forceCookies) {
        try {
          localStorage.setItem(this.prefix + '.' + 'test', 'true')
          localStorage.removeItem(this.prefix + '.' + 'test')
        } catch (err) {
          ret = false
        }
      }
      return ret
    },

    /**
     * Saves in localStorage or equivalent
     *
     * @param {string} key Key of the value. Prefix will be appended.
     * @param {string} value Value.
     */
    setLocal: function (key, value) {
      if (this.disabled) return null
      var ret = null
      try {
        if (this.forceCookies || ((typeof localStorage === 'undefined' || !localStorage) && !this.disableCookies)) {
          ret = this._setCookie(this.prefix + '.local.' + key, value)
        } else if (typeof localStorage !== 'undefined' && localStorage) {
          ret = localStorage.setItem(this.prefix + '.' + key, value)
        }
      } catch (err) {
        Log.error(AnalyticsTag, 'Npaw App Analytics needs localStorage or cookies, not supported by your browser.')
      }
      return ret
    },

    /**
     * Reads from localStorage or equivalent
     *
     * @param {string} key Key of the value. prefix will be appended.
     */
    getLocal: function (key) {
      if (this.disabled) return null
      return this._localGetRemove('getItem', '_getCookie', key)
    },

    /**
     * Removes from localStorage or equivalent
     *
     * @param {string} key Key of the value. prefix will be appended.
     */
    removeLocal: function (key) {
      if (this.disabled) return null
      return this._localGetRemove('removeItem', '_removeCookie', key)
    },

    _localGetRemove: function (storageMethod, cookieMethod, key) {
      var ret = null
      try {
        if (this.forceCookies || ((typeof localStorage === 'undefined' || !localStorage) && !this.disableCookies)) {
          ret = this[cookieMethod](this.prefix + '.local.' + key)
        } else if (typeof localStorage !== 'undefined' && localStorage) {
          ret = localStorage[storageMethod](this.prefix + '.' + key)
        }
      } catch (err) {
        Log.error(AnalyticsTag, 'Npaw App Analytics needs localStorage or cookies, not supported by your browser.')
      }
      return ret
    },

    /**
     * Saves in sessionStorage or equivalent
     *
     * @param {string} key Key of the value. prefix will be appended.
     * @param {string} value Value.
     */
    setSession: function (key, value) {
      if (this.disabled) return null
      var ret = null
      try {
        if (this.forceCookies || ((typeof sessionStorage === 'undefined' || !sessionStorage) && !this.disableCookies)) {
          ret = this._setCookie(this.prefix + '.session.' + key, value)
        } else {
          ret = sessionStorage.setItem(this.prefix + '.' + key, value)
        }
      } catch (err) {
        Log.error(AnalyticsTag, 'Npaw App Analytics needs sessionStorage or cookies, not supported by your browser.')
      }
      return ret
    },

    /**
     * Reads from sessionStorage or equivalent
     *
     * @param {string} key Key of the value. prefix will be appended.
     */
    getSession: function (key) {
      if (this.disabled) return null
      return this._sessionGetRemove('getItem', '_getCookie', key)
    },

    /**
   * Removes from sessionStorage or equivalent
   *
   * @param {string} key Key of the value. prefix will be appended.
   */
    removeSession: function (key) {
      if (this.disabled) return null
      return this._sessionGetRemove('removeItem', '_removeCookie', key)
    },

    _sessionGetRemove: function (storageMethod, cookieMethod, key) {
      var ret = null
      try {
        if (this.forceCookies || ((typeof sessionStorage === 'undefined' || !sessionStorage) && !this.disableCookies)) {
          ret = this[cookieMethod](this.prefix + '.session.' + key)
        } else {
          ret = sessionStorage[storageMethod](this.prefix + '.' + key)
        }
      } catch (err) {
        Log.error(AnalyticsTag, 'Npaw App Analytics needs sessionStorage or cookies, not supported by your browser.')
      }
      return ret
    },

    /**
     * Calls getSession and getLocal for the same key
     * @param {string} key Key of the value. prefix will be appended.
     */
    getStorages: function (key) {
      if (this.disabled) return null
      return this.getSession(key) || this.getLocal(key);
    },

    /**
     * Calls getSession and getLocal with the same key and value
     * @param {string} key Key of the value. prefix will be appended.
     * @param {string} value Value.
     */
    setStorages: function (key, value) {
      if (this.disabled) return null
      this.setSession(key, value)
      this.setLocal(key, value)
    },

    /**
     * Calls getSession and getLocal for the same key
     * @param {string} key Key of the value. prefix will be appended.
     */
    removeStorages: function (key) {
      if (this.disabled) return null
      this.removeSession(key)
      this.removeLocal(key)
    },

    // Private cookies methods

    /**
   * Sets a cookie value
   *
   * @param {string} cname Key of the value.
   * @param {Object} cvalue Value.
   */
    _setCookie: function (cname, cvalue) {
      if (typeof document !== 'undefined') {
        var domain = ';'
        if (typeof location !== 'undefined') {
          domain += 'domain=' + location.host.split('.').reverse().splice(0, 2).reverse().join('.') + ';path=/;'
        }
        document.cookie = cname + '=' + cvalue + domain
      }
    },

    /**
   * Gets a cookie value
   *
   * @param {string} cname Key of the value.
   */
    _getCookie: function (cname) {
      if (typeof document !== 'undefined') {
        var name = cname + '='
        var decodedCookie = decodeURIComponent(document.cookie)
        var ca = decodedCookie.split(';')
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i]
          while (c.charAt(0) === ' ') {
            c = c.substring(1)
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
          }
        }
      }
      return null
    },

    /**
   * Removes a cookie
   *
   * @param {string} cname Key of the value.
   */
    _removeCookie: function (cname) {
      this._setCookie(cname, '')
    }
  }
)

module.exports = NpawStorage
