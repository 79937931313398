import { FC, useEffect, useState } from "react";

import { separator } from "../constants";
import { getHHMMFromEpoch } from "../utils";
import {
  useCustomStyles,
  useIcon,
  usePlayerState,
  useSmallLayout,
  useTranslations,
  useUiCallbacks,
} from "./context-provider";
import { MetadataToast } from "./metadata";

export type TSeasonAndEpisodeProps = {
  episode: number | undefined;
  episodeText: string;
  season: number | undefined;
  seasonText: string;
};

const seasonAndEpisode = ({
  episode,
  episodeText,
  season,
  seasonText,
}: TSeasonAndEpisodeProps): string => {
  // special handling for season 0 and season 2020
  // do not show any metadata in this case
  if (!season || season === 0 || season > 1000) return "";

  return `${seasonText} ${season} ${separator} ${episodeText} ${episode}`;
};

export const MetadataContainer: FC = () => {
  const {
    metadata: { endTime, episode, logo, npvrInfo, season, startTime, title },
    playbackType,
  } = usePlayerState();
  const {
    episode: episodeText,
    recordingOngoing,
    season: seasonText,
  } = useTranslations();
  const smallLayout = useSmallLayout();
  const { IconRecordingFill, IconRecordingSeriesFill } = useIcon();
  const { recordingColor } = useCustomStyles();
  const { backButtonCallback } = useUiCallbacks();

  const [brokenImage, setBrokenImage] = useState(false);
  const [subtitle, setSubtitle] = useState("");

  const isRecording = !!(
    npvrInfo?.media.recordedPlaybackSpec &&
    endTime &&
    Date.now() < endTime
  );
  const isSeriesRecording = !!npvrInfo?.media.seriesRecording;

  useEffect(() => {
    const subtitleWithSeasonAndEpisode = seasonAndEpisode({
      episode,
      episodeText,
      season,
      seasonText,
    });
    if (subtitleWithSeasonAndEpisode) {
      setSubtitle(subtitleWithSeasonAndEpisode);
    } else if (
      playbackType &&
      playbackType === "linear-channel" &&
      startTime &&
      endTime
    ) {
      setSubtitle(
        `${getHHMMFromEpoch(startTime)}-${getHHMMFromEpoch(endTime)}`
      );
    }
  }, [
    season,
    episode,
    seasonText,
    episodeText,
    startTime,
    endTime,
    playbackType,
  ]);
  const onBrokenImage = () => {
    setBrokenImage(true);
  };

  if (smallLayout) return null;

  return (
    <MetadataToast
      backButtonVisible={!!backButtonCallback}
      brokenImage={brokenImage}
      isRecording={isRecording}
      isSeriesRecording={isSeriesRecording}
      logo={logo || ""}
      onBrokenImage={onBrokenImage}
      recordingColor={recordingColor}
      RecordingIcon={IconRecordingFill}
      RecordingIconSeries={IconRecordingSeriesFill}
      recordingText={recordingOngoing}
      subtitle={subtitle}
      title={title}
    />
  );
};
