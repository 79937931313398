import { TLanguageCode } from "../shared-constants";
import { TUserConfig } from "../shared-types";
import {
  getFromSessionStorage,
  SessionStorageKey,
  setInSessionStorage,
} from "../storage";

export const calculateLanguagePreferences = (user: TUserConfig | undefined) => {
  // set initial languages and subtitle visibility, based on sessionstorage and user preferences
  const preferredAudioLang = user?.preferredAudioLanguage || "sv";
  const fallbackAudioLang = user?.fallbackAudioLanguage || "en";

  // handle the legacy "off" language
  const preferredSubtitleLanguage =
    user?.preferredSubtitleLanguage === "off"
      ? undefined
      : user?.preferredSubtitleLanguage;

  const sessionAudioLang = getFromSessionStorage(SessionStorageKey.AUDIO_LANG);
  const sessionTextLang = getFromSessionStorage(
    SessionStorageKey.SUBTITLE_LANG
  );
  const sessionSubtitlesVisible = getFromSessionStorage(
    SessionStorageKey.SUBTITLE_VISIBLE
  );

  // Check if incoming client config indicates that we should enable subtitles
  const shouldTurnOnSubtitles = !!preferredSubtitleLanguage;
  // Prefer session data over client config
  const textLang: keyof TLanguageCode =
    sessionTextLang || preferredSubtitleLanguage;
  const audioLang: keyof TLanguageCode = sessionAudioLang || preferredAudioLang;
  const subVisibility: boolean =
    typeof sessionSubtitlesVisible === "boolean"
      ? sessionSubtitlesVisible
      : shouldTurnOnSubtitles;

  // Check if this is the first time we're playing something this session
  // and save the client config as the current session data if that is the case.
  // Session storage data is overridden if the user changes settings in the player.
  if (!sessionTextLang && preferredSubtitleLanguage)
    setInSessionStorage(
      SessionStorageKey.SUBTITLE_LANG,
      preferredSubtitleLanguage
    );

  if (!sessionAudioLang && preferredAudioLang)
    setInSessionStorage(SessionStorageKey.AUDIO_LANG, preferredAudioLang);

  if (typeof sessionSubtitlesVisible !== "boolean") {
    setInSessionStorage(
      SessionStorageKey.SUBTITLE_VISIBLE,
      shouldTurnOnSubtitles
    );
  }

  return {
    audioLang,
    fallbackAudioLang,
    subVisibility,
    textLang,
  };
};
