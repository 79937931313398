import {
  AdvertisementBreakInfo,
  AdvertisementBreakTypes,
  AdvertisementInfo,
} from "../../../../base";
import { Advertisement, AdvertisementBreak } from "./dtos";
import {
  IYospacePlaybackFeatureAdvertisementAuxiliaryData,
  IYospacePlaybackFeatureConfiguration,
  TYospaceSeekPositionData,
} from "./Types";
import { Id3Cue } from "../../../Types";
import { Yospace } from "./Yospace";
import {
  createYospaceUrl,
  getVastAdExtensionData,
  mapYospaceError,
} from "./utils";

export class YospacePlaybackFeature {
  private readonly configuration: IYospacePlaybackFeatureConfiguration;

  private yospace: Yospace<IYospacePlaybackFeatureAdvertisementAuxiliaryData>;

  constructor(configuration: IYospacePlaybackFeatureConfiguration) {
    this.configuration = configuration;

    const callbacks = this.configuration.callbacks;
    this.yospace =
      new Yospace<IYospacePlaybackFeatureAdvertisementAuxiliaryData>({
        ...this.configuration,
        allowDelayedMetadataDelivery:
          this.configuration.allowDelayedMetadataDelivery ?? true,
        // Starting in a replaced break in live does not work perfectly with the way we calculate emsg timestamps
        allowStartInReplacedLiveBreak: false,
        allowStartInReplacedOnDemandBreak:
          this.configuration.allowStartInReplacedOnDemandBreak ?? true,
        contentUrl: createYospaceUrl(
          this.configuration.contentUrl,
          this.configuration
        ),
        callbacks: {
          advertisementBreakEnd: (
            advertisementBreak?: AdvertisementBreak<IYospacePlaybackFeatureAdvertisementAuxiliaryData>
          ) =>
            callbacks.advertisementBreakEnd(
              advertisementBreak &&
                this.mapAdvertisementBreak(advertisementBreak)
            ),
          advertisementBreakStart: (
            advertisementBreak?: AdvertisementBreak<IYospacePlaybackFeatureAdvertisementAuxiliaryData>
          ) =>
            callbacks.advertisementBreakStart(
              advertisementBreak &&
                this.mapAdvertisementBreak(advertisementBreak)
            ),
          advertisementBreaksUpdated: (
            newAdvertisementBreaks: Array<
              AdvertisementBreak<IYospacePlaybackFeatureAdvertisementAuxiliaryData>
            >
          ) =>
            callbacks.advertisementBreaksUpdated?.(
              newAdvertisementBreaks.map((advertisementBreak) =>
                this.mapAdvertisementBreak(advertisementBreak)
              )
            ),
          advertisementEnd: (
            advertisement?: Advertisement<IYospacePlaybackFeatureAdvertisementAuxiliaryData>
          ) => callbacks.advertisementEnd(),
          advertisementStart: (
            advertisement?: Advertisement<IYospacePlaybackFeatureAdvertisementAuxiliaryData>
          ) =>
            callbacks.advertisementStart(
              advertisement && this.mapAdvertisement(advertisement)
            ),
        },
        liveAdvertisementBreakHistoryLimit:
          this.configuration.liveAdvertisementBreakHistoryLimit ?? 20,
        parseExtensions: (extensions) => getVastAdExtensionData(extensions),
      });
  }

  private getAdvertisementBreakForAbsolutePosition(
    absolutePosition: number
  ): AdvertisementBreakInfo | undefined {
    let pastAdvertisementBreak: AdvertisementBreakInfo | undefined;

    this.advertisementBreaks.forEach((advertisementBreak) => {
      if (
        advertisementBreak.isPositionPastAdvertisementBreak(absolutePosition) ||
        advertisementBreak.isPositionInAdvertisementBreak(absolutePosition)
      ) {
        pastAdvertisementBreak = advertisementBreak;
      }
    });

    return pastAdvertisementBreak;
  }

  private mapAdvertisement(
    advertisement: Advertisement<IYospacePlaybackFeatureAdvertisementAuxiliaryData>
  ): AdvertisementInfo {
    const advertisementBreak = this.yospace.getAdvertisementBreak();
    return new AdvertisementInfo({
      id: advertisement.correlationId,
      name: advertisement.name,
      durationInSeconds: advertisement.durationInSeconds,
      positionInAdBreak: advertisement.indexInAdvertisementBreak,
      totalAdsInAdBreak: advertisementBreak?.advertisements.length,
      breakType:
        advertisementBreak?.breakType ?? AdvertisementBreakTypes.MIDROLL,
      ...advertisement?.auxiliaryData,
    });
  }

  private mapAdvertisementBreak(
    advertisementBreak: AdvertisementBreak<IYospacePlaybackFeatureAdvertisementAuxiliaryData>
  ): AdvertisementBreakInfo {
    return new AdvertisementBreakInfo({
      id: advertisementBreak.id,
      name: advertisementBreak.name,
      active: advertisementBreak.active,
      advertisements: advertisementBreak.advertisements.map<AdvertisementInfo>(
        (advertisement) => this.mapAdvertisement(advertisement)
      ),
      breakType: advertisementBreak.breakType,
      durationInSeconds: advertisementBreak.durationInSeconds,
      embedded: advertisementBreak.embedded,
      position: advertisementBreak.position,
      watched: advertisementBreak.watched,
    });
  }

  public get advertisementBreaks(): Array<AdvertisementBreakInfo> {
    // Mapping for compatibility
    return this.yospace
      .getAdvertisementBreaks()
      .map((advertisementBreak) =>
        this.mapAdvertisementBreak(advertisementBreak)
      );
  }

  public get contentDuration(): number {
    return this.yospace.getContentDuration();
  }

  public get inAdBreak(): boolean {
    return !!this.yospace.getAdvertisementBreak();
  }

  public async initialize(): Promise<string> {
    try {
      return await this.yospace.initialize();
    } catch (e) {
      // Map Yospace error to playback sdk error
      const mapped = mapYospaceError(e, this.configuration);
      if (mapped) throw mapped;

      throw e;
    }
  }

  public getCurrentAdvertisementClickThroughUrl(): string | undefined {
    return this.yospace.getAdvertisementClickThroughUrl();
  }

  public getCurrentAdvertisementDuration(): number {
    return this.yospace.getAdvertisement()?.durationInSeconds ?? 0;
  }

  public getCurrentAdvertisementTime(): number {
    return this.yospace.getAdvertisementTime();
  }

  public getPositionWithAds(
    positionWithoutAds: /* contentPosition */ number
  ): number {
    return this.yospace.getAbsolutePosition(positionWithoutAds);
  }

  public getPositionWithoutAds(
    positionWithAds: /* absolutePosition */ number
  ): number {
    return this.yospace.getContentPosition(positionWithAds);
  }

  public getSeekPosition(
    positionWithoutAds: /* contentPosition */ number
  ): TYospaceSeekPositionData {
    const absolutePosition =
      this.yospace.getAbsolutePosition(positionWithoutAds);

    const advertisementBreak =
      this.getAdvertisementBreakForAbsolutePosition(absolutePosition);

    if (advertisementBreak && !advertisementBreak.watched) {
      return {
        seekPosition: advertisementBreak.position,
        seekPositionAfterBreakEnd: advertisementBreak.getEndPosition(),
      };
    }

    return {
      seekPosition: absolutePosition,
    };
  }

  /** Tracking */

  public advertisementClicked(): void {
    this.yospace.advertisementClicked();
  }

  public id3Cue(id3Cue: Id3Cue) {
    this.yospace.id3Cue(id3Cue);
  }

  /** Matching ITracker */

  public buffered(): void {
    this.yospace.buffered();
  }

  public buffering(): void {
    this.yospace.buffering();
  }

  public loaded(): void {
    this.yospace.loaded();
  }

  public paused(): void {
    this.yospace.paused();
  }

  public playing(): void {
    this.yospace.playing();
  }

  public stopped(): void {
    this.yospace.stopped();
  }

  public timeUpdate(positionWithAds: number): void {
    this.yospace.timeUpdate(positionWithAds);
  }

  /** /Matching ITracker */

  public async reset(): Promise<void> {
    await this.yospace.reset();
  }

  public async destroy(): Promise<void> {
    await this.reset();
    await this.yospace.destroy();
  }
}
