import { CoreTag } from '../../common/Constants';
import Log from '../../common/log';
/**
 * This class manages data storage in the browser memory.
 *
 */
export default class BrowserStorage {
    /**
     *
     * @constructs BrowserStorage
     *
     * @param {string} [prefix] Optional. Sets the prefix for saved objects in storages or cookies. 'npaw' by default.
     * @param {boolean} [disableCookies] Optional. Set to true to disable cookies fallback. True by default.
     * @param {boolean} [forceCookies] Optional. Set to true to disable cookies fallback. True by default.
     * @param {boolean} [disabled] Optional. Set to true to disable cookies fallback. True by default.
     */
    constructor(disableCookies, forceCookies, disabled) {
        this.prefix = 'npaw';
        this.disableCookies = disableCookies;
        this.forceCookies = forceCookies;
        this.disabled = disabled;
    }
    /**
     *
     * @param disableCookies
     * @param forceCookies
     * @param disable
     */
    updateStorageOptions(disableCookies, forceCookies, disable) {
        this.disableCookies = disableCookies;
        this.forceCookies = forceCookies;
        this.disabled = disable;
    }
    /**
     * Returns if storages are available or not
     *
     */
    isEnabled() {
        if (this.disabled)
            return false;
        let ret = true;
        if (!this.forceCookies) {
            try {
                localStorage.setItem(this.prefix + '.' + 'test', 'true');
                localStorage.removeItem(this.prefix + '.' + 'test');
            }
            catch (err) {
                ret = false;
            }
        }
        return ret;
    }
    /**
     * Saves in localStorage or equivalent
     *
     * @param {string} key Key of the value. Prefix will be appended.
     * @param {string} value Value.
     */
    setLocal(key, value) {
        if (this.disabled)
            return;
        try {
            if (this.forceCookies || ((typeof localStorage === 'undefined' || !localStorage) && !this.disableCookies)) {
                this._setCookie(this.prefix + '.local.' + key, value);
            }
            else if (typeof localStorage !== 'undefined' && localStorage) {
                localStorage.setItem(this.prefix + '.' + key, value);
            }
        }
        catch (err) {
            Log.debug(CoreTag, 'App Analytics needs localStorage or cookies, not supported by your browser.');
        }
    }
    /**
     * Reads from localStorage or equivalent
     *
     * @param {string} key Key of the value. prefix will be appended.
     */
    getLocal(key) {
        if (this.disabled)
            return;
        return this._localGetRemove('getItem', '_getCookie', key);
    }
    /**
     * Removes from localStorage or equivalent
     *
     * @param {string} key Key of the value. prefix will be appended.
     */
    removeLocal(key) {
        if (this.disabled)
            return;
        return this._localGetRemove('removeItem', '_removeCookie', key);
    }
    _localGetRemove(storageMethod, cookieMethod, key) {
        try {
            if (this.forceCookies || ((typeof localStorage === 'undefined' || !localStorage) && !this.disableCookies)) {
                return this[cookieMethod](this.prefix + '.local.' + key);
            }
            else if (typeof localStorage !== 'undefined' && localStorage) {
                return localStorage[storageMethod](this.prefix + '.' + key);
            }
        }
        catch (err) {
            Log.debug(CoreTag, 'App Analytics needs localStorage or cookies, not supported by your browser.');
        }
    }
    /**
     * Saves in sessionStorage or equivalent
     *
     * @param {string} key Key of the value. prefix will be appended.
     * @param {string} value Value.
     */
    setSession(key, value) {
        if (this.disabled)
            return;
        try {
            if (this.forceCookies || ((typeof sessionStorage === 'undefined' || !sessionStorage) && !this.disableCookies)) {
                this._setCookie(this.prefix + '.session.' + key, value);
            }
            else if (typeof sessionStorage !== 'undefined' && sessionStorage) {
                sessionStorage.setItem(this.prefix + '.' + key, value);
            }
        }
        catch (err) {
            Log.debug(CoreTag, 'App Analytics needs sessionStorage or cookies, not supported by your browser.');
        }
    }
    /**
     * Reads from sessionStorage or equivalent
     *
     * @param {string} key Key of the value. prefix will be appended.
     */
    getSession(key) {
        if (this.disabled)
            return;
        return this._sessionGetRemove('getItem', '_getCookie', key);
    }
    /**
     * Removes from sessionStorage or equivalent
     *
     * @param {string} key Key of the value. prefix will be appended.
     */
    removeSession(key) {
        if (this.disabled)
            return;
        return this._sessionGetRemove('removeItem', '_removeCookie', key);
    }
    _sessionGetRemove(storageMethod, cookieMethod, key) {
        try {
            if (this.forceCookies || ((typeof sessionStorage === 'undefined' || !sessionStorage) && !this.disableCookies)) {
                return this[cookieMethod](this.prefix + '.session.' + key);
            }
            else if (typeof sessionStorage !== 'undefined' && sessionStorage) {
                return sessionStorage[storageMethod](this.prefix + '.' + key);
            }
        }
        catch (err) {
            Log.debug(CoreTag, 'App Analytics needs sessionStorage or cookies, not supported by your browser.');
        }
    }
    /**
     * Calls getSession and getLocal for the same key
     * @param {string} key Key of the value. prefix will be appended.
     */
    getStorages(key) {
        if (this.disabled)
            return;
        return this.getSession(key) || this.getLocal(key);
    }
    /**
     * Calls getSession and getLocal with the same key and value
     * @param {string} key Key of the value. prefix will be appended.
     * @param {string} value Value.
     */
    setStorages(key, value) {
        if (this.disabled)
            return;
        this.setSession(key, value);
        this.setLocal(key, value);
    }
    /**
     * Calls getSession and getLocal for the same key
     * @param {string} key Key of the value. prefix will be appended.
     */
    removeStorages(key) {
        if (this.disabled)
            return;
        this.removeSession(key);
        this.removeLocal(key);
    }
    // Private cookies methods
    /**
     * Sets a cookie value
     *
     * @param {string} cname Key of the value.
     * @param {Object} cvalue Value.
     */
    _setCookie(cname, cvalue) {
        if (typeof document !== 'undefined') {
            let domain = ';';
            if (typeof location !== 'undefined') {
                domain += 'domain=' + location.host.split('.').reverse().splice(0, 2).reverse().join('.') + ';path=/;';
            }
            document.cookie = cname + '=' + cvalue + domain;
        }
    }
    /**
     * Gets a cookie value
     *
     * @param {string} cname Key of the value.
     */
    _getCookie(cname) {
        if (typeof document !== 'undefined') {
            let name = cname + '=';
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
        }
        return null;
    }
    /**
     * Removes a cookie
     *
     * @param {string} cname Key of the value.
     */
    _removeCookie(cname) {
        this._setCookie(cname, '');
    }
}
