var Adapter = require('adapter')
var NpawVideo = require('../video/video')
const { default: DiagnosticTool } = require('../../diagnostic/DiagnosticTool');

var PluginAdsMixin = {
  /**
   *
   * @param {object|string} player Either an instance of the player or a string containing an ID.
   * @param {string} adapterCode
   * @param {object} options
   * @param {string} videoKey
   */
  registerAdsAdapter: function (player, adapterCode, options, videoKey) {
    if (!adapterCode) {
      Log.error(AnalyticsTag, 'Register Ads Adapter needs an adapter to initialize');
      return;
    }
    videoKey = videoKey || 'default'
    if (!this.videos.existsVideo(videoKey)) {
      // First, create new empty video object
      var videoObj = new NpawVideo(videoKey, this, this.options, null, null)
      videoObj.mergeOptions(options)
      this.videos.setVideo(videoObj)
      // Then, create adapter, and set adapter
      var adapter = new Adapter(videoKey, videoObj, this, this.adapterTemplates, player, adapterCode)
      this.setAdsAdapter(adapter, videoKey)
    } else {
      this.videos.getVideo(videoKey).mergeOptions(options)
      this.setAdsAdapter(new Adapter(videoKey, this.videos.getVideo(videoKey), this, this.adapterTemplates, player, adapterCode), videoKey)
    }
  },

  /**
   * Update the AdsAdapter by name, that is previously loaded internally with the registerAdapter method
   *
   * @param {string} adsAdapterName
   * @param {string} videoKey
   */
  updateAdsAdapter: function(adsAdapterName, videoKey) {
    videoKey = videoKey || 'default'
    if (adsAdapterName) {
      videoObj = this.videos.getVideo(videoKey)
      if (videoObj && videoObj.getAdapter()) {
        adsAdapter = videoObj.getAdapter().getAdapterClass(adsAdapterName)
        if (adsAdapter) {
          videoObj.setAdsAdapter(adsAdapter, this, true)
        }
      }
    }
  },

  /**
     * Returns current adapter or null.
     *
     * @param {string} videoKey
     * @returns {Adapter}
     *
     * @memberof npaw.Plugin.prototype
     */
  getAdsAdapter: function (videoKey) {
    videoKey = videoKey || 'default'
    const video = this.videos.getVideo(videoKey);
    return typeof video !== 'undefined' ? video.getAdsAdapter() : undefined;
  },

  /**
     * Sets an adapter for ads.
     *
     * @param {Adapter} adsAdapter
     * @param {string} videoKey
     *
     * @memberof npaw.Plugin.prototype
     */
  setAdsAdapter: function (adsAdapter, videoKey) {
    videoKey = videoKey || 'default'
    this.videos.getVideo(videoKey).setAdsAdapter(adsAdapter, this)
    DiagnosticTool.getInstance().registeredAdsPlayer(true);
  },

  /**
     * Removes the current adapter. Fires stop if needed. Calls adapter.dispose()
     *
     * @param {string} videoKey
     *
     * @memberof npaw.Plugin.prototype
     */
  removeAdsAdapter: function (videoKey) {
    videoKey = videoKey || 'default';
    if (this.videos.existsVideo(videoKey)) {
      this.videos.getVideo(videoKey).removeAdsAdapter();
    }
  }
}

module.exports = PluginAdsMixin
