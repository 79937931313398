export type TGetDotPositionOptions = {
  dragPosition: null | number;
  progressPercent: number;
  seekWindowPercent: null | number;
};

export const getProgressPosition = ({
  dragPosition,
  progressPercent,
  seekWindowPercent,
}: TGetDotPositionOptions): number => {
  if (dragPosition !== null) {
    return seekWindowPercent
      ? dragPosition * seekWindowPercent * 100
      : dragPosition * 100;
  }

  // TODO made sure progressPercent already always is a part of seekWindowPercent?
  return seekWindowPercent
    ? progressPercent * seekWindowPercent * 100
    : progressPercent * 100;
};
