import {
  DrmType,
  ErrorCategories,
  StandardError,
} from "@telia-company/tv.web-playback-sdk";

import { TUnfortunatelyAny } from "../shared-types";

const playReady: MediaKeySystemConfiguration = {
  audioCapabilities: [
    {
      contentType: 'audio/mp4;codecs="mp4a.40.2"',
    },
  ],
  initDataTypes: ["cenc"],
  videoCapabilities: [
    {
      contentType: 'video/mp4;codecs="avc1.42E01E"',
      robustness: "3000",
    },
  ],
};

const widevine: MediaKeySystemConfiguration = {
  audioCapabilities: [
    {
      contentType: 'audio/mp4;codecs="mp4a.40.2"',
    },
  ],
  initDataTypes: ["cenc"],
  videoCapabilities: [
    {
      contentType: 'video/mp4;codecs="avc1.42E01E"',
      robustness: "SW_SECURE_DECODE",
    },
  ],
};

const fairplay: MediaKeySystemConfiguration = {
  audioCapabilities: [
    {
      contentType: 'audio/mp4;codecs="mp4a.40.2"',
    },
  ],
  initDataTypes: ["cenc"],
  videoCapabilities: [
    {
      contentType: 'video/mp4;codecs="avc1.42E01E"',
      robustness: "",
    },
  ],
};

const testKeySystem = ({
  config,
  keysystem,
}: {
  config: Array<TUnfortunatelyAny>;
  keysystem: string;
}): Promise<MediaKeySystemAccess> =>
  navigator.requestMediaKeySystemAccess(keysystem, config);

export type TKeySystem = {
  config: MediaKeySystemConfiguration[];
  keysystem: string;
};

const keySystems: Record<DrmType, TKeySystem> = {
  [DrmType.FAIRPLAY]: { config: [fairplay], keysystem: "com.apple.fps.1_0" },
  [DrmType.PLAYREADY]: {
    config: [playReady],
    keysystem: "com.microsoft.playready",
  },
  [DrmType.WIDEVINE]: { config: [widevine], keysystem: "com.widevine.alpha" },
};

export type TSupportedDrmResult = {
  debug?: Array<StandardError>;
  explicitError?: StandardError;
  type: DrmType;
};

const isMediaKeySupported = async (keySystem: TKeySystem): Promise<void> => {
  const access = await testKeySystem(keySystem);
  await access.createMediaKeys();
};

let SAVED_DRM_RESULT: TSupportedDrmResult;

export const selectBestDrm = async (): Promise<TSupportedDrmResult> => {
  if (SAVED_DRM_RESULT) return SAVED_DRM_RESULT;

  const debug: Array<StandardError> = [];

  if (!navigator.requestMediaKeySystemAccess) {
    SAVED_DRM_RESULT = {
      explicitError: new StandardError({
        category: ErrorCategories.DRM,
        code: "UNSUPPORTED_DEVICE",
        details: {
          description: "Browser does not support MSE.",
        },
        fatal: true,
        message: "Browser does not support MSE.",
      }),
      type: DrmType.NONE,
    };
    return SAVED_DRM_RESULT;
  }

  // Try WIDEVINE before FAIRPLAY becuase it is more common
  try {
    await isMediaKeySupported(keySystems[DrmType.WIDEVINE]);
    SAVED_DRM_RESULT = { type: DrmType.WIDEVINE };
    return SAVED_DRM_RESULT;
  } catch (err) {
    debug.push(
      new StandardError({
        category: ErrorCategories.DRM,
        code: "UNSUPPORTED_DRM",
        details: {
          err,
        },
        fatal: true,
        message: "Widevine unsupported.",
      })
    );
  }

  try {
    await isMediaKeySupported(keySystems[DrmType.FAIRPLAY]);
    SAVED_DRM_RESULT = { type: DrmType.FAIRPLAY };
    return SAVED_DRM_RESULT;
  } catch (err) {
    debug.push(
      new StandardError({
        category: ErrorCategories.DRM,
        code: "UNSUPPORTED_DRM",
        details: {
          err,
        },
        fatal: true,
        message: "Fairplay unsupported.",
      })
    );
  }

  try {
    await isMediaKeySupported(keySystems[DrmType.PLAYREADY]);
    SAVED_DRM_RESULT = { type: DrmType.PLAYREADY };
    return SAVED_DRM_RESULT;
  } catch (err) {
    debug.push(
      new StandardError({
        category: ErrorCategories.DRM,
        code: "UNSUPPORTED_DRM",
        details: {
          err,
        },
        fatal: true,
        message: "Playready unsupported.",
      })
    );
  }

  // Do not cache NONE drm, as the user may
  // change browser settings and try again
  return { debug, type: DrmType.NONE };
};
