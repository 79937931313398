import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import { PlaybackEventTypes } from "../Constants";

export type TCustomTrackingEventPayload = {
  name: string;
};

export class CustomTrackingEvent extends PlaybackEventBase<
  PlaybackEventTypes.CUSTOM_EVENT,
  TCustomTrackingEventPayload
> {
  constructor(payload: TCustomTrackingEventPayload) {
    super(PlaybackEventTypes.CUSTOM_EVENT, payload);
  }
}
