import styled from "@emotion/styled";
import { FC } from "react";

const AdMarker = styled.div`
  width: 0.25em;
  height: 0.25em;
  background: #efe272;
  pointer-events: none;
  position: absolute;
`;

export type TProps = {
  adMarkers: number[];
};

export const AdMarkers: FC<TProps> = ({ adMarkers }) => (
  <>
    {adMarkers.map((percentagePosition) => (
      <AdMarker
        key={percentagePosition}
        style={{
          left: `${percentagePosition * 100}%`,
        }}
      />
    ))}
  </>
);
