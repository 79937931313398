import { CustomTrackingEvent } from "@telia-company/tv.web-playback-sdk";
import {
  TBufferFullnessEvent,
  TEventMethods,
  TIsLiveEvent,
  TPlaybackRestrictionsEvent,
  TSeekRangeUpdateEvent,
  TVideoElementReadyEvent,
  WebPlayerEventType,
} from "@telia-company/tv.web-player-shared";

export type TSetupDynamicPlaybackRate = {
  event: TEventMethods;
};

export const createDynamicPlaybackRateUtility = ({
  event,
}: TSetupDynamicPlaybackRate) => {
  let interval: number | undefined;
  let seekRangeEnd: number | undefined;
  let bufferFullness: number | undefined;
  let videoElement: HTMLVideoElement | undefined;
  let restrictions: TPlaybackRestrictionsEvent["payload"] | undefined;
  let isLive = false;

  const bufferFullnessHandler = (e: TBufferFullnessEvent) => {
    bufferFullness = e.payload.fullnessBetween0and1;
  };
  const seekRangeHandler = (e: TSeekRangeUpdateEvent) => {
    seekRangeEnd = e.payload.seekRange.end;
  };
  const videoElementHandler = (e: TVideoElementReadyEvent) => {
    videoElement = e.payload.videoElement;
  };
  const restrictionsHandler = (e: TPlaybackRestrictionsEvent) => {
    restrictions = e.payload;
  };

  const isLiveHandler = (e: TIsLiveEvent) => {
    isLive = e.payload.isLive;
  };

  const setupDynamicPlaybackRate = () => {
    event.on(WebPlayerEventType.BUFFER_FULLNESS, bufferFullnessHandler);
    event.on(WebPlayerEventType.SEEK_RANGE_UPDATE, seekRangeHandler);
    event.on(WebPlayerEventType.VIDEO_ELEMENT_READY, videoElementHandler);
    event.on(WebPlayerEventType.PLAYBACK_RESTRICTIONS, restrictionsHandler);
    event.on(WebPlayerEventType.IS_LIVE, isLiveHandler);

    const creepToLiveEdge = () => {
      // Only mess with playback rate in live streams, if user is unable to scrub
      if (
        restrictions?.noFastForward &&
        restrictions.noPause &&
        restrictions.noRewind &&
        isLive
      ) {
        if (!videoElement || !bufferFullness || !seekRangeEnd) return;
        // if we're not currently playing, do nothing
        if (videoElement.playbackRate === 0) return;

        const shouldCatchUpToLive =
          seekRangeEnd - videoElement.currentTime < 10;
        const atLiveEdge = seekRangeEnd - videoElement.currentTime < 6;
        const prevPlaybackRate = videoElement.playbackRate;

        // more than 10 seconds from live edge, speed up a bit
        if (shouldCatchUpToLive && !atLiveEdge && prevPlaybackRate !== 1.02) {
          if (bufferFullness > 0.2) {
            videoElement.playbackRate = 1.02;

            event.publish(
              new CustomTrackingEvent({
                name: "PLAYBACK_RATE_INCREASED",
              })
            );
          }

          // close enough to live edge, play normally
        } else if (atLiveEdge && prevPlaybackRate !== 1) {
          videoElement.playbackRate = 1;

          event.publish(
            new CustomTrackingEvent({
              name: "PLAYBACK_RATE_NORMAL",
            })
          );
        }
      }
    };

    interval = window.setInterval(creepToLiveEdge, 10000);
  };

  const teardownDynamicPlaybackRate = () => {
    if (typeof interval === "number") {
      clearInterval(interval);
    }

    event.off(WebPlayerEventType.BUFFER_FULLNESS, bufferFullnessHandler);
    event.off(WebPlayerEventType.SEEK_RANGE_UPDATE, seekRangeHandler);
    event.off(WebPlayerEventType.VIDEO_ELEMENT_READY, videoElementHandler);
    event.off(WebPlayerEventType.PLAYBACK_RESTRICTIONS, restrictionsHandler);
    event.off(WebPlayerEventType.IS_LIVE, isLiveHandler);

    if (!videoElement) return;

    if (videoElement.playbackRate !== 0 && videoElement.playbackRate !== 1) {
      videoElement.playbackRate = 1;

      event.publish(
        new CustomTrackingEvent({
          name: "PLAYBACK_RATE_NORMAL",
        })
      );
    }
  };

  return {
    start: setupDynamicPlaybackRate,
    stop: teardownDynamicPlaybackRate,
  };
};
