import styled from "@emotion/styled";
import { FC } from "react";

import { TPlayerIcon } from "../../@types/icon-type";
import { BaseButton } from "./base";
import { TButtonProps, TextWrapper } from "./button";

export type TButtonWithIconProps = {
  background?: string;
  Icon?: TPlayerIcon;
  iconBeforeText?: boolean;
} & TButtonProps;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div<{ iconBeforeText?: boolean }>`
  height: 1em;
  width: 1em;
  ${({ iconBeforeText }) =>
    iconBeforeText ? "margin-right: 0.5em;" : "margin-left: 0.5em;"}
`;

export const ButtonWithIcon: FC<TButtonWithIconProps> = ({
  background,
  hotkey,
  Icon,
  iconBeforeText,
  onClick,
  text,
  tooltip,
  tooltipPosition,
}) => (
  <BaseButton
    aria-label={`${tooltip}${hotkey && ` (${hotkey.toUpperCase()})`}`}
    background={background}
    data-microtip-position={tooltipPosition || false}
    data-tooltip={!!tooltip}
    onClick={onClick}
  >
    <FlexContainer>
      {!!Icon && iconBeforeText && (
        <IconWrapper iconBeforeText={iconBeforeText}>
          <Icon />
        </IconWrapper>
      )}
      <TextWrapper>{text}</TextWrapper>
      {!!Icon && !iconBeforeText && (
        <IconWrapper iconBeforeText={iconBeforeText}>
          <Icon />
        </IconWrapper>
      )}
    </FlexContainer>
  </BaseButton>
);
