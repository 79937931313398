var Emitter = require('../emitter');
var Constants = require('../constants');
var AnalyticsUtil = require('../util');
const { default: Log } = require('../../common/log');

var VideosRepository = require('../video/videos');
const { default: AnalyticsRequestHandler } = require('../comm/VideoAnalyticsRequestHandler');

var Options = require('./options');
var NpawStorage = require('./storage');
var OfflineStorage = require('./offlineStorage');
var RequestBuilder = require('./requestbuilder');

var ResourceTransform = require('../comm/transform/resource');

var BrowserLoadTimes = require('../monitors/browserLoadTimes');
var BackgroundDetector = require('../detectors/backgroundDetector');
var DeviceDetector = require('../detectors/deviceDetector');
var UUIDGenerator = require('../deviceUUID/hashgenerator');
const { default: VideoAnalyticsRequest } = require('../comm/VideoAnalyticsRequest');
const { Method, AnalyticsTag } = require('../../common/Constants');
const { default: Core } = require("../../core/Core");
const { default: CoreConstants } = require("../../core/utils/CoreConstants");
const { default: Util } = require("../../core/utils/Util");

var Plugin = Emitter.extend(
  /** @lends npaw.Plugin.prototype */
  {
    /**
     * This is the main class of video analytics. You may want to have one instance for each video
     * you want to track. Will need {@link Adapter}s for both content and ads.
     *
     * @constructs Plugin
     * @extends npaw.Emitter
     * @memberof npaw
     *
     * @param {Options} [options] An object complying with {@link Options} constructor.
     * @param {Adapter} [adapter] If an adapter is provided, setAdapter will be immediately called.
     * @param {Object} [appAnalyticsDimensions] appAnalyticsDimensions
     */
    constructor: function (appAnalytics, options, adapter, appAnalyticsDimensions) {
      /** Check if plugin was created previously */
      if (Plugin.instance) {
        if (options) {
          Log.notice(AnalyticsTag, 'Set Options for already existing instance');
          Plugin.instance.setOptions(options);
          Plugin.instance.storage.updateStorageOptions(Plugin.instance.options.disableCookies, Plugin.instance.options.forceCookies, Plugin.instance.options.disableStorage);
        }
        if (adapter) {
          Log.notice(AnalyticsTag, 'Set Adapter for already existing instance');
          Plugin.instance.setAdapter(adapter);
        }
        Log.warn(AnalyticsTag, 'Only a single plugin instance can be created on npaw context');
        return Plugin.instance;
      }

      /** Stored {@link Options} of the session. */
      this.options = new Options(options);

      /** Reference to {@link npaw.NpawStorage} */
      this.storage = new NpawStorage(null, this.options.disableCookies, this.options.forceCookies, this.options.disableStorage);

      /** Reference to Utils */
      this.utils = require('./../util');

      /** UUIDGenerator manager */
      this.uuidGenerator = new UUIDGenerator();

      /** Create video structure */
      this.videos = new VideosRepository(this);

      this.analyticsRequestHandler = new AnalyticsRequestHandler(this);

      /** Adapter Templates */
      this.adapterTemplates = {};

      this.appAnalytics = appAnalytics;

      this.requestBuilder = new RequestBuilder();

      this.resourceTransform = new ResourceTransform(this);

      this.lastEventTime = null;

      this.browserLoadTimes = new BrowserLoadTimes(this);
      this.deviceDetector = new DeviceDetector();
      this.backgroundDetector = new BackgroundDetector(this);

      if (this.options['background.enabled']) {
        this.backgroundDetector.startDetection();
      }

      if (adapter) this.setAdapter(adapter);

      this._logInitPluginEvent(options);

      // If we are running on Windows we try to fetch major OS version
      // We are doing this here since the method to fetch the version is asynchronous
      if (navigator && navigator.userAgentData && navigator.userAgentData.platform === "Windows" && navigator.userAgentData.getHighEntropyValues) {
        navigator.userAgentData.getHighEntropyValues(["platformVersion"])
          .then(ua => {
            if (navigator.userAgentData.platform === "Windows") {
              this.majorPlatformVersion = parseInt(ua.platformVersion.split('.')[0]);
            }
          });
      }

      // Comment this out to avoid sending offline events everytime the plugin starts
      // if (!this.options.offline) {
      //   this.fireOfflineEvents();
      // }

      /** Set instance on static variable */
      Plugin.instance = this;
    },

    /**
     * Adds a listener for video request events.
     * @param listener A function of type `(serviceName: string, videoKey: string, params: Map<string, string>) => void` to be called when a video request is about to be sent.
     */
    addOnWillSendRequestListener(listener) {
      this.analyticsRequestHandler.onWillSendVideoRequestListeners.push(listener);
    },

    /**
     * Removes a previously added listener for video request events.
     * @param listener The listener function of type `(serviceName: string, videoKey: string, params: Map<string, string>) => void` to remove from the list of video request listeners.
     */
    removeOnWillSendRequestListener(listener) {
      this.analyticsRequestHandler.onWillSendVideoRequestListeners = this.analyticsRequestHandler.onWillSendVideoRequestListeners.filter(l => l !== listener);
    },
    /**
     * Removes all the registered adapters and videos
     */
    destroy: function () {
      this.videos.removeAllAdapters();
      this.videos.videos = {};
    },

    /**
     * Set Adapters Templates
     * @param adapters
     */
    _setAdapterTemplates: function (adapters) {
      this.adapterTemplates = adapters;
    },

    /**
     *
     * @param e
     * @private
     */
    _receiveConfiguration: function (e) {
      try {
        if (e.target.response.configurationOptions) {
          var configOptions = e.target.response.configurationOptions;
          this.options.setOptions(configOptions, this.options);
          this.videos.updateAllOptions(configOptions);
        }
        if (this.isRefreshLMAConfigurationEnabled() && this.fastDataTransform) {
          this.fastDataTransform._createConfigurationInterval();
        }
      } catch (err) { }
    },

    /**
     * Reset all variables and stop all timers
     * @param {string} videoKey
     * @private
     */
    _reset: function (videoKey) {
      this.videos.resetVideo(videoKey);
    },

    /**
     * Modifies current options. See {@link Options.setOptions}.
     *
     * @param {any} options
     * @param {string} videoKey
     */
    setOptions: function (options) {
      this._logSetOptionsEvent(options);

      if (options) {
        if (typeof options['parse.manifest'] === 'boolean') {
          if (options['parse.manifest']) {
            options['waitForMetadata'] = true;
            if (options['pendingMetadata']) {
              options['pendingMetadata'].push('parsedResource');
            } else {
              options['pendingMetadata'] = [];
              options['pendingMetadata'].push('parsedResource');
            }
          }
        }
        this.options.setOptions(options);
        this.storage.updateStorageOptions(this.options.disableCookies, this.options.forceCookies, this.options.disableStorage)
        for (const videoKey of this.videos.getVideoKeys()) {
          this.setVideoOptions(options, videoKey);
        }
        if (typeof options['background.enabled'] === 'boolean') {
          if (options['background.enabled']) {
            this.backgroundDetector.startDetection();
          } else {
            this.backgroundDetector.stopDetection();
          }
        }
      }

      Core.getInstance().registerCommonVariable(
        CoreConstants.Products.VIDEO_ANALYTICS,
        CoreConstants.AnalyticsVariables.METHOD_OPTION,
        Util.methodFromString(options['method'])
      );
    },

    /**
     * Sets anaytics options for a specific video
     * 
     * @param {object} options Analytics options to be set
     * @param {string} videoKey Video Identifier
     */
    setVideoOptions: function (options, videoKey) {
      videoKey = videoKey || 'default';
      if (options && videoKey && this.videos.existsVideo(videoKey)) {
        if (Object.keys(options).length > 0) {
          // Replace all analytics options from the provided options object
          this.videos.getVideo(videoKey).setVideoOptions(options);
        }
      }
    },

    /**
     * Updates custom metrics
     * 
     * @param {object} metrics Metrics to be updated
     * @param {string} videoKey Video Identifier
     */
    updateCustomMetrics: function(metrics, videoKey) {
      videoKey = videoKey || 'default';
      if (metrics && this.videos.existsVideo(videoKey)) {
        if (Object.keys(metrics).length > 0) {
          this.videos.getVideo(videoKey).updateCustomMetrics(metrics);
        }
      }
    },

    /**
     * Disable request sending.
     */
    disable: function () {
      this.setOptions({ enabled: false });
    },

    /**
     * Re-enable request sending.
     */
    enable: function () {
      this.setOptions({ enabled: true });
    },

    /**
     * Gets the ping time from the FastDataService.
     * @returns {number | undefined} The ping time.
     */
    getPingTime: function () {
      return Core.getInstance().getFastDataService().getPingTime();
    },

    // ----------------------------------------- LOGS -------------------------------------------

    _logInitPluginEvent: function (options) {
      var params = {
        logs: options,
        logAction: 'initPlugin',
        logType: 'pluginMethod'
      };
      this._sendPluginLogs(params, this.isMethodPostEnabled());
    },

    _logSetAdapterEvent: function (adapterObject) {
      var params = {
        adapter: adapterObject,
        logAction: 'setAdapter',
        logType: 'pluginMethod'
      };
      this._sendPluginLogs(params, this.isMethodPostEnabled());
    },

    _logSetOptionsEvent: function (options) {
      var params = {
        logs: options,
        logAction: 'setOptions',
        logType: 'pluginMethod'
      };
      this._sendPluginLogs(params, this.isMethodPostEnabled());
    },

    _logReceiveDataEvent: function (fastDataObj) {
      var fdResponse = '';
      if (
        fastDataObj.data &&
        fastDataObj.data.target &&
        fastDataObj.data.target.response &&
        fastDataObj.data.target.response.msg
      ) {
        fdResponse = JSON.parse(fastDataObj.data.target.response.msg);
      }
      var params = {
        logs: fdResponse,
        logAction: 'receiveData',
        logType: 'internalMethod'
      };
      this._sendPluginLogs(params, this.isMethodPostEnabled());
    },

    /**
     *
     * @param {*} params
     * @param postMethod
     */
    _sendPluginLogs: function (params, postMethod) {
      postMethod = postMethod || false;
      if (this.isPluginLogsEnabled()) {
        if (params && params.logType && params.logAction) {
          Log.notice(AnalyticsTag, 'PluginLog ' + params.logType + ': Action ' + params.logAction);
        }
        if (this.analyticsRequestHandler) {
          params = this.requestBuilder.buildParams(params, Constants.Service.VIDEO_PLUGIN_LOGS, this);
          if (params !== null) {
            var reqMethod = postMethod ? Method.POST : Method.GET;
            const request = new VideoAnalyticsRequest(Constants.Service.VIDEO_PLUGIN_LOGS, params, '', reqMethod);
            this.analyticsRequestHandler.sendRequest(request);
          }
        }
      }
    }
  },

  /** @lends npaw.Plugin */
  {
    // Static Memebers //
    /**
     * List of events that could be fired
     * @enum
     * @event
     */
    Event: Constants.WillSendEvent
  }
);

// Apply Mixins
// Plugin is actually a big class, I decided to separate the logic into
// different mixin files to ease the maintainability of each file.
// Filename convention will be plugin+xxxxx.js where xxxxx is the added functionality.
AnalyticsUtil.assign(Plugin.prototype, require('./plugin_adapter'));
AnalyticsUtil.assign(Plugin.prototype, require('./plugin_ads'));
AnalyticsUtil.assign(Plugin.prototype, require('./plugin_common_getters'));
AnalyticsUtil.assign(Plugin.prototype, require('./plugin_fire'));
AnalyticsUtil.assign(Plugin.prototype, require('./plugin_storage'));

Plugin.instance = null;

module.exports = Plugin;
