import {
  CoreTrackingEvent,
  PlaybackEventTypes,
} from "@telia-company/tv.web-playback-sdk";
import {
  PlayerControls,
  SessionStorageKey,
  setInSessionStorage,
  TAudioTrack,
  TContentMetadata,
  TPlaybackConfig,
  TTextTrack,
  TTranslations,
} from "@telia-company/tv.web-player-shared";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { TClickHandlers } from "../components/context-provider";
import { UiEventTypes } from "../constants";
import { TAction, TSeekRange } from "./ui-state-reducer.hook";

export type TGenerateClickHandlersOptions = {
  controls: PlayerControls | undefined;
  dispatch: Dispatch<TAction>;
  isInAdBreak: boolean;
  isLive: boolean | null;
  liveSeekEnabled: boolean;
  loading: boolean;
  metadata: TContentMetadata;
  playback: TPlaybackConfig;
  publishCustomTrackingEvent: (name: string) => void;
  seekRange: TSeekRange | undefined;
  setPlayback: Dispatch<SetStateAction<TPlaybackConfig>>;
  translations: TTranslations;
};

const generateClickHandlers = ({
  controls,
  dispatch,
  isInAdBreak,
  isLive,
  liveSeekEnabled,
  loading,
  metadata,
  playback: currentPlayback,
  publishCustomTrackingEvent,
  seekRange,
  setPlayback,
  translations,
}: TGenerateClickHandlersOptions): TClickHandlers => ({
  onJumpToLive: async () => {
    if (!controls) return;

    publishCustomTrackingEvent(
      CoreTrackingEvent.PLAYER_JUMP_TO_LIVE_BUTTON_PRESSED
    );

    if (liveSeekEnabled) {
      controls.seek("LIVE");
      return;
    }
    // First check if we have an explicit playback context (signifying what the
    // currently playing media belongs to
    if (currentPlayback.playbackContext) {
      setPlayback({
        playbackSpec: currentPlayback.playbackContext,
      });
      // If we don't have a context, check if we have a channel to jump to
    } else if (metadata.channelPlaybackSpec) {
      setPlayback({
        playbackSpec: metadata.channelPlaybackSpec,
      });
      // If we don't have a channel, check for a related live event
    } else if (metadata.linearPlaybackSpec) {
      setPlayback({
        playbackSpec: metadata.linearPlaybackSpec,
      });
    }
  },
  onNextContent: () => {
    if (metadata.nextEpisode) {
      // Prepare video element synchronously to mitigate autoplay block
      const v = document.createElement("video");
      v.load();

      publishCustomTrackingEvent(CoreTrackingEvent.PLAYER_NEXT_EPISODE);

      setPlayback({
        autoplay: true,
        overrideVideoElement: v,
        playbackSpec: metadata.nextEpisode.playbackSpec,
        startTimeInSeconds: 0,
      });
    }
  },
  onPause: () => {
    if (!controls) return;

    controls.pause();
  },
  onPlayContent: (playback: TPlaybackConfig) => {
    // Prepare video element synchronously to mitigate autoplay block
    const v = document.createElement("video");
    v.load();

    setPlayback({
      ...playback,
      autoplay: true,
      overrideVideoElement: v,
    });
  },
  onRecordAndWatch: async (recordCallback) => {
    // Prepare video element synchronously to mitigate autoplay block
    const v = document.createElement("video");
    v.load();

    dispatch({
      payload: {},
      type: UiEventTypes.ENTER_LOADING_MODE,
    });

    try {
      const playback = await recordCallback();

      setPlayback({
        ...playback,
        autoplay: true,
        overrideVideoElement: v,
      });
    } catch (_) {
      publishCustomTrackingEvent("RECORDING_FAILED");

      dispatch({
        payload: {},
        type: UiEventTypes.CANCEL_LOADING_MODE,
      });

      dispatch({
        payload: {
          modalOptions: {
            modalButtons: [
              {
                buttonText: translations.closeModalButtonText,
                onClick: () => {
                  dispatch({
                    payload: {},
                    type: UiEventTypes.HIDE_MODAL,
                  });

                  return Promise.resolve();
                },
                type: "primary",
              },
            ],
            modalMetadata: {
              body: translations.recordingFailedModalBody,
              title: translations.recordingFailedModalTitle,
            },
          },
        },
        type: UiEventTypes.SHOW_MODAL,
      });
    }
  },
  onSeek: (seekToSeconds) => {
    if (!controls) return;
    if (loading) return;
    if (isInAdBreak) return;

    controls.seek(seekToSeconds);
  },
  onSelectAudioTrack: (lang: TAudioTrack) => {
    if (loading) return;
    if (!controls) return;

    publishCustomTrackingEvent(PlaybackEventTypes.AUDIO_TRACK_CHANGED);

    setInSessionStorage(SessionStorageKey.AUDIO_LANG, lang.isoCode);

    controls.setAudioTrack(lang);
  },
  onSelectTextTrack: (track: TTextTrack) => {
    if (loading) return;
    if (!controls) return;

    publishCustomTrackingEvent(PlaybackEventTypes.TEXT_TRACK_CHANGED);

    setInSessionStorage(SessionStorageKey.SUBTITLE_LANG, track.isoCode);

    controls.setSubtitleTrack(track);
  },
  onSetTextTrackVisibility: (visible: boolean) => {
    if (loading) return;
    if (!controls) return;

    setInSessionStorage(SessionStorageKey.SUBTITLE_VISIBLE, visible);

    controls.setSubtitleVisibility(visible);
  },
  onStartover: async () => {
    publishCustomTrackingEvent(
      CoreTrackingEvent.PLAYER_STARTOVER_BUTTON_PRESSED
    );

    if (liveSeekEnabled && seekRange) {
      controls?.seek(seekRange.start);
      return;
    }
    if (metadata.canStartOver && metadata.startoverPlaybackSpec) {
      setPlayback({
        autoplay: true,
        playbackContext: currentPlayback.playbackSpec,
        playbackSpec: metadata.startoverPlaybackSpec,
        startTimeInSeconds: 0,
      });
    }
  },
  onTogglePlay: () => {
    if (!controls) return;

    // Always allow playing when play button is clicked.
    // Pause is restricted, however.
    if (controls.getSpeed() === 0) {
      controls.play();
    } else if (!isLive || liveSeekEnabled) {
      controls.pause();
    }
  },
  onWatchCredits: () =>
    dispatch({ payload: {}, type: UiEventTypes.WATCH_CREDITS }),
});

export const useClickHandlers = ({
  controls,
  dispatch,
  isInAdBreak,
  isLive,
  liveSeekEnabled,
  loading,
  metadata,
  playback,
  publishCustomTrackingEvent,
  seekRange,
  setPlayback,
  translations,
}: TGenerateClickHandlersOptions): TClickHandlers => {
  const [clickHandlers, setClickHandlers] = useState(
    generateClickHandlers({
      controls,
      dispatch,
      isInAdBreak,
      isLive,
      liveSeekEnabled,
      loading,
      metadata,
      playback,
      publishCustomTrackingEvent,
      seekRange,
      setPlayback,
      translations,
    })
  );

  useEffect(() => {
    setClickHandlers(
      generateClickHandlers({
        controls,
        dispatch,
        isInAdBreak,
        isLive,
        liveSeekEnabled,
        loading,
        metadata,
        playback,
        publishCustomTrackingEvent,
        seekRange,
        setPlayback,
        translations,
      })
    );
  }, [
    translations,
    playback,
    setPlayback,
    dispatch,
    isLive,
    seekRange,
    loading,
    metadata,
    controls,
    liveSeekEnabled,
    isInAdBreak,
    publishCustomTrackingEvent,
  ]);

  return clickHandlers;
};
