import { TPlayerStats } from "@telia-company/tv.web-player-shared";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-date-fns";
import React, { FC, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TimeScale,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Bitrate and buffer",
    },
  },
  scales: {
    bitrate: {
      display: true,
      max: 5482000 * 1.5,
      min: 0,
      position: "left" as const,
      type: "linear" as const,
    },
    buffer: {
      display: true,
      grid: {
        drawOnChartArea: false,
      },
      max: 120,
      min: 0,
      position: "right" as const,
      type: "linear" as const,
    },
    x: {
      scaleLabel: {
        display: true,
        labelString: "Time ( UTC )" as const,
      },
      time: {
        displayFormats: { second: "HH:mm:ss" as const },
        unit: "second" as const,
      },
      type: "time" as const,
    },
  },
};

export type TDataPoint = {
  x: Date;
  y: number;
};

export type Props = {
  stats: TPlayerStats;
  videoElement: HTMLVideoElement;
};

const usePrevious = (value: TPlayerStats) => {
  const ref = useRef<TPlayerStats>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useCompare = (val: TPlayerStats) => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

export const BitrateGraph: FC<Props> = ({ stats, videoElement }) => {
  const [plotData, setPlotData] = useState<Array<TDataPoint>>([]);
  const [bufferData, setBufferData] = useState<Array<TDataPoint>>([]);

  const { streamBandwidth } = stats;

  const hasStatsChanged = useCompare(stats);

  useEffect(() => {
    if (!hasStatsChanged) return;
    if (!stats.buffers) return;
    if (!streamBandwidth) return;
    if (!videoElement) return;

    setPlotData((old): TDataPoint[] =>
      [...old, { x: new Date(), y: streamBandwidth }].slice(-60)
    );

    if (stats.buffers?.video?.[0]?.end)
      setBufferData((old) => {
        if (!stats.buffers) return [];

        return [
          ...old,
          {
            x: new Date(),
            y: parseInt(
              (stats.buffers.video[0].end - videoElement.currentTime).toFixed(
                0
              ),
              10
            ),
          },
        ].slice(-60);
      });
  }, [hasStatsChanged, stats, streamBandwidth, videoElement]);

  const data = {
    datasets: [
      {
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
        data: plotData,
        fill: false,
        label: "Bitrate",
        yAxisID: "bitrate",
      },
      {
        backgroundColor: "rgb(99, 255, 132)",
        borderColor: "rgba(99, 255, 132, 0.2)",
        data: bufferData,
        fill: false,
        label: "Buffer",
        yAxisID: "buffer",
      },
    ],
    labels: [],
  };

  return <Line data={data} options={options} />;
};
