import {
  TEngineVariantRestrictions,
  TPlaybackConfig,
  TPlaybackService,
  TPlayerFeatures,
  TUserConfig,
} from "@telia-company/tv.web-player-shared";
import { Dispatch, SetStateAction, useEffect } from "react";

export type TUsePlayerSetupOptions = {
  features: TPlayerFeatures;
  initialized: boolean;
  playback: TPlaybackConfig;
  playbackService: TPlaybackService;
  restrictions?: TEngineVariantRestrictions;
  setOnConcurrentStreams: Dispatch<
    SetStateAction<((id: string) => void) | null>
  >;
  user: TUserConfig;
};

export const usePlayerSetup = ({
  features,
  initialized,
  playback,
  playbackService,
  restrictions,
  setOnConcurrentStreams,
  user,
}: TUsePlayerSetupOptions): void => {
  useEffect(() => {
    if (!playback || !initialized) return;

    const session = playbackService.initializePlayback({
      features,
      playback,
      restrictions,
      user,
    });

    if (session) {
      const { onConcurrentStreams } = session;

      setOnConcurrentStreams(() => onConcurrentStreams);
    } else {
      setOnConcurrentStreams(null);
    }
  }, [
    playback,
    user,
    playbackService,
    setOnConcurrentStreams,
    initialized,
    restrictions,
    features,
  ]);
};
