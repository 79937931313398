import { MetadataService } from "@telia-company/tv.web-playback-sdk";
import {
  TContentMetadata,
  TNpvrInfo,
  TPlaybackSpec,
  WatchMode,
} from "@telia-company/tv.web-player-shared";

export type TGetMediaMetadata = {
  metadataService: MetadataService;
  playbackSpec: TPlaybackSpec;
};

export const getMediaMetadata = ({
  metadataService,
  playbackSpec,
}: TGetMediaMetadata): Promise<TContentMetadata> =>
  metadataService.getMediaMetadata({ playbackSpec }).then((metadata) => {
    let nextEpisode;
    if (metadata.nextContent) {
      nextEpisode = {
        episode: metadata.nextContent.episode,
        episodeDescription: metadata.nextContent.metadata?.description || "",
        playbackSpec: metadata.nextContent.playbackSpec as TPlaybackSpec,
        season: metadata.nextContent.season,
        seriesTitle: metadata.nextContent.seriesTitle || "",
        validFrom: metadata.nextContent.validFrom,
        validTo: metadata.nextContent.validTo,
      };
    }

    return {
      canStartOver:
        !!metadata.startoverPlaybackSpec &&
        playbackSpec.watchMode === WatchMode.LIVE,
      channelPlaybackSpec: metadata.channelPlaybackSpec as TPlaybackSpec,
      endTime: metadata.endTime,
      episode: metadata.series?.episode,
      images: metadata.images,
      linearPlaybackSpec: metadata.linearPlaybackSpec as TPlaybackSpec,
      nextEpisode,
      npvrInfo: metadata.npvrInfo as TNpvrInfo,
      relatedContent:
        // Guard for news/sports "seasons" that can be 0 or the current year
        metadata.series?.season &&
        metadata.series.season < 1000 &&
        playbackSpec.watchMode === WatchMode.ONDEMAND &&
        metadata.series
          ? {
              items: metadata.series,
              type: "SERIES",
            }
          : undefined,
      season: metadata.series?.season,
      seriesTitle: metadata.series?.title,
      startoverPlaybackSpec: metadata.startoverPlaybackSpec as TPlaybackSpec,
      startTime: metadata.startTime,
      title: metadata.series?.title || metadata.title,
    };
  });
