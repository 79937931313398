import { TCallback } from "@telia-company/tv.web-player-shared";
import { Dispatch, useEffect } from "react";

import { UiEventTypes } from "../constants";
import { TAction } from "./ui-state-reducer.hook";

export type TUseDocumentEventOptions = {
  dispatch: Dispatch<TAction>;
};

export const useDocumentEvents = ({
  dispatch,
}: TUseDocumentEventOptions): void => {
  useEffect(() => {
    const onFullscreenChange = () => {
      if (document.fullscreenElement) {
        dispatch({
          payload: {},
          type: UiEventTypes.ENTER_FULLSCREEN,
        });
      } else {
        dispatch({
          payload: {},
          type: UiEventTypes.EXIT_FULLSCREEN,
        });
      }
    };

    const eventHandlerPairs: Array<[string, TCallback]> = [
      ["fullscreenchange", onFullscreenChange],
    ];

    eventHandlerPairs.forEach(([evt, handler]) => {
      document.addEventListener(evt, handler);
    });

    return () =>
      eventHandlerPairs.forEach(([evt, handler]) => {
        document.removeEventListener(evt, handler);
      });
  }, [dispatch]);
};
