import {
  AdvertisementBreakTypes,
  ErrorCategories,
  StandardError,
} from "../../../../../base";
import { SessionVOD } from "@yospace/admanagement-sdk";
import { IYospacePlaybackFeatureConfiguration } from "../Types";
import {
  addOrAppendQueryParameter,
  getPersistentAdvertisementId,
} from "../../../../../utils";
import {
  isYospaceError,
  isYospaceLoadError,
  YospaceErrorCodes,
} from "../YospaceError";

export const createYospaceUrl = (
  contentUrl: string,
  configuration: Omit<
    IYospacePlaybackFeatureConfiguration,
    | "allowDelayedMetadataDelivery"
    | "allowStartInReplacedOnDemandBreak"
    | "callbacks"
    | "contentUrl"
  >
): string => {
  let tags = `${configuration.serviceId.toLowerCase()}.${configuration.serviceCountry.toLowerCase()},ns_st_mv-${
    configuration.applicationVersion
  }`;

  if (configuration.startOver) {
    tags += ",startover";
  }

  if (configuration.skipBumpers) {
    tags += ",NOBUMPERS";
  }

  if (configuration.extraTags?.length) {
    tags += `,${configuration.extraTags.join(",")}`;
  }

  const yospaceUrl = new URL(contentUrl);

  yospaceUrl.searchParams.set("dcid", configuration.deviceAdvertisementId);
  yospaceUrl.searchParams.set(
    "pid",
    getPersistentAdvertisementId(configuration.userId)
  );
  addOrAppendQueryParameter(yospaceUrl.searchParams, "t", tags);

  if (configuration.advertisingId) {
    yospaceUrl.searchParams.set("cp.ifa", configuration.advertisingId);
  }
  if (configuration.gdprConsentString) {
    yospaceUrl.searchParams.set(
      "gdpr_consent",
      configuration.gdprConsentString
    );
    yospaceUrl.searchParams.set("gdpr", "1");
  }
  if (configuration.skipPrerolls) {
    yospaceUrl.searchParams.set("f", "noprerolls");
  }

  return yospaceUrl.href;
};

export const getStitchedUrl = (masterPlaylist: string): string | undefined => {
  return masterPlaylist && masterPlaylist.indexOf("https://null/") === 0
    ? undefined
    : masterPlaylist;
};

export const isVodSession = (value?: any): value is SessionVOD =>
  !!value?.setAdBreaksInactivePriorTo;

export const mapBreakType = (
  yospacePosition: "preroll" | "midroll" | "postroll" | "unknown" | string
): AdvertisementBreakTypes => {
  switch (yospacePosition) {
    case "preroll":
      return AdvertisementBreakTypes.PREROLL;
    case "midroll":
      return AdvertisementBreakTypes.MIDROLL;
    case "postroll":
      return AdvertisementBreakTypes.POSTROLL;
    case "unknown":
    default:
      return AdvertisementBreakTypes.MIDROLL;
  }
};

export const mapYospaceError = (
  e: unknown,
  configuration: Record<string, any>
): StandardError | undefined => {
  if (isYospaceError(e)) {
    if (e.code === YospaceErrorCodes.StitchingFailed) {
      return new StandardError({
        category: ErrorCategories.API,
        code: "AD_STITCHING_FAILED",
        fatal: true,
        details: {
          origin: "Yospace",
          domain: "initialize",
          configuration,
        },
      });
    } else if (isYospaceLoadError(e)) {
      return new StandardError({
        category: ErrorCategories.API,
        code: "AD_LOAD_FAILED",
        fatal: true,
        details: {
          origin: "Yospace",
          domain: "initialize",
          configuration,
          result: e.resultCode,
          sessionState: e.sessionState,
          yospaceErrorCode: e.code,
        },
      });
    }
  }
};
