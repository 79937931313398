import {
  ApplicationInfo,
  BrowserInfo,
  DeviceInfo,
  OperatingSystemInfo,
  PlaybackSessionInfo,
  PlayerInfo,
  ServiceInfo,
  SessionInfo,
  UserInfo,
} from "./";

import { ScreenInfo } from "./ScreenInfo";

export type PlatformInfoArgs = {
  browser?: BrowserInfo;
  device: DeviceInfo;
  operatingSystem?: OperatingSystemInfo;
  screen?: ScreenInfo;
};

export type ApplicationDataArgs = {
  application?: ApplicationInfo;
  device?: DeviceInfo;
  playbackSession?: PlaybackSessionInfo;
  player?: PlayerInfo;
  screen?: ScreenInfo;
  service?: ServiceInfo;
  session?: SessionInfo;
  user?: UserInfo;
  os?: OperatingSystemInfo;
};

export class ApplicationData {
  public application?: ApplicationInfo;
  public browser: BrowserInfo;
  public device?: DeviceInfo;
  public operatingSystem?: OperatingSystemInfo;
  public playbackSession?: PlaybackSessionInfo;
  public player?: PlayerInfo;
  public screen?: ScreenInfo;
  public service?: ServiceInfo;
  public session?: SessionInfo;
  public user?: UserInfo;

  constructor(args: ApplicationDataArgs) {
    this.browser = new BrowserInfo();

    if (args) {
      if (args.application)
        this.application = new ApplicationInfo(args.application);
      if (args.device) this.device = new DeviceInfo(args.device);
      if (args.playbackSession)
        this.playbackSession = new PlaybackSessionInfo(args.playbackSession);
      if (args.player) this.player = new PlayerInfo(args.player);
      if (args.screen) this.screen = new ScreenInfo(args.screen);
      if (args.service) this.service = new ServiceInfo(args.service);
      if (args.session) this.session = new SessionInfo(args.session);
      if (args.user) this.user = new UserInfo(args.user);
      if (args.os) this.operatingSystem = new OperatingSystemInfo(args.os);
    }
  }

  setApplicationInfo(args: ApplicationInfo): ApplicationData {
    this.application = new ApplicationInfo(args);
    return this;
  }

  setBrowserInfo(args: BrowserInfo): ApplicationData {
    this.browser = new BrowserInfo(args);
    return this;
  }

  setDeviceInfo(args: DeviceInfo): ApplicationData {
    this.device = new DeviceInfo(args);
    return this;
  }

  setOperatingSystemInfo(args: OperatingSystemInfo): ApplicationData {
    this.operatingSystem = new OperatingSystemInfo(args);
    return this;
  }

  setPlatformInfo(args: PlatformInfoArgs): ApplicationData {
    this.browser = new BrowserInfo(args.browser);
    this.device = new DeviceInfo(args.device);
    if (args.operatingSystem) {
      this.operatingSystem = new OperatingSystemInfo(args.operatingSystem);
    }
    this.screen = new ScreenInfo(args.screen);
    return this;
  }

  setPlaybackSessionInfo(args: PlaybackSessionInfo): ApplicationData {
    this.playbackSession = new PlaybackSessionInfo(args);
    return this;
  }

  setPlayerInfo(args: PlayerInfo): ApplicationData {
    this.player = new PlayerInfo(args);
    return this;
  }

  setScreenInfo(args: ScreenInfo): ApplicationData {
    this.screen = new ScreenInfo(args);
    return this;
  }

  setServiceInfo(args: ServiceInfo): ApplicationData {
    this.service = new ServiceInfo(args);
    return this;
  }

  setSessionInfo(args: SessionInfo): ApplicationData {
    this.session = new SessionInfo(args);
    return this;
  }

  setUserInfo(args: UserInfo): ApplicationData {
    this.user = new UserInfo(args);
    return this;
  }
}

// export class Engine{
//     engineName?: string = 'Shaka | wacka | dash | video.js'
//     engineVersion?: string = '1.0.0'
//     supportedDRMSystems?: string[] = [];
//     supportedStreamingProtocols?: string[] = [];
//     supportedContainerFormats?: string[] = [];
//     adsSupport?: string[] = ['stitched', 'clientSide']
// };
