import styled from "@emotion/styled";
import { FC } from "react";

import { TPlayerIcon } from "../@types/icon-type";
import { GridIcon } from "./elements";

const fadeinAnim = `
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const OverlayPlayButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: fadein 0.3s ease-out;
  animation-fill-mode: forwards;
  ${fadeinAnim}
`;

const PlayCircle = styled(GridIcon)`
  width: 6em;
  height: 6em;

  background: rgba(0, 0, 0, 0.4);
  border-radius: 3em;
`;

export type TOverlayPlayButtonProps = {
  clickHandler: () => void;
  Icon: TPlayerIcon;
};

export const OverlayPlayButton: FC<TOverlayPlayButtonProps> = ({
  clickHandler,
  Icon,
}) => (
  <OverlayPlayButtonWrapper onClick={clickHandler}>
    <PlayCircle>
      <Icon />
    </PlayCircle>
  </OverlayPlayButtonWrapper>
);
