var NpawObject = require('../object')
const { default: Log } = require('../../common/log')
const { AnalyticsTag } = require('../../common/Constants');

var DeprecatedOptions = NpawObject.extend({
  exists: function (optionName) {
    return DeprecatedOptions.Dictionary.hasOwnProperty(optionName) // eslint-disable-line no-prototype-builtins
  },

  getNewName: function (optionName) {
    var newName = DeprecatedOptions.Dictionary[optionName]
    Log.warn(AnalyticsTag, 'The option "' + optionName + '" is deprecated, use "' + newName + '" instead')
    return newName
  }
}, {
  // Keys are old option names
  // Values are new option names
  Dictionary: {
    httpSecure: 'app.https',
    username: 'user.name',
    anonymousUser: 'user.anonymousId',
    obfuscateIp: 'user.obfuscateIp',
    userType: 'user.type',
    'content.title2': 'content.program',
    'background.settings.iphone': 'background.settings.iOS',
    'parse.hls': 'parse.manifest',
    'parse.dash': 'parse.manifest',
    'parse.locationHeader': 'parse.manifest',
    'extraparam.1': 'content.customDimension.1',
    'extraparam.2': 'content.customDimension.2',
    'extraparam.3': 'content.customDimension.3',
    'extraparam.4': 'content.customDimension.4',
    'extraparam.5': 'content.customDimension.5',
    'extraparam.6': 'content.customDimension.6',
    'extraparam.7': 'content.customDimension.7',
    'extraparam.8': 'content.customDimension.8',
    'extraparam.9': 'content.customDimension.9',
    'extraparam.10': 'content.customDimension.10',
    'extraparam.11': 'content.customDimension.11',
    'extraparam.12': 'content.customDimension.12',
    'extraparam.13': 'content.customDimension.13',
    'extraparam.14': 'content.customDimension.14',
    'extraparam.15': 'content.customDimension.15',
    'extraparam.16': 'content.customDimension.16',
    'extraparam.17': 'content.customDimension.17',
    'extraparam.18': 'content.customDimension.18',
    'extraparam.19': 'content.customDimension.19',
    'extraparam.20': 'content.customDimension.20',
    'ad.extraparam.1': 'ad.customDimension.1',
    'ad.extraparam.2': 'ad.customDimension.2',
    'ad.extraparam.3': 'ad.customDimension.3',
    'ad.extraparam.4': 'ad.customDimension.4',
    'ad.extraparam.5': 'ad.customDimension.5',
    'ad.extraparam.6': 'ad.customDimension.6',
    'ad.extraparam.7': 'ad.customDimension.7',
    'ad.extraparam.8': 'ad.customDimension.8',
    'ad.extraparam.9': 'ad.customDimension.9',
    'ad.extraparam.10': 'ad.customDimension.10'
  }
})

module.exports = DeprecatedOptions
