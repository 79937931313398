import styled from "@emotion/styled";
import React from "react";

import { ChromecastIcon } from "~/components/ChromecastIcon";
import { useChromecastContext } from "~/providers";

export type StyledButtonProps = {
  senderState?: string; // TODO: be able to use ConnectionStates here
};

const StyledButton = styled.button<StyledButtonProps>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 48px;
  width: 48px;
  padding: 0;
`;

export type ChromecastButtonProps = {
  ariaLabel?: string;
  className?: string;
};

export const ChromecastButton: React.FC<ChromecastButtonProps> = ({
  ariaLabel,
  className,
}) => {
  const { sender, senderState } = useChromecastContext();

  if (!sender || !sender?.supported || !sender.devicesFound) {
    return null;
  }
  const onClickButtonHandler = async () => {
    if (!sender) return;
    try {
      await sender.connect();
    } catch (error) {
      if (error !== "cancel") {
        throw error;
      }
    }
  };

  return (
    <StyledButton
      aria-label={ariaLabel}
      className={className}
      onClick={onClickButtonHandler}
      senderState={senderState}
    >
      <ChromecastIcon senderState={senderState} />
    </StyledButton>
  );
};
