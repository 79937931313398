import { AccessControl, TThumbnail } from "@telia-company/tv.web-player-shared";
import { FC, useCallback, useRef, useState } from "react";

import { UiEventTypes } from "../../constants";
import {
  useClickHandler,
  useCustomStyles,
  useDispatch,
  usePlayerState,
  useSmallLayout,
  useTranslations,
  useUiScale,
} from "../context-provider";
import { AdMarkers } from "./ad-marker";
import {
  ProgressBar,
  ProgressDot,
  ProgressTrackContainer,
  SeekBubble,
  Thumbnail,
  ThumbnailWrapper,
} from "./elements";
import { useLastDragPositionWhileSeeking } from "./last-drag-position-while-seeking.hook";
import { ProgressTrack } from "./progress-track";
import { useProgress } from "./progress.hook";
import { SeekableArea } from "./seekable-area";
import { LeftTimer, RightTimer } from "./time-indicator";
import { useTimestamps } from "./timestamps.hook";
import { getProgressPosition } from "./util";

export type TProgressBarContainer = {
  canJumpToLive: boolean;
  isOngoingStartoverOrLiveEvent: boolean;
};

export const ProgressBarContainer: FC<TProgressBarContainer> = ({
  canJumpToLive,
  isOngoingStartoverOrLiveEvent,
}) => {
  const smallLayout = useSmallLayout();
  const { onJumpToLive } = useClickHandler();
  const {
    adMarkers,
    atLiveEdge,
    currentTime,
    duration: vodDuration,
    isInAdBreak,
    liveSeekEnabled,
    metadata: { endTime, startTime /* programsInSeekRange */ },
    playback,
    playbackType,
    seeking,
    seekRange,
    showProgressBar,
    trickPlayRestrictions: { noFastForward, noRewind },
  } = usePlayerState();
  const dispatch = useDispatch();
  const { progressBarColor, progressBarColorDark } = useCustomStyles();
  const { scrubbingNotAllowed } = useTranslations();

  const [mouseProgressPosition, setMouseProgressPosition] = useState(0);
  const [seekLabelTimestamp, setSeekLabelTimestamp] = useState<null | string>(
    null
  );
  const [isDragging, setIsDragging] = useState(false);
  const [dragPosition, setDragPosition] = useState<null | number>(null);
  const [seekRequested, setSeekRequested] = useState(false);
  const [thumbnail, setThumbnail] = useState<{
    img: TThumbnail;
    offset: {
      left: null | number;
      right: null | number;
    } | null;
    pos: number;
  } | null>(null);
  const progressDotRef = useRef<HTMLDivElement>(null);
  const scaleFactor = useUiScale();
  const thumbnailWrapperRef = useRef(null);

  const dispatchShowScrubbingInfo = useCallback(
    () =>
      dispatch({
        payload: {
          message: scrubbingNotAllowed,
        },
        type: UiEventTypes.SHOW_TOAST,
      }),
    [dispatch, scrubbingNotAllowed]
  );

  useLastDragPositionWhileSeeking({
    currentTime,
    isDragging,
    seeking,
    seekRequested,
    setDragPosition,
    setSeekRequested,
  });

  const { leftTimer, rightTimer } = useTimestamps({
    currentTime,
    endTime,
    liveSeekEnabled,
    playbackType,
    seekRange,
    startTime,
    vodDuration,
  });

  const { progressPercent, seekWindowPercent } = useProgress({
    currentTime,
    endTime,
    liveSeekEnabled,
    playbackType,
    seekRange,
    startTime,
    vodDuration,
  });

  // "timeupdate" events have not started yet
  if (currentTime === null) return null;
  // progress calculations could not be performed for some reason
  if (progressPercent === null || !leftTimer) return null;

  const progressPosition = getProgressPosition({
    dragPosition,
    progressPercent,
    seekWindowPercent,
  });

  const allowSeek = !(noFastForward && noRewind) && !isInAdBreak;

  return (
    <ProgressBar smallLayout={smallLayout} visible={showProgressBar}>
      {!smallLayout && <LeftTimer smallLayout={smallLayout} text={leftTimer} />}
      <ProgressTrackContainer
        noTimerRight={rightTimer === null}
        shortTimerLeft={leftTimer.length < 6}
        shortTimerRight={rightTimer === null || rightTimer.length < 6}
        smallLayout={smallLayout}
      >
        {thumbnail && !isInAdBreak && (
          <ThumbnailWrapper
            ref={thumbnailWrapperRef}
            scaleFactor={scaleFactor}
            style={{ left: `${thumbnail.pos * 100}%` }}
          >
            {thumbnail.offset && (
              <Thumbnail img={thumbnail.img} offset={thumbnail.offset} />
            )}
          </ThumbnailWrapper>
        )}
        <ProgressTrack
          atLiveEdge={atLiveEdge}
          canJumpToLive={canJumpToLive}
          isNpvr={playback?.playbackSpec.accessControl === AccessControl.NPVR}
          isOngoingStartoverOrLiveEvent={isOngoingStartoverOrLiveEvent}
          liveSeekEnabled={liveSeekEnabled}
          mouseProgressPosition={mouseProgressPosition}
          onJumpToLive={onJumpToLive}
          playbackType={playbackType}
          progressBarColor={progressBarColor}
          progressBarColorDark={progressBarColorDark}
          progressPosition={progressPosition}
          seekWindowPercent={seekWindowPercent}
          showScrubbingInfo={dispatchShowScrubbingInfo}
        />

        {seekLabelTimestamp && !isInAdBreak && (
          <SeekBubble style={{ left: `${mouseProgressPosition * 100}%` }}>
            {seekLabelTimestamp}
          </SeekBubble>
        )}

        {allowSeek && (
          <SeekableArea
            isDragging={isDragging}
            liveSeekEnabled={liveSeekEnabled}
            playbackType={playbackType}
            progressDotRef={progressDotRef}
            progressPercent={progressPercent}
            seekWindowPercent={seekWindowPercent}
            setDragPosition={setDragPosition}
            setIsDragging={setIsDragging}
            setMouseProgressPosition={setMouseProgressPosition}
            setSeekLabelTimestamp={setSeekLabelTimestamp}
            setSeekRequested={setSeekRequested}
            setThumbnail={setThumbnail}
            thumbnailWrapperRef={thumbnailWrapperRef}
          />
        )}

        {adMarkers && vodDuration && <AdMarkers adMarkers={adMarkers} />}

        {allowSeek && seekRange && (
          <ProgressDot
            horizontal
            ref={progressDotRef}
            style={{
              left: `${progressPosition.toFixed(1)}%`,
            }}
          />
        )}
      </ProgressTrackContainer>
      {!!rightTimer && (
        <RightTimer smallLayout={smallLayout} text={rightTimer} />
      )}
    </ProgressBar>
  );
};
