var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Util from '../utils/Util';
import { Method } from '../../common/Constants';
import Core from '../Core';
export default class NQSRequest {
    constructor(isHttps, host, service, method, params, onSuccess, onFail) {
        this.method = Method.GET;
        this.headers = {};
        this.xhr = new XMLHttpRequest();
        this.retries = 0;
        this.hadBody = false;
        this.isHttps = isHttps;
        this.host = host;
        this.service = service;
        this.method = method;
        this.params = params;
        this.onSuccessCallback = onSuccess;
        this.onFailCallback = onFail;
    }
    performHttps() {
        return this.isHttps;
    }
    getHost() {
        return this.host;
    }
    getService() {
        return this.service;
    }
    getMethod() {
        return this.method;
    }
    getParams() {
        return this.params;
    }
    getBody() {
        return this.body;
    }
    setBody(body) {
        this.body = body;
    }
    addHeader(key, value) {
        this.headers[key] = value;
    }
    getRetries() {
        return this.retries;
    }
    /**
     * Performs the request to the specified host and service
     *
     * @param sendFail Callback to when the send fails
     * @param onSucess Callback to when the send succeeds (optional)
     */
    send(sendFail, onSuccess) {
        this.params.timemark = Date.now();
        this.params.sessionRoot = Core.getInstance().getFastDataSessionToken();
        this.params.system = Core.getInstance().getFastDataService().getAccountCode();
        if (this.body) {
            this.hadBody = true;
        }
        if (this.method === Method.POST && !this.body) {
            const newParams = {};
            newParams.timemark = this.params.timemark;
            if (this.params.code) {
                newParams.code = this.params.code;
            }
            newParams.sessionRoot = this.params.sessionRoot;
            newParams.system = this.params.system;
            newParams.sessionId =
                this.params.sessionId && this.params.sessionId != ''
                    ? this.params.sessionId
                    : Core.getInstance().getFastDataSessionToken();
            this.body = JSON.stringify(this.params);
            this.params = newParams;
        }
        try {
            this.getUrl();
        }
        catch (e) {
            console.log(e);
        }
        this.xhr.open(this.method, this.getUrl());
        this.xhr.onreadystatechange = () => {
            if (this.xhr.readyState === 4 && this.xhr.status >= 200 && this.xhr.status < 400) {
                if (onSuccess) {
                    onSuccess();
                }
                if (this.onSuccessCallback) {
                    this.onSuccessCallback();
                }
            }
            else if (this.xhr.readyState === 4) {
                this.retries++;
                if (!this.hadBody && this.method === Method.POST && this.body) {
                    this.params = JSON.parse(this.body);
                    this.body = undefined;
                }
                sendFail();
                if (this.onFailCallback) {
                    this.onFailCallback();
                }
            }
        };
        for (const headerKey in this.headers) {
            this.xhr.setRequestHeader(headerKey, this.headers[headerKey]);
        }
        this.xhr.send(this.body);
    }
    asyncSend() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                this.send(reject, resolve);
            });
        });
    }
    /**
     * Converts this object into a string to be saved on the local storage
     */
    toLocalStorageString() {
        const info = {};
        const serviceSplit = this.service.split('/');
        info.request = serviceSplit[serviceSplit.length - 1];
        info.unixtime = this.params['timemark'];
        for (const param in this.params) {
            if (typeof this.params[param] === 'undefined')
                continue;
            if (param === 'sessionId' || param === 'sessionRoot') {
                info[param] = '';
            }
            else if (param === 'code') {
                info[param] = 'CODE_PLACEHOLDER';
            }
            else {
                if (typeof this.params[param] === 'object') {
                    info[param] = JSON.stringify(this.params[param]);
                }
                else {
                    info[param] = this.params[param];
                }
            }
        }
        return JSON.stringify(info);
    }
    getUrl() {
        const scheme = !this.isHttps ? 'http' : 'https';
        return `${scheme}://${this.host}${this.service}${Util.toQueryString(this.params)}`;
    }
}
