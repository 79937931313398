import styled from "@emotion/styled";
import { IconSpinner } from "@telia-company/tv.web-player-telia-icons";
import { FC } from "react";

const spinAnimation = `
  animation: spin 1.5s linear infinite, fadein .2s linear;
  transform-origin: center;
  
  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }
  
  @keyframes fadein {
    from {
      height: 0em;
      width: 0em;
      > svg { 
        fill-opacity: 0.2;
        stroke-opacity: 0.2;
      }
    }
    
    to {
      height: 4em;
      width: 4em;
      > svg { 
        fill-opacity: 0.7;
        stroke-opacity: 0.7;
      }
    }
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
`;

const SpinnerWrapper = styled.div`
  width: 6rem;
  height: 6rem;

  pointer-events: none;

  > svg {
    pointer-events: none;
    fill-opacity: 0.7;
    stroke-opacity: 0.7;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: auto;
  }

  ${spinAnimation}
`;

export const Spinner: FC = () => (
  <SpinnerContainer>
    <SpinnerWrapper>
      <IconSpinner />
    </SpinnerWrapper>
  </SpinnerContainer>
);
