import * as React from "react";

function SvgVolume(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 12H8a3 3 0 00-3 3v2a3 3 0 003 3h2l5.293 5.293A1 1 0 0017 24.586V7.414a1 1 0 00-1.707-.707L10 12zm11 10a9.3 9.3 0 002-6 9.3 9.3 0 00-2-6m3 15c1.832-2.14 3-5.377 3-9s-1.168-6.86-3-9"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgVolume;
