import styled from "@emotion/styled";
import { FC } from "react";

import { TJumpToLiveButtonProps } from "../@types/types";
import { GridIcon } from "./elements";

const JumpToLiveButtonWrapper = styled.div<{ position: number }>`
  grid-column: ${({ position }) => `${position} / ${position + 3}`};
  grid-row: 9;
  grid-template-columns: 1fr 2fr;
  display: grid;
`;

const JumpToLiveIcon = styled(GridIcon)`
  grid-column: 1;
`;

const ButtonLabel = styled.span`
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-column: 2;
`;

export const JumpToLiveButton: FC<TJumpToLiveButtonProps> = ({
  clickHandler,
  Icon,
  position,
  text,
  tooltip,
}) => (
  <JumpToLiveButtonWrapper position={position}>
    <JumpToLiveIcon
      aria-label={tooltip}
      data-microtip-position="top"
      data-tooltip
      onClick={clickHandler}
      onMouseLeave={(e) => e.currentTarget.blur()}
    >
      <Icon />
    </JumpToLiveIcon>
    <ButtonLabel onClick={clickHandler}> {text} </ButtonLabel>
  </JumpToLiveButtonWrapper>
);
