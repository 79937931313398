import styled from "@emotion/styled";
import { Formik } from "formik";
import { FC } from "react";

import { TLogin } from "../@types/types";
import { useRefAppState } from "../reducer/ref.reducer";
import { StyledButton } from "./Button";
import {
  StyledErrorMessage,
  StyledField,
  StyledForm,
  StyledLoginBox,
} from "./form-elements";

export type TLoginFormProps = {
  doLogin: TLogin;
};

const StyledHeader = styled.h1`
  font-size: 3rem;

  > span {
    font-size: 1rem;
  }
`;

export const LoginForm: FC<TLoginFormProps> = ({ doLogin }) => {
  const {
    env: { name },
  } = useRefAppState();

  return (
    <StyledLoginBox>
      <div>
        <StyledHeader>
          Sign In <span>to {name}</span>
        </StyledHeader>
        <Formik
          initialValues={{ password: "", username: "" }}
          onSubmit={({ password, username }, { setSubmitting }) => {
            doLogin({
              password,
              username,
            }).finally(() => setSubmitting(false));
          }}
          validate={(values) => {
            const errors: {
              password?: string;
              username?: string;
            } = {};
            if (!values.username) {
              errors.username = "REQUIRED";
            }

            if (!values.password) {
              errors.password = "REQUIRED";
            }

            return errors;
          }}
        >
          {({ isSubmitting }) => (
            <StyledForm>
              <StyledField
                margin="0 0 1rem 0"
                name="username"
                placeholder="Username"
                type="text"
              />
              <StyledErrorMessage component="div" name="username" />
              <StyledField
                margin="0 0 1rem 0"
                name="password"
                placeholder="Password"
                type="password"
              />
              <StyledErrorMessage component="div" name="password" />
              <StyledButton disabled={isSubmitting} type="submit">
                Submit
              </StyledButton>
            </StyledForm>
          )}
        </Formik>
      </div>
    </StyledLoginBox>
  );
};
