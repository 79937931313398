var Emitter = require('../emitter');
const { default: Log } = require('../../common/log');
var Util = require('../util');
var version = require('../version');
var PlaybackChronos = require('./playbackchronos');
var PlaybackFlags = require('./playbackflags');
var PlayheadMonitor = require('./playheadmonitor');
var StateMonitor = require('./statemonitor');
var AdapterConstants = require('../constants/adapter');
var MD5 = require('../deviceUUID/md5');
const { AnalyticsTag } = require('../../common/Constants');
const { default: Core } = require('../../core/Core');
const { default: CoreConstants } = require('../../core/utils/CoreConstants');

var Adapter = Emitter.extend(
  /** @lends npaw.this.prototype */
  {
    /**
     * Main Adapter class. All specific player adapters-json should extend this class specifying a player
     * class.
     *
     * The Adapter works as the 'glue' between the player and NPAW acting both as event
     * translator and as proxy for the {@link Plugin} to get info from the player.
     *
     * @constructs Adapter
     * @extends npaw.Emitter
     * @memberof npaw
     *
     * @param {string} videoKey
     * @param {object} videoObj
     * @param {object} plugin
     * @param {object} adapterTemplates
     * @param {object|string} player Either an instance of the player or a string containing an ID.
     * @param {string} adapterJsonCode
     */
    constructor: function (videoKey, videoObj, plugin, adapterTemplates, player, adapter) {
      /** Adapter Key */
      this._key = videoKey;

      /** Set adapter on a video object */
      this._setAdapter = false;

      /** NPAW Video Object */
      this._npawVideo = videoObj;

      /** Adapter implementation loaded */
      this._loadedAdapterImplementation = false;

      /** An instance of {@link FlagStatus} */
      this.flags = new PlaybackFlags();

      /** An instance of {@link ChronoStatus} */
      this.chronos = new PlaybackChronos();

      /** Adapter Classes */
      this._adapterClasses = {}

      /** A dictionary to store the events fired */
      this.fireEventsStruct = {};
      this.fireEventsStruct.buffer = [];
      this.fireEventsStruct.seek = [];

      /** Reference to {@link PlayheadMonitor}. Helps the plugin detect buffers/seeks. */
      this.monitor = null;

      /** Reference to {@link Plugin}. */
      this.plugin = plugin;

      /** Unregisters listener */
      if (this.player) {
        this.unregisterListeners();
      }

      /** Reference to the player tag */
      this.player = null;

      /** Defines if the adapter is used as adapter or adsAdapter */
      this._isAdsAdapter = null;

      /** Reference to log object */
      this.log = Log;
      this.log.loadLevelFromUrl();

      /** Reference to npaw object */
      this.npaw = require('../npawlib');

      // Set player
      this.setPlayer(player);

      /** Define adapter json */
      const isJsonAdapter = typeof adapter !== 'object';
      let adapterJson;
      const adapterCustomerDefined = isJsonAdapter ? JSON.parse(adapter) : undefined;

      /** Set Adapter Name */
      this._adapterName = !isJsonAdapter
        ? this.getAdapterNameFromClass(adapter)
        : this.getAdapterName(adapterCustomerDefined);

      if (isJsonAdapter && this.plugin && this.plugin.canOverwriteAdapters()) {
        adapterJson = this._getAdapterFromTemplates(this._adapterName);
        if (adapterJson) {
          Log.notice(AnalyticsTag, 'Overwrite Adapter: ' + this._adapterName);
        }
      }

      if (isJsonAdapter && !adapterJson) {
        if (!adapter) {
          adapterJson = this._getAdapterFromTemplates(this._adapterName);
        } else {
          try {
            adapterJson = adapterCustomerDefined;
          } catch (err) {
            Log.warn(AnalyticsTag, 'Problem parsing adapter json code (to object conversion)');
          }
        }
      }

      /** Reference to the video/object tag, could be the same as the player. */
      this.tag = this.player;

      if (isJsonAdapter && adapterJson) {
        // Eval adapter Json
        this.evalAdapterJson(adapterJson, false);

        // Register listeners
        this.registerListeners();
      } else if (!isJsonAdapter && adapter) {
        // Load adapter from given Javascript class
        this.loadAdapterClasses(adapter, false);
        // Register listeners
        this.registerListeners();
      }

      if (this.plugin.options.enableExtraMetricCollection) {
        this._npawVideo.fetchLocation();
      }

      Log.notice(AnalyticsTag, 'Adapter ' + this.getVersion() + ' with Lib ' + version + ' is ready.');
    },

    /**
     * Update player and plugin, and register again the listeners
     * @param player
     * @param plugin
     * @private
     */
    _updatePlayer: function (player) {
      /** Unregisters listener */
      if (this.player) {
        this.unregisterListeners();
      }

      /** Reference to the player tag */
      this.player = null;

      // Set player
      this.setPlayer(player);

      /** Reference to the video/object tag, could be the same as the player. */
      this.tag = this.player;

      // Register listeners
      this.registerListeners();
    },

    /**
     * Is set adapter to video object
     */
    isSetAdapter: function () {
      return this._setAdapter;
    },

    /**
     * Get Video Object
     */
    getVideo: function () {
      return this._npawVideo;
    },

    /**
     * Get Plugin Object
     */
    getPlugin: function () {
      return this.plugin;
    },

    /**
     * Get Npaw Utils
     * @returns {ObjectUtils|*|Utils}
     */
    getNpawUtils: function () {
      return this.plugin.utils;
    },

    /**
     * Get Npaw Reference
     */
    getNpawReference: function () {
      return this.npaw;
    },

    /**
     * Get Log
     */
    getLog: function () {
      return this.log;
    },

    /**
     * Return isStarted flag
     */
    isStarted: function () {
      if (this.flags) {
        return this.flags.isStarted;
      }
      return false;
    },

    /**
     *
     * @param className
     * @returns {null|Object|null|*|{}}
     */
    getAdapterClass: function (className) {
      var adapterClasses = this._adapterClasses;
      return adapterClasses[className] ? adapterClasses[className] : null;
    },

    /**
     *
     * @param adapterObject
     */
    getAdapterName: function (adapterObject) {
      if (adapterObject && adapterObject['adapter,getVersion']) {
        try {
          var getVersionMethod = window.Function(adapterObject['adapter,getVersion']);
          return getVersionMethod();
        } catch (e) { }
      }
      return 'undefined';
    },

    /**
     *
     * @param adapterClass
     */
    getAdapterNameFromClass: function (adapterClass) {
      if (adapterClass && adapterClass['getVersion']) {
        try {
          return adapterClass['getVersion']();
        } catch (e) { }
      }
      return 'undefined';
    },

    /**
     * Get Adapter classes
     * @returns {*|{}|{}|null}
     */
    getAdapterClasses: function () {
      return this._adapterClasses;
    },

    /**
     * Update Adapter templates, and try to override methods
     * @param adapterTemplates
     */
    updateAdapterTemplates: function (adapterTemplates) {
      if (this.plugin.canOverwriteAdapters()) {
        /** Define adapter json */
        var adapterJson = this._getAdapterFromTemplates(this._adapterName, adapterTemplates);

        if (adapterJson) {
          // Eval adapter Json
          this.evalAdapterJson(adapterJson, true);

          // Register listeners
          this.registerListeners();
        }
      }
    },

    /**
     *
     * @param adapterName
     * @param adapterTemplates
     */
    _getAdapterFromTemplates: function (adapterName, adapterTemplates) {
      var adapterJson = null;
      if (!adapterTemplates) {
        // If adapter templates is empty, get it from plugin adapter templates object
        adapterTemplates = this.plugin.adapterTemplates;
      }
      try {
        if (adapterName && adapterTemplates && adapterTemplates[adapterName.toLowerCase()]) {
          adapterJson = JSON.parse(adapterTemplates[adapterName.toLowerCase()]);
        }
      } catch (err) {
        Log.warn(AnalyticsTag, 'Adapter ' + adapterName + ' not found on repository');
      }
      return adapterJson;
    },

    /**
     *
     * @param adapterJson
     * @param loadFromTemplate
     */
    evalAdapterJson: function (adapterJson, loadFromTemplate) {
      loadFromTemplate = loadFromTemplate || false;
      var integratedAdapterMethods = 0;
      var integratedExternalMethods = 0;
      var lastEvaluatedMethod = '';
      try {
        for (var method in adapterJson) {
          var methodParts = method.split(',');
          var context = methodParts.shift();
          var methodName = methodParts.shift();
          lastEvaluatedMethod = methodName;
          var adapterFunction = null;
          if (methodParts && methodParts.length >= 1) {
            var methodArguments = methodParts;
            adapterFunction = window.Function(methodArguments, adapterJson[method]);
          } else {
            adapterFunction = window.Function(adapterJson[method]);
          }
          if (adapterFunction) {
            if (context.toLowerCase() === 'adapter') {
              this[methodName] = adapterFunction;
              integratedAdapterMethods++;
            } else {
              if (context.indexOf('.adapter') > 0) {
                context = context.replace('.adapter', '');
                if (!this.getAdapterClasses()[context]) {
                  // eslint-disable-next-line no-useless-constructor
                  this.getAdapterClasses()[context] = new Adapter(
                    this._key,
                    this._npawVideo,
                    this.getPlugin(),
                    null,
                    this.player,
                    null,
                    null,
                    {}
                  );
                }
                this.getAdapterClasses()[context][methodName] = adapterFunction;
                integratedExternalMethods++;
              } else {
                if (!this.getAdapterClasses()[context]) {
                  // eslint-disable-next-line no-useless-constructor
                  this.getAdapterClasses()[context] = {};
                  this.getAdapterClasses()[context].constructor = function () { };
                  // create util to get npaw utils and set the same adapter plugin
                  this.getAdapterClasses()[context].plugin = this.plugin;
                  this.getAdapterClasses()[context].getNpawUtils = function () {
                    return this.plugin.utils;
                  };
                }
                this.getAdapterClasses()[context][methodName] = adapterFunction;
                integratedExternalMethods++;
              }
            }
          }
        }
        this._loadedAdapterImplementation = true;
        // Set the adapter Json Code
        this._adapterEvaluatedCode = adapterJson;
        // Set the adapter Hash value
        try {
          if (typeof adapterJson === 'string') {
            this.adapterHash = MD5(adapterJson.trim());
          } else {
            this.adapterHash = MD5(JSON.stringify(adapterJson).trim());
          }
        } catch (err) {
          this.adapterHash = 'Undefined';
        }
        // Show Adapter loaded
        Log.notice(
          AnalyticsTag,
          '[' +
          this.getAdapterKey() +
          '] Loaded adapter (' +
          this.getVersion() +
          ') (Hash ' +
          this.adapterHash +
          ') ' +
          'implementation from JSON; From templates: ' +
          loadFromTemplate +
          '; Integrated adapter methods: ' +
          integratedAdapterMethods +
          ', external methods: ' +
          integratedExternalMethods
        );
      } catch (err) {
        Log.error(
          AnalyticsTag,
          "Can't be evaluated adapter json correctly; From templates: " +
          loadFromTemplate +
          ', Last method evaluated: ' +
          lastEvaluatedMethod
        );
      }
    },

    /**
     * Load the adapter provided by the Javascript class
     * @param adapterClass
     * @param loadFromTemplate
     */
    loadAdapterClasses: function (adapterClass, loadFromTemplate) {
      loadFromTemplate = loadFromTemplate || false;
      let integratedAdapterMethods = 0;
      let integratedExternalMethods = 0;
      let lastEvaluatedMethod = '';
      let prototype = Object.getPrototypeOf(adapterClass);

      try {
        let adapterString = '';
        const listOfMethods = new Map();
        while (prototype !== Object.prototype) {
          Object.getOwnPropertyNames(prototype).forEach((prop) => {
            if (typeof prototype[prop] === 'function' && prop !== 'constructor' && !listOfMethods.has(prop)) {
              listOfMethods.set(prop, prototype[prop]);
            }
          });
          prototype = Object.getPrototypeOf(prototype);
        }
        listOfMethods.forEach((value, key) => {
          this[key] = value;
          adapterString += value.toString();
          integratedAdapterMethods++;
        });
        lastEvaluatedMethod = 'Adapter Class';
        // Convert ads adapter from the video adapter into ads adapters
        if (typeof adapterClass.adsAdapters !== 'undefined') {
          for (const adsAdapter of Object.keys(adapterClass.adsAdapters)) {
            this.getAdapterClasses()[adsAdapter] = new Adapter(
              this._key,
              this._npawVideo,
              this.getPlugin(),
              null,
              this.player,
              new adapterClass.adsAdapters[adsAdapter]()
            );
            lastEvaluatedMethod = 'Ads Adapter -> ' + adsAdapter;
          }
        }
        // Convert additional video adapter contexts to functions
        if (typeof adapterClass.additionalContexts !== 'undefined') {
          for (const context of Object.keys(adapterClass.additionalContexts)) {
            if (!this.getAdapterClasses()[context]) {
              this.getAdapterClasses()[context] = {};
              this.getAdapterClasses()[context].constructor = function () { };
              this.getAdapterClasses()[context].plugin = this.plugin;
              this.getAdapterClasses()[context].getNpawUtils = function () {
                return this.plugin.utils;
              };
            }
            const contextInstance = new adapterClass.additionalContexts[context]();
            const contextPrototype = Object.getPrototypeOf(contextInstance);
            const contextMethods = Object.getOwnPropertyNames(contextPrototype).filter(
              (prop) => typeof contextInstance[prop] === 'function' && prop !== 'constructor'
            );
            for (const method of contextMethods) {
              this.getAdapterClasses()[context][method] = contextInstance[method];
              integratedExternalMethods++;
            }
          }
          lastEvaluatedMethod = 'Additional Contexts';
        }
        this._loadedAdapterImplementation = true;
        // Set the adapter Hash value
        try {
          this.adapterHash = MD5(adapterString.trim());
        } catch (err) {
          this.adapterHash = 'Undefined';
        }
        // Show Adapter loaded
        Log.notice(
          AnalyticsTag,
          '[' +
          this.getAdapterKey() +
          '] Loaded adapter (' +
          this.getVersion() +
          ') (Hash ' +
          this.adapterHash +
          ') ' +
          'implementation from class; From templates: ' +
          loadFromTemplate +
          '; Integrated adapter methods: ' +
          integratedAdapterMethods +
          ', external methods: ' +
          integratedExternalMethods
        );
      } catch (err) {
        Log.error(
          AnalyticsTag,
          "Can't be evaluated adapter class correctly; From templates: " +
          loadFromTemplate +
          ', Last method evaluated: ' +
          lastEvaluatedMethod
        );
      }
    },

    /**
     * Get Adapter Key
     * @returns {string}
     */
    getAdapterKey: function () {
      return this._key;
    },

    /**
     * Sets a new player, removes the old listeners if needed.
     *
     * @param {Object} player Player to be registered.
     */
    setPlayer: function (player) {
      if (typeof player === 'string' && typeof document !== 'undefined') {
        this.player = document.getElementById(player);
      } else {
        this.player = player;
      }
    },

    /**
     * Override to create event binders.
     * It's a good practice when implementing a new Adapter to create intermediate methods and call
     * those when player events are detected instead of just calling the `fire*` methods. This
     * will allow future users of the Adapter to customize its behaviour by overriding these
     * methods.
     *
     * @example
     * registerListeners: function () {
     *  this.player.addEventListener('start', this.onStart.bind(this))
     * },
     *
     * onStart: function (e) {
     *  this.emit('start')
     * }
     */
    registerListeners: function () { },

    /**
     * Override to create event de-binders.
     *
     * @example
     * registerListeners: function () {
     *  this.player.removeEventListener('start', this.onStart)
     * }
     */
    /** Unregister listeners to this.player. */
    unregisterListeners: function () { },

    /**
     * This function disposes the current adapter, removes player listeners and drops references.
     */
    dispose: function () {
      try {
        // Stop Monitor and Network monitor
        this.stopMonitor();
        this.stopReadyStateMonitor();
        // Unregister listener
        this.unregisterListeners();
        this.player = null;
        this.tag = null;
      } catch (err) {
        Log.error(AnalyticsTag, "Can't process dispose for video " + this._key);
      }
    },

    /**
     * Creates a new {@link PlayheadMonitor} at this.monitor.
     *
     * @param {bool} monitorBuffers If true, it will monitor buffers.
     * @param {bool} monitorSeeks If true, it will monitor seeks.
     * @param {number} [interval=800] The interval time in ms.
     */
    monitorPlayhead: function (monitorBuffers, monitorSeeks, interval) {
      this.stopMonitor();
      var type = 0;
      if (monitorBuffers) type |= PlayheadMonitor.Type.BUFFER;
      if (monitorSeeks) type |= PlayheadMonitor.Type.SEEK;

      if (!this.monitor || !this.monitor._timer.isRunning) {
        this.monitor = new PlayheadMonitor(this, type, interval);
      } else {
        this.monitor.skipNextTick();
      }
    },

    stopMonitor: function () {
      if (this.monitor) this.monitor.stop();
    },

    /**
     *
     * @param {number} [intervalMilliseconds=300]
     */
    monitorReadyState: function (intervalMilliseconds) {
      this.stopReadyStateMonitor();
      this.stateMonitor = new StateMonitor(this, intervalMilliseconds);
    },

    /**
     * Start ReadyState monitor
     */
    startReadyStateMonitor: function () {
      if (this.stateMonitor) this.stateMonitor.start();
    },

    /**
     * Stop ReadyState monitor
     */
    stopReadyStateMonitor: function () {
      if (this.stateMonitor) this.stateMonitor.stop();
    },

    /**
     * Check readyState video properties
     * @param readyState
     * @param triggeredEvent
     */
    checkReadyState: function (readyState, triggeredEvent) {
      try {
        if (this.plugin && this.plugin.getReadyStateMonitorEnabled()) {
          if (readyState) {
            if (readyState > 3 && !this.flags.isSeeking) {
              if (this.flags.isBuffering) {
                this.fireBufferEnd({}, triggeredEvent + '-readyState');
              } else if (!this.flags.isJoined) {
                this.fireJoin({}, triggeredEvent + '-readyState');
              }
            } else if (readyState < 4 && !this.flags.isBuffering && !this.flags.isPaused) {
              this.fireBufferBegin({}, false, triggeredEvent + '-readyState', true);
            }
          }
        }
      } catch (e) {
        Log.error(AnalyticsTag, "Can't check readyState property correctly");
      }
    },

    // GETTERS //

    /** Override to return current playhead of the video */
    getPlayhead: function () {
      return null;
    },

    /** Override to return video duration */
    getDuration: function () {
      return null;
    },

    /** Override to return current bitrate */
    getBitrate: function () {
      return null;
    },

    /** Override to return total downloaded bytes */
    getTotalBytes: function () {
      return null;
    },

    /** Override to return title */
    getTitle: function () {
      return null;
    },

    /** Override to return resource URL. */
    getResource: function () {
      return null;
    },

    /** Override to return player version */
    getPlayerVersion: function () {
      return null;
    },

    /** Override to return player's name */
    getPlayerName: function () {
      return null;
    },

    /** Override to return adapter version. */
    getVersion: function () {
      return version + '-generic-js';
    },

    /** Override to return video object */
    getVideoObject: function () {
      return null;
    },

    getLastUsedCdn: function () {
      return (
        Core.getInstance().getCommonVariable(
          CoreConstants.Products.BALANCER,
          CoreConstants.BalancerVariables.LAST_USED_CDN
        ) || undefined
      );
    },

    /** Override to return if player exists on page */
    checkExistsPlayer: function () {
      return true;
    },

    /**
     * Check if Object exists on Page (adapter Util)
     *
     * @param {*} object
     */
    checkExistsObjectOnPage: function (object) {
      return Util.elementIsInPage(object);
    },

    // FLOW //

    /**
     * Emits related event and set flags if current status is valid.
     * ie: won't sent init if isStarted is already true.
     *
     * @param {Object} [params] Object of key:value params to add to the request.
     * @param {string} [triggeredEvent]
     */
    fireInit: function (params, triggeredEvent) {
      // Check if player exists to don't fire an init event
      if (this._npawVideo && this._npawVideo.controlPlayerExists() && !this.checkExistsPlayer()) {
        Log.warn(AnalyticsTag, 'Cannot fire init event because player not exists on the document');
        return null;
      }
      if (this.adapterHash) {
        params = params || {};
        params.pluginHash = this.adapterHash;
      }
      if (this._npawVideo) this._npawVideo.fireInit(params, triggeredEvent);
    },

    /**
     * Emits related event and set flags if current status is valid.
     * ie: won't sent start if isStarted is already true.
     *
     * @param {Object} [params] Object of key:value params to add to the request.
     * @param {string} [triggeredEvent]
     */
    fireStart: function (params, triggeredEvent) {
      // Check if player exists to don't fire a start event
      if (this._npawVideo && this._npawVideo.controlPlayerExists() && !this.checkExistsPlayer()) {
        Log.warn(AnalyticsTag, 'Cannot fire start event because player not exists on the document');
        return null;
      }
      if (this.plugin && this.plugin.backgroundDetector && this.plugin.backgroundDetector.canBlockStartCalls()) {
        return null;
      }
      if (!this.flags.isStarted) {
        this.flags.isStarted = true;
        this.chronos.total.start();
        this.chronos.join.start();
        if (triggeredEvent) {
          params = params || {};
          params.triggeredEvents = [triggeredEvent];
        }
        if (this.adapterHash) {
          params = params || {};
          params.pluginHash = this.adapterHash;
        }
        this.startReadyStateMonitor();
        this.emit(AdapterConstants.Event.START, { params: params });
        // If logs is enabled, send adapter method too
        this.plugin._logSetAdapterEvent(this._adapterEvaluatedCode);
      }
    },

    /**
     * Emits related event and set flags if current status is valid.
     * ie: won't sent start if isStarted is already true.
     *
     * @param {Object} [params] Object of key:value params to add to the request.
     * @param {string} [triggeredEvent]
     */
    fireJoin: function (params, triggeredEvent) {
      if (
        !this.flags.isJoined &&
        !this.flags.isStarted &&
        !this._isAds() &&
        this._npawVideo &&
        this._npawVideo.isInitiated
      ) {
        this.fireStart();
      }
      if (this.flags.isStarted && !this.flags.isJoined) {
        this.flags.isStarted = true;
        if (this.monitor) this.monitor.start();
        this.flags.isJoined = true;
        this.chronos.join.stop();
        if (triggeredEvent) {
          params = params || {};
          params.triggeredEvents = [triggeredEvent];
        }
        this.emit(AdapterConstants.Event.JOIN, { params: params });
      }
    },

    /**
     * Emits related event and set flags if current status is valid.
     * ie: won't sent start if isStarted is already true.
     *
     * @param {Object} [params] Object of key:value params to add to the request.
     * @param {string} [triggeredEvent]
     */
    firePause: function (params, triggeredEvent) {
      // First, discard false buffers triggered by state changed before pause
      this._discardFalseBuffers();
      if (this.flags.isBuffering) {
        this.fireBufferEnd(null, 'firePauseCall');
      }
      if (this.flags.isJoined && !this.flags.isPaused) {
        this.flags.isPaused = true;

        this.chronos.pause.start();

        if (triggeredEvent) {
          params = params || {};
          params.triggeredEvents = [triggeredEvent];
        }

        this.emit(AdapterConstants.Event.PAUSE, { params: params });
      }
    },

    /**
     * Emits related event and set flags if current status is valid.
     * ie: won't sent start if isStarted is already true.
     *
     * @param {Object} [params] Object of key:value params to add to the request.
     * @param {string} [triggeredEvent]
     */
    fireResume: function (params, triggeredEvent) {
      // First, discard false buffers triggered by state changed before resume
      this._discardFalseBuffers();
      if (this.flags.isJoined && this.flags.isPaused) {
        this.flags.isPaused = false;

        // If it can ignore pause small events, and pause duration is less than 50 millis, reset pause chronos
        try {
          if (this._npawVideo.ignorePauseSmallEvents() && this.chronos.pause.getDeltaTime(false) <= 50) {
            this.chronos.pause.reset();
          } else {
            this.chronos.pause.stop();
          }
        } catch (e) {
          this.chronos.pause.stop();
        }

        if (this.monitor) this.monitor.skipNextTick();

        if (triggeredEvent) {
          params = params || {};
          params.triggeredEvents = [triggeredEvent];
        }

        this.emit(AdapterConstants.Event.RESUME, { params: params });
      }
    },

    /**
     * Emits related event and set flags if current status is valid.
     * ie: won't sent start if isStarted is already true.
     *
     * @param {Object} [params] Object of key:value params to add to the request.
     * @param {boolean} [convertFromSeek=false] If true, will convert current seek to buffer.
     * @param {string} [triggeredEvent]
     * @param {boolean} [triggeredByStateProperty]
     */
    fireBufferBegin: function (params, convertFromSeek, triggeredEvent, triggeredByStateProperty) {
      triggeredByStateProperty = triggeredByStateProperty || false;
      if (this.flags.isJoined && !this.flags.isBuffering) {
        if (this.flags.isSeeking) {
          if (convertFromSeek) {
            Log.notice(AnalyticsTag, 'Converting current buffer to seek');

            this.chronos.buffer = this.chronos.seek.clone();
            this.chronos.seek.reset();

            this.flags.isSeeking = false;
          } else {
            return;
          }
        } else {
          this.chronos.buffer.start();
        }

        try {
          // Create new buffer empty structure to add event to detect buffer begins
          this.fireEventsStruct.buffer = [];
          if (triggeredEvent) {
            this.fireEventsStruct.buffer.push(triggeredEvent);
          } else {
            this.fireEventsStruct.buffer.push('undefinedEvent');
          }
        } catch (e) { }

        this.flags.isBuffering = true;
        this.flags.isVideoStateBuffering = triggeredByStateProperty;
        this.emit(AdapterConstants.Event.BUFFER_BEGIN, { params: params });
      }
    },

    /**
     * Emits related event and set flags if current status is valid.
     * ie: won't sent start if isStarted is already true.
     *
     * @param {Object} [params] Object of key:value params to add to the request.
     * @param {string} [triggeredEvent]
     */
    fireBufferEnd: function (params, triggeredEvent) {
      if (this.flags.isJoined && this.flags.isBuffering) {
        try {
          // Add triggered event to detect buffer ends
          if (triggeredEvent) {
            this.fireEventsStruct.buffer.push(triggeredEvent);
          } else {
            this.fireEventsStruct.buffer.push('undefinedEvent');
          }
        } catch (e) { }

        params = params || {};
        params.triggeredEvents = this.fireEventsStruct.buffer;

        this.cancelBuffer();
        this.emit(AdapterConstants.Event.BUFFER_END, { params: params });

        try {
          if (this.chronos.pause.getDeltaTime(false) > 0) {
            this.chronos.pause.resume();
          }
        } catch (e) { }
      }
    },

    /**
     * Discard false buffer events from state changed
     * @private
     */
    _discardFalseBuffers: function () {
      try {
        if (this.flags.isBuffering && this.flags.isVideoStateBuffering && this._getDeltaBufferTime() <= 100) {
          this.cancelBuffer();
        }
      } catch (e) { }
    },

    /**
     *
     * @param {Object} [params] Object of key:value params to add to the request.
     */
    cancelBuffer: function (params) {
      if (this.flags.isJoined && this.flags.isBuffering) {
        this.flags.isBuffering = false;
        this.flags.isVideoStateBuffering = false;

        this.chronos.buffer.stop();

        if (this.monitor) this.monitor.skipNextTick();
      }
    },

    /**
     * Get Delta buffer time
     * @returns {number|number|*}
     * @private
     */
    _getDeltaBufferTime: function () {
      if (this.chronos && this.chronos.buffer) {
        return this.chronos.buffer.getDeltaTime(false);
      }
      return 0;
    },

    /**
     * Emits related event and set flags if current status is valid.
     * ie: won't sent start if isStarted is already true.
     *
     * @param {Object} [params] Object of key:value params to add to the request.
     * @param {string} [triggeredEvent]
     */
    fireStop: function (params, triggeredEvent) {
      try {
        if (this._isAds() || this._npawVideo._isStopReady()) {
          if (
            (this._isAds() && this.flags.isStarted) ||
            (!this._isAds() && (this.flags.isStarted || this._npawVideo.isInitiated))
          ) {
            // Stop Monitor and Network monitor
            this.stopMonitor();
            this.stopReadyStateMonitor();

            if (this._isAds()) {
              params = params || {};
              const isPaused = this.flags.isPaused;

              if (isPaused) {
                const pauseTime = this.chronos.pause.getDeltaTime();
                params.adPauseDuration = pauseTime;
              }
            }
            this.flags.reset();
            this.chronos.total.stop();
            this.chronos.join.reset();
            this.chronos.pause.stop();
            this.chronos.buffer.stop();
            this.chronos.seek.stop();

            if (triggeredEvent) {
              params = params || {};
              params.triggeredEvents = [triggeredEvent];
            }

            this.emit(AdapterConstants.Event.STOP, { params: params });

            this.chronos.pause.reset();
            this.chronos.buffer.reset();
            this.chronos.seek.reset();
            this.chronos.viewedMax.splice(0, this.chronos.viewedMax.length);
          }
        }
      } catch (err) {
        Log.warn(AnalyticsTag, 'Issue firing Stop event');
      }
    },

    /**
     *
     * @param playerEvent
     * @param playerData
     */
    firePlayerLog: function (playerEvent, playerData) {
      if (this._npawVideo) {
        var params = {
          logs: playerData,
          logAction: playerEvent,
          logType: 'playerEvent'
        };
        this._npawVideo.firePlayerLog(params);
      }
    },

    setIsAds: function (value) {
      this._isAdsAdapter = value;
    },

    _isAds: function () {
      return this._isAdsAdapter;
    },

    /**
     * @param {Object} [params] Object of key:value params to add to the request.
     * @param {string} [triggeredEvent]
     */
    fireCasted: function (params, triggeredEvent) {
      if (!params) params = {};
      params.casted = true;
      this.fireStop(params, triggeredEvent);
    },

    /**
     * Emits related event and set flags if current status is valid.
     * ie: won't sent start if isStarted is already true.
     *
     * @param {String|Object} [code] Error Code, if an object is sent, it will be treated as params.
     * @param {String} [msg] Error Message
     * @param {Object} [metadata] Object defining error metadata
     * @param {String} [level] Level of the error. (Deprecated parameter)
     * @param {string} [triggeredEvent]
     * @param {boolean} [fatalError] Indicate if is categorized as fatalError
     */
    fireError: function (code, msg, metadata, level, triggeredEvent, fatalError) {
      let params = Util.buildErrorParams(code, msg, metadata);
      if (params.code) {
        delete params.code;
      }
      if (triggeredEvent) {
        params = params || {};
        params.triggeredEvents = [triggeredEvent];
      }
      const options = this._npawVideo ? this._npawVideo.options : {};
      if (
        typeof params.errorCode !== 'undefined' &&
        options['errors.ignore'] &&
        options['errors.ignore'].indexOf(params.errorCode.toString()) > -1
      ) {
        // ignore error
      } else {
        fatalError = fatalError || false;
        // only if is detected as fatal, and view is joined (with join time), send errorType fatal
        if (
          fatalError ||
          (typeof params.errorCode !== 'undefined' &&
            options['errors.fatal'] &&
            options['errors.fatal'].indexOf(params.errorCode.toString()) > -1)
        ) {
          if (this.flags.isJoined) {
            params = params || {};
            params.errorType = 'fatal';
          }
        }
        this.emit(AdapterConstants.Event.ERROR, { params: params });
      }
    },

    /**
     * Emits related event and set flags if current status is valid.
     * ie: won't sent start if isStarted is already true.
     *
     * @param {String|Object} [code] Error Code, if an object is sent, it will be treated as params.
     * @param {String} [msg] Error Message
     * @param {Object} [metadata] Object defining error metadata
     * @param {String} [level] Level of the error. (Deprecated parameter)
     * @param {string} [triggeredEvent]
     */
    fireFatalError: function (code, msg, metadata, level, triggeredEvent) {
      this.fireError(code, msg, metadata, level, triggeredEvent, true);
    },

    /**
     * Checks if legacy buffer behaviour is enabled.
     *
     * This method checks the options object from the `_npawVideo` property to see if `enableLegacyBufferBehaviour`
     * is defined. If it exists and is truthy, it returns that value. Otherwise, it returns `false`.
     *
     * @returns {boolean} - Returns the value of `options['enableLegacyBufferBehaviour']` if it exists and is truthy,
     *                      otherwise returns `false`.
     **/
    isLegacyBufferBehaviourEnabled: function () {
      const options = this._npawVideo ? this._npawVideo.options : {};
      const enableLegacyBufferBehaviour = options['enableLegacyBufferBehaviour'] || false;
      return enableLegacyBufferBehaviour;
    }
  },
  {
    /** @lends npaw.Adapter */
    // Static Memebers //

    /**
     * List of events that could be fired
     * @enum
     * @event
     */
    Event: AdapterConstants.Event
  }
);

Util.assign(Adapter.prototype, require('./adapter+ads'));
Util.assign(Adapter.prototype, require('./adapter+content'));

module.exports = Adapter;
