import {
  NielsenTracker,
  PlaybackEventTypes,
  TTrackerConfiguration,
} from "@telia-company/tv.web-playback-sdk";
import {
  TEventMethods,
  TSeekRangeUpdateEvent,
  WebPlayerEventType,
} from "@telia-company/tv.web-player-shared";

export const getNielsenTracker = (
  options: TTrackerConfiguration,
  event: TEventMethods
) => {
  const nlsn = new NielsenTracker(options);

  const seekRangeHandler = (e: TSeekRangeUpdateEvent) => {
    if (typeof e.payload.seekRange?.end === "number") {
      nlsn.setSeekRangeEnd(e.payload.seekRange.end);

      event.off(WebPlayerEventType.SEEK_RANGE_UPDATE, seekRangeHandler);
    }
  };

  const loadedHandler = () => {
    event.off(PlaybackEventTypes.LOADED, loadedHandler);

    event.on(WebPlayerEventType.SEEK_RANGE_UPDATE, seekRangeHandler);
  };

  event.on(PlaybackEventTypes.LOADED, loadedHandler);

  return nlsn;
};
