import { ServiceCountry } from "../../../../base";

export enum NielsenEvent {
  LOAD_METADATA = "loadMetadata",
  SET_PLAYHEAD_POSITION = "setPlayheadPosition",
  STOP = "stop",
  END = "end",
}

export enum NielsenBreakType {
  PREROLL = "preroll",
  MIDROLL = "midroll",
  POSTROLL = "postroll",
  UNKNOWN = "ad",
}

export type NielsenContentMetadataSweden = {
  type: string;
  userid?: string;
  assetid: string;
  length: string;
  clientpassparam?: string;
  program: string;
  title: string;
  playlisttitle?: string;
  ispremiumcontent: string;
  isautoplay: string;
  plugv: string;
  playerv: string;
};

export type NielsenContentMetadataDenmark = {
  adloadtype: string;
  airdate: string;
  assetid: string;
  isfullepisode: string;
  islivestn: string;
  length: string;
  pbstarttm: string;
  program: string;
  stationId: string;
  subbrand: string;
  title: string;
  type: string;
};

export type NielsenContentMetadata =
  | NielsenContentMetadataSweden
  | NielsenContentMetadataDenmark;

export type NielsenAdMetadata = {
  type: NielsenBreakType;
  assetid: string;
  length: string;
  isprogrammatic?: string;
  isthirdpartyad?: string;
  adplatformorigin?: string;
  adidx: string;
};

export type NielsenInstance = {
  ggPM: (
    event: NielsenEvent,
    parameter: number | NielsenContentMetadata | NielsenAdMetadata
  ) => void;
};

export type NOLBUNDLE = {
  nlsQ: (
    appId: string,
    instanceName: string,
    options?: {
      nol_sdkDebug?: string;
      hem_unknown?: string;
    }
  ) => NielsenInstance;
};

function loadSwedishSdk() {
  (function (t, n) {
    // @ts-ignore
    t[n] = t[n] || {
      // @ts-ignore
      nlsQ: function (e, o, c, r, s, i) {
        return (
          (s = t.document),
          (r = s.createElement("script")),
          (r.async = 1),
          (r.src =
            ("http:" === t.location.protocol ? "http:" : "https:") +
            "//cdn-gl.imrworldwide.com/conf/" +
            e +
            ".js#name=" +
            o +
            "&ns=" +
            n),
          (i = s.getElementsByTagName("script")[0]),
          i.parentNode.insertBefore(r, i),
          // @ts-ignore
          (t[n][o] = t[n][o] || {
            g: c || {},
            // @ts-ignore
            ggPM: function (e, c, r, s, i) {
              // @ts-ignore
              (t[n][o].q = t[n][o].q || []).push([e, c, r, s, i]);
            },
          }),
          // @ts-ignore
          t[n][o]
        );
      },
    };
  })(window, "NOLBUNDLE");
}

function loadDanishSdk() {
  (function (e, n) {
    // @ts-ignore
    function t(e) {
      return "object" == typeof e ? JSON.parse(JSON.stringify(e)) : e;
    }
    // @ts-ignore
    e[n] = e[n] || {
      // @ts-ignore
      nlsQ: function (o, r, c) {
        var s = e.document,
          a = s.createElement("script");
        // @ts-ignore
        (a.async = 1),
          (a.src =
            ("http:" === e.location.protocol ? "http:" : "https:") +
            "//cdn-gl.nmrodam.com/conf/" +
            o +
            ".js#name=" +
            r +
            "&ns=" +
            n);
        var i = s.getElementsByTagName("script")[0];
        return (
          // @ts-ignore
          i.parentNode.insertBefore(a, i),
          // @ts-ignore
          (e[n][r] = e[n][r] || {
            g: c || {},
            // @ts-ignore
            ggPM: function (o, c, s, a, i) {
              // @ts-ignore
              e[n][r].q = e[n][r].q || [];
              try {
                var l = t([o, c, s, a, i]);
                // @ts-ignore
                e[n][r].q.push(l);
              } catch (e) {
                console &&
                  console.log &&
                  console.log(
                    "Error: Cannot register event in Nielsen SDK queue."
                  );
              }
            },
            // @ts-ignore
            trackEvent: function (o) {
              // @ts-ignore
              e[n][r].te = e[n][r].te || [];
              try {
                var c = t(o);
                // @ts-ignore
                e[n][r].te.push(c);
              } catch (e) {
                console &&
                  console.log &&
                  console.log(
                    "Error: Cannot register event in Nielsen SDK queue."
                  );
              }
            },
          }),
          // @ts-ignore
          e[n][r]
        );
      },
    };
  })(window, "NOLBUNDLE");
}

export function getSDK(serviceCountry: ServiceCountry): NOLBUNDLE {
  if (serviceCountry === ServiceCountry.DENMARK) {
    loadDanishSdk();
  } else if (serviceCountry === ServiceCountry.SWEDEN) {
    loadSwedishSdk();
  }
  return (window as any).NOLBUNDLE;
}
