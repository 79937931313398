(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("bowser"));
	else if(typeof define === 'function' && define.amd)
		define("OnePlaybackSdk", ["bowser"], factory);
	else if(typeof exports === 'object')
		exports["OnePlaybackSdk"] = factory(require("bowser"));
	else
		root["OnePlaybackSdk"] = factory(root["bowser"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__7970__) => {
return 