import { PlaybackEventTypes, StreamCueTypes } from "../Constants";
import { PlaybackEventBase } from "./PlaybackEventBaseClass";

export type TStreamCueEventPayload = {
  type: StreamCueTypes;
  parsed: any; // Id3Cue | Id3InEmsgCue | EmsgCue | Scte35Cue
  raw: Uint8Array;
  endTime?: number;
  eventDuration?: number;
  id?: number;
  presentationTimeDelta?: number;
  schemeIdUri?: string;
  startTime?: number;
  timescale?: number;
  value?: any;
};

export class StreamCueEvent extends PlaybackEventBase<
  PlaybackEventTypes.STREAM_CUE,
  TStreamCueEventPayload
> {
  constructor(payload: TStreamCueEventPayload) {
    super(PlaybackEventTypes.STREAM_CUE, payload);
  }
}
