import { ComponentType } from "react";

import Cast from "./icons/cast.svg";
import Checkmark from "./icons/checkmark.svg";
import ChevronDown from "./icons/chevron_down.svg";
import ChevronLeft from "./icons/chevron_left.svg";
import ChevronRight from "./icons/chevron_right.svg";
import Close from "./icons/close.svg";
import Fullscreen from "./icons/fullscreen.svg";
import HeartFill from "./icons/heart_fill.svg";
import LanguageSettings from "./icons/language_settings.svg";
import Minify from "./icons/minify.svg";
import Minimize from "./icons/minimize.svg";
import Pause from "./icons/pause.svg";
import PlayDark from "./icons/play_dark.svg";
import Play from "./icons/play.svg";
import RecordingActive from "./icons/recording_active.svg";
import RecordingFill from "./icons/recording_fill.svg";
import RecordingSeriesFill from "./icons/recording_series_fill.svg";
import RecordingSeries from "./icons/recording_series.svg";
import Recording from "./icons/recording.svg";
import RelatedContent from "./icons/related_content.svg";
import ReturnToLive from "./icons/return_to_live.svg";
import SkipBack from "./icons/skip_back.svg";
import SkipForward from "./icons/skip_forward.svg";
import Spinner from "./icons/spinner.svg";
import Startover from "./icons/startover.svg";
import VolumeLow from "./icons/volume_low.svg";
import VolumeOff from "./icons/volume_off.svg";
import Volume from "./icons/volume.svg";

const IconChromecast: ComponentType = Cast;
const IconCheck: ComponentType = Checkmark;
const IconNavigateLeft: ComponentType = ChevronLeft;
const IconNavigateRight: ComponentType = ChevronRight;
const IconMinimize: ComponentType = ChevronDown;
const IconCloseOverlay: ComponentType = Close;
const IconEnterFullscreen: ComponentType = Fullscreen;
const IconLanguageSettings: ComponentType = LanguageSettings;
const IconPictureInPicture: ComponentType = Minify;
const IconExitFullscreen: ComponentType = Minimize;
const IconPause: ComponentType = Pause;
const IconPlay: ComponentType = Play;
const IconPlayDark: ComponentType = PlayDark;
const IconRelatedContent: ComponentType = RelatedContent;
const IconRecording: ComponentType = Recording;
const IconRecordingSeries: ComponentType = RecordingSeries;
const IconRecordingActive: ComponentType = RecordingActive;
const IconRecordingFill: ComponentType = RecordingFill;
const IconRecordingSeriesFill: ComponentType = RecordingSeriesFill;
const IconJumpToLive: ComponentType = ReturnToLive;
const IconSkipBack: ComponentType = SkipBack;
const IconSkipForward: ComponentType = SkipForward;
const IconSpinner: ComponentType = Spinner;
const IconStartOver: ComponentType = Startover;
const IconVolumeMedium: ComponentType = Volume;
const IconVolumeLow: ComponentType = VolumeLow;
const IconVolumeOff: ComponentType = VolumeOff;
const IconHeartFill: ComponentType = HeartFill;

export {
  IconCheck,
  IconChromecast,
  IconCloseOverlay,
  IconEnterFullscreen,
  IconExitFullscreen,
  IconHeartFill,
  IconJumpToLive,
  IconLanguageSettings,
  IconMinimize,
  IconNavigateLeft,
  IconNavigateRight,
  IconPause,
  IconPictureInPicture,
  IconPlay,
  IconPlayDark,
  IconRecording,
  IconRecordingActive,
  IconRecordingFill,
  IconRecordingSeries,
  IconRecordingSeriesFill,
  IconRelatedContent,
  IconSkipBack,
  IconSkipForward,
  IconSpinner,
  IconStartOver,
  IconVolumeLow,
  IconVolumeMedium,
  IconVolumeOff,
};
