import styled from "@emotion/styled";
import { FC } from "react";

const sharedTimerStyles = `
  align-self: center;
  justify-self: center;
  overflow: hidden;

  > span {
    overflow: visible;
  }
`;

const RightTimerPosition = styled.div<TTimerStyleProps>`
  grid-column: ${({ smallLayout }) => (smallLayout ? "13 / -1" : "span 1")};
  ${sharedTimerStyles}
`;

const LeftTimerPosition = styled.div<TTimerStyleProps>`
  grid-column: span 1;
  ${sharedTimerStyles}
`;

export type TTimerStyleProps = {
  smallLayout: boolean;
};

export type TTimerProps = {
  text: string;
} & TTimerStyleProps;

const TimeDisplay = styled.span`
  width: auto;
  font-size: 1em;
  height: 1em;
  line-height: 1;
`;

export const LeftTimer: FC<TTimerProps> = ({ smallLayout, text }) => (
  <LeftTimerPosition smallLayout={smallLayout}>
    <TimeDisplay>
      <span>{text}</span>
    </TimeDisplay>
  </LeftTimerPosition>
);

export const RightTimer: FC<TTimerProps> = ({ smallLayout, text }) => (
  <RightTimerPosition smallLayout={smallLayout}>
    <TimeDisplay>
      <span>{text}</span>
    </TimeDisplay>
  </RightTimerPosition>
);
