import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { IconButton } from "~/components/IconButton";

const StyledContainer = styled.div`
  position: relative;
`;

export type StyledContentProps = {
  offsetTop: number;
};

const StyledContent = styled.div<StyledContentProps>`
  padding: 24px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(40px);
  border-radius: 6px;
  display: flex;
  width: min-content;
  position: absolute;

  ${({ offsetTop }) => css`
    top: calc(${offsetTop}px - 16px);
    right: calc(-100% + 8px);
  `}
`;

export type PopupProps = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
};

export const Popup: React.FC<PopupProps> = ({
  children,
  className,
  disabled,
  Icon,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [mouseEntered, setMouseEntered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [contentOffsetTop, setContentOffsetTop] = useState(0);

  useLayoutEffect(() => {
    if (containerRef.current && contentRef?.current) {
      const calculatedOffsetTop = -(
        contentRef.current.offsetHeight + containerRef.current.offsetTop
      );
      setContentOffsetTop(calculatedOffsetTop);
    }
  }, [isOpen]);

  useEffect(() => {
    const element = contentRef?.current;

    if (!element || !isOpen || disabled) return () => undefined;

    const onMouseEnterHandler = () => {
      setMouseEntered(true);
    };

    const onMouseLeaveHandler = () => {
      if (mouseEntered) {
        setMouseEntered(false);
        setIsOpen(false);
      }
    };

    const onClickHandler = (event: MouseEvent | TouchEvent) => {
      if (!containerRef.current?.contains(event.target as Element)) {
        setIsOpen(false);
      }
    };

    const isTouchDevice = "ontouchstart" in window;

    const onClickEventType = isTouchDevice ? "touchstart" : "mousedown";
    const onEnterEventType = isTouchDevice ? "touchenter" : "mouseenter";
    const onLeaveEventType = isTouchDevice ? "touchleave" : "mouseleave";

    document.addEventListener(onClickEventType, onClickHandler);
    element.addEventListener(onEnterEventType, onMouseEnterHandler);
    element.addEventListener(onLeaveEventType, onMouseLeaveHandler);

    return () => {
      document.removeEventListener(onClickEventType, onClickHandler);
      element.removeEventListener(onEnterEventType, onMouseEnterHandler);
      element.removeEventListener(onLeaveEventType, onMouseLeaveHandler);
    };
  }, [isOpen, mouseEntered, disabled]);

  const onClickButtonHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <StyledContainer className={className} ref={containerRef}>
      <IconButton disabled={disabled} onClick={onClickButtonHandler}>
        <Icon />
      </IconButton>
      {isOpen && (
        <StyledContent offsetTop={contentOffsetTop} ref={contentRef}>
          {children}
        </StyledContent>
      )}
    </StyledContainer>
  );
};
