/* global  atv, XMLHttpRequest, ActiveXObject */
var NpawObject = require('../object')
const { default: Log, LogLevel } = require('../../common/log')
var Util = require('../util')
const { AnalyticsTag } = require('../../common/Constants');

var YBRequest = NpawObject.extend(
  /** @lends npaw.YBRequest.prototype */
  {
    /**
     * YBYBRequest class will wrap XmlHttpRequest and extend its functionality, allowing npaw
     * to manage queues, blockers and retries.
     *
     * @constructs YBRequest
     * @extends npaw.Object
     * @memberof npaw
     *
     * @param {string} host URL of the request. ie: lma.npaw.com
     * @param {string} [service] Name of the service. ie '/start'
     * @param {Object} [params] Object of key:value params.
     * @param {Object} [options] Object with custom options.
     * @param {string} [options.method="GET"] Specifies the method of the request. ie: "GET", "HEAD".
     * @param {string} [options.requestHeaders] Object with options of requestHeaders.
     * ie: { header: value }.
     * @param {number} [options.retryAfter=5000] Time in ms before sending a failed request again.
     * 0 to disable.
     * @param {number} [options.maxRetries=3] Max number of retries. 0 to disable.
     * @param {bool} [options.cache=false] If false, timestamp will be added to each request to
     * @param videoKey
     * prevent caching.
     */
    constructor: function (host, service, params, options, videoKey) {
      /** Set VideoKey if exists */
      this.videoKey = videoKey

      /** Instance of XmlHttpRequest (or the item returned by createXHR method). */
      this.xhr = this.createXHR()

      /** Host of the request */
      this.host = host || ''

      /** Service of the request */
      this.service = service || ''

      /** Object of params of the request */
      this.params = params || {}

      /** Options of the current request */
      this.options = Util.assign({}, YBRequest.defaultOptions, options)

      /** Number of times this request has failed and retried. */
      this.retries = 0

      /** Define if plugin has enabled to send requests with POST method */
      this.sendPostRequest = false
      this.preparedPostRequest = false

      // Add timemark
      if (!this.options.cache && !this.params.timemark) {
        this.setParam('timemark', new Date().getTime())
      }
    },

    /**
     * Creates XMLHttpRequest if it is available in the browser.
     * If not, it tries to create an ActiveXObject XMLHTTP item.
     * Override this function for custom environments.
     *
     * @return YBRequest handler.
     */
    createXHR: function () {
      var xhr = {}
      try {
        if (XMLHttpRequest) {
          xhr = new XMLHttpRequest()
        } else {
          xhr = new ActiveXObject('Microsoft.XMLHTTP')
        }
      } catch (err) {
        Log.error(AnalyticsTag, err)
      }
      return xhr
    },

    /**
     * Returns xhr object.
     *
     * @return {XmlHttpRequest} object.
     */
    getXHR: function () {
      return this.xhr
    },

    getResponse: function () {
      return this.xhr.response
    },

    getResponseText: function () {
      return this.xhr.responseText
    },

    getResponseHeaders: function () {
      return this.xhr.getAllResponseHeaders()
    },

    /** Returns the complete formed url of the request url+service+params. */
    getUrl: function () {
      return this.host + this.service + this.getParamString()
    },

    /** Returns video Key */
    getVideoKey: function () {
      return this.videoKey
    },

    /**
     * Wraps this.getHXR.addEventListener.
     * Accepts a callback that receives (this YBRequest, event)
     */
    on: function (event, callback, callbackParams) {
      if (this.xhr.addEventListener) {
        this.xhr.addEventListener(event, callback.bind(this, this, callbackParams))
      } else {
        if (event === YBRequest.Event.SUCCESS) {
          this.xhr.onreadystatechange = function () {
            if (this.xhr.readyState === 4) {
              callback.bind(this, this, callbackParams)
            }
          }.bind(this)
        }
      }
      return this
    },

    /** Wraps this.getHXR.removeEventListener */
    off: function (event, callback) {
      this.xhr.removeEventListener(event, callback)
      return this
    },

    /**
     * Returns the params of the request, stringified.
     * ie: '?pluginVersion=5.1.0&systemCode=nicetv'.
     * @return {string} Concatenated Params
     */
    getParamString: function () {
      try {
        var params = '?'
        for (var key in this.params) {
          var param = this.params[key]
          if (param !== null && typeof param === 'object') {
            var string = JSON.stringify(param)
            if (string !== '{}') {
              params += encodeURIComponent(key) + '=' + encodeURIComponent(string) + '&'
            }
          } else if (param !== null && typeof param !== 'undefined' && param !== '') {
            params += encodeURIComponent(key) + '=' + encodeURIComponent(param) + '&'
          }
        }
        return params.slice(0, -1)
      } catch (err) {
        Log.error(AnalyticsTag, err)
        return ''
      }
    },

    /**
     * Returns the value of the given param, or undefined.
     * @param {string} key Name of the param.
     * @return {any}
     */
    getParam: function (key) {
      return this.params[key]
    },

    /**
     * Add or set a parameter for the request.
     * ie: if you want to add 'username=user' use setParam('username', 'user').
     * @param {string} key Name of the param.
     * @param {string} value Name of the param.
     * @return this
     */
    setParam: function (key, value) {
      this.params[key] = value
      return this
    },

    setBody: function (body) {
      this.body = body
    },

    /**
     * Set if is enabled send requests with POST method
     * @param {boolean} sendPostRequest
     */
    setPostRequest: function (sendPostRequest) {
      this.sendPostRequest = sendPostRequest
    },

    /**
     * Create Params object with the basic get params we need to send to the Data Collector nodes
     * @returns {{}}
     */
    getParamsForPostMessages: function () {
      // Creating empty params object
      var params = {}
      try {
        // Get previous params
        var timemark = this.getParam('timemark')
        var code = this.getParam('code')
        var sessionRoot = this.getParam('sessionRoot')
        var sessionId = this.getParam('sessionId')
        // Adding params
        if (timemark) {
          params.timemark = timemark
        }
        if (code) {
          params.code = code
        }
        if (sessionRoot) {
          params.sessionRoot = sessionRoot
        }
        if (sessionId) {
          params.sessionId = sessionId
        }
      } catch (err) {
        Log.error(AnalyticsTag, err)
      }
      return params
    },

    /**
     * Sends the request.
     *
     * @return returns xhr.send()
     */
    send: function () {
      try {
        try {
          if (this.sendPostRequest && !this.preparedPostRequest) {
            this.options.method = 'POST'
            this.body = JSON.stringify(this.params)
            this.params = this.getParamsForPostMessages()
            this.preparedPostRequest = true
          }
        } catch (err) {}

        this.xhr.open(this.options.method, this.getUrl(), true)

        // Add custom headers
        if (this.options.requestHeaders) {
          for (var key in this.options.requestHeaders) {
            if (this.options.requestHeaders.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
              this.xhr.setRequestHeader(key, this.options.requestHeaders[key])
            }
          }
        }

        // Add retries system
        if (this.options.retryAfter > 0 && this.options.maxRetries > 0) {
          var genericError = function () {
            if (this.retries >= this.options.maxRetries) {
              Log.error(AnalyticsTag, 'Aborting failed request "' + this.service + '". Max retries reached.')
            } else {
              Log.warn(AnalyticsTag, 'YBRequest "' + this.service + '" failed. Retry ' + (this.retries + 1) + ' of ' +
                this.options.maxRetries + ' in ' + this.options.retryAfter + 'ms.')
              try {
                setTimeout(function () {
                  this.retries += 1
                  this.send()
                }.bind(this), this.options.retryAfter)
              } catch (err) {
                if (typeof atv !== 'undefined') {
                  atv.setTimeout(function () {
                    this.retries += 1
                    this.send()
                  }.bind(this), this.options.retryAfter)
                } else {
                  Log.error(AnalyticsTag, err)
                }
              }
            }
          }
          if (this.retries === 0) {
            this.on(YBRequest.Event.ERROR, genericError.bind(this))
          }
        }

        // Log XHR
        if (Log.logLevel <= LogLevel.VERBOSE) {
          Log.verbose(AnalyticsTag, 'XHR Req: ' + this.getUrl())
        }

        // Register 'on every' listeners
        for (var event in YBRequest._globalListeners) {
          YBRequest._globalListeners[event].forEach(function (callback) {
            this.on(event, callback)
          }.bind(this))
        }

        // Send
        return this.xhr.send(this.body)
      } catch (err) {
        Log.error(AnalyticsTag, err)
      }
    }
  },

  /** @lends npaw.YBRequest */
  {
    /**
     * List of events that could be fired from XHR
     * @enum
     */
    Event: {
      /** Request successful */
      SUCCESS: 'load',
      /** Request successful */
      LOAD: 'load', // Native JS
      /** Request returned error */
      ERROR: 'error',
      /** Request aborted */
      ABORT: 'abort'
    },

    /**
     * This static property includes the default values for the options. In case you do not define
     * one of them in the constructor, these values will be used instead.
     *
     * @param {string} [defaultOptions.method="GET"] Specifies the method of the request.
     * ie: "GET", "HEAD".
     * @param {string} [defaultOptions.requestHeaders] Object with options of requestHeaders.
     * ie: { header: value }.
     * @param {number} [defaultOptions.retryAfter=5000] Time in ms before sending a failed request
     * again. 0 to disable.
     * @param {number} [defaultOptions.maxRetries=3] Max number of retries. 0 to disable.
     * @param {bool} [defaultOptions.cache=false] If false, timemark will be added to each request
     * to prevent caching.
     */
    defaultOptions: {
      method: 'GET',
      requestHeaders: {},
      maxRetries: 3,
      retryAfter: 5000,
      cache: false
    },

    /** @private */
    _globalListeners: {},

    /**
     * Adds a callback to every instance.
     * @param {string} event Name of the event.
     * @param {function} callback Callback of the event.
     */
    onEvery: function (event, callback) {
      YBRequest._globalListeners[event] = YBRequest._globalListeners[event] || []
      YBRequest._globalListeners[event].push(callback)
    },

    /**
     * Removes a global callback
     * @param {string} event Name of the event.
     * @param {function} callback Callback of the event.
     */
    offEvery: function (event, callback) {
      if (YBRequest._globalListeners[event]) {
        var index = YBRequest._globalListeners[event].indexOf(callback)
        if (index !== -1) {
          YBRequest._globalListeners[event].splice(index, 1)
        }
      }
    }
  })

module.exports = YBRequest
