import * as React from "react";

function SvgPlay(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M6 4.721V27.28c0 1.346 1.434 2.17 2.544 1.46L26.21 17.462c1.052-.672 1.052-2.25 0-2.922L8.544 3.26C7.434 2.551 6 3.375 6 4.721z"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgPlay;
