import { PlaybackSpec, SdkVideoIdType } from "../base";

export type TAreYouStillWatchingOptions = {
  closeStreamCb: () => void;
  idleWarningCb: () => void;
  resetWarningCb: () => void;
};

export type TStartOptions = {
  playbackSpec: PlaybackSpec;
  config: {
    idleTimeVodMs: number;
    idleTimeLinearMs: number;
    gracePeriodMs: number;
  };
};

// Will run the provided callback function after idleTime has been reached
export const stillWatchingTimerUtility = (
  options: TAreYouStillWatchingOptions
) => {
  const { idleWarningCb, closeStreamCb, resetWarningCb } = options;
  let idleTimer: number | undefined;
  let closeStreamTimer: number | undefined;
  let isChannel: boolean;
  let idleTimeoutMs: number;
  let gracePeriodMs: number;
  let pendingIdleWarning = false;
  let hasEventListeners = false;

  const startIdleTimer = () =>
    window.setTimeout(() => {
      if (isChannel) {
        idleWarningCb();
        closeStreamTimer = window.setTimeout(closeStreamCb, gracePeriodMs);
        idleTimer = undefined;
      } else {
        pendingIdleWarning = true;
      }
    }, idleTimeoutMs);

  const resetTimer = () => {
    if (closeStreamTimer) {
      // we have a pending warning, use callback to tell ui it is safe to clear it
      resetWarningCb();
    }
    clearTimeout(idleTimer);
    clearTimeout(closeStreamTimer);
    closeStreamTimer = undefined;
    pendingIdleWarning = false;
    idleTimer = startIdleTimer();
  };

  const stop = () => {
    clearTimeout(idleTimer);
    clearTimeout(closeStreamTimer);
    idleTimer = undefined;
    closeStreamTimer = undefined;
    pendingIdleWarning = false;
    document.removeEventListener("mousemove", resetTimer);
    document.removeEventListener("keydown", resetTimer);
    document.removeEventListener("touchstart", resetTimer);
    hasEventListeners = false;
  };

  const start = ({ playbackSpec, config }: TStartOptions) => {
    if (!config) return;

    // Stop the service for all types of content except "ONDEMAND",
    // ONDEMAND indicates PNC where timers should live across play
    // starts. This handles the case of going from a channel, where we
    // want a timer, to startover, where we want no timers.
    // If the new .start() call requires timers, they will be restarted below.
    if (playbackSpec.watchMode !== "ONDEMAND") {
      stop();
    }

    isChannel = playbackSpec.videoIdType === SdkVideoIdType.CHANNEL;

    idleTimeoutMs = isChannel ? config.idleTimeLinearMs : config.idleTimeVodMs;
    gracePeriodMs = config.gracePeriodMs;

    // play next content case, show the warning when new content starts
    if (pendingIdleWarning) {
      idleWarningCb();
      closeStreamTimer = window.setTimeout(closeStreamCb, gracePeriodMs);
      clearTimeout(idleTimer);
      idleTimer = undefined;
    } else if (!idleTimer) {
      idleTimer = startIdleTimer();
    }

    if (!hasEventListeners) {
      document.addEventListener("mousemove", resetTimer);
      document.addEventListener("keydown", resetTimer);
      document.addEventListener("touchstart", resetTimer);
      hasEventListeners = true;
    }
  };

  return {
    start,
    stop,
    resetTimer,
  };
};
