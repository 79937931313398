import { StandardError, StandardErrorArgs } from "./StandardError";
import { ErrorCategories } from "../Constants";
import { NetworkError } from "./NetworkError";
import { LoginGatewayErrorMap } from "./constants";
import { getCodeFromNetworkError } from "./utils";

export type TLoginGatewayErrorArgs = Omit<
  StandardErrorArgs,
  "code" | "category"
> & {
  originalError: NetworkError;
};

export class LoginGatewayError extends StandardError {
  constructor(args: TLoginGatewayErrorArgs) {
    super({
      ...args,
      category: ErrorCategories.LOGIN_GATEWAY,
      code: getCodeFromNetworkError({
        error: args.originalError,
        errorMap: LoginGatewayErrorMap,
      }),
    });
  }
}
