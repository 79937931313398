import * as React from "react";

function SvgStop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        fill="#FFF"
        fillRule="evenodd"
        height={32.5}
        rx={4}
        width={32.5}
        x={4}
        y={4}
      />
    </svg>
  );
}

export default SvgStop;
