import styled from "@emotion/styled";
import { CoreTrackingEvent } from "@telia-company/tv.web-playback-sdk";
import { FC, ReactNode, useState } from "react";

import { TModalOptions } from "../../@types";
import { ButtonReset } from "../buttons/base";
import {
  useClickHandler,
  useCustomStyles,
  usePlayerState,
  usePublishCustomTrackingEvent,
  useSmallLayout,
  useTranslations,
} from "../context-provider";
import { OverlayWrapper } from "./elements";

export type Props = {
  children?: ReactNode;
  dismissModal: () => void;
  modalOptions?: TModalOptions;
  type?: "inactivity" | "zapConfirmation";
};

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: none;
  cursor: default;
  background: rgba(0, 0, 0, 0);
`;

const InactivityModal = styled.div<{
  smallLayout: boolean | null;
}>`
  padding: ${({ smallLayout }) =>
    smallLayout ? "3% 2% 1% 2%" : "3% 3.5% 1% 3.5%;"};
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(9, 1fr);
  grid-gap: 0.5%;
  pointer-events: all;
`;

const Heading = styled.div`
  grid-column: 1/17;
  grid-row: 4;
  font-size: 1.2em;
  font-weight: 700;
  color: #fff;
  justify-self: center;
  align-self: flex-end;
`;

const BodyText = styled.span`
  grid-column: 5/13;
  text-align: center;
  grid-row: 5;
  font-size: 0.7em;
  line-height: 1.3em;
  justify-self: center;
  align-self: center;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 0.5em;
  text-align: center;
  grid-column: 1/17;
  grid-row: 5/7;
  justify-self: center;
  align-self: center;
`;

const ButtonContainerWithBodyText = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 0.5em;
  text-align: center;
  grid-column: 1/17;
  grid-row: 6;
  justify-self: center;
  align-self: start;
`;

const ModalButton = styled(ButtonReset)<{ borderRadius: string }>`
  grid-column: 1/17;
  grid-row: 5;
  border-radius: ${({ borderRadius }) => borderRadius};
  justify-self: center;
  background-color: rgba(255, 255, 255, 0.15);
  font-size: 0.6em;
  font-weight: 500;
  color: #fff;
  height: fit-content;
  padding: 0.75em 3em;
  cursor: pointer;
  :hover {
    background: rgba(255, 255, 255, 0.4);
  }
`;

export const FullscreenModal: FC<Props> = ({
  children,
  dismissModal,
  modalOptions,
  type = "inactivity",
}) => {
  const [hasFiredImpression, setHasFiredImpression] = useState(false);
  const { paused } = usePlayerState();
  const { onPause, onTogglePlay } = useClickHandler();
  const smallLayout = useSmallLayout();
  const { buttonBorderRadius } = useCustomStyles();
  const publish = usePublishCustomTrackingEvent();

  const { ayswButtonText, ayswHeading, closeModalButtonText } =
    useTranslations();

  if (!paused && type === "inactivity") {
    if (!hasFiredImpression) {
      // fired once when overlay is shown
      publish(CoreTrackingEvent.PLAYER_STILL_WATCHING_IMPRESSION);
      setHasFiredImpression(true);
    }
    onPause();
  }

  return (
    <OverlayWrapper>
      <FlexContainer>
        {type === "inactivity" ? (
          <InactivityModal smallLayout={smallLayout}>
            {children}
            <Heading>{ayswHeading}</Heading>
            <ButtonContainer>
              <ModalButton
                borderRadius={buttonBorderRadius}
                onClick={() => {
                  publish(CoreTrackingEvent.PLAYER_STILL_WATCHING);
                  dismissModal();
                  onTogglePlay();
                }}
              >
                {ayswButtonText}
              </ModalButton>
              <ModalButton
                borderRadius={buttonBorderRadius}
                onClick={() => {
                  dismissModal();
                }}
              >
                {closeModalButtonText}
              </ModalButton>
            </ButtonContainer>
          </InactivityModal>
        ) : null}
        {type === "zapConfirmation" ? (
          <InactivityModal smallLayout={smallLayout}>
            {children}
            <Heading>{modalOptions?.modalMetadata.title}</Heading>
            <BodyText>{modalOptions?.modalMetadata.body}</BodyText>
            <ButtonContainerWithBodyText>
              {modalOptions?.modalButtons.map((button) => (
                <ModalButton
                  borderRadius={buttonBorderRadius}
                  key={button.type}
                  onClick={button.onClick}
                >
                  {button.buttonText}
                </ModalButton>
              ))}
            </ButtonContainerWithBodyText>
          </InactivityModal>
        ) : null}
      </FlexContainer>
    </OverlayWrapper>
  );
};
