var YouboraObject = require('../object')
const { default: Log } = require('../../common/log')
const { AnalyticsTag } = require('../../common/Constants');

var StateMonitor = YouboraObject.extend(
  /** @lends youbora.NetworkMonitor.prototype */
  {
    /**
     * @constructs StateMonitor
     * @extends youbora.YouboraObject
     * @memberof youbora
     *
     * @param {Adapter} adapter Adapter to monitor. Must have getVideoObject defined.
     * @param {int} [intervalMilliseconds=300] How many ms will wait between progress. -1 to disable.
     */
    constructor: function (adapter, intervalMilliseconds) {
      this._adapter = adapter
      this._videoElement = adapter.getVideoObject()
      this._startedStateCheck = false
      this._intervalCheckId = null
      this._intervalMilliseconds = intervalMilliseconds || 300
    },

    /**
     * Start Network monitor
     */
    start: function () {
      var plugin = this._adapter.plugin
      if (plugin && plugin.getReadyStateMonitorEnabled()) {
        if (this._videoElement && !this._startedStateCheck) {
          this._intervalCheckId = setInterval(this.checkStateProperty.bind(this), this._intervalMilliseconds)
          this._startedStateCheck = true
        }
      }
    },

    /**
     * Stop Network monitor
     */
    stop: function () {
      try {
        if (this._startedStateCheck && this._intervalCheckId) {
          clearInterval(this._intervalCheckId)
        }
        this._intervalCheckId = null
        this._startedStateCheck = false
      } catch (e) {
        Log.error(AnalyticsTag, 'Produced error stopping the network monitor')
      }
    },

    /**
     * Check ReadyState properties values
     * @param e
     */
    checkStateProperty: function (e) {
      try {
        if (this._videoElement && this._adapter) {
          if (this._videoElement.readyState) {
            if (this._videoElement.readyState > 3 && !this._adapter.flags.isSeeking) {
              if (this._adapter.flags.isBuffering) {
                this.fireBufferEnd({}, 'stateMonitor')
              } else if (!this._adapter.flags.isJoined) {
                this.fireJoin({}, 'stateMonitor')
              }
            } else if (this._videoElement.readyState < 4 && !this._adapter.flags.isBuffering) {
              this.fireBufferBegin({}, false, 'stateMonitor', true)
            }
          }
        }
      } catch (e) {}
    }
  })

module.exports = StateMonitor
