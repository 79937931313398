import styled from "@emotion/styled";
import { FC } from "react";

import { ButtonReset } from "../buttons/base";
import {
  useClickHandler,
  useCustomStyles,
  useIcon,
  usePlayerState,
  useSmallLayout,
  useTranslations,
} from "../context-provider";
import { AbsolutePositionFullSizeDiv } from "../elements";
import { absolutePositionFullSizeBlock } from "../styles";
import { NonBreaking, OverlayInnerWrapper, OverlayWrapper } from "./elements";

const padding = "0.8em 1.2em";
const textColor = "rgb(0,0,0)";
const buttonBackground = "rgba(255,255,255,0.6)";
const keepWatchingBackground = "rgba(255,255,255,0.15)";
const overlayBackground = "rgb(255,255,255)";

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const SkipBaseButton = styled(ButtonReset)<{
  brandColorDark: string;
  customBorderRadius: string;
  smallLayout: boolean;
}>`
  pointer-events: all;
  margin-right: 1em;
  ${({ customBorderRadius }) => `
    border-radius: ${customBorderRadius};
  `}
  background: ${buttonBackground};
  color: ${textColor};
  cursor: pointer;
  display: inline-block;
  margin-left: ${padding};
  font-weight: 500;
  font-size: 0.8em;
  line-height: 1.8;
  ${({ smallLayout }) =>
    smallLayout ? "margin-bottom: 0;" : "margin-bottom: 0.5em;"}
`;

const SkipToNextContentButton = styled(SkipBaseButton)<{
  brandColorDark: string;
  smallLayout: boolean;
}>`
  position: relative;
  overflow: hidden;
`;

const KeepWatchingButton = styled(SkipBaseButton)<{ brandColorDark: string }>`
  padding: ${padding};
  background: ${({ brandColorDark }) => brandColorDark};
  background: ${keepWatchingBackground};
  color: rgb(255, 255, 255);
`;

const TimerOverlay = styled(AbsolutePositionFullSizeDiv)<{
  brandColor: string;
  customBorderRadius: string;
  duration: number;
}>`
  background: ${overlayBackground};
  width: 0;
  ${({ customBorderRadius }) => `
    border-bottom-left-radius: ${customBorderRadius};
    border-top-left-radius: ${customBorderRadius};
  `}
  animation: animateWidth ${({ duration }) => duration}s linear;
  animation-fill-mode: forwards;

  @keyframes animateWidth {
    0% {
      width: 0;
    }
    95% {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    100% {
      width: 100%;
      ${({ customBorderRadius }) => `
        border-bottom-right-radius: ${customBorderRadius};
        border-top-right-radius: ${customBorderRadius};
      `}
    }
  }
`;

const NextContentMetadata = styled.div`
  max-width: 50em;
  margin-right: 2em;
  max-height: 20%;
  flex: 1;
`;

const TextContainer = styled.div<{ invisible?: boolean }>`
  ${({ invisible }) =>
    invisible ? "visibility: hidden;" : absolutePositionFullSizeBlock}
  ${({ invisible }) => (invisible ? "" : `padding: ${padding}`)};
  display: flex;
  align-items: center;
  position: relative;
`;

const IconContainer = styled.div`
  width: 1.8em;
  margin-right: 1em;
  & > svg {
    display: block;
    margin: auto;
  }
`;

const SmallText = styled.p`
  font-size: 0.8em;
  line-height: 1;
`;

const NextText = styled(SmallText)`
  margin-bottom: -0.8em;
`;

const Header = styled.h3<{ smallLayout: boolean }>`
  line-height: 2;
  ${({ smallLayout }) =>
    smallLayout ? "margin-bottom: 0;" : "margin-bottom: 0.5em;"}
`;

const DescriptionParagraph = styled(SmallText)`
  padding: 0;
  margin: 0;
  font-size: 0.8em;
  line-height: 1.5;
`;

export const NextContentContainer: FC = () => {
  const {
    metadata: {
      nextEpisode: { episode, episodeDescription, season, seriesTitle } = {},
    },
    nextEpisodeOverlayDuration,
  } = usePlayerState();
  const smallLayout = useSmallLayout();
  const {
    episode: episodeTranslation,
    next,
    playNext,
    season: seasonTranslation,
    watchCredits,
  } = useTranslations();
  const { onNextContent, onWatchCredits } = useClickHandler();
  const { brandColor, brandColorDark, buttonBorderRadius } = useCustomStyles();
  const { IconPlayDark } = useIcon();

  if (!nextEpisodeOverlayDuration) return null;

  return (
    <OverlayWrapper>
      <OverlayInnerWrapper padded>
        <FlexContainer>
          <NextContentMetadata>
            <NextText>{next}</NextText>
            <Header smallLayout={smallLayout}>
              {seriesTitle}{" "}
              <NonBreaking>
                {episodeTranslation} {episode}
              </NonBreaking>{" "}
              <NonBreaking>
                {seasonTranslation} {season}
              </NonBreaking>
            </Header>
            <DescriptionParagraph>
              {smallLayout ? null : `${episodeDescription} `}
            </DescriptionParagraph>
          </NextContentMetadata>
          <div style={{ display: "flex" }}>
            <KeepWatchingButton
              brandColorDark={brandColorDark}
              customBorderRadius={buttonBorderRadius}
              onClick={onWatchCredits}
              smallLayout={smallLayout}
            >
              {watchCredits}
            </KeepWatchingButton>
            <SkipToNextContentButton
              brandColorDark={brandColorDark}
              customBorderRadius={buttonBorderRadius}
              onClick={onNextContent}
              smallLayout={smallLayout}
            >
              <TimerOverlay
                brandColor={brandColor}
                customBorderRadius={buttonBorderRadius}
                duration={
                  nextEpisodeOverlayDuration > 0.5
                    ? nextEpisodeOverlayDuration - 0.5
                    : nextEpisodeOverlayDuration
                }
              />
              <TextContainer>
                <IconContainer>
                  <IconPlayDark />
                </IconContainer>
                {playNext}
              </TextContainer>
            </SkipToNextContentButton>
          </div>
        </FlexContainer>
      </OverlayInnerWrapper>
    </OverlayWrapper>
  );
};
