import Util from '../analytics/util';
import Timer from '../analytics/timer';
import Constants from '../analytics/constants';
import Core from '../core/Core';
import Options from '../analytics/plugin/options';
import RequestBuilder from '../analytics/plugin/requestbuilder';
import AppAnalyticsRequest from './AppAnalyticsRequest';
import { AppAnalyticsTag, Method } from '../common/Constants';
import AppAnalyticsRequestHandler from './comm/AppAnalyticsRequestHandler';
import { POST } from '../analytics/constants/requestMethod';
import CommonGetters from '../common/CommonGetters';
import Log from '../common/log';
/**
 * NpawAppAnalytics class is the base of npaw appanalytics.
 * Every plugin will have an instance.
 */
export default class AppAnalytics {
    /**
     * Constructs the NpawAppAnalytics class
     */
    constructor(accountCode) {
        this.options = new Options();
        this.core = Core.getInstance();
        this.coreStorage = this.core.getCoreStorage();
        this.analyticsRequestHandler = new AppAnalyticsRequestHandler();
        this.accountCode = accountCode;
        this.requestBuilder = new RequestBuilder();
        this._beat = new Timer(this._sendBeat.bind(this), 30000);
        this.sessionExpire = this.coreStorage.getSessionExpire() || 180000;
        this.appAnalyticsStarted = false;
        this.appAnalyticsStopped = false;
        this._lastNavigation = { page: '', route: '' };
        this._registeredProperties = null;
    }
    destroy() {
        this._beat.stop();
        this.analyticsRequestHandler.destroy();
    }
    /**
     * Sets Analytics options. See {@link Options.setOptions}.
     *
     * @internal
     * @param options
     */
    setOptions(options) {
        this.options = options;
    }
    /**
     * Gets Analytics options. See {@link Options}.
     */
    getOptions() {
        return this.options;
    }
    /**
     * This method will start appAnalytics logic, setting storage as needed.
     * Will call fireSessionStart the first time and fireNav for every subsequent route change.
     *
     * @param dimensions - Object of key:value params to add to the request.
     * @param forceNewSession - boolean to force open a new session.
     */
    begin(dimensions = {}) {
        if (this.core.isUsingLegacy())
            return;
        if (!this.appAnalyticsStarted) {
            this._logBeginEvent(dimensions);
            this._registeredProperties = this.coreStorage.getAppAnalyticsRegisteredProperties();
        }
        // send start session with navigation information at beginning
        this.fireSessionStart(dimensions);
    }
    /**
     * Starts a new session. If a sessions exists stops it and starts a new one
     *
     * @param options - Object of key:value options (optional).
     * @param dimensions - Object of key:value params to add to the request (optional).
     */
    newSession(options, dimensions = {}) {
        if (this.core.isUsingLegacy())
            return;
        this._logFireNewSessionListener(options !== null && options !== void 0 ? options : {}, dimensions);
        if (this.appAnalyticsStarted || this.appAnalyticsStopped) {
            this.endSession();
        }
        if (options) {
            this.setOptions(options);
        }
        this.begin(dimensions);
    }
    /**
     * Stops the session
     *
     * @param params - Object of key:value params to add to the request.
     */
    endSession(params = {}) {
        if (this.core.isUsingLegacy())
            return;
        if (this.appAnalyticsStarted) {
            this.fireSessionStop(params);
        }
    }
    _setLastActive() {
        if (!this._firstActive) {
            this._firstActive = this.getFirstActive();
        }
        this.coreStorage.setLastActive(new Date().getTime().toString());
    }
    getAccountCode() {
        return this.accountCode;
    }
    /**
     * @internal
     */
    getFirstActive() {
        return this.coreStorage.getLastActive() || 0;
    }
    /**
     * @internal
     */
    getIsDataExpired() {
        const now = new Date().getTime();
        return (!this.coreStorage.isEnabled() ||
            !this.coreStorage.getStoredData() ||
            this.getFirstActive() < now - this.sessionExpire);
    }
    // Fire
    /**
     * Emits session start request.
     *
     * @param dimensions - Object of key:value params to add to the request.
     */
    fireSessionStart(dimensions = {}) {
        if (this.core.isUsingLegacy())
            return;
        this._logFireSessionStartEvent(dimensions);
        if (this.appAnalyticsStopped) {
            this.core.refreshSessionToken();
            this.appAnalyticsStopped = false;
        }
        if (!this.appAnalyticsStarted) {
            this.coreStorage.setAppAnalyticsStarted(true);
            this.appAnalyticsStarted = true;
            this._processSessionStart(this._getParamsJson(dimensions, null, null, true, true));
            this._setLastActive();
        }
    }
    /**
     * Emits session start request.
     *
     * @param params - Object of key:value params to add to
     * the request.
     */
    fireSessionStop(params = {}) {
        if (this.core.isUsingLegacy())
            return;
        this._logFireSessionStopEvent(params);
        if (this.appAnalyticsStarted) {
            this.appAnalyticsStopped = true;
            this.appAnalyticsStarted = false;
            this.coreStorage.removeAppAnalyticsStarted();
            this._processSessionStop(params);
            this.coreStorage.removeStoredData();
            this.coreStorage.removeSession();
            this.coreStorage.removeLastActive();
            this.core.refreshSessionToken();
        }
    }
    /**
     * Emits session start request.
     *
     * @param dimensions - Object of key:value params to add to the request.
     */
    fireNav(dimensions = {}) {
        if (this.core.isUsingLegacy())
            return;
        this._logFireNavListener(dimensions);
        if (this.isActive()) {
            this.appAnalyticsStarted = true;
            this._processNavigation(this._getParamsJson(dimensions, null, null, true));
        }
    }
    /**
     * Emits session start request.
     *
     * @param eventName - Event name.
     * @param dimensions - Object of key:value dimensions to add to the request.
     * @param values - Object of key:value values to add to the request.
     * @param topLevelDimensions - Object of key:value topLevelDimensions to add to the request.
     */
    fireEvent(eventName = '', dimensions = {}, values = {}, topLevelDimensions = {}) {
        if (this.core.isUsingLegacy())
            return;
        this._logFireEventListener(eventName);
        if (this.appAnalyticsStarted) {
            const builtParams = this._getParamsJson(dimensions, values, eventName);
            Util.assign(builtParams.params, topLevelDimensions || {});
            this._processCustomEvent(builtParams);
            this._setLastActive();
        }
    }
    /** Register properties sent by the User, to send in all the events
     *
     * @param dimensions - Object of key:value dimensions.
     * @param values - Object of key:value values.
     */
    register(dimensions = {}, values = {}) {
        this._registeredProperties = { dimensions: dimensions, values: values };
        this.coreStorage.setAppAnalyticsRegisteredProperties(JSON.stringify(this._registeredProperties));
    }
    /** Calls register if registeredProperties is empty
     *
     * @param dimensions - Object of key:value dimensions.
     * @param values - Object of key:value values.
     */
    registerOnce(dimensions, values) {
        if (!this._registeredProperties) {
            this.register(dimensions, values);
        }
    }
    /** Unregister all properties registered with register() */
    unregister() {
        this._registeredProperties = null;
        this.coreStorage.removeAppAnalyticsRegisteredProperties();
    }
    /**
     * Splits params in dimensions (strings) and values (numbers)
     *
     * @param dimensions - Object of key:value dimensions to split before adding to request.
     * @param values - Object of key:value values to split before adding to request.
     * @param eventName - Event name.
     * @param isNavigation - Boolean to check if it's a navigation event.
     * @param isStart - Boolean to check if it's a start event.
     */
    _getParamsJson(dimensions, values, eventName, isNavigation, isStart) {
        const returnparams = {};
        if (eventName)
            returnparams.name = eventName;
        returnparams.dimensions = dimensions || {};
        returnparams.values = values || {};
        if (this._registeredProperties) {
            for (const key in this._registeredProperties.dimensions) {
                returnparams.dimensions[key] = this._registeredProperties.dimensions[key];
            }
            for (const key2 in this._registeredProperties.values) {
                returnparams.values[key2] = this._registeredProperties.values[key2];
            }
        }
        const paramsObject = { params: returnparams };
        if (isNavigation) {
            if (paramsObject.params.dimensions.page) {
                paramsObject.params.page = paramsObject.params.dimensions.page;
                delete paramsObject.params.dimensions.page;
            }
            if (paramsObject.params.dimensions.route) {
                paramsObject.params.route = paramsObject.params.dimensions.route;
                delete paramsObject.params.dimensions.route;
            }
            if (!isStart) {
                delete paramsObject.params.dimensions;
            }
            delete paramsObject.params.values;
        }
        return paramsObject;
    }
    /**
     * @internal
     */
    isActive() {
        if (this.core.isUsingLegacy())
            return false;
        if (this.appAnalyticsStarted || this.coreStorage.getAppAnalyticsStarted()) {
            return true;
        }
        const now = new Date().getTime();
        if (this.coreStorage.getLastActive() + this.sessionExpire > now) {
            return true;
        }
        return false;
    }
    /**
     *
     * @param willSendEvent
     * @param service
     * @param params
     * @private
     */
    _sendAppAnalytics(service, params) {
        if (this.core.isUsingLegacy())
            return;
        params = this.requestBuilder.buildParams(params, service, [this, new CommonGetters(this.options)]);
        if (params !== null && this.options.enabled) {
            const request = new AppAnalyticsRequest(service, params);
            if (this.isMethodPostEnabled()) {
                request.setMethod(Method.POST);
            }
            this.analyticsRequestHandler.prepareParams(request);
            this.analyticsRequestHandler.sendRequest(request);
        }
    }
    // ---------------------------------------- LISTENERS -----------------------------------------
    /**
     * Adds a listener for analytics request events.
     * @param listener A function of type `(serviceName: string, params: Map<string, string>) => void` to be called when an analytics request is about to be sent.
     */
    addOnWillSendRequestListener(listener) {
        var _a;
        (_a = this.analyticsRequestHandler) === null || _a === void 0 ? void 0 : _a.onWillSendAnalyticsRequestListeners.push(listener);
    }
    /**
     * Removes a previously added listener for analytics request events.
     * @param listener The listener function of type `(serviceName: string, params: Map<string, string>) => void` to remove from the list of analytics request listeners.
     */
    removeOnWillSendRequestListener(listener) {
        var _a;
        if (this.analyticsRequestHandler)
            this.analyticsRequestHandler.onWillSendAnalyticsRequestListeners = (_a = this.analyticsRequestHandler) === null || _a === void 0 ? void 0 : _a.onWillSendAnalyticsRequestListeners.filter(l => l !== listener);
    }
    _processNavigation(params) {
        params = this._parseInternalParams(params);
        if (this._checkDifferentNavigation(params)) {
            this._sendAppAnalytics(Constants.Service.NAV, params);
            // start beats
            if (!this._beat.isRunning)
                this._beat.start();
            Log.notice(AppAnalyticsTag, '[' + this.core.getFastDataSessionToken() + '] ' + Constants.Service.NAV + ' ' + params.route);
            this._lastNavigation.page = params.page;
            this._lastNavigation.route = params.route;
        }
        else {
            Log.warn(AppAnalyticsTag, 'Same navigation detected and ignored for Page and Route');
        }
    }
    _processSessionStart(params) {
        params = this._parseInternalParams(params);
        this._sendAppAnalytics(Constants.Service.SESSION_START, params);
        // start beats
        if (!this._beat.isRunning)
            this._beat.start();
        Log.notice(AppAnalyticsTag, '[' + this.core.getFastDataSessionToken() + '] ' + Constants.Service.SESSION_START + ' ' + params.route);
    }
    _processSessionStop(params) {
        params = this._parseInternalParams(params);
        this._sendAppAnalytics(Constants.Service.SESSION_STOP, params);
        // stop beats
        if (this._beat.isRunning)
            this._beat.stop();
        Log.notice(AppAnalyticsTag, '[' + this.core.getFastDataSessionToken() + '] ' + Constants.Service.SESSION_STOP + ' ' + params.route);
    }
    _processCustomEvent(params) {
        params = this._parseInternalParams(params);
        this._sendAppAnalytics(Constants.Service.EVENT, params);
        Log.notice(AppAnalyticsTag, '[' + this.core.getFastDataSessionToken() + '] ' + Constants.Service.EVENT + ' ' + params.name);
    }
    /**
     * Process Internal Params object
     * @param params
     * @returns {{}|*|{}}
     * @private
     */
    _parseInternalParams(params) {
        if (params) {
            return params.params || params || {};
        }
        else {
            return {};
        }
    }
    /**
     * Sends beat request
     *
     * @param diffTime - Time since the last ping
     *
     * @private
     * @memberof NpawAppAnalytics.prototype
     */
    _sendBeat(diffTime) {
        const params = {
            diffTime: diffTime
        };
        this._sendAppAnalytics(Constants.Service.BEAT, params);
        if (this) {
            this._setLastActive();
        }
        Log.verbose(AppAnalyticsTag, Constants.Service.BEAT);
    }
    /**
     * Check if change page/route (to avoid duplicated navigations)
     * @param params
     * @returns {boolean}
     * @private
     */
    _checkDifferentNavigation(params) {
        params = params || {};
        const sameRoute = this._lastNavigation && params.route === this._lastNavigation.route;
        const samePage = this._lastNavigation && params.page === this._lastNavigation.page;
        return !sameRoute || !samePage;
    }
    // ---------------------------------------- GETTERS -----------------------------------------
    /**
     * Returns a json with the metrics to be reported in beats when changed
     *
     * @memberof NpawAppAnalytics.prototype
     */
    getSessionMetrics() {
        return Util.getMetricsFrom(this.options['session.metrics']);
    }
    /**
     * Is player event logs enabled (to report QA Tools additional info)
     *
     * @internal
     * @returns {boolean}
     */
    isPlayerLogsEnabled() {
        Log.debug(AppAnalyticsTag, 'isPlayerLogsEnabled: ' + this.options['debug.playerLogs.enabled']);
        return Util.parseBoolean(this.options['debug.playerLogs.enabled']);
    }
    /**
     * Is plugin pogs enabled (to report QA Tools additional info)
     *
     * @internal
     * @returns {boolean}
     */
    isPluginLogsEnabled() {
        Log.debug(AppAnalyticsTag, 'isPluginLogsEnabled: ' + this.options['debug.pluginLogs.enabled']);
        return Util.parseBoolean(this.options['debug.pluginLogs.enabled']);
    }
    /**
     * Is post method enabled
     * @returns {boolean}
     */
    isMethodPostEnabled() {
        return this.options.method && this.options.method.toUpperCase() === POST;
    }
    // ----------------------------------------- LOGS -------------------------------------------
    /**
     *
     * @param willSendLog
     * @param service
     * @param params
     */
    _sendPluginLogs(willSendLog, service, params) {
        if (this.core.isUsingLegacy())
            return;
        if (this.isPluginLogsEnabled()) {
            try {
                if (params && params.logType && params.logAction) {
                    const token = this.core.getFastDataSessionToken();
                    Log.notice(AppAnalyticsTag, token.length ? '[' + token + '] ' : '' + params.logType + ': Action ' + params.logAction);
                }
            }
            catch (e) {
                /* empty */
            }
            params['timemark'] = new Date().getTime();
            params = this.requestBuilder.buildParams(params, service);
            if (params !== null && this.options.enabled) {
                const request = new AppAnalyticsRequest(service, params);
                if (this.isMethodPostEnabled()) {
                    request.setMethod(Method.POST);
                }
                this.analyticsRequestHandler.prepareParams(request);
                this.analyticsRequestHandler.sendRequest(request);
            }
        }
    }
    _logBeginEvent(dimensions) {
        const params = {
            logs: {
                data: dimensions
            },
            logAction: 'beginSession',
            logType: 'pluginMethod'
        };
        this._sendPluginLogs(Constants.WillSendLog.WILL_SEND_LOG_INFINITY_BEGIN, Constants.Service.APP_ANALYTICS_PLUGIN_LOGS, params);
    }
    _logFireSessionStartEvent(dimensions) {
        const params = {
            logs: {
                data: dimensions
            },
            logAction: 'startSession',
            logType: 'pluginMethod'
        };
        this._sendPluginLogs(Constants.WillSendLog.WILL_SEND_LOG_INFINITY_START, Constants.Service.APP_ANALYTICS_PLUGIN_LOGS, params);
    }
    _logFireSessionStopEvent(eventParams) {
        const params = {
            logs: {
                data: eventParams
            },
            logAction: 'stopSession',
            logType: 'pluginMethod'
        };
        this._sendPluginLogs(Constants.WillSendLog.WILL_SEND_LOG_INFINITY_START, Constants.Service.APP_ANALYTICS_PLUGIN_LOGS, params);
    }
    _logFireEventListener(eventName) {
        const params = {
            logs: {
                data: eventName
            },
            logAction: 'eventSession',
            logType: 'pluginMethod'
        };
        this._sendPluginLogs(Constants.WillSendLog.WILL_SEND_LOG_INFINITY_EVENT, Constants.Service.APP_ANALYTICS_PLUGIN_LOGS, params);
    }
    _logFireNavListener(dimensions) {
        const params = {
            logs: {
                data: dimensions
            },
            logAction: 'nav',
            logType: 'pluginMethod'
        };
        this._sendPluginLogs(Constants.WillSendLog.WILL_SEND_LOG_INFINITY_NAV, Constants.Service.APP_ANALYTICS_PLUGIN_LOGS, params);
    }
    _logFireNewSessionListener(options, eventParams) {
        const params = {
            logs: {
                data: options,
                params: eventParams
            },
            logAction: 'newSession',
            logType: 'pluginMethod'
        };
        this._sendPluginLogs(Constants.WillSendLog.WILL_SEND_LOG_INFINITY_NAV, Constants.Service.APP_ANALYTICS_PLUGIN_LOGS, params);
    }
}
