import {
  ErrorCategories,
  StandardError,
} from "@telia-company/tv.web-playback-sdk";
import {
  isArray,
  isObject,
  JSONStringifyWithErrors,
  MsEdgeErrorMap,
  NativeErrorMap,
  TClientErrorId,
  TUnfortunatelyAny,
} from "@telia-company/tv.web-player-shared";
import shaka from "shaka-player";

import { playerId } from "../constants";
import { getErrorDescription } from "./shaka-error-description";
import { convertShakaCategory } from "./shaka-to-youbora-category";
import {
  TIsErrorFatalFn,
  TOverrideCodeFn,
  TShakaError,
  TShakaStats,
} from "./types";

const looksLikeNestedError = (data: unknown): data is Array<TShakaError> =>
  isArray(data) &&
  isObject(data[0]) &&
  typeof data[0].code === "number" &&
  typeof data[0].category === "number" &&
  typeof data[0].severity === "number";

const getShakaKey = (detail: TShakaError) =>
  Object.keys(shaka.util.Error.Code).find(
    (key) => shaka.util.Error.Code[key] === detail.code
  ) || "UNKNOWN";

const getShakaCategory = (detail: TShakaError) =>
  Object.keys(shaka.util.Error.Category).find(
    (key) => shaka.util.Error.Category[key] === detail.category
  ) || "UNKNOWN";

const getSupplementalCode = (detail: TShakaError, shakaKey: string) => {
  if (shakaKey === "BAD_HTTP_STATUS" && isArray(detail?.data))
    return detail.data[1];

  if (shakaKey === "VIDEO_ERROR" && isArray(detail?.data))
    return NativeErrorMap[detail.data[0]] || "";

  if (shakaKey === "LICENSE_REQUEST_FAILED" && isArray(detail?.data)) {
    if (detail.data[0]?.code === 1001) {
      return parseInt(detail.data[0].data[1], 10) || "";
    }

    if (detail.data[0]?.code === 1003) {
      return "TIMEOUT";
    }
  }

  return "";
};

const getBufferedRangeDebugData = (videoElement?: HTMLVideoElement) => {
  if (!videoElement) return {};

  const { buffered: timeRange, currentTime } = videoElement;

  if (!timeRange.length)
    return {
      timeRangeUnavailable: true,
    };

  // this try/catch shouldn't be needed. Monitor youbora.
  // TODO remove after summer freeze 2021
  try {
    return {
      bufferedEnd: timeRange.end(0),
      bufferedStart: timeRange.start(0),
      currentTime,
      currentTimeOutsideOfBuffer:
        currentTime > timeRange.end(0) || currentTime < timeRange.start(0),
    };
  } catch (_) {
    return {
      failedToAccessTimeRange: true,
    };
  }
};

export type TConvertShakaErrorOptions = {
  // when stream was first loaded
  engineInitTimestamp?: number;
  error: Error | TShakaError;
  // will be included in the fault as-is
  extraDebugData?: Record<string, TUnfortunatelyAny>;
  // allows overriding fataility of an error
  // https://github.com/google/shaka-player/issues/3455
  isErrorFatalFn?: TIsErrorFatalFn;
  // allows overriding the parsed code
  overrideCodeFn?: TOverrideCodeFn;
  // player.getStats()
  stats?: TShakaStats;
  // including video element will parse out some extra debug data
  videoElement?: HTMLVideoElement;
};

export const convertShakaError = ({
  engineInitTimestamp,
  error,
  extraDebugData,
  isErrorFatalFn,
  overrideCodeFn,
  stats,
  videoElement,
}: TConvertShakaErrorOptions): StandardError => {
  if (error instanceof Error) {
    return new StandardError({
      category: ErrorCategories.DEFAULT,
      code: `SHAKA:UNHANDLED:${error.message}`,
      fatal: true,
      message: "Shaka encountered an uncaught internal error.",
      originalError: JSON.parse(JSONStringifyWithErrors(error)),
    });
  }

  try {
    const { category, data, severity } = error;

    const description = getErrorDescription(error);
    const shakaKey = getShakaKey(error);
    const shakaCategoryKey = getShakaCategory(error);

    // include the http error code if available
    const supplementalCode = getSupplementalCode(error, shakaKey);

    const fatal =
      severity === shaka.util.Error.Severity.CRITICAL ||
      (isErrorFatalFn ? isErrorFatalFn(error) : false);

    const msEdgeCode: string = (isArray(data) && MsEdgeErrorMap[data[1]]) || "";

    // It is possible for the Shaka error to be missing key or category if it
    // is a syntax/type/reference error. In this case, use the error message as id.
    const haveDescriptionButNoKeyOrCategoryOverride =
      shakaKey === "UNKNOWN" && shakaCategoryKey === "UNKNOWN" && description
        ? `${playerId}:${description}`
        : "";

    // some errors should be handled by the client and not reported to youbora, hence the special handling.
    const overrideCode: null | TClientErrorId = overrideCodeFn
      ? overrideCodeFn(error, msEdgeCode, engineInitTimestamp)
      : null;

    const bufferedRangeDebugData = getBufferedRangeDebugData(videoElement);

    return new StandardError({
      category: convertShakaCategory(category),
      code:
        overrideCode ||
        haveDescriptionButNoKeyOrCategoryOverride ||
        `${playerId}:${shakaCategoryKey}:${shakaKey}${
          supplementalCode ? `:${supplementalCode}` : ""
        }${msEdgeCode ? `:${msEdgeCode}` : ""}`,
      details: {
        debug: {
          extraDebugData,
          nestedError: looksLikeNestedError(data)
            ? convertShakaError({ error: data[0] })
            : null,
          shakaCategoryKey,
          shakaKey,
          shakaStatistics: stats,
          ...bufferedRangeDebugData,
        },
        domain: "errorConverter",
        origin: "ShakaEngine",
      },
      fatal,
      message: description,
      originalError: JSON.parse(JSONStringifyWithErrors(error)),
    });
  } catch (e) {
    return {
      category: ErrorCategories.DEFAULT,
      code: "ERROR_CONVERTER_PARSING_ERROR",
      details: {
        originalError: JSON.parse(JSONStringifyWithErrors(e)),
      },
      fatal: true,
      message: "Shaka error converter encountered a parsing error",
    };
  }
};
