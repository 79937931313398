const AdPosition = require('./constants/adPosition')
const Adapter = require('./constants/adapter')
const ManifestError = require('./constants/manifestError')
const RequestMethod = require('./constants/requestMethod')
const Service = require('./constants/service')
const WillSendEvent = require('./constants/willSendEvent')
const WillSendLog = require('./constants/willSendLog')
const AdInsertionType = require('./constants/adInsertionType')
const Metadata = require('./constants/metadata')
/**
 * This static class englobes npaw constants.
 *
 * @class
 * @static
 * @memberof npaw
 */
const Constants = {
  AdPosition: AdPosition,
  ManifestError: ManifestError,
  RequestMethod: RequestMethod,
  Service: Service,
  WillSendEvent: WillSendEvent,
  WillSendLog: WillSendLog,
  AdInsertionType: AdInsertionType,
  Adapter: Adapter,
  Metadata: Metadata
}

module.exports = Constants
