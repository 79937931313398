import * as React from "react";

function SvgCastConnecting1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M1 18v3h3c0-1.66-1.34-3-3-3z" fill="#000" opacity={0.3} />
        <path d="M1 14v2c2.76 0 5 2.24 5 5h2c0-3.87-3.13-7-7-7z" fill="#000" />
        <path
          d="M1 10v2a9 9 0 019 9h2c0-6.08-4.93-11-11-11z"
          fill="#000"
          opacity={0.3}
        />
        <path
          d="M21 3H3c-1.1 0-2 .9-2 2v3h2V5h18v14h-7v2h7c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
          fill="#000"
        />
        <path d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
}

export default SvgCastConnecting1;
