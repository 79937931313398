import styled from "@emotion/styled";
import { FC } from "react";

import { TPlayerIcon } from "../@types";
import { TextOverflowEllipsis } from "./overlays/elements";

const MetadataBox = styled.div<{ backButtonVisible: boolean }>`
  grid-column: ${({ backButtonVisible }) =>
    backButtonVisible ? "2 / 11" : "1 / 11"};
  grid-row: 1;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
`;

const MetadataTitle = styled(TextOverflowEllipsis)`
  line-height: 1.2em;
  font-size: 1.1em;
  font-weight: 500;
`;

const MetadataSubtitle = styled(TextOverflowEllipsis)`
  display: inherit;
  font-size: 0.9em;
  line-height: 1.2;
`;

const MetadataTitleBox = styled.div<{ hasSubtitle: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
  justify-content: ${({ hasSubtitle }) =>
    hasSubtitle ? "space-evenly" : "center"};
  overflow: hidden;
`;

const RecordingIconWrapper = styled.div<{ recordingColor: string }>`
  width: 1em;
  display: flex;
  margin-left: 0.5em;
  margin-right: 0.2em;
  > svg {
    > path {
      fill: ${({ recordingColor }) => recordingColor};
    }
  }
`;
const MetadataSubtitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const LogoContainer = styled.div`
  margin-right: 1em;
  width: 3em;
  overflow: hidden;
  display: flex;
  align-items: center;
  min-width: 4em;
  > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export type TMetadataToastProps = {
  backButtonVisible: boolean;
  brokenImage: boolean;
  isRecording: boolean;
  isSeriesRecording: boolean;
  logo: string;
  onBrokenImage: () => void;
  recordingColor: string;
  RecordingIcon: TPlayerIcon;
  RecordingIconSeries: TPlayerIcon;
  recordingText: string;
  subtitle: string;
  title: string;
};

export const MetadataToast: FC<TMetadataToastProps> = ({
  backButtonVisible,
  brokenImage,
  isRecording,
  isSeriesRecording,
  logo,
  onBrokenImage,
  recordingColor,
  RecordingIcon,
  RecordingIconSeries,
  recordingText,
  subtitle,
  title,
}) => (
  <MetadataBox backButtonVisible={backButtonVisible}>
    {logo && !brokenImage && (
      <LogoContainer>
        <img alt="channel logo" onError={onBrokenImage} src={logo} />
      </LogoContainer>
    )}
    <MetadataTitleBox hasSubtitle={subtitle !== ""}>
      {title && <MetadataTitle>{title}</MetadataTitle>}
      {(subtitle || isRecording || isSeriesRecording) && (
        <MetadataSubtitleWrapper>
          <MetadataSubtitle>
            {subtitle}
            {isRecording || isSeriesRecording ? (
              <RecordingIconWrapper recordingColor={recordingColor}>
                {isSeriesRecording ? (
                  <RecordingIconSeries />
                ) : (
                  <RecordingIcon />
                )}
              </RecordingIconWrapper>
            ) : null}
            {isRecording || isSeriesRecording ? recordingText : null}
          </MetadataSubtitle>
        </MetadataSubtitleWrapper>
      )}
    </MetadataTitleBox>
  </MetadataBox>
);
