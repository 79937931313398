var Constants = require('../constants');
var Util = require('../util');
var Adapter = require('adapter');
const { AnalyticsTag } = require('../../common/Constants');
const { default: Log } = require('../../common/log');
const { default: DiagnosticTool } = require('../../diagnostic/DiagnosticTool');

var NpawVideoAdsMixin = {
  getAdsAdapter: function () {
    return this._adsAdapter;
  },

  /**
   *
   * @param adsAdapter
   * @param plugin
   * @param registerListeners
   */
  setAdsAdapter: function (adsAdapter, plugin, registerListeners) {
    registerListeners = registerListeners || false;
    if (adsAdapter.isSetAdapter()) {
      Log.warn(AnalyticsTag, 'Adapters can only be added to a single plugin');
    } else {
      try {
        this.removeAdsAdapter();
        this.plugin = plugin;
        this._adsAdapter = adsAdapter;
        this._adsAdapter.setIsAds(true);

        this.adsAdapterListeners = {};
        this.adsAdapterListeners[Adapter.Event.START] = this._adStartListener.bind(this);
        this.adsAdapterListeners[Adapter.Event.JOIN] = this._adJoinListener.bind(this);
        this.adsAdapterListeners[Adapter.Event.DATA_OBJECT] = this._adAdvancedDataListener.bind(this);
        this.adsAdapterListeners[Adapter.Event.PAUSE] = this._adPauseListener.bind(this);
        this.adsAdapterListeners[Adapter.Event.RESUME] = this._adResumeListener.bind(this);
        this.adsAdapterListeners[Adapter.Event.BUFFER_BEGIN] = this._adBufferBeginListener.bind(this);
        this.adsAdapterListeners[Adapter.Event.BUFFER_END] = this._adBufferEndListener.bind(this);
        this.adsAdapterListeners[Adapter.Event.STOP] = this._adStopListener.bind(this);
        this.adsAdapterListeners[Adapter.Event.ERROR] = this._adErrorListener.bind(this);
        this.adsAdapterListeners[Adapter.Event.CLICK] = this._adClickListener.bind(this);
        this.adsAdapterListeners[Adapter.Event.MANIFEST] = this._adManifestListener.bind(this);
        this.adsAdapterListeners[Adapter.Event.PODSTART] = this._adBreakStartListener.bind(this);
        this.adsAdapterListeners[Adapter.Event.PODSTOP] = this._adBreakStopListener.bind(this);
        this.adsAdapterListeners[Adapter.Event.QUARTILE] = this._adQuartileListener.bind(this);

        for (var key in this.adsAdapterListeners) {
          this._adsAdapter.on(key, this.adsAdapterListeners[key]);
        }

        if (registerListeners) {
          Log.notice(AnalyticsTag, 'Registering listeners for registered AdsAdapter');
          this._adsAdapter.registerListeners();
        }
      } catch (err) {
        Log.warn(AnalyticsTag, 'Problem during setAdsAdapter process');
      }
    }
  },

  removeAdsAdapter: function () {
    if (this._adsAdapter) {
      this._adsAdapter.dispose();
      if (this.adsAdapterListeners) {
        for (var key in this.adsAdapterListeners) {
          this._adsAdapter.off(key, this.adsAdapterListeners[key]);
        }
        delete this.adsAdapterListeners;
      }
      this.resizeScrollDetector.stopDetection();
      this._adsAdapter = undefined;
    }
  },

  _adStartListener: function (e) {
    if (this._adapter) {
      this._adapter.fireBufferEnd(null, 'adStartListener');
      this._adapter.fireSeekEnd(null, 'adStartListener');
      if (!this.isInitiated && !this._adapter.flags.isStarted) this._adapter.fireStart();
      if (this._adapter.flags.isPaused) this._adapter.chronos.pause.reset();
    } else {
      this.fireInit();
    }
    if (this._adsAdapter) {
      this._adsAdapter.chronos.viewedMax = [];
    }
    const params = e.data.params || {};
    params.adNumber = this.getNewAdNumber();
    const allParamsReady = (this.getAdResource() || this.getAdTitle()) && typeof this.getAdDuration() === 'number';
    if (allParamsReady) {
      this.adStartSent = true;
      this._adsAdapter.fireManifest();
      this._adsAdapter.fireAdBreakStart();
      params.adNumberInBreak = this.getNewAdNumberInBreak();
      DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.START, params);
      this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_AD_START, Constants.Service.AD_START, params);
    } else {
      this.adInitSent = true;
      params.adNumberInBreak = this.getNewAdNumberInBreak();
      DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.INIT, params);
      this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_AD_INIT, Constants.Service.AD_INIT, params);
    }
  },

  _adJoinListener: function (e) {
    let params = {};
    Util.assign(params, e.data.params || {});
    if (this.adInitSent && !this.adStartSent) {
      this._adsAdapter.fireManifest();
      this._adsAdapter.fireAdBreakStart();
      DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.START, params);
      this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_AD_START, Constants.Service.AD_START, params);
    }
    this._adsAdapter.startChronoView();
    if (this.adConnected) {
      this._adsAdapter.chronos.join.startTime = this.adConnectedTime;
      this._adsAdapter.chronos.total.startTime = this.adConnectedTime;
      this.adConnectedTime = 0;
      this.adConnected = false;
    }
    params = e.data.params || {};
    DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.JOIN, params);
    this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_AD_JOIN, Constants.Service.AD_JOIN, params);
    this.adInitSent = false;
    this.adStartSent = false;
  },

  _adAdvancedDataListener: function (e) {
    var params = e.data.params || {};
    this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_DATA_OBJECT, Constants.Service.DATA_OBJECT, params);
  },

  _adPauseListener: function (e) {
    const params = e.data.params || {};
    this._adsAdapter.stopChronoView();
    DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.PAUSE, params);
    this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_AD_PAUSE, Constants.Service.AD_PAUSE, params);
  },

  _adResumeListener: function (e) {
    const params = e.data.params || {};
    this._adsAdapter.startChronoView();
    DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.RESUME, params);
    this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_AD_RESUME, Constants.Service.AD_RESUME, params);
  },

  _adBufferBeginListener: function (e) {
    Log.notice(
      AnalyticsTag,
      '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + 'Ad Buffer Begin'
    );
    this._adsAdapter.stopChronoView();
    if (this._adsAdapter && this._adsAdapter.flags.isPaused) {
      this._adsAdapter.chronos.pause.reset();
    }
  },

  _adBufferEndListener: function (e) {
    const params = e.data.params || {};
    this._adsAdapter.startChronoView();
    DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.BUFFER_END, params);
    this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_AD_BUFFER, Constants.Service.AD_BUFFER, params);
  },

  _adStopListener: function (e) {
    const params = e.data.params || {};

    this._adsAdapter.stopChronoView();
    this._adsAdapter.flags.reset();
    this._totalPrerollsTime = (this._totalPrerollsTime || 0) + this._adsAdapter.chronos.total.getDeltaTime();

    params.position = this.requestBuilder.lastSent.position;

    DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.STOP, params);
    this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_AD_STOP, Constants.Service.AD_STOP, params);

    // If its a stop for a postroll we check if we can detect if its the last one to call view stop
    if (this.requestBuilder.lastSent.position === Constants.AdPosition.Postroll) {
      const pat = this.options['ad.expectedPattern'];
      this.playedPostrolls++;
      // If we know the amount of postrolls and this was the last one
      if (
        (this.requestBuilder.lastSent.givenAds && this.requestBuilder.lastSent.givenAds <= this.playedPostrolls) ||
        // Or if we have expected (and not given!) and this was the last expected one
        (!this.requestBuilder.lastSent.givenAds &&
          pat &&
          pat.post &&
          pat.post[0] &&
          pat.post[0] <= this.playedPostrolls)
      ) {
        this._adapter.fireStop({}, '_adStopListener');
      }
    }

    this.adConnected = true;
    this.adConnectedTime = new Date().getTime();
  },

  _adErrorListener: function (e) {
    const params = e.data.params || {};
    if (this._adapter && !this._adapter.flags.isStarted && !this.isInitiated) {
      this._savedAdError = e;
      return undefined; // Ignore ad errors before content
    }
    if (this._blockAdError(e.data.params)) return undefined;
    if (this._adsAdapter) {
      this._adsAdapter.fireManifest();
      this._adsAdapter.fireAdBreakStart();
    }
    if (!this._adsAdapter || !this._adsAdapter.flags.isStarted) {
      params.adNumber = this.getNewAdNumber();
      params.adNumberInBreak = this.getNewAdNumberInBreak();
    }
    if (!this.isBreakStarted) {
      params.breakNumber = this.getNewBreakNumber();
    }
    DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.ERROR, params);
    this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_AD_ERROR, Constants.Service.AD_ERROR, params);
  },

  _adSavedError: function () {
    if (this._savedAdError) {
      this._adErrorListener(this._savedAdError);
      this._savedAdError = null;
    }
  },

  _adSavedManifest: function () {
    if (this._savedAdManifest) {
      this._adManifestListener(this._savedAdManifest);
      this._savedAdManifest = null;
    }
  },

  _blockAdError: function (errorParams) {
    const now = Date.now();
    const sameError =
      this._lastAdErrorParams &&
      this._lastAdErrorParams.errorCode === errorParams.errorCode &&
      this._lastAdErrorParams.msg === errorParams.msg &&
      this._lastAdErrorParams.adCreativeId === this.getAdCreativeId();

    if (sameError && this._lastAdErrorTime + 5000 > now) {
      this._lastAdErrorTime = now;
      return true;
    }
    this._lastAdErrorTime = now;
    this._lastAdErrorParams = errorParams;
    return false;
  },

  _adClickListener: function (e) {
    const params = e.data.params || {};
    this._adsAdapter.stopChronoView();
    DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.CLICK, params);
    this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_AD_CLICK, Constants.Service.AD_CLICK, params);
  },

  _adManifestListener: function (e) {
    if (!this.isAdsManifestSent) {
      if (this._adapter && !this._adapter.flags.isStarted && !this.isInitiated) {
        this._savedAdManifest = e;
        return undefined; // Ignore ad manifest before content
      }
      const params = e.data.params || {};
      this.isAdsManifestSent = true;
      DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.MANIFEST, params);
      this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_AD_MANIFEST, Constants.Service.AD_MANIFEST, params);
    }
  },

  _adBreakStartListener: function (e) {
    if (!this.isBreakStarted) {
      this.isBreakStarted = true;
      if (this._adapter) this._adapter.firePause();
      const params = e.data.params || {};
      params.breakNumber = this.getNewBreakNumber();
      DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.PODSTART, params);
      this._sendAdEventIfAllowed(
        Constants.WillSendEvent.WILL_SEND_AD_POD_START,
        Constants.Service.AD_POD_START,
        params
      );
      this.adConnected = false;
    }
  },

  _adBreakStopListener: function (e) {
    if (this.isBreakStarted) {
      this.isBreakStarted = false;
      const params = e.data.params || {};
      params.position = this.requestBuilder.lastSent.position;
      DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.PODSTOP, params);
      this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_AD_POD_STOP, Constants.Service.AD_POD_STOP, params);
      this.adConnected = false;
      if (this._adapter) this._adapter.fireResume();
    }
  },

  _adQuartileListener: function (e) {
    const params = e.data.params || {};
    if (params.quartile) {
      DiagnosticTool.getInstance().addNQSAdEvent(Adapter.Event.QUARTILE, params);
      this._sendAdEventIfAllowed(Constants.WillSendEvent.WILL_SEND_AD_QUARTILE, Constants.Service.AD_QUARTILE, params);
    }
  },

  _sendAdEventIfAllowed: function (willSend, service, params) {
    if (!this.options['ad.ignore']) {
      DiagnosticTool.getInstance().setTriedSendingNQSAdStats(true);
      this._send(willSend, service, params, undefined, undefined, () => {
        DiagnosticTool.getInstance().addNQSAdRequest(service);
      });
    }
    Log.notice(AnalyticsTag, '[' + this.getViewCode() + '] (Video Space ' + this.getVideoKey() + ') ' + service);
  }
};

module.exports = NpawVideoAdsMixin;
