import { StandardError, StandardErrorArgs } from "./StandardError";
import { ErrorCategories } from "../Constants";

// TODO remove?
export enum MediaErrorCodes {
  NETWORK = "NETWORK",
  TEXT = "TEXT",
  MEDIA = "MEDIA",
  MANIFEST = "MANIFEST",
  STREAMING = "STREAMING",
  DRM = "DRM",
  PLAYER = "PLAYER",
  CAST = "CAST",
  STORAGE = "STORAGE",
  ADS = "ADS",
}

export type TMediaErrorArgs = Omit<StandardErrorArgs, "category"> & {};

export class MediaError extends StandardError {
  constructor(args: TMediaErrorArgs) {
    super({
      ...args,
      category: ErrorCategories.MEDIA,
    });
  }
}
