import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";
import {
  ISession,
  PlaybackSessionEventKeys,
  PlaybackSessionStateChangedEvent,
  PlaybackStates,
  PreferredLanguage,
  Roles,
  SessionEndedEvent,
  SessionStartedEvent,
  SessionStates,
  StreamTypes,
  TimelineSectionTypes,
  Track,
  TrackTypes,
  UselessSender,
  UselessSenderEvents,
  UselessSessionEvents,
} from "@useless-media-public/useless-sender-web";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { UselessChromecastBar } from "~/components";

import { Breakpoints, DesignTokens, ThemeProvider } from "./ThemeProvider";

export type UselessTranslations = {
  audioTracks: string;
  descriptiveAudio: string;
  episode: string;
  hardOfHearingSubtitles: string;
  off: string;
  season: string;
  subtitleTracks: string;
};

type TCredentials = {
  auxiliaryData?: {
    autoLogin: boolean;
    profileHeader: string;
  };
  token: string;
};

export const UselessContext = createContext<{
  credentials?: TUnfortunatelyAny;
  devicesFound?: boolean;
  isChromecastBarVisible: boolean;
  preferredLanguage: PreferredLanguage;
  sender?: UselessSender;
  senderState?: string;
  serviceCountry: string;
  sessionStarted?: boolean;
  translations?: UselessTranslations;
}>({
  isChromecastBarVisible: false,
  preferredLanguage: { audio: [], audioRoles: [], text: [], textRoles: [] },
  serviceCountry: "",
});

const DEVICE_CHECK_INTERVAL = 1500;

export function useChromecastContext() {
  return useContext(UselessContext);
}

export type TUselessProviderProps = {
  applicationId: string;
  breakpoints: Breakpoints;
  children: ReactNode;
  credentials: TCredentials;
  designTokens: DesignTokens;
  preferredLanguage: PreferredLanguage;
  serviceCountry: string;
  translations?: UselessTranslations;
};

type TActiveTracks = {
  audio?: Track;
  text?: Track;
};

const extractAdMarkers = (session: ISession) => {
  if (!session.playbackSessionState.timeline) return [];
  return Object.values(session.playbackSessionState.timeline.items)
    .map((item: TUnfortunatelyAny) => {
      if (item.sectionType === TimelineSectionTypes.AdvertisementBreak) {
        return session.playbackSessionState.timeline
          ? item.offset /
              (session.playbackSessionState.timeline.timelineEnd -
                session.playbackSessionState.timeline.timelineStart)
          : undefined;
      }
    })
    .filter((i) => i !== Infinity)
    .filter((i) => typeof i === "number");
};

export const UselessProvider: React.FC<TUselessProviderProps> = ({
  applicationId,
  breakpoints,
  children,
  credentials,
  designTokens,
  preferredLanguage,
  serviceCountry,
  translations,
}) => {
  const [session, setSession] = useState<ISession | undefined>();
  const [sessionState, setSessionState] = useState<ISession>();
  const [devicesFound, setDevicesFound] = useState(false);
  const [activeVolume, setActiveVolume] = useState(1);
  const [activeTracks, setActiveTracks] = useState<TActiveTracks>();
  const [adMarkers, setAdMarkers] = useState<number[]>([]);
  const [canSeek, setCanSeek] = useState<boolean>();
  const [canPause, setCanPause] = useState<boolean>();
  const [isMuted, setIsMuted] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [position, setPosition] = useState(-1);
  const [duration, setDuration] = useState<number>(1);
  const [seekableEndRange, setSeekableEndRange] = useState<number>();
  const [title, setTitle] = useState<string>();
  const [secondaryTitle, setSecondaryTitle] = useState<string>();
  const [senderState, setSenderState] = useState<SessionStates>();
  const [sessionStarted, setSessionStarted] = useState(false);

  const isChromecastBarVisible = isActive;

  const sender = UselessSender.getInstance();

  const stop = useCallback(() => {
    if (!session) return;
    session.destroy(true);
  }, [session]);

  useEffect(() => {
    if (!session) return;
    const playbackSessionStateHandler = (
      e: PlaybackSessionStateChangedEvent
    ) => {
      try {
        // update active tracks
        if (
          typeof activeTracks === "undefined" ||
          e.events.includes(PlaybackSessionEventKeys.TracksActive)
        ) {
          setActiveTracks({
            audio: session.activeTracks.find(
              (track) => track.type === TrackTypes.Audio
            ),
            text: session.activeTracks.find(
              (track) => track.type === TrackTypes.Text
            ),
          });
        }

        // update stream restrictions
        if (
          typeof canSeek === "undefined" ||
          e.events.includes(PlaybackSessionEventKeys.StreamRestrictions)
        ) {
          setCanSeek(!session.playbackSessionState.streamRestrictions?.seek);
        }
        if (
          typeof canPause === "undefined" ||
          e.events.includes(PlaybackSessionEventKeys.StreamRestrictions)
        ) {
          setCanPause(!session.playbackSessionState.streamRestrictions?.pause);
        }
        // Update metadata
        if (
          !title ||
          e.events.includes(PlaybackSessionEventKeys.ContentMetadata)
        ) {
          const seriesTitle =
            session.playbackSessionState.content?.metadata?.series?.title;
          if (seriesTitle) {
            const seasonNumber =
              session.playbackSessionState.content?.metadata?.series?.season;
            const episodeNumber =
              session.playbackSessionState.content?.metadata?.series?.episode;
            if (seasonNumber && episodeNumber) {
              const constructedSecondaryTitle = `${translations?.season || "Season"} ${seasonNumber} • ${translations?.episode || "Episode"} ${episodeNumber}`;
              setSecondaryTitle(constructedSecondaryTitle);
            }
            setTitle(seriesTitle);
          } else {
            setTitle(session.playbackSessionState.content?.metadata?.title);
          }
        }
        // Update volume level, 0-1 on receiver, 0-100 in the ui
        setActiveVolume(
          typeof session.playbackSessionState.volume?.volume !== "undefined"
            ? session.playbackSessionState.volume?.volume
            : 1
        );

        // Update playback position
        if (e.events.includes(PlaybackSessionEventKeys.TimelinePosition)) {
          if (
            session.playbackSessionState.timeline?.streamType ===
            StreamTypes.OnDemand
          ) {
            setPosition(
              typeof session.playbackSessionState.timeline?.position !==
                "undefined"
                ? session.playbackSessionState.timeline.position
                : -1
            );
          } else {
            setPosition(
              typeof session.playbackSessionState.timeline?.position !==
                "undefined"
                ? session.playbackSessionState.timeline.timelineStartOffset +
                    (session.playbackSessionState.timeline.position -
                      session.playbackSessionState.timeline.stream0Time)
                : -1
            );
          }
        }
        // update timeline
        if (
          duration === 1 ||
          e.events.includes(PlaybackSessionEventKeys.Timeline)
        ) {
          if (session.playbackSessionState.timeline) {
            setDuration(
              session.playbackSessionState.timeline.timelineEnd -
                session.playbackSessionState.timeline.timelineStart
            );
            setAdMarkers(extractAdMarkers(session));
          }
          setSeekableEndRange(
            typeof session.playbackSessionState.timeline?.seekableRange?.end !==
              "undefined"
              ? session.playbackSessionState.timeline?.seekableRange.end -
                  session.playbackSessionState.timeline?.seekableRange.start
              : 0
          );
        }
        setIsPaused(
          session.playbackSessionState.playbackState === PlaybackStates.Paused
        );
        setSessionState(session);
      } catch (_e) {
        // console.log(_e);
      }
    };

    session.on(
      UselessSessionEvents.PlaybackSessionStateChanged,
      playbackSessionStateHandler
    );
  }, [
    activeTracks,
    canPause,
    canSeek,
    duration,
    session,
    title,
    translations?.episode,
    translations?.season,
  ]);

  const OFF_TEXT_TRACK: Track = useMemo(
    () => ({
      active: false,
      id: -1,
      language: "off",
      name: translations?.off || "Off",
      referenceId: "-1",
      roles: [Roles.Caption],
      type: TrackTypes.Text,
    }),
    [translations?.off]
  );

  const getAudioTracks = () =>
    sessionState?.tracks.filter((t) => t.type === TrackTypes.Audio);
  const getTextTracks = () => {
    const textTracks = sessionState?.tracks.filter(
      (t) => t.type === TrackTypes.Text
    );

    textTracks?.unshift(OFF_TEXT_TRACK);
    return textTracks;
  };

  const getIsLoading = () => {
    if (!sessionState) return false;
    return (
      sessionState.playbackSessionState.playbackState ===
        PlaybackStates.Buffering ||
      sessionState.playbackSessionState.playbackState ===
        PlaybackStates.Seeking ||
      sessionState.playbackSessionState.playbackState === PlaybackStates.Loading
    );
  };

  const deviceChecker = setInterval(() => {
    if (!devicesFound && sender.devicesFound) {
      setDevicesFound(true);
      clearInterval(deviceChecker);
    }
  }, DEVICE_CHECK_INTERVAL);

  useEffect(() => {
    setSenderState(sender.sessionState);
  }, [sender.sessionState]);

  useEffect(() => {
    sender
      .initialize({
        applicationId: applicationId,
        useEmulator: false,
      })
      .then(() => {
        sender.on(
          UselessSenderEvents.SessionStarted,
          (e: SessionStartedEvent) => {
            setSessionStarted(true);
            if (e.session) {
              setSession(e.session);
            }
          }
        );

        sender.on(UselessSenderEvents.SessionEnded, (_e: SessionEndedEvent) => {
          setSessionStarted(false);

          // Other cleanup
          session?.off();
          setSession(undefined);
        });
      });
  }, [applicationId, sender, session]);

  useEffect(() => {
    if (sender.sessionState === SessionStates.Started) {
      // reset metadata?
      setTitle("");
      setSecondaryTitle("");
      setPosition(-1);
      setDuration(1);
      setSeekableEndRange(0);
      setIsActive(true);
    } else if (sender.sessionState === SessionStates.Ended) {
      setIsActive(false);
    }
  }, [sender.sessionState]);

  const uselessContextValue = useMemo(
    () => ({
      credentials,
      devicesFound,
      isChromecastBarVisible,
      preferredLanguage,
      sender,
      senderState,
      serviceCountry,
      sessionStarted,
      translations,
    }),
    [
      credentials,
      devicesFound,
      isChromecastBarVisible,
      preferredLanguage,
      sender,
      senderState,
      sessionStarted,
      serviceCountry,
      translations,
    ]
  );

  return (
    <ThemeProvider breakpoints={breakpoints} designTokens={designTokens}>
      <UselessContext.Provider value={uselessContextValue}>
        {children}

        {isChromecastBarVisible && sessionState && (
          <UselessChromecastBar
            activeAudioTrack={activeTracks?.audio}
            activeTextTrack={activeTracks?.text || OFF_TEXT_TRACK}
            activeVolume={activeVolume * 100}
            adMarkers={adMarkers}
            audioTracks={getAudioTracks()}
            canPause={!!canPause}
            canSeek={!!canSeek}
            currentTime={position}
            duration={duration}
            isLoading={getIsLoading()}
            isMuted={isMuted}
            isPaused={isPaused}
            onMute={() => {
              sessionState
                .setVolume({
                  muted: true,
                  volume: activeVolume,
                })
                .then(() => {
                  setIsMuted(true);
                });
            }}
            onSeek={(position) => {
              sessionState.seek({ position }).then(() => {
                setPosition(position);
              });
            }}
            onSetAudioTrack={(track: Track) => {
              sessionState.setAudioTrackByTrack(track);
            }}
            onSetTextTrack={(track: Track) => {
              sessionState.setTextTrackByTrack(
                track !== OFF_TEXT_TRACK ? track : undefined
              );
            }}
            onSetVolume={(volume) => {
              sessionState.setVolume({ volume: volume / 100 }).then(() => {
                setActiveVolume(volume / 100);
              });
            }}
            onStop={stop}
            onTogglePlay={() => {
              if (
                sessionState.playbackSessionState.playbackState ===
                PlaybackStates.Paused
              ) {
                sessionState.play().then(() => setIsPaused(false));
              } else {
                sessionState.pause().then(() => setIsPaused(true));
              }
            }}
            onUnmute={() => {
              sessionState
                .setVolume({
                  muted: false,
                  volume: activeVolume,
                })
                .then(() => {
                  setIsMuted(false);
                });
            }}
            secondaryTitle={secondaryTitle}
            seekableEndRange={canPause ? seekableEndRange : undefined}
            showProgressBar={position !== -1}
            textTracks={getTextTracks()}
            title={title}
          />
        )}
      </UselessContext.Provider>
    </ThemeProvider>
  );
};
