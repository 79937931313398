/* global PerformanceObserver */
var NpawObject = require('../object')
var RUMSpeedIndex = require('./speedIndex')

/**
 * This static class provides information about the load times of the page
 *
 * @constructs NpawObject
 * @extends npaw.NpawObject
 * @memberof npaw
 *
 */
var BrowserLoadTimes = NpawObject.extend({
  constructor: function (plugin) {
    this.appAnalytics = plugin.appAnalytics;
    this.timeObject = undefined;
    this.playerSetup = undefined;
    this.myTimesObject = {};
    this.perfObject = undefined;

    if (typeof window !== 'undefined' && typeof window.addEventListener === 'function') {
      window.addEventListener('load', this._windowLoaded.bind(this));
      if (window.performance && window.performance.timing) {
        try {
          if (typeof window.performance.getEntriesByType === 'function') {
            this.perfObject = window.performance;
          }
        } catch (err) {
          // Nothing
        }
        this.timeObject = window.performance.timing;
      }
    }
    try {
      if (typeof PerformanceObserver === 'function') {
        const observer = new PerformanceObserver((list, obj) => {
          const entries = list.getEntries();
          this.myTimesObject.largestContentfulPaint = entries[entries.length - 1].renderTime;
        });
        if (PerformanceObserver.supportedEntryTypes.indexOf('largest-contentful-paint') > -1) {
          observer.observe({ entryTypes: ['largest-contentful-paint'] });
        }
      }
    } catch (err) {
      // Cant use PerformanceObserver, entryTypes...
    }
  },

  _windowLoaded: function () {
    this.myTimesObject.onLoad = new Date().getTime();
    this._getEnoughFPS();
    setTimeout(this._fireLoadTimesEvent.bind(this), 1000);
  },

  _fireLoadTimesEvent: function () {
    this._getLastMetrics();
    if (this.appAnalytics) {
      this.appAnalytics.fireEvent('loadTimes', {}, this._getAllValues());
    }
  },

  _getAllValues: function () {
    const ret = {
      // Time between the navigation starts and the page loadEventEnd
      PageLoadTime: this.getPageLoadTime(),
      // Domain lookup time
      DNSTime: this.getDnsTime(),
      // Connection time
      TCPTime: this.getTcpTime(),
      // Handshake (https) connection time
      HandshakeTime: this.getHandshakeTime(),
      // Time between navigation starts and DOM is ready
      DomReadyTime: this.getDomReadyTime(),
      // Time between navigation start and beginning of the backend response
      BackendTime: this.getBackendTime(),
      // Time between navigation starts and page load event
      FrontendTime: this.getFrontendTime(),
      // The maximum of First Paint / First Contentful Paint and domContentLoadedEventEnd
      VisualReady: this.getTimeToVisuallyReady(),
      // https://developer.mozilla.org/en-US/docs/Glossary/Time_to_interactive
      TimeToInteractive: this.getTimeToInteractive(),
      // Accumulated time to download all the JS files
      JsTime: this.getJSTime(),
      // Accumulated time to download all the CSS files
      CssTime: this.getCSSTime(),
      // Accumulated time to download all the images
      ImageTime: this.getImageTime(),
      // Accumulated time to download all the fonts
      FontTime: this.getFontTime(),
      // Average latency of all the file requests
      AvgReqLatency: this.getAvgReqLatency(),
      // Highest latency of all the file requests
      MaxReqLatency: this.getMaxReqLatency(),
      // https://developer.mozilla.org/en-US/docs/Glossary/First_paint
      FirstPaint: this.getFirstPaint(),
      // https://developer.mozilla.org/en-US/docs/Glossary/First_contentful_paint
      FirstContentfulPaint: this.getFirstContentfulPaint(),
      // https://rockcontent.com/blog/largest-contentful-paint/
      LargestContentfulPaint: this.getLargestContentfulPaint(),
      // https://developer.mozilla.org/en-US/docs/Glossary/Speed_index
      SpeedIndex: this.getSpeedIndex()
    };
    for (const key in ret) {
      if (ret[key] === null || ret[key] === undefined || ret[key] < 0) {
        delete ret[key];
      } else {
        ret[key] = Math.round(ret[key]);
      }
    }
    return ret;
  },

  // Getters

  getPageLoadTime: function () {
    let ret;
    if (this.timeObject) {
      ret = this.timeObject.loadEventEnd - this.timeObject.navigationStart;
    }
    return ret;
  },

  getPlayerStartupTime: function () {
    let ret;
    if (this.timeObject && this.playerSetup) {
      ret = this.playerSetup - this.timeObject.navigationStart;
    }
    return ret;
  },

  getDnsTime: function () {
    let ret;
    if (this.timeObject) {
      ret = this.timeObject.domainLookupEnd - this.timeObject.domainLookupStart;
    }
    return ret;
  },

  getTcpTime: function () {
    let ret;
    if (this.timeObject) {
      ret = this.timeObject.connectEnd - this.timeObject.connectStart;
    }
    return ret;
  },

  getHandshakeTime: function () {
    let ret;
    if (this.timeObject && this.timeObject.secureConnectionStart) {
      ret = this.timeObject.connectEnd - this.timeObject.secureConnectionStart;
    }
    return ret;
  },

  getDomReadyTime: function () {
    let ret;
    if (this.timeObject) {
      ret = this.timeObject.domComplete - this.timeObject.navigationStart;
    }
    return ret;
  },

  getBackendTime: function () {
    let ret;
    if (this.timeObject) {
      ret = this.timeObject.responseStart - this.timeObject.navigationStart;
    }
    return ret;
  },

  getFrontendTime: function () {
    let ret;
    if (this.timeObject) {
      ret = this.myTimesObject.onLoad - this.timeObject.responseStart;
    }
    return ret;
  },

  getTimeToVisuallyReady: function () {
    const ret = this.myTimesObject.firstPaint || 0;
    if (this.timeObject) {
      return Math.max(
        ret,
        this.timeObject.domContentLoadedEventEnd - this.timeObject.navigationStart || 0
      // this.myTimesObject.heroImages - this.timeObject.navigationStart || 0
      );
    }
    return ret || undefined;
  },

  getTimeToInteractive: function () {
    if (this.myTimesObject.fps && this.getTimeToVisuallyReady()) {
      return Math.max(this.myTimesObject.fps, this.getTimeToVisuallyReady());
    } else {
      setTimeout(() => {
        this.getTimeToInteractive();
      }, 500);
    }
  },

  getJSTime: function () {
    return this._getXTime('script');
  },

  getCSSTime: function () {
    return this._getXTime('css');
  },

  getImageTime: function () {
    return this._getXTime('img');
  },

  getFontTime: function () {
    return this._getXTime('css', ['.woff', '.otf', '.ttf']);
  },

  getAvgReqLatency: function () {
    try {
      if (this.perfObject && typeof this.perfObject.getEntriesByType === 'function') {
        let count = 0;
        let latency = 0;
        const scripts = this.perfObject.getEntriesByType('resource');
        for (var i in scripts) {
          if (scripts[i].requestStart && scripts[i].responseStart) {
            latency += scripts[i].responseStart - scripts[i].requestStart;
          }
          count++;
        }
        return latency / count;
      }
    } catch (err) {
      // Nothing
    }
    return undefined;
  },

  getFirstPaint: function () {
    return this.myTimesObject ? this.myTimesObject.firstPaint : undefined;
  },

  getFirstContentfulPaint: function () {
    return this.myTimesObject ? this.myTimesObject.firstContentfulPaint : undefined;
  },

  getLargestContentfulPaint: function () {
    return this.myTimesObject ? this.myTimesObject.largestContentfulPaint : undefined;
  },

  getMaxReqLatency: function () {
    try {
      if (this.perfObject && typeof this.perfObject.getEntriesByType === 'function') {
        const scripts = this.perfObject.getEntriesByType('resource');
        let latency = 0;
        for (const i in scripts) {
          if (scripts[i].requestStart && scripts[i].responseStart) {
            latency = Math.max(latency, scripts[i].responseStart - scripts[i].requestStart);
          }
        }
        return latency;
      }
    } catch (err) {
      // Nothing
    }
    return undefined;
  },

  getSpeedIndex: function () {
    let ret;
    if (typeof window !== 'undefined' && window.performance && typeof window.performance.getEntriesByType === 'function') {
      try {
        ret = RUMSpeedIndex();
      } catch (e) {
        // nothing
      }
    }
    return ret;
  },

  _getXTime: function (type, validExtensions) {
    let ret = 0;
    try {
      if (this.perfObject && typeof this.perfObject.getEntriesByType === 'function') {
        const scripts = this.perfObject.getEntriesByType('resource');
        for (const i in scripts) {
          if (scripts[i].initiatorType === type) {
            if (!validExtensions) {
              ret += scripts[i].duration;
            } else {
              let valid = false;
              for (var ext in validExtensions) {
                if (scripts[i].name.indexOf(validExtensions[ext] > 0)) {
                  valid = true;
                }
              }
              if (valid) {
                ret += scripts[i].duration;
              }
            }
          }
        }
      }
    } catch (err) {
      // Nothing
    }
    return Math.round(ret) || undefined;
  },

  _getLastMetrics: function () {
    let firstPaint = undefined;
    let contentfulPaint = undefined;
    try {
      if (this.perfObject && typeof this.perfObject.getEntriesByType === 'function') {
        const entries = this.perfObject.getEntriesByType('paint');
        for (const i in entries) {
          if (entries[i].name === 'first-paint') {
            firstPaint = entries[i].startTime;
          } else if (entries[i].name === 'first-contentful-paint') {
            contentfulPaint = entries[i].startTime;
          }
        }
      }
    } catch (err) {
      // Nothing
    }
    // First paint
    if (!firstPaint && this.timeObject) {
      firstPaint = this.timeObject.msFirstPaint - this.timeObject.navigationStart;
    }
    // TODO first paint for firefox
    this.myTimesObject.firstPaint = firstPaint;
    // First contentful paint
    this.myTimesObject.firstContentfulPaint = contentfulPaint;
    // Others
    this.getTimeToInteractive();
  },

  _getEnoughFPS: function () {
    if (this.timeObject && typeof window !== 'undefined') {
      const req = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function () {};
      this.preFPS = new Date().getTime();
      req(() => {
        const now = new Date().getTime();
        if (now < this.preFPS + 50) {
          this.myTimesObject.fps = now - this.timeObject.navigationStart;
        } else {
          setTimeout(() => {
            return this._getEnoughFPS();
          }, 50);
        }
      });
    } else {
      return true;
    }
  },

  // Setters

  setPlayerSetupTime: function () {
    this.playerSetup = this.playerSetup || new Date().getTime();
  }
});

module.exports = BrowserLoadTimes;
