var AdapterConstants = require('../constants/adapter')
const { default: Log } = require('../../common/log')
const { AnalyticsTag } = require('../../common/Constants');

var AdapterContentMixin = {
  /** Override to return current playrate */
  getPlayrate: function () {
    return !this.flags.isPaused ? 1 : 0;
  },

  /** Override to return Frames Per Second (FPS) */
  getFramesPerSecond: function () {
    return undefined;
  },

  /** Override to return dropped frames since start */
  getDroppedFrames: function () {
    return undefined;
  },

  /** Override to return user bandwidth throughput */
  getThroughput: function () {
    return undefined;
  },

  /** Override to return rendition */
  getRendition: function () {
    return undefined;
  },

  /** Override to return title2 */
  getTitle2: function () {
    return undefined;
  },

  /** Override to recurn true if live and false if VOD */
  getIsLive: function () {
    return undefined;
  },

  /** Override to return CDN traffic bytes not using streamroot or peer5. */
  getCdnTraffic: function () {
    return undefined;
  },

  /** Override to return P2P traffic bytes not using streamroot or peer5. */
  getP2PTraffic: function () {
    return undefined;
  },

  /** Override to return P2P traffic sent in bytes, not using streamroot or peer5. */
  getUploadTraffic: function () {
    return undefined;
  },

  /** Override to return if P2P is enabled not using streamroot or peer5. */
  getIsP2PEnabled: function () {
    return undefined;
  },

  /** Override to return video segment duration. */
  getSegmentDuration: function () {
    return undefined;
  },

  /** Override to return household id */
  getHouseholdId: function () {
    return undefined;
  },

  /** Override to return the latency */
  getLatency: function () {
    return undefined;
  },

  /** Override to return the number of packets lost */
  getPacketLoss: function () {
    return undefined;
  },

  /** Override to return the number of packets sent */
  getPacketSent: function () {
    return undefined;
  },

  /** Override to return a json with metrics */
  getMetrics: function () {
    return undefined;
  },

  /** Override to return the audio codec */
  getAudioCodec: function () {
    return undefined;
  },

  /** Override to return the video codec */
  getVideoCodec: function () {
    return undefined;
  },

  /** Override to return a chunk or intermediate manifest url */
  getURLToParse: function () {
    return undefined;
  },

  /**
 * Emits related event and set flags if current status is valid.
 * ie: won't sent start if isStarted is already true.
 *
 * @param {Object} [params] Object of key:value params to add to the request.
 * @param {bool} [convertFromBuffer=true] If false, will convert current buffer to seek.
 * @param {string} [triggeredEvent]
 */
  fireSeekBegin: function (params, convertFromBuffer, triggeredEvent) {
    // First, discard false buffers triggered by state changed before seek
    this._discardFalseBuffers()
    if (this.getVideo() && this.getVideo().getIsLive() && this.plugin.options['content.isLive.noSeek']) return undefined;
    if (this.flags.isJoined && !this.flags.isSeeking) {
      if (this.flags.isBuffering) {
        if (convertFromBuffer !== false) {
          Log.notice(AnalyticsTag, 'Converting current buffer to seek')

          this.chronos.seek = this.chronos.buffer.clone()
          this.chronos.buffer.reset()

          this.flags.isBuffering = false
        } else {
          return
        }
      } else {
        this.chronos.seek.start()
      }

      try {
        // Create new seek empty structure to add event to detect seek begins
        this.fireEventsStruct.seek = []
        if (triggeredEvent) {
          this.fireEventsStruct.seek.push(triggeredEvent)
        } else {
          this.fireEventsStruct.seek.push('undefinedEvent')
        }
      } catch (e) {}

      this.flags.isSeeking = true
      this.emit(AdapterConstants.Event.SEEK_BEGIN, { params: params })
    }
  },

  /**
   * Emits related event and set flags if current status is valid.
   * ie: won't sent start if isStarted is already true.
   *
   * @param {Object} [params] Object of key:value params to add to the request.
   */
  fireSeekEnd: function (params, triggeredEvent) {
    if (this.getVideo() && this.getVideo().getIsLive() && this.plugin.options['content.isLive.noSeek']) return undefined;
    if (this.flags.isJoined && this.flags.isSeeking) {
      try {
        // Add triggered event to detect buffer ends
        if (triggeredEvent) {
          this.fireEventsStruct.seek.push(triggeredEvent)
        } else {
          this.fireEventsStruct.seek.push('undefinedEvent')
        }
      } catch (e) {}

      params = params || {}
      params.triggeredEvents = this.fireEventsStruct.seek

      this.cancelSeek()
      this.emit(AdapterConstants.Event.SEEK_END, { params: params })

      try {
        if (this.chronos.pause.getDeltaTime(false) > 0) {
          this.chronos.pause.resume();
        }
      } catch (e) {}
    }
  },

  /**
   *
   * @param {Object} [params] Object of key:value params to add to the request.
   */
  cancelSeek: function (params) {
    if (this.flags.isJoined && this.flags.isSeeking) {
      this.flags.isSeeking = false

      this.chronos.seek.stop()

      if (this.monitor) this.monitor.skipNextTick()
    }
  },

  /**
 * Emits event request.
 *
 * @param {Object} [params] Object of key:value params to add to the request.
 */
  fireEvent: function (eventName, dimensions, values, topLevelDimensions) {
    if (eventName === "data") {
      this.emit(AdapterConstants.Event.DATA_OBJECT, { params: topLevelDimensions });
    } else {
    var returnparams = topLevelDimensions || {}
    returnparams.name = eventName || ''
    returnparams.dimensions = dimensions || {}
    returnparams.values = values || {}
    this.emit(AdapterConstants.Event.VIDEO_EVENT, { params: returnparams })
    }
  },

  storeNewRendition: function (rendition) {
    this.getVideo().triggerUpdateRendition(rendition);
  }
}

module.exports = AdapterContentMixin
