import styled from "@emotion/styled";
import { TPlayerStats } from "@telia-company/tv.web-player-shared";
import { FC } from "react";

import { BitrateGraph } from "./BitrateGraph";

const StatItem = styled.div<{ alert?: boolean }>`
  background: ${({ alert }) =>
    alert ? "rgba(0, 0, 0, 0.1)" : "rgba(255, 255, 255, 0.2)"};
  border: 1px solid
    ${({ alert }) => (alert ? "rgba(255, 255, 255, 0.2)" : "transparent")};
  padding: 0.5rem;
  font-size: 1rem;
  margin: 0 0 0.5rem;
`;

export type Props = {
  playerStats: TPlayerStats;
  videoElement: HTMLVideoElement;
};

export const Stats: FC<Props> = ({ playerStats, videoElement }) => {
  const badBandwidthRatio =
    (playerStats.streamBandwidth || 0) > (playerStats.estimatedBandwidth || 0);

  return (
    <>
      <BitrateGraph stats={playerStats} videoElement={videoElement} />

      {!!playerStats.width && (
        <StatItem>
          Resolution: {playerStats.width} x {playerStats.height}
        </StatItem>
      )}

      {!!playerStats.streamBandwidth && (
        <StatItem alert={badBandwidthRatio}>
          Bandwidth: {(playerStats.streamBandwidth / 1024 / 1024).toFixed(2)} Mb
        </StatItem>
      )}

      {!!playerStats.estimatedBandwidth && (
        <StatItem alert={badBandwidthRatio}>
          Est. Bandwidth:{" "}
          {(playerStats.estimatedBandwidth / 1024 / 1024).toFixed(2)} Mb
        </StatItem>
      )}

      {!!playerStats.drmTimeSeconds && (
        <StatItem alert={playerStats.drmTimeSeconds > 1}>
          DRM Time: {playerStats.drmTimeSeconds.toFixed(2)}s
        </StatItem>
      )}

      {!!playerStats.manifestTimeSeconds && (
        <StatItem alert={playerStats.manifestTimeSeconds > 1}>
          Manifest Load Time: {playerStats.manifestTimeSeconds.toFixed(2)}s
        </StatItem>
      )}

      {!!playerStats.licenseTime && (
        <StatItem>License Time: {playerStats.licenseTime.toFixed(2)}s</StatItem>
      )}

      {!!playerStats.bufferingTime && (
        <StatItem>
          Buffer & Seek: {playerStats.bufferingTime.toFixed(2)}s
        </StatItem>
      )}

      {!!playerStats.playTime && (
        <StatItem>Play: {playerStats.playTime.toFixed(2)}s</StatItem>
      )}

      {!!playerStats.pauseTime && (
        <StatItem>Pause Time: {playerStats.pauseTime.toFixed(2)}s</StatItem>
      )}

      {!!playerStats.playTime && !!playerStats.bufferingTime && (
        <StatItem
          alert={playerStats.bufferingTime / playerStats.playTime > 0.3}
        >
          Buffer Ratio:{" "}
          {((playerStats.bufferingTime / playerStats.playTime) * 100).toFixed(
            2
          )}
          %
        </StatItem>
      )}

      {!!playerStats.decodedFrames && (
        <StatItem>Decoded Frames: {playerStats.decodedFrames}</StatItem>
      )}

      {!!playerStats.droppedFrames && (
        <StatItem alert>Dropped Frames: {playerStats.droppedFrames}</StatItem>
      )}

      {!!playerStats.corruptedFrames && (
        <StatItem alert>
          Corrupted Frames: {playerStats.corruptedFrames}
        </StatItem>
      )}

      {!!playerStats.liveLatency && (
        <StatItem>Live Latency: {playerStats.liveLatency.toFixed(2)}s</StatItem>
      )}

      {!!playerStats.loadLatency && (
        <StatItem alert={playerStats.loadLatency > 1}>
          Load Latency: {playerStats.loadLatency.toFixed(2)}s
        </StatItem>
      )}

      {!!playerStats.maxSegmentDuration && (
        <StatItem>
          Max Segment Duration: {playerStats.maxSegmentDuration.toFixed(2)}s
        </StatItem>
      )}
    </>
  );
};
