import { AdvertisementBreakTypes, PlaybackEventTypes } from "../Constants";
import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import { TEmptyPayload } from "./Types";

export type TAdvertisementBreakEndedEventPayload = {
  breakType: AdvertisementBreakTypes;
  id: string;
};

export class AdvertisementBreakEndedEvent extends PlaybackEventBase<
  PlaybackEventTypes.ADVERTISEMENT_BREAK_ENDED,
  TAdvertisementBreakEndedEventPayload
> {
  constructor(payload: TAdvertisementBreakEndedEventPayload) {
    super(PlaybackEventTypes.ADVERTISEMENT_BREAK_ENDED, payload);
  }
}
