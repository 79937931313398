export default class DiagnosticBalancerCdn {
    constructor(name, provider) {
        this.columns = ['CDN', 'Provider', '# of requests', '% of failed req.', 'Traffic served (MB)'];
        this.requestsPerformed = 0;
        this.trafficServedInBytes = 0;
        this.failedRequests = 0;
        this.name = name;
        this.provider = provider;
    }
    setRequestsPerformed(numberOfRequests) {
        this.requestsPerformed = numberOfRequests;
    }
    setTrafficServerInBytes(traffic) {
        this.trafficServedInBytes = traffic;
    }
    setFailedRequests(failed) {
        this.failedRequests = failed;
    }
    getFailedRequestsPercentage() {
        const totalRequests = this.requestsPerformed + this.failedRequests;
        return totalRequests !== 0 ? ((this.failedRequests / totalRequests) * 100) : 0;
    }
    generateRow() {
        let failed = this.requestsPerformed !== 0 ? ((this.failedRequests / this.requestsPerformed) * 100) : 0;
        failed = this.requestsPerformed === 0 && this.failedRequests > 0 ? 100 : failed;
        let color = '';
        if (failed > 70) {
            color = 'red';
        }
        else if (failed > 30) {
            color = 'yellow';
        }
        const elems = [this.name, this.provider, this.requestsPerformed + this.failedRequests, failed.toFixed(0), (this.trafficServedInBytes / (1024 * 1024)).toFixed(2)];
        return { row: '%c' + elems.map(elem => elem.toString().padEnd(18)).join(''), color: color };
    }
}
