import NpawPluginOptions from './NpawPluginOptions';
export default class CoreOptions {
    constructor() {
        /**
         * If true the plugin will store the events and send them later when there's connection
         * Default: false
         */
        this.offline = false;
        /**
         * Host of the Fastdata service
         * Default: 'lma.npaw.com'
         */
        this.host = 'lma.npaw.com';
        /**
         * To disable cookies storage fallback after local/sessionstorage
         * Default: true
         */
        this.disableCookies = true;
        /**
         * To force the use of cookies storage instead of local/sessionstorage
         * Default: false
         */
        this.forceCookies = false;
        /**
         * To disable all possible storages usage (cookies, localStorage, sessionStorage)
         * CAUTION: enabling this option session tracking may stop to work properly
         * Default: false
         */
        this.disableStorage = false;
        /**
         * Optional auth token to validate all the requests
         */
        this.authToken = undefined;
        /**
         * Optional auth type. Used if authToken is set.
         * 'Bearer' by default.
         */
        this.authType = 'Bearer';
        /**
         * Define the security of NQS calls.
         * If true it will use 'https://',
         * if false it will use 'http://',
         * if null/undefined it will use '//'.
         * Default: true
         */
        this['app.https'] = true;
        /**
         * Enable get configuration from LMA periodically
         * Default: false
         */
        this['lma.refresh.configuration.enabled'] = false;
        /**
         * Time to get configuration from LMA service
         * Default: 300 (5 minutes)
         */
        this['lma.refresh.configuration.seconds'] = 300;
        /**
         * Parses fastdata response to modify the host where the requests will be sent
         * Default: undefined
         */
        this['parse.fdsResponseHost'] = null;
    }
    /**
     * Updates core options based on the received
     * @param options AnalyticsOptions object
     */
    updateOptions(options) {
        const npawPluginOptions = new NpawPluginOptions();
        Object.keys(this).forEach((key) => {
            // Do not set options that are undefined or that belong to NPAW Plugin options
            if (options[key] != undefined && !Object.keys(npawPluginOptions).includes(key)) {
                this[key] = options[key];
            }
        });
    }
    /**
     * Sets NPAW Plugin options that belong to the core
     * @param options
     */
    setNpawPluginOptions(options) {
        Object.keys(this).forEach((key) => {
            if (options[key] != undefined) {
                this[key] = options[key];
            }
        });
    }
    isRefreshLMAConfigurationEnabled() {
        return this['lma.refresh.configuration.enabled'];
    }
    refreshLMAConfigurationSeconds() {
        return this['lma.refresh.configuration.seconds'];
    }
    getHost() {
        return this.host;
    }
    /**
     * Returns the FastData host
     *
     */
    getFastDataHost() {
        return this.host;
    }
}
