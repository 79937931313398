import {
  FilteredMediaEvent,
  getMediaEventFilter,
  TMediaEventFilter,
} from "@eyevinn/media-event-filter";

import { TEventHandler, TVideoEventHandlerOptions } from "../shared-types";

const handleDroppedFrames = ({
  callbacks,
  videoElement,
}: TVideoEventHandlerOptions): void => {
  if (videoElement.getVideoPlaybackQuality) {
    const { droppedVideoFrames: frames, totalVideoFrames: total } =
      videoElement.getVideoPlaybackQuality();

    callbacks.onDroppedFrames({
      frames,
      total,
    });
  } else {
    const {
      // TODO create extended HTMLVideoElement interface
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      webkitDecodedFrameCount,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      webkitDroppedFrameCount,
    } = videoElement;

    if (Number.isInteger(webkitDroppedFrameCount)) {
      callbacks.onDroppedFrames({
        frames: webkitDroppedFrameCount,
        total: webkitDecodedFrameCount,
      });
    }
  }
};

export const getVideoEventHandler = (
  options: TVideoEventHandlerOptions
): TEventHandler => {
  const { callbacks, videoElement } = options;
  let listenersAdded = false;

  let mediaEventFilter: TMediaEventFilter | undefined;

  const onVolumeChanged = () => {
    const { muted, volume } = videoElement;

    callbacks.onVolumeChanged({
      muted,
      volume,
    });
  };

  return {
    addEventListeners: () => {
      if (listenersAdded)
        throw new Error("Video event listeners already bound");

      listenersAdded = true;

      mediaEventFilter = getMediaEventFilter({
        callback: (event) => {
          switch (event) {
            // buffering currently handled by shaka integration
            //            case FilteredMediaEvent.BUFFERED:
            //              publish(new BufferedEvent());
            //              break;
            //            case FilteredMediaEvent.BUFFERING:
            //              publish(new BufferingEvent());
            //              detectGapStall({ videoElement, publish });
            //              break;
            case FilteredMediaEvent.ENDED:
              callbacks.onEnded({
                currentTime: videoElement.currentTime,
                duration: videoElement.duration,
              });
              break;
            case FilteredMediaEvent.LOADED:
              callbacks.onLoaded({});
              break;
            case FilteredMediaEvent.PAUSE:
              callbacks.onPaused({});
              break;
            case FilteredMediaEvent.PLAY:
              callbacks.onPlay({});
              break;
            case FilteredMediaEvent.PLAYING:
              callbacks.onPlaying({});
              break;
            case FilteredMediaEvent.SEEKED:
              callbacks.onSeeked({});
              break;
            case FilteredMediaEvent.SEEKING:
              callbacks.onSeeking({});
              break;
            case FilteredMediaEvent.TIME_UPDATE:
              handleDroppedFrames(options);

              callbacks.onTimeUpdate({
                currentTime: videoElement.currentTime,
                duration: videoElement.duration,
              });
              break;
            default:
              break;
          }
        },
        mediaElement: videoElement,
      });

      videoElement.addEventListener("volumechange", onVolumeChanged);
    },
    removeEventListeners: () => {
      listenersAdded = false;

      mediaEventFilter?.teardown();
      videoElement.removeEventListener("volumechange", onVolumeChanged);
    },
  };
};
