import Bowser from "bowser";

export class OperatingSystemInfo {
  public operatingSystemName: string;
  public operatingSystemVersion: string;
  public operatingSystemFirmwareVersion?: string;

  constructor(args: OperatingSystemInfo) {
    const os = Bowser.getParser(window.navigator.userAgent).getOS();
    this.operatingSystemName = args.operatingSystemName || os.name || "Unknown";
    this.operatingSystemVersion =
      args.operatingSystemVersion || os.version || "Unknown";
    this.operatingSystemFirmwareVersion =
      args.operatingSystemFirmwareVersion || os.versionName;
  }
}
