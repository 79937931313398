import { useCallback, useEffect, useRef, useState } from "react";

import { UiEventTypes } from "../constants";
import { noop } from "../utils";
import {
  useClickHandler,
  useDispatch,
  usePlayerControls,
  usePlayerState,
  useTranslations,
} from "./context-provider";

export type TUseSkipClick = {
  numberOfSkips: number;
  skip: (direction: number) => void;
};

export const useSkipClick = (): TUseSkipClick => {
  const [numbersSkipClicked, setNumbersSkipClicked] = useState(0);
  const skipSeekTimeoutRef = useRef<number | undefined>(undefined);
  const { onSeek } = useClickHandler();
  const dispatch = useDispatch();
  const {
    isLive,
    liveSeekEnabled,
    trickPlayRestrictions: { noFastForward },
  } = usePlayerState();
  const controls = usePlayerControls();
  const { scrubbingForwardNotAllowed, scrubbingNotAllowed } = useTranslations();
  const onSeekRef = useRef(onSeek);

  // Because onSeek is recreated every second (seekRange is updated in click handlers)
  // it arbitrarily delays skipSeekTimeoutRef by resetting the timeout. Use a ref
  // instead to make sure it doesn't cause re-triggers of the useEffect hook.
  onSeekRef.current = onSeek;

  const skip = useCallback(
    (direction: number) => {
      if (isLive && !liveSeekEnabled) {
        dispatch({
          payload: {
            message: scrubbingNotAllowed,
          },
          type: UiEventTypes.SHOW_TOAST,
        });
        return;
      }
      if (noFastForward && direction > 0) {
        dispatch({
          payload: {
            message: scrubbingForwardNotAllowed,
          },
          type: UiEventTypes.SHOW_TOAST,
        });
        return;
      }

      setNumbersSkipClicked((value) => value + direction);
    },
    [
      isLive,
      noFastForward,
      liveSeekEnabled,
      dispatch,
      scrubbingNotAllowed,
      scrubbingForwardNotAllowed,
    ]
  );

  useEffect(() => {
    if (numbersSkipClicked === 0) return;

    dispatch({
      payload: {},
      type: UiEventTypes.SHOW_SEEK_INDICATOR,
    });
  }, [numbersSkipClicked, dispatch]);

  useEffect(() => {
    if (!controls) return noop;
    if (numbersSkipClicked === 0) return noop;
    if (controls.getPosition() === undefined) return noop;

    clearTimeout(skipSeekTimeoutRef.current);

    skipSeekTimeoutRef.current = window.setTimeout(() => {
      const position = controls.getPosition();

      if (typeof position !== "number") return;

      const updatedPosition = position + numbersSkipClicked * 10;

      onSeekRef.current(updatedPosition);

      dispatch({
        payload: {},
        type: UiEventTypes.HIDE_SEEK_INDICATOR,
      });

      setNumbersSkipClicked(0);
    }, 1000);

    return () => {
      clearTimeout(skipSeekTimeoutRef.current);
    };
  }, [numbersSkipClicked, onSeekRef, dispatch, controls]);

  return {
    numberOfSkips: numbersSkipClicked,
    skip,
  };
};
