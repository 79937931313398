import { WebPlayerEventType } from "@telia-company/tv.web-player-shared";

import { TEngineMethodOptions } from "../@types/types";

export const getPlayFn = (options: TEngineMethodOptions) => (): void => {
  let canStopCheckingAutoplayBlock = false;

  const setState = () => {
    canStopCheckingAutoplayBlock = true;
  };

  const checkError = (e: Error) => {
    if (canStopCheckingAutoplayBlock) return;

    // Ignore user spamming play-pause
    if (e.name.indexOf("AbortError") > -1) return;

    if (e.name.indexOf("NotAllowedError") > -1) {
      options.callbacks.onCustomTrackingEvent({
        name: WebPlayerEventType.AUTOPLAY_BLOCKED,
      });
      options.callbacks.onAutoPlayBlocked({});
    }
  };

  options.domElements.videoElement.play().then(setState).catch(checkError);
};
