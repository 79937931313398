import styled from "@emotion/styled";
import { TState } from "@telia-company/tv.web-player-ui";
import { FC } from "react";

import { BrandColor, BrandColorDark } from "../misc/constants";

const StateItem = styled.div<{ alert?: boolean }>`
  background: ${BrandColorDark};
  border: 1px solid ${BrandColor};
  padding: 0.5rem;
  font-size: 1rem;
  margin: 0 0 0.5rem;
`;

export type Props = {
  playerState: TState;
};

const getRestrictions = ({
  trickPlayRestrictions: { noFastForward, noPause, noRewind },
}: TState) => {
  const restrictions = [];

  if (noFastForward) restrictions.push("No FFWD");
  if (noPause) restrictions.push("No Pause");
  if (noRewind) restrictions.push("No Rewind");

  return restrictions.length ? restrictions.concat(" | ") : null;
};

export const PlayerState: FC<Props> = ({ playerState }) => {
  const restrictions = getRestrictions(playerState);

  return (
    <>
      {playerState.autoplayBlocked && <StateItem>AUTOPLAY BLOCKED</StateItem>}
      {playerState.cast && <StateItem>Cast Mode: {playerState.cast}</StateItem>}
      <StateItem>Mode: {playerState.playbackType}</StateItem>
      <StateItem>Engine: {playerState.engineState}</StateItem>
      <StateItem>State: {playerState.playbackState}</StateItem>

      {playerState.isLive && (
        <StateItem>
          DVR Enabled: {playerState.liveSeekEnabled ? "YES" : "NO"}
        </StateItem>
      )}

      {playerState.playbackType === "startover" && (
        <StateItem>
          Manifest Type: {playerState.manifestChanged ? "STATIC" : "DYNAMIC"}
        </StateItem>
      )}

      {restrictions && <StateItem>Restrictions: {restrictions}</StateItem>}
    </>
  );
};
