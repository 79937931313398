import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";
import { useCallback } from "react";

export type TUseDomElementsOptions = {
  textElement: HTMLDivElement | undefined;
  videoElement: HTMLVideoElement | undefined;
};

export const useDomElements = ({
  textElement,
  videoElement,
}: TUseDomElementsOptions): {
  textWrapperCallbackRef: (node: HTMLDivElement | null) => void;
  videoWrapperCallbackRef: (node: HTMLDivElement | null) => void;
} => {
  const textWrapperCallbackRef = useCallback(
    (node: TUnfortunatelyAny) => {
      if (node && textElement) {
        node.innerHTML = "";
        node.appendChild(textElement);
      }
    },
    [textElement]
  );

  const videoWrapperCallbackRef = useCallback(
    (node: TUnfortunatelyAny) => {
      if (node && videoElement) {
        node.innerHTML = "";
        node.appendChild(videoElement);
      }
    },
    [videoElement]
  );

  return {
    textWrapperCallbackRef,
    videoWrapperCallbackRef,
  };
};
