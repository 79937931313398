export const BrandColor = "#CB5C0D";
export const BrandColorDark = "#813F0B";

export const TextColor = "#F8E5D6FF";

export const ServiceCountries = [
  { icon: "🇸🇪", id: "SE" },
  { icon: "🇫🇮", id: "FI" },
  { icon: "🇩🇰", id: "DK" },
  { icon: "🇱🇹", id: "LT" },
  { icon: "🇳🇴", id: "NO" },
];
