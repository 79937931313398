import { PlaybackEventTypes } from "../Constants";
import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import { TEmptyPayload } from "./Types";

export class BufferingEvent extends PlaybackEventBase<
  PlaybackEventTypes.BUFFERING,
  TEmptyPayload
> {
  constructor() {
    super(PlaybackEventTypes.BUFFERING, {});
  }
}
