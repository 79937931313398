import {
  TCallback,
  TPlaybackService,
} from "@telia-company/tv.web-player-shared";
import { useCallback } from "react";

export type TUseDestroyPlayerOptions = {
  playbackService: TPlaybackService;
};

export const useDestroyPlayer = ({
  playbackService,
}: TUseDestroyPlayerOptions): TCallback =>
  useCallback(() => playbackService.destroy(), [playbackService]);
