import styled from "@emotion/styled";
import { TCallback } from "@telia-company/tv.web-player-shared";
import { FC } from "react";

import { BaseButton } from "./base";

export type TButtonProps = {
  hotkey?: string; // TODO use the new enum from master
  onClick: TCallback;
  text: string;
  tooltip?: string;
  tooltipPosition?: "bottom" | "top";
};

export const TextWrapper = styled.div``;

export const Button: FC<TButtonProps> = ({
  hotkey,
  onClick,
  text,
  tooltip,
  tooltipPosition,
}) => (
  <BaseButton
    aria-label={`${tooltip}${hotkey && ` (${hotkey.toUpperCase()})`}`}
    data-microtip-position={tooltipPosition || false}
    data-tooltip={!!tooltip}
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();

      onClick();
    }}
  >
    <TextWrapper>{text}</TextWrapper>
  </BaseButton>
);
