var NpawObject = require('../object')
/**
 * This static class provides device detection methods.
 *
 * @class
 * @static
 * @memberof npaw
 */
var BackgroundDetector = NpawObject.extend({
  constructor: function (plugin) {
    this.plugin = plugin
    this.isInBackground = false
    this.listenerReference = this._visibilityListener.bind(this)
    this._reset()
  },

  startDetection: function () {
    if (!this.isBackgroundDetectorStarted && typeof document !== 'undefined') {
      this.isBackgroundDetectorStarted = true
      document.addEventListener('visibilitychange', this.listenerReference)
    }
  },

  stopDetection: function () {
    if (this.isBackgroundDetectorStarted && typeof document !== 'undefined') {
      document.removeEventListener('visibilitychange', this.listenerReference)
      this._reset()
    }
  },

  _reset: function () {
    this.isBackgroundDetectorStarted = false
  },

  _visibilityListener: function (e) {
    if (typeof document !== 'undefined') {
      var settings = this._getSettings()
      if (document.visibilityState === 'hidden') {
        this._toBackground(settings)
      } else if (document.visibilityState === 'visible') { // to Foreground
        this._toForeground(settings)
      }
    }
  },

  _toBackground: function (settings) {
    this.isInBackground = true
    try {
      if (this.plugin && this.plugin.videos) {
        for (var i = 0; i < this.plugin.videos.getVideoKeys().length; i++) {
          var videoKey = this.plugin.videos.getVideoKeys()[i]
          var adsAdapter = this.plugin.videos.getAdsAdapter(videoKey)
          if (adsAdapter) {
            adsAdapter.stopChronoView()
          }
        }
      }
    } catch (e) {}
    try {
      if (typeof settings === 'string') {
        switch (settings) {
          case 'stop':
            this._fireStop()
            break
          case 'pause':
            this._firePause()
            break
        }
        if (this.plugin.appAnalytics.appAnalyticsStarted) {
          this.lastBeatTime = new Date().getTime()
          var difftime = this.plugin.appAnalytics._beat.chrono.startTime ? (this.lastBeatTime - this.plugin.appAnalytics._beat.chrono.startTime) : 0
          this.plugin.appAnalytics._sendBeat(difftime)
          this.plugin.appAnalytics._beat.stop()
        }
      }
    } catch (e) {}
  },

  _toForeground: function (settings) {
    this.isInBackground = false
    // ads
    try {
      if (this.plugin && this.plugin.videos) {
        for (var i = 0; i < this.plugin.videos.getVideoKeys().length; i++) {
          var videoKey = this.plugin.videos.getVideoKeys()[i]
          var adsAdapter = this.plugin.videos.getAdsAdapter(videoKey)
          if (adsAdapter) {
            adsAdapter.startChronoView()
          }
        }
      }
    } catch (e) {}
    // nothing for video yet
    try {
      if (typeof settings === 'string' && settings) {
        if (this.plugin.appAnalytics.appAnalyticsStarted) {
          var now = new Date().getTime()
          if (now - this.lastBeatTime < this.plugin.appAnalytics.sessionExpire) { // if session not expired
            this.plugin.appAnalytics._sendBeat(now - this.lastBeatTime)
            this.plugin.appAnalytics._beat.start()
          } else { // session expired
            this.plugin.appAnalytics.newSession()
          }
        }
      }
    } catch (e) {}
  },

  _getSettings: function () {
    if (typeof this.plugin.options['background.settings'] === 'string' && this.plugin.options['background.settings']) {
      return this.plugin.options['background.settings']
    }
    if (this.plugin.deviceDetector.isSmartTV()) {
      return this.plugin.options['background.settings.tv']
    }
    if (this.plugin.deviceDetector.isDesktop()) {
      return this.plugin.options['background.settings.desktop']
    }
    if (this.plugin.deviceDetector.isAndroid()) {
      return this.plugin.options['background.settings.android']
    }
    if (this.plugin.deviceDetector.isIphone()) {
      return this.plugin.options['background.settings.iOS']
    }
    if (this.plugin.deviceDetector.isPlayStation()) {
      return this.plugin.options['background.settings.playstation']
    }
  },

  _firePause: function () {
    this._fireX('firePause')
  },

  _fireStop: function () {
    this._fireX('fireStop')
  },

  _fireX: function (fireMethod) {
    try {
      if (this.plugin && this.plugin.videos) {
        for (var i = 0; i < this.plugin.videos.getVideoKeys().length; i++) {
          var videoKey = this.plugin.videos.getVideoKeys()[i]
          this.adsAdapter = this.plugin.videos.getAdsAdapter(videoKey)
          if (this.adsAdapter) {
            this.adsAdapter[fireMethod]({}, 'backgroundDetector')
          }
          this.contentAdapter = this.plugin.videos.getAdapter(videoKey)
          if (this.contentAdapter) {
            this.contentAdapter[fireMethod]({}, 'backgroundDetector')
          }
        }
      }
    } catch (e) {}
  },

  canBlockStartCalls: function () {
    return (
      this.isInBackground && this.plugin.options['background.enabled'] === true && this._getSettings() // it is stop or pause
    );
  }
})

module.exports = BackgroundDetector
