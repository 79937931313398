import { getUserData, storeUserData } from "./LocalStorage";
import { guid } from "./Guid";

const anonymousUserId = "anonymous";
const dataKey = "advertisement";

export const getPersistentAdvertisementId = (userId?: string): string => {
  const userIdKey = userId || anonymousUserId;
  const userData = getUserData(userIdKey, dataKey) ?? {};
  const persistentAdvertisementId = userData.persistentAdvertisementId;

  // persistent id is already user id
  if (userData.persistentAdvertisementId === userId)
    return persistentAdvertisementId;

  // persistent id is not current user id, update it
  userData.persistentAdvertisementId = userId || guid();
  storeUserData(userIdKey, dataKey, userData);

  return userData.persistentAdvertisementId;
};
