import {
  DrmType,
  StreamingTicketData,
} from "@telia-company/tv.web-playback-sdk";
import {
  EngineLogic,
  isSafari,
  TPlaybackConfig,
  TPlayerFeatures,
  TUserConfig,
} from "@telia-company/tv.web-player-shared";

import { TServices } from "../@types/types";

export type TGetStreamingTicketOptions = {
  drmType: DrmType;
  engineLogic: EngineLogic;
  features: TPlayerFeatures;
  playback: TPlaybackConfig;
  playbackSessionId: string;
  services: TServices;
  user: TUserConfig;
};

export const getStreamingTicket = async ({
  drmType,
  engineLogic: { capabilities, supportedPackagings },
  features: { disableDVR, enableUHD },
  playback: { playbackSpec, selectedAssetAudioLanguage },
  playbackSessionId,
  services: { sgwService },
  user,
}: TGetStreamingTicketOptions): Promise<StreamingTicketData> => {
  /* TODO: remove when channel dvr is enabled in prod
    override capabilites based on feature toggle
  */
  const overrideCapabilites = capabilities.filter((cap) =>
    disableDVR ? cap !== "CHANNEL_DVR" : true
  );

  // check if we can add the UHD capability
  if (isSafari && enableUHD) {
    if (!overrideCapabilites.includes("VC_H265")) {
      overrideCapabilites.push("VC_H265");
    }
  }

  const options = {
    capabilities: overrideCapabilites,
    drmType,
    packagings: supportedPackagings[drmType],
    playbackSessionId,
    playbackSpec,
    preferredAudioLanguage: selectedAssetAudioLanguage,
    regionalChannels: user.preferredRegionalChannels,
  };

  return (
    sgwService
      .getStreamingTicket(options)
      // Hacky retry to reduce UNKNOWN_ERROR (Failed to Fetch)
      .catch(() => sgwService.getStreamingTicket(options))
  );
};
