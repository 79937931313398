var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import FastDataService from './FastDataService';
import CoreOptions from './CoreOptions';
import NQSAnalyticsService from './nqs/NQSAnalyticsService';
import BrowserStorage from './storage/BrowserStorage';
import CoreStorage from './storage/CoreStorage';
import Log from '../common/log';
import { AnalyticsTag, CoreTag, Method } from '../common/Constants';
import Services from './nqs/Services';
import NQSRequest from './nqs/NQSRequest';
import CoreConstants from './utils/CoreConstants';
export default class Core {
    static getInstance(accountCode, options, forceNew) {
        if (!this._instance || forceNew) {
            this._instance = new Core(accountCode || '', options);
        }
        return this._instance;
    }
    constructor(accountCode, options) {
        var _a, _b, _c, _d;
        this.options = new CoreOptions();
        this.sendingOffline = false;
        this.isDestroyed = false;
        this.usingLegacy = false;
        this.commonVariables = new Map();
        this.registeredPeriodicPushes = new Map();
        this.pluginOptions = options;
        if (this.pluginOptions) {
            if (this.pluginOptions.host) {
                this.options.setNpawPluginOptions({ host: this.pluginOptions.host });
            }
            const usingStandalone = (_a = process.env.IS_STANDALONE === 'true') !== null && _a !== void 0 ? _a : false;
            this.usingLegacy = usingStandalone && ((_d = typeof ((_c = (_b = this.pluginOptions) === null || _b === void 0 ? void 0 : _b.legacyPlugin) === null || _c === void 0 ? void 0 : _c.viewTransform) !== 'undefined') !== null && _d !== void 0 ? _d : false);
        }
        this.browserStorage = new BrowserStorage(this.options.disableCookies, this.options.forceCookies, this.options.disableStorage);
        this.coreStorage = new CoreStorage(this.options, this.browserStorage);
        this.fastDataService = new FastDataService(this.options, accountCode, this.coreStorage, this.browserStorage, this.pluginOptions);
        this.fastDataService.init();
        this.nqsService = new NQSAnalyticsService(this.options, this.coreStorage, this.fastDataService);
        this.deleteOldOfflineEntries();
    }
    /**
     * @internal
     */
    destroy() {
        this.unregisterAllPeriodicPushes();
        this.commonVariables.clear();
        this.nqsService.destroy();
    }
    /**
     * Return the NPAW Plugin versions
     */
    static getPluginVersion() {
        // get the version from gitlab. If not available, use 7.2.0 as default
        const version = process.env.VERSION || '7.2.0';
        const mode = process.env.NWF || '';
        const standalone = process.env.IS_STANDALONE === 'true' ? '-balancer' : '';
        return `${version}${standalone}${mode}-js-sdk`;
    }
    /**
     * @internal
     * @returns true if the pluginOptions.legacyPlugin contains a valid legacy plugin instance, false otherwhise
     */
    isUsingLegacy() {
        return this.usingLegacy;
    }
    /**
     * @internal
     * @returns true if the /start or /init requests were sent, false otherwise
     */
    wasStartSent() {
        var _a, _b, _c, _d, _e, _f, _g;
        if (this.isUsingLegacy()) {
            if (((_b = (_a = this.pluginOptions) === null || _a === void 0 ? void 0 : _a.legacyPlugin) === null || _b === void 0 ? void 0 : _b._comm) && ((_d = (_c = this.pluginOptions) === null || _c === void 0 ? void 0 : _c.legacyPlugin) === null || _d === void 0 ? void 0 : _d._comm.transforms)) {
                return (_g = !((_f = (_e = this.pluginOptions) === null || _e === void 0 ? void 0 : _e.legacyPlugin) === null || _f === void 0 ? void 0 : _f._comm.transforms[0]._isBusy)) !== null && _g !== void 0 ? _g : true;
            }
        }
        else {
            return this.nqsService.wasStartSent();
        }
    }
    /**
     * @internal
     * @param legacyPlugin
     */
    setLegacyPlugin(legacyPlugin) {
        var _a, _b, _c, _d;
        if (this.pluginOptions) {
            this.pluginOptions.legacyPlugin = legacyPlugin;
            const usingStandalone = (_a = process.env.IS_STANDALONE === 'true') !== null && _a !== void 0 ? _a : false;
            this.usingLegacy = usingStandalone && ((_d = typeof ((_c = (_b = this.pluginOptions) === null || _b === void 0 ? void 0 : _b.legacyPlugin) === null || _c === void 0 ? void 0 : _c.viewTransform) !== 'undefined') !== null && _d !== void 0 ? _d : false);
        }
    }
    /**
     * Sets the option content.cdn on the legacy plugin if Active Switching is enabled
     * @internal
     * @param isAsEnabled true if Active Switching is enabled, false otherwhise
     */
    setActiveSwitchingOnLegacy(isAsEnabled) {
        if (isAsEnabled && this.pluginOptions && this.pluginOptions.legacyPlugin && this.pluginOptions.legacyPlugin.options) {
            this.pluginOptions.legacyPlugin.options['content.cdn'] = 'ACTVSWIT';
        }
    }
    /**
     * Fetches the current view code from the legacy plugin object from pluginOptions.legacyOptions
     *
     * @internal
     * @returns The current view code from the legacy plugin
     */
    getViewCode() {
        var _a;
        return (_a = this.pluginOptions) === null || _a === void 0 ? void 0 : _a.legacyPlugin.viewTransform.getViewCode();
    }
    /**
     * @internal
     * @returns The FastData server host if using the legacy plugin
     */
    getLegacyFastDataHost() {
        var _a;
        return (_a = this.pluginOptions) === null || _a === void 0 ? void 0 : _a.legacyPlugin.viewTransform.response.host;
    }
    /**
     * @internal
     * @param options
     */
    setOptions(options) {
        this.options.updateOptions(options);
        this.browserStorage.updateStorageOptions(this.options.disableCookies, this.options.forceCookies, this.options.disableStorage);
        this.fastDataService.updateOptions(options);
    }
    /**
     * @internal
     */
    getOptions() {
        return this.options;
    }
    /**
     * @internal
     * @returns the Core storage instance
     */
    getCoreStorage() {
        return this.coreStorage;
    }
    /**
     * Stores a common value for a product that might need to be used by other products
     *
     * @param productKey Product identifier (e.g. balancer)
     * @param variableKey Variable identifier (e.g. balancer-id)
     * @param value Value to be stored
     */
    registerCommonVariable(productKey, variableKey, value) {
        var _a, _b;
        if (this.commonVariables.has(productKey)) {
            (_a = this.commonVariables.get(productKey)) === null || _a === void 0 ? void 0 : _a.set(variableKey, value);
        }
        else {
            this.commonVariables.set(productKey, new Map());
            (_b = this.commonVariables.get(productKey)) === null || _b === void 0 ? void 0 : _b.set(variableKey, value);
        }
    }
    /**
     * Deletes a common variable for the specified product
     * @param productKey Product identifier (e.g. balancer)
     * @param variableKey Variable identifier (e.g. balancer-id)
     */
    unregisterCommonVariable(productKey, variableKey) {
        var _a;
        (_a = this.commonVariables.get(productKey)) === null || _a === void 0 ? void 0 : _a.delete(variableKey);
    }
    /**
     * Fetches the value stored for a product and variable identifiers
     *
     * @param productKey Product identifier (e.g. balancer)
     * @param variableKey Variable identifier (e.g. balancer-id)
     * @returns The value of the variable associated with the product or undefined if it is not set
     */
    getCommonVariable(productKey, variableKey) {
        var _a;
        return (_a = this.commonVariables.get(productKey)) === null || _a === void 0 ? void 0 : _a.get(variableKey);
    }
    /**
     * @internal
     * @returns The FastData service instance
     */
    getFastDataService() {
        return this.fastDataService;
    }
    /**
     *
     * @returns The FastData service session token
     */
    getFastDataSessionToken() {
        return this.fastDataService.getSession();
    }
    /**
     * Refreshes the session token
     */
    refreshSessionToken() {
        return this.usingLegacy ? undefined : this.fastDataService.refreshSessionToken();
    }
    /**
     *
     * @returns true if the session token is expired, false otherwise
     */
    isSessionTokenExpired() {
        var _a;
        const currentTime = new Date().getTime();
        return this.usingLegacy ? false : (currentTime - ((_a = this.nqsService.getTimeOfLastSentRequest()) !== null && _a !== void 0 ? _a : currentTime) >
            this.fastDataService.getSessionExpireTime() * 1000);
    }
    /**
     * Send data as soon as possible to NQS
     *
     * @internal
     * @param consumer Consumer to where data will be sent
     * @param service The path to where the request should be performed
     * @param method The type of request to be performed (GET, POST, DELETE, ...)
     * @param params Data to be sent
     * @param body Request body
     * @param onSuccessCallback Callback function to call if the request is successful
     * @param onFailCallback Callback function to call if the request fails
     */
    pushData(consumer, service, method, params, onSuccessCallback, onFailCallback) {
        this.handleDataToSend(consumer, service, method, params, onSuccessCallback, onFailCallback);
    }
    /**
     * Periodically fetches data from the callback and sends it to NQS as soon as possible
     *
     * @internal
     * @param consumer Consumer to where we will send data
     * @param key Identifier key for the interval
     * @param service The path to where the request should be performed
     * @param interval The time between dataCallback executions in milliseconds
     * @param method The type of request to be performed (GET, POST, DELETE, ...)
     * @param dataCallback Callback function to retrieve the data that needs to be sent
     * @param onSuccessCallback Callback function to run when request completes successfully
     * @param onFailCallback Callback function to run when request fails
     */
    pushPeriodicDataFromCallback(consumer, key, service, interval, method, dataCallback, onSuccessCallback, onFailCallback) {
        const timer = setInterval(() => {
            // Implement sending params to service
            const params = dataCallback();
            if (params) {
                this.handleDataToSend(consumer, service, method, params, onSuccessCallback, onFailCallback);
            }
        }, interval < 1000 ? 1000 : interval);
        if (!this.registeredPeriodicPushes.has(key)) {
            this.registeredPeriodicPushes.set(key, timer);
        }
        else {
            clearInterval(this.registeredPeriodicPushes.get(key));
            this.registeredPeriodicPushes.set(key, timer);
        }
    }
    /**
     * Stops registered timer to push data
     *
     * @internal
     * @param key Timer identifier
     */
    unregisterPeriodicPush(key) {
        clearInterval(this.registeredPeriodicPushes.get(key));
        this.registeredPeriodicPushes.delete(key);
    }
    /**
     * Stops all registered timers to push data
     *
     * @internal
     */
    unregisterAllPeriodicPushes() {
        if (this.registeredPeriodicPushes.size === 0)
            return;
        Log.debug(CoreTag, `Unregistering ${this.registeredPeriodicPushes.size} periodic pushes`);
        const keys = Array.from(this.registeredPeriodicPushes.keys());
        for (const key of keys) {
            clearInterval(this.registeredPeriodicPushes.get(key));
            this.registeredPeriodicPushes.delete(key);
        }
        Log.debug(CoreTag, 'Finished unregistering all pushes');
    }
    /**
     * Deletes offline entries older than 30 days
     * @internal
     */
    deleteOldOfflineEntries() {
        var _a, _b, _c, _d;
        const storedViews = (_b = (_a = this.coreStorage.getLocal('offlineViews')) === null || _a === void 0 ? void 0 : _a.split(',')) !== null && _b !== void 0 ? _b : [];
        for (const viewId of storedViews) {
            const viewData = (_d = (_c = this.coreStorage.getLocal('offlineViews.' + viewId)) === null || _c === void 0 ? void 0 : _c.split(',')) !== null && _d !== void 0 ? _d : [];
            const thirtyDays = 30 * 24 * 60 * 60 * 1000;
            if (viewData.length > 0 && Date.now() - JSON.parse('[' + viewData + ']')[0].timemark > thirtyDays) {
                this.deleteViewDataFromLocalStorage(viewId);
            }
        }
    }
    /**
     * Sends events that were stored due to offline configuration
     *
     * @internal
     */
    sendOfflineEvents() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.options.offline) {
                Log.notice(AnalyticsTag, 'Unable to send offline events because offline option is enabled');
                return;
            }
            if (this.sendingOffline)
                return;
            this.sendingOffline = true;
            let retries = 15;
            let found = false;
            while (!found && retries-- > 0) {
                if (this.getFastDataSessionToken() === '') {
                    yield new Promise((resolve) => setTimeout(resolve, 100));
                }
                else {
                    found = true;
                }
            }
            if (retries < 1)
                return;
            const storedViews = (_b = (_a = this.coreStorage.getLocal('offlineViews')) === null || _a === void 0 ? void 0 : _a.split(',')) !== null && _b !== void 0 ? _b : [];
            const requests = storedViews.map(viewId => {
                return new Promise((resolve, reject) => {
                    var _a;
                    let viewData = (_a = this.coreStorage.getLocal('offlineViews.' + viewId)) !== null && _a !== void 0 ? _a : '';
                    viewData = viewData.replace(/CODE_PLACEHOLDER/g, Core.getInstance().getFastDataSessionToken() + '_' + viewId.split('_')[1]);
                    if (viewData.length > 0) {
                        const request = new NQSRequest(true, this.coreStorage.getHost(), Services.Service.OFFLINE_EVENTS, Method.POST, { code: Core.getInstance().getFastDataSessionToken() + '_' + viewId.split('_')[1] }, () => {
                            this.deleteViewDataFromLocalStorage(viewId);
                            resolve();
                        }, () => resolve());
                        request.setBody('[' + viewData + ']');
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        request.send(function () { });
                    }
                });
            });
            yield Promise.all(requests).finally(() => {
                this.sendingOffline = false;
            });
        });
    }
    /**
     * Deletes a view data from local storage
     * @param viewId
     * @private
     */
    deleteViewDataFromLocalStorage(viewId) {
        var _a, _b;
        this.coreStorage.removeLocal('offlineViews.' + viewId);
        const storedViewsAfterSend = (_b = (_a = this.coreStorage.getLocal('offlineViews')) === null || _a === void 0 ? void 0 : _a.split(',')) !== null && _b !== void 0 ? _b : [];
        const position = storedViewsAfterSend.indexOf(viewId);
        if (position != -1)
            storedViewsAfterSend.splice(position, 1);
        this.coreStorage.setLocal('offlineViews', storedViewsAfterSend.toString());
    }
    handleDataToSend(consumer, service, method, params, onSuccessCallback, onFailCallback) {
        if (this.isDestroyed)
            return;
        switch (consumer) {
            case CoreConstants.Consumer.NQS:
                this.nqsService.send(service, method, params, onSuccessCallback, onFailCallback);
                break;
            case CoreConstants.Consumer.PERSISTENT:
                if (typeof onSuccessCallback !== 'undefined')
                    onSuccessCallback();
                if (service === Services.Service.VIDEO_PLUGIN_LOGS)
                    break;
                if (service === Services.Service.INIT)
                    break;
                params.timemark = Date.now();
                // Store on the database
                const request = new NQSRequest(this.options['app.https'], '', service, method, params, onSuccessCallback, onFailCallback);
                const localViews = this.coreStorage.getLocal('offlineViews');
                if (!localViews) {
                    this.coreStorage.setLocal('offlineViews', params.code);
                }
                else {
                    const splittedViews = localViews.split(',');
                    if (!splittedViews.includes(params.code)) {
                        splittedViews.push(params.code);
                        this.coreStorage.setLocal('offlineViews', splittedViews.toString());
                    }
                }
                const localView = this.coreStorage.getLocal('offlineViews.' + params.code);
                if (!localView) {
                    this.coreStorage.setLocal('offlineViews.' + params.code, request.toLocalStorageString());
                }
                else {
                    const splittedView = localView.split(',');
                    splittedView.push(request.toLocalStorageString());
                    this.coreStorage.setLocal('offlineViews.' + params.code, splittedView.toString());
                }
                break;
            case CoreConstants.Consumer.BLACK_HOLE:
                if (typeof onSuccessCallback !== 'undefined')
                    onSuccessCallback();
                // Ignore the events
                break;
        }
    }
}
