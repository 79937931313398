import { TTranslations } from "@telia-company/tv.web-player-shared";

export const translations: TTranslations = {
  audioTracks: "Ljud",
  ayswButtonText: "Ja, fortsätt titta",
  ayswHeading: "Är du fortfarande här?",
  ayswWarning:
    "Tryck på valfri tangent för att låta oss veta att du fortfarande är här",
  closeModalButtonText: "Stäng",
  closeRelatedContent: "Stäng",
  descriptiveAudio: "Commentary",
  episode: "Avsnitt",
  eventEnded: "Eventets sluttid har passerat",
  eventNotStarted: "Eventet har inte startat än",
  hardOfHearingSubtitles: "Captions",
  language: "Språk",
  languages: {
    ar: "",
    bg: "",
    cs: "",
    da: "Danska",
    de: "Tyska",
    el: "",
    en: "Engelska",
    es: "",
    et: "",
    fa: "",
    fi: "Finska",
    fr: "",
    he: "",
    hi: "",
    hr: "",
    hu: "",
    is: "",
    it: "",
    ja: "",
    lt: "",
    lv: "",
    mk: "",
    nb: "Norska",
    nl: "",
    no: "Norska",
    pl: "",
    pt: "",
    qad: "",
    qc: "",
    qd: "",
    ro: "",
    ru: "",
    sk: "",
    sl: "",
    sq: "",
    sr: "",
    sv: "Svenska",
    tr: "",
    uk: "",
    zh: "",
    zu: "",
  },
  live: "Live",
  next: "Nästa",
  notPlayable: "Kan ej spelas",
  off: "Av",
  playingNow: "Spelar nu",
  playNext: "Spela nästa",
  recordEpisode: "Set a single recording",
  recordingEdit: "Edit recording",
  recordingFailedModalBody:
    "Inspelningen misslyckades tyvärr, du kan försöka igen.",
  recordingFailedModalTitle: "Inspelningen misslyckades",
  recordingOngoing: "Recording",
  recordingStart: "Start recording",
  recordingStartToast: "Recording started",
  recordingStop: "Stop recording",
  recordingStopToast: "Recording stopped",
  recordSeries: "Set a series recording",
  relatedContentSeries: "Fler avsnitt",
  scrubbingForwardNotAllowed:
    "Av rättighetsskäl går det inte att spola framåt i programmet",
  scrubbingNotAllowed: "Av rättighetsskäl går det inte att spola i programmet",
  season: "Säsong",
  skipOpeningCredits: "Hoppa över intro",
  skipRecap: "Hoppa över resumé",
  startOver: "Katso alusta",
  subtitleTracks: "Undertexter",
  tooltips: {
    CLOSE_PLAYER: "Stäng",
    ENTER_FULLSCREEN: "Helskärm",
    ENTER_PIP: "Bild-i-bild",
    EXIT_FULLSCREEN: "Stäng helskärm",
    PAUSE: "Pausa",
    PLAY: "Spela",
    RECORD: "Spela in",
    RELATED: "Mer innehåll",
    SKIP_BACKWARD: "Hoppa bakåt",
    SKIP_FORWARD: "Hoppa framåt",
    START_CASTING: "Casta",
    START_OVER: "Börja om",
    STOP_CASTING: "Sluta casta",
  },
  watchCredits: "Se eftertexter",
  zapModalBody:
    "Din tidspunkt sparas inte när du byter kanal. Du går nu till senaste sändningen.",
  zapModalCancel: "Fortsätt kolla på program",
  zapModalConfirm: "Byt ändå",
  zapModalNpvrBody:
    "Din tidspunkt sparas inte när du byter kanal. Du går nu till senaste sändningen.",
  zapModalTitle: "Är du klar med detta program?",
};
