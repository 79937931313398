// No-Conflict
var previousNpaw = npaw // eslint-disable-line no-use-before-define
var npaw = {}

/**
 * This allows you to run multiple instances of NpawLib on the same webapp.
 * After loading the new version, call `noConflict()` to get a reference to it.
 * At the same time the old version will be returned to Youbora.
 */
npaw.noConflict = function () {
  npaw = previousNpaw
  return this
}

// Info
npaw.VERSION = require('./version')

// Polyfills
npaw.polyfills = require('./polyfills')
npaw.polyfills()

// Base Classes
npaw.Object = require('./object')
npaw.Emitter = require('./emitter')

// Log
const { default: Log } = require('../common/log');
npaw.Log = Log;
npaw.Log.loadLevelFromUrl();

// General classes
npaw.Util = require('./util')
npaw.HybridNetwork = require('./monitors/hybridnetwork')
npaw.Chrono = require('./chrono')
npaw.Timer = require('./timer')
npaw.Constants = require('./constants')

// Resource Transform classes
npaw.Transform = require('./comm/transform/transform')
npaw.ResourceTransform = require('./comm/transform/resource')
npaw.CdnParser = require('./comm/transform/resourceparsers/cdnparser')
npaw.HlsParser = require('./comm/transform/resourceparsers/formatparsers/hlsparser')
npaw.DashParser = require('./comm/transform/resourceparsers/formatparsers/dashparser')
npaw.LocationheaderParser = require('./comm/transform/resourceparsers/formatparsers/locationheaderparser')

// Plugin Classes
npaw.Options = require('./plugin/options')
npaw.Plugin = require('./plugin/plugin')
npaw.Storage = require('./plugin/storage')
npaw.RequestBuilder = require('./plugin/requestbuilder')

// Adapters
npaw.PlayheadMonitor = require('./adapter/playheadmonitor')
npaw.Adapter = require('adapter')
npaw.adapters = {}

// Detector classes
npaw.BackgroundDetector = require('./detectors/backgroundDetector')
npaw.DeviceDetector = require('./detectors/deviceDetector')
npaw.UUIDGenerator = require('./deviceUUID/hashgenerator')

/**
 * Register the given adapter in <npaw>.adapters-json.
 *
 * @param {string} key Unique adapter identifier.
 * @param {npaw.Adapter} Adapter Adapter class.
 *
 * @memberof npaw
 */
npaw.registerAdapter = function (key, Adapter) {
  this.adapters[key] = Adapter
}.bind(npaw)

/**
 * Remove the given adapter in <npaw>.adapters-json.
 *
 * @param {string} key Unique adapter identifier.
 *
 * @memberof npaw
 */
npaw.unregisterAdapter = function (key) {
  this.adapters[key] = null
}.bind(npaw)

module.exports = npaw
