import {
  TCallback,
  TPlaybackConfig,
  TTimelineRange,
  VideoIdType,
  WatchMode,
} from "@telia-company/tv.web-player-shared";
import Bowser from "bowser";
import { PointerEvent, RefObject } from "react";

import { TPlaybackType } from "./@types/types";

export const noop: TCallback = () => {};

export const getZeroBasedTimeString = (totalSeconds: number): string => {
  const negative = totalSeconds < 0;
  const absoluteSeconds = Math.abs(totalSeconds);
  // Returns a string with an amount of hours, minutes and seconds
  // Based on http://jsfiddle.net/StevenIseki/apg8yx1s/
  const hours = Math.floor(absoluteSeconds / 3600);
  const minutes = Math.floor((absoluteSeconds - hours * 3600) / 60);
  const seconds = Math.floor(absoluteSeconds - hours * 3600 - minutes * 60);

  const timeArray = [minutes, seconds];
  if (hours > 0) {
    timeArray.unshift(hours);
  }

  return (
    (negative ? "-" : "") +
    timeArray
      .map((num) => {
        if (!Number.isInteger(num)) {
          return "00";
        }
        if (num < 10) {
          return `0${num}`;
        }
        return num;
      })
      .join(":")
  );
};

export const throttle = <T extends TCallback>(
  fn: T,
  wait: number
): T | TCallback => {
  let isCalled = false;

  return (...args) => {
    if (isCalled) return;

    fn(...args);

    isCalled = true;

    setTimeout(() => {
      isCalled = false;
    }, wait);
  };
};

const padWithLeadingZero = (string: string) =>
  string.length === 1 ? `0${string}` : string;

export const getHHMMFromEpoch = (epochMs: number): string => {
  const date = new Date(epochMs);

  const h = date.getHours();
  const m = date.getMinutes();

  return `${padWithLeadingZero(h.toString())}:${padWithLeadingZero(
    m.toString()
  )}`;
};

export type TDotAnimationProps = {
  dragging?: boolean;
  ev?: PointerEvent<HTMLDivElement>;
  ref: RefObject<HTMLDivElement>;
};

export const dotAnimation = ({
  dragging,
  ev,
  ref,
}: TDotAnimationProps): void => {
  if (!ref.current) return;
  if (!ev) {
    ref.current.style.setProperty("transform", "scale(1)");
    return;
  }
  if (dragging) {
    ref.current.style.setProperty("transform", "scale(1.2");
    return;
  }
  const { bottom, left, right, top } = ref.current.getBoundingClientRect();
  if (
    ev.clientX > left &&
    ev.clientX < right &&
    ev.clientY < bottom &&
    ev.clientY > top
  ) {
    ref.current.style.setProperty("transition", "transform 0.1s ease-in");
    ref.current.style.setProperty("transform", "scale(1.2");
  } else {
    ref.current.style.setProperty("transform", "scale(1)");
  }
};

/*
 * if currentTime is very large, we need to recalculate it before we display elapsed time
 * in the progress bar.
 */
export const shouldConvertCurrentTime = (currentTime: number) =>
  currentTime > 24 * 60 * 60;

export const currentTimeWithinTimelineBlock = (
  block: TTimelineRange,
  currentTime: null | number
) => {
  if (typeof currentTime !== "number") return false;
  return currentTime > block.start && currentTime < block.end;
};

type TjustBeforeEOS = {
  currentTime: number;
  duration: number;
};

export const justBeforeEOS = ({ currentTime, duration }: TjustBeforeEOS) => {
  const EOSMarginSeconds = 2;
  return duration - currentTime < EOSMarginSeconds;
};

export const isPlaybackProbablySupported = (): boolean => {
  if (!("requestMediaKeySystemAccess" in navigator)) return false;

  const browser = Bowser.getParser(window.navigator.userAgent);

  const supportedBrowsers = {
    // Should be the version with the most recent Widevine CDM keys
    chrome: ">=80",
    // implementations might have one (e.g. Tesla cars).
    chromium: ">=80",
    edge: ">=80",
    // Chromium does not come with a built-in CDM but custom
    firefox: ">=80",
    // Minimum version for required CSS and EcmaScript support
    safari: ">=13.1",
  };

  return (
    browser.satisfies({
      // Bowser does not count Chromebooks as desktop devices, use separate check.
      "Chrome OS": supportedBrowsers,
      desktop: supportedBrowsers,
    }) || false
  );
};

// Even though the playbackSpec from the client should indicate what type of
// content we're playing, this isn't always true,
// one example is starting a catchup that is just past it's broadcast end time
// this recording will still be ongoing for a few minutes (varies) and will be
// a live manifest, instead of trusting the client, we trust the manifest by
// checking the isLive attribute.

export const getPlaybackType = (
  // incoming playback request
  p: null | TPlaybackConfig,
  // is the manifest a live manifest
  isLive: boolean
): null | TPlaybackType => {
  if (!p) return null;

  // we can always trust the playback spec when it asks to play a channel
  if (p.playbackSpec.videoIdType === VideoIdType.CHANNEL)
    return "linear-channel";

  // only trust MEDIA LIVE if manifest is also live, otherwise
  // fall back to VOD
  if (
    p.playbackSpec.videoIdType === VideoIdType.MEDIA &&
    p.playbackSpec.watchMode === WatchMode.LIVE &&
    isLive
  )
    return "live-event";

  if (
    // always trust STARTOVER, we don't want to fall back to VOD if manifest is
    // no longer live
    p.playbackSpec.watchMode === WatchMode.STARTOVER ||
    // user tried to play catchup, but recording is still ongoing,
    // we should play in startover mode
    (p.playbackSpec.watchMode === WatchMode.ONDEMAND && isLive)
  )
    return "startover";

  // remaining cases interpreted as vod
  return "vod";
};
