import { PlaybackEventTypes } from "../Constants";
import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import { TEmptyPayload } from "./Types";

export class LoadedEvent extends PlaybackEventBase<
  PlaybackEventTypes.LOADED,
  TEmptyPayload
> {
  constructor() {
    super(PlaybackEventTypes.LOADED, {});
  }
}
