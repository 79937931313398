import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";
import shaka from "shaka-player";

import { TEngineMethodOptions } from "../@types/types";

export type TGetTimeoutFilterFunction = (
  type: number,
  response: TUnfortunatelyAny
) => TUnfortunatelyAny;

// A filter that detects when live streams get stalled fetching
// the same manifest over and over, without ever fetching a
// segment. We want to break the loop and report as much debug
// data as is possible.
const getManifestStallFilter = ({
  domElements: { videoElement },
  player,
}: TEngineMethodOptions): TGetTimeoutFilterFunction => {
  let counter = 0;
  let adjustedCurrentTime = false;

  return (type: number): TUnfortunatelyAny => {
    // Increment counter on manifest
    if (type === shaka.net.NetworkingEngine.RequestType.MANIFEST) {
      counter += 1;
    }

    // Reset if we fetch a segment
    if (type === shaka.net.NetworkingEngine.RequestType.SEGMENT) {
      counter = 0;
    }

    const maxAttempts =
      player.getConfiguration()?.manifest?.retryParameters?.maxAttempts || 4;
    const maxRetries = Math.max(maxAttempts, 5);

    // Dash live streams recovery logic
    //
    // Player is stuck fetching manifest over and over
    // without fetching segments. Manipulate currentTime
    // to attempt recovery.
    //
    // Counter must be greater than shaka retryParams
    // maxAttempts, or it will eat other errors.
    if (counter > maxRetries && !adjustedCurrentTime) {
      if (
        videoElement.buffered.length &&
        videoElement.currentTime < videoElement.buffered.start(0)
      ) {
        // Shaka calculations have put the time outside of buffered range
        // Adjust currentTime to attempt recovery.
        //
        videoElement.currentTime = videoElement.buffered.start(0) + 3;
      } else {
        // Nudge current time to attempt recovery.
        //
        videoElement.currentTime += 1;
      }

      adjustedCurrentTime = true;
    }

    // Throw if counter ever reaches above X and we have adjusted
    // currentTime.
    if (counter >= maxRetries * 2 && adjustedCurrentTime) {
      throw new shaka.util.Error(2, 1, "MANIFEST_FETCH_LOOP", {
        reason: "Session closed due to manifest stuck in fetch loop.",
      });
    }
  };
};

export { getManifestStallFilter };
