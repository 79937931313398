const Util = require('../util');
const { default: Log } = require('../../common/log');
const version = require('../version');
const StreamingProtocol = require('../constants/streamingProtocols');
const TransportFormat = require('../constants/transportFormat');
const Constants = require('../constants');
const { default: Core } = require('../../core/Core');
const { AnalyticsTag } = require('../../common/Constants');
const { default: CoreConstants } = require('../../core/utils/CoreConstants');

var NpawVideoGettersMixin = {
  /**
   * Returns content's playhead
   *
   * @memberof npaw.Plugin.prototype
   */
  getPlayhead: function () {
    var ret = this._safeGetterAdapter('getPlayhead');
    return Util.parseNumber(ret, 0);
  },

  /**
   * Returns content's PlayRate
   *
   * @memberof npaw.Plugin.prototype
   */
  getPlayrate: function () {
    let ret = 0;
    if (this._adapter && this._adapter.flags && !this._adapter.flags.isPaused) {
      try {
        ret = this._adapter.getPlayrate();
      } catch (err) {
        Log.warn(AnalyticsTag, 'An error occured while calling getPlayrate', err);
      }
    }
    return ret;
  },

  /**
   * Returns content's FramesPerSecond
   *
   * @memberof npaw.Plugin.prototype
   */
  getFramesPerSecond: function () {
    return this._safeGetterAdapter('getFramesPerSecond', 'content.fps');
  },

  /**
   * Returns content's segment duration
   *
   * @memberof npaw.Plugin.prototype
   */
  getSegmentDuration: function () {
    return this.hybridNetwork.getSegmentDuration() || this._safeGetterAdapter('getSegmentDuration', 'content.segmentDuration');
  },

  /**
   * Returns content's DroppedFrames
   *
   * @memberof npaw.Plugin.prototype
   */
  getDroppedFrames: function () {
    let ret = this._safeGetterAdapter('getDroppedFrames');
    if (!ret) {
      ret = this.getWebkitDroppedFrames();
    }
    return Util.parseNumber(ret, 0);
  },

  /**
   * Returns dropped frames as per webkitDroppedFrameCount
   *
   * @returns {number}
   *
   * @memberof npaw.Plugin.prototype
   */
  getWebkitDroppedFrames: function () {
    if (this._adapter && this._adapter.tag && this._adapter.tag.webkitDroppedFrameCount) {
      return this._adapter.tag.webkitDroppedFrameCount;
    }
    return undefined;
  },

  /**
   * Returns content's Duration
   *
   * @memberof npaw.Plugin.prototype
   */
  getDuration: function () {
    const ret = this._safeGetterAdapter('getDuration', 'content.duration') || undefined;
    return Util.parseNumber(Math.round(ret), undefined);
  },

  /**
   * Returns content's Bitrate
   *
   * @memberof npaw.Plugin.prototype
   */
  getBitrate: function () {
    let ret = this._safeGetterAdapter('getBitrate', 'content.bitrate');
    if (!ret || ret === -1) {
      ret = this.getWebkitBitrate();
    }
    return Util.parseNumber(ret, -1);
  },

  /**
   * Returns content's total bytes downloaded
   *
   * @memberof npaw.Plugin.prototype
   */
  getTotalBytes: function () {
    return this.options['content.sendTotalBytes'] ? this._safeGetterAdapter('getTotalBytes', 'content.totalBytes') : undefined;
  },

  /**
   * Returns bitrate as per webkitVideoDecodedByteCount
   *
   * @param {Object} tag Video tag DOM reference.
   * @returns {number}
   *
   * @memberof npaw.Plugin.prototype
   */
  getWebkitBitrate: function () {
    try {
      if (this._adapter && this._adapter.tag && this._adapter.tag.webkitVideoDecodedByteCount) {
        let bitrate = this._adapter.tag.webkitVideoDecodedByteCount;
        if (this._lastWebkitBitrate) {
          var delta = this._adapter.tag.webkitVideoDecodedByteCount - this._lastWebkitBitrate;
          bitrate = Math.round(((delta) / this.plugin.fastDataTransform.response.pingTime) * 8)
        }
        this._lastWebkitBitrate = this._adapter.tag.webkitVideoDecodedByteCount;
        return bitrate !== 0 ? bitrate : -1;
      }
    } catch (e) {}
    return -1;
  },

  /**
   * Returns content's Throughput
   *
   * @memberof npaw.Plugin.prototype
   */
  getThroughput: function () {
    const ret = this._safeGetterAdapter('getThroughput', 'content.throughput');
    return Util.parseNumber(ret, -1);
  },

  /**
   * Returns content's Rendition
   *
   * @memberof npaw.Plugin.prototype
   */
  getRendition: function () {
    return this._safeGetterAdapter('getRendition', 'content.rendition');
  },

  /**
   * Returns content's Title
   *
   * @memberof npaw.Plugin.prototype
   */
  getTitle: function () {
    return this._safeGetterAdapter('getTitle', 'content.title');
  },

  /**
   * Returns content's Title2
   *
   * @memberof npaw.Plugin.prototype
   */
  getTitle2: function () {
    return this._safeGetterAdapter('getTitle2', 'content.program');
  },

  /**
   * Returns content's IsLive
   *
   * @memberof npaw.Plugin.prototype
   */
  getIsLive: function () {
    let ret = this.options['content.isLive'];
    if (!ret && ret !== false) {
      ret = this._safeGetterAdapter('getIsLive') || false;
    }
    return ret;
  },

  /**
   * Returns content's Resource after being parsed by the resourceTransform
   *
   * @memberof npaw.Plugin.prototype
   */
  getResource: function () {
    return this._safeGetterAdapter('getResource', 'content.resource');
  },

  getParsedResource: function () {
    let ret = this.plugin.resourceTransform.getResource();
    ret = ret || this._safeGetterAdapter('getURLToParse');
    return ret === this.getResource() ? undefined : ret;
  },

  /**
   * Returns content's TransactionCode
   *
   * @memberof npaw.Plugin.prototype
   */
  getTransactionCode: function () {
    return this.options['content.transactionCode'];
  },

  /**
   * Returns content's Metadata
   *
   * @memberof npaw.Plugin.prototype
   */
  getMetadata: function () {
    return this.options['content.metadata'];
  },

  /**
   * Returns content's PlayerVersion
   *
   * @memberof npaw.Plugin.prototype
   */
  getPlayerVersion: function () {
    return this._safeGetterAdapter('getPlayerVersion') || '';
  },

  /**
   * Returns content's PlayerName
   *
   * @memberof npaw.Plugin.prototype
   */
  getPlayerName: function () {
    return this._safeGetterAdapter('getPlayerName') || '';
  },

  /**
   * Returns content's Cdn
   *
   * @memberof npaw.Plugin.prototype
   */
  getCdn: function () {
    let ret = null;

    const activeSwitchingEnabled = Core.getInstance().getCommonVariable(
      CoreConstants.Products.BALANCER,
      CoreConstants.BalancerVariables.AS_ENABLED
    );

    if (activeSwitchingEnabled) {
      ret = 'ACTVSWIT';
    } else {
      ret = this.plugin.resourceTransform.getCdnName();
    }
    return ret || this.options['content.cdn'];
  },

  /**
   * Returns Balancer's profile if set
   *
   * @memberof npaw.Plugin.prototype
   */
  getProfileName: function () {
    return Core.getInstance().getCommonVariable(CoreConstants.Products.BALANCER, CoreConstants.BalancerVariables.PROFILE_NAME);
  },

  /**
   * Returns Balancer's bucket name if set
   *
   * @memberof npaw.Plugin.prototype
   */
  getBucketName: function () {
    return Core.getInstance().getCommonVariable(CoreConstants.Products.BALANCER, CoreConstants.BalancerVariables.BUCKET);
  },

  /**
   * Returns content's PluginVersion
   *
   * @memberof npaw.Plugin.prototype
   */
  getPluginVersion: function () {
    return this.getAdapterVersion();
  },

  /**
   * Returns ads adapter getVersion or null
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdapterVersion: function () {
    return this._safeGetterAdapter('getVersion');
  },

  /**
   * Returns cdn traffic received in bytes or null
   *
   * @memberof npaw.Plugin.prototype
   */
  getCdnTraffic: function () {
    return this._safeGetterAdapter('getCdnTraffic') || this.hybridNetwork.getCdnTraffic();
  },

  /**
   * Returns multi cdn traffic received in bytes or null
   *
   * @memberof npaw.Plugin.prototype
   */
  getMultiCdnInfo: function () {
    return this.hybridNetwork.getMultiCdnInfo();
  },

  /**
   * Returns cdn balancer response id
   *
   * @memberof npaw.Plugin.prototype
   */
  getBalancerResponseId: function () {
    return this.hybridNetwork.getBalancerResponseId();
  },

  /**
   * Returns p2p traffic received in bytes or null
   *
   * @memberof npaw.Plugin.prototype
   */
  getP2PTraffic: function () {
    return this._safeGetterAdapter('getP2PTraffic') || this.hybridNetwork.getP2PTraffic();
  },

  /**
   * Returns p2p traffic sent in bytes or null
   *
   * @memberof npaw.Plugin.prototype
   */
  getUploadTraffic: function () {
    return this._safeGetterAdapter('getUploadTraffic') || this.hybridNetwork.getUploadTraffic();
  },

  /**
   * Returns if p2p plugin is enabled or null
   *
   * @memberof npaw.Plugin.prototype
   */
  getIsP2PEnabled: function () {
    return this._safeGetterAdapter('getIsP2PEnabled') || this.hybridNetwork.getIsP2PEnabled();
  },

  /** Returns streaming protocol (DASH, HLS, HDS...) */
  getStreamingProtocol: function () {
    const ret = this.options['content.streamingProtocol'];
    // if ret is not in the list of possible options, ret = null
    if (ret) {
      for (var i in StreamingProtocol) {
        if (StreamingProtocol[i] === ret) {
          return ret;
        }
      }
    }
    return undefined;
  },

  /** Returns transport format (TS, MP4) */
  getTransportFormat: function () {
    let ret = this.options['content.transportFormat'];
    if (!ret && this.options['parse.manifest']) {
      ret = this.plugin.resourceTransform.getTransportFormat();
    }
    // if ret is not in the list of possible options, ret = null
    if (ret) {
      for (const i in TransportFormat) {
        if (TransportFormat[i] === ret) {
          return ret;
        }
      }
    }
    return undefined;
  },

  /** Returns household id */
  getHouseholdId: function () {
    return this._safeGetterAdapter('getHouseholdId');
  },

  /**
   * Returns latency of a live video, or null
   *
   * @memberof npaw.Plugin.prototype
   */
  getLatency: function () {
    return this.getIsLive() ? this._safeGetterAdapter('getLatency') : undefined;
  },

  /**
   * Returns the amount of packets lost, or null
   *
   * @memberof npaw.Plugin.prototype
   */
  getPacketLoss: function () {
    return this._safeGetterAdapter('getPacketLoss');
  },

  /**
   * Returns the amount of packets sent, or null
   *
   * @memberof npaw.Plugin.prototype
   */
  getPacketSent: function () {
    return this._safeGetterAdapter('getPacketSent');
  },

  /**
   * Returns a json with the metrics to be reported in pings when changed
   *
   * @memberof npaw.Plugin.prototype
   */
  getVideoMetrics: function () {
    return Util.getMetricsFrom(this._adapter ? this._adapter.getMetrics() : undefined, this.options['content.metrics']);
  },

  getPlayerStartupTime: function () {
    return this.plugin.browserLoadTimes.getPlayerStartupTime();
  },

  // ----------------------------------------- CHRONOS ------------------------------------------

  /**
   * Returns JoinDuration chrono delta time
   *
   * @memberof npaw.Plugin.prototype
   */
  getJoinDuration: function () {
    return this._adapter ? this._adapter.chronos.join.getDeltaTime(false) : -1;
  },

  /**
   * Returns BufferDuration chrono delta time
   *
   * @memberof npaw.Plugin.prototype
   */
  getBufferDuration: function () {
    return this._adapter ? this._adapter.chronos.buffer.getDeltaTime(false) : -1;
  },

  /**
   * Returns SeekDuration chrono delta time
   *
   * @memberof npaw.Plugin.prototype
   */
  getSeekDuration: function () {
    return this._adapter ? this._adapter.chronos.seek.getDeltaTime(false) : -1;
  },

  /**
   * Returns pauseDuration chrono delta time
   *
   * @memberof npaw.Plugin.prototype
   */
  getPauseDuration: function () {
    return this._adapter ? this._adapter.chronos.pause.getDeltaTime(false) : 0;
  },

  /**
   * Returns content's package
   *
   * @memberof npaw.Plugin.prototype
   */
  getPackage: function () {
    return this.options['content.package'];
  },

  /**
   * Returns content's saga
   *
   * @memberof npaw.Plugin.prototype
   */
  getSaga: function () {
    return this.options['content.saga'];
  },

  /**
   * Returns content's tv show
   *
   * @memberof npaw.Plugin.prototype
   */
  getTvShow: function () {
    return this.options['content.tvShow'];
  },

  /**
   * Returns content's season
   *
   * @memberof npaw.Plugin.prototype
   */
  getSeason: function () {
    return this.options['content.season'];
  },

  /**
   * Returns content's episode title
   *
   * @memberof npaw.Plugin.prototype
   */
  getEpisodeTitle: function () {
    return this.options['content.episodeTitle'];
  },

  /**
   * Returns content's channel
   *
   * @memberof npaw.Plugin.prototype
   */
  getChannel: function () {
    return this.options['content.channel'];
  },

  /**
   * Returns content's id
   *
   * @memberof npaw.Plugin.prototype
   */
  getID: function () {
    return this.options['content.id'];
  },

  /**
   * Returns content's IMDB id
   *
   * @memberof npaw.Plugin.prototype
   */
  getImdbId: function () {
    return this.options['content.imdbId'];
  },

  /**
   * Returns content's gracenote id
   *
   * @memberof npaw.Plugin.prototype
   */
  getGracenoteID: function () {
    return this.options['content.gracenoteId'];
  },

  /**
   * Returns content's type
   *
   * @memberof npaw.Plugin.prototype
   */
  getType: function () {
    return this.options['content.type'];
  },

  /**
   * Returns content's genre
   *
   * @memberof npaw.Plugin.prototype
   */
  getGenre: function () {
    return this.options['content.genre'];
  },

  /**
   * Returns content's language
   *
   * @memberof npaw.Plugin.prototype
   */
  getVideoLanguage: function () {
    return this.options['content.language'];
  },

  /**
   * Returns content's subtitles
   *
   * @memberof npaw.Plugin.prototype
   */
  getSubtitles: function () {
    return this.options['content.subtitles'];
  },

  /**
   * Returns content's contracted resolution
   *
   * @memberof npaw.Plugin.prototype
   */
  getContractedResolution: function () {
    return this.options['content.contractedResolution'];
  },

  /**
   * Returns content's cost
   *
   * @memberof npaw.Plugin.prototype
   */
  getCost: function () {
    return this.options['content.cost'];
  },

  /**
   * Returns content's price
   *
   * @memberof npaw.Plugin.prototype
   */
  getPrice: function () {
    return this.options['content.price'];
  },

  /**
   * Returns content's playback type
   *
   * @memberof npaw.Plugin.prototype
   */
  getPlaybackType: function () {
    let ret = this.options['content.playbackType'];
    if (!ret) {
      let isLive = this.options['content.isLive'];
      if (typeof isLive !== 'boolean') {
        isLive = this._safeGetterAdapter('getIsLive');
      }
      if (typeof isLive === 'boolean') {
        ret = isLive ? 'Live' : 'VoD';
      }
    }
    return ret;
  },

  /**
   * Returns content's DRM
   *
   * @memberof npaw.Plugin.prototype
   */
  getDRM: function () {
    return this.options['content.drm'];
  },

  /**
   * Returns content's video codec
   *
   * @memberof npaw.Plugin.prototype
   */
  getVideoCodec: function () {
    return this._safeGetterAdapter('getVideoCodec', 'content.encoding.videoCodec');
  },

  /**
   * Returns content's audio codec
   *
   * @memberof npaw.Plugin.prototype
   */
  getAudioCodec: function () {
    return this._safeGetterAdapter('getAudioCodec', 'content.encoding.audioCodec');
  },

  /**
   * Returns content's codec settings
   *
   * @memberof npaw.Plugin.prototype
   */
  getCodecSettings: function () {
    return this.options['content.encoding.codecSettings'];
  },

  /**
   * Returns content's codec profile
   *
   * @memberof npaw.Plugin.prototype
   */
  getCodecProfile: function () {
    return this.options['content.encoding.codecProfile'];
  },

  /**
   * Returns content's container format
   *
   * @memberof npaw.Plugin.prototype
   */
  getContainerFormat: function () {
    return this.options['content.encoding.containerFormat'];
  },

  /**
   * Returns linked view id string
   *
   * @memberof npaw.Plugin.prototype
   */
  getLinkedViewId: function () {
    return this.options.linkedViewId;
  },

  _safeGetterAdapter: function (functionName, optionName) {
    let ret;
    if (optionName && this.options[optionName]) {
      ret = this.options[optionName];
    } else {
      try {
        if (this._adapter && typeof this._adapter[functionName] === 'function') {
          ret = this._adapter[functionName]();
        }
      } catch (err) {
        Log.warn(AnalyticsTag, 'An error occured while calling ' + functionName, err);
      }
    }
    return ret || undefined;
  },

  /**
   * Returns content's custom dimensions
   *
   * @memberof npaw.Plugin.prototype
   */
  getCustomDimensions: function () {
    const dim = this.options['content.customDimensions'];
    return typeof dim === 'object' ? dim : undefined;
  },

  /**
   * Returns ad's Extraparam1
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdExtraparam1: function () {
    return this.options['ad.customDimension.1'];
  },

  /**
   * Returns ad's Extraparam2
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdExtraparam2: function () {
    return this.options['ad.customDimension.2'];
  },

  /**
   * Returns ad's Extraparam3
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdExtraparam3: function () {
    return this.options['ad.customDimension.3'];
  },

  /**
   * Returns ad's Extraparam4
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdExtraparam4: function () {
    return this.options['ad.customDimension.4'];
  },
  /**
   * Returns ad's Extraparam5
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdExtraparam5: function () {
    return this.options['ad.customDimension.5'];
  },

  /**
   * Returns ad's Extraparam6
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdExtraparam6: function () {
    return this.options['ad.customDimension.6'];
  },

  /**
   * Returns ad's Extraparam7
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdExtraparam7: function () {
    return this.options['ad.customDimension.7'];
  },

  /**
   * Returns ad's Extraparam8
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdExtraparam8: function () {
    return this.options['ad.customDimension.8'];
  },

  /**
   * Returns ad's Extraparam9
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdExtraparam9: function () {
    return this.options['ad.customDimension.9'];
  },

  /**
   * Returns ad's Extraparam10
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdExtraparam10: function () {
    return this.options['ad.customDimension.10'];
  },

  /**
   * Returns PluginInfo
   *
   * @memberof npaw.Plugin.prototype
   */
  getPluginInfo: function () {
    return JSON.stringify({
      lib: version,
      adapter: this.getAdapterVersion(),
      adAdapter: this.getAdAdapterVersion()
    });
  },

  /**
   * Returns AccountCode
   *
   * @memberof npaw.Plugin.prototype
   */
  getAccountCode: function () {
    return Core.getInstance().getFastDataService().getAccountCode();
  },

  /**
   * Returns Username
   *
   * @memberof npaw.Plugin.prototype
   */
  getUsername: function () {
    return this.options['user.name'];
  },

  /**
   * Returns user email
   *
   * @memberof npaw.Plugin.prototype
   */
  getEmail: function () {
    return this.options['user.email'];
  },

  /**
   * Returns the nodehost
   *
   * @memberof npaw.Plugin.prototype
   */
  getNodeHost: function () {
    return this.options['content.cdnNode'] || this.plugin.resourceTransform.getNodeHost();
  },

  /**
   * Returns the node type id
   *
   * @memberof npaw.Plugin.prototype
   */
  getNodeType: function () {
    return this.options['content.cdnType'] || this.plugin.resourceTransform.getNodeType();
  },

  /**
   * Returns the node type string
   *
   * @memberof npaw.Plugin.prototype
   */
  getNodeTypeString: function () {
    return this.plugin.resourceTransform.getNodeTypeString();
  },

  /**
   * Returns requestNumber value, to prevent /data calls being cached
   *
   * @memberof npaw.Plugin.prototype
   */
  getRequestNumber: function () {
    return Math.random();
  },

  /**
   * Returns a whole offline view and its id.
   *
   * @memberof npaw.Plugin.prototype
   */
  getOfflineView: function () {
    let ret;
    if (this.plugin.offlineStorage) {
      ret = this.plugin.offlineStorage.getView();
    }
    return ret;
  },

  getSmartswitchConfigCode: function () {
    return this.options['smartswitch.configCode'];
  },

  getSmartswitchGroupCode: function () {
    return this.options['smartswitch.groupCode'];
  },

  getSmartswitchContractCode: function () {
    return this.options['smartswitch.contractCode'];
  },

  /**
   * Returns a boolean to indicate if plugin should control if player exists
   *
   * @memberof npaw.Plugin.prototype
   */
  controlPlayerExists: function () {
    return this.options['check.playerExists'];
  },

  /**
   * Returns a boolean to indicate if plugin should ignore send pauseDuration in a small pause events
   *
   * @memberof npaw.Plugin.prototype
   */
  ignorePauseSmallEvents: function () {
    return this.options['pause.ignoreSmallEvents'];
  },

  /**
   * Returns ads's PlayerVersion
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdPlayerVersion: function () {
    return this._safeGetterAdsAdapter('getPlayerVersion') || '';
  },

  /**
   * Returns ad's position
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdPosition: function () {
    let ret = Constants.AdPosition.Preroll;
    if (this._adsAdapter) {
      try {
        var temporalRet = this._adsAdapter.getPosition();
        if (
          Constants.AdPosition.Preroll === temporalRet ||
          Constants.AdPosition.Midroll === temporalRet ||
          Constants.AdPosition.Postroll === temporalRet
        ) {
          ret = temporalRet;
        }
      } catch (err) {
        Log.warn(AnalyticsTag, 'An error occured while calling getAdPosition', err);
      }
    }
    if (!ret && this._adapter) {
      ret = this._adapter.flags.isJoined ? Constants.AdPosition.Midroll : Constants.AdPosition.Preroll;
    }
    return ret;
  },

  getAdNumber: function () {
    return this.requestBuilder.lastSent.adNumber || 0;
  },

  getAdNumberInBreak: function () {
    return this.requestBuilder.lastSent.adNumberInBreak || 0;
  },

  getBreakNumber: function () {
    return this.requestBuilder.lastSent.breakNumber || 0;
  },

  /**
   * Returns ad's AdPlayhead
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdPlayhead: function () {
    const ret = this._safeGetterAdsAdapter('getPlayhead');
    return Util.parseNumber(ret, 0);
  },

  /**
   * Returns ad's AdDuration
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdDuration: function () {
    const ret = this._safeGetterAdsAdapter('getDuration', 'ad.duration');
    return Util.parseNumber(ret, 0);
  },

  /**
   * Returns ad's AdBitrate
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdBitrate: function () {
    let ret = this._safeGetterAdsAdapter('getBitrate');
    if (!ret || ret === -1) {
      ret = this.getWebkitAdBitrate();
    }
    return Util.parseNumber(ret, -1);
  },

  /**
   * Returns bitrate as per webkitVideoDecodedByteCount
   *
   * @param {Object} tag Video tag DOM reference.
   * @returns {number}
   *
   * @memberof npaw.Plugin.prototype
   */
  getWebkitAdBitrate: function () {
    try {
      if (this._adsAdapter && this._adsAdapter.tag && this._adsAdapter.tag.webkitVideoDecodedByteCount) {
        let bitrate = this._adsAdapter.tag.webkitVideoDecodedByteCount;
        if (this._lastWebkitAdBitrate) {
          const delta = this._adsAdapter.tag.webkitVideoDecodedByteCount - this._lastWebkitAdBitrate;
          bitrate = Math.round(((delta) / this.plugin.fastDataTransform.response.pingTime) * 8);
        }
        this._lastWebkitAdBitrate = this._adsAdapter.tag.webkitVideoDecodedByteCount;
        return bitrate !== 0 ? bitrate : -1;
      }
    } catch (e) {}
    return -1;
  },

  /**
   * Returns ad's AdTitle
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdTitle: function () {
    return this._safeGetterAdsAdapter('getTitle', 'ad.title');
  },

  /**
   * Returns ad's AdResource
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdResource: function () {
    return this._safeGetterAdsAdapter('getResource', 'ad.resource');
  },

  /**
   * Returns ad's campaign
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdCampaign: function () {
    return this._safeGetterAdsAdapter('getCampaign', 'ad.campaign');
  },

  /**
   * Returns ad's campaign
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdCreativeId: function () {
    return this._safeGetterAdsAdapter('getCreativeId', 'ad.creativeId');
  },

  /**
   * Returns ad's provider
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdProvider: function () {
    return this._safeGetterAdsAdapter('getProvider', 'ad.provider');
  },

  /**
   * Returns ads adapter getVersion or null
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdAdapterVersion: function () {
    return this._safeGetterAdsAdapter('getVersion');
  },

  /**
   * Returns ad's AdMetadata
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdMetadata: function () {
    return this.options['ad.metadata'];
  },

  /**
   * Returns the type of ad insertion (clientside, serverside)
   * @returns {string} ad insertion type
   */
  getAdInsertionType: function () {
    return this._safeGetterAdsAdapter('getAdInsertionType');
  },

  getGivenBreaks: function () {
    return this._safeGetterAdsAdapter('getGivenBreaks', 'ad.givenBreaks');
  },

  getExpectedBreaks: function () {
    let ret;
    const expBreaks = this.options['ad.expectedBreaks'];
    const expPattern = this.options['ad.expectedPattern'];
    if (expBreaks) {
      ret = expBreaks;
    } else if (expPattern) {
      ret = 0;
      ret = expPattern.pre ? expPattern.pre.length : 0;
      ret += expPattern.mid ? expPattern.mid.length : 0;
      ret += expPattern.post ? expPattern.post.length : 0;
    } else if (this._adsAdapter) {
      try {
        ret = this._adsAdapter.getExpectedBreaks();
      } catch (err) {
        Log.warn(AnalyticsTag, 'An error occured while calling expectedBreaks', err);
      }
    }
    return ret;
  },

  getExpectedPattern: function () {
    return this._safeGetterAdsAdapter('getExpectedPattern', 'ad.expectedPattern');
  },

  getBreaksTime: function () {
    return this._safeGetterAdsAdapter('getBreaksTime', 'ad.breaksTime');
  },

  getGivenAds: function () {
    return this._safeGetterAdsAdapter('getGivenAds', 'ad.givenAds');
  },

  getExpectedAds: function () {
    let ret;
    try {
      if (this._adsAdapter) {
        const pattern = this.options['ad.expectedPattern'];
        if (pattern && this.getAdPosition()) {
          let list = [];
          if (pattern.pre) list = list.concat(pattern.pre);
          if (pattern.mid) list = list.concat(pattern.mid);
          if (pattern.post) list = list.concat(pattern.post);
          if (list.length > 0) {
            let position = this.requestBuilder.lastSent.breakNumber;
            if (position > list.length) position = list.length;
            ret = list[position - 1];
          }
        } else {
          ret = this._adsAdapter.getExpectedAds();
        }
      }
    } catch (err) {
      Log.warn(AnalyticsTag, 'An error occured while calling expectedAds', err);
    }
    return ret;
  },

  getAdsExpected: function () {
    let ret;
    try {
      ret = this.getExpectedPattern() || this.getGivenAds() || false;
    } catch (err) {
      Log.warn(AnalyticsTag, 'An error occured while calling givenAds or expectedPattern', err);
    }
    return ret;
  },

  // ----------------------------------------- CHRONOS ------------------------------------------

  /**
   * Returns AdJoinDuration chrono delta time
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdJoinDuration: function () {
    return this._adsAdapter ? this._adsAdapter.chronos.join.getDeltaTime(false) : -1;
  },

  /**
   * Returns AdBufferDuration chrono delta time
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdBufferDuration: function () {
    return this._adsAdapter ? this._adsAdapter.chronos.buffer.getDeltaTime(false) : -1;
  },

  /**
   * Returns AdPauseDuration chrono delta time
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdPauseDuration: function () {
    return this._adsAdapter ? this._adsAdapter.chronos.pause.getDeltaTime(false) : 0;
  },

  /**
   * Returns total totalAdDuration chrono delta time
   *
   * @memberof npaw.Plugin.prototype
   */
  getAdTotalDuration: function () {
    return this._adsAdapter ? this._adsAdapter.chronos.total.getDeltaTime(false) : -1;
  },

  getAdViewedDuration: function () {
    return this._getTimeMaxOrAcum(true);
  },

  getAdViewability: function () {
    return this._getTimeMaxOrAcum();
  },

  _getTimeMaxOrAcum: function (acum) {
    let maxTime = 0;
    if (this._adsAdapter) {
      this._adsAdapter.chronos.viewedMax.forEach(function (chrono) {
        if (acum) {
          maxTime += chrono.getDeltaTime(false);
        } else {
          maxTime = Math.max(chrono.getDeltaTime(false), maxTime);
        }
      });
    }
    return maxTime;
  },

  getAudioEnabled: function () {
    return this._safeGetterAdsAdapter('getAudioEnabled');
  },

  getIsSkippable: function () {
    return this._safeGetterAdsAdapter('getIsSkippable');
  },

  getIsFullscreen: function () {
    return this._safeGetterAdsAdapter('getIsFullscreen');
  },

  _safeGetterAdsAdapter: function (func, option) {
    let ret;
    if (option && this.options[option]) {
      ret = this.options[option];
    } else {
      try {
        if (this._adsAdapter && typeof this._adsAdapter[func] === 'function') {
          ret = this._adsAdapter[func]();
        }
      } catch (err) {
        Log.warn(AnalyticsTag, 'An error occured while calling ' + func, err);
      }
    }
    return ret;
  },

  /**
   * Creates an adnumber if it does not exist and stores it in lastSent. If it already exists,
   * it is incremented by 1.
   *
   * @returns {number} adNumber
   */
  getNewAdNumber: function () {
    let adNumber = this.requestBuilder.lastSent.adNumber;
    if (adNumber && this.requestBuilder.lastSent.position === this.getAdPosition()) {
      adNumber += 1;
    } else {
      adNumber = 1;
    }
    this.requestBuilder.lastSent.adNumber = adNumber;
    return adNumber;
  },

  /**
   * Creates an adnumberinbreak incrementing by 1.
   *
   * @returns {number} adNumber
   */
  getNewAdNumberInBreak: function () {
    this._adNumberInBreak++;
    this.requestBuilder.lastSent.adNumberInBreak = this._adNumberInBreak;
    return this._adNumberInBreak;
  },

  /**
   * Creates an breaknumber if it does not exist and stores it in lastSent. If it already exists,
   * it is incremented by 1.
   * Also resets adnumberinbreak
   * @returns {number} breakNumber
   */
  getNewBreakNumber: function () {
    let breakNumber = 1;
    this._adNumberInBreak = 0;
    if (this.requestBuilder.lastSent.breakNumber) {
      breakNumber = this.requestBuilder.lastSent.breakNumber + 1;
    }
    if (breakNumber && this.requestBuilder.lastSent.position !== this.getAdPosition()) {
      breakNumber = 1;
    }
    this.requestBuilder.lastSent.breakNumber = breakNumber;
    return breakNumber;
  },

  /**
   * Returns last known location
   * @returns {object}
   */
  getAccurateGeoLocation: function () {
    return this.lastKnownLocation;
  }
};

module.exports = NpawVideoGettersMixin;
