import styled from "@emotion/styled";
import { FC } from "react";

import { useTranslations } from "../context-provider";
import { OverlayWrapper } from "./elements";

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: none;
  cursor: default;
  background: rgba(0, 0, 0, 0);
`;

const InnerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    ". . ."
    ". message ."
    ". . .";
  width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;
`;

const InactivityWarningMessage = styled.div<{
  bgColor: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  grid-area: message;
  display: flex;
  width: fit-content;
  border-radius: 4px;
  padding: 1em 1.5em;
  text-align: center;
`;

export const InactivityWarning: FC = () => {
  const { ayswWarning } = useTranslations();

  return (
    <OverlayWrapper>
      <FlexContainer>
        <InnerGrid>
          <InactivityWarningMessage bgColor="rgba(0,0,0,0.6)">
            {ayswWarning}
          </InactivityWarningMessage>
        </InnerGrid>
      </FlexContainer>
    </OverlayWrapper>
  );
};
