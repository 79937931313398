import { ErrorCategories } from "../../../base";
import { TTrackerConfiguration } from "../Types";

export type TYouboraTrackerConfiguration = TTrackerConfiguration & {
  useProductAnalytics?: boolean;
  accountId: "tv4" | "tv4dev";
};

export enum YouboraErrorLevels {
  Error = "error",
  Fatal = "fatal",
}

export type TYouboraError = {
  errorCode: string;
  msg: ErrorCategories;
  errorMetadata: any;
  errorLevel: YouboraErrorLevels;
};

export type TYouboraOptions = {
  enabled?: boolean;
  host?: string;
  accountCode?: string;
  preventZombieViews?: boolean;
  offline?: boolean;
  referer?: string;
  disableCookies?: boolean;
  "user.email"?: string;
  "user.type"?: string;
  "user.name"?: string;
  "user.obfuscateIp"?: boolean;
  "user.anonymousId"?: string;
  "parse.cdnNameHeader"?: string;
  "parse.cdnNode"?: boolean;
  "parse.cdnNode.list"?: Array<string>;
  "parse.locationHeader"?: boolean;
  "parse.manifest"?: boolean;
  "parse.fdsResponseHost"?: Function | null;
  "network.ip"?: string;
  "network.isp"?: string;
  "network.connectionType"?: string;
  "device.code"?: string;
  "device.model"?: string;
  "device.brand"?: string;
  "device.type"?: string;
  "device.name"?: string;
  "device.osName"?: string;
  "device.osVersion"?: string;
  "device.browserName"?: string;
  "device.browserVersion"?: string;
  "device.browserType"?: string;
  "device.browserEngine"?: string;
  "device.isAnonymous"?: boolean;
  "encoding.videoCodec"?: number;
  "encoding.audioCodec"?: number;
  "encoding.codecSettings"?: number;
  "encoding.codecProfile"?: number;
  "encoding.containerFormat"?: number;
  "content.transactionCode"?: string;
  "content.resource"?: string;
  "content.isLive"?: boolean;
  "content.isLive.noSeek"?: boolean;
  "content.title"?: string;
  "content.program"?: string;
  "content.duration"?: number;
  "content.fps"?: number;
  "content.bitrate"?: number;
  "content.throughput"?: number;
  "content.rendition"?: string;
  "content.cdn"?: string;
  "content.cdnNode"?: string;
  "content.cdnType"?: number;
  "content.metadata"?: object;
  "content.metrics"?: object;
  "content.streamingProtocol"?: string;
  "content.package"?: number;
  "content.saga"?: number;
  "content.tvShow"?: number;
  "content.season"?: number;
  "content.episodeTitle"?: number;
  "content.channel"?: number;
  "content.id"?: string;
  "content.imdbId"?: number;
  "content.gracenoteId"?: number;
  "content.type"?: string;
  "content.genre"?: number;
  "content.language"?: number;
  "content.subtitles"?: number;
  "content.contractedResolution"?: number;
  "content.cost"?: number;
  "content.price"?: number;
  "content.playbackType"?: string;
  "content.drm"?: number;
  "content.customDimension.1"?: string;
  "content.customDimension.2"?: string;
  "content.customDimension.3"?: string;
  "content.customDimension.4"?: string;
  "content.customDimension.5"?: string;
  "content.customDimension.6"?: string;
  "content.customDimension.7"?: string;
  "content.customDimension.8"?: string;
  "content.customDimension.9"?: string;
  "content.customDimension.10"?: string;
  "content.customDimension.11"?: string;
  "content.customDimension.12"?: string;
  "content.customDimension.13"?: string;
  "content.customDimension.14"?: string;
  "content.customDimension.15"?: string;
  "content.customDimension.16"?: string;
  "content.customDimension.17"?: string;
  "content.customDimension.18"?: string;
  "content.customDimension.19"?: string;
  "content.customDimension.20"?: string;
  "ad.metadata"?: any;
  "ad.campaign"?: string;
  "ad.creativeId"?: string;
  "ad.provider"?: string;
  "ad.resource"?: string;
  "ad.title"?: string;
  "ad.expectedPattern"?: any;
  "ad.givenAds"?: string;
  "ad.breaksTime"?: Array<number>;
  "ad.expectedBreaks"?: string;
  "ad.givenBreaks"?: number;
  "ad.ignore"?: boolean;
  "ad.blockDetection"?: boolean;
  "ad.customDimension.1"?: string;
  "ad.customDimension.2"?: string;
  "ad.customDimension.3"?: string;
  "ad.customDimension.4"?: string;
  "ad.customDimension.5"?: string;
  "ad.customDimension.6"?: string;
  "ad.customDimension.7"?: string;
  "ad.customDimension.8"?: string;
  "ad.customDimension.9"?: string;
  "ad.customDimension.10"?: string;
  "app.name"?: string;
  "app.releaseVersion"?: string;
  "app.https"?: null | boolean;
  "background.enabled"?: boolean;
  "background.settings"?: string;
  "background.settings.android"?: string;
  "background.settings.iOS"?: string;
  "background.settings.desktop"?: string;
  "background.settings.tv"?: string;
  "background.settings.playstation"?: string;
  "smartswitch.configCode"?: string;
  "smartswitch.groupCode"?: string;
  "smartswitch.contractCode"?: string;
  forceInit?: boolean;
  "session.metrics"?: any;
  "session.context"?: boolean;
  waitForMetadata?: boolean;
  pendingMetadata?: Array<string>;
};
