//
//  BaseService.ts
//
//  Created by Lars Rothaus on 22/02/2021.
import { FetchRequestFactory } from "../network/FetchRequestFactory/FetchRequestFactory";
import { BaseComponent } from "../base";

const getOsName = (osName?: string): "mac" | "windows" | "linux" => {
  if (!osName) return "linux";

  if (osName.toLowerCase().indexOf("mac") > -1) return "mac";
  if (osName.toLowerCase().indexOf("wind") > -1) return "windows";

  return "linux";
};

export class BaseService extends BaseComponent {
  protected requestFactory: FetchRequestFactory = new FetchRequestFactory();
  protected headers: any;

  /**
   * @param {string} serviceName
   */
  constructor(serviceName: string) {
    super(serviceName);
    this.headers = {};
  }

  /**
   * @param {FetchRequestFactory} factory
   * @return {BaseService}
   */
  public attachRequestFactory(factory: FetchRequestFactory): BaseService {
    this.requestFactory = factory;
    return this;
  }

  /**
   * @param {*} configuration
   */
  public initialize(configuration: any) {}

  public async reset(): Promise<void> {
    throw new Error(
      `Error class:BaseService[reset] : reset is a required method!`
    );
  }

  public async destroy(): Promise<void> {
    throw new Error(`Error class:BaseService[reset] : destroy is a required!`);
  }
}
