import {
  TEngineOptions,
  TUnfortunatelyAny,
} from "@telia-company/tv.web-player-shared";

export type TSafariStartoverFixOptions = {
  videoElement: TUnfortunatelyAny;
};

/**
 * Safari will play startovers from the live edge, since it is a type of live stream
 * in order to override this, we wait until the 'canplay' event is fired, and set the
 * currentTime of the videoElement directly.
 * We also have to a bit of playbackrate-trickery to avoid messing up the events that
 * are used for youbora reporting, if we set playbackrate to 0 we can do the initial
 * seek back to 0 for safari, and then set playbackrate back to 1 after the seek
 * without triggering a seek-event to youbora.
 */

export const safariStartoverFix = ({
  videoElement,
}: TSafariStartoverFixOptions): void => {
  const deferLoadingEvent = () => {
    videoElement.removeEventListener("seeked", deferLoadingEvent);
    videoElement.playbackRate = 1;
  };

  const startFromPosition = () => {
    videoElement.removeEventListener("canplay", startFromPosition);
    // avoid micro-seek on non-safari platforms
    // chrome, edge and firefox will all have a "currentTime" that is 0.001, for startovers
    // while safari will have a "currentTime" that is > 1 (depending on how long ago the program started)
    if (videoElement.currentTime > 1) {
      videoElement.addEventListener("seeked", deferLoadingEvent);
      videoElement.playbackRate = 0;
      videoElement.currentTime = 0;
    }
  };

  videoElement.addEventListener("canplay", startFromPosition);
};

export const safari15StartoverFix = ({
  videoElement,
}: TSafariStartoverFixOptions): void => {
  videoElement.playbackRate = 0;
  const deferLoadingEvent = () => {
    videoElement.removeEventListener("seeked", deferLoadingEvent);
    videoElement.playbackRate = 1;
  };

  const startFromPosition = () => {
    videoElement.removeEventListener("durationchange", startFromPosition);
    // avoid micro-seek on non-safari platforms
    // chrome, edge and firefox will all have a "currentTime" that is 0.001, for startovers
    // while safari will have a "currentTime" that is > 1 (depending on how long ago the program started)
    // UPDATE: as of Safari 15, the above is no longer true, it will have a currentTime of 0 immediately,
    // and then start playing from a live position at a later time, ~50ms or so.
    const canSeekToZero = setInterval(() => {
      if (videoElement.currentTime > 1) {
        videoElement.addEventListener("seeked", deferLoadingEvent);
        videoElement.currentTime = 0;
        clearInterval(canSeekToZero);
      }
    }, 100);
  };
  // events that don't work: canplay, canplaythrough, playing, play
  // see if there is a better way to poke videoElement.currentTime for Safari 15
  videoElement.addEventListener("durationchange", startFromPosition);
};

export const shouldUseHlsThumbs = (options: TEngineOptions) => {
  const isHlsPackaging = options.selectedStream.packaging === "HLS_CMAF_CBCS";
  const isHlsUrl =
    options.selectedStream.url &&
    options.selectedStream.url.toLowerCase().indexOf("m3u") > -1;
  const hasWebVttThumbnails = !!options.selectedStream.webvttThumbnailsUrl;

  return isHlsPackaging && isHlsUrl && hasWebVttThumbnails;
};
