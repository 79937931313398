import styled from "@emotion/styled";
import { TTooltipTranslations } from "@telia-company/tv.web-player-shared";
import { FC } from "react";

import { TPlayerIcon } from "../@types/icon-type";
import { TCastButtonCallback } from "../@types/types";
import { UiEventTypes } from "../constants";
import {
  useDispatch,
  usePlayerControls,
  usePlayerState,
} from "./context-provider";
import { GridIcon } from "./elements";

const CastIcon = styled(GridIcon)<{ smallLayout: boolean }>`
  grid-column: ${({ smallLayout }) => (smallLayout ? "13 / 15" : "14")};
  grid-row: ${({ smallLayout }) => (smallLayout ? "1 / 3" : "9")};
`;

export type TCastButtonProps = {
  callback: TCastButtonCallback;
  Icon: TPlayerIcon;
  smallLayout: boolean;
  tooltips?: TTooltipTranslations;
};

export const CastButton: FC<TCastButtonProps> = ({
  callback,
  Icon,
  smallLayout,
  tooltips,
}) => {
  const {
    currentTime,
    metadata: { episode, season, seriesTitle, title },
    playback,
    selectedAudioTrack,
    selectedTextTrack,
    textTrackVisible,
  } = usePlayerState();
  const controls = usePlayerControls();
  const dispatch = useDispatch();

  if (!controls || typeof currentTime !== "number" || !playback?.playbackSpec)
    return null;

  const { getSpeed, pause, play } = controls;

  return (
    // TODO: check cast status, to determine what tooltip to show
    <CastIcon
      aria-label={tooltips?.START_CASTING}
      data-microtip-position="top"
      data-tooltip
      onClick={() => {
        dispatch({
          payload: {},
          type: UiEventTypes.CAST_INITIATED,
        });

        if (getSpeed() !== 0) pause();

        callback({
          currentTime,
          playbackSpec: playback.playbackSpec,
          selected: {
            assetLanguage: playback.selectedAssetAudioLanguage,
            audio: selectedAudioTrack && selectedAudioTrack.isoCode,
            text: textTrackVisible
              ? selectedTextTrack && selectedTextTrack.isoCode
              : null,
          },
          title,
          ...(typeof season === "number" &&
            typeof episode === "number" &&
            seriesTitle && {
              series: {
                episode,
                season,
                title: seriesTitle,
              },
            }),
        }).catch(() => {
          if (getSpeed() === 0) play();

          // TODO show message that casting failed instead of playing?

          dispatch({
            payload: {},
            type: UiEventTypes.CAST_CANCELLED,
          });
        });
      }}
      onKeyDown={(e) => {
        if (e.key === " ") {
          e.preventDefault();
        }
      }}
      onMouseLeave={(e) => e.currentTarget.blur()}
      smallLayout={smallLayout}
    >
      <Icon />
    </CastIcon>
  );
};
