import { CoreTrackingEvent } from "@telia-company/tv.web-playback-sdk";
import {
  TContentMetadata,
  TPlaybackConfig,
  TTimeline,
} from "@telia-company/tv.web-player-shared";
import { Dispatch, SetStateAction, useEffect } from "react";

import { UiEventTypes } from "../constants";
import { currentTimeWithinTimelineBlock } from "../utils";
import { TAction } from "./ui-state-reducer.hook";

export type TUsePlayNextEpisodeOptions = {
  currentTime: null | number;
  dispatch: Dispatch<TAction>;
  duration: null | number;
  metadata: TContentMetadata;
  publishCustomTrackingEvent: (name: string) => void;
  restarting: boolean;
  setPlayback: Dispatch<SetStateAction<TPlaybackConfig>>;
  showNextEpisodeOverlay: boolean;
  timeline: TTimeline;
};

export const useNextContent = ({
  currentTime,
  dispatch,
  duration,
  metadata,
  publishCustomTrackingEvent,
  restarting,
  setPlayback,
  showNextEpisodeOverlay,
  timeline,
}: TUsePlayNextEpisodeOptions): void => {
  useEffect(() => {
    if (restarting) return;
    if (!metadata.nextEpisode) return;
    if (
      metadata.nextEpisode.validFrom > Date.now() ||
      metadata.nextEpisode.validTo < Date.now()
    )
      return;

    if (duration && currentTime && timeline?.closingCredits) {
      // hide notification and do nothing if more than 22s left
      const inClosingCredits = currentTimeWithinTimelineBlock(
        timeline.closingCredits,
        currentTime
      );
      if (!inClosingCredits) {
        if (!showNextEpisodeOverlay) return;

        dispatch({
          payload: {},
          type: UiEventTypes.HIDE_NEXT_EPISODE,
        });

        return;
      }

      // Change episode when closing credits end
      if (timeline.closingCredits.end - currentTime < 1) {
        setPlayback({
          playbackSpec: metadata.nextEpisode.playbackSpec,
          startTimeInSeconds: 0,
        });
      } else if (!showNextEpisodeOverlay && inClosingCredits) {
        publishCustomTrackingEvent(
          CoreTrackingEvent.PLAYER_NEXT_EPISODE_IMPRESSION
        );
        dispatch({
          payload: {
            nextEpisodeOverlayDuration: Math.min(
              timeline.closingCredits.end - timeline.closingCredits.start,
              timeline.closingCredits.end - currentTime
            ),
          },
          type: UiEventTypes.SHOW_NEXT_EPISODE,
        });
      }
    }
  }, [
    setPlayback,
    dispatch,
    metadata,
    duration,
    timeline?.closingCredits,
    currentTime,
    restarting,
    showNextEpisodeOverlay,
    publishCustomTrackingEvent,
  ]);
};
