import {
  IAuthenticationComponent,
  ServiceConfig,
} from "@telia-company/tv.web-playback-sdk";
import { TPlayerConfig } from "@telia-company/tv.web-player-ui";
import { FC } from "react";

import packageJson from "../../package.json";
import { TEnv } from "../@types/types";
import { Content } from "../mock-data/mockContent";
import { CMORE_LOGO, TELIA_LOGO } from "./Logos";

export type TEnvConf = {
  id: TEnv;
  Logo: FC;
  name: string;
};

export type TServiceCountryConf = {
  serviceCountry: string;
};

export const TeliaStageEnv: TEnvConf = {
  id: "TELIA_STAGE",
  Logo: TELIA_LOGO,
  name: "Telia Stage",
};

export const TeliaProdEnv: TEnvConf = {
  id: "TELIA_PROD",
  Logo: TELIA_LOGO,
  name: "Telia Prod",
};

export const CmoreStageEnv: TEnvConf = {
  id: "CMORE_STAGE",
  Logo: CMORE_LOGO,
  name: "Cmore Stage",
};

export const CmoreProdEnv: TEnvConf = {
  id: "CMORE_PROD",
  Logo: CMORE_LOGO,
  name: "Cmore Prod",
};

export const EnvironmentConfig: Array<TEnvConf> = [
  TeliaStageEnv,
  TeliaProdEnv,
  CmoreStageEnv,
  CmoreProdEnv,
];

const baseConfig = {
  clientName: "web",
  deviceModel: "reference app",
  screen: {
    screenHeight: 1080,
    screenWidth: 1920,
  },
  serviceCountry: "SE",
};

const trackerBaseConfig = {
  application: {
    applicationName: "Reference App",
    applicationVersion: packageJson.version,
  },
};

export type TUrlMap = Record<TEnv, string>;

const streamingGatewayEndpointMap: TUrlMap = {
  CMORE_PROD: "https://streaminggateway-cmore.clientapi-prod.live.tv.telia.net",
  CMORE_STAGE:
    "https://streaminggateway-cmore.clientapi-pilot.live.tv.telia.net",
  TELIA_PROD: "https://streaminggateway.clientapi-prod.live.tv.telia.net",
  TELIA_STAGE: "https://streaminggateway.clientapi-pilot.live.tv.telia.net",
};

const playbackTrackingEndpointMap: TUrlMap = {
  CMORE_PROD: "",
  CMORE_STAGE: "",
  TELIA_PROD:
    "https://playbacktrackinggateway.clientapi-prod.live.tv.telia.net/event",
  TELIA_STAGE:
    "https://playbacktrackinggateway.clientapi-pilot.live.tv.telia.net/event",
};

const graphEndpointMap: TUrlMap = {
  CMORE_PROD: "https://graphql-cmore.t6a.net",
  CMORE_STAGE: "https://graphql-cmore.t6a-stage.net",
  TELIA_PROD: "https://graphql-telia.t6a.net",
  TELIA_STAGE: "https://graphql-telia.t6a-stage.net",
};
const loginGatewayEndpointMap: TUrlMap = {
  CMORE_PROD: "https://logingateway-cmore.clientapi-prod.live.tv.telia.net",
  CMORE_STAGE: "https://logingateway-cmore.clientapi-pilot.live.tv.telia.net",
  TELIA_PROD: "https://logingateway.clientapi-prod.live.tv.telia.net",
  TELIA_STAGE: "https://logingateway.clientapi-pilot.live.tv.telia.net",
};

export const loginMap = {
  CMORE_PROD: {
    password: process.env.REACT_APP_CMORE_PROD_PW || "",
    username: process.env.REACT_APP_CMORE_PROD_USER || "",
  },
  CMORE_STAGE: {
    password: process.env.REACT_APP_CMORE_STAGE_PW || "",
    username: process.env.REACT_APP_CMORE_STAGE_USER || "",
  },
  TELIA_PROD: {
    password: process.env.REACT_APP_TELIA_PROD_PW || "",
    username: process.env.REACT_APP_TELIA_PROD_USER || "",
  },
  TELIA_STAGE: {
    password: process.env.REACT_APP_TELIA_STAGE_PW || "",
    username: process.env.REACT_APP_TELIA_STAGE_USER || "",
  },
};

export const contentMap = {
  CMORE_PROD: Content.CMORE,
  CMORE_STAGE: Content.CMORE,
  TELIA_PROD: Content.TELIA,
  TELIA_STAGE: Content.TELIA,
};

export const getServiceConfig = ({
  applicationSessionId,
  deviceId,
  env,
  serviceCountry,
}: {
  applicationSessionId: string;
  deviceId: string;
  env: TEnv;
  serviceCountry: string;
}): Omit<ServiceConfig, "tvClientGatewayUrl"> => ({
  ...baseConfig,
  application: {
    applicationName: "web-reference-app",
    applicationVersion: packageJson.version,
  },
  applicationSessionId,
  clientName: "web",
  deviceId,
  graphClientVersion: packageJson.version,
  graphUrl: graphEndpointMap[env],
  loginGatewayUrl: loginGatewayEndpointMap[env],
  operatingSystemName: "",
  serviceBrand: env.indexOf("TELIA") > -1 ? "TELIA" : "CMORE",
  serviceCountry,
  serviceName: env.indexOf("TELIA") > -1 ? "Telia TV" : "C More",
  streamingGatewayUrl: streamingGatewayEndpointMap[env],
});

export const getPlayerConfig = ({
  applicationSessionId,
  deviceId,
  env,
  iAuth,
  serviceCountry,
  userId,
}: {
  applicationSessionId: string;
  deviceId: string;
  env: TEnv;
  iAuth: IAuthenticationComponent;
  serviceCountry: string;
  userId: null | string;
}): TPlayerConfig => ({
  ...getServiceConfig({ applicationSessionId, deviceId, env, serviceCountry }),
  ...trackerBaseConfig,
  auth: iAuth,
  playbackTrackingUrl: playbackTrackingEndpointMap[env],
  // springstreamsSite: "",
  serviceId: env.indexOf("TELIA") > -1 ? "telia-play" : "cmore",
  user: userId
    ? {
        userId,
        userIsLoggedIn: true,
      }
    : undefined,
  youboraAccount: "tv4dev",
});
