export enum AdvertisementBreakTypes {
  MIDROLL = "MIDROLL",
  POSTROLL = "POSTROLL",
  PREROLL = "PREROLL",
}

export enum ErrorCategories {
  ADS = "Ads",
  API = "API",
  DEFAULT = "Default",
  DRM = "DRM",
  GRAPH = "Graph",
  LOGIN_GATEWAY = "LoginGateway",
  MEDIA = "Media",
  NETWORK = "Network",
  STREAMING_GATEWAY = "StreamingGateway",
  TV_CLIENT_GATEWAY = "TvClientGateway",
  USER = "User",
}

// Deprecated
export enum ErrorSeverities {
  CRITICAL = "CRITICAL",
  RECOVERABLE = "RECOVERABLE",
}

export enum PlaybackEventTypes {
  ADVERTISEMENT_ENDED = "ADVERTISEMENT_ENDED",
  ADVERTISEMENT_STARTED = "ADVERTISEMENT_STARTED",
  ADVERTISEMENT_BREAK_ENDED = "ADVERTISEMENT_BREAK_ENDED",
  ADVERTISEMENT_BREAK_STARTED = "ADVERTISEMENT_BREAK_STARTED",
  ADVERTISEMENT_TIME_UPDATE = "ADVERTISEMENT_TIME_UPDATE",

  AUDIO_TRACK_CHANGED = "AUDIO_TRACK_CHANGED",
  BITRATE_CHANGED = "BITRATE_CHANGED",
  BUFFERED = "BUFFERED",
  BUFFERING = "BUFFERING",
  CDN_CHANGED = "CDN_CHANGED",
  DROPPED_FRAMES = "DROPPED_FRAMES",
  ERROR = "ERROR",
  /* The stream is ready, with enough buffer, to start playing */
  LOADED = "LOADED",
  /* Api calls are done, a streaming ticket has been received, and the player/engine will start loading */
  LOADING = "LOADING",
  MANIFEST_TYPE_CHANGED = "MANIFEST_TYPE_CHANGED",
  PAUSED = "PAUSED",
  /* A play has been requested from the underlying engine */
  PLAY = "PLAY",
  /* Playback has started in the underlying engine */
  PLAYING = "PLAYING",
  SEEKED = "SEEKED",
  SEEKING = "SEEKING",
  /* Before anything - a request to play something has been received */
  STARTING = "STARTING",
  /* Stream has stopped for whatever reason */
  STOPPED = "STOPPED",
  STREAM_CUE = "STREAM_CUE",
  /* Information about the stream is available */
  STREAM_INFO = "STREAM_INFO",
  TEXT_TRACK_CHANGED = "TEXT_TRACK_CHANGED",
  TEXT_TRACK_VISIBILITY_CHANGED = "TEXT_TRACK_VISIBILITY_CHANGED",
  TIME_UPDATE = "TIME_UPDATE",
  TRACKING_INFO = "TRACKING_INFO",
  VOLUME_CHANGED = "VOLUME_CHANGED",
  CUSTOM_EVENT = "CUSTOM_EVENT",
}

export enum StoppedReasons {
  ADVERTISEMENT_SKIP = "ADVERTISEMENT_SKIP",
  ADVERTISEMENT_SWITCH = "ADVERTISEMENT_SWITCH",
  END_OF_STREAM = "END_OF_STREAM",
  ERROR = "ERROR",
  INACTIVITY = "INACTIVITY",
  INTERRUPTED = "INTERRUPTED",
  NEXT_CONTENT_AUTOMATIC = "NEXT_CONTENT_AUTOMATIC",
  NEXT_CONTENT_CHOICE = "NEXT_CONTENT_CHOICE",
  RELATED_CONTENT_AUTOMATIC = "RELATED_CONTENT_AUTOMATIC",
  RELATED_CONTENT_CHOICE = "RELATED_CONTENT_CHOICE",
  USER = "USER",
  CONCURRENT_STREAMS = "CONCURRENT_STREAMS",
  UNKNOWN = "UNKNOWN",
}

export enum StreamCueTypes {
  EMSG = "EMSG",
  ID3 = "ID3",
  ID3_IN_EMSG = "ID3_IN_EMSG",
  SCTE35 = "SCTE35",
  UNKNOWN = "UNKNOWN",
}

export const SdkWatchMode = {
  ONDEMAND: "ONDEMAND",
  STARTOVER: "STARTOVER",
  LIVE: "LIVE",
  TRAILER: "TRAILER",
};

export const SdkAccessControl = {
  FREE: "FREE",
  SUBSCRIPTION: "SUBSCRIPTION",
  TRANSACTION: "TRANSACTION",
  NPVR: "NPVR",
};

export const SdkVideoIdType = {
  CHANNEL: "CHANNEL",
  MEDIA: "MEDIA",
};

export enum ServiceCountry {
  SWEDEN = "SE",
  DENMARK = "DK",
  FINLAND = "FI",
  LITHUANIA = "LT",
  NORWAY = "NO",
}

export type PlaybackSpec = {
  accessControl: SdkAccessControl;
  videoId: string;
  videoIdType: SdkVideoIdType;
  watchMode: SdkWatchMode;
};

export type SdkAccessControl =
  (typeof SdkAccessControl)[keyof typeof SdkAccessControl];
export type SdkVideoIdType =
  (typeof SdkVideoIdType)[keyof typeof SdkVideoIdType];
export type SdkWatchMode = (typeof SdkWatchMode)[keyof typeof SdkWatchMode];
