import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import { PlaybackEventTypes } from "../Constants";

export type TTimeUpdateEventPayload = {
  currentTime: number;
  duration: number;
};

export class TimeUpdateEvent extends PlaybackEventBase<
  PlaybackEventTypes.TIME_UPDATE,
  TTimeUpdateEventPayload
> {
  constructor(payload: TTimeUpdateEventPayload) {
    super(PlaybackEventTypes.TIME_UPDATE, payload);
  }
}
