/**
 * List of will-send events.
 *
 * @memberof npaw.Plugin
 * @enum
 * @event
 */
var WillSendEvent = {
  WILL_SEND_INIT: 'will-send-init',
  WILL_SEND_START: 'will-send-start',
  WILL_SEND_JOIN: 'will-send-join',
  WILL_SEND_PAUSE: 'will-send-pause',
  WILL_SEND_RESUME: 'will-send-resume',
  WILL_SEND_SEEK: 'will-send-seek',
  WILL_SEND_DATA_OBJECT: 'will-send-data',
  WILL_SEND_BUFFER: 'will-send-buffer',
  WILL_SEND_ERROR: 'will-send-error',
  WILL_SEND_FATAL_ERROR: 'will-send-fatal-error',
  WILL_SEND_STOP: 'will-send-stop',
  WILL_SEND_PING: 'will-send-ping',
  WILL_SEND_VIDEO_EVENT: 'will-send-video-event',

  WILL_SEND_AD_START: 'will-send-ad-start',
  WILL_SEND_AD_JOIN: 'will-send-ad-join',
  WILL_SEND_AD_PAUSE: 'will-send-ad-pause',
  WILL_SEND_AD_RESUME: 'will-send-ad-resume',
  WILL_SEND_AD_BUFFER: 'will-send-ad-buffer',
  WILL_SEND_AD_STOP: 'will-send-ad-stop',
  WILL_SEND_AD_CLICK: 'will-send-ad-click',
  WILL_SEND_AD_ERROR: 'will-send-ad-error',
  WILL_SEND_AD_MANIFEST: 'will-send-ad-manifest',
  WILL_SEND_AD_POD_START: 'will-send-ad-break-start',
  WILL_SEND_AD_POD_STOP: 'will-send-ad-break-stop',
  WILL_SEND_AD_QUARTILE: 'will-send-ad-quartile',

  WILL_SEND_SESSION_START: 'will-send-session-start',
  WILL_SEND_SESSION_STOP: 'will-send-session-stop',
  WILL_SEND_NAV: 'will-send-nav',
  WILL_SEND_BEAT: 'will-send-beat',
  WILL_SEND_EVENT: 'will-send-event',

  WILL_SEND_OFFLINE_EVENTS: 'will-send-offline-events'
}
module.exports = WillSendEvent
