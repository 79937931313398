import styled from "@emotion/styled";
import React, { ComponentType, FC } from "react";

import { TServiceCountryConf } from "../config/ref-app-config";
import { BrandColorDark } from "../misc/constants";
import {
  changeServiceCountryAction,
  useRefAppDispatch,
  useRefAppState,
} from "../reducer/ref.reducer";
import { Button } from "./Button";

const ServiceCountryBarWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  background: ${BrandColorDark};
  display: flex;
  flex-wrap: wrap;
`;

const ServiceCountryItem = styled.li<{ right?: boolean }>`
  padding: 0.5rem;
  display: flex;
  align-self: end;
  margin: 0;
  margin-left: ${({ right }) => (right ? "auto" : "0")};
`;

export type TServiceCountry = {
  icon: ComponentType | string;
  id: string;
};

export type Props = {
  serviceCountries: TServiceCountry[];
};

export const ServiceCountryBar: FC<Props> = ({ serviceCountries }) => {
  const { serviceCountry } = useRefAppState();
  const dispatch = useRefAppDispatch();
  const changeCountry = (payload: TServiceCountryConf) => {
    dispatch(changeServiceCountryAction(payload));
  };

  return (
    <ServiceCountryBarWrapper>
      {serviceCountries.map((country) => (
        <ServiceCountryItem key={country.id}>
          <Button
            disabled={country.id === serviceCountry}
            onClick={() =>
              changeCountry({
                serviceCountry: country.id,
              })
            }
            text={country.id + country.icon}
          />
        </ServiceCountryItem>
      ))}
    </ServiceCountryBarWrapper>
  );
};
