import styled from "@emotion/styled";
import { TCallback } from "@telia-company/tv.web-player-shared";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import { TPlayerIcon } from "../../../../@types/icon-type";
import { ButtonWithIcon } from "../../../buttons/button-with-icon";
import { useIcon, useTranslations } from "../../../context-provider";
import { TRelatedContentSeason } from "../types/types";

const margin = "5.5%";

const SeasonSelectorList = styled.ul<{
  leftArrowVisible: boolean;
  rightArrowVisible: boolean;
}>`
  list-style: none;
  padding: 0.25em;
  line-height: 1;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.25em;
  display: flex;
  grid-gap: 0.25em;
  font-weight: bold;
  overflow-x: scroll;
  scrollbar-width: none;
  width: 95%;
  &::-webkit-scrollbar {
    display: none;
  }
  ${({ leftArrowVisible, rightArrowVisible }) => {
    const both = leftArrowVisible && rightArrowVisible;
    if (both)
      return `mask-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(255,255,255,0) 100%);`;
    if (leftArrowVisible)
      return `mask-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 10%);`;
    if (rightArrowVisible)
      return `mask-image: linear-gradient(90deg, rgba(0,0,0,1) 90%, rgba(255,255,255,0) 100%);`;
    return "";
  }}
`;

const SeasonSelectorListItem = styled.li<{ selected: boolean }>`
  padding: 0.75em 1em;
  border-radius: 0.25em;
  cursor: pointer;
  font-size: 0.8em;
  white-space: nowrap;

  ${({ selected }) =>
    selected
      ? `background: rgba(255,255,255,0.15);
    color: #fff;
    cursor: default;`
      : ""}

  &:hover {
    ${({ selected }) =>
      !selected ? `background: rgba(255,255,255,0.15);` : ""};
  }
`;

const ArrowIconContainer = styled.div`
  width: 1.5em;
  height: 55%;
  position: relative;
  left: -1em;
  display: flex;
  align-items: center;
  padding-left: 0.25em;
  padding-right: 0.25em;
  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const ButtonGridWrapper = styled.div`
  grid-column: 16;
`;

const SeriesRelatedContentHeaderWrapper = styled.div`
  width: 100%;
  display: grid;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
  padding: 0 ${margin};
`;

const TitleText = styled.div`
  margin: 0 ${margin};
  align-self: start;
  font-size: 1em;
`;

enum Direction {
  LEFT,
  RIGHT,
}

export type TSeriesHeaderProps = {
  buttonIcon: TPlayerIcon;
  buttonText: string;
  headerText: string;
  onClose: TCallback;
  seasons: TRelatedContentSeason[];
  selectedSeason: null | number;
  setSelectedSeason: Dispatch<SetStateAction<null | number>>;
} & React.JSX.IntrinsicAttributes;

export const SeriesRelatedContentHeader: FC<TSeriesHeaderProps> = ({
  buttonIcon,
  buttonText,
  headerText,
  onClose,
  seasons,
  selectedSeason,
  setSelectedSeason,
}) => {
  const translations = useTranslations();
  const { IconNavigateLeft, IconNavigateRight } = useIcon();
  const [leftArrowVisible, setLeftArrowVisible] = useState(false);
  const [rightArrowVisible, setRightArrowVisible] = useState(false);

  const seasonRef = useRef<HTMLUListElement>(null);

  const checkArrowVisibility = () => {
    if (!seasonRef.current || seasonRef.current === null) return;
    const { clientWidth, scrollLeft, scrollWidth } = seasonRef.current;
    setLeftArrowVisible(scrollLeft > 0);
    setRightArrowVisible(scrollWidth - clientWidth > scrollLeft);
  };

  const scrollSeasons = (dir: Direction) => {
    if (seasonRef === null || seasonRef.current === null) return;
    // should scroll to somewhere between 0 and seasonRef.scrollWidth
    const step = Math.round(seasonRef.current.clientWidth / 3);
    const amount = dir === Direction.RIGHT ? step : step * -1;
    seasonRef.current.scrollLeft = Math.min(
      Math.max(seasonRef.current.scrollLeft + amount, 0),
      seasonRef.current.scrollWidth
    );
    checkArrowVisibility();
  };

  useEffect(() => {
    if (!seasonRef.current) return;
    checkArrowVisibility();
  }, [seasonRef]);

  return (
    <>
      <TitleText>{headerText}</TitleText>
      <SeriesRelatedContentHeaderWrapper>
        {leftArrowVisible ? (
          <ArrowIconContainer onClick={() => scrollSeasons(Direction.LEFT)}>
            <IconNavigateLeft />
          </ArrowIconContainer>
        ) : null}
        <SeasonSelectorList
          leftArrowVisible={leftArrowVisible}
          ref={seasonRef}
          rightArrowVisible={rightArrowVisible}
        >
          {seasons.map(({ season }) => (
            <SeasonSelectorListItem
              key={season}
              onClick={(ev) => {
                // TODO: a bit buggy when clicking near the end of the season list
                // and the "Season"-text expands
                ev.currentTarget.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "center",
                });
                setSelectedSeason(season);
                checkArrowVisibility();
              }}
              selected={season === selectedSeason}
            >
              {/* TODO translation */}
              {season === selectedSeason ? translations.season : ""} {season}
            </SeasonSelectorListItem>
          ))}
        </SeasonSelectorList>
        {rightArrowVisible ? (
          <ArrowIconContainer onClick={() => scrollSeasons(Direction.RIGHT)}>
            <IconNavigateRight />
          </ArrowIconContainer>
        ) : null}
        <ButtonGridWrapper>
          <ButtonWithIcon
            Icon={buttonIcon}
            onClick={onClose}
            text={buttonText}
          />
        </ButtonGridWrapper>
      </SeriesRelatedContentHeaderWrapper>
    </>
  );
};
