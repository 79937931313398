import {
  Logger as LoggerSingleton,
  ErrorCategories,
  ErrorSeverities,
  GraphError,
  NetworkError,
  PlaybackSpec,
} from "../../base";
import { BaseService } from "../BaseService";
import { FetchRequestOptions, FetchRequestResponse } from "../../network";
import { MediaMetadataQuery } from "./query/MediaMetadataQuery";
import {
  ChannelMetadataQuery,
  ChannelsMetadataQuery,
} from "./query/ChannelMetadataQuery";
import { MetadataServiceConfiguration, ServiceConfig } from "../Types";
import { ServiceError } from "../errors/";
import {
  ChannelMetadata,
  ChannelsMetadata,
  MediaMetadata,
  TimeRange,
} from "./Types";
import { MediaMetadataMapper } from "./mappers/MediaMetadataMapper";
import { ChannelMetadataMapper } from "./mappers/ChannelMetadataMapper";
import { ServiceErrorCodes } from "../errors";
//@ts-ignore
import Bowser from "bowser";
import { ChannelsMetadataMapper } from "./mappers/ChannelsMetadataMapper";

const Logger = LoggerSingleton.createLoggerContext("MetadataService");

export type GetChannelMetadataArgs = {
  playbackSpec: PlaybackSpec;
  timeRange?: TimeRange;
  limit?: number;
};

export type GetMediaMetadataArgs = {
  playbackSpec: PlaybackSpec;
};

export class MetadataService extends BaseService {
  protected configuration?: MetadataServiceConfiguration | ServiceConfig;
  private mediaQuery: MediaMetadataQuery = new MediaMetadataQuery();
  private channelQuery: ChannelMetadataQuery = new ChannelMetadataQuery();
  private channelsQuery: ChannelsMetadataQuery = new ChannelsMetadataQuery();

  protected headers: any = {};

  constructor() {
    super("MetadataService");
  }

  public initialize(
    configuration: MetadataServiceConfiguration | ServiceConfig
  ) {
    this.configuration = configuration;

    const parser = Bowser.getParser(navigator.userAgent);
    const browserName = parser.getBrowserName();
    const browserVersion = parser.getBrowserVersion();
    const osName = parser.getOSName();
    const osVersion = parser.getOSVersion();

    this.headers = {
      "tv-client-name": "webplayer",
      "tv-client-version": this.configuration.graphClientVersion,
      "tv-client-boot-id": this.configuration.applicationSessionId,
      "tv-client-tz": Intl.DateTimeFormat().resolvedOptions().timeZone,
      "X-Country": this.configuration.serviceCountry.toUpperCase(),
      ...(osName ? { "tv-client-os-name": osName } : {}),
      ...(osVersion ? { "tv-client-os-version": osVersion } : {}),
      ...(browserName ? { "tv-client-browser": browserName } : {}),
      ...(browserVersion
        ? { "tv-client-browser-version": browserVersion }
        : {}),
      ...(this.configuration.deviceVendor
        ? { "tv-client-vendor": this.configuration.deviceVendor }
        : {}),
      "tv-client-vendor-model": this.configuration.deviceModel,
    };
  }

  private createRequestOptions(
    body: string,
    isAuthenticated: boolean = false
  ): FetchRequestOptions {
    return {
      body: body,
      headers: this.headers,
      useAuthentication: isAuthenticated,
      ignoreGlobalHeaders: false,
    };
  }

  public async getChannels(): Promise<Array<ChannelsMetadata>> {
    if (!this.configuration || !this.configuration.graphUrl) {
      throw new ServiceError({
        code: ServiceErrorCodes.MissingConfiguration,
        category: ErrorCategories.DEFAULT,
        fatal: true,
        details: {
          origin: this.name,
          domain: "getChannelsMetadata",
          severity: ErrorSeverities.RECOVERABLE,
        },
      });
    }

    const isAuthenticated = await this.requestFactory.isAuthenticated();
    const graphQuery = this.channelsQuery.generateBody();
    const response: FetchRequestResponse | NetworkError =
      await this.requestFactory.fetch(
        this.configuration.graphUrl,
        this.createRequestOptions(graphQuery, isAuthenticated)
      );

    if (response instanceof NetworkError) {
      if (response.responseBody?.errorCode) {
        throw new GraphError({
          fatal: true,
          originalError: response,
          details: {
            origin: this.name,
            domain: "getChannels",
            configuration: this.configuration,
          },
        });
      }

      throw response;
    } else if (response instanceof FetchRequestResponse) {
      const mappedMetadata = ChannelsMetadataMapper(response.responseBody);
      if (!mappedMetadata) {
        throw new ServiceError({
          code: ServiceErrorCodes.MappingError,
          category: ErrorCategories.DEFAULT,
          fatal: true,
          details: {
            origin: this.name,
            domain: "getChannelMetadata",
            severity: ErrorSeverities.RECOVERABLE,
          },
        });
      }

      return mappedMetadata;
    } else {
      throw new ServiceError({
        code: ServiceErrorCodes.BadResponseError,
        category: ErrorCategories.GRAPH,
        fatal: true,
        details: {
          origin: this.name,
          domain: "getChannelMetadata",
          severity: ErrorSeverities.RECOVERABLE,
        },
      });
    }
  }

  /**
   * @param {boolean} includeNextContent
   * @throws {MetadataServiceError}
   * @throws {NetworkError}
   * @throws {MissingConfigurationError}
   */
  public async getChannelMetadata(
    args: GetChannelMetadataArgs
  ): Promise<ChannelMetadata> {
    const methodName = "getChannelMetadata";
    if (!this.configuration || !this.configuration.graphUrl) {
      throw new ServiceError({
        code: ServiceErrorCodes.MissingConfiguration,
        category: ErrorCategories.DEFAULT,
        fatal: true,
        details: {
          origin: this.name,
          domain: methodName,
          severity: ErrorSeverities.RECOVERABLE,
        },
      });
    }

    if (args.timeRange) {
      if (args.timeRange.start instanceof Date) {
        args.timeRange.start = args.timeRange.start.valueOf();
      }
      if (args.timeRange.end instanceof Date) {
        args.timeRange.end = args.timeRange.end.valueOf();
      }
    }

    const isAuthenticated = await this.requestFactory.isAuthenticated();
    const graphQuery = this.createRequestOptions(
      this.channelQuery.generateBody({
        id: args.playbackSpec.videoId,
        limit: args.limit,
        timeRange: args.timeRange,
      }),
      isAuthenticated
    );

    const response: FetchRequestResponse | NetworkError =
      await this.requestFactory.fetch(this.configuration.graphUrl, graphQuery);

    if (response instanceof NetworkError) {
      if (response.responseBody?.errorCode) {
        throw new GraphError({
          fatal: true,
          originalError: response,
          details: {
            origin: this.name,
            domain: "getChannelMetadata",
            configuration: this.configuration,
          },
        });
      }

      throw response;
    } else if (response instanceof FetchRequestResponse) {
      const mappedMetadata = ChannelMetadataMapper(
        response.responseBody,
        args.timeRange
      );
      if (!mappedMetadata) {
        throw new ServiceError({
          code: ServiceErrorCodes.MappingError,
          category: ErrorCategories.DEFAULT,
          fatal: true,
          details: {
            origin: this.name,
            domain: methodName,
            severity: ErrorSeverities.RECOVERABLE,
          },
        });
      }

      return mappedMetadata;
    } else {
      throw new ServiceError({
        code: ServiceErrorCodes.BadResponseError,
        category: ErrorCategories.GRAPH,
        fatal: true,
        details: {
          origin: this.name,
          domain: methodName,
          severity: ErrorSeverities.RECOVERABLE,
        },
      });
    }
  }

  /**
   * @throws {MetadataServiceError}
   * @throws {NetworkError}
   * @throws {MissingConfigurationError}
   */
  public async getMediaMetadata(
    args: GetMediaMetadataArgs
  ): Promise<MediaMetadata> {
    if (!this.configuration || !this.configuration.graphUrl) {
      throw new ServiceError({
        code: ServiceErrorCodes.MissingConfiguration,
        category: ErrorCategories.DEFAULT,
        fatal: true,
        details: {
          origin: this.name,
          domain: "getMediaMetadata",
          severity: ErrorSeverities.RECOVERABLE,
        },
      });
    }

    const isAuthenticated = await this.requestFactory.isAuthenticated();
    const graphQuery = this.mediaQuery.generateBody({
      id: args.playbackSpec.videoId,
    });
    const response: FetchRequestResponse | NetworkError =
      await this.requestFactory.fetch(
        this.configuration.graphUrl,
        this.createRequestOptions(graphQuery, isAuthenticated)
      );

    if (response instanceof NetworkError) {
      if (response.responseBody?.errorCode) {
        throw new GraphError({
          fatal: true,
          originalError: response,
          details: {
            origin: this.name,
            domain: "getMediaMetadata",
            configuration: this.configuration,
          },
        });
      }

      throw response;
    } else if (response instanceof FetchRequestResponse) {
      const mappedMetadata = MediaMetadataMapper(response.responseBody);
      if (!mappedMetadata) {
        throw new ServiceError({
          code: ServiceErrorCodes.MappingError,
          category: ErrorCategories.DEFAULT,
          fatal: true,
          details: {
            origin: this.name,
            domain: "getMediaMetadata",
            severity: ErrorSeverities.RECOVERABLE,
          },
        });
      }

      return mappedMetadata;
    } else {
      throw new ServiceError({
        code: ServiceErrorCodes.BadResponseError,
        category: ErrorCategories.GRAPH,
        fatal: true,
        details: {
          origin: this.name,
          domain: "getMediaMetadata",
          severity: ErrorSeverities.RECOVERABLE,
        },
      });
    }
  }

  async reset(): Promise<void> {
    return Promise.resolve();
  }

  async destroy(): Promise<void> {
    return Promise.resolve();
  }
}
