var Emitter = require('../emitter')
const { default: Log } = require('../../common/log')
const { AnalyticsTag } = require('../../common/Constants');

var NpawVideosRepository = Emitter.extend({
  /**
   *
   * @param plugin
   */
  constructor: function (plugin) {
    /** Instance plugin */
    this.plugin = plugin

    /** Create videos object */
    this.videos = {}
  },

  /**
   *
   * @param videoKey
   * @returns {undefined|*}
   */
  existsVideo: function (videoKey) {
    videoKey = videoKey || 'default'
    if (this.videos[videoKey]) {
      return true
    }
    return false
  },

  /**
   *
   * @param videoKey
   * @returns {undefined|*}
   */
  getVideo: function (videoKey) {
    videoKey = videoKey || 'default'
    if (this.videos[videoKey]) {
      return this.videos[videoKey]
    }
    return undefined
  },

  /**
   * @param {object} videoObj
   * @returns {undefined|*}
   */
  setVideo: function (videoObj) {
    this.videos[this._parseVideoKey(videoObj.getVideoKey())] = videoObj
  },

  /**
   * Get Video Keys
   * @returns {string[]}
   */
  getVideoKeys: function () {
    return Object.keys(this.videos)
  },

  /**
   *
   * @param videoKey
   * @returns {boolean}
   */
  existsAdapter: function (videoKey) {
    videoKey = videoKey || 'default'
    if (this.videos[videoKey]) {
      if (this.videos[videoKey]._adapter) {
        return true
      }
    }
    return false
  },

  /**
   *
   * @param videoKey
   * @returns {undefined|*}
   */
  getAdapter: function (videoKey) {
    videoKey = videoKey || 'default'
    if (this.videos[videoKey]) {
      return this.videos[videoKey].getAdapter()
    }
    return undefined
  },

  /**
   *
   * @param adapterObj
   * @param videoKey
   * @returns {undefined|*}
   */
  setAdapter: function (adapterObj, videoKey) {
    videoKey = videoKey || 'default'
    if (this.videos[videoKey]) {
      this.videos[videoKey].setAdapter(adapterObj, this.plugin)
    }
  },

  /**
   *
   * @param videoKey
   * @returns {undefined|*}
   */
  getAdsAdapter: function (videoKey) {
    videoKey = videoKey || 'default'
    if (this.videos[videoKey]) {
      return this.videos[videoKey].getAdsAdapter()
    }
    return undefined
  },

  /**
   *
   * @param contentAdapterListeners
   * @param videoKey
   */
  removeAdapter: function (contentAdapterListeners, videoKey) {
    videoKey = videoKey || 'default'
    try {
      if (this.videos[videoKey]) {
        this.videos[videoKey].removeAdapter()
      }
    } catch (err) {
      Log.error(AnalyticsTag, 'Is not possible to remove adapter for video: ' + videoKey)
    }
  },

  /**
   * Removes all the registered adapters
   */
  removeAllAdapters: function () {
    for (const video of Object.keys(this.videos)) {
      this.videos[video].removeAdapter();
      this.videos[video].removeAdsAdapter();
    }
  },

  /**
   *
   * @param videoKey
   * @param willSendEvent
   * @param service
   * @param params
   * @param body
   * @param method
   * @param callback
   * @param callbackParams
   */
  sendRequest: function (videoKey, willSendEvent, service, params, body, method, callback, callbackParams) {
    videoKey = videoKey || 'default'
    if (this.videos[videoKey]) {
      this.videos._send(this._key, willSendEvent, service, params, body, method, callback, callbackParams)
    } else {
      Log.error(AnalyticsTag, 'Can\'t send request for not existent video object')
    }
  },

  /**
   *
   * @param options
   */
  updateAllOptions: function (options) {
    for (const videoKey in this.videos) {
      for (const configKey in options) {
        this.videos[videoKey].options[configKey] = options[configKey];
      }
    }
    if (options['adapters.templates']) {
      this.plugin._setAdapterTemplates(options['adapters.templates']);
      this.updateAdapterTemplates(options['adapters.templates']);
    }
  },

  /**
   *
   * @param pingInterval
   */
  updatePingInterval: function (pingInterval) {
    for (var videoKey in this.videos) {
      this.videos[videoKey]._ping.interval = pingInterval
    }
  },

  /**
   *
   * @param adapterTemplates
   */
  updateAdapterTemplates: function (adapterTemplates) {
    if (adapterTemplates && this.videos) {
      for (var videoTag in this.videos) {
        if (this.videos[videoTag]._adapter) {
          this.videos[videoTag]._adapter.updateAdapterTemplates(adapterTemplates)
        }
      }
    }
  },

  /**
   *
   * @param videoKey
   */
  resetVideo: function (videoKey) {
    videoKey = videoKey || 'default'
    if (this.video[videoKey]) {
      this.video[videoKey]._reset()
    }
  },

  /**
   *
   * @param videoKey
   * @returns {string|*}
   * @private
   */
  _parseVideoKey: function (videoKey) {
    return (!videoKey) ? 'default' : videoKey
  }
})

module.exports = NpawVideosRepository
