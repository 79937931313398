import isArray from './mixins/isarray';
export var LogLevel;
(function (LogLevel) {
    /** No console outputs */
    LogLevel[LogLevel["SILENT"] = 6] = "SILENT";
    /** Console will show errors */
    LogLevel[LogLevel["ERROR"] = 5] = "ERROR";
    /** Console will show warnings */
    LogLevel[LogLevel["WARNING"] = 4] = "WARNING";
    /** Console will show notices (ie: life-cycle logs) */
    LogLevel[LogLevel["NOTICE"] = 3] = "NOTICE";
    /** Console will show debug messages (ie: player events) */
    LogLevel[LogLevel["DEBUG"] = 2] = "DEBUG";
    /** Console will show verbose messages (ie: Http Requests) */
    LogLevel[LogLevel["VERBOSE"] = 1] = "VERBOSE";
})(LogLevel || (LogLevel = {}));
/**
 * Static Log class for NPAW Plugin
 */
export default class Log {
    /**
     * Returns a console message
     *
     * @private
     * @param tag Tag used to identify component
     * @param msg Message string, error object or array of messages.
     * @param level Defines the level of the error sent.
     * Only errors with higher or equal level than Log.logLevel will be displayed.
     * @param color Color of the header
     */
    static report(tag, msg, level, color) {
        if (typeof console !== 'undefined' && console.log) {
            level = level || LogLevel.NOTICE;
            color = color || 'darkcyan';
            const letters = {
                5: 'e',
                4: 'w',
                3: 'n',
                2: 'd',
                1: 'v' // Verbose
            };
            const letter = letters[level];
            let prefix = `[NPAW - ${tag}]` + this._getCurrentTime() + ' ' + letter + ':';
            // this.emit('log', { level: level, msg: msg, prefix: prefix })
            // Show messages in actual console if level is enought
            if (Log.logLevel <= level) {
                // document['documentMode'] exits only in IE
                if (Log.plainLogs || (typeof document !== 'undefined' && document['documentMode'])) {
                    // Plain log for IE and devices
                    this._plainReport(msg, prefix);
                }
                else {
                    // choose log method
                    let logMethod;
                    if (level === LogLevel.ERROR && console.error) {
                        logMethod = console.error;
                    }
                    else if (level === LogLevel.WARNING && console.warn) {
                        logMethod = console.warn;
                    }
                    else if (level === LogLevel.DEBUG && console.debug) {
                        logMethod = console.debug;
                    }
                    else {
                        logMethod = console.log;
                    }
                    // print message
                    prefix = '%c' + prefix;
                    if (isArray(msg)) {
                        msg.splice(0, 0, prefix, 'color: ' + color);
                        logMethod.apply(console, msg);
                    }
                    else {
                        logMethod.call(console, prefix, 'color: ' + color, msg);
                    }
                }
            }
        }
    }
    /**
     * Returns the current time in format hh:mm:ss.mmm (with trailing 0s)
     * @return Current time.
     */
    static _getCurrentTime() {
        const d = new Date();
        const hh = ('0' + d.getDate()).slice(-2);
        const mm = ('0' + d.getMinutes()).slice(-2);
        const ss = ('0' + d.getSeconds()).slice(-2);
        const mmm = ('00' + d.getMilliseconds()).slice(-3);
        return '[' + hh + ':' + mm + ':' + ss + '.' + mmm + ']';
    }
    /**
     * Returns a console message without style
     *
     * @param msg Message string, object or array of messages.
     * @param prefix Prefix of the message.
     */
    static _plainReport(msg, prefix) {
        if (msg instanceof Array) {
            for (const m in msg) {
                this._plainReport(msg[m], prefix);
            }
        }
        else {
            if (typeof msg === 'string') {
                console.log(prefix + ' ' + msg);
            }
            else {
                console.log(prefix + ' <next line>');
                console.log(msg);
            }
        }
    }
    /**
     * Defines the level of the NPAW Plugin logging
     *
     * @param logLevel Logging level
     */
    static setLogLevel(logLevel) {
        this.logLevel = logLevel;
    }
    /**
     * Gets the current log level of the NPAW Plugin
     *
     * @return The current log level.
     */
    static getLogLevel() {
        return this.logLevel;
    }
    /**
     * Sends an error (level 1) console log.
     * Supports unlimited arguments: ("this", "is", "a", "message")
     */
    static error(tag, ...msg) {
        this.report(tag, [].slice.call(arguments, 1), LogLevel.ERROR, 'darkred');
    }
    /**
     * Sends a warning (level 2) console log.
     * Supports unlimited arguments: ("this", "is", "a", "message")
     */
    static warn(tag, ...msg) {
        this.report(tag, [].slice.call(arguments, 1), LogLevel.WARNING, 'darkorange');
    }
    /**
     * Sends a notice (level 3) console log.
     * Supports unlimited arguments: ("this", "is", "a", "message")
     */
    static notice(tag, ...msg) {
        this.report(tag, [].slice.call(arguments, 1), LogLevel.NOTICE, 'darkgreen');
    }
    /**
     * Sends a debug message (level 4) to console.
     * Supports unlimited arguments: ("this", "is", "a", "message")
     */
    static debug(tag, ...msg) {
        this.report(tag, [].slice.call(arguments, 1), LogLevel.DEBUG, 'indigo');
    }
    /**
     * Sends a verbose message (level 5) to console.
     * Supports unlimited arguments: ("this", "is", "a", "message")
     */
    static verbose(tag, ...msg) {
        this.report(tag, [].slice.call(arguments, 1), LogLevel.VERBOSE, 'navy');
    }
    /**
     * This function is automatically executed at npawlib's init.
     * Will search inside window.location.search for attribute 'npaw-debug=X'.
     * X can have one of these values, that will modify LogLevel.
     * 6: SILENT,
     * 5: ERROR,
     * 4: WARNING,
     * 3: NOTICE,
     * 2: DEBUG,
     * 1: VERBOSE
     *
     * If npaw-console=plain is present, plainLogs will be set to true.
     */
    static loadLevelFromUrl() {
        if (typeof window !== 'undefined' && window.location) {
            this._parseLevelFromUrl(window.location.search);
        }
    }
    static _parseLevelFromUrl(url) {
        if (url) {
            const m = /\?.*&*npaw-debug=(.+)/i.exec(url);
            if (m !== null) {
                this.logLevel = Number(m[1]);
            }
            const m2 = /\?.*&*npaw-debug=plain/i.exec(url);
            if (m2 !== null) {
                this.plainLogs = true;
            }
        }
    }
}
/**
 * Only logs of this imporance or higher will be shown.
 * @default LogLevels.ERROR
 */
Log.logLevel = LogLevel.ERROR;
/**
 * If true, console logs will always be outputed without colors (for debbugin in devices).
 * @default false
 */
Log.plainLogs = false;
