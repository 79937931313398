import { TimedMetadata } from "@yospace/admanagement-sdk";
import { Id3Cue } from "../../../../Types";
import { secondsToMs } from "../../../../../utils";

interface IYospaceTimedDataTagsObject {
  ymid: string;
  ycsp: string;
  ytyp: string;
  yseq: string;
  ydur: string;
}

export const parseYospaceTimedDataObjectFromId3Cues = (
  id3Cue: Id3Cue
): TimedMetadata => {
  const tags = id3Cue.tags.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.key.toLowerCase()]: String.fromCharCode(...Array.from(curr.data)),
    };
  }, {}) as IYospaceTimedDataTagsObject;

  return TimedMetadata.createFromMetadata(
    tags.ymid,
    tags.yseq,
    tags.ytyp,
    parseFloat(tags.ydur),
    secondsToMs(id3Cue.startTime)
  );
};
