import { Dispatch, SetStateAction, useEffect } from "react";

export type TUseLocalProgressWhileSeekingOptions = {
  currentTime: null | number;
  isDragging: boolean;
  seeking: boolean;
  seekRequested: boolean;
  setDragPosition: Dispatch<SetStateAction<null | number>>;
  setSeekRequested: Dispatch<SetStateAction<boolean>>;
};

export const useLastDragPositionWhileSeeking = ({
  currentTime,
  isDragging,
  seeking,
  seekRequested,
  setDragPosition,
  setSeekRequested,
}: TUseLocalProgressWhileSeekingOptions): void => {
  useEffect(() => {
    if (seekRequested && seeking) {
      setSeekRequested(false);
    }

    if (isDragging || seekRequested || seeking) return;

    setDragPosition(null);
  }, [
    currentTime,
    isDragging,
    seeking,
    setDragPosition,
    seekRequested,
    setSeekRequested,
  ]);
};
