import { PlaybackEventTypes, PlaybackSpec } from "../Constants";
import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import type { PlayerInfo, UserInfo, SessionInfo } from "../dtos";
import { AdvertisementBreakInfo } from "../dtos";
import { ExcludeMethods } from "./Types";

export type AdvertisementBreakInfoData = ExcludeMethods<AdvertisementBreakInfo>;

export type ContentInfo = {
  contentUrl: string;
  playNext: boolean; // if content was started via PlayNext
  stitched: boolean;
  playbackSpec: PlaybackSpec;
};

// Streaming Gateway controls which properties are sent on the VideoTracking
// object. They should be passed as-is to the WireTracker/video tracking.
export type TVideoTracking = Record<string, any>;

export type TYouboraTracking = {
  assetId: string;
  country: string;
  live: boolean;
  streamingProtocol: string;
  title: string;
  transactionCode: string;
  videoId: string;
  videoIdType: string;
  watchMode: string;
};

export type TNielsenContentMetadataSweden = {
  type: string;
  assetid: string;
  length: string;
  program: string;
  title: string;
  userid: string;
  clientpassparam: string;
  ispremiumcontent: string;
  isautoplay: string;
  playerv: string;
};

export type TNielsenContentMetadataDenmark = {
  adloadtype: string;
  airdate: string;
  assetid: string;
  isfullepisode: string;
  islivestn: string;
  length: string;
  pbstarttm: string;
  program: string;
  stationId: string;
  subbrand: string;
  title: string;
  type: string;
};

export type TNielsenTracking = {
  appInformation: {
    appid: string;
    appname: string;
    nol_devDebug?: string;
  };
  contentMetadata:
    | TNielsenContentMetadataDenmark
    | TNielsenContentMetadataSweden;
  channelInfo?: {
    channelName: string;
  };
};

export type TrackingData = {
  YOUBORA?: TYouboraTracking;
  PLAYBACKTRACKING?: TVideoTracking;
  VIDEOTRACKING?: TVideoTracking;
  LOGSTASH?: Record<string, unknown>;
  NIELSEN?: TNielsenTracking;
};

export type TTrackingInfoPlayback = {
  startTimeInSeconds: number;
};

export type TTrackingInfoEventPayload = Partial<{
  content: Partial<ContentInfo>;
  playback: Partial<TTrackingInfoPlayback>;
  tracking: TrackingData;
  player: PlayerInfo;
  session: SessionInfo;
  user: UserInfo | null;
  advertisementBreaks: AdvertisementBreakInfoData[];
}>;

export class TrackingInfoEvent extends PlaybackEventBase<
  PlaybackEventTypes.TRACKING_INFO,
  TTrackingInfoEventPayload
> {
  constructor(payload: TTrackingInfoEventPayload) {
    super(PlaybackEventTypes.TRACKING_INFO, payload);
  }
}
