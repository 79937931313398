import teliaDesignToken from "@telia-company/tv.one-design-system-web/legacy/desktop/telia/tokens.json";
import { UselessProvider } from "@telia-company/tv.web-player-cc-sender";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";

export type ChromecastThingProps = {
  auth: {
    profileHeader: null | string;
    refreshToken: string | undefined;
  } | null;
  children?: ReactNode;
  env: string;
  serviceCountry: string;
};

export const ChromecastThing2: FC<ChromecastThingProps> = ({
  auth,
  children,
  env,
  serviceCountry,
}) => {
  const [applicationId, setApplicationId] = useState<string | undefined>("");

  const designToken = teliaDesignToken;

  const getApplicationIds = useCallback(() => {
    const applicationIds = [
      {
        id: "0D86F1F9",
        name: "CMORE_STAGE",
      },
      {
        id: "1095029F",
        name: "CMORE_PROD",
      },
      {
        id: "0142F0D4",
        name: "TELIA_STAGE",
      },
      {
        id: "14839D9B",
        name: "TELIA_PROD",
      },
    ];
    const appid = applicationIds.find((i) => i.name === env)?.id;
    return appid;
  }, [env]);

  useEffect(() => {
    setApplicationId(getApplicationIds());
    // setDesignToken(
    //   env[0].toUpperCase() === "C" ? cmoreDesignToken : teliaDesignToken
    // );
  }, [env, serviceCountry, getApplicationIds]);

  if (!applicationId || getApplicationIds() !== applicationId) {
    return null;
  }
  return (
    <UselessProvider
      applicationId={applicationId}
      breakpoints={{
        L: 1025,
        M: 769,
        S: 480,
        XL: 1440,
      }}
      credentials={{
        auxiliaryData: {
          autoLogin: true,
          profileHeader: auth?.profileHeader || "",
        },
        token: auth?.refreshToken || "",
      }}
      designTokens={designToken}
      preferredLanguage={{
        audio: ["swe"],
        audioRoles: [],
        text: ["swe"],
        textRoles: [],
      }}
      serviceCountry={serviceCountry}
      translations={{
        audioTracks: "Ljudspår",
        descriptiveAudio: "Syntolkat",
        episode: "Avsnitt",
        hardOfHearingSubtitles: "CC",
        off: "Av",
        season: "Säsong",
        subtitleTracks: "Undertexter",
      }}
    >
      {children}
    </UselessProvider>
  );
};
