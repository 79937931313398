import { PlaybackEventTypes } from "../Constants";
import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import { AdvertisementInfo } from "../dtos";

export class AdvertisementStartedEvent extends PlaybackEventBase<
  PlaybackEventTypes.ADVERTISEMENT_STARTED,
  AdvertisementInfo
> {
  constructor(adInfo: AdvertisementInfo) {
    super(PlaybackEventTypes.ADVERTISEMENT_STARTED, adInfo);
  }
}
