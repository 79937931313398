var YBRequest = require('../../../request')
var Parser = require('./parser')
var TransportFormat = require('../../../../constants/transportFormat')
const { AnalyticsTag } = require('../../../../../common/Constants');
const { default: Log } = require('../../../../../common/log');

var DashParser = Parser.extend(
  /** @lends npaw.DashParser.prototype */
  {
    /**
     * Starts the Dash parsing from the given resource. The first (outside) call should set the
     * parentResource to null.
     *
     * @param {string} resource The resource url.
     */
    parse: function (resource, lastManifest) {
      if (!lastManifest) {
        var request = new YBRequest(resource, null, null, { cache: true, requestHeaders: this._headers })

        request.on(YBRequest.Event.SUCCESS, function (resp) {
          this.parseLocation(resp, resource)
        }.bind(this))

        request.on(YBRequest.Event.ERROR, function (resp) {
          this.done()
        }.bind(this))

        request.send()
      } else {
        this.parseLocation(lastManifest, resource)
      }
    },

    parseLocation: function (lastManifest, resource) {
      this.iterations--
      var locationRegexp = new RegExp(/<Location>([\s\S]+)<\/Location>/)
      try {
        this._lastManifest = lastManifest
        var manifest = lastManifest.getResponseText()
        var matches = locationRegexp.exec(manifest)

        if (matches && matches[1]) {
          var newRes = this._htmlDecode(matches[1].replace('\n', ''))
        }
        if (newRes && newRes !== resource && this.iterations > 0) {
          this.parse(newRes)
        } else {
          this.parseFinalResource(manifest, resource)
        }
      } catch (err) {
        Log.warn(AnalyticsTag, 'Dash parse failed')
        this.done()
      }
    },

    _htmlDecode: function (input) {
      if (typeof document !== 'undefined' && typeof document.createElement === 'function') {
        var e = document.createElement('textarea')
        e.innerHTML = input
        return e.childNodes && e.childNodes.length ? e.childNodes[0].nodeValue : input
      }
      return input
    },

    parseFinalResource: function (manifest, resource) {
      var baseUrlRegexp = new RegExp(/<BaseURL>[\r\n]*(.*?)[\r\n]*<\/BaseURL>/)
      var segmentUrlRegexp = new RegExp(/<SegmentURL[\s\S]*media="([^"]+)/)
      var segmentTemplateRegexp = new RegExp(/<SegmentTemplate[\s\S]*media="([^"]+)/)
      var matches = null
      // first: base url, second: segment url, third: segment template
      try {
        matches = baseUrlRegexp.exec(manifest) || segmentTemplateRegexp.exec(manifest) || segmentUrlRegexp.exec(manifest)
        this._getManifestMetadata(manifest)
        if (matches && matches[1] && this._isFullUrl(matches[1])) {
          if (matches[1].indexOf('"') > 0) {
            this._realResource = matches[1].substr(0, matches[1].indexOf('"')).replace('\n', '')
          } else {
            this._realResource = matches[1].replace('\n', '')
          }
        } else {
          this._realResource = resource
        }
      } catch (err) {
        Log.warn(AnalyticsTag, 'Dash manifest parse failed')
      }
      this.done()
    },

    _getManifestMetadata: function (manifest) {
      try {
        var transportFormatRegexp = new RegExp(/<AdaptationSet[\s\S]*mimeType="video\/([^"]+)/)
        var matches = transportFormatRegexp.exec(manifest)
        var ret = null
        switch (matches[1]) {
          case 'mp4':
          case 'm4s':
            ret = TransportFormat.MP4
            break
          case 'mp2t':
            ret = TransportFormat.MPEG2
            break
          case 'cmfv':
            ret = TransportFormat.CMF
            break
        }
        this._transportFormat = ret
      } catch (err) {
        Log.warn(AnalyticsTag, 'Couldnt find the transport format')
      }
    },

    _isFullUrl: function (url) {
      return url.indexOf('http') !== -1
    },

    shouldExecute: function (lastManifest) {
      var ret = true
      if (lastManifest) {
        var response = lastManifest.getResponseText()
        ret = response.indexOf('<MPD') !== -1
      }
      return ret
    }
  }
)

module.exports = DashParser
