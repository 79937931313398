import { PlaybackRestrictions } from "@telia-company/tv.web-playback-sdk";

export const canSeek = ({
  currentPositionInSeconds,
  destination,
  restrictions,
}: {
  currentPositionInSeconds: number;
  destination: "LIVE" | number;
  restrictions: PlaybackRestrictions;
}): boolean => {
  // No seek allowed.
  if (restrictions.noRewind && restrictions.noFastForward) {
    return false;
  }
  // Cannot go to live point if forward seek is restricted
  if (restrictions.noFastForward && destination === "LIVE") {
    return false;
  }

  if (typeof destination === "number") {
    // No seeking forward
    if (currentPositionInSeconds < destination && restrictions.noFastForward) {
      return false;
    }

    // No seeking backward
    if (currentPositionInSeconds > destination && restrictions.noRewind) {
      return false;
    }
  }

  return true;
};
