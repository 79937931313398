import {
  INormalizedError,
  OperatingSystemInfo,
  TLanguageCode,
} from "@telia-company/tv.web-playback-sdk";
import { createPlaybackService } from "@telia-company/tv.web-player-service-layer";
import {
  TClientErrorId,
  TEngineVariantRestrictions,
  TPlaybackConfig,
  TPlaybackService,
  TPlaybackSpec,
  TPlayerConfig,
  TPlayerFeatures,
  TPlayerStats,
  TTranslations,
  TUserConfig,
} from "@telia-company/tv.web-player-shared";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import type { TState } from "./hooks/ui-state-reducer.hook";

import {
  TCurrentlyPlayingData,
  TDesign,
  TMetadataImplementations,
  TUiCallbacks,
  TUsePlayerOptions,
} from "./@types";
import { DefaultStyles } from "./components/context-provider";
import { VideoWrapper } from "./components/video-wrapper";
import { EngineState, playerUiVersion } from "./constants";
import { engineLogic } from "./engine-logic";
import { useAutoplay } from "./hooks/autoplay.hook";
import { useClickHandlers } from "./hooks/click-handlers.hook";
import { useDestroyPlayer } from "./hooks/destroy-player.hook";
import { useDetectProgramChange } from "./hooks/detect-program-change.hook";
import { useDocumentEvents } from "./hooks/document-events.hook";
import { useDomElements } from "./hooks/dom-elements.hook";
import { useMediaSessionMetadata } from "./hooks/media-session.hook";
import { useMetadata } from "./hooks/metadata.hook";
import { useNewPlaybackConfig } from "./hooks/new-content-spec.hook";
import { useNextContent } from "./hooks/next-content.hook";
import { useNoDeadEnds } from "./hooks/no-dead-ends.hook";
import { usePlayerEvents } from "./hooks/player-events.hook";
import { usePlayerSetup } from "./hooks/player-setup.hook";
import { useRelatedContent } from "./hooks/related-content.hook";
import { useUiStateReducer } from "./hooks/ui-state-reducer.hook";
import { isPlaybackProbablySupported } from "./utils";

export type TUsePlayer = {
  currentlyPlaying: TPlaybackSpec;
  debug: TState;
  destroyPlayer: () => void;
  error: INormalizedError | null;
  getCurrentlyPlayingData: () => null | TCurrentlyPlayingData;
  minimizeRequested: boolean;
  onConcurrentStreams: ((id: string) => void) | null;
  state: EngineState;
  stats?: TPlayerStats;
  VideoWrapper: null | ReactNode;
};

const useFlatObjectMemo = <
  T extends
    | TEngineVariantRestrictions
    | TMetadataImplementations
    | TPlaybackConfig
    | TPlayerConfig
    | TPlayerFeatures
    | TTranslations
    | TUiCallbacks
    | TUserConfig,
>(
  data: T
): T => {
  const deps = Object.values(data);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => data, [...deps]);
};

const usePlayer = ({
  callbacks: notMemoizedCallbacks = {},
  conf: notMemoizedConf,
  design: { customStyles, icons },
  features: notMemoizedFeatures = {},
  imageProxy: notMemoizedImageProxy,
  metadata: notMemoizedMetadata = {},
  playback: notMemoizedPlayback,
  restrictions: notMemoizedRestrictions = {},
  // evaluate if we should memoize translations (needs deep memo)
  translations: notMemoizedTranslations,
  user: notMemoizedUser = {},
}: TUsePlayerOptions): TUsePlayer => {
  const incomingPlayback =
    useFlatObjectMemo<TPlaybackConfig>(notMemoizedPlayback);
  const callbacks = useFlatObjectMemo(notMemoizedCallbacks);
  const user = useFlatObjectMemo<TUserConfig>(notMemoizedUser);
  const conf = useFlatObjectMemo(notMemoizedConf);
  const translations = useFlatObjectMemo(notMemoizedTranslations);
  const restrictions = useFlatObjectMemo(notMemoizedRestrictions);
  const features = useFlatObjectMemo(notMemoizedFeatures);
  const metadataImpl = useFlatObjectMemo(notMemoizedMetadata);

  const imageProxyImpl = useCallback(notMemoizedImageProxy, [
    notMemoizedImageProxy,
  ]);

  // The playbackService exposes methods for interacting with the service layer.
  // A single playbackService instance can handle multiple playback sessions.
  const [playbackService] = useState<TPlaybackService>(
    createPlaybackService({ client: "web-player", engineLogic })
  );

  // "event" is the event manager that is shared across services.
  // The publish method feeds into the ui-state-reducer as a reducer
  // dispatch via a mapper hook.
  const [{ off, on, publishCustomTrackingEvent }] = useState(
    playbackService.event
  );

  const [onConcurrentStreams, setOnConcurrentStreams] = useState<
    ((id: string) => void) | null
  >(null);

  // Represents what is being played.
  // If this changes, the player will start a new playback session, fetch
  // new metadata etc.
  const [playback, setPlayback] = useState<TPlaybackConfig>(incomingPlayback);

  useEffect(() => {
    if (features.timers) {
      // eslint-disable-next-line no-console
      console.time("incoming playback -> stream is playing");
      // eslint-disable-next-line no-console
      console.time("incoming playback -> stream ticket request");
    }
    setPlayback(incomingPlayback);
  }, [incomingPlayback, features.timers]);

  const design = useMemo<TDesign>(
    () => ({
      customStyles: {
        ...DefaultStyles,
        ...customStyles,
      },
      icons,
    }),
    [customStyles, icons]
  );

  const [initialized, setInitialized] = useState(false);

  const [state, dispatch] = useUiStateReducer();

  const {
    controls,
    currentTime,
    duration,
    engineState,
    error,
    isInAdBreak,
    isLive,
    liveSeekEnabled,
    loading,
    metadata: { endTime: epgEndTime, startTime: epgStartTime },
    metadata,
    minimizeRequested,
    playbackType,
    restarting,
    seekRange,
    selectedAudioTrack,
    selectedTextTrack,
    shouldFetchMetadata,
    showNextEpisodeOverlay,
    stats,
    textElement,
    textTrackVisible,
    timeline,
    unmodifiedSeekRange,
    videoElement,
  } = state;

  const clickHandlers = useClickHandlers({
    controls,
    dispatch,
    isInAdBreak,
    isLive,
    liveSeekEnabled,
    loading,
    metadata,
    playback,
    publishCustomTrackingEvent,
    seekRange,
    setPlayback,
    translations,
  });

  const destroyPlayer = useDestroyPlayer({ playbackService });

  useNewPlaybackConfig({
    dispatch,
    playback,
  });

  useMediaSessionMetadata({
    metadata,
  });

  useDetectProgramChange({
    currentTime,
    dispatch,
    endTime: epgEndTime,
    liveSeekEnabled,
    playbackType,
    seekRange,
    startTime: epgStartTime,
  });

  usePlayerEvents({
    dispatch,
    off,
    on,
  });

  useDocumentEvents({
    dispatch,
  });

  const { textWrapperCallbackRef, videoWrapperCallbackRef } = useDomElements({
    textElement,
    videoElement,
  });

  useEffect(() => {
    if (!initialized) {
      playbackService.initializeServices({
        conf: {
          ...conf,
          ...new OperatingSystemInfo({
            // empty strings get overridden in the class constructor
            operatingSystemFirmwareVersion: "",
            operatingSystemName: "",
            operatingSystemVersion: "",
          }),
          graphClientVersion: playerUiVersion,
        },
        version: playerUiVersion,
      });

      setInitialized(true);
    }
  }, [initialized, playbackService, conf]);

  usePlayerSetup({
    features,
    initialized,
    playback,
    playbackService,
    restrictions,
    setOnConcurrentStreams,
    user,
  });

  useAutoplay({
    autoplay: typeof playback.autoplay === "boolean" ? playback.autoplay : true,
    controls,
    engineState,
  });

  useMetadata({
    currentTime,
    dispatch,
    liveSeekEnabled,
    playbackService,
    playbackSpec: playback.playbackSpec,
    seekRange: unmodifiedSeekRange,
    shouldFetchMetadata,
  });

  useRelatedContent({
    dispatch,
    getRelatedContent: metadataImpl.getRelatedContent,
    playback,
  });

  useNextContent({
    currentTime,
    dispatch,
    duration,
    metadata,
    publishCustomTrackingEvent,
    restarting,
    setPlayback,
    showNextEpisodeOverlay,
    timeline,
  });

  useNoDeadEnds({
    currentTime,
    dispatch,
    duration,
    metadata,
    minimizeRequested,
    timeline,
  });

  const ComponentTree = VideoWrapper({
    callbacks,
    contextValues: {
      clickHandlers,
      controls,
      design,
      dispatch,
      features,
      imageProxyImpl,
      metadataImpl,
      publishCustomTrackingEvent,
      state,
      translations,
    },
    textWrapperCallbackRef,
    videoWrapperCallbackRef,
  });

  const getCurrentlyPlayingData = useCallback(() => {
    if (!videoElement) return null;

    return {
      currentTime: videoElement.currentTime,
      playbackSpec: playback.playbackSpec,
      selected: {
        assetLanguage: playback.selectedAssetAudioLanguage,
        audio: selectedAudioTrack && selectedAudioTrack.isoCode,
        text: textTrackVisible
          ? selectedTextTrack && selectedTextTrack.isoCode
          : null,
      },
      title: metadata.title,
      ...(typeof metadata.season === "number" &&
        typeof metadata.episode === "number" &&
        metadata.seriesTitle && {
          series: {
            episode: metadata.episode,
            season: metadata.season,
            title: metadata.seriesTitle,
          },
        }),
    };
  }, [
    videoElement,
    metadata,
    selectedAudioTrack,
    selectedTextTrack,
    textTrackVisible,
    playback,
  ]);

  return {
    currentlyPlaying: playback.playbackSpec,
    debug: state,
    destroyPlayer,
    error,
    getCurrentlyPlayingData,
    minimizeRequested,
    onConcurrentStreams,
    state: engineState,
    stats,
    VideoWrapper: engineState === EngineState.STOPPED ? null : ComponentTree,
  };
};

export {
  isPlaybackProbablySupported,
  TClientErrorId,
  TLanguageCode,
  TPlaybackSpec,
  TPlayerConfig,
  TUserConfig,
  usePlayer,
};

export type * from "./@types";
export type * from "./components/overlays/list-overlay/types/types";
export * from "./constants";
export type * from "./hooks/ui-state-reducer.hook";
