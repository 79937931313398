import { SessionState } from "@yospace/admanagement-sdk";

export enum YospaceErrorCodes {
  FailedToCreateSession = "YospaceErrorCodes.FailedToCreateSession",
  LoadFailed = "YospaceErrorCodes.LoadFailed",
  StitchingFailed = "YospaceErrorCodes.StitchingFailed",
}

export class YospaceError {
  public code: YospaceErrorCodes;

  constructor(code: YospaceErrorCodes) {
    this.code = code;
  }
}

export class YospaceLoadError extends YospaceError {
  public sessionState: SessionState;
  public resultCode: number;

  constructor(
    sessionState: SessionState,
    resultCode: number,
    code?: YospaceErrorCodes
  ) {
    super(code ?? YospaceErrorCodes.LoadFailed);

    this.sessionState = sessionState;
    this.resultCode = resultCode;
  }
}

export const isYospaceError = (error: any): error is YospaceError =>
  error instanceof YospaceError;
export const isYospaceLoadError = (error: any): error is YospaceLoadError =>
  error instanceof YospaceLoadError;
