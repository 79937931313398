import styled from "@emotion/styled";
import { FC } from "react";

const fadeout = `
  @keyframes fadeout {
    0% {
      transform: scale(0.8);
      opacity: .3;
    }
    20% {
      transform: scale(1);
      opacity: 0.7;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }
`;

const SkipIndicatorWrapper = styled.div`
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.8em;
  pointer-events: none;
`;

const SkipIndicatorText = styled.span`
  animation: fadeout 0.3s ease-in;
  animation-fill-mode: forwards;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

  ${fadeout}
`;

export type SkipIndicatorProps = {
  numberOfSkips: number;
};

export const SkipIndicator: FC<SkipIndicatorProps> = ({ numberOfSkips }) =>
  numberOfSkips === 0 ? null : (
    <SkipIndicatorWrapper>
      <SkipIndicatorText key={numberOfSkips}>
        {`${numberOfSkips < 0 ? "" : "+"}${numberOfSkips * 10}`}
      </SkipIndicatorText>
    </SkipIndicatorWrapper>
  );
