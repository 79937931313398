import DiagnosticNQSEvent from './videoAnalytics/DiagnosticNQSEvent';
import DiagnosticLogs, { LogColors } from './utils/DiagnosticLogs';
import Event from '../analytics/constants/event';
import AnalyticsConstants from '../analytics/constants';
import Util from '../core/utils/Util';
import Core from '../core/Core';
export default class DiagnosticTool {
    // Make constructor private so that new instances of this class can't be created
    constructor() {
        this.integrationMessageColors = { RED: '#FF1515', GREEN: '#0f0', YELLOW: '#F6D500' };
        this.balancerEnabled = true;
        this.videoAnalyticsEnabled = true;
        this.adsAnalyticsEnabled = true;
        this.reportTimeoutDuration = DiagnosticTool.defaultReportTimeout;
        this.reportTimeoutStarted = false;
        this.runningOffline = false;
        // Balancer checks
        this.balancerInterceptedRequests = false;
        this.successfulResponseFromSelector = false;
        this.usedSelectorAPI = false;
        this.activeSwitchingEnabled = false;
        this.asDecisionPerformed = false;
        this.switchedCdns = false;
        this.cdnList = new Map();
        this.triedSendingBalancerStats = false;
        this.mandatoryBalancerRequests = ['cdn'];
        // Video Analytics checks
        this.capturedEvents = [];
        this.successfulNQSRequests = new Set();
        this.hasPlayerRegistered = false;
        this.triedSendingNQSStats = false;
        this.mandatoryNQSRequests = [Event.START, Event.JOIN_TIME, 'ping', Event.STOP];
        // Ads Analytics checks
        this.shouldIgnoreAds = false;
        this.capturedAdsEvents = [];
        this.successfulNQSAdsRequests = new Set();
        this.hasAdsPlayerRegistered = false;
        this.triedSendingNQSAdStats = false;
        this.mandatoryNQSAdsRequests = [
            AnalyticsConstants.Service.AD_START,
            AnalyticsConstants.Service.AD_MANIFEST,
            AnalyticsConstants.Service.AD_JOIN,
            AnalyticsConstants.Service.AD_STOP,
            AnalyticsConstants.Service.AD_POD_START,
            AnalyticsConstants.Service.AD_QUARTILE,
            AnalyticsConstants.Service.AD_POD_STOP
        ].map((el) => el.slice(1));
    }
    static getInstance(forceNew = false) {
        if (!this._instance || forceNew) {
            this._instance = new DiagnosticTool();
        }
        return this._instance;
    }
    /**
     * @internal
     */
    destroy() {
        this.clearReportTimeout();
        this.cleanCdnList();
        this.capturedEvents = [];
        this.successfulNQSRequests.clear();
        this.reportTimeoutStarted = false;
    }
    /**
     * @internal
     */
    start(npawPluginInstance) {
        if (npawPluginInstance)
            this.npawPluginInstance = npawPluginInstance;
        if (!this.reportTimeoutStarted) {
            this.reportTimeout = setTimeout(() => {
                this.report();
                this.videoAnalyticsEnabled = false;
                this.balancerEnabled = false;
                this.adsAnalyticsEnabled = false;
            }, this.reportTimeoutDuration);
            this.reportTimeoutStarted = true;
        }
    }
    /**
     * @internal
     */
    clearReportTimeout() {
        clearTimeout(this.reportTimeout);
        this.reportTimeoutStarted = false;
        this.reportTimeout = undefined;
    }
    /**
     * @internal
     * @param options
     */
    setOptions(options) {
        if (options) {
            if (options['ad.ignore']) {
                this.shouldIgnoreAds = Util.parseBoolean(options['ad.ignore']);
            }
            if (options['offline']) {
                this.runningOffline = Util.parseBoolean(options['offline']);
            }
        }
    }
    setBalancerEnabled(enabled) {
        this.balancerEnabled = enabled !== null && enabled !== void 0 ? enabled : this.balancerEnabled;
    }
    setVideoAnalyticsEnabled(enabled) {
        this.videoAnalyticsEnabled = enabled !== null && enabled !== void 0 ? enabled : this.videoAnalyticsEnabled;
    }
    setAdsAnalyticsEnabled(enabled) {
        this.adsAnalyticsEnabled = enabled !== null && enabled !== void 0 ? enabled : this.adsAnalyticsEnabled;
    }
    getReportTimeout() {
        return this.reportTimeoutDuration;
    }
    setReportTimeout(timeout) {
        if (timeout && timeout > 0) {
            this.reportTimeoutDuration = timeout !== null && timeout !== void 0 ? timeout : this.reportTimeoutDuration;
            this.clearReportTimeout();
            this.start();
        }
    }
    // Balancer Diagnostic methods
    /**
     * @internal
     */
    cleanCdnList() {
        this.cdnList.clear();
    }
    /**
     * @internal
     * @param name
     * @param cdn
     */
    addCdn(name, cdn) {
        this.cdnList.set(name, cdn);
    }
    /**
     * @internal
     * @param name
     * @param numberOfRequests
     * @param trafficServedInBytes
     * @param failedRequests
     */
    updateCdnInfo(name, numberOfRequests, trafficServedInBytes, failedRequests) {
        if (this.balancerEnabled) {
            const cdn = this.cdnList.get(name);
            if (cdn) {
                cdn.setRequestsPerformed(numberOfRequests);
                cdn.setTrafficServerInBytes(trafficServedInBytes);
                cdn.setFailedRequests(failedRequests);
            }
        }
    }
    /**
     * @internal
     * @param wasSuccessful
     * @param errorMessage
     */
    wasSelectorCommunicationSuccessful(wasSuccessful, errorMessage) {
        this.successfulResponseFromSelector = wasSuccessful;
        if (errorMessage) {
            this.apiErrorMessage = errorMessage;
        }
    }
    /**
     * @internal
     * @param intercepted
     */
    setInterceptedRequests(intercepted) {
        this.balancerInterceptedRequests = intercepted;
    }
    /**
     * @internal
     * @param enabled
     */
    setActiveSwitchingEnabled(enabled) {
        this.activeSwitchingEnabled = enabled;
    }
    /**
     * @internal
     * @param performed
     */
    wasActiveSwitchingDecisionPerformed(performed) {
        this.asDecisionPerformed = performed;
    }
    /**
     * @internal
     * @param switched
     */
    setSwitchedCdns(switched) {
        this.switchedCdns = switched;
    }
    /**
     * @internal
     * @param used
     */
    usedSelectorAPIResponse(used) {
        this.usedSelectorAPI = used;
    }
    /**
     * @internal
     * @param tried
     */
    setTriedSendingBalancerStats(tried) {
        this.triedSendingBalancerStats = tried;
    }
    // Video Analytics Diagnostic methods
    /**
     * @internal
     * @param requestPath
     */
    addNQSRequest(requestPath) {
        if (this.videoAnalyticsEnabled || this.balancerEnabled) {
            this.successfulNQSRequests.add(requestPath);
        }
    }
    /**
     * @internal
     * @param eventType
     * @param params
     */
    addNQSEvent(eventType, params) {
        if (this.videoAnalyticsEnabled) {
            this.capturedEvents.push(new DiagnosticNQSEvent(eventType, params));
        }
        if (eventType === 'stop') {
            setTimeout(() => {
                this.report();
                this.clearReportTimeout();
            }, 500);
        }
    }
    /**
     * @internal
     * @param registered
     */
    registeredPlayer(registered) {
        this.hasPlayerRegistered = registered;
    }
    /**
     * @internal
     * @param tried
     */
    setTriedSendingNQSStats(tried) {
        this.triedSendingNQSStats = tried;
    }
    // Ad Analytics methods
    /**
     * @internal
     * @param requestPath
     */
    addNQSAdRequest(requestPath) {
        if (this.adsAnalyticsEnabled) {
            this.successfulNQSAdsRequests.add(requestPath);
        }
    }
    /**
     * @internal
     * @param eventType
     * @param params
     */
    addNQSAdEvent(eventType, params) {
        if (this.adsAnalyticsEnabled) {
            this.capturedAdsEvents.push(new DiagnosticNQSEvent(eventType, params));
        }
    }
    /**
     * @internal
     * @param registered
     */
    registeredAdsPlayer(registered) {
        this.hasAdsPlayerRegistered = registered;
    }
    /**
     * @internal
     * @param tried
     */
    setTriedSendingNQSAdStats(tried) {
        this.triedSendingNQSAdStats = tried;
    }
    report() {
        if (this.balancerEnabled || this.videoAnalyticsEnabled || this.adsAnalyticsEnabled) {
            // TODO: Implement sending data to Control Panel
            const diagnosticHeader = '------- Certification Tool -------';
            DiagnosticLogs.header(diagnosticHeader);
            if (this.apiErrorMessage === 'ERROR_INVALID_ACCOUNT') {
                DiagnosticLogs.log('%cAccount code is invalid!', 'color: #FF1515; font-size: 12px; font-weight: bold;');
            }
            else {
                this.logBalancerDiagnostic();
                if (!Core.getInstance().isUsingLegacy()) {
                    this.logVideoAnalyticsDiagnostic();
                    this.logAdsAnalyticsDiagnostic();
                }
            }
            // Disable metrics collection and future reports
            this.videoAnalyticsEnabled = false;
            this.balancerEnabled = false;
            this.adsAnalyticsEnabled = false;
            this.clearReportTimeout();
            DiagnosticLogs.header(''.padEnd(diagnosticHeader.length, '-'));
        }
    }
    logBalancerDiagnostic() {
        var _a, _b;
        if (!this.balancerEnabled || !((_b = (_a = this.npawPluginInstance) === null || _a === void 0 ? void 0 : _a.isBalancerEnabled()) !== null && _b !== void 0 ? _b : true)) {
            return;
        }
        const nqsRequestsArray = Array.from(this.successfulNQSRequests).map((req) => req.slice(1));
        const reachedNQS = this.mandatoryBalancerRequests.some((request) => nqsRequestsArray.includes(request));
        const missingRequests = this.mandatoryBalancerRequests.filter((request) => !nqsRequestsArray.includes(request));
        DiagnosticLogs.header('Balancer Diagnostic');
        DiagnosticLogs.logWithPrefix('Intercepted requests: ' +
            (this.balancerInterceptedRequests
                ? DiagnosticLogs.colorizeText('Yes', LogColors.GREEN)
                : DiagnosticLogs.colorizeText('No', LogColors.RED)), DiagnosticLogs.logIdent);
        if (this.runningOffline) {
            DiagnosticLogs.logWithPrefix('Ignoring Balancer stats since running in offline mode', DiagnosticLogs.logIdent);
        }
        else if (this.triedSendingBalancerStats) {
            DiagnosticLogs.logWithPrefix('Any event sent to server: ' +
                (reachedNQS
                    ? DiagnosticLogs.colorizeText('Yes', LogColors.GREEN)
                    : DiagnosticLogs.colorizeText('No', LogColors.RED)), DiagnosticLogs.logIdent);
        }
        else {
            DiagnosticLogs.logWithPrefix('Reached stats server: Unknown', DiagnosticLogs.logIdent);
        }
        if (!this.runningOffline && missingRequests.length > 0 && this.triedSendingBalancerStats) {
            DiagnosticLogs.logWithPrefix(DiagnosticLogs.colorizeText('Missing requests: ' + missingRequests.join(', '), LogColors.YELLOW), DiagnosticLogs.logIdent);
        }
        DiagnosticLogs.logWithPrefix('Request to the Selector API: ' +
            (this.successfulResponseFromSelector
                ? DiagnosticLogs.colorizeText('Successful', LogColors.GREEN)
                : DiagnosticLogs.colorizeText('Unsuccessful', LogColors.RED)), DiagnosticLogs.logIdent);
        if (this.apiErrorMessage && !this.successfulResponseFromSelector) {
            DiagnosticLogs.logWithPrefix(this.getApiErrorMessage(), DiagnosticLogs.logIdent, 2);
        }
        DiagnosticLogs.logWithPrefix('Took decision based on Selector API: ' +
            (this.usedSelectorAPI
                ? DiagnosticLogs.colorizeText('Yes', LogColors.GREEN)
                : DiagnosticLogs.colorizeText('No', LogColors.RED)), DiagnosticLogs.logIdent);
        if (this.activeSwitchingEnabled) {
            DiagnosticLogs.logWithPrefix('Active switching decision: ' +
                (this.asDecisionPerformed
                    ? DiagnosticLogs.colorizeText('Performed', LogColors.GREEN)
                    : DiagnosticLogs.colorizeText('Not performed', LogColors.RED)), DiagnosticLogs.logIdent);
        }
        else {
            DiagnosticLogs.logWithPrefix('Active switching decision: ' + DiagnosticLogs.colorizeText('Active Switching Disabled!', LogColors.YELLOW), DiagnosticLogs.logIdent);
        }
        DiagnosticLogs.logWithPrefix('CDN List: ', DiagnosticLogs.logIdent);
        if (this.cdnList.size > 0) {
            const columnString = this.cdnList
                .values()
                .next()
                .value.columns.map((element) => element.padEnd(18))
                .join('');
            DiagnosticLogs.logWithPrefix(columnString, DiagnosticLogs.logIdent, 2);
        }
        else {
            DiagnosticLogs.logWithPrefix(DiagnosticLogs.colorizeText('The list is empty!', LogColors.RED), DiagnosticLogs.logIdent, 2);
        }
        for (const cdn of this.cdnList.values()) {
            const rowObject = cdn.generateRow();
            DiagnosticLogs.log(DiagnosticLogs.logIdent.repeat(2) + rowObject.row, 'color: ' + rowObject.color);
        }
        const failingCdns = Array.from(this.cdnList.values()).filter((cdn) => cdn.getFailedRequestsPercentage() > 30).length;
        const balancerIntegrated = this.successfulResponseFromSelector &&
            this.balancerInterceptedRequests &&
            this.usedSelectorAPI &&
            this.cdnList.size > 0 &&
            (this.triedSendingBalancerStats ? reachedNQS : true);
        let balancerIntegratedColor = this.integrationMessageColors.YELLOW;
        let integrationMessage = '%cCDN Balancer might not be integrated!';
        if (balancerIntegrated) {
            if (this.triedSendingBalancerStats && missingRequests.length === 0 && failingCdns < this.cdnList.size) {
                integrationMessage = '%cCDN Balancer is integrated!';
                balancerIntegratedColor = this.integrationMessageColors.GREEN;
            }
        }
        else {
            integrationMessage = '%cCDN Balancer is NOT integrated!';
            balancerIntegratedColor = this.integrationMessageColors.RED;
        }
        DiagnosticLogs.log(integrationMessage, `color: ${balancerIntegratedColor}; font-size: 12px; font-weight: bold;`);
    }
    logVideoAnalyticsDiagnostic() {
        if (!this.videoAnalyticsEnabled) {
            return;
        }
        const nqsRequestsArray = Array.from(this.successfulNQSRequests).map((req) => req.slice(1));
        const missingRequests = this.mandatoryNQSRequests.filter((request) => !nqsRequestsArray.includes(request));
        DiagnosticLogs.header('Video Analytics Diagnostic');
        DiagnosticLogs.logWithPrefix('Events sent to server/storage: ' +
            (nqsRequestsArray.length !== 0
                ? DiagnosticLogs.colorizeText('Yes', LogColors.GREEN)
                : DiagnosticLogs.colorizeText('No', LogColors.RED)), DiagnosticLogs.logIdent);
        DiagnosticLogs.logWithPrefix('All mandatory events sent to server/storage: ' +
            (missingRequests.length === 0
                ? DiagnosticLogs.colorizeText('Yes', LogColors.GREEN)
                : DiagnosticLogs.colorizeText('No', LogColors.RED)), DiagnosticLogs.logIdent);
        if (missingRequests.length > 0 && this.triedSendingNQSStats) {
            DiagnosticLogs.logWithPrefix('Missing stats requests: ' + DiagnosticLogs.colorizeText(missingRequests.join(', '), LogColors.RED), DiagnosticLogs.logIdent);
        }
        DiagnosticLogs.logWithPrefix('Was able to capture events: ' +
            (this.capturedEvents.length > 0
                ? DiagnosticLogs.colorizeText('Yes', LogColors.GREEN)
                : DiagnosticLogs.colorizeText('No', LogColors.RED)), DiagnosticLogs.logIdent);
        DiagnosticLogs.logWithPrefix('Captured events:', DiagnosticLogs.logIdent);
        if (this.capturedEvents.length > 0) {
            const columnString = this.capturedEvents[0].columns.map((element) => element.padEnd(15)).join('');
            DiagnosticLogs.logWithPrefix(columnString, DiagnosticLogs.logIdent, 2);
        }
        else {
            DiagnosticLogs.logWithPrefix(DiagnosticLogs.colorizeText('No events captured!', LogColors.RED), DiagnosticLogs.logIdent, 2);
        }
        for (const event of this.capturedEvents) {
            DiagnosticLogs.logWithPrefix(event.generateRow(), DiagnosticLogs.logIdent, 2);
        }
        const videoAnalyticsIntegrated = this.hasPlayerRegistered && nqsRequestsArray.length !== 0 && this.capturedEvents.length > 0;
        let videoAnalyticsIntegratedColor = this.integrationMessageColors.YELLOW;
        let integrationMessage = '%cVideo Analytics might not be integrated!';
        if (videoAnalyticsIntegrated) {
            if (this.triedSendingNQSStats && missingRequests.length === 0) {
                integrationMessage = '%cVideo Analytics is integrated!';
                videoAnalyticsIntegratedColor = this.integrationMessageColors.GREEN;
            }
        }
        else {
            integrationMessage = '%cVideo Analytics is NOT integrated!';
            videoAnalyticsIntegratedColor = this.integrationMessageColors.RED;
        }
        DiagnosticLogs.log(integrationMessage, `color: ${videoAnalyticsIntegratedColor}; font-size: 12px; font-weight: bold;`);
    }
    logAdsAnalyticsDiagnostic() {
        if (!this.adsAnalyticsEnabled) {
            return;
        }
        const nqsRequestsArray = Array.from(this.successfulNQSAdsRequests).map((req) => req.slice(1));
        const missingRequests = this.mandatoryNQSAdsRequests.filter((request) => !nqsRequestsArray.includes(request));
        DiagnosticLogs.header('Ads Analytics Diagnostic');
        if (this.shouldIgnoreAds) {
            DiagnosticLogs.log('%cIgnoring Ads Analytics because ad.ignore option is set to true', `color: ${this.integrationMessageColors.YELLOW}; font-size: 12px; font-weight: bold;`);
        }
        if (!this.hasAdsPlayerRegistered && nqsRequestsArray.length === 0) {
            DiagnosticLogs.log('%cIgnoring Ads Analytics because no ads adapter was registered', `color: ${this.integrationMessageColors.YELLOW}; font-size: 12px; font-weight: bold;`);
        }
        else {
            DiagnosticLogs.logWithPrefix('Events sent to server/storage: ' +
                (nqsRequestsArray.length !== 0
                    ? DiagnosticLogs.colorizeText('Yes', LogColors.GREEN)
                    : DiagnosticLogs.colorizeText('No', LogColors.RED)), DiagnosticLogs.logIdent);
            DiagnosticLogs.logWithPrefix('All mandatory events sent to server/storage: ' +
                (missingRequests.length === 0
                    ? DiagnosticLogs.colorizeText('Yes', LogColors.GREEN)
                    : DiagnosticLogs.colorizeText('No', LogColors.RED)), DiagnosticLogs.logIdent);
            if (missingRequests.length > 0 && this.triedSendingNQSAdStats) {
                DiagnosticLogs.logWithPrefix('Missing ad stats requests: ' + DiagnosticLogs.colorizeText(missingRequests.join(', '), LogColors.RED), DiagnosticLogs.logIdent);
            }
            DiagnosticLogs.logWithPrefix('Was able to capture ads events: ' +
                (this.capturedAdsEvents.length > 0
                    ? DiagnosticLogs.colorizeText('Yes', LogColors.GREEN)
                    : DiagnosticLogs.colorizeText('No', LogColors.RED)), DiagnosticLogs.logIdent);
            DiagnosticLogs.logWithPrefix('Captured events:', DiagnosticLogs.logIdent);
            if (this.capturedAdsEvents.length > 0) {
                const columnString = this.capturedAdsEvents[0].columns.map((element) => element.padEnd(15)).join('');
                DiagnosticLogs.logWithPrefix(columnString, DiagnosticLogs.logIdent, 2);
            }
            else {
                DiagnosticLogs.logWithPrefix(DiagnosticLogs.colorizeText('No events captured!', LogColors.RED), DiagnosticLogs.logIdent, 2);
            }
            for (const event of this.capturedAdsEvents) {
                DiagnosticLogs.logWithPrefix(event.generateRow(), DiagnosticLogs.logIdent, 2);
            }
            const adsAnalyticsIntegrated = (this.hasAdsPlayerRegistered || nqsRequestsArray.length != 0) && this.capturedAdsEvents.length > 0;
            let adsAnalyticsIntegratedColor = this.integrationMessageColors.YELLOW;
            let integrationMessage = '%cAds Analytics might not be integrated!';
            if (adsAnalyticsIntegrated) {
                if (missingRequests.length === 0) {
                    integrationMessage = '%cAds Analytics is integrated!';
                    adsAnalyticsIntegratedColor = this.integrationMessageColors.GREEN;
                }
            }
            else {
                integrationMessage = '%cAds Analytics is NOT integrated!';
                adsAnalyticsIntegratedColor = this.integrationMessageColors.RED;
            }
            DiagnosticLogs.log(integrationMessage, `color: ${adsAnalyticsIntegratedColor}; font-size: 12px; font-weight: bold;`);
        }
    }
    getApiErrorMessage() {
        switch (this.apiErrorMessage) {
            case 'ERROR_CONFIGURATION_NOT_FOUND':
                return DiagnosticLogs.colorizeText(`${this.apiErrorMessage} - Default profile not set or profile doesn't exist`, LogColors.YELLOW);
            default:
                return this.apiErrorMessage;
        }
    }
}
DiagnosticTool.defaultReportTimeout = 5 * 60 * 1000; // 5 minutes
