import {
  TAudioTrackType,
  TTextTrackType,
} from "@telia-company/tv.web-playback-sdk";
import {
  convertShakaError,
  isErrorFatalFn,
  overrideCodeFn,
  TShakaErrorEvent,
} from "@telia-company/tv.web-player-shaka-util";
import {
  detectGapStall,
  TAudioTrack,
  TCallback,
  TEventHandler,
  TLanguageCode,
  TLanguagesEvent,
  TTextTrack,
  TUnfortunatelyAny,
} from "@telia-company/tv.web-player-shared";

import {
  TShakaEventHandlerArray,
  TShakaEventHandlerOptions,
} from "../@types/types";

export type TGetCallback = (options: TShakaEventHandlerOptions) => TCallback;

export type THandlers = {
  onAdaptation: TCallback | undefined;
  onBuffering: TCallback | undefined;
  onError: TCallback | undefined;
  onManifestParsed: TCallback | undefined;
  onTextChanged: TCallback | undefined;
  onTextTrackVisibility: TCallback | undefined;
  onTracksChanged: TCallback | undefined;
  onVariantChanged: TCallback | undefined;
};

export type TInitialHandlers = {
  onAdaptation: undefined;
  onBuffering: undefined;
  onError: undefined;
  onManifestParsed: undefined;
  onTextChanged: undefined;
  onTextTrackVisibility: undefined;
  onTracksChanged: undefined;
  onVariantChanged: undefined;
};

const getAudioTrackType = (track: TUnfortunatelyAny): TAudioTrackType => {
  if (track.role?.indexOf("descr") > -1) return "commentary";

  if (track.role === "commentary") return "commentary";

  if (track.kind?.indexOf("descr") > -1) return "commentary";

  if (track.kind === "commentary") return "commentary";

  if (
    track.roles &&
    track.roles.length > 0 &&
    track.roles.includes("commentary")
  )
    return "commentary";

  if (
    track.roles &&
    track.roles.length > 0 &&
    track.roles.includes("description")
  )
    return "commentary";

  // e.g. "public.accessibility.describes-video"
  if (
    track.roles &&
    track.roles.length > 0 &&
    track.roles.find(
      (r: TUnfortunatelyAny) =>
        r.indexOf("accessibility") > -1 && r.indexOf("desc") > -1
    )
  )
    return "commentary";

  if (track.roles && track.roles.length > 0) return track.roles[0];

  // HLS assets with multiple audio tracks have dubbed tracks marked as "alternative"
  if (track.role) return track.role;

  return "main";
};

export type TShakaTrack = {
  active: boolean;
  audioBandwidth: null | number;
  audioCodec: null | string;
  audioId: null | number;
  audioRoles: null | string[];
  audioSamplingRate: null | number;
  bandwidth: number;
  channelsCount: null | number;
  codecs: null | string;
  forced: boolean;
  frameRate: null | number;
  hdr: null | string;
  height: null | number;
  id: number;
  kind: null | string;
  label: null | string;
  language: keyof TLanguageCode;
  mimeType: null | string;
  originalAudioId: null | string;
  originalImageId: null | string;
  originalTextId: null | string;
  originalVideoId: null | string;
  pixelAspectRatio: null | string;
  primary: boolean;
  roles: string[];
  spatialAudio: boolean;
  tilesLayout: null | string;
  type: string;
  videoBandwidth: null | number;
  videoCodec: null | string;
  videoId: null | number;
  width: null | number;
};

export const getSubtitleTrackType = (t: TShakaTrack): TTextTrackType => {
  // e.g. public.accessibility.describes-spoken-dialog,public.accessibility.describes-music-and-sound
  if (
    t.roles &&
    t.roles.length > 0 &&
    t.roles.find(
      (r) => r.indexOf("accessibility") > -1 && r.indexOf("desc") > -1
    )
  )
    return "caption";

  if (!t.kind) return "subtitle";

  // can show up as "caption" or "captions"
  if (t.kind?.indexOf("caption") > -1) return "caption";

  // can show up as "subtitle" or "subtitles"
  if (t.kind?.indexOf("subtitle") > -1) return "subtitle";

  return "subtitle";
};

export const getShakaEventHandler = (
  options: TShakaEventHandlerOptions
): TEventHandler => {
  const { engineInitTimestamp } = options;

  const initialHandlers: TInitialHandlers = {
    onAdaptation: undefined, // bitrate changed
    onBuffering: undefined,
    onError: undefined,
    onManifestParsed: undefined,
    onTextChanged: undefined, // subtitle changed
    onTextTrackVisibility: undefined, // "texttrackvisibility" - subtitles on/off?
    onTracksChanged: undefined, // audio and subtitle tracks should be available
    onVariantChanged: undefined, // audio changed
    // onTracksChanged: undefined // "trackschanged"
    // onId3: undefined // detect id3 on shaka frag parsing?
  };

  let handlers: THandlers = {
    ...initialHandlers,
  };

  let listenersAdded = false;

  const getOnAdaptation: TGetCallback = ({ callbacks, player }) => {
    if (handlers.onAdaptation) return handlers.onAdaptation;

    handlers.onAdaptation = () => {
      const activeTracks = player
        .getVariantTracks()
        .filter((track: Record<string, TUnfortunatelyAny>) => track.active);
      const bitrate = activeTracks.reduce(
        (btr: number, track: Record<string, TUnfortunatelyAny>) =>
          btr + track.bandwidth,
        0
      );

      callbacks.onBitrateChanged({
        bitrate,
      });
    };

    return handlers.onAdaptation;
  };

  const getOnVariantChanged: TGetCallback = ({ callbacks, player }) => {
    if (handlers.onVariantChanged) return handlers.onVariantChanged;

    handlers.onVariantChanged = () => {
      // Shaka Track: https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.Track
      const activeTrack: {
        language: "und" | keyof TLanguageCode;
        role: "commentary" | "main";
      } = player
        .getVariantTracks()
        .find(
          (t: TUnfortunatelyAny) =>
            t.type === "variant" && (t.audioId || t.originalAudioId) && t.active
        );

      if (activeTrack && activeTrack.language !== "und") {
        callbacks.onAudioTrackChanged({
          language: activeTrack.language,
          type: getAudioTrackType(activeTrack),
        });
      }
    };

    return handlers.onVariantChanged;
  };

  const getOnTextChanged: TGetCallback = ({ callbacks, player }) => {
    if (handlers.onTextChanged) return handlers.onTextChanged;

    handlers.onTextChanged = () => {
      // Shaka Track: https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.Track
      const activeTrack: TShakaTrack = player
        .getTextTracks()
        .find((t: TUnfortunatelyAny) => t.type === "text" && t.active);

      if (activeTrack) {
        callbacks.onTextTrackChanged({
          language: activeTrack.language,
          type: getSubtitleTrackType(activeTrack),
        });
      }
    };

    return handlers.onTextChanged;
  };

  const getOnTextTrackVisibility: TGetCallback = ({ callbacks, player }) => {
    if (handlers.onTextTrackVisibility) return handlers.onTextTrackVisibility;

    handlers.onTextTrackVisibility = () => {
      callbacks.onTextTrackVisibility({
        visible: player.isTextTrackVisible(),
      });
    };

    return handlers.onTextTrackVisibility;
  };

  const getOnTracksChanged: TGetCallback = ({ callbacks, player }) => {
    if (handlers.onTracksChanged) return handlers.onTracksChanged;
    let textTrackVisible: boolean | undefined;

    handlers.onTracksChanged = () => {
      callbacks.onLanguagesUpdated({
        audioTracks: player
          .getAudioLanguagesAndRoles()
          .map(
            (t: TUnfortunatelyAny): TAudioTrack => ({
              isoCode: t.language as keyof TLanguageCode,
              type: getAudioTrackType(t),
            })
          )
          .sort((a: TAudioTrack, b: TAudioTrack) =>
            a.isoCode.localeCompare(b.isoCode)
          ),
        textTracks: player
          .getTextTracks()
          .filter((track: TUnfortunatelyAny) => !!track.language)
          .map(
            (track: TUnfortunatelyAny): TTextTrack => ({
              isoCode: track.language,
              type: getSubtitleTrackType(track),
            })
          )
          .sort((a: TTextTrack, b: TTextTrack) =>
            a.isoCode.localeCompare(b.isoCode)
          ),
      } as TLanguagesEvent["payload"]);

      const activeAudioTrack = player
        .getVariantTracks()
        .find(
          (t: Record<string, TUnfortunatelyAny>) =>
            t.type === "variant" && (t.audioId || t.originalAudioId) && t.active
        );

      const { preferredTextLanguage } = player.getConfiguration();
      const activeTextTrack = player
        .getTextTracks()
        .find(
          (t: Record<string, TUnfortunatelyAny>) =>
            t.type === "text" && t.active
        );

      if (
        // Do not auto-select hard of hearing subtitles
        (activeTextTrack &&
          getSubtitleTrackType(activeTextTrack) === "caption" &&
          textTrackVisible === undefined) ||
        // If the configured text language does not match the selected language it means we're
        // playing a video where the users preferred subtitles do not exist. Turn them off.
        // Special handling for safari, we keep track if we show/hide text tracks based on initial tracks
        // in Safari these tracks show up one after another, which would trigger show/hide several times
        // we mitigate this with the "textTrackVisible" variable, and hide/show the tracks once
        (!activeTextTrack && textTrackVisible === undefined) ||
        (activeTextTrack &&
          preferredTextLanguage !== activeTextTrack.language &&
          textTrackVisible === undefined)
      ) {
        player.setTextTrackVisibility(false);
        textTrackVisible = false;
      } else if (!textTrackVisible || textTrackVisible === undefined) {
        player.setTextTrackVisibility(true);
        textTrackVisible = true;
      }

      const nullOrAudioTrack = (
        audioTrack: TUnfortunatelyAny
      ): null | TAudioTrack => {
        if (!audioTrack || audioTrack.language === "und") return null;

        return {
          isoCode: audioTrack.language,
          type: getAudioTrackType(audioTrack),
        };
      };

      callbacks.onInitialActiveTracks({
        initialActiveTracks: {
          audio: nullOrAudioTrack(activeAudioTrack),
          text: activeTextTrack
            ? {
                isoCode: activeTextTrack.language,
                type: getSubtitleTrackType(activeTextTrack),
              }
            : null,
        },
        textTrackVisible: player.isTextTrackVisible(),
      });
    };

    return handlers.onTracksChanged;
  };

  const getOnError: TGetCallback = ({ callbacks, player, videoElement }) => {
    if (handlers.onError) return handlers.onError;

    handlers.onError = ({ detail: error }: TShakaErrorEvent) => {
      const fault = convertShakaError({
        engineInitTimestamp,
        error,
        extraDebugData: {
          drmKeyStatus: player.getKeyStatuses(),
        },
        isErrorFatalFn,
        overrideCodeFn,
        stats: player.getStats(),
        videoElement,
      });

      if (fault.fatal) {
        callbacks.onError({
          error: fault,
        });
      } else {
        callbacks.onCustomTrackingEvent({
          name: `WARNING:${fault.code}`,
        });
      }
    };

    return handlers.onError;
  };

  const getOnBuffering: TGetCallback = (o) => {
    if (handlers.onBuffering) return handlers.onBuffering;

    // Don't act until playback has started
    let active = false;
    // Prevent "buffered" event from occurring without preceding "buffering" event
    let buffering = false;

    const isLoaded = () => {
      active = true;

      o.videoElement.removeEventListener("canplaythrough", isLoaded);
    };

    o.videoElement.addEventListener("canplaythrough", isLoaded);

    handlers.onBuffering = ({ buffering: isBuffering }: TUnfortunatelyAny) => {
      if (active) {
        if (!buffering && isBuffering) {
          o.callbacks.onBuffering({});

          buffering = true;
        }

        if (!isBuffering && buffering) {
          o.callbacks.onBuffered({});

          buffering = false;
        }

        if (isBuffering) {
          detectGapStall(o);
        }
      }
    };

    return handlers.onBuffering;
  };

  const getOnManifestParsed: TGetCallback = (o) => {
    if (handlers.onManifestParsed) return handlers.onManifestParsed;
    const { callbacks, player } = o;

    handlers.onManifestParsed = () => {
      const isLive = player.isLive();
      callbacks.onIsLive({
        isLive,
      });
    };

    return handlers.onManifestParsed;
  };

  const shakaListenerArray: TShakaEventHandlerArray = [
    ["error", getOnError],
    ["trackschanged", getOnTracksChanged],
    ["textchanged", getOnTextChanged],
    ["adaptation", getOnAdaptation],
    ["variantchanged", getOnVariantChanged],
    ["texttrackvisibility", getOnTextTrackVisibility],
    ["buffering", getOnBuffering],
    // using the 'loaded'-event because we don't know if a hls
    // manifest is live or not at the time of 'manifestparsed'
    ["loaded", getOnManifestParsed],
  ];

  // Seek range handling should probably be rewritten.
  let clearShakaSeekRangeUpdater: TCallback | undefined;

  const getSeekRange = ({ player /* isLive */ }: TShakaEventHandlerOptions) => {
    const shakaRange = player.seekRange();

    return {
      end: shakaRange.end,
      start: shakaRange.start,
    };
  };

  const startShakaSeekRangeUpdater = (o: TShakaEventHandlerOptions) => {
    if (clearShakaSeekRangeUpdater) return;

    const { callbacks, player } = o;

    callbacks.onSeekRangeUpdate({
      seekRange: getSeekRange(o),
      stats: {
        player: "Shaka",
        version: player.version,
        ...player.getStats(),
      },
    });

    const interval = setInterval(() => {
      const buffers = player.getBufferedInfo();
      const stats = player.getStats();

      callbacks.onSeekRangeUpdate({
        seekRange: getSeekRange(o),
        stats: {
          player: "Shaka",
          version: player.version,
          ...stats,
          buffers,
        },
      });
    }, 1000);

    clearShakaSeekRangeUpdater = () => clearInterval(interval);
  };

  const addShakaEventListeners = (): void => {
    if (listenersAdded) throw new Error("Shaka event listeners already bound");

    startShakaSeekRangeUpdater(options);

    shakaListenerArray.forEach(([event, handler]) =>
      options.player.addEventListener(event, handler(options))
    );

    listenersAdded = true;
  };

  const removeShakaEventListeners = (): void => {
    if (clearShakaSeekRangeUpdater) {
      clearShakaSeekRangeUpdater();
      clearShakaSeekRangeUpdater = undefined;
    }

    shakaListenerArray.forEach(([event, handler]) =>
      options.player.removeEventListener(event, handler(options))
    );

    handlers = {
      ...initialHandlers,
    };

    listenersAdded = false;
  };

  return {
    addEventListeners: addShakaEventListeners,
    removeEventListeners: removeShakaEventListeners,
  };
};
