import {
  PlaybackErrorEvent,
  StartingEvent,
  StoppedEvent,
  StoppedReasons,
  TimeUpdateEvent,
  TrackingInfoEvent,
  TVideoTracking,
} from "../../../base";

import { guid } from "../../../utils/Guid";
import { secondsToMs } from "../../../utils/Time";
import { BaseTracker } from "../BaseTracker";
import { TTrackerConfiguration } from "../Types";

export type TPlaybackTrackerConfiguration = TTrackerConfiguration & {
  endpoint: string;
};

export class PlaybackTracker extends BaseTracker {
  readonly endpoint: string;
  private active: boolean;
  private duration: number;
  private heartbeat: any;
  private live: boolean;
  private playbackSessionId?: string;
  private position: number;
  private sessionEndFired: boolean;
  private sessionStartFired: boolean;
  private startPosition: number;
  private trackingData?: TVideoTracking;
  private client: string;

  constructor({ endpoint, player, service }: TPlaybackTrackerConfiguration) {
    super("PlaybackTracker");
    this.endpoint = endpoint;

    this.active = false;
    this.duration = 0;
    this.heartbeat = undefined;
    this.live = false;
    this.position = 0;
    this.sessionEndFired = false;
    this.sessionStartFired = false;
    this.startPosition = 0;
    this.client = `${service.serviceId}-${player.playerName}`;
  }

  private getDuration(): number {
    if (this.live) {
      return 0;
    }

    return secondsToMs(this.duration);
  }

  private getPosition(): number {
    if (this.live) {
      return Date.now() - this.startPosition;
    }

    return secondsToMs(this.position);
  }

  private async sessionStart(): Promise<void> {
    this.startPosition = Date.now();

    if (!this.playbackSessionId) {
      this.playbackSessionId = guid();
    }

    this.postData("SESSION_START");

    if (
      this.trackingData?.progressInterval &&
      this.trackingData.progressInterval > 0
    ) {
      this.heartbeat = setInterval(
        () => this.progress(),
        this.trackingData.progressInterval
      );
    }
  }

  private sessionEnd(): void {
    this.postData("SESSION_END");

    clearInterval(this.heartbeat);

    this.sessionEndFired = true;
  }

  private progress(): void {
    this.postData("PROGRESS");
  }

  private progressEnd(): void {
    this.postData("PROGRESS");
  }

  private postData(type: string): void {
    if (!this.active || !this.endpoint || !this.trackingData) {
      return;
    }

    const data = {
      type,
      position: this.getPosition(),
      duration: this.getDuration(),
      // spread the sgw provided data
      ...this.trackingData,
    };

    // console.debug(`Playback [${type}]`, data);

    void this.requestFactory
      ?.fetch(this.endpoint, {
        method: "POST",
        headers: {
          client: this.client,
        },
        body: JSON.stringify(data),
        useAuthentication: true,
      })
      .catch(() => {});
  }

  // This tracker needs the SGW-provided tracking object to initialize.
  // The properties can be dynamically changed by SGW at any time.
  public initialize(initData: TVideoTracking): void {
    if (this.active) return;

    this.active = true;

    this.live = initData.live;
    this.trackingData = initData;
  }

  public starting({ payload: { playbackSessionId } }: StartingEvent): void {
    this.playbackSessionId = playbackSessionId;
  }

  public buffering(): void {
    this.progress();
  }

  public error({ payload }: PlaybackErrorEvent): void {
    //fixes error recursion on cc
    if (payload.error.fatal) {
      this.progress();
    }
  }

  public paused(): void {
    this.progress();
  }

  public playing(): void {
    if (!this.sessionStartFired) {
      void this.sessionStart();
      this.sessionStartFired = true;
    }
  }

  public stopped({ payload }: StoppedEvent): void {
    if (payload.reason === StoppedReasons.END_OF_STREAM) {
      this.position = this.duration;
    }

    this.progressEnd();
  }

  public timeUpdate({ payload }: TimeUpdateEvent): void {
    this.duration = payload.duration;
    this.position = payload.currentTime;
  }

  public trackingInfo({
    payload: { tracking: { PLAYBACKTRACKING } = {} },
  }: TrackingInfoEvent): void {
    if (!this.active && PLAYBACKTRACKING) {
      this.initialize(PLAYBACKTRACKING);
    }
  }

  async reset(): Promise<void> {
    if (this.sessionStartFired && !this.sessionEndFired) {
      this.sessionEnd();
    }

    this.active = false;
    this.duration = 0;
    this.heartbeat = undefined;
    this.live = false;
    this.playbackSessionId = undefined;
    this.position = 0;
    this.sessionEndFired = false;
    this.sessionStartFired = false;
    this.startPosition = 0;

    return Promise.resolve();
  }

  async destroy(): Promise<void> {
    if (this.active) {
      this.reset();
    }
  }
}
