import React, { useCallback } from "react";

import { noop } from "../utils";
import { useFullscreenElement } from "./context-provider";

export type TUseFullscreen = {
  toggleFullscreen: (
    event?: React.KeyboardEvent | React.MouseEvent<Element, MouseEvent>
  ) => void;
};

export const useFullscreen = (): TUseFullscreen => {
  const fullscreenElementRef = useFullscreenElement();
  const toggleFullscreen = useCallback(
    (event?: React.KeyboardEvent | React.MouseEvent<Element, MouseEvent>) => {
      if (!fullscreenElementRef || !fullscreenElementRef.current) return;
      // workaround for useHotkeys fullscreen bug
      // see: https://github.com/JohannesKlauss/react-hotkeys-hook/issues/1067
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-line
      if (event && event.type === "keydown" && event.key && event.key !== "f")
        return;
      if (
        !document.fullscreenElement &&
        fullscreenElementRef.current.requestFullscreen
      ) {
        const maybePromise = fullscreenElementRef.current.requestFullscreen();
        if (maybePromise) maybePromise.catch(noop);
        return;
      }

      if (document.exitFullscreen) {
        const maybeExitPromise = document.exitFullscreen();
        if (maybeExitPromise) maybeExitPromise.catch(noop);
      }
    },
    [fullscreenElementRef]
  );

  return {
    toggleFullscreen,
  };
};
