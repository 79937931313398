/*
 * This file could be its own package (@telia-company/tv.web-player-engine-logic), but is not currently
 * worth the extra overhead. It is not used separately from the UI.
 */

// import { getEngine as getHlsjsEngine } from "@telia-company/tv.web-player-hlsjs";
import { DrmType } from "@telia-company/tv.web-playback-sdk";
import { getEngine as getShakaEngine } from "@telia-company/tv.web-player-shaka";
import {
  EngineLogic,
  // isSafari,
  selectBestDrm,
  TCreateMainPlayer,
  // TEngineOptions,
} from "@telia-company/tv.web-player-shared";

// const shouldUseHlsJs = (options: TEngineOptions) => {
//   const {
//     selectedStream,
//     features: { disableHlsjs },
//   } = options;

//   if (disableHlsjs) return false;
//   const streamUrl = selectedStream.url as string;
//   const { packaging } = selectedStream;

//   // double check packaging to cover the case where user has changed userAgent
//   if (packaging === "DASH_MP4_CTR") return false;

//   const hasFairplayDrm = selectedStream.drm?.type === DrmType.FAIRPLAY;
//   // const isActuallySafari = hasFairplayDrm || isSafari;
//   // const hasAds = selectedStream.adType !== AdType.NONE;
//   const isHls = streamUrl.toLowerCase().indexOf(".m3u") > -1;

//   // play HLS_CMAF_CBCS with hls.js regardless of drm, only play HLS_TS_CBCS with hls.js if drm is NONE
//   return (
//     isHls &&
//     ((isSafari && packaging === "HLS_CMAF_CBCS") ||
//       (isSafari && !hasFairplayDrm))
//   ); // (hasAds && isHls);
// };

const createEngine: TCreateMainPlayer = (options) =>
  // if (shouldUseHlsJs(options)) {
  //   return getHlsjsEngine(options);
  // }
  getShakaEngine(options);
export const engineLogic: EngineLogic = {
  capabilities: ["YOSPACE_DASH", "CHANNEL_DVR", "YOSPACE_VOD"],
  createEngine,
  selectDrm: async () => {
    const res = await selectBestDrm();

    if (res.explicitError) {
      throw res.explicitError;
    }

    return res.type;
  },
  /*
   * The packagings-array is not a priority order, HLS_CMAF_CBCS will take precedence
   * if there is a HLS_CMAF_CBCS DAI configuration for that channel.
   * If we would put HLS_CMAF_CBCS first, we would get playlists we cannot play, as
   * they are not configured correctly.
   */
  supportedPackagings: {
    [DrmType.FAIRPLAY]: ["HLS_TS_CBCS", "HLS_CMAF_CBCS"],
    [DrmType.PLAYREADY]: ["DASH_MP4_CTR"],
    [DrmType.WIDEVINE]: ["DASH_MP4_CTR", "HLS_CMAF_CBCS", "HLS_TS_CBCS"],
  },
};
