import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import OneDesignTokens from "@telia-company/tv.one-design-system-web/legacy/tokens";
import React from "react";

export type Breakpoints = {
  L: number;
  M: number;
  S: number;
  XL: number;
};

export type DesignTokens = OneDesignTokens.TokenRoot;

export type ThemeProviderProps = {
  breakpoints: Breakpoints;
  children: React.ReactNode;
  designTokens: DesignTokens;
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  breakpoints,
  children,
  designTokens,
}) => (
  <EmotionThemeProvider theme={{ breakpoints, designTokens }}>
    {children}
  </EmotionThemeProvider>
);
