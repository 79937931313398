// Detect when a startover manifest changes type from dynamic to static
import { TEngineMethodOptions } from "../../@types/types";
// without doing this, the stream would be stopped when the ongoing recording is complete
import { getResponseFilters } from "../../network-filters/manifest-handler";

export const setupStaticDynamicManifestSwitchHandler = (
  options: TEngineMethodOptions
): void => {
  const responseFilters = getResponseFilters();
  options.player
    .getNetworkingEngine()
    .registerResponseFilter(responseFilters.manifestFilter);

  responseFilters.setManifestChangedHandler(
    options.callbacks.onManifestTypeChanged
  );
};
