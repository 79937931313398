export const cw = `
query cw ($limit: Int!) {
  continueWatchingList (limit: $limit) {
    items {
      media {
        ... on Episode {
          title
          playback {
            play {
              subscription {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              rental {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              linear {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
            }
          }
          images {
            showcard2x3 {
              source
            }
          }
        }
        
        ... on Movie {
          title
          playback {
            play {
              subscription {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              rental {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              linear {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
            }
          }
          images {
            showcard2x3 {
              source
            }
          }
        }
        
        ... on SportEvent {
          title
          playback {
            play {
              subscription {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              rental {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              linear {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
            }
          }
          images {
            showcard2x3 {
              source
            }
          }
        }
      }
    }
  }
}
`;

export const ml = `
query cw ($limit: Int!) {
  myList (limit: $limit) {
    items {
      media {        
        ... on Movie {
          title
          playback {
            play {
              npvr {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              subscription {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              rental {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              linear {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
            }
          }
          images {
            showcard2x3 {
              source
            }
          }
        }
        
        ... on SportEvent {
          title
          playback {
            play {
              npvr {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              subscription {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              rental {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
              linear {
                item {
                  playbackSpec {
                    accessControl
                    watchMode
                    videoId
                    videoIdType
                  }
                }
              }
            }
          }
          images {
            showcard2x3 {
              source
            }
          }
        }
      }
    }
  }
}
`;

// fetch panelX-data, for cw use:
// {
//   "panelXId": "known-panel|container-continue-watching"
// }
// you then get id:s, use another query to fetch data for these ids, to get a playbackSpec etc

export const cwmk2 = `
query PanelX($panelXId: String!) {
  panelX(id: $panelXId) {
    ... on PosterListPanel {
      posters {
        items {
          image {
            sourceNonEncoded
          }
          link {
            link3 {
              ... on LinkEpisode {
                to
              }
              ... on LinkMovie {
                to
              }
              ... on LinkSportEvent {
                to
              }
            }
          }
        }
      }
    }
  }
}`;

export const media = `
query Media($mediaId: String!) {
  media(id: $mediaId) {
    ... on Movie {
      title
      images {
        showcard2x3 {
          sourceNonEncoded
        }
      }
      playback {
        play {
          est {
            item {
              playbackSpec {
                videoId
                videoIdType
                watchMode
                accessControl
              }
            }
          }
          subscription {
            item {
              playbackSpec {
                videoId
                videoIdType
                watchMode
                accessControl
              }
            }
          }
        }
      }
    }
    ... on Episode {
      title
      series {
        title
      }
      playback {
        play {
          subscription {
            item {
              playbackSpec {
                videoId
                videoIdType
                watchMode
                accessControl
              }
            }
          }
          est {
            item {
              playbackSpec {
                videoId
                videoIdType
                watchMode
                accessControl
              }
            }
          }
        }
      }
      images {
        showcard2x3 {
          sourceNonEncoded
        }
      }
    }
    ... on SportEvent {
      title
      images {
        showcard2x3 {
          sourceNonEncoded
        }
      }
      playback {
        play {
          subscription {
            item {
              playbackSpec {
                videoId
                videoIdType
                watchMode
                accessControl
              }
            }
          }
        }
      }
    }
  }
}
`;
