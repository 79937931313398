import { isSafari } from "@telia-company/tv.web-player-shared";
import shaka from "shaka-player";

import { TEngineMethodOptions } from "../@types/types";

export const getTeardownFn = (options: TEngineMethodOptions) => (): void => {
  const {
    features: { disableHlsjs },
  } = options;
  options.shakaEventHandler.removeEventListeners();
  options.videoEventHandler.removeEventListeners();
  options.nativeCueMetadataHandler.removeEventListeners();
  options.hlsStallEventHandler.removeEventListeners();

  options.player.destroy();

  // uninstall polyfill or other player engines may not work
  if (isSafari && !disableHlsjs) {
    shaka.polyfill.PatchedMediaKeysApple.uninstall();
  }

  // Clean up video from memory
  options.domElements.videoElement.pause();
  options.domElements.videoElement.src = "";
  options.domElements.videoElement.load();
};
