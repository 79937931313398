export class UserInfo {
  public userId: string;
  public userIsLoggedIn: boolean;
  public userType?: string;

  constructor(args: UserInfo) {
    this.userId = args.userId;
    this.userType = args.userType;
    this.userIsLoggedIn = args.userIsLoggedIn;
  }
}
