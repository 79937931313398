import { TCallback } from "@telia-company/tv.web-player-shared";
import { Dispatch, SetStateAction, useEffect } from "react";

import {
  SimpleRelatedContentHeader,
  TMiniEpgHeaderProps,
} from "../components/overlays/list-overlay/generic-panel/panel";
import { TPanelOverlayHeader } from "../components/overlays/list-overlay/generic-panel/panel-overlay";
import {
  SeriesRelatedContentHeader,
  TSeriesHeaderProps,
} from "../components/overlays/list-overlay/panel-header/series-header";
import { TRelatedContentImplementation } from "../components/overlays/list-overlay/types/types";

export type TRelatedContentHeader = null | TPanelOverlayHeader<
  TMiniEpgHeaderProps | TSeriesHeaderProps
>;

export type TRelatedContentHeaderOptions = {
  closeRelatedContent: string;
  onCloseRelatedContent: TCallback;
  relatedContent: null | TRelatedContentImplementation;
  selectedSeason: null | number;
  setRelatedContentHeader: Dispatch<
    SetStateAction<null | TRelatedContentHeader>
  >;
  setSelectedSeason: Dispatch<SetStateAction<null | number>>;
};

export const useRelatedContentHeader = ({
  closeRelatedContent,
  onCloseRelatedContent,
  relatedContent,
  selectedSeason,
  setRelatedContentHeader,
  setSelectedSeason,
}: TRelatedContentHeaderOptions) => {
  useEffect(() => {
    switch (relatedContent?.type) {
      case "epg":
        setRelatedContentHeader({
          Component: SimpleRelatedContentHeader,
          props: {
            buttonIcon: relatedContent.closeButtonIcon,
            buttonText: relatedContent.closeButtonText,
            headerText: relatedContent.headerText,
            onClose: onCloseRelatedContent,
          },
        });
        break;
      case "series":
        setRelatedContentHeader({
          // the generic typing breaks and TS creates an unwanted intersection making this invalid
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Component: SeriesRelatedContentHeader,
          props: {
            buttonIcon: relatedContent.closeButtonIcon,
            buttonText: relatedContent.closeButtonText,
            headerText: relatedContent.headerText,
            onClose: onCloseRelatedContent,
            seasons: relatedContent.seasons,
            selectedSeason,
            setSelectedSeason,
          },
        });
        break;
      case "live-event":
        setRelatedContentHeader({
          Component: SimpleRelatedContentHeader,
          props: {
            buttonIcon: relatedContent.closeButtonIcon,
            buttonText: relatedContent.closeButtonText,
            headerText: relatedContent.headerText,
            onClose: onCloseRelatedContent,
          },
        });
        break;
      default:
        setRelatedContentHeader(null);
    }
  }, [
    relatedContent,
    onCloseRelatedContent,
    closeRelatedContent,
    setRelatedContentHeader,
    selectedSeason,
    setSelectedSeason,
  ]);
};
