import styled from "@emotion/styled";
import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";
import React, { FC, useEffect, useRef, useState } from "react";

import { getPreparedVideoElement } from "../misc/util";
import {
  playbackAction,
  togglePlayerAction,
  useRefAppDispatch,
} from "../reducer/ref.reducer";

const StyledZapDisplay = styled.div`
  position: fixed;
  bottom: 4rem;
  left: 4rem;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 0.5rem;
  padding: 5rem;
  font-size: 3rem;
`;

const ChannelZapDisplay: FC<{ text: string }> = ({ text }) => {
  if (!text) return null;
  return <StyledZapDisplay>Zap to Channel Id: {text}</StyledZapDisplay>;
};

export const useChannelZap = (): React.JSX.Element => {
  const zapTimeout = useRef<null | number>(null);
  const [quickChannelZap, setQuickChannelZap] = useState("");
  const dispatch = useRefAppDispatch();

  useEffect(() => {
    if (!quickChannelZap) return;
    if (zapTimeout.current) clearTimeout(zapTimeout.current);

    zapTimeout.current = setTimeout(() => {
      dispatch(
        playbackAction({
          overrideVideoElement: getPreparedVideoElement(),
          playbackSpec: {
            accessControl: "SUBSCRIPTION",
            videoId: quickChannelZap,
            videoIdType: "CHANNEL",
            watchMode: "LIVE",
          },
        })
      );

      dispatch(togglePlayerAction(true));

      setQuickChannelZap("");
    }, 1300) as unknown as number;
  }, [quickChannelZap, dispatch]);

  useEffect(() => {
    const isADigit = (key: string) => {
      const maybeDigit = parseInt(key, 10);
      return !Number.isNaN(maybeDigit);
    };
    const handleKeyPress = (event: TUnfortunatelyAny) => {
      if (event.target.type === "text" || event.target.type === "password")
        return;
      if (isADigit(event.key)) {
        setQuickChannelZap((q) => q + event.key);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return <ChannelZapDisplay text={quickChannelZap} />;
};
