export const UnknownError = "UNKNOWN_ERROR";

export enum LoginGatewayErrorCodes {
  TryAgain = "TRY_AGAIN",
  UsernameOrPasswordIncorrect = "USERNAME_OR_PASSWORD_INCORRECT",
  AccountBlocked = "ACCOUNT_BLOCKED",
  AccountClosed = "ACCOUNT_CLOSED",
  IllegalRefreshToken = "ILLEGAL_REFRESH_TOKEN",
  EmptyRefreshToken = "EMPTY_REFRESH_TOKEN",
  TokenNotFound = "TOKEN_NOT_FOUND",
  UserNotFound = "USER_NOT_FOUND",
}

export const LoginGatewayErrorMap: Record<number, LoginGatewayErrorCodes> = {
  9020: LoginGatewayErrorCodes.TryAgain,
  50002: LoginGatewayErrorCodes.UsernameOrPasswordIncorrect,
  50003: LoginGatewayErrorCodes.AccountBlocked,
  50004: LoginGatewayErrorCodes.AccountClosed,
  50005: LoginGatewayErrorCodes.IllegalRefreshToken,
  50007: LoginGatewayErrorCodes.EmptyRefreshToken,
  50008: LoginGatewayErrorCodes.TokenNotFound,
  50011: LoginGatewayErrorCodes.UserNotFound,
};

export enum StreamingGatewayErrorCodes {
  DrmDevice = "DRM_DEVICE",
  DrmEntitlement = "DRM_ENTITLEMENT",
  ContentNotFound = "CONTENT_NOT_FOUND",
  ContentNotAuthorized = "CONTENT_NOT_AUTHORIZED",
  ContentNotAvailableInGeographicArea = "CONTENT_NOT_AVAILABLE_OUTSIDE_GEOGRAPHIC_AREA",
  ResolutionNotSupported = "RESOLUTION_NOT_SUPPORTED",
  BadInputData = "BAD_INPUT_DATA",
  Unauthorized = "UNAUTHORIZED",
  IncorrectParam = "INCORRECT_PARAM",
}

export const StreamingGatewayErrorMap: Record<
  number,
  StreamingGatewayErrorCodes
> = {
  40020: StreamingGatewayErrorCodes.DrmDevice,
  40021: StreamingGatewayErrorCodes.DrmEntitlement,
  40030: StreamingGatewayErrorCodes.ContentNotFound,
  40031: StreamingGatewayErrorCodes.ContentNotAuthorized,
  40032: StreamingGatewayErrorCodes.ContentNotAvailableInGeographicArea,
  40041: StreamingGatewayErrorCodes.ResolutionNotSupported,
  9070: StreamingGatewayErrorCodes.BadInputData,
  9060: StreamingGatewayErrorCodes.IncorrectParam,
  9030: StreamingGatewayErrorCodes.Unauthorized,
};

export enum GraphErrorCodes {
  FormatError = "FORMAT_ERROR",
}

export const GraphErrorMap: Record<any, any> = {};

export enum TvClientGatewayErrorCodes {
  DrmRegistrationFailure = "DRM_REGISTRATION_FAILURE",
  ServiceRegistrationFailure = "SERVICE_REGISTRATION_FAILURE",
  TooManyDevices = "DRM_REGISTRATION_FAILURE_TOO_MANY_DEVICES",
}

export const TvClientGatewayErrorMap: Record<
  number,
  TvClientGatewayErrorCodes
> = {
  61001: TvClientGatewayErrorCodes.DrmRegistrationFailure,
  61002: TvClientGatewayErrorCodes.ServiceRegistrationFailure,
  61004: TvClientGatewayErrorCodes.TooManyDevices,
};

export enum MessagingErrorCodes {}

export const MessagingErrorMap: Record<number, MessagingErrorCodes> = {};
