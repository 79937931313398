import { TLanguageCode } from "@telia-company/tv.web-playback-sdk";
import {
  ClientLoadConfiguration,
  Content,
  Language,
  Load,
  LoadConfiguration,
  Metadata,
  PreferredLanguage,
  SessionStates,
  TokenCredentials,
  UselessSenderEvents,
} from "@useless-media-public/useless-sender-web";
import { useCallback } from "react";

import { useChromecastContext } from "~/providers/UselessProvider";

export type PlaybackSpec = {
  accessControl: string;
  videoId: string;
  videoIdType: string;
  watchMode: string;
};

export type Series = {
  episode: number;
  season: number;
  title: string;
};

export type SelectedLanguages = {
  assetLanguage?: "undefined" | keyof TLanguageCode;
  audio?: keyof TLanguageCode;
  text?: keyof TLanguageCode;
};

export type CastPlaybackSpecFnParams = {
  autoplay?: boolean;
  /**
   * If playbackSpec.watchMode is LIVE currentTime will always be sent as undefined to the receiver
   */
  currentTime?: number;
  playbackSpec: PlaybackSpec;
  selected?: SelectedLanguages;
  series?: Series;
  title?: string;
};

export const useCastPlaybackSpec = () => {
  const {
    credentials,
    preferredLanguage,
    sender,
    senderState,
    serviceCountry,
    sessionStarted,
  } = useChromecastContext();

  const castPlaybackSpec = useCallback(
    async ({
      autoplay = true,
      currentTime = 0,
      playbackSpec,
      selected,
      series,
      title = "",
    }: CastPlaybackSpecFnParams) => {
      if (!sender || !credentials) return;

      const onSessionStarted = () => {
        cast();
        sender.off(UselessSenderEvents.SessionStarted, onSessionStarted);
      };

      const cast = () => {
        const content = Content({
          auxiliaryData: {
            assetLanguage: selected?.assetLanguage,
            playbackSpec,
            regionalChannels: {},
          },
          id: playbackSpec.videoId,
          metadata: Metadata({ images: [], series, title }),
          type: "Content",
        });

        const language = Language({
          preferred: PreferredLanguage(preferredLanguage),
          // selected: SelectedLanguage(selected),
        });

        const configuration = LoadConfiguration({
          client: ClientLoadConfiguration({
            excludeAccessibilityTracks: false,
          }),
          serviceLayer: {
            service: {
              serviceCountry: serviceCountry,
            },
          },
        });

        const load = Load({
          autoplay,
          configuration,
          content,
          credentials: TokenCredentials({
            token: credentials.token,
          }),
          // Trust that the client knows where to start
          currentTime,
          language,
        });

        sender.cast(load);
      };

      if (sessionStarted) {
        cast();
      } else if (senderState === SessionStates.Ended && !sessionStarted) {
        sender.on(UselessSenderEvents.SessionStarted, onSessionStarted);
        await sender.connect();
      } else {
        cast();
      }
    },
    [
      credentials,
      preferredLanguage,
      sender,
      senderState,
      serviceCountry,
      sessionStarted,
    ]
  );

  return castPlaybackSpec;
};
