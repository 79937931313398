//
//  TimelineManager.ts
//
//  Created by Lars Rothaus on 17/06/2021.
//  Copyright © 17/06/2021 Lars Rothaus. All rights reserved.
//

import { Timeline, TimelineItem } from "./types";

export class TimelineManager {
  private mainTimeline?: Timeline;

  constructor() {}

  public attachMainTimeline(timeLine: Timeline) {
    this.mainTimeline = timeLine;
  }

  public addTimelineItem(timelineItem: TimelineItem) {
    if (!this.mainTimeline) {
      throw new Error(
        `Error class:TimelineManager[addTimelineItem] : No MainTimeline available please make sure use "attachMainTimeline()" !`
      );
    }

    switch (timelineItem.type) {
      case "Content": {
        break;
      }
      case "AdvertisementBreak": {
        break;
      }
      case "OpeningCredits": {
        break;
      }
      case "ClosingCredits": {
        break;
      }
    }
    this.mainTimeline.items.push(timelineItem);
  }

  private recalculateTimeline() {}
}
