import {
  TImageProxyProps,
  TUnfortunatelyAny,
} from "@telia-company/tv.web-player-shared";

export const getPreparedVideoElement = (): HTMLVideoElement => {
  const v = document.createElement("video");

  v.load();

  return v;
};

const parseSubtitle = (program: TUnfortunatelyAny) => {
  const start = new Date(program.startTime?.timestamp);
  const end = new Date(program.endTime?.timestamp);
  const startPaddedHr = `00${start.getHours()}`.slice(-2);
  const startPaddedMinutes = `00${start.getMinutes()}`.slice(-2);
  const endPaddedHr = `00${end.getHours()}`.slice(-2);
  const endPaddedMinutes = `00${end.getMinutes()}`.slice(-2);

  if (!Number.isNaN(start) && !Number.isNaN(end)) {
    return `${startPaddedHr}:${startPaddedMinutes} - ${endPaddedHr}:${endPaddedMinutes}`;
  }
  return "";
};

export const getOngoingProgram = (programs: TUnfortunatelyAny) => {
  const now = Date.now();
  const ongoing = programs.find(
    (p: TUnfortunatelyAny) =>
      p.startTime.timestamp < now && p.endTime.timestamp > now
  );
  if (!ongoing)
    return {
      endTime: programs?.[0]?.startTime.timestamp || now + 10 * 60,
      startTime: now,
      subtitle: "",
      title: "No broadcast",
    };
  return {
    endTime: ongoing.endTime?.timestamp,
    startTime: ongoing.startTime?.timestamp,
    subtitle: parseSubtitle(ongoing),
    title: ongoing.media?.title,
  };
};

export const imageProxy = ({ height, uri, width }: TImageProxyProps) => {
  const baseUrl = "https://imageproxy.t6a.net";
  return `${baseUrl}/?resize=${width}x${height}&source=${uri}`;
};
