import {
  AdvertisementBreakStartedEvent,
  BitrateChangedEvent,
  CdnChangedEvent,
  DroppedFramesEvent,
  PlaybackErrorEvent,
  StartingEvent,
  StoppedEvent,
  StreamInfoEvent,
  TimeUpdateEvent,
  TrackingInfoEvent,
  CustomTrackingEvent,
  INormalizedError,
  ErrorCategories,
  AdvertisementStartedEvent,
  AdvertisementTimeUpdateEvent,
} from "../../../base";

import { BaseTracker } from "../BaseTracker";
import { AdsAdapter } from "./AdsAdapter";
import { PlayerAdapter } from "./PlayerAdapter";
import {
  TYouboraError,
  TYouboraOptions,
  TYouboraTrackerConfiguration,
  YouboraErrorLevels,
} from "./Types";
import {
  buildYouboraOptionsFromTrackerConfiguration,
  buildYouboraOptionsFromTrackingInfo,
} from "./YouboraOptionsBuilder";
import deepmerge from "deepmerge";
import NpawPlugin from "npaw-plugin";

export type TYouboraTrackerData = {
  contentId: string;
  live: boolean;
  title?: string;
};

const getEngineFormatting = (string: string) => ` (${string})`;

const canJSONStringify = (data: any): any => {
  if (typeof data !== "object") return data;
  try {
    JSON.stringify(data);
    return data;
  } catch (err) {
    const errorData = {
      message:
        "YouboraTracker::PlayerAdapter: unable to stringify error.details",
      error: err,
    };
    console.error(errorData);
    return errorData;
  }
};

export const convertTrackerErrorToYouboraError = (
  error: INormalizedError
): TYouboraError => ({
  errorCode: error.code, // = name in youbora
  msg: error.category, // = description in youbora
  errorMetadata: canJSONStringify(error.details),
  errorLevel: error.fatal ? YouboraErrorLevels.Fatal : YouboraErrorLevels.Error,
});

// TODO we should potentially be reporting all errors
export const ignore = (error: INormalizedError): boolean =>
  error.category === ErrorCategories.USER || !error.fatal;
//|| IgnoredErrorsList.includes(error.code); // ToDo: Get as parameter in TYouboraTrackerConfiguration ?!

export class YouboraTracker extends BaseTracker {
  readonly playerAdapter: PlayerAdapter;
  readonly adsAdapter: AdsAdapter;
  private activeAdapter: PlayerAdapter | AdsAdapter;
  private deepMergedYouboraOptions: TYouboraOptions | undefined;
  private active: boolean = false;
  private npawPlugin: NpawPlugin;

  private playerIdPrefix: string;
  private playerIdSuffix: string;

  constructor(configuration: TYouboraTrackerConfiguration) {
    super("YouboraTracker");

    this.npawPlugin = new NpawPlugin(configuration.accountId);

    this.npawPlugin.setAnalyticsOptions(
      buildYouboraOptionsFromTrackerConfiguration(configuration)
    );

    // Enables/disables CDN Balancer diagnostic
    this.npawPlugin.diagnostic.setBalancerEnabled(false);
    // Enables/disables Video Analytics diagnostic
    this.npawPlugin.diagnostic.setVideoAnalyticsEnabled(false);
    // Enables/disables Ads Analytics diagnostic
    this.npawPlugin.diagnostic.setAdsAnalyticsEnabled(false);

    // debug logging
    // SILENT: 6,
    // ERROR: 5,
    // WARNING: 4,
    // NOTICE: 3,
    // DEBUG: 2,
    // VERBOSE: 1
    // this.npawPlugin.setLogLevel(6)

    this.npawPlugin.registerDefaultAdapter();
    this.npawPlugin.registerDefaultAdsAdapter();

    this.playerAdapter = new PlayerAdapter(
      configuration,
      this.npawPlugin.getAdapter()
    );

    this.adsAdapter = new AdsAdapter(
      configuration,
      // @ts-ignore TODO why can the getAdsAdapter return undefined?
      this.npawPlugin.getAdsAdapter()
    );

    this.activeAdapter = this.playerAdapter;

    this.playerIdPrefix = `${configuration.service.serviceName} ${configuration.application.applicationName}`;
    this.playerIdSuffix = configuration.player.playerEngine
      ? getEngineFormatting(configuration.player.playerEngine)
      : "";

    this.setPlayerId();
  }

  public setOptions(options: TYouboraOptions): void {
    this.npawPlugin.setAnalyticsOptions(options);
  }

  public setPlayerEngine(engine: string) {
    this.playerIdSuffix = getEngineFormatting(engine);

    this.setPlayerId();
  }

  public setPlayerIdPrefix(string: string) {
    this.playerIdPrefix = string;

    this.setPlayerId();
  }

  private setPlayerId() {
    this.npawPlugin.setAnalyticsOptions({
      "content.customDimension.1": this.playerIdPrefix + this.playerIdSuffix,
    });
  }

  // ITracker
  public initialize(data: TYouboraTrackerData) {
    if (this.active) return;

    this.active = true;

    this.npawPlugin.setAnalyticsOptions({
      "content.id": data.contentId,
      "content.title": data.title || data.contentId,
      "content.isLive": data.live,
    });

    this.playerAdapter.initialize(data);
  }

  public advertisementBreakEnded(): void {
    this.adsAdapter.advertisementBreakEnded();
    this.activeAdapter = this.playerAdapter;
  }

  public advertisementBreakStarted(
    event: AdvertisementBreakStartedEvent
  ): void {
    if (!event.payload || !event.payload.breakType) return;
    this.adsAdapter.advertisementBreakStarted(event);
    this.activeAdapter = this.adsAdapter;
  }

  public advertisementStarted(data: AdvertisementStartedEvent): void {
    this.adsAdapter.advertisementStarted(data);
  }

  public advertisementEnded(): void {
    this.adsAdapter.advertisementEnded();
  }

  public advertisementTimeUpdate(data: AdvertisementTimeUpdateEvent): void {
    this.adsAdapter.advertisementTimeUpdate(data);
  }

  public bitrateChanged(event: BitrateChangedEvent): void {
    if (!event.payload || !event.payload.bitrate) return;

    this.playerAdapter.bitrateChanged(event);
  }

  public buffering(): void {
    this.activeAdapter.buffering();
  }

  public buffered(): void {
    this.activeAdapter.buffered();
  }

  public cdnChanged({ payload }: CdnChangedEvent): void {
    if (!payload || !payload.cdn) return;
    this.npawPlugin.setAnalyticsOptions({
      "content.cdn": payload.cdn,
    });
  }

  public droppedFrames(event: DroppedFramesEvent) {
    this.playerAdapter.droppedFrames(event);
  }

  public error(event: PlaybackErrorEvent) {
    this.activeAdapter.error(event);
  }

  public starting({ payload: { playbackSessionId } }: StartingEvent): void {
    this.npawPlugin.setAnalyticsOptions({
      "content.transactionCode": playbackSessionId,
    });

    this.playerAdapter.starting();
  }

  public paused(): void {
    this.activeAdapter.paused();
  }

  public playing(): void {
    this.activeAdapter.playing();
  }

  public seeking(): void {
    this.playerAdapter.seeking();
  }

  public seeked(): void {
    this.playerAdapter.seeked();
  }

  public stopped(event: StoppedEvent): void {
    this.activeAdapter.stopped(event);
  }

  public streamInfo(event: StreamInfoEvent): void {
    this.playerAdapter.streamInfo(event);
  }

  public timeUpdate(event: TimeUpdateEvent): void {
    if (!event.payload || !event.payload.currentTime) return;

    this.playerAdapter.timeUpdate(event);
  }

  public custom(event: CustomTrackingEvent) {
    this.playerAdapter.custom(event);
  }

  public trackingInfo(trackingInfo: TrackingInfoEvent): void {
    this.deepMergedYouboraOptions = deepmerge<TYouboraOptions>(
      this.deepMergedYouboraOptions || {},
      buildYouboraOptionsFromTrackingInfo(trackingInfo)
    );

    this.npawPlugin.setAnalyticsOptions(this.deepMergedYouboraOptions);
    this.playerAdapter.trackingInfo(trackingInfo);

    if (trackingInfo.payload.player?.playerEngine) {
      this.setPlayerEngine(trackingInfo.payload.player.playerEngine);
    }

    if (
      !this.active &&
      typeof trackingInfo.payload.tracking?.YOUBORA?.videoId === "string" &&
      typeof trackingInfo.payload.tracking.YOUBORA?.live === "boolean"
    ) {
      this.initialize({
        contentId: trackingInfo.payload.tracking.YOUBORA.videoId,
        live: trackingInfo.payload.tracking.YOUBORA.live,
        title: trackingInfo.payload.tracking.YOUBORA.title,
      });
    }
  }

  async reset(): Promise<void> {
    this.adsAdapter.reset();
    this.playerAdapter.reset();

    this.active = false;
    this.deepMergedYouboraOptions = undefined;

    return Promise.resolve();
  }

  async destroy(): Promise<void> {
    // This fixes a memory leak with Youbora's background detector
    this.npawPlugin.setAnalyticsOptions({
      "background.enabled": false,
    });

    if (this.active) {
      this.reset();
    }

    this.npawPlugin.removeAdsAdapter();
    this.npawPlugin.removeAdapter();

    this.npawPlugin.destroy();
    return Promise.resolve();
  }
}
