/**
 * List of Request Methods
 *   - GET
 *   - POST
 */
const RequestMethod = {
  GET: 'GET',
  POST: 'POST'
};

module.exports = RequestMethod;
