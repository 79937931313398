export const PREFIX = "webplayer.";

export const canJSONStringify = (data: unknown): boolean => {
  try {
    JSON.stringify(data);
    return true;
  } catch (_err) {
    return false;
  }
};
