import { playerId } from "@telia-company/tv.web-player-shaka-util";
import {
  getDuration,
  getHlsStallEventHandler,
  getNativeCueMetadataHandler,
  getPosition,
  getVideoEventHandler,
  getWebVttThumbnails,
  isSafari,
  MainPlayer,
  setupDomElements,
  TAudioTrack,
  TCreateMainPlayer,
  TTextTrack,
  TThumbnail,
  TThumbnailOptions,
  WatchMode,
} from "@telia-company/tv.web-player-shared";
import shaka from "shaka-player";

import { TEngineMethodOptions } from "./@types/types";
import { getShakaInstance } from "./engine-methods/init";
import { getLoadFn } from "./engine-methods/load/load";
import { shouldUseHlsThumbs } from "./engine-methods/load/workarounds";
import { getPlayFn } from "./engine-methods/play";
import { getTeardownFn } from "./engine-methods/teardown";
import {
  getShakaEventHandler,
  getSubtitleTrackType,
  TShakaTrack,
} from "./event-handling/shaka-event-handler";

export * from "./engine-methods/load/capabilities";

let installed = false;

export const getEngine: TCreateMainPlayer = async (
  options
): Promise<MainPlayer> => {
  const engineInitTimestamp = Date.now();

  if (!installed) {
    shaka.polyfill.installAll();
    installed = true;
  }
  // this polyfill is uninstalled every teardown
  if (isSafari) {
    shaka.polyfill.PatchedMediaKeysApple.install(true);
  }

  const { callbacks, event, playback, selectedStream } = options;
  const {
    autoplay,
    playbackSpec: { watchMode },
  } = playback;

  const domElements = setupDomElements({
    autoplay,
    v: options.playback.overrideVideoElement,
  });

  const videoElementReadyListener = () => {
    callbacks.onVideoElementReady({
      textElement: domElements.textElement,
      videoElement: domElements.videoElement,
    });

    domElements.videoElement.removeEventListener(
      "loadstart",
      videoElementReadyListener
    );
  };

  domElements.videoElement.addEventListener(
    "loadstart",
    videoElementReadyListener
  );

  const player = await getShakaInstance({
    callbacks,
    textElement: domElements.textElement,
    videoElement: domElements.videoElement,
  });

  const videoEventHandler = getVideoEventHandler({
    callbacks,
    videoElement: domElements.videoElement,
  });

  const shakaEventHandler = getShakaEventHandler({
    callbacks,
    engineInitTimestamp,
    isLive: watchMode === WatchMode.LIVE,
    player,
    videoElement: domElements.videoElement,
  });

  const nativeCueMetadataHandler = getNativeCueMetadataHandler({
    callbacks,
    videoElement: domElements.videoElement,
  });

  const hlsStallEventHandler = getHlsStallEventHandler({
    callbacks,
    event,
    player,
    videoElement: domElements.videoElement,
  });

  // if we have a track for external thumbnails, fetch them immediately
  let rawThumbnailData: Promise<null | string> | undefined;
  if (selectedStream.webvttThumbnailsUrl) {
    rawThumbnailData = fetch(selectedStream.webvttThumbnailsUrl)
      .then((res) => res.text())
      .catch(() => null);
  }

  const engineMethodOptions: TEngineMethodOptions = {
    ...options,
    domElements,
    engineInitTimestamp,
    hlsStallEventHandler,
    nativeCueMetadataHandler,
    player,
    shakaEventHandler,
    videoEventHandler,
  };

  return {
    getAudioTracks: (): Array<TAudioTrack> => player.getAudioLanguages(),
    getDuration: () => getDuration(domElements.videoElement),
    getPosition: () => getPosition(domElements.videoElement),
    getSpeed: () => {
      if (domElements.videoElement.paused) return 0;
      return player.getPlaybackRate();
    },
    getSubtitleTracks: (): Array<TTextTrack> => player.getTextLanguages(),
    /**
     * This can be done more robust, see: https://github.com/shaka-project/shaka-player/issues/3371#issuecomment-830001465 for reference
     */
    getThumbnail: async ({
      percent,
    }: TThumbnailOptions): Promise<null | TThumbnail> => {
      // check if we're playing HLS, get alternative subtitles through webvttthumbnails
      if (shouldUseHlsThumbs(options)) {
        const resolvedThumbData = await rawThumbnailData;
        return getWebVttThumbnails({
          percent,
          thumbData: resolvedThumbData,
          videoElement: domElements.videoElement,
        });
      }
      const position = Math.round(player.seekRange().end * percent);
      const imageTracks = player.getImageTracks();

      if (imageTracks.length === 0) return null;

      // if we add more complexity/async behaviour here, make sure we clean up/abort
      // when player is stopped.
      const thumbnails = await player.getThumbnails(
        imageTracks[0].id,
        position
      );

      if (!thumbnails) return null;

      return {
        ...thumbnails,
        positionX:
          imageTracks[0].width - thumbnails.positionX - thumbnails.width,
        positionY:
          imageTracks[0].height - thumbnails.positionY - thumbnails.height,
      };
    },
    load: getLoadFn(engineMethodOptions),
    pause: () => domElements.videoElement.pause(),
    play: getPlayFn(engineMethodOptions),
    playerId,
    seek: (secondsOrLive) => {
      if (secondsOrLive === "LIVE") {
        player.goToLive();
      } else {
        domElements.videoElement.currentTime = secondsOrLive;
      }
    },
    setAudioTrack: (lang) => {
      if (!lang) return;

      player.selectAudioLanguage(lang.isoCode, lang.type);
    },
    setSpeed: (speed) => {
      const previousSpeed = player.getPlaybackRate();

      domElements.videoElement.playbackRate = speed;

      callbacks.onSpeedChange({
        newSpeed: speed,
        previousSpeed,
      });
    },
    setSubtitleTrack: (lang) => {
      if (!lang) return;

      // Look for a track that matches language and type
      const wantedTrack: TShakaTrack = player
        .getTextTracks()
        .find(
          (t: TShakaTrack) =>
            t.language === lang.isoCode && getSubtitleTrackType(t) === lang.type
        );

      if (wantedTrack) {
        player.selectTextTrack(wantedTrack);
      } else {
        // eslint-disable-next-line no-console
        console.warn("Text track not found.");
      }
    },
    setSubtitleVisibility: (visible) => {
      player.setTextTrackVisibility(visible);
    },
    teardown: getTeardownFn(engineMethodOptions),
  };
};
