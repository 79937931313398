var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Core from '../Core';
export default class CoreStorage {
    constructor(options, browserStorage) {
        this.options = options;
        this.browserStorage = browserStorage;
    }
    /**
     * Returns if storages are available or not
     *
     */
    isEnabled() {
        return this.browserStorage.isEnabled();
    }
    /**
     * Sets data in the local storage
     * @param key
     * @param value
     */
    setLocal(key, value) {
        this.browserStorage.setLocal(key, value);
    }
    /**
     * Gets data from the local storage
     * @param key
     * @returns
     */
    getLocal(key) {
        return this.browserStorage.getLocal(key);
    }
    /**
     * Removes data from the local storage
     * @param key
     */
    removeLocal(key) {
        this.browserStorage.removeLocal(key);
    }
    /**
     * Returns service host
     *
     */
    getHost() {
        let host = this.options.getHost();
        const fastData = Core.getInstance().getFastDataService();
        if (!Core.getInstance().isUsingLegacy() && fastData && fastData.getFastDataResponse() && fastData.getFastDataResponse().host) {
            host = fastData.getFastDataResponse().host;
        }
        else if (Core.getInstance().isUsingLegacy() && Core.getInstance().getLegacyFastDataHost()) {
            host = Core.getInstance().getLegacyFastDataHost();
        }
        return host;
    }
    /**
     *
     * @returns {*}
     */
    getSession() {
        let ret = this.browserStorage.getStorages('session');
        // Some tvs set the value as 'undefined' as string when deleting the value
        if (ret === 'undefined') {
            ret = undefined;
        }
        if (!ret) {
            const data = this.getStoredData();
            if (data) {
                try {
                    ret = JSON.parse(data).q.c;
                }
                catch (err) {
                    // nothing
                }
            }
        }
        return ret;
    }
    getStorageHost() {
        let ret = null;
        const data = this.getStoredData();
        if (data) {
            try {
                ret = JSON.parse(data).q.h;
            }
            catch (err) {
                // nothing
            }
        }
        return ret;
    }
    // AccountCode
    getLocalAccountCode() {
        return this.browserStorage.getLocal('accCode');
    }
    getSessionAccountCode() {
        return this.browserStorage.getSession('accCode');
    }
    // AppAnalyticsRegisteredProperties
    getAppAnalyticsRegisteredProperties() {
        return this.browserStorage.getLocal('appAnalyticsRegisteredProperties');
    }
    setAppAnalyticsRegisteredProperties(registeredProperties) {
        this.browserStorage.setLocal('appAnalyticsRegisteredProperties', registeredProperties);
    }
    removeAppAnalyticsRegisteredProperties() {
        this.browserStorage.removeLocal('appAnalyticsRegisteredProperties');
    }
    // AppAnalyticsStarted
    getAppAnalyticsStarted() {
        return this.browserStorage.getLocal('appAnalyticsStarted') === 'true';
    }
    setAppAnalyticsStarted(sessionStarted) {
        this.browserStorage.setLocal('appAnalyticsStarted', String(sessionStarted));
    }
    removeAppAnalyticsStarted() {
        this.browserStorage.removeStorages('appAnalyticsStarted');
    }
    // DataTime
    getDataTime() {
        return this.browserStorage.getStorages('dataTime');
    }
    setDataTime(time) {
        this.browserStorage.setStorages('dataTime', time);
    }
    // LastActive
    getLastActive() {
        return Number(this.browserStorage.getStorages('lastactive'));
    }
    setLastActive(lastActive) {
        this.browserStorage.setStorages('lastactive', lastActive);
    }
    removeLastActive() {
        this.browserStorage.removeStorages('lastactive');
    }
    // Session
    setSession(session) {
        this.browserStorage.setStorages('session', session);
    }
    removeSession() {
        this.browserStorage.removeStorages('session');
    }
    // SessionExpire
    getSessionExpire() {
        return Number(this.browserStorage.getLocal('sessionExpire')) * 1000;
    }
    // StoredData
    getStoredData() {
        return this.browserStorage.getStorages('data');
    }
    setStoredData(data) {
        this.browserStorage.setStorages('data', data);
    }
    removeStoredData() {
        this.browserStorage.removeStorages('data');
    }
    getStorages(key) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.browserStorage.getStorages(key);
        });
    }
}
