import Log from '../../common/log';
import { CoreTag, Method } from '../../common/Constants';
/**
 * This static class provides utility methods.
 *
 * @class
 * @static
 */
export default class Util {
    static toQueryString(obj) {
        return '?' + Object.keys(obj)
            .map(key => {
            if (obj[key] !== null && typeof obj[key] === 'object') {
                const string = JSON.stringify(obj[key]);
                if (string !== '{}') {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(string);
                }
            }
            else if (obj[key] !== null && typeof obj[key] !== 'undefined' && obj[key] !== '') {
                return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
            }
            return '';
        })
            .filter(el => el != '')
            .join('&');
    }
    /**
     * Adds specific protocol. ie: [http://]nqs.nice264.com
     *
     * @param {string} url Domain of the service. Without protocol. ie: 'nqs.nice264.com'.
     * @param {boolean|null} [httpSecure]
     * If true will add https, if false http.
     * Otherwise will add //
     * @return Return the complete service URL.
     */
    static addProtocol(url, httpSecure) {
        let serviceUrl = 'http://localhost/';
        try {
            serviceUrl = 'http://' + url;
            if (httpSecure || (typeof window !== 'undefined' && window.location.protocol.indexOf('https') === 0)) {
                serviceUrl = 'https://' + url;
            }
            else if (typeof window !== 'undefined' && window.location.protocol.indexOf('http') === 0) {
                serviceUrl = '//' + url;
            }
        }
        catch (err) {
            Log.warn(CoreTag, err);
        }
        return serviceUrl;
    }
    /**
     * Parse Boolean (support strings)
     * @param booleanValue
     */
    static parseBoolean(booleanValue) {
        if (typeof booleanValue === 'string') {
            return booleanValue.toLowerCase() === 'true';
        }
        else {
            return !!booleanValue;
        }
    }
    static methodFromString(method) {
        var _a;
        const reqMethod = (_a = method === null || method === void 0 ? void 0 : method.toUpperCase()) !== null && _a !== void 0 ? _a : '';
        if (reqMethod in Method) {
            return Method[reqMethod];
        }
        return Method.GET;
    }
    static generateRandomString(length) {
        let randomString = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < length; i++) {
            randomString += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return randomString;
    }
}
