import styled from "@emotion/styled";
import {
  AccessControl,
  TPlaybackSpec,
  VideoIdType,
  WatchMode,
} from "@telia-company/tv.web-player-shared";
import { Formik } from "formik";
import React, { FC } from "react";

import { getPreparedVideoElement } from "../misc/util";
import { playbackAction, useRefAppDispatch } from "../reducer/ref.reducer";
import { Button } from "./Button";
import { StyledField, StyledSelect } from "./form-elements";
import { PanelTitle } from "./row";

const Row = styled.div`
  background: rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0 0 0.5rem;
  margin: 0 0 0.5rem;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;

    margin-bottom: 0.5rem;
  }

  > button {
    margin-right: 0.5rem;
  }
`;

export const CustomContentForm: FC = () => {
  const dispatch = useRefAppDispatch();

  return (
    <>
      <PanelTitle>Custom Content</PanelTitle>
      <Row>
        <Formik
          initialValues={{
            accessControl: AccessControl.SUBSCRIPTION,
            overrideStreamUrl: "",
            videoId: "",
            videoIdType: VideoIdType.MEDIA,
            watchMode: WatchMode.ONDEMAND,
          }}
          onSubmit={(
            {
              accessControl,
              overrideStreamUrl,
              videoId,
              videoIdType,
              watchMode,
            },
            { setSubmitting }
          ) => {
            dispatch(
              playbackAction({
                overrideStreamUrl: overrideStreamUrl || undefined,
                overrideVideoElement: getPreparedVideoElement(),
                playbackSpec: {
                  accessControl,
                  videoId,
                  videoIdType,
                  watchMode,
                } as TPlaybackSpec,
              })
            );

            setSubmitting(false);
          }}
          validate={(values) => {
            const errors: {
              accessControl?: string;
              videoId?: string;
              videoIdType?: string;
              watchMode?: string;
            } = {};
            if (!values.accessControl) {
              errors.accessControl = "REQUIRED";
            }

            if (!values.videoId) {
              errors.videoId = "REQUIRED";
            }

            if (!values.videoIdType) {
              errors.videoIdType = "REQUIRED";
            }

            if (!values.watchMode) {
              errors.watchMode = "REQUIRED";
            }

            return errors;
          }}
        >
          {({ handleChange, handleSubmit, isSubmitting, values }) => (
            <>
              <FlexContainer>
                <StyledSelect
                  margin="0 0.5rem 0.5rem 0"
                  name="videoIdType"
                  onChange={handleChange}
                  value={values.videoIdType}
                >
                  {Object.keys(VideoIdType).map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </StyledSelect>

                <StyledSelect
                  margin="0 0.5rem 0.5rem 0"
                  name="accessControl"
                  onChange={handleChange}
                  value={values.accessControl}
                >
                  {Object.keys(AccessControl).map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </StyledSelect>

                <StyledSelect
                  margin="0 0.5rem 0.5rem 0"
                  name="watchMode"
                  onChange={handleChange}
                  value={values.watchMode}
                >
                  {Object.keys(WatchMode).map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </StyledSelect>

                <StyledField
                  margin="0 0.5rem 0.5rem 0"
                  name="videoId"
                  placeholder="Video ID"
                  type="text"
                />

                <Button
                  disabled={isSubmitting || !values.videoId}
                  onClick={handleSubmit}
                  text="Play"
                />
              </FlexContainer>

              <FlexContainer>
                <StyledField
                  margin="0 0 0.5rem 0"
                  name="overrideStreamUrl"
                  placeholder="Override Stream Url"
                  type="text"
                />
              </FlexContainer>
            </>
          )}
        </Formik>
      </Row>
    </>
  );
};
