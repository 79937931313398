import { PlaybackEventTypes } from "../Constants";
import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import { TEmptyPayload } from "./Types";

export class BufferedEvent extends PlaybackEventBase<
  PlaybackEventTypes.BUFFERED,
  TEmptyPayload
> {
  constructor() {
    super(PlaybackEventTypes.BUFFERED, {});
  }
}
