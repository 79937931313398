/* global Streamroot, peer5, teltoo, CdnBalancerStats */
const NpawObject = require('../object');
/**
 * This static class provides p2p and cdn network traffic information for
 * Streamroot, Peer5 and EasyBroadcast
 *
 * @constructs NpawObject
 * @extends npaw.NpawObject
 * @memberof npaw
 *
 */
var HybridNetwork = NpawObject.extend({
  /** Returns CDN traffic bytes using NPAW balancer, streamroot, peer5 or teltoo. Otherwise null */
  getCdnTraffic: function () {
    let ret;
    if (typeof CdnBalancerStats !== 'undefined' && CdnBalancerStats.cdn) {
      ret = CdnBalancerStats.cdn.totalDownloadedBytes;
    } else if (typeof Streamroot !== 'undefined') {
      ret = this._getStreamrootPeerObject('cdn', false) || this._getStreamrootInstanceObject('cdnDownload');
    } else if (typeof peer5 !== 'undefined' && peer5.getStats) {
      ret = peer5.getStats().totalHttpDownloaded;
    } else if (typeof teltoo !== 'undefined' && teltoo.getStats) {
      var stats = teltoo.getStats();
      ret = stats.totalReceivedBytes - stats.p2pReceivedBytes;
    }
    return ret;
  },

  /** Returns CDN traffic when using multiple cdns, available only for NPAW solution. Otherwise null */
  getMultiCdnInfo: function () {
    let ret;
    if (typeof CdnBalancerStats !== 'undefined' && CdnBalancerStats.cdn && CdnBalancerStats.cdn.cdns) {
      const p2p = CdnBalancerStats.p2p;
      ret = {
        P2P: {
          downloaded_bytes: p2p.downloadedBytes,
          uploaded_bytes: p2p.uploadedBytes,
          downloaded_chunks: p2p.downloadedSegments,
          uploaded_chunks: p2p.uploadedSegments,
          errors: p2p.failedRequests ? p2p.failedRequests.total : 0,
          missed_downloaded_chunks: p2p.failedRequests ? p2p.failedRequests.absent : 0,
          timeout_errors: p2p.failedRequests ? p2p.failedRequests.timeout : 0,
          other_errors: p2p.failedRequests ? p2p.failedRequests.error : 0,
          late_uploaded_chunks: p2p.discardedUploadedSegments,
          late_uploaded_bytes: p2p.discardedUploadedBytes,
          late_downloaded_bytes: p2p.discardedDownloadedBytes,
          time: p2p.downloadMillis,
          active_peers: p2p.activePeers,
          peers: p2p.totalPeers
        }
      };
      CdnBalancerStats.cdn.cdns.forEach(function (cdn) {
        ret[cdn.name] = {
          downloaded_bytes: cdn.bytes,
          downloaded_chunks: cdn.chunks,
          errors: cdn.failures,
          time: cdn.downloadMillis
        };
      });
    }
    return ret;
  },

  /** Returns segment duration using NPAW balancer API. Otherwise null */
  getSegmentDuration: function () {
    if (typeof CdnBalancerStats !== 'undefined' && CdnBalancerStats.segmentDuration) {
      return CdnBalancerStats.segmentDuration;
    }
    return undefined;
  },

  /** Returns CDN balancer API response id, available only for NPAW solution. Otherwise null */
  getBalancerResponseId: function () {
    if (typeof CdnBalancerStats !== 'undefined' && CdnBalancerStats.cdn) {
      return CdnBalancerStats.cdn.responseUUID;
    }
    return undefined;
  },

  /** Returns P2P traffic bytes using NPAW balancer, streamroot, peer5 or teltoo. Otherwise null */
  getP2PTraffic: function () {
    let ret;
    if (typeof CdnBalancerStats !== 'undefined' && CdnBalancerStats.p2p) {
      ret = CdnBalancerStats.p2p.downloadedBytes;
    } else if (typeof Streamroot !== 'undefined') {
      ret = this._getStreamrootPeerObject('p2p', true) || this._getStreamrootInstanceObject('dnaDownload');
    } else if (typeof peer5 !== 'undefined' && peer5.getStats) {
      ret = peer5.getStats().totalP2PDownloaded;
    } else if (typeof teltoo !== 'undefined' && teltoo.getStats) {
      ret = teltoo.getStats().p2pReceivedBytes;
    }
    return ret;
  },

  /** Returns P2P traffic sent in bytes, using NPAW balancer, streamroot or peer5. Otherwise null */
  getUploadTraffic: function () {
    let ret;
    if (typeof CdnBalancerStats !== 'undefined' && CdnBalancerStats.p2p) {
      ret = CdnBalancerStats.p2p.uploadedBytes;
    } else if (typeof Streamroot !== 'undefined') {
      ret = this._getStreamrootPeerObject('upload', true) || this._getStreamrootInstanceObject('dnaUpload');
    } else if (typeof peer5 !== 'undefined' && peer5.getStats) {
      ret = peer5.getStats().totalP2PUploaded;
    }
    return ret;
  },

  /** Returns if P2P is enabled, using NPAW balancer, streamroot or peer5. Otherwise null */
  getIsP2PEnabled: function () {
    let ret = false;
    if (typeof CdnBalancerStats !== 'undefined' && CdnBalancerStats.p2p) {
      ret = CdnBalancerStats.p2p.downloadEnabled;
    } else if (typeof Streamroot !== 'undefined') {
      if (Streamroot.p2pAvailable && Streamroot.peerAgents) {
        for (var agent in Streamroot.peerAgents) {
          ret = ret || Streamroot.peerAgents[agent].isP2PEnabled;
        }
      } else if (Streamroot.instances) {
        Streamroot.instances.forEach(function (instance) {
          ret = ret || instance.dnaDownloadEnabled || instance.dnaUploadEnabled;
        });
      }
    } else if (typeof peer5 !== 'undefined' && peer5.isEnabled) {
      ret = peer5.isEnabled();
    } else if (typeof teltoo !== 'undefined') {
      ret = true;
    }
    return ret;
  },

  _getStreamrootPeerObject: function (objectName, check) {
    let ret = '';
    if (Streamroot.p2pAvailable && Streamroot.peerAgents) {
      for (const agent in Streamroot.peerAgents) {
        const agentInst = Streamroot.peerAgents[agent];
        if (agentInst.stats && (!check || agentInst.isP2PEnabled)) {
          ret += agentInst.stats[objectName];
        }
      }
    }
    return ret.length > 0 ? ret : undefined;
  },

  _getStreamrootInstanceObject: function (objectName) {
    let ret = '';
    if (Streamroot.instances) {
      Streamroot.instances.forEach(function (instance) {
        if (instance.stats && instance.stats.currentContent) {
          ret += instance.stats.currentContent[objectName];
        }
      });
    }
    return ret.length > 0 ? ret : undefined;
  }
});

module.exports = HybridNetwork;
