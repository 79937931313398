import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { SessionStates } from "@useless-media-public/useless-sender-web";
import React, { useEffect, useMemo, useState } from "react";

import {
  SvgCastConnected,
  SvgCastConnecting0,
  SvgCastConnecting1,
  SvgCastConnecting2,
  SvgCastDisconnected,
} from "~/components/Icons";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => css`
    svg {
      width: 24px;
      height: 24px;
      path[fill] {
        fill: ${theme.designTokens.color.text.primary};
      }
    }
  `}
`;

export type ChromecastIconProps = {
  className?: string;
  senderState?: string;
};

export const ChromecastIcon: React.FC<ChromecastIconProps> = ({
  className,
  senderState,
}) => {
  const [connectingState, setConnectingState] = useState<0 | 1 | 2>();

  useEffect(() => {
    if (senderState !== SessionStates.Starting) return () => undefined;

    const interval = setInterval(() => {
      if (connectingState === 0) {
        setConnectingState(1);
      } else if (connectingState === 1) {
        setConnectingState(2);
      } else {
        setConnectingState(0);
      }
    }, 250);

    return () => clearInterval(interval);
  }, [connectingState, senderState]);

  const icon = useMemo(() => {
    if (senderState === SessionStates.Started) {
      return <SvgCastConnected />;
    }

    if (senderState === SessionStates.Starting) {
      switch (connectingState) {
        case 1:
          return <SvgCastConnecting1 />;
        case 2:
          return <SvgCastConnecting2 />;
        default:
          return <SvgCastConnecting0 />;
      }
    }

    return <SvgCastDisconnected />;
  }, [connectingState, senderState]);

  return <StyledContainer className={className}>{icon}</StyledContainer>;
};
