import {
  AdvertisementBreakEndedEvent,
  AdvertisementBreakInfo,
  AdvertisementBreakStartedEvent,
  AdvertisementBreakTypes,
  AdvertisementEndedEvent,
  AdvertisementStartedEvent,
  CustomTrackingEvent,
  ErrorCategories,
  getDeviceAdvertisementId,
  StandardError,
  Stream,
} from "@telia-company/tv.web-playback-sdk";
import {
  AdSession,
  TEventMethods,
  TPlaybackConfig,
  TPlayerConfig,
  TUserConfig,
  WatchMode,
} from "@telia-company/tv.web-player-shared";

import { getYospaceAdSession } from "./yospace-ad-session";

export type TGetAdSessionOptions = {
  config: TPlayerConfig;
  event: TEventMethods;
  playback: TPlaybackConfig;
  startTime: number;
  stream: Stream;
  user: TUserConfig;
};

const getAdSessionForType = ({
  config,
  event,
  playback,
  startTime,
  stream,
  user,
}: TGetAdSessionOptions): Promise<AdSession | undefined> => {
  if (stream.adType === "YOSPACE_VOD" || stream.adType === "YOSPACE") {
    return getYospaceAdSession({
      event,
      yospaceConfiguration: {
        // debug: true,
        applicationVersion: config.application.applicationVersion,
        callbacks: {
          advertisementBreakEnd: (data) => {
            event.publish(
              new AdvertisementBreakEndedEvent({
                breakType: data?.breakType || AdvertisementBreakTypes.MIDROLL,
                id: data?.id ?? "no-id",
              })
            );
          },
          advertisementBreakStart: (data) => {
            event.publish(
              new AdvertisementBreakStartedEvent(
                data ||
                  new AdvertisementBreakInfo({
                    advertisements: [],
                    breakType: AdvertisementBreakTypes.MIDROLL,
                    durationInSeconds: 0,
                    embedded: false,
                    id: "",
                    name: "",
                    position: 0,
                  })
              )
            );
          },
          advertisementEnd: () => {
            event.publish(new AdvertisementEndedEvent());
          },
          advertisementStart: (data) => {
            event.publish(
              new AdvertisementStartedEvent(
                data || {
                  breakType: AdvertisementBreakTypes.MIDROLL,
                  campaignId: "",
                  customId: "",
                  durationInSeconds: 0,
                  goalId: "",
                  id: "no-id",
                  name: "no-name",
                  sponsor: false,
                }
              )
            );
          },
        },
        contentId: playback.playbackSpec.videoId,
        contentUrl: stream.url as string,
        // https://teliatv4media.atlassian.net/wiki/spaces/OVPCL/pages/682360996/Dcids+-+lista
        deviceAdvertisementId: getDeviceAdvertisementId(config.clientName),
        gdprConsentString: user.gdprConsent,
        live: playback.playbackSpec.watchMode === WatchMode.LIVE,
        serviceCountry: config.serviceCountry,
        serviceId: config.serviceId,
        skipBumpers: false,
        skipPrerolls: startTime > 0,
        startOver: playback.playbackSpec.watchMode === WatchMode.STARTOVER,
        startTime,
        userId: config.user?.userId,
      },
    });
  }

  return Promise.resolve(undefined);
};

export const getAdSession = (
  options: TGetAdSessionOptions
): Promise<AdSession | undefined> =>
  getAdSessionForType(options).catch((e) => {
    // eslint-disable-next-line no-console
    console.warn("[Ad Session Failed]", e);

    options.event.publish(
      new CustomTrackingEvent({
        name: "AD_SESSION_FAILED",
      })
    );

    throw new StandardError({
      category: ErrorCategories.ADS,
      code: "AD_SESSION_ERROR",
      fatal: false,
      originalError: e,
    });
  });
