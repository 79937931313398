import { PlaybackEventTypes } from "@telia-company/tv.web-playback-sdk";
import {
  TEventMethods,
  TWildcardEventHandler,
} from "@telia-company/tv.web-player-shared";
import { Dispatch, useEffect } from "react";

import { TAction } from "./ui-state-reducer.hook";

export type TUsePlayerEventsOptions = {
  dispatch: Dispatch<TAction>;
  off: TEventMethods["off"];
  on: TEventMethods["on"];
};

export const usePlayerEvents = ({
  dispatch,
  off,
  on,
}: TUsePlayerEventsOptions): void => {
  useEffect(() => {
    const handler: TWildcardEventHandler = (type, payload) => {
      if (type === PlaybackEventTypes.DROPPED_FRAMES) return;

      dispatch(payload);
    };

    on("*", handler);

    return () => off("*", handler);
  }, [dispatch, on, off]);
};
