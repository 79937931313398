import { TUnfortunatelyAny } from "@telia-company/tv.web-player-shared";
import shaka from "shaka-player";

const staleSessionTimeout = 30 * 60 * 1000; // 30 minutes

export type TGetTimeoutFilterFunction = () => TUnfortunatelyAny;

const getSessionTimeoutFilter = (): TGetTimeoutFilterFunction => {
  let lastRequestTimestamp: number;

  return (): TUnfortunatelyAny => {
    if (
      lastRequestTimestamp &&
      Date.now() - lastRequestTimestamp > staleSessionTimeout
    ) {
      throw new shaka.util.Error(2, 1, "STALE_SESSION", {
        reason: "Session closed due to inactivity",
      });
    }

    lastRequestTimestamp = Date.now();
  };
};

export { getSessionTimeoutFilter };
