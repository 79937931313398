import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { SvgSpinner } from "~/components/Icons";

const spin = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`;

const StyledContainer = styled.div`
  align-items: center;
  margin: 0 8px;

  ${({ theme }) => css`
    svg {
      animation: ${spin} 1s ease infinite;
      width: 28px;
      height: 28px;
      padding: 14px;

      *[stroke] {
        stroke: ${theme.designTokens.color.text.primary};
      }

      @media (min-width: ${theme.breakpoints.S}px) {
        width: 40px;
        height: 40px;
        padding: 12px;
      }
    }
  `}
`;

export type SpinnerProps = {
  className?: string;
};

export const Spinner: React.FC<SpinnerProps> = ({ className }) => (
  <StyledContainer>
    <SvgSpinner className={className} />
  </StyledContainer>
);
