//
//  ID3Parser.ts
//
//  Created by Lars Rothaus on 14/06/2021.
//  Copyright © 14/06/2021 Lars Rothaus. All rights reserved.
//

import { Id3Cue } from "../Types";

const byteArrayToString = (byteArray: Uint8Array): string => {
  let s = "";
  for (let i = 0; i < byteArray.length; i += 1) {
    s += String.fromCharCode(byteArray[i]);
  }
  return s;
};

export class ID3Parser {
  static Parse(byteArray: Uint8Array, startTime: number): Id3Cue {
    try {
      const id3Cue: Id3Cue = {
        startTime,
        tags: [],
      };
      let offset = 0;
      if (
        !(
          byteArray[0] === 0x49 &&
          byteArray[1] === 0x44 &&
          byteArray[2] === 0x33
        )
      ) {
        // Should be 'ID3'
        throw new Error("Invalid Id3 payload");
      }
      const flags = byteArray[5];
      const usesSynch = (flags & 0x80) !== 0;
      const hasExtendedHdr = (flags & 0x40) !== 0;
      const size =
        (byteArray[9] & 0xff) |
        ((byteArray[8] & 0xff) << 7) |
        ((byteArray[7] & 0xff) << 14);
      offset += 10; // Header size is 10 bytes
      if (usesSynch) {
        throw new Error("Uses Synch which is not supported yet");
      }
      if (hasExtendedHdr) {
        throw new Error("Has extended hdr which is not supported yet");
      }
      const frameHeaderSize = byteArray[3] < 3 ? 6 : 10;
      while (offset < byteArray.length) {
        if (offset > size + 10) {
          break;
        }
        const key = byteArrayToString(byteArray.slice(offset, offset + 4));
        const frameSize =
          (byteArray[offset + 7] & 0xff) |
          ((byteArray[offset + 6] & 0xff) << 8) |
          ((byteArray[offset + 5] & 0xff) << 16) |
          (byteArray[offset + 4] << 24);
        if (frameSize > 0) {
          id3Cue.tags.push({
            key,
            data: byteArray.slice(
              offset + 11,
              offset + frameSize + frameHeaderSize
            ),
          });
        }
        offset += frameSize + frameHeaderSize;
      }

      return id3Cue;
    } catch (e) {
      throw new Error(
        `Error class:ID3Parser[Parse] : error parsing ID3 cues \n${e}!`
      );
    }
  }
}
