import {
  isArray,
  TClientErrorId,
  TUnfortunatelyAny,
} from "@telia-company/tv.web-player-shared";

import { TOverrideCodeFn } from "./types";

// Override codes based on how it has been agreed
// we want to display them in AFS / Youbora UI.
const getNativeErrorOverrideMessage = (
  string: string
): null | TClientErrorId => {
  if (string.indexOf("NS_ERROR_OUT_OF_MEMORY") > -1)
    return "NS_ERROR_OUT_OF_MEMORY";

  if (string.indexOf("AUDIO_RENDERER_ERROR") > -1)
    return "AUDIO_RENDERER_ERROR";

  return null;
};

const getNativeErrorString = (data: unknown): string =>
  isArray(data) && typeof data[2] === "string" ? data[2] : "";

// Handling for custom error codes, and conversions for specific error cases
export const overrideCodeFn: TOverrideCodeFn = (
  detail,
  msEdgeCode,
  engineInitTimestamp
) => {
  const { code, data } = detail;

  // handle custom request/response filter error codes
  if (code === 1006 && isArray(data) && data[0].code === "STALE_SESSION")
    return "STALE_SESSION";
  if (code === 1007 && isArray(data) && data[0].code === "MANIFEST_FETCH_LOOP")
    return "MANIFEST_FETCH_LOOP";

  // handle drm expired as stale session if enough time has passed (30 minutes)
  if (
    code === 6014 &&
    engineInitTimestamp &&
    Date.now() - engineInitTimestamp > 30 * 60 * 1000
  )
    return "STALE_SESSION";

  // wrong cdm, returned from castlabs
  if (
    code === 6007 &&
    isArray(data) &&
    data[0].code === 1001 &&
    parseInt(data[0].data[1], 10) === 406
  )
    return "DRM_NOT_ACCEPTABLE";

  // wrong cdm, returned from vmx
  if (
    code === 6007 &&
    isArray(data) &&
    data[0].code === 1001 &&
    parseInt(data[0].data[1], 10) === 500 &&
    data[0].data[2] === "ACCESS_DENIED"
  )
    return "DRM_NOT_ACCEPTABLE";

  if (
    msEdgeCode === "UNSUPPORTED_DEVICE" ||
    msEdgeCode === "UNSUPPORTED_SCREEN"
  )
    return msEdgeCode;

  const nativeErrorOverride = getNativeErrorOverrideMessage(
    getNativeErrorString(data)
  );

  if (nativeErrorOverride) return nativeErrorOverride;

  return null;
};

// Helper function to figure out if an error is fatal, since shaka
// unpredictably can override the fatality level of a thrown custom error.
// https://github.com/google/shaka-player/issues/3455
export const isErrorFatalFn = (detail: TUnfortunatelyAny): boolean => {
  try {
    if (detail?.data?.[0]?.code === "MANIFEST_FETCH_LOOP") return true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn("Failed parsing custom error", e, detail);
  }

  return false;
};
