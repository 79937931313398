import {
  AuthenticationService,
  MetadataService,
} from "@telia-company/tv.web-playback-sdk";
import { Dispatch, useEffect } from "react";

import { TLogin } from "../@types/types";
import { getServiceConfig, loginMap, TEnvConf } from "../config/ref-app-config";
import { getPreparedVideoElement } from "../misc/util";
import {
  logoutAction,
  playbackAction,
  TAction,
  togglePlayerAction,
} from "../reducer/ref.reducer";

export type Props = {
  applicationSessionId: string;
  auth: AuthenticationService;
  deviceId: string;
  dispatch: Dispatch<TAction>;
  doLogin: TLogin;
  env: TEnvConf;
  metadataService: MetadataService;
  serviceCountry: string;
};

export const useEnvironment = ({
  applicationSessionId,
  auth,
  deviceId,
  dispatch,
  doLogin,
  env,
  metadataService,
  serviceCountry,
}: Props): void => {
  useEffect(() => {
    if (!serviceCountry) return;
    dispatch(togglePlayerAction(false));
    dispatch(logoutAction());

    auth.initialize({
      ...getServiceConfig({
        applicationSessionId,
        deviceId,
        env: env.id,
        serviceCountry,
      }),
    });

    metadataService.initialize(
      getServiceConfig({
        applicationSessionId,
        deviceId,
        env: env.id,
        serviceCountry,
      })
    );

    // Auto Login
    doLogin(loginMap[env.id]);

    // auto play block testing
    const lastPlaybackSpec = localStorage.getItem("lastPlaybackSpec");
    if (lastPlaybackSpec) {
      dispatch(
        playbackAction({
          overrideVideoElement: getPreparedVideoElement(),
          playbackSpec: JSON.parse(lastPlaybackSpec),
        })
      );
    }
  }, [
    env,
    dispatch,
    doLogin,
    auth,
    deviceId,
    applicationSessionId,
    metadataService,
    serviceCountry,
  ]);
};
