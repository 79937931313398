import { PlaybackEventTypes } from "../Constants";
import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import { TEmptyPayload } from "./Types";

export class SeekingEvent extends PlaybackEventBase<
  PlaybackEventTypes.SEEKING,
  TEmptyPayload
> {
  constructor() {
    super(PlaybackEventTypes.SEEKING, {});
  }
}
