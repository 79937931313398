import { PlaybackEventTypes } from "../Constants";
import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import { AdvertisementBreakInfo } from "../dtos";
import { ExcludeMethods } from "./Types";

export type TAdvertisementBreakStartedEventPayload =
  ExcludeMethods<AdvertisementBreakInfo>;

export class AdvertisementBreakStartedEvent extends PlaybackEventBase<
  PlaybackEventTypes.ADVERTISEMENT_BREAK_STARTED,
  TAdvertisementBreakStartedEventPayload
> {
  constructor(payload: TAdvertisementBreakStartedEventPayload) {
    super(PlaybackEventTypes.ADVERTISEMENT_BREAK_STARTED, payload);
  }
}
