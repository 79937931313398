import { StandardError, StandardErrorArgs } from "./StandardError";
import { ErrorCategories } from "../Constants";
import { UnknownError } from "./constants";

export enum NetworkErrorCodes {
  /// sdk errors
  MissingAuthentication = "MISSING_AUTHENTICATION",
  Aborted = "ABORTED",
  InternalError = "INTERNAL_ERROR",
  ResponseParsing = "RESPONSE_PARSING",
  /// http error codes
  BadRequestResponse = "BAD_REQUEST_RESPONSE",
  UnauthorizedResponse = "UNAUTHORIZED_RESPONSE",
  PaymentRequiredResponse = "PAYMENT_REQUIRED_RESPONSE",
  ForbiddenResponse = "FORBIDDEN_RESPONSE",
  NotFoundResponse = "NOT_FOUND_RESPONSE",
  MethodNotAllowedResponse = "METHOD_NOT_ALLOWED_RESPONSE",
  NotAcceptableResponse = "NOT_ACCEPTABLE_RESPONSE",
  ProxyAuthenticationRequiredResponse = "PROXY_AUTHENTICATION_REQUIRED_RESPONSE",
  RequestTimeoutResponse = "REQUEST_TIMEOUT_RESPONSE",
  ConflictResponse = "CONFLICT_RESPONSE",
  GoneResponse = "GONE_RESPONSE",
  LengthRequiredResponse = "LENGTH_REQUIRED_RESPONSE",
  PreconditionFailedResponse = "PRECONDITION_FAILED_RESPONSE",
  PayloadTooLargeResponse = "PAYLOAD_TOO_LARGE_RESPONSE",
  URITooLongResponse = "URI_TOO_LONG_RESPONSE",
  UnsupportedMediaTypeResponse = "UNSUPPORTED_MEDIA_TYPE_RESPONSE",
  RangeNotSatisfiableResponse = "RANGE_NOT_SATISFIABLE_RESPONSE",
  ExpectationFailedResponse = "EXPECTATION_FAILED_RESPONSE",
  ImATeapotResponse = "IM_A_TEAPOT_RESPONSE",
  MisdirectedRequestResponse = "MISDIRECTED_REQUEST_RESPONSE",
  UnprocessableEntityResponse = "UNPROCESSABLE_ENTITY_RESPONSE",
  LockedResponse = "LOCKED_RESPONSE",
  FailedDependencyResponse = "FAILED_DEPENDENCY_RESPONSE",
  UnorderedCollectionResponse = "UNORDERED_COLLECTION_RESPONSE",
  UpgradeRequiredResponse = "UPGRADE_REQUIRED_RESPONSE",
  PreconditionRequiredResponse = "PRECONDITION_REQUIRED_RESPONSE",
  TooManyRequestsResponse = "TOO_MANY_REQUESTS_RESPONSE",
  RequestHeaderFieldsTooLargeResponse = "REQUEST_HEADER_FIELDS_TOO_LARGE_RESPONSE",
  UnavailableForLegalReasonsResponse = "UNAVAILABLE_FOR_LEGAL_REASONS_RESPONSE",
  InternalServerErrorResponse = "INTERNAL_SERVER_ERROR_RESPONSE",
  NotImplementedResponse = "NOT_IMPLEMENTED_RESPONSE",
  BadGatewayResponse = "BAD_GATEWAY_RESPONSE",
  ServiceUnavailableResponse = "SERVICE_UNAVAILABLE_RESPONSE",
  GatewayTimeoutResponse = "GATEWAY_TIMEOUT_RESPONSE",
  HTTPVersionNotSupportedResponse = "HTTP_VERSION_NOT_SUPPORTED_RESPONSE",
  VariantAlsoNegotiatesResponse = "VARIANT_ALSO_NEGOTIATES_RESPONSE",
  InsufficientStorageResponse = "INSUFFICIENT_STORAGE_RESPONSE",
  LoopDetectedResponse = "LOOP_DETECTED_RESPONSE",
  BandwidthLimitExceededResponse = "BANDWIDTH_LIMIT_EXCEEDED_RESPONSE",
  NotExtendedResponse = "NOT_EXTENDED_RESPONSE",
  NetworkAuthenticationRequiredResponse = "NETWORK_AUTHENTICATION_REQUIRED_RESPONSE",
}

const ErrorCodesNamesMap: Record<number, NetworkErrorCodes> = {
  400: NetworkErrorCodes.BadRequestResponse,
  401: NetworkErrorCodes.UnauthorizedResponse,
  402: NetworkErrorCodes.PaymentRequiredResponse,
  403: NetworkErrorCodes.ForbiddenResponse,
  404: NetworkErrorCodes.NotFoundResponse,
  405: NetworkErrorCodes.MethodNotAllowedResponse,
  406: NetworkErrorCodes.NotAcceptableResponse,
  407: NetworkErrorCodes.ProxyAuthenticationRequiredResponse,
  408: NetworkErrorCodes.RequestTimeoutResponse,
  409: NetworkErrorCodes.ConflictResponse,
  410: NetworkErrorCodes.GoneResponse,
  411: NetworkErrorCodes.LengthRequiredResponse,
  412: NetworkErrorCodes.PreconditionFailedResponse,
  413: NetworkErrorCodes.PayloadTooLargeResponse,
  414: NetworkErrorCodes.URITooLongResponse,
  415: NetworkErrorCodes.UnsupportedMediaTypeResponse,
  416: NetworkErrorCodes.RangeNotSatisfiableResponse,
  417: NetworkErrorCodes.ExpectationFailedResponse,
  418: NetworkErrorCodes.ImATeapotResponse,
  421: NetworkErrorCodes.MisdirectedRequestResponse,
  422: NetworkErrorCodes.UnprocessableEntityResponse,
  423: NetworkErrorCodes.LockedResponse,
  424: NetworkErrorCodes.FailedDependencyResponse,
  425: NetworkErrorCodes.UnorderedCollectionResponse,
  426: NetworkErrorCodes.UpgradeRequiredResponse,
  428: NetworkErrorCodes.PreconditionRequiredResponse,
  429: NetworkErrorCodes.TooManyRequestsResponse,
  431: NetworkErrorCodes.RequestHeaderFieldsTooLargeResponse,
  451: NetworkErrorCodes.UnavailableForLegalReasonsResponse,
  500: NetworkErrorCodes.InternalServerErrorResponse,
  501: NetworkErrorCodes.NotImplementedResponse,
  502: NetworkErrorCodes.BadGatewayResponse,
  503: NetworkErrorCodes.ServiceUnavailableResponse,
  504: NetworkErrorCodes.GatewayTimeoutResponse,
  505: NetworkErrorCodes.HTTPVersionNotSupportedResponse,
  506: NetworkErrorCodes.VariantAlsoNegotiatesResponse,
  507: NetworkErrorCodes.InsufficientStorageResponse,
  508: NetworkErrorCodes.LoopDetectedResponse,
  509: NetworkErrorCodes.BandwidthLimitExceededResponse,
  510: NetworkErrorCodes.NotExtendedResponse,
  511: NetworkErrorCodes.NetworkAuthenticationRequiredResponse,
};

export const getNetworkErrorCode = (StatusCodeOrErrorName: string | number) => {
  if (typeof StatusCodeOrErrorName === "string") {
    return StatusCodeOrErrorName;
  }

  if (typeof StatusCodeOrErrorName === "number") {
    return (
      ErrorCodesNamesMap[StatusCodeOrErrorName] ??
      `${UnknownError}:${StatusCodeOrErrorName}`
    );
  }

  return `${UnknownError}:${StatusCodeOrErrorName}`;
};

export type TNetworkErrorArgs = Omit<StandardErrorArgs, "category"> & {
  manuallyAborted?: boolean;
  statusCode?: number;
  statusText?: string;
  responseBody?: any;
  responseHeaders?: any;
};

export class NetworkError extends StandardError {
  readonly aborted: boolean;
  readonly manuallyAborted: boolean;
  readonly statusCode: number | null;
  readonly statusText: string | null;
  readonly responseBody?: any;
  readonly responseHeaders?: any;

  constructor(args: TNetworkErrorArgs) {
    super({
      ...args,
      message: args.responseBody?.errorMessage || args.statusText,
      category: ErrorCategories.NETWORK,
      details: {
        ...args.details,
        aborted: args.originalError?.name === "AbortError",
        manuallyAborted: args.manuallyAborted || false,
        statusCode: args.statusCode || null,
        statusText: args.statusText || null,
        responseBody: args.responseBody,
        responseHeaders: args.responseHeaders,
      },
    });

    this.aborted = args.originalError?.name === "AbortError";
    this.manuallyAborted = args.manuallyAborted || false;
    this.statusCode = args.statusCode || null;
    this.statusText = args.statusText || null;
    this.responseBody = args.responseBody;
  }
}
