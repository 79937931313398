import { isArray, isObject } from "@telia-company/tv.web-player-shared";

import { TShakaError } from "./types";

const getMessageFromOriginalError = (data: unknown): null | string => {
  if (!data) return null;
  if (!isArray(data)) return null;

  if (
    typeof data[0] === "string" &&
    isObject(data[1]) &&
    typeof data[1].message === "string"
  ) {
    return data[1].message;
  }

  if (isObject(data[0]) && data[0].message) return data[0].message;

  if (typeof data[3] === "string") return data[3];

  // sometimes errors are nested, but there's probably no need to go recursive
  if (
    isObject(data[0]) &&
    isArray(data[0].data) &&
    typeof data[0].data[3] === "string"
  )
    return data[0].data[3];

  return null;
};

export const getErrorDescription = (error: TShakaError): string =>
  error.message ||
  getMessageFromOriginalError(error.data) ||
  "[SHAKA] The shaka player has encountered an error without a description.";
