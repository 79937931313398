var Emitter = require('../../../emitter');
var Util = require('../../../util');
var YBRequest = require('../../request');
const { AnalyticsTag } = require('../../../../common/Constants');
const { default: Log } = require('../../../../common/log');

var CdnParser = Emitter.extend(
  /** @lends npaw.CdnParser.prototype */
  {
    /**
     * Class that asynchronously tries to get information about the CDN where a given resource is
     * hosted.
     *
     * The info we care about is the CDN code itself, the node host and node type.
     *
     * The CDN is queried with http HEAD requests. This only will work if the CDN has been properly
     * configured.
     *
     * When HEAD requests are performed against the resources, the CDN returns a set of headers that
     * contain info about the cdn header and/or cdn type.
     *
     * Each CDN is different; some require special headers to be set when the HEAD request is
     * performed and others don't. Also, the info can come back in any fashion of ways, sometimes
     * both type and host come in the same response header while sometimes they're in different
     * headers. The format of these response headers is also different from CDN to CDN, so a
     * different regex is used for each CDN.
     *
     * Lastly, as the values indicating the CDN type are also different, we need a specific mapping
     * for each one.
     *
     * Every instance of this class will represent a 'way' of parsing the HEAD response. So an
     * instance should be created for Level3, Akamai, Highwinds, etc...
     *
     * @constructs CdnParser
     * @extends npaw.Emitter
     * @memberof npaw
     *
     * @param {object} options This object represents the configuration of a certain CDN parsing
     * methodology.
     * @param {string} options.cdnName see {@link CdnParser#setCdnName}.
     * @param {array} options.parsers see {@link CdnParser#addParser}.
     * @param {array} options.requestMethod see {@link CdnParser#setRequestMethod}.
     * @param {array} options.requestHeaders see {@link CdnParser#setRequestHeader}.
     * @param {function} options.parseType see {@link CdnParser#setParseType}.
     */
    constructor: function (options) {
      this._options = Util.assign(
        {
          cdnName: null,
          parsers: [],
          requestMethod: 'HEAD',
          requestHeaders: {},
          parseType: function () {
            return 0;
          }
        },
        options
      );

      this._responses = {};
    },

    /**
     * Emits DONE event
     */
    done: function () {
      this.emit(CdnParser.Event.DONE);
    },

    /**
     * Adds an object that represents a parse step of the headers.
     *
     * Each parser item will define the element parsed (whether if it is a host, a type, both...)
     * a headerName to parse (ie: x-cache) and a regex to execute over that header.
     *
     * @param {object} parser
     * @param {string} parser.element Which element will be parsed from the request.
     * Use {@link CdnParser.ElementType} enum.
     * @param {string} parser.headerName Name of the header to parse.
     * @param {regex} parser.regex Regex to match against the header content.
     *
     * @return itself to chain method calls
     */
    addParser: function (parser) {
      this._options.parsers.push(parser);
      return this;
    },

    /**
     * Sets the cdn name. Note that this names are provided by Youbora and must coincede with the
     * ones offered here: {@link http://mapi.youbora.com:8081/cdns}.
     *
     * @return itself to chain method calls
     */
    setCdnName: function (name) {
      this._options.cdnName = name;
      return this;
    },

    /**
     * Sets the method of the request. HEAD by default.
     *
     * @return itself to chain method calls
     */
    setRequestMethod: function (method) {
      this._options.requestMethod = method;
      return this;
    },

    /**
     * if this CDN requires special headers to be set in order to respond with the info we want,
     * add them using this method.
     *
     * @param {string} key Name of the header.
     * @param {string} value Content of the header.
     *
     * @return itself to chain method calls
     */
    setRequestHeader: function (key, value) {
      this._options.requestHeaders[key] = value;
      return this;
    },

    /**
     * Adds a parsing function for parsing the type (hit or miss) of the request.
     *
     * Parser fucntion will receive a string parsed from a type header (see
     * {@link CdnParser#addParser}). Should return 1 in case of HIT, 2 in case of MISS and
     * 0 otherwise.
     *
     * @param {function} parser Parsing function
     *
     * @return itself to chain method calls
     */
    setParseType: function (parser) {
      this._options.parseType = parser;
      return this;
    },

    /**
     * Get parsed CDN name.
     *
     * @return {string} The CDN name or null if unknown
     */
    getParsedCdnName: function () {
      return this._cdnName;
    },

    /**
     * Get the parsed CDN node.
     *
     * @return {string} The CDN node or null if unknown
     */
    getParsedNodeHost: function () {
      return this._cdnNodeHost;
    },

    /**
     * Get the parsed CDN type string, as returned in the cdn header response.
     *
     * @return {string} The CDN type string
     */
    getParsedNodeTypeString: function () {
      return this._cdnNodeTypeString;
    },

    /**
     * Get the parsed CDN type, parsed from the type string.
     *
     * @return {string} The CDN type
     */
    getParsedNodeType: function () {
      return this._cdnNodeType;
    },

    /**
     * Returns the request responses from this CdnParser.
     * This is filled with the responses from the constructor, or created empty if null.
     * Then the performed request response (if any) is added to this map.
     * Call this method after "using" the CdnParser and pass the responses to the following
     * CdnParser so it can use the responses if it applies.
     * @return the request responses
     */
    getResponses: function () {
      return this._responses;
    },

    /**
     * Parses given headers to check for matches.
     */
    parse: function (url, responses) {
      this._responses = responses || {};
      var headerString = JSON.stringify(this._options.requestHeaders);
      if (this._responses[headerString]) {
        this._parseResponse(this._responses[headerString]);
      } else {
        this._requestResponse(url);
      }
    },

    _requestResponse: function (url) {
      var headerString = JSON.stringify(this._options.requestHeaders);
      var useheaders = headerString !== '{}';

      const xhr = new XMLHttpRequest();
      xhr.open(this._options.requestMethod, url);

      for (const key in this._options.requestHeaders) {
        if (Object.prototype.hasOwnProperty(this._options.requestHeaders, key)) {
          xhr.setRequestHeader(key, this._options.requestHeaders[key]);
        }
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 400) {
            this._responses[headerString] = xhr.getAllResponseHeaders();
            this._parseResponse(this._responses[headerString]);
          } else {
            if (useheaders) {
              this._options.requestHeaders = {};
              this._requestResponse(url);
            } else {
              this.done();
            }
          }
        }
      };

      xhr.send();
    },

    /**
     *
     * @param headers
     * @private
     */
    _parseResponse: function (headers) {
      this._options.parsers.forEach(
        function (parser) {
          if (typeof parser.headerName === 'string') {
            this._parseHeaderResponse(headers, parser, parser.headerName);
          } else if (Array.isArray(parser.headerName)) {
            for (var i = 0, len = parser.headerName.length; i < len; i++) {
              var headerNameElement = parser.headerName[i];
              if (typeof headerNameElement === 'string') {
                this._parseHeaderResponse(headers, parser, headerNameElement);
              }
            }
          }
        }.bind(this)
      );
      this.done();
    },

    /**
     *
     * @param headers
     * @param parser
     * @param parser
     * @private
     */
    _parseHeaderResponse: function (headers, parser, headerName) {
      if (headerName) {
        headerName = headerName ? headerName.toLowerCase() : '';
        headers.split('\n').forEach(
          function (line) {
            var index = line.indexOf(':');
            if (index !== -1) {
              var key = line.slice(0, index).toLowerCase();
              if (key === headerName) {
                this._executeParser(parser, line.slice(index + 1));
              }
            }
          }.bind(this)
        );
      }
    },

    _executeParser: function (parser, value) {
      try {
        var matches = parser.regex.exec(value.trim());
        if (matches !== null) {
          if (this._options.cdnName) this._cdnName = this._options.cdnName;
          switch (parser.element) {
            case CdnParser.ElementType.HOST:
              this._cdnNodeHost = matches[1];
              break;
            case CdnParser.ElementType.TYPE:
              this._cdnNodeTypeString = matches[1];
              this._cdnNodeType = this._options.parseType(this._cdnNodeTypeString);
              break;
            case CdnParser.ElementType.HOST_AND_TYPE:
              this._cdnNodeHost = matches[1];
              this._cdnNodeTypeString = matches[2];
              this._cdnNodeType = this._options.parseType(this._cdnNodeTypeString);
              break;
            case CdnParser.ElementType.TYPE_AND_HOST:
              this._cdnNodeTypeString = matches[1];
              this._cdnNodeType = this._options.parseType(this._cdnNodeTypeString);
              this._cdnNodeHost = matches[2];
              break;
            case CdnParser.ElementType.NAME:
              this._cdnName = matches[1].toUpperCase();
              break;
          }
        }
      } catch (err) {
        Log.warn(AnalyticsTag, 'CDN parsing for ' + this._options.cdnName + ' could not parse header value ' + value);
      }
    },

    shouldExecute: function () {
      return true;
    }
  },

  /** @lends npaw.CdnParser */
  {
    // Static members

    /**
     * List of events that could be fired from this class.
     *
     * @enum
     */
    Event: {
      /** Notifies that this CdnParser is done processing. */
      DONE: 'done'
    },

    /**
     * Possible different bits of info we can get from a header.
     *
     * @enum
     */
    ElementType: {
      HOST: 'host',
      TYPE: 'type',
      HOST_AND_TYPE: 'host+type',
      TYPE_AND_HOST: 'type+host',
      NAME: 'name'
    },

    /**
     * List of available CDN parsers.
     * @private
     */
    _cdnConfigs: {},

    /**
     * This is a special case. The BalancerCdnParser is a custom CDN definition
     * that tries to get the CDN name directly from one of the headers. This method can be used
     * as a shortcut to creating a new CDN definition.
     *
     * This is usually used with DNS-based load balance services, such as Cedexis.
     *
     * Npawlib will use this method by itself using the configuration passed in the
     * {@link Options}.
     *
     * @param {string} cdnNameHeader the header response name where to get the CDN name from.
     * @param {string} cdnNodeNameHeader the header response name where to get the host name from.
     */
    setBalancerHeaderName: function (name, nodename) {
      CdnParser._cdnConfigs.Balancer.parsers[0].headerName = name;
      CdnParser._cdnConfigs.Balancer.parsers[1].headerName = nodename;
    },

    /**
     * Create one of the pre-defined CDN parsers. This method will be called with the keys passed
     * to {@link Options#'parse.CdnNode.list'}.
     *
     * Before using this method, configs must be added first using {@link CdnParser.add}.
     *
     * @param {string} cdnName Name of the CDN
     * @return {CdnParser} An instance or undefined if the names does not match any CDN.
     */
    create: function (key) {
      if (CdnParser._cdnConfigs[key]) {
        return new CdnParser(CdnParser._cdnConfigs[key]);
      } else {
        Log.warn(AnalyticsTag, 'Tried to create an unexisting CdnParser named ' + key);
      }
    },

    /**
     * Adds the given CdnParser's config to the available list. Objects sent must comply with
     * CdnParser constructor.
     *
     * @param {string} key The name that will identify the CDN.
     * @param {Object} config The parser that defines the CDN.
     */
    add: function (key, config) {
      CdnParser._cdnConfigs[key] = config;
    }
  }
);

// Adding built-in parsers
CdnParser.add('Level3', require('./cdnparsers/level3'));
CdnParser.add('Cloudfront', require('./cdnparsers/cloudfront'));
CdnParser.add('Akamai', require('./cdnparsers/akamai'));
CdnParser.add('Highwinds', require('./cdnparsers/highwinds'));
CdnParser.add('Fastly', require('./cdnparsers/fastly'));
CdnParser.add('Telefonica', require('./cdnparsers/telefonica'));
CdnParser.add('Amazon', require('./cdnparsers/amazon'));
CdnParser.add('Edgecast', require('./cdnparsers/edgecast'));
CdnParser.add('Balancer', require('./cdnparsers/balancer'));
CdnParser.add('NosOtt', require('./cdnparsers/nosott'));

module.exports = CdnParser;
