var NpawObject = require('../object')

var PlaybackFlags = NpawObject.extend(
  /** @lends npaw.PlaybackFlags.prototype */
  {
    /**
     * This class contains all the flags related to view status.
     * Each plugin will have an instance of this class.
     *
     * @constructs PlaybackFlags
     * @extends npaw.NpawObject
     * @memberof npaw
     */
    constructor: function () {
      this.reset()
    },

    /** Reset flag values */
    reset: function () {
      /** Flag when Start has been sent. */
      this.isStarted = false

      /** Flag when Join has been sent. */
      this.isJoined = false

      /** Flag when Player is paused. */
      this.isPaused = false

      /** Flag when Player is seeking. */
      this.isSeeking = false

      /** Flag when Player is buffering. */
      this.isBuffering = false

      /** Flag when Player is buffering by video state. */
      this.isVideoStateBuffering = false

      /** Flag when Player is ended. */
      this.isEnded = false

      /** Flag when Player view ends and stop is already sent. */
      this.isStopped = false

      /** Flag with last quartile sent. */
      this.lastQuartileSent = 0

      /** Flag when the current ad is paused. */
      this.isAdPaused = false
    }
  }
)

module.exports = PlaybackFlags
