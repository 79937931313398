import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import { PlaybackEventTypes } from "../Constants";

export type TDroppedFramesEventPayload = {
  frames: number;
  total: number;
};

export class DroppedFramesEvent extends PlaybackEventBase<
  PlaybackEventTypes.DROPPED_FRAMES,
  TDroppedFramesEventPayload
> {
  constructor(payload: TDroppedFramesEventPayload) {
    super(PlaybackEventTypes.DROPPED_FRAMES, payload);
  }
}
