import { Stream } from "@telia-company/tv.web-playback-sdk";
import Bowser from "bowser";

import { WatchMode } from "../shared-constants";
import { TPlaybackConfig, TSeconds, TUnfortunatelyAny } from "../shared-types";

export const getDuration = (
  videoElement: HTMLVideoElement | undefined
): TSeconds => (videoElement ? videoElement.duration : 0);

export const getPosition = (
  videoElement: HTMLVideoElement | undefined
): TSeconds => (videoElement ? Math.floor(videoElement.currentTime) : 0);

export const isObject = (
  val: unknown
): val is Record<number | string | symbol, TUnfortunatelyAny> =>
  typeof val === "object" && val !== null;

export const isArray = (val: unknown): val is TUnfortunatelyAny[] =>
  Array.isArray(val);

export const getUuid = (): string =>
  "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const rnd = (Math.random() * 16) % 16 | 0;
    return (c === "x" ? rnd : (rnd & 0x7) | 0x8).toString(16);
  });

const browser = Bowser.getParser(window.navigator.userAgent);

export const isSafari15OrGreater = browser.satisfies({
  macos: {
    safari: ">=15",
  },
});

export const isSafari = browser.satisfies({
  macos: {
    safari: ">=1",
  },
});

export const getStartTime = (
  playback: TPlaybackConfig,
  stream: Stream
): number => {
  if (
    typeof playback.startTimeInSeconds !== "number" &&
    playback.playbackSpec.watchMode === WatchMode.LIVE
  ) {
    const isHls = stream?.url && stream.url.toLowerCase().indexOf("m3u") > -1;
    return isHls && isSafari15OrGreater ? 2 ** 32 : Infinity; // Safari does not like Infinity for live streams
  }

  if (isSafari15OrGreater && playback.startTimeInSeconds === Infinity)
    return 2 ** 32;

  return playback.startTimeInSeconds || 0;
};

const getTimeInMs = (timestamp: string) =>
  Date.parse(`1970-01-01T${timestamp}Z`);

const getThumbnailUrl = (timeInMs: number, incomingThumbs: string) => {
  try {
    const thumbs = incomingThumbs.split("\n\n").slice(1);
    const remappedThumbs = thumbs.map((thumb) => {
      const parts = thumb.split("\n");
      const [begin, end] = parts[0].split(" --> ");

      return {
        begin: getTimeInMs(begin),
        end: getTimeInMs(end),
        url: parts[1],
      };
    });

    return remappedThumbs.find(
      (thumb) => timeInMs >= thumb.begin && timeInMs < thumb.end
    );
  } catch (_) {
    return null;
  }
};

type TGetThumbnailOptions = {
  percent: number;
  thumbData: null | string | undefined;
  videoElement: HTMLVideoElement;
};

export const getWebVttThumbnails = ({
  percent,
  thumbData,
  videoElement,
}: TGetThumbnailOptions) => {
  if (!thumbData) return null;
  const timeInMs = percent * (getDuration(videoElement) * 1000);

  // get the actual url to the specific thumbnail grid that was requested, based on position
  const thumbUrl = getThumbnailUrl(timeInMs, thumbData);
  if (!thumbUrl) return null;
  // remap the properties at the end of the url to our thumbnail format, for presentation
  const thumbProps = thumbUrl.url.split("#xywh=");
  const [posX, posY, width, height] = thumbProps[1].split(",");

  return {
    height: parseInt(height, 10),
    positionX: parseInt(posX, 10) * -1,
    positionY: parseInt(posY, 10) * -1,
    uris: [thumbUrl.url],
    width: parseInt(width, 10),
  };
};
