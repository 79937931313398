import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

const StyledContainer = styled.div`
  margin: 0 8px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
`;

const StyledTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    color: ${theme.designTokens.color.text.primary};
    font-family: ${theme.designTokens.typography.headingTertiary.fontFamily};
    font-size: ${theme.designTokens.typography.headingTertiary.fontSize};
    font-weight: ${theme.designTokens.typography.headingTertiary.fontWeight};
    line-height: ${theme.designTokens.typography.headingTertiary.lineHeight};
    text-transform: ${theme.designTokens.typography.headingTertiary
      .textTransform};
  `}
`;

const StyledSecondaryTitle = styled.div`
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    color: ${theme.designTokens.color.text.secondary};
    font-family: ${theme.designTokens.typography.contentData.fontFamily};
    font-size: ${theme.designTokens.typography.contentData.fontSize};
    font-weight: ${theme.designTokens.typography.contentData.fontWeight};
    line-height: ${theme.designTokens.typography.contentData.lineHeight};
  `}
`;

export type AssetInfoProps = {
  secondaryTitle?: string;
  title?: string;
};

export const AssetInfo: React.FC<AssetInfoProps> = ({
  secondaryTitle,
  title,
}) => {
  if (!title && !secondaryTitle) {
    return null;
  }
  return (
    <StyledContainer>
      {title && <StyledTitle>{title}</StyledTitle>}
      {secondaryTitle && (
        <StyledSecondaryTitle>{secondaryTitle}</StyledSecondaryTitle>
      )}
    </StyledContainer>
  );
};
