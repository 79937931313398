import Bowser from "bowser";

export class BrowserInfo {
  public browserName: string;
  public browserVersion: string;

  constructor(args?: BrowserInfo) {
    const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
    this.browserName = args?.browserName || browser.name || "Unknown";
    this.browserVersion = args?.browserVersion || browser.version || "Unknown";
  }
}
