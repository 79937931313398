// import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import "./styles/index.css";

const container = document.getElementById("root");
const root = createRoot(container!);

/* disable strict mode until we figure out why it breaks
  strict mode seems to do more.. stuff in react 18
*/
root.render(
  // <StrictMode>
  <App />
  // </StrictMode>
);
