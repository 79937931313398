import {
  cdnDetection,
  TUnfortunatelyAny,
} from "@telia-company/tv.web-player-shared";
import shaka from "shaka-player";

import { TGetShakaInstanceOptions } from "../../@types/types";

export const setUpCdnDetection = (
  player: TUnfortunatelyAny,
  { callbacks }: TGetShakaInstanceOptions
): void => {
  const cdnChanged = cdnDetection(callbacks.onCdnChange);

  player
    .getNetworkingEngine()
    .registerResponseFilter((type: string, response: TUnfortunatelyAny) => {
      if (type === shaka.net.NetworkingEngine.RequestType.SEGMENT) {
        cdnChanged(response.headers["x-cdn-forward"]);
      }
    });
};
