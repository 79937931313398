import { ScreenInfo, TClientName } from "../base";

// |--------------------------|
// | Interface
// |--------------------------|

export interface IAuthenticationComponent {
  getProfileHeader?: () => string | null;
  getAccessToken: () => Promise<string | null>;
  isAuthenticated: () => Promise<boolean>;
  isAuthenticationDelegated: boolean;
}

// |--------------------------|
// | enums
// |--------------------------|

export const AdType = {
  NONE: "NONE",
  YOSPACE: "YOSPACE",
  YOSPACE_VOD: "YOSPACE_VOD",
};

export const DrmType = {
  PLAYREADY: "PLAYREADY",
  WIDEVINE: "WIDEVINE",
  FAIRPLAY: "FAIRPLAY",
  VERIMATRIX: "VERIMATRIX",
  NONE: "NONE",
};

export const Packaging = {
  HLS_TS_VMX: "HLS_TS_VMX",
  HLS_TS_CBCS: "HLS_TS_CBCS",
  HLS_CMAF_CBCS: "HLS_CMAF_CBCS",
  DASH_MP4_CTR: "DASH_MP4_CTR",
  RTSP: "RTSP",
  IGMP: "IGMP",
};

export const WhiteLabelBrand = {
  CMORE: "CMORE",
  TELIA: "TELIA",
  TV4: "TV4",
};

export type AdType = (typeof AdType)[keyof typeof AdType];
export type DrmType = (typeof DrmType)[keyof typeof DrmType];
export type Packaging = (typeof Packaging)[keyof typeof Packaging];
export type WhiteLabelBrand =
  (typeof WhiteLabelBrand)[keyof typeof WhiteLabelBrand];

export type BaseServiceConfiguration = {
  serviceCountry: string;
};

export type TSessionId = {
  applicationSessionId: string;
};

export type TDeviceId = {
  deviceId: string;
};

export type TServiceBrand = {
  serviceBrand: WhiteLabelBrand;
};

export type TOperatingSystemName = {
  operatingSystemName: string;
};

export type TDeviceVendor = {
  deviceVendor?: string;
};

export type TDeviceModel = {
  deviceModel: string;
};

export type TServiceClientName = {
  clientName: TClientName;
};

export type AuthenticationServiceConfiguration = BaseServiceConfiguration &
  TDeviceId &
  TServiceBrand &
  TServiceClientName & {
    loginGatewayUrl: string;
  };

export type MessagingServiceConfiguration = BaseServiceConfiguration & {
  tvClientGatewayUrl: string;
};

export type MetadataServiceConfiguration = BaseServiceConfiguration &
  TSessionId &
  TDeviceVendor &
  TDeviceModel &
  TServiceClientName & {
    graphUrl: string;
    // Version string must validate as semver (e.g. 1.0.0)
    graphClientVersion: string;
  };

export type StreamingGatewayServiceConfiguration = BaseServiceConfiguration &
  TSessionId &
  TDeviceId &
  TServiceBrand &
  TDeviceVendor &
  TDeviceModel &
  TOperatingSystemName &
  TServiceClientName & {
    streamingGatewayUrl: string;
    screen: ScreenInfo;
    application: {
      applicationName: string;
      applicationVersion: string;
    };
    serviceName: "C More" | "Telia TV" | "Reference App";
  };

export type ServiceConfig = AuthenticationServiceConfiguration &
  MessagingServiceConfiguration &
  MetadataServiceConfiguration &
  StreamingGatewayServiceConfiguration;
