import { useTheme } from "@emotion/react";
import { useEffect } from "react";

export function useMiniControlsSpacer() {
  const { breakpoints } = useTheme();
  const createSpacer = () => {
    if (!window?.matchMedia) {
      return () => undefined;
    }

    function widthChange(mq: MediaQueryList | MediaQueryListEvent) {
      let spacer = document.getElementById("mini-controls-spacer");
      if (!spacer) {
        spacer = document.createElement("div");
        spacer.setAttribute("id", "mini-controls-spacer");
        document.getElementById("root")?.appendChild(spacer);
      }
      if (!spacer) return;
      if (mq.matches) {
        spacer.style.paddingBottom = "124px";
      } else {
        spacer.style.paddingBottom = "108px";
      }
    }

    const mediaQuery = window.matchMedia(`(min-width: ${breakpoints.S}px)`);
    mediaQuery.addEventListener("change", widthChange);
    widthChange(mediaQuery);

    return () => {
      document.getElementById("mini-controls-spacer")?.remove();
    };
  };

  useEffect(createSpacer, [breakpoints.S]);
}
