import { NetworkError } from "./NetworkError";
import {
  GraphErrorCodes,
  LoginGatewayErrorCodes,
  MessagingErrorCodes,
  StreamingGatewayErrorCodes,
  TvClientGatewayErrorCodes,
  UnknownError,
} from "./constants";

export type TGetCodeFromNetworkErrorArgs = {
  error: NetworkError;
  errorMap: Record<
    number,
    | LoginGatewayErrorCodes
    | StreamingGatewayErrorCodes
    | GraphErrorCodes
    | TvClientGatewayErrorCodes
    | MessagingErrorCodes
  >;
};

export const getCodeFromNetworkError = ({
  error,
  errorMap,
}: TGetCodeFromNetworkErrorArgs) => {
  // First look for errorName in the response
  if (typeof error.responseBody?.errorName === "string")
    return error.responseBody.errorName;

  // Then look for a numeric code, falling back to the network error code if missing
  const code = error.responseBody?.errorCode || error.statusCode;

  // Map the code to a string, or fall back to UnknownError
  return errorMap[code] || code || UnknownError;
};

const mapNonEnumerableErrorProperties = (error: Error | any) => {
  const mapped: any = {};
  if (!error) return mapped;

  mapped.category = error.category;
  mapped.code = error.code;
  mapped.details = error.details;
  mapped.fatal = error.fatal;

  const innerError = error.error || {};
  const innerReason = error.reason || {};
  const message =
    error.message ||
    innerError.message ||
    (typeof innerReason === "string" ? innerReason : innerReason.message);
  const stack = error.stack || innerError.stack || innerReason.stack;

  if (message) {
    mapped.message = message;
  }
  if (stack) {
    mapped.stack = stack;
  }
  if (error.reason && typeof error.reason !== "string") {
    mapped.reason = error.reason;
  }

  return mapped;
};

export const mapToEnumerableError = (error: Error | any) => {
  Object.entries(mapNonEnumerableErrorProperties(error))
    .filter((entry) => !!entry[1])
    .forEach((entry) =>
      Object.defineProperty(error, entry[0], {
        configurable: true,
        enumerable: true,
        value: entry[1],
        writable: true,
      })
    );

  return error;
};

export const enumerateError = (val: Error): any =>
  Object.getOwnPropertyNames(val).reduce(
    (acc, propName) => ({
      ...acc,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [propName]: val[propName],
    }),
    {}
  );

export const JSONStringifyWithErrors = (data: any): string => {
  const replaceErrors = (key: string, value: Record<string, any>): any => {
    if (value instanceof Error) {
      return enumerateError(value);
    }

    return value;
  };

  return JSON.stringify(data, replaceErrors);
};
