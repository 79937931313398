var DataExtractor = require('./dataextractor')
var NpawObject = require('../object')
var MD5 = require('./md5')
const { default: Core } = require('../../core/Core')

var HashGenerator = NpawObject.extend({
  constructor: function () {
    this.dataExtractor = new DataExtractor()
    this.key = this.getPreviousKey()
  },

  generateHashKey: function () {
    if (!this.key) {
      var data = this.dataExtractor.getAllData()
      this.key = this._hashFunction(data)
      Core.getInstance().getCoreStorage().setLocal('npawDeviceUUID', this.key);
    }
  },

  getKey: function () {
    if (!this.key) this.generateHashKey()
    return this.key
  },

  _hashFunction: function (inputString) {
    var outputString = inputString
    if (typeof inputString !== 'string') {
      outputString = JSON.stringify(inputString)
    }
    return MD5(outputString)
  },

  _reset: function () {
    this.key = undefined
  },

  getPreviousKey: function () {
    return Core.getInstance().getCoreStorage().getLocal('npawDeviceUUID');
  }
});

module.exports = HashGenerator
