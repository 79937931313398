import styled from "@emotion/styled";
import { FC } from "react";

import { usePlayerState } from "./context-provider";

const StatsOverlayDiv = styled.div`
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.85;
`;

// enable with shift+D, registered in global-hotkeys.hook.ts

export const StatsOverlay: FC = () => {
  const { showStatistics, stats } = usePlayerState();
  if (showStatistics)
    return (
      <StatsOverlayDiv>
        <ul>
          <li>{`ui: ${WEB_PLAYER_UI_VERSION}`}</li>
        </ul>
        <pre>{JSON.stringify(stats, null, 2)}</pre>
      </StatsOverlayDiv>
    );
  return null;
};
