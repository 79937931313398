import { PlaybackEventTypes } from "../Constants";
import { PlaybackEventBase } from "./PlaybackEventBaseClass";
import { TEmptyPayload } from "./Types";

export class ManifestTypeChangedEvent extends PlaybackEventBase<
  PlaybackEventTypes.MANIFEST_TYPE_CHANGED,
  TEmptyPayload
> {
  constructor() {
    super(PlaybackEventTypes.MANIFEST_TYPE_CHANGED, {});
  }
}
