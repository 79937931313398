import { PlaybackEventTypes, StoppedReasons } from "../Constants";
import { PlaybackEventBase } from "./PlaybackEventBaseClass";

// currentTime and duration should be undefined for live content
export type TStoppedEventPayload = {
  currentTime: number | undefined;
  duration: number | undefined;
  reason: StoppedReasons;
};

export class StoppedEvent extends PlaybackEventBase<
  PlaybackEventTypes.STOPPED,
  TStoppedEventPayload
> {
  constructor(payload: TStoppedEventPayload) {
    super(PlaybackEventTypes.STOPPED, payload);
  }
}
