import Constants from './nqs/Services';
import Util from './utils/Util';
import Core from './Core';
import { CoreTag } from '../common/Constants';
import Log from '../common/log';
export default class FastDataService {
    /**
     * This class manages Fastdata service and view index.
     *
     * @constructs
     *
     * @param {CoreOptions} options Options from the Core
     * @param {CoreStorage} coreStorage Core storage object
     */
    constructor(options, accountCode, coreStorage, browserStorage, npawPluginOptions) {
        var _a, _b, _c;
        this.response = {};
        this._session = '';
        this.sessionExpire = 120;
        this.retries = 0;
        this.isUsingLegacy = false;
        this.options = options;
        this.npawPluginOptions = npawPluginOptions;
        this.accountCode = accountCode;
        this.coreStorage = coreStorage;
        this.browserStorage = browserStorage;
        this._fastDataHost = this.options.getFastDataHost();
        const usingStandalone = (_a = process.env.IS_STANDALONE === 'true') !== null && _a !== void 0 ? _a : false;
        this.isUsingLegacy = usingStandalone && ((_c = typeof ((_b = npawPluginOptions === null || npawPluginOptions === void 0 ? void 0 : npawPluginOptions.legacyPlugin) === null || _b === void 0 ? void 0 : _b.viewTransform) !== 'undefined') !== null && _c !== void 0 ? _c : false);
        /** Create interval to get configuration from LMA periodically */
        this._createConfigurationInterval();
    }
    /**
     * Starts the 'FastData' fetching. This will send the initial request to NPAW in order to get
     * the needed info for the rest of the requests.
     *
     * This is an asynchronous process.
     *
     * When the fetch is complete, {@link #fastDataConfig} will contain the parsed info.
     * @see FastDataConfig
     */
    init() {
        var _a, _b;
        // offline
        if (this.options && this.options.offline) {
            // set the options
            this.response.host = 'OFFLINE';
            this.response.code = 'OFFLINE';
            this.response.pingTime = 60;
            this.response.beatTime = 60;
            this._session = Util.generateRandomString(15);
            return;
        }
        // Do not perform /configuration and /data requests if using the legacy plugin
        if (this.isUsingLegacy)
            return;
        // reusing old data not expired
        if (this.browserStorage && this.browserStorage.isEnabled()) {
            const now = new Date().getTime();
            const sessionExpire = ((_a = Number(this.browserStorage.getLocal('sessionExpire'))) !== null && _a !== void 0 ? _a : this.sessionExpire) * 1000;
            if (now < sessionExpire + (Number(this.coreStorage.getDataTime()) || 0) && this.coreStorage.getStoredData()) {
                this.setData((_b = this.coreStorage.getStoredData()) !== null && _b !== void 0 ? _b : '');
                return;
            }
        }
        // request new data
        const serviceData = Constants.Service.DATA;
        const dataParams = {
            system: this.accountCode,
            pluginVersion: Core.getPluginVersion(),
            requestNumber: this.getRequestNumber()
        };
        // load configuration from backend
        this.loadConfiguration();
        try {
            Log.notice(CoreTag, serviceData + ' ' + dataParams.system);
            if (dataParams.system === 'nicetest') {
                // "nicetest" is the default accountCode.
                // If found here, it's very likely that the customer has forgotten to set it.
                Log.error(CoreTag, "No accountCode has been set. Please set your accountCode inside plugin's options.");
            }
            this.performRequestToFastData(serviceData, dataParams, this._receiveData.bind(this), this._failedData.bind(this));
        }
        catch (err) {
            Log.error(CoreTag, 'Error doing get data request to FastData service.');
        }
    }
    updateOptions(options) {
        this.options.updateOptions(options);
        if (this.options.offline) {
            this.reset();
            this.init();
        }
    }
    getSessionExpireTime() {
        return this.sessionExpire;
    }
    refreshSessionToken() {
        this._session = '';
        this.browserStorage.removeStorages('data');
        this.init();
    }
    /**
     * Load configuration from backend
     */
    loadConfiguration() {
        const serviceConfiguration = Constants.Service.CONFIGURATION;
        const configurationParams = {
            system: this.accountCode,
            pluginVersion: Core.getPluginVersion(),
            requestNumber: this.getRequestNumber()
        };
        this.performRequestToFastData(serviceConfiguration, configurationParams, this._receiveConfiguration.bind(this), this._failedConfiguration.bind(this));
    }
    /**
     * Returns the current session
     *
     * @returns Session
     */
    getSession() {
        var _a, _b;
        return this.isUsingLegacy ? (_b = (_a = this.npawPluginOptions) === null || _a === void 0 ? void 0 : _a.legacyPlugin) === null || _b === void 0 ? void 0 : _b.viewTransform._session : this._session;
    }
    /**
     * Sets the session
     *
     * @param sessionId Sets the session
     */
    setSession(session) {
        this._session = session;
    }
    /** Sets the host url
     *
     * @param host Sets the host
     */
    setHost(host) {
        this.response.host = host;
    }
    getPingTime() {
        var _a;
        return (_a = this.pingTime) !== null && _a !== void 0 ? _a : 5;
    }
    getBeatTime() {
        var _a;
        return (_a = this.beatTime) !== null && _a !== void 0 ? _a : 30;
    }
    /**
     * Uses given response to set fastdata response.
     *
     * @param {String} response Fastdata response as json string.
     */
    setData(response) {
        try {
            let resp = JSON.parse(response);
            if (this.options['parse.fdsResponseHost']) {
                response = response.replace(resp.q.h, this.options['parse.fdsResponseHost'](resp.q.h));
                resp = JSON.parse(response);
            }
            this.response = {
                msg: response,
                host: resp.q.h,
                code: resp.q.c,
                pingTime: resp.q.pt || 5,
                beatTime: resp.q.i ? resp.q.i.bt || 30 : 30,
                sessionExpire: resp.q.st ? resp.q.st || 120 : 120
            };
            this._session = this.response.code;
            this.browserStorage.setLocal('sessionExpire', this.response.sessionExpire);
            this.browserStorage.setStorages('accCode', this.accountCode);
            this.browserStorage.setStorages('session', this.response.code);
            this.coreStorage.setStoredData(this.response.msg);
            this.coreStorage.setDataTime(new Date().getTime().toString());
            this.pingTime = this.response.pingTime;
            this.beatTime = this.response.beatTime;
            this.sessionExpire = this.response.sessionExpire;
        }
        catch (err) {
            Log.error(CoreTag, 'Fastdata response is invalid.');
        }
    }
    /**
     *
     * @param response Fastdata response as json string
     */
    setConfiguration(response) {
        try {
            this.response.configurationOptions = JSON.parse(response);
        }
        catch (err) {
            Log.error(CoreTag, 'Configuration response is invalid.');
        }
    }
    getFastDataResponse() {
        return this.response;
    }
    getAccountCode() {
        return this.accountCode;
    }
    getRequestNumber() {
        return Math.random();
    }
    /**
     * Resets the data stored on the browser
     * @private
     */
    reset() {
        this.coreStorage.removeStoredData();
        this.coreStorage.removeSession();
        this.browserStorage.removeLocal('sessionExpire');
        this.browserStorage.removeStorages('accCode');
        this.browserStorage.removeStorages('session');
    }
    performRequestToFastData(service, params, onSuccess, onFail) {
        if (this.options.offline)
            return;
        params['timemark'] = new Date().getTime();
        params['outputFormat'] = 'json';
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', `https://${this._fastDataHost}${service}${Util.toQueryString(params)}`);
        xhr.onload = function (ev) {
            if (self.options.offline)
                return;
            if (xhr.status >= 200 && xhr.status < 400) {
                onSuccess(this, ev);
            }
            else {
                onFail(this, ev);
            }
        };
        xhr.onerror = function (ev) {
            onFail(this, ev);
        };
        xhr.send();
    }
    /**
     * Create Configuration Interval
     * @private
     */
    _createConfigurationInterval() {
        // Register only if refresh lma configuration enabled, and is not created previously
        if (!this.isUsingLegacy && this.options.isRefreshLMAConfigurationEnabled() && !this._refreshInterval) {
            this._refreshInterval = setInterval(this.loadConfiguration.bind(this), this.options.refreshLMAConfigurationSeconds() * 1000);
        }
    }
    /**
     * Parse the response from the fastData service.
     *
     */
    _receiveData(req, e) {
        this.setData(req.response);
    }
    _receiveConfiguration(req, e) {
        this.setConfiguration(req.response);
    }
    _failedData(req, e) {
        Log.error(CoreTag, 'Fastdata request has failed.');
        if (++this.retries < 5) {
            setTimeout(this.init.bind(this), 5000);
        }
    }
    _failedConfiguration(req, e) {
        Log.error(CoreTag, 'Fastdata configuration request has failed.');
        if (++this.retries < 5) {
            setTimeout(this.init.bind(this), 5000);
        }
    }
}
